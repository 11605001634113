// @flow
import React from 'react';
import classNames from 'classnames';

import type { ValidationResult } from '../../types/order-item';

import Popover from '../../../components/Popover';

import styles from './ItemIssues.css';

const firstLetterUpperCase = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

type IssuesLinkProps = {
  active?: boolean,
  firstValidationResult: ValidationResult,
  issuePopoverText: string
};
const IssuesLink = (props: IssuesLinkProps) => {
  const { firstValidationResult, issuePopoverText } = props;
  return (
    <div>
      <span
        className={classNames(styles.dot, {
          [styles.warningIssueDot]: firstValidationResult.level === 'warning',
          [styles.errorIssueDot]: firstValidationResult.level === 'error'
        })}
      />{' '}
      <span
        className={classNames({
          [styles.warningIssueText]: firstValidationResult.level === 'warning',
          [styles.errorIssueText]: firstValidationResult.level === 'error'
        })}
      >
        {issuePopoverText}
      </span>
    </div>
  );
};

type ItemIssuesProps = {
  validationResults: ValidationResult[]
};
const ItemIssues = (props: ItemIssuesProps) => {
  const { validationResults } = props;

  let issuePopoverText: string = 'No Issues';
  let firstValidationResult: ValidationResult | null = null;
  if (validationResults.length > 0) {
    firstValidationResult = validationResults[0];
    issuePopoverText = `${firstValidationResult.message} ${
      validationResults.length > 1 ? ' +' + (validationResults.length - 1) : ''
    }`;
  }

  return (
    <div>
      {firstValidationResult !== null ? (
        <Popover
          PopoverLinkComponent={
            <IssuesLink
              firstValidationResult={firstValidationResult}
              issuePopoverText={issuePopoverText}
            />
          }
          PopoverContentComponent={
            <div className={styles.additionalIssuesContainer}>
              {validationResults.map(
                (validationResult: ValidationResult, index: number) => {
                  return (
                    <div className={styles.additionalIssueItem} key={index}>
                      <div
                        className={classNames(styles.additionalIssueText, {
                          [styles.warningIssueText]:
                            validationResult.level === 'warning',
                          [styles.errorIssueText]:
                            validationResult.level === 'error'
                        })}
                      >
                        {`${firstLetterUpperCase(validationResult.level)} - ${
                          validationResult.message
                        }`}
                      </div>
                      <div>{validationResult.detail}</div>
                    </div>
                  );
                }
              )}
            </div>
          }
        />
      ) : (
        <div>
          <span className={classNames(styles.dot, styles.noIssuesDot)} />{' '}
          <span className={styles.noIssuesText}>{issuePopoverText}</span>
        </div>
      )}
    </div>
  );
};

export default ItemIssues;
