// @flow
import React from 'react';
import { RTThemedIconButton } from '@hc/component-lib/lib/rt-themed';
import WithRouterLink from 'src/history/WithRouterLink';

import styles from './RoutingIconButton.css';

export const RoutingIconButton = ({ white, ...props }: any) => (
  <RTThemedIconButton theme={white ? styles : {}} {...props} />
);

export default WithRouterLink(RoutingIconButton);
