// @flow
import type { OrderExport } from 'src/client/types/order-export';

export const REQUEST_ORDER_EXPORT = 'REQUEST_ORDER_EXPORT';
export const REQUEST_ORDER_EXPORT_ERROR = 'REQUEST_ORDER_EXPORT_ERROR';
export const REQUEST_ORDER_EXPORT_SUCCESS = 'REQUEST_ORDER_EXPORT_SUCCESS';
export const FETCH_ORDER_EXPORTS_LOADING = 'FETCH_ORDER_EXPORTS_LOADING';
export const FETCH_ORDER_EXPORTS_SUCCESS = 'FETCH_ORDER_EXPORTS_SUCCESS';
export const FETCH_ORDER_EXPORTS_ERROR = 'FETCH_ORDER_EXPORTS_ERROR';

export type RequestOrderExportAction = {
  type: 'REQUEST_ORDER_EXPORT',
  payload: { orderId: string, includeJSON?: boolean }
};
export type RequestOrderExportErrorAction = {
  type: 'REQUEST_ORDER_EXPORT_ERROR',
  payload: { orderId: string, errorMessage: string }
};
export type RequestOrderExportSuccessAction = {
  type: 'REQUEST_ORDER_EXPORT_SUCCESS',
  payload: { orderId: string }
};
export type FetchOrderExportsLoadingAction = {
  type: 'FETCH_ORDER_EXPORTS_LOADING',
  payload: { orderId: string }
};
export type FetchOrderExportsSuccessAction = {
  type: 'FETCH_ORDER_EXPORTS_SUCCESS',
  payload: { orderId: string, orderExports: OrderExport[] }
};
export type FetchOrderExportsErrorAction = {
  type: 'FETCH_ORDER_EXPORTS_ERROR',
  payload: { orderId: string, errorMessage: string }
};

export type OrderExportAction =
  | RequestOrderExportAction
  | RequestOrderExportErrorAction
  | RequestOrderExportSuccessAction
  | FetchOrderExportsLoadingAction
  | FetchOrderExportsSuccessAction
  | FetchOrderExportsErrorAction;

export const requestOrderExport = (
  orderId: string,
  includeJSON?: boolean
): RequestOrderExportAction => ({
  type: REQUEST_ORDER_EXPORT,
  payload: { orderId, includeJSON }
});
export const requestOrderExportError = (
  orderId: string,
  errorMessage: string
): RequestOrderExportErrorAction => ({
  type: REQUEST_ORDER_EXPORT_ERROR,
  payload: { orderId, errorMessage }
});
export const requestOrderExportSuccess = (
  orderId: string
): RequestOrderExportSuccessAction => ({
  type: REQUEST_ORDER_EXPORT_SUCCESS,
  payload: { orderId }
});
export const fetchOrderExportsLoading = (
  orderId: string
): FetchOrderExportsLoadingAction => ({
  type: FETCH_ORDER_EXPORTS_LOADING,
  payload: { orderId }
});
export const fetchOrderExportsSuccess = (
  orderId: string,
  orderExports: OrderExport[]
): FetchOrderExportsSuccessAction => ({
  type: FETCH_ORDER_EXPORTS_SUCCESS,
  payload: { orderId, orderExports }
});
export const fetchOrderExportsError = (
  orderId: string,
  errorMessage: string
): FetchOrderExportsErrorAction => ({
  type: FETCH_ORDER_EXPORTS_ERROR,
  payload: { orderId, errorMessage }
});
