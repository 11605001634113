// @flow
import type { Action } from '../actions';

import {
  SHOW_CANCEL_ORDER_DIALOG,
  DISMISS_CANCEL_ORDER_DIALOG,
  CANCEL_ORDER,
  CANCEL_ORDER_ERROR,
  CANCEL_ORDER_SUCCESS
} from 'src/client/actions/cancel-order.actions';

type CancelOrderClosedState = {
  dialogVisible: false,
  status: 'closed',
  orderId: null
};

type CancelOrderStateType = {
  dialogVisible: true,
  status: 'untouched' | 'loading' | 'cancelled',
  orderId: string
};

type CancelOrderErrorState = {
  dialogVisible: true,
  status: 'error',
  error: string,
  orderId: string
};

export type CancelOrderState =
  | CancelOrderClosedState
  | CancelOrderStateType
  | CancelOrderErrorState;

const defaultState = {
  dialogVisible: false,
  status: 'closed',
  orderId: null
};

export function cancelOrderReducer(
  state: CancelOrderState = defaultState,
  action: Action
) {
  switch (action.type) {
    case SHOW_CANCEL_ORDER_DIALOG: {
      return {
        dialogVisible: true,
        status: 'untouched',
        orderId: action.payload.id
      };
    }
    case DISMISS_CANCEL_ORDER_DIALOG: {
      return {
        dialogVisible: false,
        status: 'closed',
        orderId: null
      };
    }
    case CANCEL_ORDER: {
      return {
        dialogVisible: true,
        status: 'loading',
        orderId: action.payload.id
      };
    }
    case CANCEL_ORDER_SUCCESS: {
      if (action.payload.id !== state.orderId) {
        return state;
      }
      return {
        dialogVisible: true,
        status: 'cancelled',
        orderId: action.payload.id
      };
    }
    case CANCEL_ORDER_ERROR: {
      if (action.payload.id !== state.orderId) {
        return state;
      }
      return {
        dialogVisible: true,
        status: 'error',
        error: action.payload.errorMessage,
        orderId: action.payload.id
      };
    }
    default: {
      return state;
    }
  }
}
