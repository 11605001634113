// @flow
import * as React from 'react';
import classNames from 'classnames';
import { RTThemedProgressBar } from '@hc/component-lib/lib/rt-themed';

import theme from './Loader.css';

type LoaderProps = {
  loading: boolean,
  className?: string
};

const Loader = (props: LoaderProps) => {
  const { loading, className } = props;

  return (
    <div className={classNames(theme.loaderContainer, className)}>
      {loading ? (
        <RTThemedProgressBar type="circular" mode="indeterminate" />
      ) : null}
    </div>
  );
};

export default Loader;
