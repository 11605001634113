// @flow
import fetch from 'isomorphic-fetch';
import parseLinkHeader from 'parse-link-header';
import { saveAs } from 'file-saver';

import type { PostMessage } from '../client/types/add-message';
import type { PagingParams, QueryParams } from '../types/list';
import type { AddressParts } from '../types/address';

import { camelToSnake } from 'src/sagas/helpers';
import * as urls from 'src/helpers/url-helpers';
import { authClient } from '../sagas/auth-client-instance';

var SETTINGS = window.SETTINGS;

export function NotFoundError(message: ?string) {
  this.name = 'NotFoundError';
  this.message = message || 'Item not found';
  this.stack = new Error().stack;
}
NotFoundError.prototype = Object.create(Error.prototype);
NotFoundError.prototype.constructor = NotFoundError;

export function OrderConflictError(message: ?string) {
  this.name = 'OrderConflictError';
  this.message = message || 'Order not available';
  this.stack = new Error().stack;
}
OrderConflictError.prototype = Object.create(Error.prototype);
OrderConflictError.prototype.constructor = OrderConflictError;

export function LoginRequiredError(message: ?string) {
  this.name = 'LoginRequiredError';
  this.message = message || 'Login Required';
  this.stack = new Error().stack;
}
LoginRequiredError.prototype = Object.create(Error.prototype);
LoginRequiredError.prototype.constructor = LoginRequiredError;

export function ForbiddenError(message: ?string) {
  this.name = 'ForbiddenError';
  this.message = message || 'Access Denied';
  this.stack = new Error().stack;
}
ForbiddenError.prototype = Object.create(Error.prototype);
ForbiddenError.prototype.constructor = ForbiddenError;

export function ValidationError(data: { [key: string]: any }) {
  this.name = 'ValidationError';
  this.message = 'Validation error';
  this.data = data;
  this.stack = new Error().stack;
}
ValidationError.prototype = Object.create(Error.prototype);
ValidationError.prototype.constructor = ValidationError;

export function ServerError(message: ?string) {
  this.name = 'ServerError';
  this.message = message || 'Internal server error';
  this.stack = new Error().stack;
}
ServerError.prototype = Object.create(Error.prototype);
ServerError.prototype.constructor = ServerError;

export function RevisionRequestError(message: ?string) {
  this.name = 'RevisionRequestError';
  this.message = message || 'Revision request error';
  this.stack = new Error().stack;
}
RevisionRequestError.prototype = Object.create(Error.prototype);
RevisionRequestError.prototype.constructor = RevisionRequestError;

function maybeThrowApiError(response) {
  if (response.status === 403) {
    throw new ForbiddenError();
  } else if (response.status === 404) {
    throw new NotFoundError();
  } else if (response.status === 409) {
    return response.json().then((data) => {
      throw new OrderConflictError((data && data.detail) || '');
    });
  } else if (response.status === 204) {
    return {};
  } else if (response.status !== 200 && response.status !== 201) {
    return response.text().then((text) => {
      throw new ServerError(text);
    });
  }
}

const X_CALLER_VALUE = 'order-manager-client';

export class Client {
  apiUrl: string;

  token: string;

  constructor(token: string) {
    this.apiUrl = SETTINGS.ORDER_MANAGER_API_URL;
    this.token = token;
  }

  async refreshAccessToken(retryfetch: Function) {
    try {
      console.log('about to check or referesh token');
      const userContext = await authClient.checkOrRefreshToken();
      const newToken = userContext.validity.token;
      console.log('newToken', newToken);
      if (newToken) {
        this.token = newToken;
        return retryfetch();
      }
    } catch (e) {
      console.log('refreshAccessToken error', e);
      throw new LoginRequiredError();
    }
  }

  maybeRefreshAccessToken(
    response: Response,
    retryMethod: Function,
    ...args: any[]
  ) {
    if (response.status === 401) {
      return this.refreshAccessToken(retryMethod.bind(this, ...args));
    }
  }

  getAcceptOrderDetails(orderId: string) {
    const url = `${this.apiUrl}${urls.getAcceptOrderPath(orderId)}`;
    return fetch(url, {
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(
          response,
          this.getAcceptOrderDetails,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  getEvaluateOrders() {
    const url = `${this.apiUrl}/partner-api/v1/valuation-orders`;
    return fetch(url, {
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(response, this.getEvaluateOrders) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  submitAcceptOrder(orderId: string) {
    const url = `${this.apiUrl}${urls.getAcceptOrderPath(orderId)}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`
      },
      data: { id: orderId }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(
          response,
          this.submitAcceptOrder,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  getOrderTypeDescriptors() {
    const url = `${this.apiUrl}/client-api/v1/order_types`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(response, this.getOrderTypeDescriptors) ||
        maybeThrowApiError(response) ||
        response.json().then((data) => {
          return {
            data
          };
        })
      );
    });
  }

  uploadSupportingDocument(data: window.FormData) {
    const url = `${this.apiUrl}/client-api/v1/orders/supporting-documents`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'x-caller': X_CALLER_VALUE,
        Authorization: `JWT ${this.token}`
      },
      body: data
    }).then((response) => {
      if (response.status === 400) {
        return response.json().then((data) => {
          throw new ValidationError(data);
        });
      }
      return (
        this.maybeRefreshAccessToken(response, this.uploadSupportingDocument) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  addNewOrderJson(data: any) {
    const url = `${this.apiUrl}/client-api/v1/orders/json`;
    const headers = {
      'x-caller': X_CALLER_VALUE,
      Authorization: `JWT ${this.token}`,
      'Content-Type': 'application/json'
    };
    return fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    }).then((response) => {
      if (response.status === 400) {
        return response.json().then((data) => {
          throw new ValidationError(data);
        });
      }
      return (
        this.maybeRefreshAccessToken(response, this.addNewOrderJson, data) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  addNewOrderCsv(data: window.FormData) {
    const url = `${this.apiUrl}${urls.getCreateOrderCsvPath}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'x-caller': X_CALLER_VALUE,
        Authorization: `JWT ${this.token}`
      },
      body: data
    }).then((response) => {
      if (response.status === 400) {
        return response.json().then((data) => {
          throw new ValidationError(data);
        });
      }
      return (
        this.maybeRefreshAccessToken(response, this.addNewOrderCsv, data) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  extendOrderJson(data: any, orderId: string) {
    const url = `${this.apiUrl}/client-api/v1/orders/${orderId}/json`;
    const headers = {
      'x-caller': X_CALLER_VALUE,
      Authorization: `JWT ${this.token}`,
      'Content-Type': 'application/json'
    };
    return fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    }).then((response) => {
      if (response.status === 400) {
        return response.json().then((data) => {
          throw new ValidationError(data);
        });
      }
      return (
        this.maybeRefreshAccessToken(
          response,
          this.extendOrderJson,
          data,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  extendOrderCsv(data: window.FormData, orderId: string) {
    const url = `${this.apiUrl}/client-api/v1/orders/${orderId}/csv`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'x-caller': X_CALLER_VALUE,
        Authorization: `JWT ${this.token}`
      },
      body: data
    }).then((response) => {
      if (response.status === 400) {
        return response.json().then((data) => {
          throw new ValidationError(data);
        });
      }
      return (
        this.maybeRefreshAccessToken(
          response,
          this.extendOrderCsv,
          data,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  reviewAcceptOrder(orderId: string) {
    const url = `${this.apiUrl}${urls.getReviewAcceptOrderPath(orderId)}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(
          response,
          this.reviewAcceptOrder,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  reviewRejectOrder(orderId: string) {
    const url = `${this.apiUrl}${urls.getReviewRejectOrderPath(orderId)}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(
          response,
          this.reviewRejectOrder,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  getOrders(params: QueryParams) {
    const queryParams = camelToSnake(params, Object.keys(params));
    const url = `${this.apiUrl}${urls.getOrdersWithQueryStringPath(
      queryParams
    )}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(response, this.getOrders, params) ||
        maybeThrowApiError(response) ||
        response.json().then((data) => {
          return {
            links: parseLinkHeader(response.headers.get('Link')),
            ordering: response.headers.get('Ordering'),
            data
          };
        })
      );
    });
  }

  getOrderProgress(orderId: string) {
    const url = `${this.apiUrl}${urls.getOrderProgressPath(orderId)}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(
          response,
          this.getOrderProgress,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  getOrderDetails(id: string) {
    const url = `${this.apiUrl}${urls.getOrderPath(id)}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(response, this.getOrderDetails, id) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  getOrderItemProcessCounts(id: string) {
    const url = `${this.apiUrl}${urls.getOrderProcessCountsPath(id)}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.getOrderItemProcessCounts,
          id
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  cancelOrder(orderId: string) {
    const url = `${this.apiUrl}${urls.getCancelOrderPath(orderId)}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(response, this.cancelOrder, orderId) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  cancelOrderItem(orderId: string, orderItemId: string) {
    const url = `${this.apiUrl}${urls.getCancelOrderItemPath(
      orderId,
      orderItemId
    )}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.cancelOrderItem,
          orderId,
          orderItemId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  submitRevisionRequest(
    orderId: string,
    orderItemId: string,
    explanation: string
  ) {
    const url = `${this.apiUrl}${urls.getSubmitRevisionRequestPath(
      orderId,
      orderItemId
    )}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        message: explanation
      })
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.submitRevisionRequest,
          orderId,
          orderItemId,
          explanation
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  getOrderItems(orderId: string, params: QueryParams) {
    const queryParams = camelToSnake(params, Object.keys(params), {}, false);
    const url = `${this.apiUrl}${urls.getOrderItemsWithQueryStringPath(
      orderId,
      queryParams
    )}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(
          response,
          this.getOrderItems,
          orderId,
          params
        ) ||
        maybeThrowApiError(response) ||
        response.json().then((data) => {
          return {
            links: parseLinkHeader(response.headers.get('Link')),
            data,
            ordering: response.headers.get('ordering')
          };
        })
      );
    });
  }

  updateOrderItems(
    orderId: string,
    packet: { id: number, [property: string]: any }[]
  ) {
    const url = `${this.apiUrl}/${urls.getOrderItemsBulkPath(orderId)}`;
    return fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(packet)
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.updateOrderItems,
          orderId,
          packet
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  updateReverifyOrderItem(
    orderId: string,
    orderItemId: string,
    addressParts: AddressParts,
    propertyType: string
  ) {
    const url = `${this.apiUrl}${urls.getOrderItemUpdateReverifyPath(
      orderId,
      orderItemId
    )}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: orderItemId,
        ...addressParts,
        property_type: propertyType
      })
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.updateReverifyOrderItem,
          orderId,
          orderItemId,
          addressParts,
          propertyType
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  getOrderItemComments(
    orderId: string,
    orderItemId: string,
    params: PagingParams
  ) {
    const url = `${this.apiUrl}${urls.getOrderItemCommentsWithPagingPath(
      orderId,
      orderItemId,
      params
    )}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.getOrderItemComments,
          orderId,
          orderItemId,
          params
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  postOrderItemComment(
    orderId: string,
    orderItemId: string,
    newComment: PostMessage
  ) {
    const url = `${this.apiUrl}${urls.getOrderItemCommentsPath(
      orderId,
      orderItemId
    )}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newComment)
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.postOrderItemComment,
          orderId,
          orderItemId,
          newComment
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  downloadSummary(
    orderId: string,
    filename: string,
    format: string = 'application/zip'
  ) {
    const url = `${this.apiUrl}${urls.getOrderSummaryCsvPath(orderId)}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': format
      }
    })
      .then(
        (response) =>
          this.maybeRefreshAccessToken(
            response,
            this.downloadSummary,
            orderId,
            filename,
            format
          ) ||
          maybeThrowApiError(response) ||
          response.blob()
      )
      .then((blob) => saveAs(blob, filename));
  }

  requestOrderExport(
    orderId: string,
    includeJSON: boolean = false,
    format: string = 'zip'
  ) {
    const url = `${this.apiUrl}${urls.getOrderExportPath(
      orderId,
      includeJSON,
      format
    )}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(
          response,
          this.requestOrderExport,
          orderId,
          includeJSON,
          format
        ) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  getOrderExportRequests(orderId: string) {
    const url = `${this.apiUrl}${urls.getOrderExportRequestsPath(
      orderId
    )}?ordering=-id`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(
          response,
          this.getOrderExportRequests,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  getOrderExportStatus(checkStatusUrl: string) {
    return fetch(checkStatusUrl, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return (
        this.maybeRefreshAccessToken(
          response,
          this.getOrderExportStatus,
          checkStatusUrl
        ) ||
        maybeThrowApiError(response) ||
        response.json()
      );
    });
  }

  downloadOrderItem(
    orderId: string,
    orderItemId: string,
    filename: string,
    format: string = 'application/zip'
  ) {
    const url = `${this.apiUrl}${urls.getOrderItemDownloadPath(
      orderId,
      orderItemId
    )}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': format
      }
    })
      .then(
        (response) =>
          this.maybeRefreshAccessToken(
            response,
            this.downloadOrderItem,
            orderId,
            orderItemId,
            filename,
            format
          ) ||
          maybeThrowApiError(response) ||
          response.blob()
      )
      .then((blob) => saveAs(blob, filename));
  }

  downloadOrderItemPdf(
    orderId: string,
    orderItemId: string,
    reportType: string
  ) {
    const url = `${this.apiUrl}${urls.getOrderItemDownloadPdfPath(
      orderId,
      orderItemId,
      reportType
    )}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.downloadOrderItemPdf,
          orderId,
          orderItemId,
          reportType
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  // does the same thing as downloadOrderItemPdf, but the backend will pick the pdf to download which will be the report of the first item
  // it's intended that this endpoint would only be used to quickly get a pdf download link for orders with one item
  downloadOrderPdf(orderId: string) {
    const url = `${this.apiUrl}${urls.getOrderDownloadPdfPath(orderId)}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.downloadOrderPdf,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  getAddressParts(address: string, addressId: ?string) {
    const url = `${this.apiUrl}${urls.getAddressParsePath}`;
    const packet = { address_string: address, address_id: addressId };

    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(packet)
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.getAddressParts,
          address,
          addressId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  getPartnerValuationOrderComments(orderId: string) {
    const url = `${this.apiUrl}${urls.getPartnerValuationCommentsPath(
      orderId,
      this.token
    )}`;
    return fetch(url, {
      method: 'GET'
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.getPartnerValuationOrderComments,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  getPartnerValuationRevision(orderId: string) {
    const url = `${this.apiUrl}${urls.getPartnerValuationRevisionPath(
      orderId
    )}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': 'application/json'
      }
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.getPartnerValuationRevision,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  submitPartnerValuationRevisionResponse(
    orderId: string,
    action: 'accept' | 'reject',
    message: string
  ) {
    const url = `${this.apiUrl}${urls.getPartnerValuationRevisionPath(
      orderId
    )}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        action,
        message
      })
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.submitPartnerValuationRevisionResponse,
          orderId,
          action,
          message
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  submitPartnerValuationOrderComment(
    orderId: string,
    comment: string,
    actionRequired: boolean
  ) {
    const url = `${this.apiUrl}${urls.getPartnerValuationCommentsPath(
      orderId,
      this.token
    )}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        comment,
        is_action_required: actionRequired
      })
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.submitPartnerValuationOrderComment,
          orderId,
          comment,
          actionRequired
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  getReviewValuationOrder(orderId: string) {
    const url = `${this.apiUrl}${urls.getReviewValuationPath(
      orderId,
      this.token
    )}`;
    return fetch(url, {
      method: 'GET'
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.getReviewValuationOrder,
          orderId
        ) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }

  submitReviewValuationOrder(
    orderId: string,
    payload: { status: 'Pass' } | { status: 'Reject', message: string }
  ) {
    const url = `${this.apiUrl}${urls.getReviewValuationPath(
      orderId,
      this.token
    )}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(
          response,
          this.submitReviewValuationOrder,
          orderId,
          payload
        ) || maybeThrowApiError(response)
    );
  }

  downloadReportSummaryCSV(
    orderId: string,
    filename: string,
    format: string = 'text/csv'
  ) {
    const url = `${this.apiUrl}${urls.getDownloadReportSummaryPath(orderId)}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': format
      }
    })
      .then(
        (response) =>
          this.maybeRefreshAccessToken(
            response,
            this.downloadReportSummaryCSV,
            orderId,
            filename,
            format
          ) ||
          maybeThrowApiError(response) ||
          response.blob()
      )
      .then((blob) => saveAs(blob, filename));
  }

  websocketTokenExchange() {
    const url = `${this.apiUrl}${urls.getWebsocketTokenExchangePath()}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${this.token}`,
        'Content-Type': 'application/json'
      }
    }).then(
      (response) =>
        this.maybeRefreshAccessToken(response, this.websocketTokenExchange) ||
        maybeThrowApiError(response) ||
        response.json()
    );
  }
}
