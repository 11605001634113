// @flow
import React from 'react';
import { TileButton } from '@hc/component-lib';
import { reduxForm, getFormValues } from 'redux-form';
import { connect, type MapStateToProps } from 'react-redux';

import {
  ADD_ORDER_FORM_NAME,
  INSPECTION_NAMES,
  FIELD_NAMES
} from '../../../constants/add-order';
import { getAsRepairedLabel } from '../../../helpers/add-order.helpers';
import { fieldMetaByOrderTypeSelector } from '../../../selectors/add-order.selectors';
import { ICONS } from '../../../constants/order-choices';

import type {
  FieldMetaByOrderType,
  FieldMeta,
  ProductType
} from '../../../types/add-order';
import type { DeliverySpeed, OrderTypeDescriptor } from '../../../types/order';
import type { State } from '../../../../reducers';

import classNames from 'classnames';
import styles from './add-order-form.css';

type SelectInspectionMethodProps = {
  updateStep: (number, ?ProductType) => void,
  onDeliveryClick: (DeliverySpeed) => void,
  onInspectionClick: (
    inspectionType: string,
    includesAsRepairedValue: ?boolean
  ) => void,
  showDeliveryOptions: boolean,
  orderTypes: OrderTypeDescriptor[],
  inspectionType: ?string,
  includesAsRepairedValue: ?boolean,
  fieldMeta: FieldMeta,
  change: Function
};

type SelectInspectionMethodState = {
  deliverySpeeds: ?(DeliverySpeed[])
};

class AddOrderInspectionMethodFormContent extends React.Component<
  SelectInspectionMethodProps,
  SelectInspectionMethodState
> {
  STEP_NUM = 3;

  state = {
    inspectionType: this.props.inspectionType,
    deliverySpeeds: null
  };

  updateDeliverySpeed = (option: DeliverySpeed) => {
    const { onDeliveryClick, change } = this.props;
    change(FIELD_NAMES.DELIVERY_SPEED, option.id);
    onDeliveryClick(option);
  };

  handleDeliveryClick = (option) => (e) => {
    e.preventDefault();
    const { updateStep } = this.props;
    this.updateDeliverySpeed(option);
    updateStep(this.STEP_NUM);
  };

  handleInspectionClick = (orderType) => (e) => {
    e.preventDefault();
    const { updateStep, change } = this.props;
    const { inspectionType, includesAsRepairedValue, deliverySpeeds } =
      orderType;
    change('orderType', orderType.id);
    this.props.onInspectionClick(
      inspectionType || 'desktop',
      includesAsRepairedValue
    );
    this.setState({
      deliverySpeeds: deliverySpeeds
    });
    if (deliverySpeeds.length < 2) {
      if (deliverySpeeds.length === 1) {
        this.updateDeliverySpeed(deliverySpeeds[0]);
      }
      updateStep(this.STEP_NUM);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const {
      orderTypes,
      showDeliveryOptions,
      inspectionType,
      includesAsRepairedValue,
      fieldMeta
    } = this.props;
    const { deliverySpeeds } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={styles.tileWrap}>
          <div className={styles.inspectionButtonsContainer}>
            {orderTypes.map((orderType, idx) => {
              const selected =
                orderType.inspectionType === inspectionType &&
                orderType.includesAsRepairedValue === includesAsRepairedValue;
              const inspectionKey = orderType.inspectionType
                ? orderType.inspectionType
                : 'desktop';
              const iconKey = orderType.inspectionType
                ? orderType.inspectionType
                : 'desktop';
              const iconSrc = ICONS[iconKey];
              const asRepairedLabel = getAsRepairedLabel(
                orderType.includesAsRepairedValue
              );
              return (
                <div
                  className={classNames({ [styles.active]: selected })}
                  key={idx}
                >
                  <TileButton
                    onClick={this.handleInspectionClick(orderType)}
                    theme={styles}
                    key={idx}
                    dataHcName={`inspection-${inspectionKey}-button`}
                  >
                    <img src={iconSrc} style={{ height: 50 }} />
                    <h4 data-hc-name="inspection-title-text">
                      {INSPECTION_NAMES[inspectionKey]}
                    </h4>
                    {asRepairedLabel && <div>{asRepairedLabel}</div>}
                  </TileButton>
                </div>
              );
            })}
          </div>
          {fieldMeta[FIELD_NAMES.DELIVERY_SPEED].shown && showDeliveryOptions && (
            <div className={styles.deliveryInputContainer}>
              {deliverySpeeds &&
                deliverySpeeds.map((option: DeliverySpeed, idx) => {
                  return (
                    <TileButton
                      onClick={this.handleDeliveryClick(option)}
                      theme={styles}
                      key={idx}
                      dataHcName={`delivery-${option.id}-button`}
                    >
                      <h4 data-hc-name="delivery-type-name-text">
                        {option.label}
                      </h4>
                      <div
                        className={styles.surcharge}
                        data-hc-name="delivery-type-surcharge-text"
                      >
                        {option.surcharge
                          ? `+ $${option.surcharge}/address`
                          : 'Standard'}
                      </div>
                    </TileButton>
                  );
                })}
            </div>
          )}
        </div>
      </form>
    );
  }
}

const AddOrderInspectionMethodForm = reduxForm({
  form: ADD_ORDER_FORM_NAME,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
  forceUnregisterOnUnmount: true
})(AddOrderInspectionMethodFormContent);

const mapStateToProps: MapStateToProps<*, *, *> = (state: State) => {
  const selector = getFormValues(ADD_ORDER_FORM_NAME);
  const formValues = selector(state);
  const currentOrderType = formValues ? formValues.orderType : null;
  const fieldMetaByOrderType: FieldMetaByOrderType =
    fieldMetaByOrderTypeSelector(state);
  const fieldMeta = currentOrderType
    ? fieldMetaByOrderType[currentOrderType]
    : fieldMetaByOrderType.default;
  return {
    fieldMeta
  };
};

export default connect(mapStateToProps)(AddOrderInspectionMethodForm);
