// @flow
import type { Saga } from 'redux-saga';
import type { ValuationCommentAddAction } from '../actions/valuation-comment.actions';

import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Client } from 'src/api/order-manager-api-client';
import {
  resetValuationComments,
  valuationCommentLoadComplete,
  valuationCommentLoadError,
  valuationCommentAddComplete,
  VALUATION_COMMENT_ADD
} from '../actions/valuation-comment.actions';
import valuationCommentStateSelector from '../selectors/valuation-comment.selector';
import { handleStandardExceptions } from 'src/sagas/helpers';
import * as logger from 'src/logger';

export function* loadValuationCommentDetails({
  orderId,
  tok
}: {
  orderId: string,
  tok: string
}): Saga<void> {
  yield put(resetValuationComments());

  try {
    // Call the API to get the order details
    const client = new Client(tok);
    const response = yield call(
      [client, client.getPartnerValuationOrderComments],
      orderId
    );
    yield put(
      valuationCommentLoadComplete(
        orderId,
        {
          address: response.address,
          unit: response.unit,
          city: response.city,
          state: response.state,
          zipcode: response.zipcode,
          comments: response.comments.map((comment) => ({
            id: comment.id,
            comment: comment.comment,
            isAmcComment: comment.is_amc_comment,
            isActionRequired: comment.is_action_required,
            createdAt: new Date(comment.created_at)
          }))
        },
        tok
      )
    );
  } catch (e) {
    if (e.name === 'OrderNotAvailableError') {
      yield put(
        valuationCommentLoadError(
          'This order is not available for review at this time.  The most likely cause of this is that the order has already been reviewed.  Please confirm you are viewing the correct order.'
        )
      );
    } else {
      yield call(handleStandardExceptions, e);
      yield put(valuationCommentLoadError(e.message));
    }
  }
}

export function* addComment(action: ValuationCommentAddAction): Saga<void> {
  const state = yield select(valuationCommentStateSelector);
  if (state.status !== 'loaded') {
    logger.logException(
      new Error(`Cannot add a comment a valuation in state ${state.status}`)
    );
    return;
  }
  const client = new Client(state.token);
  try {
    yield call(
      [client, client.submitPartnerValuationOrderComment],
      state.orderId,
      action.payload.comment,
      action.payload.actionRequired
    );
    yield put(valuationCommentAddComplete(true));
    yield call(loadValuationCommentDetails, {
      orderId: state.orderId,
      tok: state.token
    });
  } catch (e) {
    logger.logException(`Error adding comment ${e}`);
    yield put(valuationCommentAddComplete(false));
  }
}

export function* addCommentSaga(): Saga<void> {
  yield takeEvery(VALUATION_COMMENT_ADD, addComment);
}
