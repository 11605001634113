// @flow
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import type { ViewState } from './view.reducer';
import type { UserState } from './user.reducer';
import type { ClientState } from 'src/client/reducers';
import type { PartnerState } from 'src/partner/reducers';
import type { HistoryState } from './history.reducer';
import { type AdminState } from 'src/admin/reducers/index';

import { viewReducer } from './view.reducer';
import userReducer from './user.reducer';
import clientReducer from 'src/client/reducers';
import partnerReducer from 'src/partner/reducers';
import adminReducer from 'src/admin/reducers';
import historyReducer from './history.reducer';
import clearReducerOnLogout from './clear-reducer-on-logout';

export type State = {
  view: ViewState,
  user: UserState,
  client: ClientState,
  partner: PartnerState,
  admin: AdminState,
  form: Function,
  history: HistoryState
};

const rootReducer = combineReducers({
  view: viewReducer,
  user: userReducer,
  client: clientReducer,
  partner: partnerReducer,
  admin: adminReducer,
  form: formReducer,
  history: historyReducer
});

export default clearReducerOnLogout(rootReducer);
