// @flow
import React, { type ComponentType } from 'react';
import { AppUserContext } from './context';
import { type UserContext, type NullUserContext } from '../../types';

export const withUserContext = (Component: ComponentType<any>) => {
  return (props: any) => {
    return (
      <AppUserContext.Consumer>
        {(userContext: UserContext | NullUserContext) => {
          return <Component userContext={userContext} {...props} />;
        }}
      </AppUserContext.Consumer>
    );
  };
};
