// @flow
import type { File } from '../types/add-order';
import type { SupportingDocument } from '../types/supporting-documents';

export const UPLOAD_SUPPORTING_DOCUMENTS = 'UPLOAD_SUPPORTING_DOCUMENTS';

export type OnUploadDocumentSuccessCallback = (
  document: SupportingDocument
) => void;

export type OnUploadDocumentErrrorCallback = (
  fileName: string,
  error: string
) => void;

export type UploadSupportingDocumentsAction = {
  type: 'UPLOAD_SUPPORTING_DOCUMENTS',
  payload: {
    documentFiles: File[],
    onDocumentSuccess: OnUploadDocumentSuccessCallback,
    onDocumentError: OnUploadDocumentErrrorCallback
  }
};

export type SupportingDocumentsAction = UploadSupportingDocumentsAction;

export const uploadSupportingDocuments = (
  documentFiles: File[],
  onDocumentSuccess: OnUploadDocumentSuccessCallback,
  onDocumentError: OnUploadDocumentErrrorCallback
): UploadSupportingDocumentsAction => ({
  type: UPLOAD_SUPPORTING_DOCUMENTS,
  payload: {
    documentFiles,
    onDocumentSuccess,
    onDocumentError
  }
});
