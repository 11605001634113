// @flow
import React, { type Element, type ElementRef } from 'react';
import classNames from 'classnames';
import { themr } from 'react-css-themr';

import defaultTheme from './Popover.css';

type PopoverProps = {
  theme: {
    popoverLink: string,
    popoverContent: string,
    open: string
  },
  PopoverLinkComponent: Element<any>,
  PopoverContentComponent: Element<any>,
  onPopoverShow?: () => void
};

type PopoverState = {
  show: boolean,
  popoverLeftOffset: number
};

class Popover extends React.Component<PopoverProps, PopoverState> {
  state = {
    show: false,
    popoverLeftOffset: 0
  };

  containerEl: ?ElementRef<'div'>;

  popoverEl: ?ElementRef<'div'>;

  handleToggleShow = () => {
    const show = !this.state.show;
    this.setState({ show });

    if (show && this.props.onPopoverShow) {
      this.props.onPopoverShow();
    }
  };

  handleClick = (e: window.Event) => {
    e.stopPropagation();
    e.preventDefault();
  };

  componentDidMount() {
    if (this.popoverEl != null && this.containerEl != null) {
      const popoverEl = this.popoverEl;
      const containerEl = this.containerEl;
      const popoverRect = popoverEl.getBoundingClientRect();
      const containerRect = containerEl.getBoundingClientRect();
      this.setState({
        popoverLeftOffset: 0 - (popoverRect.width / 2 - containerRect.width / 2)
      });
    }
  }

  render() {
    const { show } = this.state;
    const { theme, PopoverLinkComponent, PopoverContentComponent } = this.props;

    return (
      <div
        className={theme.popoverLink}
        ref={(containerEl) => {
          this.containerEl = containerEl;
        }}
        onMouseEnter={this.handleToggleShow}
        onMouseLeave={this.handleToggleShow}
        onClick={this.handleClick}
      >
        {show
          ? React.cloneElement(PopoverLinkComponent, { active: 'true' })
          : PopoverLinkComponent}

        <div
          ref={(popoverEl) => {
            this.popoverEl = popoverEl;
          }}
          style={{ left: this.state.popoverLeftOffset }}
          className={classNames(theme.popoverContent, {
            [theme.open]: show
          })}
        >
          {PopoverContentComponent}
        </div>
      </div>
    );
  }
}

const ThemedPopover = themr('ThemedPopover', defaultTheme)(Popover);
export default ThemedPopover;
