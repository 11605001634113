// @flow
import { combineReducers } from 'redux';

import { adminUserReducer, type AdminUserState } from './admin-user.reducer';
import {
  workflowWorkersReducer,
  type WorkflowWorkersState
} from './workflow-workers.reducer';
import {
  workflowExternalTasksReducer,
  type WorkflowExternalTasksState
} from './workflow-external-tasks.reducer';
import {
  workflowAutoRefreshReducer,
  type WorkflowAutoRefreshState
} from './workflow-auto-refresh.reducer';

export type AdminState = {
  user: AdminUserState,
  camunda: {
    workers: WorkflowWorkersState,
    tasks: WorkflowExternalTasksState,
    autoRefresh: WorkflowAutoRefreshState
  }
};

const adminReducer = combineReducers({
  user: adminUserReducer,
  camunda: combineReducers({
    workers: workflowWorkersReducer,
    tasks: workflowExternalTasksReducer,
    autoRefresh: workflowAutoRefreshReducer
  })
});

export default adminReducer;
