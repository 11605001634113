// @flow
import React from 'react';

import { reduxForm, Field } from 'redux-form';

import { FileSelectButton } from '../orders/components/add-order-form/FileSelectButton';
import requiredValidator from './required-validator';
import SampleBulkFile from '../orders/components/add-order-form/SampleBulkFile.jsx';
import { ADD_ORDER_FORM_NAME } from '../constants/add-order';
import house from '../../icons/house.svg';
import excel from '../../icons/excel.svg';
import { Button } from '@hc/component-lib/lib';
import type { OrderTypeDescriptor } from '../types/order';

import styles from '../orders/components/add-order-form/add-order-form.css';

type AddressUploadProps = {
  updateStep: (number) => void,
  change: Function,
  shouldSubmit?: boolean,
  onSubmit: (
    e: any,
    newValues: { itemsSource: string, orderFile: any }
  ) => void,
  orderTypes: OrderTypeDescriptor[]
};

type AddressUploadState = {
  orderFile: any
};

class AddOrderAddressUploadFormContent extends React.Component<
  AddressUploadProps,
  AddressUploadState
> {
  STEP_NUM = 4;

  state = {
    orderFile: null
  };

  constructor(props) {
    super(props);
    const { orderTypes, change } = props;
    if (
      orderTypes &&
      orderTypes.length === 1 &&
      orderTypes[0].deliverySpeeds.length < 2
    ) {
      change('orderType', orderTypes[0].id);
    }
  }

  handleManualClick = () => {
    const { change, updateStep } = this.props;
    change('itemsSource', 'entry');
    updateStep(this.STEP_NUM);
  };

  handleBulkClick = () => {
    const { change, updateStep, shouldSubmit } = this.props;
    change('itemsSource', 'csv');
    if (shouldSubmit) {
      return false;
    } else {
      updateStep(this.STEP_NUM + 1);
    }
  };

  handleChange = (e, newValues) => {
    const { shouldSubmit } = this.props;
    if (shouldSubmit) {
      this.setState({ orderFile: newValues });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { orderFile } = this.state;
    onSubmit(e, { itemsSource: 'csv', orderFile: orderFile });
  };

  render() {
    const { orderFile } = this.state;
    const { shouldSubmit } = this.props;
    return (
      <div>
        <h3>Add Addresses</h3>
        <div className={styles.uploadContainer} data-hc-name="manual-upload">
          <div
            className={`${styles.uploadItem} ${styles.uploadManual}`}
            onClick={this.handleManualClick}
          >
            <div className={styles.uploadContent}>
              <img src={house} style={{ width: 70 }} />
              <h4
                className={styles.uploadTitle}
                data-hc-name="manual-upload-name"
              >
                Enter Manually
              </h4>
            </div>
          </div>
          <div className={styles.orCircle}>or</div>
          <div
            className={`${styles.uploadItem} ${styles.uploadManual}`}
            data-hc-name="bulk-upload"
          >
            <div className={styles.uploadContent}>
              <img src={excel} style={{ width: 70 }} />
              <h4
                className={styles.uploadTitle}
                data-hc-name="bulk-upload-name"
              >
                Upload Bulk File
              </h4>
              <div
                className={styles.uploadDescription}
                data-hc-name="bulk-upload-description"
              >
                csv or xlsx
              </div>
              <form>
                <Field
                  data-hc-name="upload-file-link"
                  name="orderFile"
                  component={FileSelectButton}
                  validate={[requiredValidator]}
                  onChange={this.handleChange}
                  props={{
                    handleBulkClick: this.handleBulkClick,
                    className: styles.uploadFileButton
                  }}
                />
              </form>
            </div>
          </div>
        </div>
        <div className={styles.sampleBulkFile}>
          <SampleBulkFile />
        </div>
        {shouldSubmit && (
          <form
            onSubmit={this.handleSubmit}
            className={styles.addressUploadSubmit}
          >
            <Button
              data-hc-name="continue-button"
              type="submit"
              disabled={!orderFile}
            >
              Submit Order
            </Button>
          </form>
        )}
      </div>
    );
  }
}

const AddOrderAddressUploadForm = reduxForm({
  form: ADD_ORDER_FORM_NAME,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
  forceUnregisterOnUnmount: true,
  initialValues: {
    items: [{ address: null, propertyType: null }]
  }
})(AddOrderAddressUploadFormContent);

export default AddOrderAddressUploadForm;
