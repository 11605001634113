// @flow
import * as React from 'react';
import {
  RTThemedCard,
  RTThemedCardText,
  RTThemedCardTitle,
  RTThemedButton,
  RTThemedSwitch,
  RTThemedInput
} from '@hc/component-lib/lib/rt-themed';

import type { ReviewValuationLoadedState } from 'src/partner/reducers/review-valuation.reducer';

type ReviewValuationFormProps = {
  reviewValuationState: ReviewValuationLoadedState,
  onReviewAccept: () => void,
  onReviewReject: (message: string) => void
};

type ReviewValuationFormState = {
  acceptChecked: boolean,
  message: string
};

declare var Intl: any;

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

class ReviewValuationForm extends React.Component<
  ReviewValuationFormProps,
  ReviewValuationFormState
> {
  constructor(props: ReviewValuationFormProps) {
    super(props);
    this.state = {
      acceptChecked: true,
      message: ''
    };
  }

  handleAcceptCheckedChanged = (value: boolean) => {
    this.setState({
      acceptChecked: value
    });
  };

  handleMessageChanged = (value: string) => {
    this.setState({
      message: value
    });
  };

  handleSubmit = () => {
    if (this.state.acceptChecked) {
      this.props.onReviewAccept();
    } else {
      this.props.onReviewReject(this.state.message);
    }
  };

  render() {
    const { reviewValuationState } = this.props;

    var body;
    if (reviewValuationState.actionStatus === 'success') {
      body = (
        <RTThemedCardText>
          <p>
            Your {this.state.acceptChecked ? 'approval' : 'rejection'} of this
            report has been recorded.
          </p>
          <p>
            {this.state.acceptChecked
              ? 'No further action is required at this time'
              : 'The appraiser will now need to submit a revised report.  Your review will be requested once the revised report is available.'}
          </p>
          <p>You may now close this window.</p>
        </RTThemedCardText>
      );
    } else {
      const submitDisabled =
        reviewValuationState.actionStatus === 'pending' ||
        (!this.state.acceptChecked && this.state.message.trim().length === 0);
      body = (
        <RTThemedCardText>
          <div style={{ margin: 'auto', width: '250px' }}>
            <RTThemedButton
              href={reviewValuationState.details.valuationReport}
              raised
              primary
              target="_blank"
            >
              Click here to view report
            </RTThemedButton>
          </div>
          <div
            style={{
              marginTop: '20px',
              borderTop: '1px solid grey',
              paddingTop: '20px'
            }}
          >
            <RTThemedSwitch
              checked={this.state.acceptChecked}
              label={
                this.state.acceptChecked
                  ? 'This valuation report is clear, complete and accurate'
                  : 'This valuation report requires revision'
              }
              onChange={this.handleAcceptCheckedChanged}
            />
            {!this.state.acceptChecked ? (
              <div>
                <RTThemedInput
                  type="text"
                  multiline
                  required
                  label="Rejection Comment"
                  maxLength={4000}
                  value={this.state.message}
                  onChange={this.handleMessageChanged}
                />
              </div>
            ) : null}
            <RTThemedButton
              onClick={this.handleSubmit}
              disabled={submitDisabled}
            >
              {this.state.acceptChecked ? 'Approve' : 'Reject'}
            </RTThemedButton>
            {reviewValuationState.actionStatus === 'failure'
              ? 'Update failed, please try again.'
              : null}
          </div>
        </RTThemedCardText>
      );
    }

    var addressLine = reviewValuationState.details.address;
    if (
      reviewValuationState.details.unit &&
      reviewValuationState.details.unit.length
    ) {
      addressLine += ' ' + reviewValuationState.details.unit;
    }
    addressLine += ', ' + reviewValuationState.details.city;
    addressLine += ', ' + reviewValuationState.details.state;
    addressLine += ' ' + reviewValuationState.details.zipcode;
    return (
      <div style={{ width: '100%' }}>
        <RTThemedCard
          raised
          style={{ width: '25%', margin: 'auto', minWidth: '600px' }}
        >
          <RTThemedCardTitle
            title="Review Order"
            subtitle={
              <div>
                <div>{addressLine}</div>
                <div>
                  {reviewValuationState.details.valuationDate.toLocaleDateString()}{' '}
                  |{' '}
                  {currencyFormatter.format(reviewValuationState.details.value)}
                </div>
              </div>
            }
          />
          {body}
        </RTThemedCard>
      </div>
    );
  }
}

export default ReviewValuationForm;
