// @flow
import React from 'react';
import { Link } from '../hyperlink';
import { themr } from 'react-css-themr';
import termsLinkTheme from './TermsLink.scss';

export type Props = {
  termsUrl: string,
  privacyUrl: string,
  target: string,
  theme: {
    TermsLink: string,
    ...any
  }
};

export const TermsLink = ({
  theme,
  termsUrl,
  privacyUrl,
  target,
  ...props
}: Props) => {
  return (
    <div className={theme.TermsLink} {...props}>
      By registering, I agree to HouseCanary's{' '}
      <Link
        data-hc-name="termsofuse-link"
        href={termsUrl}
        target={target}
        theme={theme}
      >
        Terms of Use
      </Link>{' '}
      and{' '}
      <Link
        data-hc-name="privacy-link"
        href={privacyUrl}
        target={target}
        theme={theme}
      >
        Privacy Policy
      </Link>
    </div>
  );
};

export const ThemedTermsLink = themr(
  'ThemedTermsLink',
  termsLinkTheme
)(TermsLink);

export default ThemedTermsLink;
