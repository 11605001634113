// @flow
import {
  INIT_ORDER_ITEM_GROUP,
  SELECT_ORDER_ITEM_GROUP,
  TOGGLE_ITEM_SELECT,
  TOGGLE_ALL_ITEMS_SELECT,
  MOVE_ITEMS_COMPLETE,
  ORDER_ITEMS_LOAD_SUCCESS
} from '../actions/order-items.actions';
import {
  NEED_REVIEW_GROUP,
  REMOVED_GROUP,
  IN_ORDER_GROUP
} from '../constants/order-items';

import type { Action } from '../actions';
import type { OrderItemGroup, OrderItem } from '../types/order-item';

export type OrderItemsState = {
  orderItemGroup: OrderItemGroup,
  selectedItemsByGroup: { [key: OrderItemGroup]: string[] }
};

const defaultState = {
  orderItemGroup: IN_ORDER_GROUP,
  selectedItemsByGroup: {
    [IN_ORDER_GROUP]: [],
    [NEED_REVIEW_GROUP]: [],
    [REMOVED_GROUP]: []
  }
};

export function orderItemsReducer(
  state: OrderItemsState = defaultState,
  action: Action
): OrderItemsState {
  switch (action.type) {
    case INIT_ORDER_ITEM_GROUP:
    case SELECT_ORDER_ITEM_GROUP: {
      return action.payload.orderItemGroup === state.orderItemGroup
        ? state
        : {
            orderItemGroup: action.payload.orderItemGroup,
            selectedItemsByGroup: state.selectedItemsByGroup
          };
    }
    case TOGGLE_ITEM_SELECT: {
      const { orderItemGroup, orderItemId } = action.payload;
      const currentlySelectedIds: string[] =
        state.selectedItemsByGroup[orderItemGroup];
      return {
        orderItemGroup: state.orderItemGroup,
        selectedItemsByGroup: {
          ...state.selectedItemsByGroup,
          [orderItemGroup]:
            currentlySelectedIds.indexOf(orderItemId) === -1
              ? [...currentlySelectedIds, orderItemId]
              : currentlySelectedIds.filter((id: string) => id !== orderItemId)
        }
      };
    }
    case TOGGLE_ALL_ITEMS_SELECT: {
      const { orderItemGroup, orderItemIds, toggleOn } = action.payload;
      return {
        orderItemGroup: state.orderItemGroup,
        selectedItemsByGroup: {
          ...state.selectedItemsByGroup,
          [orderItemGroup]: toggleOn ? [...orderItemIds] : []
        }
      };
    }
    // remove those items that were successfully moved from the selected list
    case MOVE_ITEMS_COMPLETE: {
      const { orderItemGroup, succeededItemIds } = action.payload;
      const currentlySelectedIds: string[] =
        state.selectedItemsByGroup[orderItemGroup];
      return {
        orderItemGroup: state.orderItemGroup,
        selectedItemsByGroup: {
          ...state.selectedItemsByGroup,
          [orderItemGroup]: currentlySelectedIds.filter(
            (id) => succeededItemIds.indexOf(id) === -1
          )
        }
      };
    }
    // prune selected items no longer in the list
    case ORDER_ITEMS_LOAD_SUCCESS: {
      const { orderItemGroup, orderItems } = action.payload;
      if (orderItemGroup === IN_ORDER_GROUP) {
        return state;
      }
      const orderItemIds = orderItems.map(
        (orderItem: OrderItem) => orderItem.id
      );
      const currentlySelectedIds: string[] =
        state.selectedItemsByGroup[orderItemGroup];
      return {
        orderItemGroup: state.orderItemGroup,
        selectedItemsByGroup: {
          ...state.selectedItemsByGroup,
          [orderItemGroup]: currentlySelectedIds.filter(
            (id: string) => orderItemIds.indexOf(id) !== -1
          )
        }
      };
    }
    default: {
      return state;
    }
  }
}
