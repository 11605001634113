// @flow
import type { AddOrderFields, GroupKey } from 'src/client/types/add-order';
import type { OrderTypeDescriptor } from 'src/client/types/order';

export const ADD_ORDER = 'ADD_ORDER';
export const ADD_ORDER_ERROR = 'ADD_ORDER_ERROR';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const SHOW_ADD_ORDER_DIALOG = 'SHOW_ADD_ORDER_DIALOG';
export const HIDE_ADD_ORDER_DIALOG = 'HIDE_ADD_ORDER_DIALOG';
export const TOGGLE_ADD_ORDER_CONFIRMATION = 'TOGGLE_ADD_ORDER_CONFIRMATION';
export const LOAD_ORDER_TYPE_DESCRIPTORS_SUCCESS =
  'LOAD_ORDER_TYPE_DESCRIPTORS_SUCCESS';
export const LOAD_ORDER_TYPE_DESCRIPTORS_ERROR =
  'LOAD_ORDER_TYPE_DESCRIPTORS_ERROR';

export type AddOrderActionType = {
  type: 'ADD_ORDER',
  payload: { values: AddOrderFields },
  meta?: { onSuccess?: () => void, onError?: () => void }
};
export type AddOrderErrorAction = {
  type: 'ADD_ORDER_ERROR',
  payload: { errorMessage: string, validationErrors: ?{ [key: string]: any } }
};
export type AddOrderSuccessAction = { type: 'ADD_ORDER_SUCCESS' };
export type ShowAddOrderDialogAction = {
  type: 'SHOW_ADD_ORDER_DIALOG',
  payload: { createProduct: ?GroupKey }
};
export type HideAddOrderDialogAction = { type: 'HIDE_ADD_ORDER_DIALOG' };
export type ToggleAddOrderConfirmationAction = {
  type: 'TOGGLE_ADD_ORDER_CONFIRMATION',
  payload: { showConfirmation: boolean, confirmationType: 'toast' | 'dialog' }
};
export type LoadOrderTypeDescriptorsSuccessAction = {
  type: 'LOAD_ORDER_TYPE_DESCRIPTORS_SUCCESS',
  payload: { orderTypeDescriptors: OrderTypeDescriptor[] }
};
export type LoadOrderTypeDescriptorsErrorAction = {
  type: 'LOAD_ORDER_TYPE_DESCRIPTORS_ERROR',
  payload: { errorMessage: string }
};

export type AddOrderAction =
  | AddOrderActionType
  | AddOrderErrorAction
  | AddOrderSuccessAction
  | ShowAddOrderDialogAction
  | HideAddOrderDialogAction
  | ToggleAddOrderConfirmationAction
  | LoadOrderTypeDescriptorsSuccessAction
  | LoadOrderTypeDescriptorsErrorAction;

export const addOrder = (values: AddOrderFields): AddOrderActionType => ({
  type: ADD_ORDER,
  payload: { values }
});
export const addOrderError = (
  errorMessage: string,
  validationErrors: ?{ [key: string]: any }
): AddOrderErrorAction => ({
  type: ADD_ORDER_ERROR,
  payload: { errorMessage, validationErrors }
});
export const addOrderSuccess = (): AddOrderSuccessAction => ({
  type: ADD_ORDER_SUCCESS
});
export const showAddOrderDialog = (
  createProduct: ?GroupKey
): ShowAddOrderDialogAction => ({
  type: SHOW_ADD_ORDER_DIALOG,
  payload: { createProduct }
});
export const hideAddOrderDialog = (): HideAddOrderDialogAction => ({
  type: HIDE_ADD_ORDER_DIALOG
});
export const toggleAddOrderConfirmation = (
  showConfirmation: boolean,
  confirmationType: 'toast' | 'dialog'
): ToggleAddOrderConfirmationAction => ({
  type: TOGGLE_ADD_ORDER_CONFIRMATION,
  payload: { showConfirmation, confirmationType }
});
export const loadOrderTypeDescriptorsSuccess = (
  orderTypeDescriptors: OrderTypeDescriptor[]
): LoadOrderTypeDescriptorsSuccessAction => ({
  type: LOAD_ORDER_TYPE_DESCRIPTORS_SUCCESS,
  payload: { orderTypeDescriptors }
});
export const loadOrderTypeDescriptorsError = (
  errorMessage: string
): LoadOrderTypeDescriptorsErrorAction => ({
  type: LOAD_ORDER_TYPE_DESCRIPTORS_ERROR,
  payload: { errorMessage }
});
