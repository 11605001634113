// @flow
import type { State } from 'src/reducers';
import type { UserState } from 'src/reducers/user.reducer';
import userStateSelector from './user-state.selector';

const userTokenSelector = (state: State): ?string => {
  const userState: UserState = userStateSelector(state);
  if (userState.userDetails.status === 'loaded') {
    return userState.userDetails.userDetails.token;
  }
  return null;
};
export default userTokenSelector;
