// @flow
import type { Action } from 'src/client/actions';
import type { OrderProgress } from 'src/client/types/order';

import {
  FETCH_ORDER_PROGRESS,
  FETCH_ORDER_PROGRESS_SUCCESS,
  FETCH_ORDER_PROGRESS_ERROR
} from 'src/client/actions/orders.actions';

type OrderProgressStateNotLoaded = {
  status: 'not_loaded',
  loading: false
};
type OrderProgressStateLoading = {
  status: 'loading',
  orderId: string,
  loading: true
};
type OrderProgressStateLoaded = {
  status: 'loaded',
  loading: false,
  orderId: string,
  orderProgress: OrderProgress
};
type OrderProgressStateError = {
  status: 'error',
  loading: false,
  orderId: string,
  errorMessage: string
};
export type OrderProgressState =
  | OrderProgressStateNotLoaded
  | OrderProgressStateLoading
  | OrderProgressStateLoaded
  | OrderProgressStateError;

const defaultState = {
  status: 'not_loaded',
  loading: false
};

export function orderProgressReducer(
  state: OrderProgressState = defaultState,
  action: Action
): OrderProgressState {
  switch (action.type) {
    case FETCH_ORDER_PROGRESS: {
      const { order } = action.payload;
      return {
        status: 'loading',
        orderId: order.id,
        loading: true
      };
    }
    case FETCH_ORDER_PROGRESS_SUCCESS: {
      const { orderId, progress } = action.payload;

      return {
        status: 'loaded',
        loading: false,
        orderId,
        orderProgress: progress
      };
    }
    case FETCH_ORDER_PROGRESS_ERROR: {
      const { orderId, error } = action.payload;
      return {
        status: 'error',
        loading: false,
        orderId,
        errorMessage: error
      };
    }
    default: {
      return state;
    }
  }
}
