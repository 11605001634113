// @flow
import React from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import Drawer from 'react-toolbox/lib/drawer';
import Dialog from 'react-toolbox/lib/dialog';

import { type UserContext, type TrackerConfigurationType } from '../../../../types';

import { AlertIcon, AnnouncementIcon } from '../../../../icons';
import { withTracker } from '../../Tracker';
import NotificationsDrawerContent from './NotificationsDrawerContent';
import PlatformAnnouncementModalContent from './notification-models/PlatformAnnouncementModalContent';
import {
  NAVIGATION_BAR_PREFIX,
  NAVIGATION_BAR_EVENTS
} from '../../constants/events.constants';
import {
  EHRMANTRAUT,
  LOGIN_SERVICE
} from '../../constants/products.constants';
import { getEnvUrl } from '../../constants/url.constants';
import { getAuthClient } from '../../../../clients/auth-api-client';

import defaultTheme from './NotificationsMenu.scss';
import { NAV_BAR_HC_NAMES } from '../../hc.names';
// import { NAV_BAR_HC_NAMES } from '../../hc.names';

export type NotificationsMenuThemeType = {
  NotificationsMenu: string,
  Trigger: string,
  mobile: string
}

export type NotificationsOptionsType = {
  onDrawerToggle: (boolean) => void
};

export type NotificationsMenuProps = {
  className?: string,
  theme: NotificationsMenuThemeType,
  env?: 'DEV' | 'STAGE' | 'PROD',
  appUserContext: UserContext,
  updateContextCallback: (userContext: UserContext) => void;
  showAnnouncementOnInit?: boolean,
  notificationsOptions: NotificationsOptionsType,
  tracking: TrackerConfigurationType
}

export type NotificationsMenuState = {
  active: boolean,
  platformActive: boolean,
  platformMessageId: ?number,
  notifications: any[]
}

const AlertIconWithTracker = withTracker(AlertIcon);

class NotificationsMenu extends React.Component <NotificationsMenuProps, NotificationsMenuState> {
  state = {
    active: false,
    platformActive: false,
    platformMessageId: null,
    notifications: []
  }

  static defaultProps = {
    showAnnouncementOnInit: true,
    notificationsOptions: {
      onDrawerToggle: () => {}
    }
  }

  componentDidMount () {
    this.fetchMessageNotifications().then(notifications =>
      this.setState({ notifications })
    );
  }

  fetchMessageNotifications = () => {
    const { env, appUserContext, showAnnouncementOnInit, updateContextCallback } = this.props;
    const apiUrl = getEnvUrl(env, EHRMANTRAUT);
    const loginServiceUrl = getEnvUrl(env, LOGIN_SERVICE);
    const client = getAuthClient(loginServiceUrl, `${apiUrl}/api/v1/`, updateContextCallback);

    return client.getMessages(appUserContext.validity.token)
      .then(notifications => {
        const notification = notifications
          .find(notification => notification.message_type === 'PlatformAnnouncement');

        const formattedNotifications = (() => {
          if (notification) {
            const seen = notification.seen_marks.length > 0;
            const seenOn = seen &&
              notification.seen_marks[0] &&
              notification.seen_marks[0].created_on &&
              format(parseISO(notification.seen_marks[0].created_on), 'MM/dd/yyyy');

            if (showAnnouncementOnInit && !seen) {
              this.setState({
                platformActive: true, // show notification if has not been seen
                platformMessageId: notification.id
              });
            }

            return [{
              icon: <AnnouncementIcon />,
              title: notification.subject,
              description: notification.body,
              seenOn,
              seen,
              onClick: this.handleNotificationClick(notification.id)
            }];
          } else {
            return [];
          }
        })();

        return formattedNotifications;
      })
      .catch((e) => {
        console.warn(`Error fetching notifications - message: ${e.message}`);
        // return empty array of notifications
        return [];
      });
  }

  updateMessageNotification = (messageId) => {
    const { env, appUserContext, updateContextCallback } = this.props;
    const apiUrl = getEnvUrl(env, EHRMANTRAUT);
    const loginServiceUrl = getEnvUrl(env, LOGIN_SERVICE);
    const client = getAuthClient(loginServiceUrl, `${apiUrl}/api/v1/`, updateContextCallback);
    return client.setMessageAsRead(appUserContext.validity.token, messageId);
  };

  handleNotificationClick = (messageId) => {
    return () => {
      this.setState({ platformActive: true, platformMessageId: messageId });
    };
  };

  handleToggleDrawer = () => {
    const active = !this.state.active;
    this.props.notificationsOptions.onDrawerToggle(active);
    this.setState({ active });
  };

  handleClosePlatformAnnouncement = () => {
    const { platformMessageId } = this.state;

    if (platformMessageId) {
      this.updateMessageNotification(platformMessageId);
    }
    this.setState({ platformActive: false, platformMessageId: null });
  };

  render () {
    const { theme, tracking } = this.props;

    const { active, platformActive, notifications } = this.state;

    return (
      <React.Fragment>
        <div className={classNames(theme.NotificationsMenu, theme.Trigger)}>
          <AlertIconWithTracker
            data-hc-name={NAV_BAR_HC_NAMES.HC_NOTIFICATIONS_MENU}
            type={NAVIGATION_BAR_PREFIX}
            event={NAVIGATION_BAR_EVENTS.NOTIFICATION_ICON_CLICK}
            onClick={this.handleToggleDrawer}
          />
        </div>

        <Drawer
          theme={theme}
          type={'right'}
          onOverlayClick={this.handleToggleDrawer}
          active={active}
        >
          <NotificationsDrawerContent
            tracking={tracking}
            theme={theme}
            notifications={notifications}
            onCloseClick={this.handleToggleDrawer}
          />
        </Drawer>

        <Dialog
          theme={theme}
          active={platformActive}
          onEscKeyDown={this.handleClosePlatformAnnouncement}
          onOverlayMouseUp={this.handleClosePlatformAnnouncement}>
          <PlatformAnnouncementModalContent onClose={this.handleClosePlatformAnnouncement} />
        </Dialog>

      </React.Fragment>
    );
  }
}

export default themr('NotificationsMenuThemed', defaultTheme)(NotificationsMenu);
