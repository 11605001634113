// @flow
import {
  type WorkflowExternalTask,
  type ExternalTasksFilters,
  type ExternalTaskFilterOptions,
  type WorkflowPaginationWithCount
} from '../types/workflow';

import {
  type WorkflowWorkersLoadSuccessAction,
  WORKFLOW_WORKERS_LOAD_SUCCESS
} from '../actions/workflow-workers.actions';

import {
  type WorkflowExternalTasksAction,
  WORKFLOW_WORKER_EXTERNAL_TASKS_LOAD,
  WORKFLOW_WORKER_EXTERNAL_TASKS_SUCCESS,
  WORKFLOW_WORKER_EXTERNAL_TASKS_ERROR,
  WORKFLOW_WORKER_EXTERNAL_TASK_DETAIL,
  WORKFLOW_PAGINATION_FIRST_PAGE,
  WORKFLOW_PAGINATION_PREVIOUS_PAGE,
  WORKFLOW_PAGINATION_NEXT_PAGE,
  WORKFLOW_PAGINATION_LAST_PAGE
} from '../actions/workflow-external-tasks.actions';

export type WorkflowExternalTasksState = {
  status: 'INIT' | 'LOADING' | 'SUCCESS' | 'ERROR',
  filters: ExternalTasksFilters,
  filterOptions: ExternalTaskFilterOptions,
  tasks: WorkflowExternalTask[],
  detail: ?WorkflowExternalTask,
  pagination: WorkflowPaginationWithCount,
  errorReason: ?string
};

const defaultState = {
  status: 'INIT',
  tasks: [],
  filters: {
    showErrors: false,
    sortBy: 'lockExpirationTime',
    sortOrder: 'desc'
  },
  filterOptions: {
    topicSets: {},
    topicNames: [],
    workers: []
  },
  pagination: {
    count: null,
    maxResults: 20,
    firstResult: 0
  },
  detail: null,
  errorReason: null
};

export function workflowExternalTasksReducer(
  previousState: WorkflowExternalTasksState = defaultState,
  action: WorkflowExternalTasksAction | WorkflowWorkersLoadSuccessAction
): WorkflowExternalTasksState {
  switch (action.type) {
    case WORKFLOW_WORKERS_LOAD_SUCCESS: {
      const filterOptions: ExternalTaskFilterOptions =
        action.payload.workers.reduce(
          (filterOptions, worker) => {
            filterOptions.topicSets[worker.topic_set] = worker.topic_names;
            filterOptions.topicNames = [
              ...filterOptions.topicNames,
              ...worker.topic_names
            ];
            filterOptions.workers = [
              ...filterOptions.workers,
              { label: worker.topic_set, value: worker.worker_id }
            ];
            return filterOptions;
          },
          { topicSets: {}, topicNames: [], workers: [] }
        );

      const filters: ExternalTasksFilters =
        previousState.status === 'INIT'
          ? {
              ...previousState.filters,
              ...(filterOptions.topicNames.length > 0
                ? { topicName: filterOptions.topicNames[0] }
                : {})
            }
          : previousState.filters;

      return {
        ...previousState,
        filterOptions,
        filters
      };
    }
    case WORKFLOW_WORKER_EXTERNAL_TASKS_LOAD: {
      return {
        ...previousState,
        status: 'LOADING',
        filters: {
          ...previousState.filters,
          ...action.payload.params
        }
      };
    }

    case WORKFLOW_WORKER_EXTERNAL_TASKS_SUCCESS: {
      const tasks = previousState.filters.showErrors
        ? action.payload.tasks
        : action.payload.tasks.filter((task) => task.errorMessage === '');
      const tasksCount = previousState.filters.showErrors
        ? action.payload.count
        : tasks.length;

      return {
        ...previousState,
        status: 'SUCCESS',
        tasks,
        pagination: {
          ...previousState.pagination,
          count: tasksCount
        }
      };
    }

    case WORKFLOW_WORKER_EXTERNAL_TASK_DETAIL: {
      if (previousState.status === 'SUCCESS') {
        return {
          ...previousState,
          detail: action.payload.task
        };
      } else {
        return previousState;
      }
    }

    case WORKFLOW_WORKER_EXTERNAL_TASKS_ERROR: {
      return {
        ...previousState,
        status: 'ERROR',
        errorReason: action.payload.error
      };
    }

    case WORKFLOW_PAGINATION_FIRST_PAGE: {
      return {
        ...previousState,
        pagination: {
          ...previousState.pagination,
          firstResult: 0
        }
      };
    }

    case WORKFLOW_PAGINATION_PREVIOUS_PAGE: {
      const {
        pagination: { firstResult, maxResults }
      } = previousState;
      return {
        ...previousState,
        pagination: {
          ...previousState.pagination,
          firstResult:
            firstResult === 0 ? firstResult : firstResult - maxResults
        }
      };
    }

    case WORKFLOW_PAGINATION_NEXT_PAGE: {
      const {
        pagination: { firstResult, maxResults, count }
      } = previousState;
      return {
        ...previousState,
        pagination: {
          ...previousState.pagination,
          firstResult:
            count && count % (maxResults * (firstResult + 1)) > 0
              ? firstResult + maxResults + 1
              : firstResult
        }
      };
    }

    case WORKFLOW_PAGINATION_LAST_PAGE: {
      const {
        pagination: { firstResult, maxResults, count }
      } = previousState;
      return {
        ...previousState,
        pagination: {
          ...previousState.pagination,
          firstResult:
            count && count % (maxResults * (firstResult + 1)) > 0
              ? (() => {
                  /**
                   * Example:
                   * 25 (total items) / 20 (items per page) = 1.25 rounds to 2 pages
                   * 2 (pages) * 20 (items per page) = 40
                   * 40 - 20 + 1 = 21 first result index for second page
                   * */
                  const lastPage = Math.ceil(count / maxResults);
                  return lastPage * maxResults - maxResults + 1;
                })()
              : firstResult
        }
      };
    }

    default:
      return previousState;
  }
}
