// @flow
import type { Saga } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import store from 'store/dist/store.modern';

import routeTo from '../../history/route-to';
import { redirectToAdminLogin } from './helpers';
import { ADMIN_USER_STORE_KEY } from '../constants/user';

import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGOUT_REQUEST,
  loginSuccess,
  loginFailure,
  loginSetSuccessRoute
} from '../actions/admin-user.actions';
import * as logger from 'src/logger';

import { Client, LoginRequiredError } from '../api/admin-api-client';
import type { LoginRequestAction } from '../actions/admin-user.actions';
import type { User } from '../types/user';

type UserData = {
  email: string,
  first_name: string,
  last_name: string,
  token: string
};

export function* loadPermissionsStoreUser(userData: UserData): Saga<void> {
  const { first_name: firstName, last_name: lastName, ...rest } = userData;
  const user: User = {
    firstName,
    lastName,
    ...rest
  };
  yield put(loginSuccess(user));
}

export function* adminLogin(action: LoginRequestAction): Saga<void> {
  try {
    const { username, password } = action.payload;

    const client = new Client();
    const { token } = yield call([client, client.login], username, password);
    const userInfo = yield call([client, client.userDetails], token);
    const route = yield select((state) => state.admin.user.successRoute);
    const userData: UserData = { ...userInfo, token };
    yield call([store, store.set], ADMIN_USER_STORE_KEY, userData);
    yield call(loadPermissionsStoreUser, userData);
    if (route) {
      yield call(routeTo, route);
    } else {
      yield call(routeTo, '/admin/dashboard/camunda/workers');
    }
  } catch (e) {
    logger.logWarning(e, 'failed admin login');
    yield put(loginFailure());
  }
}

export function* adminLogout(): Saga<void> {
  yield call([store, store.remove], ADMIN_USER_STORE_KEY);
  yield call(redirectToAdminLogin);
}

export function* adminLoginSaga(): Saga<void> {
  yield takeEvery(ADMIN_LOGIN_REQUEST, adminLogin);
}

export function* adminLogoutSaga(): Saga<void> {
  yield takeEvery(ADMIN_LOGOUT_REQUEST, adminLogout);
}

export function* loadLoginSaga({ route }: { route?: ?string }): Saga<void> {
  route = route || null;
  yield put(loginSetSuccessRoute(route));
}

export function* resolveUserSaga(loginRequired: boolean): Saga<void> {
  const userData = yield call([store, store.get], ADMIN_USER_STORE_KEY);
  if (userData == null || !userData.token) {
    if (loginRequired) {
      throw new LoginRequiredError();
    }
    return;
  }
  yield call(loadPermissionsStoreUser, userData);
}
