// @flow
import React from 'react';
import {
  RTThemedDialog,
  RTThemedIconButton,
  RTThemedFontIcon
} from '@hc/component-lib/lib/rt-themed';

import RevisionRequestForm from './RevisionRequestForm';
import type { OrderItem } from 'src/client/types/order-item';

import styles from './RevisionRequestDialog.css';

type RevisionRequestDialogProps = {
  orderId: ?string,
  orderItem: ?OrderItem,
  onSubmit: (string, OrderItem, string) => void,
  onDismiss: () => void,
  show: boolean,
  loading: boolean,
  submitted: boolean,
  errors: ?(string[]),
  onShowMessages: (OrderItem) => void
};

type RevisionRequestDialogState = {
  errors: ?(string[]),
  submitted: boolean
};

class RevisionRequestDialog extends React.Component<
  RevisionRequestDialogProps,
  RevisionRequestDialogState
> {
  state = {
    errors: null,
    submitted: false
  };

  // bit of hackery to make the error stay showing even as the error is cleared on dismiss (with the rest of the state)
  UNSAFE_componentWillReceiveProps(nextProps: RevisionRequestDialogProps) {
    if (!(this.props.show === true && nextProps.show === false)) {
      this.setState({
        errors: nextProps.errors,
        submitted: nextProps.submitted
      });
    }
  }

  handleSubmit = (values: { revisionRequestExplanation: string }) => {
    if (this.props.orderId && this.props.orderItem) {
      this.props.onSubmit(
        this.props.orderId,
        this.props.orderItem,
        values.revisionRequestExplanation
      );
    }
  };

  showMessages = () => {
    const orderItem: ?OrderItem = this.props.orderItem;
    if (orderItem != null) {
      this.props.onDismiss();
      this.props.onShowMessages(orderItem);
    }
  };

  render() {
    const { onDismiss, show, loading } = this.props;
    const { errors, submitted } = this.state;

    return (
      <RTThemedDialog
        theme={submitted ? { ...styles, title: styles.disabledTitle } : styles}
        title="Revision Request"
        onEscKeyDown={onDismiss}
        active={show}
      >
        <RTThemedIconButton
          icon="close"
          className={styles.closeIcon}
          onClick={onDismiss}
        />

        <div>
          <div className={submitted ? styles.disabledText : null}>
            Please explain why you are requesting a revision from the appraiser.
            If you have a question that does not require a revision to the
            report, you can message the AMC{' '}
            <a href="javascript:void(0)" onClick={this.showMessages}>
              here
            </a>{' '}
            instead.
          </div>
          {submitted && (
            <div className={styles.submitted}>
              <div className={styles.submittedTextContainer}>
                <div className={styles.submittedTitle}>Request Sent</div>
              </div>
              <div>
                <RTThemedFontIcon value="check_circle" />
              </div>
            </div>
          )}
        </div>

        {!submitted && (
          <RevisionRequestForm
            errors={errors}
            loading={loading}
            onCancel={onDismiss}
            onSubmit={this.handleSubmit}
          />
        )}
      </RTThemedDialog>
    );
  }
}

export default RevisionRequestDialog;
