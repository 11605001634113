// @flow
import * as React from 'react';
import { Link as RTLink } from '@hc/component-lib/lib/rt-themed';

import styles from './Link.css';

type LinkProps = {
  icon?: string | React.Node,
  label: string,
  onClick?: () => void,
  className?: string
};

const Link = (props: LinkProps) => {
  const { icon, label, className } = props;
  return (
    <RTLink
      className={className}
      theme={styles}
      icon={icon}
      label={label}
      {...props}
    />
  );
};

export default Link;
