// @flow
import React, { PureComponent } from 'react';
import { orgLengthValidator } from '../../services/validator';
import CompanyField from './CompanyField';

/** Pass thorugh all props */
type Props = {};

/**
 * Standard text field with required validator
 */
export class SignupCompanyField extends PureComponent <Props> {
  render () {
    return <CompanyField validate={[orgLengthValidator]} {...this.props} />;
  }
}

export default SignupCompanyField;
