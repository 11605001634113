// @flow
/*
  This is a higher order component surrounding the comopnent-lib DataTable to handle Order Manager specific ordering requirements
*/
import * as React from 'react';
import { DataTable } from '@hc/component-lib';
import type {
  Col,
  Data
} from '@hc/component-lib/lib/components/organisms/data-table/types';

import type { OrderingParams } from 'src/types/list';

export type ColWithOrderingParams = Col & {
  customOnSortOrdering?: OrderingParams[], // custom ordering params if they will be different than just a asc or desc sort on the attribute (or sortAttribute)
  customOrderingIndex?: number // if the ordering param index is something other than 0 (i.e. the primary (aka first) ordering field is not the ordering field to toggle)
};

type DataTableWithOrderingProps = {
  data: Data,
  ordering: OrderingParams[],
  columns: ColWithOrderingParams[],
  loading: boolean,
  onSortColumn: (OrderingParams[]) => void,
  onRowClick: (any) => void
};

type DataTableWithOrderingPropsState = {
  columnMapBySortAttribute: { [sortAttribute: string]: ColWithOrderingParams },
  columns: ColWithOrderingParams[],
  sortAttr: string,
  sortOrder: 'asc' | 'desc'
};

export class DataTableWithOrdering extends React.Component<
  DataTableWithOrderingProps,
  DataTableWithOrderingPropsState
> {
  constructor(props: DataTableWithOrderingProps) {
    super(props);
    this.state = this.calculateState(props);
  }

  getColumnsMap = (columns: ColWithOrderingParams[]) => {
    return columns.reduce((accum, column: ColWithOrderingParams) => {
      if (column.sortAttribute) {
        accum[column.sortAttribute] = column;
      } else {
        accum[column.attribute] = column;
      }
      return accum;
    }, {});
  };

  getOrderingIndex(column: ColWithOrderingParams): number {
    return column.customOrderingIndex || 0;
  }

  calculateState = (
    props: DataTableWithOrderingProps
  ): DataTableWithOrderingPropsState => {
    const { ordering, columns } = props;
    // don't recreate the column map if the columns haven't changed
    const columnMapBySortAttribute =
      this.state && columns === this.state.columns
        ? this.state.columnMapBySortAttribute
        : this.getColumnsMap(columns);
    if (ordering.length > 0) {
      // find the column that matches the active ordering
      const activeColumnKey = Object.keys(columnMapBySortAttribute).find(
        (columnKey: string) => {
          const column = columnMapBySortAttribute[columnKey];
          const orderingIndex = this.getOrderingIndex(column);
          if (
            ordering[orderingIndex] &&
            ordering[orderingIndex].field === columnKey
          ) {
            return true;
          }
        }
      );
      if (activeColumnKey) {
        const activeColumn = columnMapBySortAttribute[activeColumnKey];
        const activeOrdering = ordering[this.getOrderingIndex(activeColumn)];
        return {
          columns,
          columnMapBySortAttribute,
          sortAttr: activeColumnKey,
          sortOrder: activeOrdering.asc ? 'asc' : 'desc'
        };
      }
    }
    return {
      columns,
      columnMapBySortAttribute,
      sortAttr: '',
      sortOrder: 'asc'
    };
  };

  componentDidUpdate(prevProps: DataTableWithOrderingProps) {
    if (
      this.props.columns !== prevProps.columns ||
      this.props.ordering !== prevProps.ordering
    ) {
      this.setState(this.calculateState(this.props));
    }
  }

  calculateNewSorting = (sortAttribute: string) => {
    const { columnMapBySortAttribute } = this.state;
    const column = columnMapBySortAttribute[sortAttribute];

    // if currently active
    if (this.state.sortAttr === sortAttribute) {
      // toggle sort order
      const newOrderingAsc = this.state.sortOrder !== 'asc';
      if (column.customOnSortOrdering) {
        const newOrdering = [...column.customOnSortOrdering];
        const orderingIndex = this.getOrderingIndex(column);
        newOrdering[orderingIndex] = {
          field: sortAttribute,
          asc: newOrderingAsc
        };
        return newOrdering;
      } else {
        return [
          {
            field: sortAttribute,
            asc: newOrderingAsc
          }
        ];
      }
    } else {
      // not currently active
      if (column.customOnSortOrdering) {
        return [...column.customOnSortOrdering];
      } else {
        return [
          {
            field: sortAttribute,
            asc: true
          }
        ];
      }
    }
  };

  handleSort = (sortAttribute: string) => {
    this.props.onSortColumn(this.calculateNewSorting(sortAttribute));
  };

  render() {
    return (
      <DataTable
        {...this.props}
        onSortColumn={this.handleSort}
        sortAttr={this.state.sortAttr}
        sortOrder={this.state.sortOrder}
      />
    );
  }
}
