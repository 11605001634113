// @flow
import React, { PureComponent } from 'react';
import { isRequiredValidator, nameLengthValidator } from '../../services/validator';
import NameField from './NameField';

/** Pass thorugh all props */
type Props = {};

/**
 * Standard text field with required validator
 */
export class SignupNameField extends PureComponent <Props> {
  render () {
    return <NameField validate={[isRequiredValidator, nameLengthValidator]} {...this.props} />;
  }
}

export default SignupNameField;
