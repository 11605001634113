import React from 'react';
import { connect } from 'react-redux';

import { RTThemedButton as Button } from '@hc/component-lib/hclib/rt-themed';
import { type WorkflowExternalTasksState } from '../reducers/workflow-external-tasks.reducer';
import { type WorkflowAutoRefreshState } from '../reducers/workflow-auto-refresh.reducer';
import { type State } from 'src/reducers/index';

import {
  workflowExternalTasksLoad,
  workflowExternalTaskDetail,
  workflowPaginationFirstPage,
  workflowPaginationPreviousPage,
  workflowPaginationNextPage,
  workflowPaginationLastPage
} from '../actions/workflow-external-tasks.actions';
import {
  type ExternalTasksFilters,
  type WorkflowExternalTask,
  type WorkflowPaginationWithCount
} from '../types/workflow';

import AdminPage from '../components/AdminPage';
import WorkflowExternalTasks from '../components/WorkflowExternalTasks';

import theme from './AdminCamundaWorkflowTasksDashboard.css';
import Drawer from 'src/components/Drawer';
import {
  workflowRefreshEnable,
  workflowRefreshDisable,
  workflowRefreshUpdateConfig
} from '../actions/workflow-auto-refresh.actions';

type AdminCamundaWorkflowTasksDashboardProps = {
  camundaTasksState: WorkflowExternalTasksState,
  autoRefreshState: WorkflowAutoRefreshState,
  pagination: WorkflowPaginationWithCount,
  onSelectTopic: (topicName: string) => void,
  onSelectWorker: (workerId: string) => void,
  onSelectShowErrors: (showErrors: boolean) => void,
  onRefreshSpanChange: (amount: number) => void,
  onRefreshUnitChange: (unit: 'seconds' | 'minutes') => void,
  onDetailClick: (task: ?WorkflowExternalTask) => void,
  onFirstPageClick: () => void,
  onPreviousPageClick: () => void,
  onNextPageClick: () => void,
  onLastPageClick: () => void
};

class AdminCamundaWorkflowTasksDashboard extends React.Component<
  AdminCamundaWorkflowTasksDashboardProps,
  void
> {
  getCurrentPage = () => {
    const { pagination } = this.props;
    return pagination.firstResult === 0
      ? pagination.firstResult + 1
      : Math.floor((pagination.firstResult - 1) / pagination.maxResults) + 1;
  };

  getLastPage = () => {
    const { pagination } = this.props;
    return (
      pagination.count && Math.ceil(pagination.count / pagination.maxResults)
    );
  };

  renderTabs = (renderTab) => {
    const configs = [
      {
        key: 'workers',
        label: 'Workers',
        to: '/admin/dashboard/camunda/workers'
      },
      {
        key: 'tasks',
        isActive: true,
        label: 'Tasks',
        to: '/admin/dashboard/camunda/tasks'
      }
    ];
    return <React.Fragment>{configs.map(renderTab)}</React.Fragment>;
  };

  renderContent = () => {
    const {
      camundaTasksState,
      autoRefreshState,
      pagination,
      onSelectTopic,
      onSelectWorker,
      onSelectShowErrors,
      onDetailClick,
      onRefreshSpanChange,
      onRefreshUnitChange,
      onToggleAutoRefresh,
      onFirstPageClick,
      onPreviousPageClick,
      onNextPageClick,
      onLastPageClick
    } = this.props;

    const filters: ExternalTasksFilters =
      camundaTasksState.status !== 'ERROR' ? camundaTasksState.filters : {};

    const onFirstPage = pagination.firstResult === 0;
    const hasNextPage =
      pagination.count > pagination.firstResult + pagination.maxResults + 1 ||
      pagination.count % pagination.firstResult > pagination.maxResults;

    return (
      <React.Fragment>
        <WorkflowExternalTasks
          filters={filters}
          tasks={camundaTasksState.tasks || []}
          autoRefresh={autoRefreshState}
          topicSets={camundaTasksState.filterOptions.topicSets}
          workers={camundaTasksState.filterOptions.workers}
          onSelectTopic={onSelectTopic}
          onSelectWorker={onSelectWorker}
          onSelectShowErrors={onSelectShowErrors}
          onDetailClick={onDetailClick}
          onRefreshSpanChange={onRefreshSpanChange}
          onRefreshUnitChange={onRefreshUnitChange}
          onToggleAutoRefresh={onToggleAutoRefresh}
        />
        <div className={theme.WorkflowMetadata}>
          <p>Total Results: {pagination.count}</p>
          {filters.showErrors && (
            <p>
              Page: {this.getCurrentPage()} of {this.getLastPage()}
            </p>
          )}
          {!filters.showErrors && (
            <p>Pagination does not work due to frontend filtering errors</p>
          )}
        </div>
        {filters.showErrors && (
          <div className={theme.WorkflowPagination}>
            <Button
              theme={theme}
              icon="first_page"
              disabled={!filters.showErrors || onFirstPage}
              onClick={onFirstPageClick}
            />
            <Button
              theme={theme}
              icon="chevron_left"
              disabled={!filters.showErrors || onFirstPage}
              onClick={onPreviousPageClick}
            />
            <Button
              theme={theme}
              icon="chevron_right"
              disabled={!filters.showErrors || !hasNextPage}
              onClick={onNextPageClick}
            />
            <Button
              theme={theme}
              icon="last_page"
              disabled={!filters.showErrors || !hasNextPage}
              onClick={onLastPageClick}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <AdminPage
          theme={theme}
          renderTabs={this.renderTabs}
          renderHeader={this.renderHeader}
          renderContent={this.renderContent}
        />
        <Drawer
          theme={theme}
          side={'right'}
          show={
            this.props.camundaTasksState.status === 'SUCCESS' &&
            !!this.props.camundaTasksState.detail
          }
          onOverlayClick={this.props.onDetailClick}
        >
          <div className={theme.DetailContent}>
            <pre>
              {JSON.stringify(this.props.camundaTasksState.detail, null, 4)}
            </pre>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    camundaWorkersState: state.admin.camunda.workers,
    camundaTasksState: state.admin.camunda.tasks,
    autoRefreshState: state.admin.camunda.autoRefresh,
    pagination: state.admin.camunda.tasks.pagination
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectTopic: (topicName: string) =>
      dispatch(workflowExternalTasksLoad({ topicName })),
    onSelectWorker: (workerId: string) =>
      dispatch(workflowExternalTasksLoad({ workerId })),
    onSelectShowErrors: (showErrors: boolean) =>
      dispatch(workflowExternalTasksLoad({ showErrors })),
    onDetailClick: (task: ?WorkflowExternalTask) =>
      dispatch(workflowExternalTaskDetail(task)),
    onRefreshSpanChange: (amount: number) =>
      dispatch(workflowRefreshUpdateConfig({ amount })),
    onRefreshUnitChange: (unit: number) =>
      dispatch(workflowRefreshUpdateConfig({ unit })),
    onToggleAutoRefresh: (val: boolean) =>
      val
        ? dispatch(workflowRefreshEnable())
        : dispatch(workflowRefreshDisable()),
    onFirstPageClick: () => dispatch(workflowPaginationFirstPage()),
    onPreviousPageClick: () => dispatch(workflowPaginationPreviousPage()),
    onNextPageClick: () => dispatch(workflowPaginationNextPage()),
    onLastPageClick: () => dispatch(workflowPaginationLastPage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCamundaWorkflowTasksDashboard);
