// @flow
import React from 'react';
import { Dropdown } from '@hc/component-lib';

class FormDropdown extends React.Component<any> {
  render() {
    const { input, meta, ...props } = this.props;
    return (
      <Dropdown
        {...input}
        {...props}
        onBlur={null}
        error={(meta.touched && meta.error) || ''}
      />
    );
  }
}

export default FormDropdown;
