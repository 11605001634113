// @flow
import React from 'react';
import { NavigationBar, ORDER_MANAGER, MenuItem } from '../auth-lib/components';
import type { UserContext } from '../auth-lib/types/user.types';
import { InternetExplorerWarning } from '@hc/component-lib/hclib/components/atoms/internet-explorer-warning/InternetExplorerWarning';
import reportSummariesIcon from '../icons/report_summary.svg';
import OrderItemsSearch from '../client/components/OrderItemsSearch';
import { ORDERS_ROUTE } from '../client/routes';
import routeTo from '../history/route-to';

import type { UserDetails } from 'src/types/user';
import styles from './HcHeader.css';

const {
  SETTINGS: { PLATFORM_ENVIRONMENT }
} = window;

const REPORT_SUMMARY_ROUTE: string = '/client/report-summary';

const ReportSummariesIcon = () => <img src={reportSummariesIcon} />;

const ProductClickAreaComponent = ({ className, children }) => {
  return (
    <div className={className} onClick={() => routeTo(ORDERS_ROUTE)}>
      {children}
    </div>
  );
};

type HcHeaderProps = {
  userDetails: ?UserDetails,
  userContext: ?UserContext,
  showAppLinks?: boolean
};
export class HcHeader extends React.Component<HcHeaderProps, void> {
  render() {
    const {
      userDetails,
      userContext,
      showAppLinks = true,
      ...props
    } = this.props;

    return (
      <>
        <NavigationBar
          theme={styles}
          userContext={userContext || {}}
          env={PLATFORM_ENVIRONMENT}
          productSlug={ORDER_MANAGER}
          logoutOptions={{
            url: '/logout'
          }}
          renderCustomAccountMenuContent={
            showAppLinks
              ? () => (
                  <div>
                    <MenuItem
                      url={REPORT_SUMMARY_ROUTE}
                      label="Report Summaries"
                      icon={<ReportSummariesIcon />}
                      dataHcName="profile-report-summaries"
                    />
                  </div>
                )
              : null
          }
          ProductClickAreaComponent={ProductClickAreaComponent}
          {...props}
        >
          {showAppLinks && userDetails && (
            <OrderItemsSearch token={userDetails.token} />
          )}
        </NavigationBar>
        <InternetExplorerWarning className={styles.InternetExplorerWarning} />
      </>
    );
  }
}
