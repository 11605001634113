/**
 * NOTE: type identifiers are messed up in flow.
 * React docgen expects 3 types, flow only supports 2
 * Once that is resolved, enable flow
 */
import React, { cloneElement, Component } from 'react';
import { findDOMNode } from 'react-dom';
import { ErrorMessage } from '../error-message';
import { EmailField } from '../email-field';
import { PasswordField } from '../password-field';
import { SignupNameField } from '../name-field';
import { SignupCompanyField } from '../company-field';
import { SignupPhoneField } from '../phone-field';
import { FormSubmit } from '../form-submit';
import LoginLink from './LoginLink';
import { themr } from 'react-css-themr';
import signupTheme from './Signup.scss';

type SignupFormTheme = {
  SignupForm: string,
  SignupTitle: string,
  FormSubmit: string
}

type User = {
  firstName: string,
  lastName: string,
  companyName?: string;
  phone?: string;
  email: string,
  password: string
};

type Props = {
  /** optional value for firstName input */
  firstName?: string,
  /** optional value for lastName input */
  lastName?: string,
  /** optional value for email input */
  email?: string,
  /** Called when Submit button is clicked */
  onSubmit: User => mixed,
  /** Email Field Component to use */
  emailField: any,
  /** Password Field Component to use */
  passwordField: any,
  /** Terms Field Component to use */
  termsField: any,
  /** Terms Link Component to user */
  termsLink: any,
  /** Error message to display */
  errorMessage: ?string,
  /** Login url */
  loginUrl: ?string,
  /** optional callback when "go to login" link is clicked */
  handleLoginClick?: {} => void,
  /** Status for when the component goes into submit state */
  isSubmitting: boolean,
  /** Custom validation boolean passed for validation with custom components passed in */
  parentIsFormValid: boolean,
  /** optional boolean to include the company name field -- added for PEXP self-service */
  includeCompanyName: boolean,
  /** optional boolean to include the phone field -- added for PEXP self-service */
  includePhone: boolean,
  /** React Css Themr theme */
  theme: SignupFormTheme,
  /** Title */
  title: ?string
};

type UserStatus = {
  firstNameStatus: boolean,
  lastNameStatus: boolean,
  companyNameStatus: boolean,
  emailStatus: boolean,
  phoneStatus: boolean,
  passwordStatus: boolean
};

type State = User & UserStatus;

/**
 * Signup Component
 */
export class Signup extends Component <Props, State> {
  handleSignupValid: Function;
  handleSignupInvalid: Function;
  submitForm: Function;
  firstInputField: Object;

  emailField: any;
  passwordField: any;

  static defaultProps = {
    title: null,
    onSubmit: () => {},
    errorMessage: null,
    loginUrl: null,
    isSubmitting: false,
    emailField: (<EmailField data-hc-name='signup-email-field' label='Email' debounce={500} />),
    passwordField: (<PasswordField data-hc-name='signup-password-field' label='Password' />),
    focusFirstFieldOnMount: true,
    includeCompanyName: false,
    includePhone: false
  };

  state = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phone: '',
    password: '',
    firstNameStatus: false,
    lastNameStatus: false,
    companyNameStatus: true,
    phoneStatus: true,
    emailStatus: false,
    passwordStatus: false
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount () {
    const { firstName, lastName, email } = this.props;
    if (firstName || lastName || email) {
      this.setState({ ...this.state, firstName, lastName, email });
    }
  }

  componentDidMount () {
    if (this.props.focusFirstFieldOnMount && this.firstInputField) {
      this.firstInputField.focus();
    }
  }

  getUser () {
    return {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      companyName: this.state.companyName,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password
    };
  }

  submitForm = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.getUser());
  }

  handleSignupChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSignupValid = (val: string, e: SyntheticInputEvent<*>) => {
    this.setState({ [`${e.target.name}Status`]: true });
  }

  handleSignupInvalid = (reason: string, e: SyntheticInputEvent<*>) => {
    this.setState({ [`${e.target.name}Status`]: false });
  }

  recordFirstField = (node) => {
    if (node) {
      this.firstInputField = findDOMNode(node).querySelector('input');
    }
  }

  checkValidation = () : boolean => {
    const {
      firstName,
      firstNameStatus,
      lastName,
      lastNameStatus,
      companyNameStatus,
      email,
      emailStatus,
      phoneStatus,
      password,
      passwordStatus
    } = this.state;

    return !!firstName &&
       !!lastName &&
       !!email &&
       !!password &&
       firstNameStatus &&
       lastNameStatus &&
       companyNameStatus &&
       emailStatus &&
       phoneStatus &&
       passwordStatus
    ;
  }
  render () {
    const {
      theme,
      isSubmitting,
      parentIsFormValid,
      errorMessage,
      loginUrl,
      handleLoginClick,
      termsLink,
      title,
      includeCompanyName,
      includePhone
    } = this.props;
    const isFormValid = this.checkValidation();

    return (
      <div className={theme.SignupForm}>
        {title && <h2 className={theme.SignupTitle}>{title}</h2>}
        <form onSubmit={this.submitForm}>
          <SignupNameField
            data-hc-name='signup-first_name-field'
            theme={theme}
            name='firstName'
            label='First Name'
            value={this.state.firstName}
            onChange={this.handleSignupChange}
            onValid={this.handleSignupValid}
            onInvalid={this.handleSignupInvalid}
            ref={this.recordFirstField}
            required />
          <SignupNameField
            data-hc-name='signup-last_name-field'
            theme={theme}
            name='lastName'
            label='Last Name'
            value={this.state.lastName}
            onChange={this.handleSignupChange}
            onValid={this.handleSignupValid}
            onInvalid={this.handleSignupInvalid}
            required />
          {includeCompanyName &&
            <SignupCompanyField
              data-hc-name='signup-company_name-field'
              theme={theme}
              name='companyName'
              label='Company Name'
              value={this.state.companyName}
              onChange={this.handleSignupChange}
              onValid={this.handleSignupValid}
              onInvalid={this.handleSignupInvalid} />
          }
          {includePhone &&
            <SignupPhoneField
              data-hc-name='signup-phone-field'
              theme={theme}
              name='phone'
              label='Phone Number'
              value={this.state.phone}
              onChange={this.handleSignupChange}
              onValid={this.handleSignupValid}
              onInvalid={this.handleSignupInvalid} />
          }
          {
            cloneElement(
              this.props.emailField, {
                theme,
                name: 'email',
                value: this.state.email,
                maxLength: 55,
                onChange: this.handleSignupChange,
                onValid: this.handleSignupValid,
                onInvalid: this.handleSignupInvalid,
                required: true
              })
          }
          {
            cloneElement(
              this.props.passwordField, {
                name: 'password',
                value: this.state.password,
                onChange: this.handleSignupChange,
                onValid: this.handleSignupValid,
                onInvalid: this.handleSignupInvalid,
                required: true
              })
          }
          {this.props.termsField}
          <FormSubmit
            data-hc-name='signup-submit-button-text'
            theme={theme}
            type='submit'
            disabled={isSubmitting || parentIsFormValid === false || !isFormValid}
            onSubmit={this.submitForm}
            label={isSubmitting ? 'Submitting' : 'Sign Up'} />
          {
            errorMessage &&
            <ErrorMessage theme={theme} value={errorMessage} />
          }
          {
            (loginUrl || handleLoginClick) &&
            <LoginLink data-hc-name='login-link' theme={theme} href={loginUrl} handleClick={handleLoginClick} />
          }
          {termsLink}
        </form>
      </div>
    );
  }
}

export const SignupThemed = themr('SignupThemed', signupTheme)(Signup);

export default SignupThemed;
