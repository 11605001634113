// @flow
import React from 'react';
import {
  RTThemedCard,
  RTThemedCardText
} from '@hc/component-lib/lib/rt-themed';

const OrderRejected = () => (
  <RTThemedCard raised>
    <RTThemedCardText>Order Rejected</RTThemedCardText>
  </RTThemedCard>
);

export default OrderRejected;
