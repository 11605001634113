// @flow
import * as React from 'react';
import {
  RTThemedDialog,
  RTThemedIconButton
} from '@hc/component-lib/lib/rt-themed';

import LoadingButton from 'src/components/loading-button/LoadingButton';

import styles from './ConfirmDialog.css';

type ConfirmDialogProps = {
  title: string,
  loading: boolean,
  children: React.Node,
  theme?: ?Object,
  className?: ?string,
  show: boolean,
  onConfirm: () => void,
  onDismiss: () => void,
  confirmButtonLabel: string,
  error: ?string,
  containerHasHeader?: boolean
};

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    title,
    loading,
    children,
    theme,
    className,
    show,
    onConfirm,
    onDismiss,
    confirmButtonLabel,
    error,
    containerHasHeader
  } = props;
  return (
    <RTThemedDialog
      title={title}
      theme={theme || styles}
      active={show}
      onEscKeyDown={onDismiss}
      className={containerHasHeader ? styles.containerHasHeader : null}
    >
      <RTThemedIconButton
        data-hc-name="confirm-dialog-close"
        icon="close"
        className={className || styles.closeIcon}
        onClick={onDismiss}
      />
      {children}
      {error && <div className={styles.error}>Error: {error}</div>}
      <nav role="navigation" className={styles.navigation}>
        <LoadingButton
          dataHcName="confirm-dialog-accept"
          className={styles.loadingButton}
          theme={styles}
          loading={loading}
          disabled={loading}
          onClick={onConfirm}
        >
          {confirmButtonLabel}
        </LoadingButton>
      </nav>
    </RTThemedDialog>
  );
};

export default ConfirmDialog;
