// @flow
import queryString from 'query-string';
import { type UserContext } from '../types';

import type { Application } from '../types';

import { AuthApiClient, ServerError } from './auth-api-client';
import { Factory } from '../services/factory';

type Component = {
  application_id: number,
  component_type: { id: number, name: string },
  component_type_id: number,
  id: number,
  name: string,
  tier: ?string,
  uom: string
}

type RatePlanCharge = {
  billingPeriod: string,
  model: string,
  name: string,
  price: number,
  pricingSummary: string,
  productRatePlanChargeId: string,
  type: string
}

export type SubscriptionItem = {
  components: Component[],
  created_on: ?string,
  effective_end_date: ?string,
  effective_start_date: ?string,
  id: number,
  last_updated_on: ?string,
  product: {
    application_id: number,
    created_on: string,
    id: number,
    last_updated_on: string,
    name: string,
    z_created_date: ?string,
    z_description: string,
    z_product_id: string,
    z_sku: string,
    z_updated_date: ?string
  },
  product_id: number,
  product_rate_plan: {
    base_price: number,
    billing_period: string,
    created_on: string,
    id: number,
    is_enterprise_plan: boolean,
    is_featured_plan: boolean,
    is_self_service_plan: boolean,
    last_updated_on: string,
    name: string,
    product_id: string,
    product_rate_plan_group: {
      application_id: number,
      created_on: string,
      id: number,
      included_units: number,
      last_updated_on: string,
      monthly_cost: number,
      monthly_cost_billed_annually: number,
      name: string,
      overage_pricing: number,
      per_unit_pricing: ?number,
      pricing_link: ?string,
      uom: string
    },
    product_rate_plan_group_id: number,
    status: string,
    z_description: string,
    z_group_name: string,
    z_product_rate_plan_id: string
  },
  product_rate_plan_charge_id: number,
  product_rate_plan_id: number,
  subscription_id: number,
  z_charge_number: string,
  z_included_units: number,
  z_last_change_type: ?string,
  z_product_id: string,
  z_product_rate_plan_id: string,
  z_rate_plan_charge_id: string,
  z_rate_plan_id: string,
  z_segment: string,
  z_uom: string
}

export type Subscription = {
    active: boolean,
    application_ids: number[],
    applications: Application[],
    billing_day_of_month: ?string,
    end_date: ?string,
    id: number,
    items: SubscriptionItem[],
    organization_id: number,
    start_date: string,
    status: string,
    term_end_date: ?string,
    term_start_date: string,
    z_notes: ?string,
    z_status: string,
    z_subscription_id: string,
    z_subscription_number: string,
    zuora_data: {
      billing_period: string,
      includedUnits: number,
      lastChargedThrough: string,
      model: string,
      monthlyFee: number,
      name: string,
      overagePrice: number,
      price: number,
      pricingSummary: string,
      productId: string,
      productName: string,
      productRatePlanChargeId: string,
      productRatePlanId: string,
      ratePlanCharges: RatePlanCharge[],
      ratePlanName: string,
      subscriptionStartDate: string,
      termEndDate: string,
      termStartDate: string,
      type: string,
      uom: string
    }
}

export type TrialSubscriptionItem = {
  component: Component,
  component_id: number,
  created_on: string,
  id: number,
  included_units: number,
  last_updated_on: string,
  remaining_units: number,
  trial_subscription_id: number
}

export type TrialSubscription = {
  application: Application,
  application_id: number,
  created_by: string,
  created_on: string,
  credit: number,
  deleted_by: ?string,
  deleted_on: ?string,
  end_date: string,
  id: number,
  is_deleted: boolean,
  items: TrialSubscription[],
  last_updated_by: string,
  last_updated_on: string,
  organization_id: number,
  start_date: string,
  trial_type: ?string
}

export type ApplicationSubscription = {
  active: boolean,
  application: Application,
  subscriptions: Subscription[],
  trial: boolean,
  trial_ended: boolean,
  trial_subscriptions: TrialSubscription[]
}

// contains list of application subscriptions
export type SubscriptionResponse = ApplicationSubscription[]
export type PromiseSubscriptionsResponse = Promise<SubscriptionResponse|ServerError>

export class SubscriptionApiClient extends AuthApiClient {
  token: string
  constructor (loginUrl: string, apiUrl: string, updateContextCallback: (userContext: UserContext) => void, token: string) {
    super(loginUrl, apiUrl, updateContextCallback);
    this.token = token;
  }

  /**
   * Fetch all subscriptions for the given org
   * The context for the user/org is embedded in the token of the logged in user
   * @return {Promise<SubscriptionResponse|ServerError>}
   */
  getSubscriptions (): PromiseSubscriptionsResponse {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.apiUrl, 'subscription', null, {
        headers: { 'HC-Auth-Token': this.token }
      })
        .then(response => resolve(response))
        .catch(error => reject(new ServerError(error ? error.message : 'AuthLib[SubscriptionClient#getSubscriptions]: Error requesting subscriptions')));
    });
  }

  /**
   * Fetch subscriptions for the given org and application name
   * The context for the user/org is embedded in the token of the logged in user
   * @param {String} applicationName
   * @return {Promise<SubscriptionResponse|ServerError>}
   *
   * @example <caption>Fetch Value Report Subscription</caption>
   * const { SubHeading } = require('@hc/component-lib');
   * const { SubscriptionApiClient } = require('../../src/clients');
   * const apiUrl = 'https://api-dev.housecanary.net/api/v1/';
   * const token = 'secret_token';
   * const client = new SubscriptionApiClient(loginUrl, apiUrl, token);
   *
   * client.getApplicationSubscription('Value Report')
   *    .then(subscription => {
   *      // subscriptions for Value Report application
   *    })
   *    .catch(error => console.error(error));
   *
   * <SubHeading>Click Code below</SubHeading>
   */
  getApplicationSubscription (applicationName: string): PromiseSubscriptionsResponse {
    return new Promise((resolve, reject) => {
      const path = `subscription?${queryString.stringify({ application_name: applicationName })}`;
      return this.makeAuthRequest(this.apiUrl, path, null, {
        headers: { 'HC-Auth-Token': this.token }
      })
        .then(response => resolve(response))
        .catch(error => reject(new ServerError(error ? error.message : `AuthLib[SubscriptionClient#getApplicationSubscription]: Error requesting subscriptions for ${applicationName}`)));
    });
  }
}

export const getSubscriptionsClient = (loginUrl: string, apiUrl: string, token: string) => {
  return Factory.get('SubscriptionApiClient', SubscriptionApiClient, loginUrl, apiUrl, token);
};
