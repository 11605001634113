// @flow

export const MARK_CHECKPOINT = 'MARK_CHECKPOINT';

type RecordCheckpointAction = {
  type: 'MARK_CHECKPOINT',
  payload: {
    checkpointRoute: string
  }
};

export type HistoryAction = RecordCheckpointAction;

export const markCheckpoint = (
  checkpointRoute: string
): RecordCheckpointAction => ({
  type: MARK_CHECKPOINT,
  payload: { checkpointRoute }
});
