// @flow
import {
  ORDER_ITEMS_LOAD,
  ORDER_ITEMS_LOAD_SUCCESS,
  ORDER_ITEMS_LOAD_ERROR,
  SEARCH_ORDER_ITEMS,
  SORT_ORDER_ITEMS,
  CLEAR_ORDER_ITEMS,
  ORDER_ITEMS_PARTIAL_LOAD_SUCCESS
} from 'src/client/actions/order-items.actions';
import { REMOVED_GROUP } from 'src/client/constants/order-items';
import { WEBSOCKET_ORDER_ITEM_UPDATED } from 'src/client/actions/websocket.actions';

import type { Action } from 'src/client/actions';
import type { OrderItem } from 'src/client/types/order-item';
import type { Links } from 'src/types/link';
import type { OrderingParams } from 'src/types/list';

type RemovedItemsNotLoadedState = {
  status: 'not_loaded',
  fetching: false
};
type RemovedItemsLoadingState = {
  status: 'loading',
  fetching: true
};
type RemovedItemsLoadedState = {
  status: 'loaded',
  fetching: boolean,
  links: Links,
  pageItems: OrderItem[],
  page: ?number,
  query: ?string,
  ordering: OrderingParams[],
  defaultOrdering: OrderingParams[]
};
type RemovedItemsErrorState = {
  status: 'error',
  fetching: false,
  errorMessage: string
};
export type RemovedItemsState =
  | RemovedItemsNotLoadedState
  | RemovedItemsLoadingState
  | RemovedItemsLoadedState
  | RemovedItemsErrorState;

const defaultState = {
  status: 'not_loaded',
  fetching: false
};

export function removedItemsReducer(
  state: RemovedItemsState = defaultState,
  action: Action
): RemovedItemsState {
  switch (action.type) {
    case ORDER_ITEMS_LOAD: {
      if (action.payload.orderItemGroup !== REMOVED_GROUP) {
        return state;
      }
      return state.status === 'loaded'
        ? {
            status: 'loaded',
            fetching: true,
            links: state.links,
            pageItems: state.pageItems,
            page: state.page,
            query: state.query,
            ordering: state.ordering,
            defaultOrdering: state.defaultOrdering
          }
        : {
            status: 'loading',
            fetching: true
          };
    }
    case ORDER_ITEMS_LOAD_SUCCESS: {
      if (action.payload.orderItemGroup !== REMOVED_GROUP) {
        return state;
      }
      const { ordering, query, page } = action.payload.params;
      return {
        status: 'loaded',
        fetching: false,
        links: action.payload.links,
        pageItems: action.payload.orderItems,
        page,
        query,
        ordering: ordering || [],
        defaultOrdering: action.payload.defaultOrdering
      };
    }
    case ORDER_ITEMS_LOAD_ERROR: {
      if (action.payload.orderItemGroup !== REMOVED_GROUP) {
        return state;
      }
      return {
        status: 'error',
        fetching: false,
        errorMessage: action.payload.errorMessage
      };
    }
    case SEARCH_ORDER_ITEMS: {
      if (action.payload.orderItemGroup !== REMOVED_GROUP) {
        return state;
      }
      const { query } = action.payload;
      return state.status === 'loaded'
        ? {
            status: 'loaded',
            fetching: false,
            links: state.links,
            pageItems: state.pageItems,
            page: null,
            query,
            ordering: state.ordering,
            defaultOrdering: state.defaultOrdering
          }
        : state;
    }
    case SORT_ORDER_ITEMS: {
      if (action.payload.orderItemGroup !== REMOVED_GROUP) {
        return state;
      }
      return state.status === 'loaded'
        ? {
            status: 'loaded',
            fetching: false,
            links: state.links,
            pageItems: state.pageItems,
            page: state.page,
            query: state.query,
            ordering:
              action.payload.ordering === state.defaultOrdering
                ? []
                : action.payload.ordering,
            defaultOrdering: state.defaultOrdering
          }
        : state;
    }
    case WEBSOCKET_ORDER_ITEM_UPDATED: {
      if (state.status === 'loaded') {
        const orderItemId: string = action.payload.orderItemId;
        const matchingOrderItemIndex = state.pageItems.findIndex(
          (orderItem: OrderItem) => orderItem.id === orderItemId
        );
        if (matchingOrderItemIndex !== -1) {
          const updatedOrderItem = {
            ...state.pageItems[matchingOrderItemIndex],
            status: action.payload.status,
            cancelled: action.payload.cancelled
          };
          return {
            status: 'loaded',
            fetching: false,
            links: state.links,
            pageItems: Object.assign([...state.pageItems], {
              [matchingOrderItemIndex]: updatedOrderItem
            }),
            page: state.page,
            query: state.query,
            ordering: state.ordering,
            defaultOrdering: state.defaultOrdering
          };
        }
      }
      return state;
    }
    case ORDER_ITEMS_PARTIAL_LOAD_SUCCESS: {
      const { orderItemGroup, orderItems } = action.payload;
      if (state.status === 'loaded' && orderItemGroup === REMOVED_GROUP) {
        const updatedOrderItemById = orderItems.reduce(
          (accum, updatedOrderItem: OrderItem) => {
            accum[updatedOrderItem.id] = updatedOrderItem;
            return accum;
          },
          {}
        );
        return {
          status: 'loaded',
          fetching: false,
          pageItems: state.pageItems.map((orderItem: OrderItem) => {
            if (updatedOrderItemById[orderItem.id]) {
              return updatedOrderItemById[orderItem.id];
            }
            return orderItem;
          }),
          links: state.links,
          page: state.page,
          query: state.query,
          ordering: state.ordering,
          defaultOrdering: state.defaultOrdering
        };
      }
      return state;
    }
    case CLEAR_ORDER_ITEMS: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
}
