// @flow
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  all,
  fork,
  spawn
} from 'redux-saga/effects';
import {
  startSubmit,
  stopSubmit,
  setSubmitSucceeded,
  getFormValues
} from 'redux-form';
import { delay } from 'redux-saga';

import type { Saga } from 'redux-saga';

import type { QueryParams } from '../../types/list';
import type { AddressParts } from '../../types/address';
import type {
  DownloadOrderItemAction,
  ViewOrderItemReportAction
} from '../actions/order-items.actions';
import type { SidePanelContentType } from '../reducers/side-panel.reducer';
import type { OrderItemsState } from '../reducers/order-items.reducer';
import type { InOrderItemsState } from '../reducers/in-order-items.reducer';
import type { NeedReviewItemsState } from '../reducers/need-review-items.reducer';
import type { RemovedItemsState } from '../reducers/removed-items.reducer';
import type { SelectedOrderState } from '../reducers/selected-order.reducer';
import type { Order } from '../types/order';
import type {
  OrderItem,
  OrderItemGroup,
  OrderItemProcessCounts
} from '../types/order-item';
import type { AddressValidationState } from '../reducers/address-validation.reducer';
import type { UpdateReverifyItemState } from '../reducers/update-reverify-item.reducer';

import { handleStandardExceptions, snakeToCamel } from '../../sagas/helpers';
import {
  selectOrder,
  orderItemsLoad,
  orderItemsLoadSuccess,
  orderItemsLoadError,
  downloadOrderItemError,
  DOWNLOAD_ORDER_ITEM,
  SEARCH_ORDER_ITEMS,
  SORT_ORDER_ITEMS,
  VIEW_ORDER_ITEM_REPORT,
  FETCH_SELECTED_ORDER_OWNER,
  type FetchSelectedOrderOwnerAction,
  fetchSelectedOrderOwnerSuccess,
  fetchSelectedOrderOwnerError,
  fetchOrderItemProcessCount,
  fetchOrderItemProcessCountSuccess,
  type UpdateReverifyItemAction,
  updateReverifyItemSuccess,
  updateReverifyItemError,
  updateReverifyItemCancelled,
  UPDATE_REVERIFY_ITEM,
  type AddItemsToOrderAction,
  ADD_ITEMS_TO_ORDER,
  moveItemsComplete,
  REMOVE_ITEMS_FROM_ORDER,
  type RemoveItemsFromOrderAction,
  type RecoverRemovedItemsAction,
  REVIEW_ACCEPT_ORDER,
  type ReviewAcceptOrderAction,
  reviewAcceptOrderSuccess,
  type ReviewCancelOrderAction,
  reviewAcceptOrderError,
  REVIEW_CANCEL_ORDER,
  reviewCancelOrderSuccess,
  reviewCancelOrderError,
  RECOVER_REMOVED_ITEMS,
  initOrderItemGroup,
  SELECT_ORDER_ITEM_GROUP,
  showMoveCompleteDialog,
  orderItemsPartialLoadSuccess
} from '../actions/order-items.actions';
import { fetchMessages } from '../actions/order-item-messages.actions';
import userTokenSelector from '../../selectors/user-token.selector';
import {
  orderItemsStateSelector,
  selectedOrderStateSelector,
  inOrderItemsStateSelector,
  needReviewItemsStateSelector,
  removedItemsStateSelector,
  updateReverifyItemStateSelector
} from '../selectors/order-items.selectors';
import { Client } from '../../api/order-manager-api-client';
import routeTo from '../../history/route-to';
import {
  fetchOrderProgress,
  fetchOrder,
  requestOrderReload
} from './order.saga';
import { ORDER_STATUSES } from '../constants/order-statuses';
import { showToast } from '../../actions/view.actions';
import {
  openOrderItemDetailsSidePanel,
  openMessagesSidePanel
} from '../actions/side-panel.actions';
import {
  slugifyFileName,
  addFileExtension
} from '../../helpers/file-name-helpers';
import {
  orderItemResponseToCamelCase,
  isOverrideable
} from '../helpers/order-item.helpers';
import * as logger from '../../logger';
import { authClient } from '../../sagas/auth-client-instance';
import {
  getOrderingParamsFromQS,
  buildListLink
} from '../helpers/list-helpers';
import {
  IN_ORDER_GROUP,
  NEED_REVIEW_GROUP,
  REMOVED_GROUP,
  EDIT_ADDRESS_FIELD_NAME,
  PROPERTY_TYPE_FIELD_NAME
} from '../constants/order-items';
import {
  VALIDATE_ADDRESS_FIELD_SUCCESS,
  VALIDATE_ADDRESS_FIELD_ERROR,
  type ValidateAddressFieldSuccessAction,
  type ValidateAddressFieldErrorAction
} from '../actions/address-validation.actions';
import { addressValidationStateSelector } from '../selectors/address-validation.selectors';
import {
  connectWebsocket,
  WEBSOCKET_ORDER_UPDATED,
  type WebsocketOrderUpdatedAction,
  WEBSOCKET_ORDER_ITEM_UPDATED,
  type WebsocketOrderItemUpdatedAction
} from '../actions/websocket.actions';
import { ORDERS_ROUTE } from '../routes';

type ItemsState = InOrderItemsState | NeedReviewItemsState | RemovedItemsState;

function* getItemsStateByGroup(orderItemGroup: OrderItemGroup): Saga<void> {
  if (orderItemGroup === IN_ORDER_GROUP) {
    return yield select(inOrderItemsStateSelector);
  } else if (orderItemGroup === NEED_REVIEW_GROUP) {
    return yield select(needReviewItemsStateSelector);
  } else if (orderItemGroup === REMOVED_GROUP) {
    return yield select(removedItemsStateSelector);
  }
}

export function* loadSelectedOrder(orderId: string): Saga<void> {
  const userToken = yield select(userTokenSelector);
  const client = new Client(userToken);
  const order = yield call(fetchOrder, orderId, client);
  yield put(selectOrder(order));
}

const orderItemResponseToOrderItem = (
  orderItemResponse: any,
  orderItemGroup: OrderItemGroup
): OrderItem => {
  const orderItem: OrderItem = orderItemResponseToCamelCase(orderItemResponse);
  if (orderItemGroup === IN_ORDER_GROUP) {
    orderItem.isOverridable = false;
  } else {
    orderItem.isOverridable = isOverrideable(orderItem);
  }
  return orderItem;
};

export function* fetchOrderItems(
  orderId: string,
  params: QueryParams,
  orderItemGroup: OrderItemGroup
): Saga<void> {
  try {
    yield put(orderItemsLoad(orderItemGroup));
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);

    if (orderItemGroup === IN_ORDER_GROUP) {
      params.filters = { processItem: true };
    } else if (orderItemGroup === NEED_REVIEW_GROUP) {
      params.filters = { processItem: null };
    } else if (orderItemGroup === REMOVED_GROUP) {
      params.filters = { processItem: false };
    }
    const {
      links,
      ordering: defaultOrdering,
      data
    } = yield call([client, client.getOrderItems], orderId, params);
    const orderItems = data.map((item) =>
      orderItemResponseToOrderItem(item, orderItemGroup)
    );
    const defaultOrderingParams = getOrderingParamsFromQS(defaultOrdering);
    yield put(
      orderItemsLoadSuccess(
        orderItemGroup,
        orderItems,
        links,
        params,
        defaultOrderingParams
      )
    );
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(orderItemsLoadError(orderItemGroup, e.message));
  }
}

export function* requestOrderItemProcessCounts(
  orderId: string
): Saga<OrderItemProcessCounts> {
  yield put(fetchOrderItemProcessCount());
  const userToken = yield select(userTokenSelector);
  const client = new Client(userToken);
  const response = yield call(
    [client, client.getOrderItemProcessCounts],
    orderId
  );
  const orderItemProcessCounts: OrderItemProcessCounts = snakeToCamel(
    response,
    Object.keys(response),
    {},
    false
  );
  yield put(fetchOrderItemProcessCountSuccess(orderItemProcessCounts));
  return orderItemProcessCounts;
}

export function* resolveOrderItems({
  orderId,
  page,
  query,
  ordering,
  sidePanelContent,
  selectedItem,
  orderItemGroup = IN_ORDER_GROUP
}: {
  orderId: string,
  page?: number,
  query?: string,
  ordering?: string,
  sidePanelContent: ?SidePanelContentType,
  selectedItem: ?string,
  orderItemGroup: OrderItemGroup
}): Saga<void> {
  try {
    yield put(initOrderItemGroup(orderItemGroup));

    if (selectedItem) {
      query = `uid:${selectedItem}`;
      ordering = '';
      page = 1;
    }

    const params: QueryParams = {};
    if (page) {
      params.page = page;
    }
    if (query) {
      params.query = query;
    }
    const orderingParams = getOrderingParamsFromQS(ordering);
    if (orderingParams.length > 0) {
      params.ordering = orderingParams;
    }

    yield call(loadSelectedOrder, orderId);

    const selectedOrderState: SelectedOrderState = yield select(
      selectedOrderStateSelector
    );
    if (
      selectedOrderState.status === 'loaded' &&
      (selectedOrderState.order.status === ORDER_STATUSES.ACCEPTED ||
        selectedOrderState.order.status === ORDER_STATUSES.COMPLETE)
    ) {
      yield call(fetchOrderProgress, selectedOrderState.order);
    }

    yield all([
      call(fetchOrderItems, orderId, params, orderItemGroup),
      call(requestOrderItemProcessCounts, orderId)
    ]);
    yield put(connectWebsocket());
    if (sidePanelContent && selectedItem) {
      const itemsState: ItemsState = yield call(
        getItemsStateByGroup,
        orderItemGroup
      );

      if (itemsState.status === 'loaded' && itemsState.pageItems.length) {
        const orderItem = itemsState.pageItems[0];
        if (sidePanelContent === 'messages') {
          yield put(fetchMessages(orderId, selectedItem));
          yield put(openMessagesSidePanel(orderItem));
        } else if (sidePanelContent === 'details') {
          yield put(openOrderItemDetailsSidePanel(orderItem));
        }
      }
    }
  } catch (e) {
    yield call(handleStandardExceptions, e);
  }
}

export function* handleDownloadOrderItem(
  action: DownloadOrderItemAction
): Saga<void> {
  const { orderId, orderItemId, filename } = action.payload;
  const sluggedFileName = addFileExtension(slugifyFileName(filename), 'zip');

  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    yield call(
      [client, client.downloadOrderItem],
      orderId,
      orderItemId,
      sluggedFileName
    );
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(downloadOrderItemError(e.message));
  }
}

export function* handleViewOrderItemReport(
  action: ViewOrderItemReportAction
): Saga<void> {
  const { orderId, orderItemId, reportType } = action.payload;

  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const response = yield call(
      [client, client.downloadOrderItemPdf],
      orderId,
      orderItemId,
      reportType
    );
    // SECURITY: false posititive, window.open is not fs.open @john
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const opened = window.open(response.url);
    if (!opened) {
      yield put(
        showToast(
          'Could not open the requested report.  Please allow popups from this site and try again.'
        )
      );
    }
  } catch (e) {
    if (e.name === 'NotFoundError') {
      yield put(
        showToast('The requested report is not available for this item.')
      );
    } else {
      yield call(handleStandardExceptions, e);
      yield put(showToast('Could not retrieve report.  Please try again.'));
    }
  }
}

export function* loadCurrentOrderItems(): Saga<void> {
  const orderItemsState: OrderItemsState = yield select(
    orderItemsStateSelector
  );
  const selectedOrderState: SelectedOrderState = yield select(
    selectedOrderStateSelector
  );
  const orderItemGroup: OrderItemGroup = orderItemsState.orderItemGroup;

  const itemsState: ItemsState = yield call(
    getItemsStateByGroup,
    orderItemGroup
  );
  if (selectedOrderState.status === 'loaded') {
    const orderId = selectedOrderState.order.id;

    const params: QueryParams = {};
    if (itemsState.status === 'loaded') {
      params.query = itemsState.query;
      params.ordering = itemsState.ordering;
      if (itemsState.page) {
        params.page = itemsState.page;
      }
    }
    yield call(
      fetchOrderItems,
      orderId,
      params,
      orderItemsState.orderItemGroup
    );

    const baseUrl = `/client/order/${orderId}/${orderItemsState.orderItemGroup}`;
    const link =
      itemsState.status === 'loaded'
        ? buildListLink(
            baseUrl,
            itemsState.query,
            itemsState.ordering,
            itemsState.page
          )
        : buildListLink(baseUrl, null, [], null);
    yield call(routeTo, link, true);
  } else {
    if (selectedOrderState.status !== 'loaded') {
      logger.logException(
        new Error(
          `OrderItem.saga#loadCurrentOrderItems selectedOrderState status should be loaded but is ${selectedOrderState.status}`
        )
      );
    }
  }
}

function* handleFetchSelectedOrderOwner(
  action: FetchSelectedOrderOwnerAction
): Saga<void> {
  try {
    const userToken = yield select(userTokenSelector);
    const userId = action.payload.userId;
    const selectedOrderId = action.payload.selectedOrderId;
    const response = yield call(
      [authClient, authClient.getUser],
      userToken,
      userId
    );
    yield put(fetchSelectedOrderOwnerSuccess(response, selectedOrderId));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(
      fetchSelectedOrderOwnerError(e.message, action.payload.selectedOrderId)
    );
  }
}

function* updateReverifyItem(
  orderId: string,
  orderItemId: string,
  formName: string
): Saga<void> {
  try {
    const selector = getFormValues(formName);
    // const addressFieldSelector = (state) => selector(state, EDIT_ADDRESS_FIELD_NAME);
    const formValues = yield select(selector);
    const addressValues = formValues[EDIT_ADDRESS_FIELD_NAME];
    const addressParts: AddressParts = {
      address: addressValues.address,
      unit: addressValues.unit,
      city: addressValues.city,
      state: addressValues.state,
      zipcode: addressValues.zipcode
    };
    const propertyType = formValues[PROPERTY_TYPE_FIELD_NAME];
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const response = yield call(
      [client, client.updateReverifyOrderItem],
      orderId,
      orderItemId,
      addressParts,
      propertyType
    );
    const updatedOrderItem = orderItemResponseToOrderItem(
      response,
      NEED_REVIEW_GROUP
    );
    yield put(updateReverifyItemSuccess(updatedOrderItem));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(updateReverifyItemError(orderItemId, e.message));
    throw e;
  }
}

function* handleUpdateReverifyItem(
  action: UpdateReverifyItemAction
): Saga<void> {
  const { orderId, orderItemId, onUpdateComplete, formName } = action.payload;

  try {
    yield put(startSubmit(formName));
    const addressValidationState: AddressValidationState = yield select(
      addressValidationStateSelector
    );
    if (
      addressValidationState.status === 'loading' &&
      addressValidationState.formName === formName
    ) {
      // wait for addressValidation to complete instead
      return;
    }
    yield call(updateReverifyItem, orderId, orderItemId, formName);
    yield put(setSubmitSucceeded(formName));
    onUpdateComplete();
  } catch (e) {
    yield put(
      stopSubmit(formName, {
        [EDIT_ADDRESS_FIELD_NAME]: e.message
      })
    );
  }
}

function* handleAddressVerificationSuccess(
  action: ValidateAddressFieldSuccessAction
): Saga<void> {
  const { formName } = action.payload;
  try {
    const reviewUpdateState: UpdateReverifyItemState = yield select(
      updateReverifyItemStateSelector
    );
    // if a form is in the middle of submitting
    if (
      reviewUpdateState.status === 'loading' &&
      reviewUpdateState.formName === formName
    ) {
      yield call(
        updateReverifyItem,
        reviewUpdateState.orderId,
        reviewUpdateState.orderItemId,
        formName
      );
      yield put(setSubmitSucceeded(formName));
      reviewUpdateState.onUpdateComplete();
    }
  } catch (e) {
    yield put(
      stopSubmit(formName, {
        [EDIT_ADDRESS_FIELD_NAME]: e.message
      })
    );
  }
}

function* handleAddressVerificationError(
  action: ValidateAddressFieldErrorAction
): Saga<void> {
  const { formName } = action.payload;
  const reviewUpdateState: UpdateReverifyItemState = yield select(
    updateReverifyItemStateSelector
  );
  // if a form is in the middle of submitting
  if (
    reviewUpdateState.status === 'loading' &&
    reviewUpdateState.formName === formName
  ) {
    yield put(stopSubmit(formName));
    yield put(updateReverifyItemCancelled(formName));
  }
}

type UpdateOrderItemsResponse = {
  results: { id: string }[],
  errors: { id: string }[]
};
function* handleMoveItems(
  action:
    | AddItemsToOrderAction
    | RemoveItemsFromOrderAction
    | RecoverRemovedItemsAction
): Saga<void> {
  try {
    const { orderId, orderItemIds, orderItemGroup } = action.payload;
    const userToken: string = yield select(userTokenSelector);
    const client = new Client(userToken);

    let processItemValue = null;
    if (action.type === ADD_ITEMS_TO_ORDER) {
      processItemValue = true;
    } else if (action.type === REMOVE_ITEMS_FROM_ORDER) {
      processItemValue = false;
    } else if (action.type === RECOVER_REMOVED_ITEMS) {
      processItemValue = null;
    }

    const data = orderItemIds.map((orderItemId) => ({
      id: parseInt(orderItemId),
      process_item: processItemValue
    }));

    const response: UpdateOrderItemsResponse = yield call(
      [client, client.updateOrderItems],
      orderId,
      data
    );
    const succeededItemIds = response.results.map((result) => result.id);
    const failedItemIds = response.errors.map((error) => error.id);

    yield all([
      call(loadCurrentOrderItems),
      call(requestOrderItemProcessCounts, orderId)
    ]);
    if (succeededItemIds.length > 0) {
      yield put(moveItemsComplete(orderItemGroup, succeededItemIds));
    }
    let succeededMessage = '';
    let failedMessage = '';
    if (action.type === RECOVER_REMOVED_ITEMS) {
      succeededMessage =
        succeededItemIds.length > 0
          ? `${succeededItemIds.length} added to review. `
          : '';
      failedMessage =
        failedItemIds.length > 0
          ? `${failedItemIds.length} failed to recover.`
          : '';
    } else if (action.type === REMOVE_ITEMS_FROM_ORDER) {
      succeededMessage =
        succeededItemIds.length > 0
          ? `${succeededItemIds.length} removed from order. `
          : '';
      failedMessage =
        failedItemIds.length > 0
          ? `${failedItemIds.length} ${
              failedItemIds.length === 1 ? 'remains' : 'remain'
            } in ${orderItemGroup === IN_ORDER_GROUP ? 'order' : 'review'}.`
          : '';
    } else if (action.type === ADD_ITEMS_TO_ORDER) {
      succeededMessage =
        succeededItemIds.length > 0
          ? `${succeededItemIds.length} added to order. `
          : '';
      failedMessage =
        failedItemIds.length > 0
          ? `${failedItemIds.length} ${
              failedItemIds.length === 1 ? 'needs' : 'need'
            } further review.`
          : '';
    }
    yield put(showMoveCompleteDialog(succeededMessage, failedMessage));
  } catch (e) {
    yield call(handleStandardExceptions, e);
  }
}

function* handleReviewAcceptOrder(action: ReviewAcceptOrderAction): Saga<void> {
  try {
    const userToken: string = yield select(userTokenSelector);
    const client = new Client(userToken);
    yield call([client, client.reviewAcceptOrder], action.payload.id);
    const updatedOrder: Order = yield call(
      awaitOrderStatus,
      action.payload.id,
      'ClientReview',
      client
    );
    yield put(reviewAcceptOrderSuccess(updatedOrder));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(reviewAcceptOrderError(e.message));
  }
}

function* handleReviewCancelOrder(action: ReviewCancelOrderAction): Saga<void> {
  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    yield call([client, client.reviewRejectOrder], action.payload.id);
    yield call(awaitOrderStatus, action.payload.id, 'ClientReview', client);
    yield put(reviewCancelOrderSuccess());
    yield call(routeTo, ORDERS_ROUTE);
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(reviewCancelOrderError(e.message));
  }
}

function* awaitOrderStatus(
  id: string,
  fromStatus: string,
  client: Client
): Saga<?Order> {
  while (true) {
    yield call(delay, 5000);
    const order: Order = yield call(fetchOrder, id, client);
    if (order.status !== fromStatus) {
      return order;
    }
  }
}

function* handleWebsocketOrderUpdated(
  action: WebsocketOrderUpdatedAction
): Saga<void> {
  try {
    const { orderId } = action.payload;
    const selectedOrderState: SelectedOrderState = yield select(
      selectedOrderStateSelector
    );
    // we only care about these updates if the page is loaded
    if (
      selectedOrderState.status === 'loaded' &&
      selectedOrderState.order.id === orderId
    ) {
      yield call(requestOrderReload, orderId);
    }
  } catch (e) {
    yield call(handleStandardExceptions, e);
  }
}

export function* fetchOrderItemIds(
  orderId: string,
  orderItemIds: string[],
  orderItemGroup: OrderItemGroup
): Saga<void> {
  try {
    yield put(orderItemsLoad(orderItemGroup));
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const params: QueryParams = {
      filters: {
        id: orderItemIds
      }
    };
    const { data } = yield call(
      [client, client.getOrderItems],
      orderId,
      params
    );
    const updatedOrdersItems = data.map((orderItem) =>
      orderItemResponseToOrderItem(orderItem, orderItemGroup)
    );
    yield put(orderItemsPartialLoadSuccess(orderItemGroup, updatedOrdersItems));
  } catch (e) {
    yield call(handleStandardExceptions, e);
  }
}

const orderItemIdsToLoad = {};
function* watchOrderItemsIdsToReload(): Saga<void> {
  while (true) {
    try {
      const orderItemIds = Object.keys(orderItemIdsToLoad);
      if (orderItemIds.length > 0) {
        const orderItemsState: OrderItemsState = yield select(
          orderItemsStateSelector
        );
        const selectedOrderState: SelectedOrderState = yield select(
          selectedOrderStateSelector
        );
        if (selectedOrderState.status === 'loaded') {
          yield fork(
            fetchOrderItemIds,
            selectedOrderState.order.id,
            orderItemIds,
            orderItemsState.orderItemGroup
          );
          orderItemIds.forEach(
            (orderId: string) => delete orderItemIdsToLoad[orderId]
          );
        }
      }
      yield call(delay, 5000);
    } catch (e) {
      yield call(handleStandardExceptions, e);
    }
  }
}

const requestOrderItemReload = (orderItemId: string): void => {
  orderItemIdsToLoad[orderItemId] = true;
};

function* handleWebsocketOrderItemUpdated(
  action: WebsocketOrderItemUpdatedAction
): Saga<void> {
  try {
    const { orderItemId } = action.payload;
    const orderItemsState: OrderItemsState = yield select(
      orderItemsStateSelector
    );
    const orderItemGroup: OrderItemGroup = orderItemsState.orderItemGroup;
    const itemsState: ItemsState = yield call(
      getItemsStateByGroup,
      orderItemGroup
    );
    // we only care about these updates if the page is loaded
    if (itemsState.status === 'loaded') {
      // if the order is on the page, reload it to get updated item counts
      if (
        itemsState.pageItems.findIndex(
          (orderItem: OrderItem) => orderItem.id === orderItemId
        ) !== -1
      ) {
        yield call(requestOrderItemReload, orderItemId);
      }
    }
  } catch (e) {
    logger.logException(
      new Error(
        `order-item.saga#handleWebsocketOrderItemUpdated error: ${e.message}`
      )
    );
  }
}

export function* registerDownloadOrderItem(): Saga<void> {
  yield takeEvery(DOWNLOAD_ORDER_ITEM, handleDownloadOrderItem);
}

export function* registerOrderItemSort(): Saga<void> {
  yield takeLatest(SORT_ORDER_ITEMS, loadCurrentOrderItems);
}

export function* registerOrderItemSearch(): Saga<void> {
  yield takeLatest(SEARCH_ORDER_ITEMS, loadCurrentOrderItems);
}

export function* registerDownloadOrderItemPdf(): Saga<void> {
  yield takeEvery(VIEW_ORDER_ITEM_REPORT, handleViewOrderItemReport);
}

export function* registerFetchSelectedOrderOwner(): Saga<void> {
  yield takeEvery(FETCH_SELECTED_ORDER_OWNER, handleFetchSelectedOrderOwner);
}

export function* registerUpdateReverifyItem(): Saga<void> {
  yield takeLatest(UPDATE_REVERIFY_ITEM, handleUpdateReverifyItem);
}

export function* registerAddItemsToOrder(): Saga<void> {
  yield takeEvery(ADD_ITEMS_TO_ORDER, handleMoveItems);
}

export function* registerRemoveItemFromOrder(): Saga<void> {
  yield takeEvery(REMOVE_ITEMS_FROM_ORDER, handleMoveItems);
}

export function* registerRecoverRemovedItems(): Saga<void> {
  yield takeEvery(RECOVER_REMOVED_ITEMS, handleMoveItems);
}

export function* registerReviewAcceptOrder(): Saga<void> {
  yield takeEvery(REVIEW_ACCEPT_ORDER, handleReviewAcceptOrder);
}

export function* registerReviewCancelOrder(): Saga<void> {
  yield takeEvery(REVIEW_CANCEL_ORDER, handleReviewCancelOrder);
}

export function* registerValidateAddressFieldSuccess(): Saga<void> {
  yield takeEvery(
    VALIDATE_ADDRESS_FIELD_SUCCESS,
    handleAddressVerificationSuccess
  );
}

export function* registerValidateAddressFieldError(): Saga<void> {
  yield takeEvery(VALIDATE_ADDRESS_FIELD_ERROR, handleAddressVerificationError);
}

export function* registerSelectOrderItemGroup(): Saga<void> {
  yield takeEvery(SELECT_ORDER_ITEM_GROUP, loadCurrentOrderItems);
}

export function* registerWebsocketOrderUpdated(): Saga<void> {
  yield takeEvery(WEBSOCKET_ORDER_UPDATED, handleWebsocketOrderUpdated);
}

export function* registerWatchOrderItemIdsToReload(): Saga<void> {
  yield spawn(watchOrderItemsIdsToReload);
}

export function* registerWebsocketOrderItemUpdated(): Saga<void> {
  yield takeEvery(
    WEBSOCKET_ORDER_ITEM_UPDATED,
    handleWebsocketOrderItemUpdated
  );
}
