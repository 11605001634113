// @flow
import React from 'react';
import { Dropdown } from '@hc/component-lib';

type Organization = {
  id: number,
  name: string,
  slug: string
}

type DropdownSource = { value: number, label: string }[]

type SelectOrgProps = {
  /** Available Organizations array from user object. Array of objects in format { id: number, name: string, slug: string} */
  availableOrganizations: Organization[],
  /** onChange will be called with the organization's id */
  onChange?: (id: number) => void
}

type SelectOrgState = {
  selectedOrgId: ?number,
  dropdownSource: DropdownSource
}

const mapOrgsToDropdownSource = (organizations : Organization[]) : DropdownSource => {
  return organizations.map((orgObj) => {
    return { value: orgObj.id, label: orgObj.name };
  });
};

// have to pass void as third arg for styleguidist to build (but it breaks flow)
// $FlowFixMe
class SelectOrg extends React.Component <SelectOrgProps, SelectOrgState, void> {
  constructor (props : SelectOrgProps) {
    super(props);
    this.state = {
      selectedOrgId: null,
      dropdownSource: mapOrgsToDropdownSource(props.availableOrganizations)
    };
  }

  handleChange = (value : number) => {
    this.setState({ selectedOrgId: value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps (newProps : SelectOrgProps) {
    if (newProps.availableOrganizations !== this.props.availableOrganizations) {
      this.setState({
        dropdownSource: mapOrgsToDropdownSource(newProps.availableOrganizations)
      });
    }
  }

  render () {
    const { availableOrganizations, ...rest } = this.props;
    return (
      <Dropdown
        source={this.state.dropdownSource}
        value={this.state.selectedOrgId}
        {...rest}
        onChange={this.handleChange}
      />
    );
  }
}

export default SelectOrg;
