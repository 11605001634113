// @flow
import type { Saga } from 'redux-saga';
import type { AcceptOrderAcceptedAction } from '../actions/accept-order.actions';

import { delay } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Client } from 'src/api/order-manager-api-client';
import {
  resetAcceptOrder,
  acceptOrderLoadComplete,
  acceptOrderLoadError,
  acceptOrderComplete,
  ORDER_ACCEPTED
} from '../actions/accept-order.actions';
import { handleStandardExceptions } from 'src/sagas/helpers';
import userTokenSelector from 'src/selectors/user-token.selector';

export function* loadAcceptOrderDetailsSaga({
  orderId
}: {
  orderId: string
}): Saga<void> {
  yield put(resetAcceptOrder());

  try {
    const userToken = yield select(userTokenSelector);
    // Call the API to get the order details
    const client = new Client(userToken);
    const response = yield call(
      [client, client.getAcceptOrderDetails],
      orderId
    );

    const orderDetails = {};
    orderDetails.orderId = orderId;
    orderDetails.orderType = response.order_type;
    orderDetails.address = response.address;
    orderDetails.unit = response.unit;
    orderDetails.city = response.city;
    orderDetails.state = response.state;
    orderDetails.zipcode = response.zipcode;

    if (response.appraisal_link) {
      orderDetails.link = response.appraisal_link;
    }
    yield put(acceptOrderLoadComplete(orderDetails));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(acceptOrderLoadError(e.message));
  }
}

export function* acceptOrder(action: AcceptOrderAcceptedAction): Saga<void> {
  const userToken = yield select(userTokenSelector);
  const client = new Client(userToken);
  try {
    yield call([client, client.submitAcceptOrder], action.payload.orderId);
    let link = null;
    while (!link) {
      const orderDetails = yield call(
        [client, client.getAcceptOrderDetails],
        action.payload.orderId
      );
      if (orderDetails['appraisal_link']) {
        link = orderDetails['appraisal_link'];
      }
      yield call(delay, 1000);
    }
    yield put(acceptOrderComplete(link));
  } catch (e) {
    yield put(acceptOrderLoadError(e.message));
  }
}

export function* acceptOrderSaga(): Saga<void> {
  yield takeEvery(ORDER_ACCEPTED, acceptOrder);
}
