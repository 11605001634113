// @flow
import { getHCLauncherProductName } from '../hc.names';

export const DATA_FACTORY_TOOLS = 'Data Factory Tools';

export const TRACKING_NAME_DATA_FACTORY_TOOLS = 'df_tools';

export const INTERNAL_PRODUCT_SLUGS = {
  DATA_FACTORY_TOOLS
};

export const SORTED_INTERNAL_PRODUCT_LABELS = [
  'Data Factory Tools'
];

export const DATA_FACTORY_TOOLS_PRODUCT = {
  key: DATA_FACTORY_TOOLS,
  trackingName: TRACKING_NAME_DATA_FACTORY_TOOLS,
  title: 'Data Factory Tools',
  icon: null,
  dataHcName: getHCLauncherProductName(DATA_FACTORY_TOOLS)
};

export const INTERNAL_PRODUCTS = {
  [DATA_FACTORY_TOOLS]: DATA_FACTORY_TOOLS_PRODUCT
};
