// @flow
export const WORKFLOW_AUTO_REFRESH_ENABLE = 'WORKFLOW_AUTO_REFRESH_ENABLE';
export const WORKFLOW_AUTO_REFRESH_DISABLE = 'WORKFLOW_AUTO_REFRESH_DISABLE';
export const WORKFLOW_AUTO_REFRESH_UPDATE_CONFIG =
  'WORKFLOW_AUTO_REFRESH_UPDATE_CONFIG';
export const WORKFLOW_AUTO_REFRESH_ALIVE = 'WORKFLOW_AUTO_REFRESH_ALIVE';

type RefreshConfig = { amount?: number, unit?: 'seconds' | 'minutes' };
export type WorkfloWAutoRefreshEnableAction = {
  type: 'WORKFLOW_AUTO_REFRESH_ENABLE'
};
export type WorkfloWAutoRefreshDisableAction = {
  type: 'WORKFLOW_AUTO_REFRESH_DISABLE'
};
export type WorkflowAutoRefreshUpdateConfigAction = {
  type: 'WORKFLOW_AUTO_REFRESH_UPDATE_CONFIG',
  payload: RefreshConfig
};
export type WorkflowAutoRefreshAliveAction = {
  type: 'WORKFLOW_AUTO_REFRESH_ALIVE',
  payload: { timestamp: string }
};

export type WorkflowAutoRefreshAction =
  | WorkfloWAutoRefreshEnableAction
  | WorkfloWAutoRefreshDisableAction
  | WorkflowAutoRefreshUpdateConfigAction
  | WorkflowAutoRefreshAliveAction;

export const workflowRefreshEnable = (): WorkfloWAutoRefreshEnableAction => ({
  type: WORKFLOW_AUTO_REFRESH_ENABLE
});
export const workflowRefreshDisable = (): WorkfloWAutoRefreshDisableAction => ({
  type: WORKFLOW_AUTO_REFRESH_DISABLE
});
export const workflowRefreshUpdateConfig = (
  config: RefreshConfig
): WorkflowAutoRefreshUpdateConfigAction => {
  const { amount, unit } = config;
  return {
    type: WORKFLOW_AUTO_REFRESH_UPDATE_CONFIG,
    payload: {
      amount,
      unit
    }
  };
};
export const workflowRefreshAlive = (
  timestamp: string
): WorkflowAutoRefreshAliveAction => ({
  type: WORKFLOW_AUTO_REFRESH_ALIVE,
  payload: { timestamp }
});
