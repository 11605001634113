// @flow
import React, { type ComponentType } from 'react';
import { UrlsContext } from './context';

export const withUrls = (Component: ComponentType<any>) => {
  return (props: any) => {
    return (
      <UrlsContext.Consumer>
        {(urls: any) => {
          return <Component {...urls} {...props} />;
        }}
      </UrlsContext.Consumer>
    );
  };
};
