// @flow
import type { RouteDefinition } from '../types/route';
import type { ViewTypes } from '../types/view';
import type { ViewState } from '../reducers/view.reducer';

import { call, put, select } from 'redux-saga/effects';

import { history } from '../history/history';
import { testAndClearSkipRouting } from '../history/route-to';
import { viewLoading, viewLoaded } from '../actions/view.actions';
import viewStateSelector from '../selectors/view-state.selector';
import { handleStandardExceptions } from './helpers';
import { resolveUserSaga } from './user.saga';
import queryString from 'query-string';

export function routeActionsCreator(
  ROUTES: RouteDefinition,
  userResolver: Function = resolveUserSaga,
  exceptionHandler: Function = handleStandardExceptions
) {
  const routeConfigById: RouteDefinition = Object.keys(ROUTES).reduce(
    (accum, routeKey: string) => {
      accum[ROUTES[routeKey].id] = ROUTES[routeKey];
      return accum;
    },
    {}
  );
  function wrapSaga(
    view: ViewTypes,
    loginRequired: boolean,
    queryParams: ?(string[]),
    saga
  ) {
    return function* (args) {
      if (
        history.location.state &&
        testAndClearSkipRouting(history.location.state.stateId)
      ) {
        return;
      }
      try {
        const viewState: ViewState = yield select(viewStateSelector);
        const cleanupAction =
          routeConfigById[viewState.currentView] &&
          routeConfigById[viewState.currentView].cleanupAction;
        if (cleanupAction != null) {
          yield put(cleanupAction);
        }
        yield put(viewLoading(view));
        yield call(userResolver, loginRequired);
        if (queryParams && history.location.search) {
          const qsp = queryString.parse(history.location.search);
          for (const qpn of queryParams) {
            args[qpn] = qsp[qpn];
          }
        }
        yield call(saga, args);
        yield put(viewLoaded(view));
      } catch (e) {
        yield call(exceptionHandler, e, true);
      }
    };
  }
  return Object.keys(ROUTES).reduce((routeActions, key) => {
    const def = ROUTES[key];
    routeActions[key] = wrapSaga(
      def.id,
      def.requiresLogin,
      def.queryParams,
      def.saga
    );
    return routeActions;
  }, {});
}
