// @flow
import {
  call,
  put,
  select,
  takeEvery,
  fork,
  spawn,
  throttle
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import type { Saga } from 'redux-saga';

import type { Order, OrderProgress } from 'src/client/types/order';
import type { GroupKey } from 'src/client/types/add-order';
import type { OrderSet } from 'src/client/types/order-set';
import type { QueryParams } from 'src/types/list';
import type {
  FetchOrderProgressAction,
  DownloadOrderSummaryAction
} from 'src/client/actions/orders.actions';
import type { OrdersState } from 'src/client/reducers/orders.reducer';
import { authClient } from 'src/sagas/auth-client-instance';
import { type Query, defaultQuery } from '../../auth-lib/services';

import { Client } from 'src/api/order-manager-api-client';
import {
  ordersLoad,
  ordersLoadSuccess,
  ordersLoadError,
  fetchOrderProgressSuccess,
  fetchOrderProgressError,
  SEARCH_ORDERS,
  SORT_ORDERS,
  FETCH_ORDER_PROGRESS,
  DOWNLOAD_ORDER_SUMMARY_CSV,
  ordersPartialLoadSuccess,
  THROTTLED_LOAD_ORDERS,
  throttledLoadOrders,
  CHANGE_ORDER_SET,
  CHANGE_OWNER_ID,
  fetchOrgUsersSuccess,
  fetchOrgUsersError,
  VIEW_ORDER_REPORT,
  type ViewOrderReportAction
} from '../actions/orders.actions';
import { showAddOrderDialog } from '../actions/add-order.actions';
import { handleStandardExceptions } from '../../sagas/helpers';
import { ORDER_SETS, ORDER_SET_TO_STATUSES } from '../constants/order-sets';
import { ordersStateSelector } from '../selectors/orders.selectors';
import { isAdminUserSelector } from '../../selectors/user-state.selector';
import userTokenSelector from '../../selectors/user-token.selector';
import routeTo from '../../history/WithRouterLink';
import { orderResponseToCamelCase } from '../helpers/order-helpers';
import * as logger from '../../logger';
import {
  addFileExtension,
  slugifyFileName
} from '../../helpers/file-name-helpers';
import {
  getOrderingParamsFromQS,
  buildListLink
} from '../helpers/list-helpers';
import {
  connectWebsocket,
  WEBSOCKET_ORDER_UPDATED,
  type WebsocketOrderUpdatedAction,
  WEBSOCKET_ORDER_ITEM_UPDATED,
  type WebsocketOrderItemUpdatedAction
} from '../actions/websocket.actions';
import { orderStatusBelongsInOrderSet } from '../helpers/status-helpers';
import { ORDERS_ROUTE } from '../routes';
import { showToast } from '../../actions/view.actions';

export function* fetchOrders(
  orderSet: OrderSet = ORDER_SETS.ALL,
  params: QueryParams,
  ownerId: ?string
): Saga<void> {
  try {
    yield put(ordersLoad());
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    let paramsWithFilters: QueryParams = {
      ...params
    };
    if (orderSet !== ORDER_SETS.ALL) {
      const orderStatuses = ORDER_SET_TO_STATUSES[orderSet];
      paramsWithFilters = {
        ...params,
        filters: {
          status: orderStatuses
        }
      };
    }
    if (ownerId) {
      paramsWithFilters = {
        ...params,
        filters: {
          ...paramsWithFilters.filters,
          ownerId: ownerId
        }
      };
    }
    const {
      links,
      ordering: defaultOrdering,
      data
    } = yield call([client, client.getOrders], paramsWithFilters);
    const orders = data.map((order) => orderResponseToCamelCase(order));
    const defaultOrderingParams = getOrderingParamsFromQS(defaultOrdering);
    yield put(
      ordersLoadSuccess({
        orderSet,
        orders,
        links,
        ownerId,
        page: params.page,
        query: params.query,
        ordering: params.ordering || [],
        defaultOrdering: defaultOrderingParams
      })
    );
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(ordersLoadError(orderSet, e.message));
  }
}

export function* fetchOrderIds(orderIds: string[]): Saga<void> {
  try {
    yield put(ordersLoad());
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const params: QueryParams = {
      filters: {
        id: orderIds
      }
    };
    const { data } = yield call([client, client.getOrders], params);
    const updatedOrders = data.map((order) => orderResponseToCamelCase(order));
    yield put(ordersPartialLoadSuccess(updatedOrders));
  } catch (e) {
    yield call(handleStandardExceptions, e);
  }
}

export function* resolveOrders({
  orderSet = ORDER_SETS.ALL,
  page,
  query,
  ordering,
  createProduct,
  ownerId
}: {
  orderSet: OrderSet,
  page?: number,
  query?: string,
  ordering?: string,
  createProduct: ?GroupKey,
  ownerId: ?string
}): Saga<void> {
  try {
    const isAdminUser = yield select(isAdminUserSelector);
    if (isAdminUser) {
      yield call(fetchOrgUsers, defaultQuery);
    }

    const params: QueryParams = {};
    if (page) {
      params.page = page;
    }
    if (query) {
      params.query = query;
    }
    const orderingParams = getOrderingParamsFromQS(ordering);
    if (orderingParams.length > 0) {
      params.ordering = orderingParams;
    }
    yield call(fetchOrders, orderSet, params, ownerId);
    yield put(connectWebsocket());
    if (createProduct) {
      yield put(showAddOrderDialog(createProduct));
    }
  } catch (e) {
    console.log('error in resolveOrders', e);
    yield call(handleStandardExceptions, e);
  }
}

export function* loadCurrentOrders(): Saga<void> {
  const ordersState: OrdersState = yield select(ordersStateSelector);
  if (ordersState.status === 'loaded') {
    const orderSet = ordersState.orderSet;
    const ownerId = ordersState.ownerId;
    const params: QueryParams = {
      query: ordersState.query,
      ordering: ordersState.ordering
    };
    if (ordersState.page) {
      params.page = ordersState.page;
    }
    yield call(fetchOrders, orderSet, params, ownerId);
    yield call(
      routeTo,
      buildListLink(
        ORDERS_ROUTE,
        ordersState.query,
        ordersState.ordering,
        ordersState.page,
        orderSet,
        ownerId
      ),
      true
    );
  }
}

export function* fetchOrderProgress(order: Order): Saga<void> {
  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const progressResponse: OrderProgress = yield call(
      [client, client.getOrderProgress],
      order.id
    );
    yield put(fetchOrderProgressSuccess(order.id, progressResponse));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(fetchOrderProgressError(order.id, e.message));
  }
}

export function* fetchOrgUsers(query: Query): Saga<void> {
  try {
    const userToken = yield select(userTokenSelector);
    const orgUsersResponse = yield call(
      [authClient, authClient.getOrgUsers],
      userToken,
      null,
      null,
      query || defaultQuery
    );
    yield put(fetchOrgUsersSuccess(orgUsersResponse));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(fetchOrgUsersError(e.message));
  }
}

function* handleFetchOrderProgress(
  action: FetchOrderProgressAction
): Saga<void> {
  const { order } = action.payload;
  yield call(fetchOrderProgress, order);
}

function* handleDownloadOrderSummary(
  action: DownloadOrderSummaryAction
): Saga<void> {
  const { orderId, filename } = action.payload;
  const sluggedFileNameWithExtension = addFileExtension(
    slugifyFileName(filename),
    'zip'
  );
  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    yield call(
      [client, client.downloadSummary],
      orderId,
      sluggedFileNameWithExtension
    );
  } catch (e) {
    yield call(handleStandardExceptions, e);
  }
}

export function* fetchOrder(orderId: string, client: any): Saga<Order> {
  const response = yield call([client, client.getOrderDetails], orderId);
  const order: Order = orderResponseToCamelCase(response);
  return order;
}

const orderIdsToLoad = {};
function* watchOrderIdsToReload(): Saga<void> {
  while (true) {
    try {
      const orderIds = Object.keys(orderIdsToLoad);
      if (orderIds.length > 0) {
        yield fork(fetchOrderIds, orderIds);
        orderIds.forEach((orderId: string) => delete orderIdsToLoad[orderId]);
      }
      yield call(delay, 5000);
    } catch (e) {
      yield call(handleStandardExceptions, e);
    }
  }
}

export const requestOrderReload = (orderId: string): void => {
  orderIdsToLoad[orderId] = true;
};

function* handleWebsocketOrderUpdated(
  action: WebsocketOrderUpdatedAction
): Saga<void> {
  try {
    const { orderId, status: orderStatus } = action.payload;
    const ordersState: OrdersState = yield select(ordersStateSelector);
    // we only care about these updates if the page is loaded
    if (ordersState.status === 'loaded') {
      const belongsInOrderSet = orderStatusBelongsInOrderSet(orderStatus);
      // if after update, the order belongs in the currently loaded order set
      if (ordersState.orderSet === belongsInOrderSet) {
        // if the updated order is currently on this page
        if (
          ordersState.pageItems.findIndex(
            (order: Order) => order.id === orderId
          ) !== -1
        ) {
          yield call(requestOrderReload, orderId);
          // if the updated order isn't on this page, but should be
        } else {
          // reload with orders
          yield put(throttledLoadOrders());
        }
        // else order DOES NOT belong in currently selected order set
      } else {
        // if the updated order is currently on this page
        if (
          ordersState.pageItems.findIndex(
            (order: Order) => order.id === orderId
          ) !== -1
        ) {
          // reload WITHOUT order
          yield put(throttledLoadOrders());
        }
      }
    }
  } catch (e) {
    yield call(handleStandardExceptions, e);
  }
}

function* handleWebsocketOrderItemUpdated(
  action: WebsocketOrderItemUpdatedAction
): Saga<void> {
  try {
    const { orderId } = action.payload;
    const ordersState: OrdersState = yield select(ordersStateSelector);
    // we only care about these updates if the page is loaded
    if (ordersState.status === 'loaded') {
      // if the order is on the page, reload it to get updated item counts
      if (
        ordersState.pageItems.findIndex(
          (order: Order) => order.id === orderId
        ) !== -1
      ) {
        yield call(requestOrderReload, orderId);
      }
    }
  } catch (e) {
    logger.logException(
      new Error(
        `Order.saga#handleWebsocketOrderItemUpdated error: ${e.message}`
      )
    );
  }
}

export function* handleViewOrderReport(
  action: ViewOrderReportAction
): Saga<void> {
  const { orderId } = action.payload;

  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const response = yield call([client, client.downloadOrderPdf], orderId);
    // SECURITY: false posititive, window.open is not fs.open @john
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const opened = window.open(response.url);
    if (!opened) {
      yield put(
        showToast(
          'Could not open the requested report.  Please allow popups from this site and try again.'
        )
      );
    }
  } catch (e) {
    if (e.name === 'NotFoundError') {
      yield put(
        showToast('The requested report is not available for this item.')
      );
    } else {
      yield call(handleStandardExceptions, e);
      yield put(showToast('Could not retrieve report.  Please try again.'));
    }
  }
}

export function* registerOrderSeach(): Saga<void> {
  yield takeEvery(SEARCH_ORDERS, loadCurrentOrders);
}

export function* registerOrderSort(): Saga<void> {
  yield takeEvery(SORT_ORDERS, loadCurrentOrders);
}

export function* registerChangeOrderSet(): Saga<void> {
  yield takeEvery(CHANGE_ORDER_SET, loadCurrentOrders);
}

export function* registerChangeOwnerId(): Saga<void> {
  yield takeEvery(CHANGE_OWNER_ID, loadCurrentOrders);
}

export function* registerFetchOrderProgress(): Saga<void> {
  yield takeEvery(FETCH_ORDER_PROGRESS, handleFetchOrderProgress);
}

export function* registerDownloadOrderSummary(): Saga<void> {
  yield takeEvery(DOWNLOAD_ORDER_SUMMARY_CSV, handleDownloadOrderSummary);
}

export function* registerWatchOrderIdsToReload(): Saga<void> {
  yield spawn(watchOrderIdsToReload);
}

export function* registerThrottledFetchOrders(): Saga<void> {
  yield throttle(5000, THROTTLED_LOAD_ORDERS, loadCurrentOrders);
}

export function* registerWebsocketOrderUpdated(): Saga<void> {
  yield takeEvery(WEBSOCKET_ORDER_UPDATED, handleWebsocketOrderUpdated);
}

export function* registerWebsocketOrderItemUpdated(): Saga<void> {
  yield takeEvery(
    WEBSOCKET_ORDER_ITEM_UPDATED,
    handleWebsocketOrderItemUpdated
  );
}

export function* registerViewOrderReport(): Saga<void> {
  yield takeEvery(VIEW_ORDER_REPORT, handleViewOrderReport);
}
