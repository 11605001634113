// @flow
import React from 'react';
import { RTThemedProgressBar } from '@hc/component-lib/lib/rt-themed';
import classNames from 'classnames';

import Search from 'src/components/Search.jsx';

import styles from './ListHeader.css';

type ListHeaderProps = {
  title: string,
  className?: string,
  loading: boolean,
  search?: {
    onSearch: (string) => void,
    query: ?string
  }
};

const ListHeader = (props: ListHeaderProps) => {
  const { title, loading, className, search } = props;

  return (
    <div className={classNames(styles.listHeader, className)}>
      <div className={styles.titleSearchContainer}>
        <span className={styles.title}>{title}</span>
        {search && (
          <Search
            className={styles.searchBox}
            query={search.query}
            onSearch={search.onSearch}
          />
        )}
      </div>
      <div className={styles.loaderContainer}>
        {loading && (
          <RTThemedProgressBar
            theme={styles}
            type="circular"
            mode="indeterminate"
          />
        )}
      </div>
    </div>
  );
};

export default ListHeader;
