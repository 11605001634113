// @flow
import { eventChannel, END, delay } from 'redux-saga';
import {
  select,
  take,
  fork,
  call,
  put,
  cancel,
  cancelled,
  spawn
} from 'redux-saga/effects';

import type { Saga } from 'redux-saga';

import type { OrderStatus } from 'src/client/types/order';
import type { OrderItemStatus } from 'src/client/types/order-item';
import type { OrderExportWebsocketUpdate } from 'src/client/types/order-export';

import { Client } from 'src/api/order-manager-api-client';
import {
  CONNECT_WEBSOCKET,
  CLOSE_WEBSOCKET,
  websocketOrderUpdated,
  websocketOrderItemUpdated,
  websocketOrderExportUpdated
} from 'src/client/actions/websocket.actions';
import { LOGOUT_COMPLETE } from 'src/actions/user.actions';
import userTokenSelector from 'src/selectors/user-token.selector';
import { handleStandardExceptions, snakeToCamel } from 'src/sagas/helpers';
import { getWebsocketUrl } from 'src/helpers/url-helpers';

export const STREAMS = {
  WEBSOCKET: 'websocket',
  ORDER: 'order',
  ORDER_ITEM: 'orderitem',
  ORDER_EXPORT: 'orderdownloadrequest'
};
export const INITIAL_RECONNECT_DELAY = 100;
export const MAX_RECONNECT_ATTEMPT_DELAY = 10000;
const SOCKET_OPENED = Symbol('websocket opened');
const SOCKET_CLOSED = Symbol('websocket closed');
const SETTINGS = window.SETTINGS;

type WebsocketOrderMessage = {
  stream: 'order',
  payload: {
    action: 'created' | 'updated',
    order_id: string,
    data: {
      id: string,
      status: OrderStatus,
      created_at: string,
      updated_at: string,
      cancelled: boolean
    }
  }
};
type WebsocketOrderItemMessage = {
  stream: 'orderitem',
  payload: {
    action: 'updated',
    order_id: string,
    data: {
      id: string,
      status: OrderItemStatus,
      created_at: string,
      updated_at: string,
      cancelled: boolean
    }
  }
};
type WebsocketOrderExportMessage = {
  stream: 'orderdownloadrequest',
  payload: {
    action: 'updated',
    order_id: string,
    data: OrderExportWebsocketUpdate
  }
};
type WebsocketMessage =
  | WebsocketOrderMessage
  | WebsocketOrderItemMessage
  | WebsocketOrderExportMessage;

export const messageToActionEmit = (emit: any, msgData: WebsocketMessage) => {
  switch (msgData.stream) {
    case STREAMS.ORDER: {
      const { order_id: orderId, action, data } = msgData.payload;
      switch (action) {
        case 'updated':
          return emit(
            websocketOrderUpdated(orderId, data.status, data.cancelled)
          );
      }
      return;
    }
    case STREAMS.ORDER_ITEM: {
      const { order_id: orderId, action, data } = msgData.payload;
      switch (action) {
        case 'updated':
          return emit(
            websocketOrderItemUpdated(
              orderId,
              data.id,
              data.status,
              data.cancelled
            )
          );
      }
      return;
    }
    case STREAMS.ORDER_EXPORT: {
      const { order_id: orderId, action, data } = msgData.payload;
      switch (action) {
        case 'updated': {
          const exportUpdatedObj: OrderExportWebsocketUpdate = snakeToCamel(
            data,
            Object.keys(data)
          );
          return emit(websocketOrderExportUpdated(orderId, exportUpdatedObj));
        }
      }
    }
  }
};

export function eventChannelHandlerFactory(ws: any) {
  return (emit: any) => {
    ws.onopen = () => {
      emit(SOCKET_OPENED);
    };

    ws.onmessage = (e) => {
      let msgData = null;
      try {
        msgData = JSON.parse(e.data);
      } catch (err) {
        console.error(`Error parsing: ${e.data}`);
      }

      if (msgData) {
        messageToActionEmit(emit, msgData);
      }
    };

    ws.onclose = (e) => {
      console.log(`websocket onclose, ${JSON.stringify(e)}`);
      emit(SOCKET_CLOSED);
      emit(END);
    };

    ws.onerror = (e) => {
      console.log(`websocket onerror, ${JSON.stringify(e)}`);
    };

    // put any logic here that should happen on Saga event channel unsubscribe
    const eventChannelUnsubscribe = () => {};

    return eventChannelUnsubscribe;
  };
}

export function* connectWebsocket(): Saga<void> {
  let connectionCloseRequested = false;
  let readChannel;
  let ws;
  let reconnectDelay = INITIAL_RECONNECT_DELAY;
  while (!connectionCloseRequested) {
    try {
      const userToken = yield select(userTokenSelector);
      const client = new Client(userToken);
      const wsTokenResponse: { token: string } = yield call([
        client,
        client.websocketTokenExchange
      ]);
      if (wsTokenResponse && wsTokenResponse.token) {
        ws = new window.WebSocket(
          getWebsocketUrl(
            SETTINGS.ORDER_MANAGER_WEBSOCKET_URL,
            wsTokenResponse.token
          )
        );
        readChannel = eventChannel(eventChannelHandlerFactory(ws));
        while (true) {
          const action = yield take(readChannel);
          if (action === SOCKET_OPENED) {
            // Reset the connect delay once we reconnect
            reconnectDelay = INITIAL_RECONNECT_DELAY;
          } else if (action === SOCKET_CLOSED) {
            console.log(
              'Websocket channel closed, will attempt reconnect if needed'
            );
            break;
          } else {
            yield put(action);
          }
        }
      }
    } catch (e) {
      yield call(handleStandardExceptions, e);
    } finally {
      // The connection has been closed in some manner...make sure we clean up our mess
      if (readChannel) {
        readChannel.close();
        readChannel = null;
      }
      if (ws) {
        ws.close();
        ws = null;
      }
      // if connection close was requested, close up shop
      if (yield cancelled()) {
        console.log('websocket connection cancelled');
        connectionCloseRequested = true;
        // if not, attempt to reconnect after a delay
      } else {
        yield call(delay, reconnectDelay);
        console.log(
          `websocket attempting to reconnect after ${reconnectDelay}`
        );
        // Exponential reconnect backoff
        reconnectDelay = Math.min(
          reconnectDelay * 2,
          MAX_RECONNECT_ATTEMPT_DELAY
        );
      }
    }
  }
}

export function* websocketFlow(): Saga<void> {
  while (true) {
    yield take(CONNECT_WEBSOCKET);
    const connectTask = yield fork(connectWebsocket);
    yield take([CLOSE_WEBSOCKET, LOGOUT_COMPLETE]);
    yield cancel(connectTask);
  }
}

export function* registerWebsocketFlow(): Saga<void> {
  yield spawn(websocketFlow);
}
