// @flow
import type { OrderItemStatus } from 'src/client/types/order-item';

export const ORDER_ITEM_STATUSES: { [key: string]: OrderItemStatus } = {
  PROCESSING: '', // empty string is returned from api for order items without status
  INSPECTION_PENDING: 'InspectionPending',
  INSPECTION_COMPLETE: 'InspectionComplete',
  APPRAISAL_PENDING: 'AppraisalPending',
  APPRAISAL_REVISION_PENDING: 'AppraisalRevisionPending',
  EVALUATION_COMPLETE: 'EvaluationComplete',
  REPORT_PENDING: 'ReportPending',
  COMPLETE: 'Complete',
  CANCEL_PENDING: 'CancelPending',
  CANCELLED: 'Cancelled',
  QC_REVIEW: 'QCReview',
  INSPECTION_REVISION: 'InspectionRevision',
  INSPECTION_REVIEW: 'InspectionReview',
  INSPECTION_CORRECTION_REVIEW: 'InspectionCorrectionReview',
  VALUATION_REVIEW: 'ValuationReview',
  REVISION_REQUESTED: 'RevisionRequested'
};
