// @flow
/* global TimeoutID */
import React from 'react';
import {
  RTThemedDialog,
  RTThemedFontIcon
} from '@hc/component-lib/lib/rt-themed';

import styles from './MoveCompleteDialog.css';

type MoveCompleteDialogProps = {
  active: boolean,
  succeededMessage: string,
  failedMessage: string,
  timeout: number,
  onTimeout: () => void,
  onDismiss: () => void
};

class MoveCompleteDialog extends React.Component<
  MoveCompleteDialogProps,
  { succeededMessage: string }
> {
  curTimeout: ?TimeoutID;

  scheduleTimout: (props: MoveCompleteDialogProps) => void;

  constructor(props: MoveCompleteDialogProps) {
    super(props);
    this.state = { succeededMessage: props.succeededMessage };
  }

  componentDidMount() {
    if (this.props.active && this.props.timeout) {
      this.scheduleTimeout(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: MoveCompleteDialogProps) {
    if (nextProps.active && nextProps.timeout) {
      this.scheduleTimeout(nextProps);
      this.setState({ succeededMessage: nextProps.succeededMessage });
    } else if (!nextProps.active) {
      this.setState({ succeededMessage: this.props.succeededMessage });
    }
  }

  componentWillUnmount() {
    if (this.curTimeout) {
      clearTimeout(this.curTimeout);
    }
  }

  scheduleTimeout = (props: MoveCompleteDialogProps) => {
    const { onTimeout, timeout } = props;
    if (this.curTimeout) clearTimeout(this.curTimeout);
    this.curTimeout = setTimeout(() => {
      if (onTimeout) onTimeout();
      this.curTimeout = null;
    }, timeout);
  };

  render() {
    const { active, failedMessage, onDismiss } = this.props;

    return (
      <RTThemedDialog
        theme={styles}
        onEscKeyDown={onDismiss}
        onOverlayClick={onDismiss}
        active={active}
      >
        {this.state.succeededMessage && (
          <div className={styles.content}>
            <RTThemedFontIcon
              className={styles.succeededIcon}
              value="check_circle"
            />
            <div className={styles.succeededMessage}>
              {this.state.succeededMessage}
            </div>
            {failedMessage && (
              <div className={styles.failedMessage}>{failedMessage}</div>
            )}
          </div>
        )}
        {!this.state.succeededMessage && (
          <div className={styles.content}>
            <RTThemedFontIcon className={styles.failedIcon} value="error" />
            <div className={styles.failedMessage}>{failedMessage}</div>
          </div>
        )}
      </RTThemedDialog>
    );
  }
}

export default MoveCompleteDialog;
