// @flow
import type { ViewTypes } from 'src/types/view';

export const VIEW_LOADING = 'VIEW_LOADING';
export const VIEW_LOADED = 'VIEW_LOADED';
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

export type ViewLoadAction = {
  type: 'VIEW_LOADING' | 'VIEW_LOADED',
  payload: {
    view: ViewTypes
  }
};

export type ShowToastAction = {
  type: 'SHOW_TOAST',
  payload: {
    message: string
  }
};

export type HideToastAction = {
  type: 'HIDE_TOAST'
};

export type ViewAction = ViewLoadAction | ShowToastAction | HideToastAction;

export const viewLoading = (view: ViewTypes): ViewAction => ({
  type: VIEW_LOADING,
  payload: { view }
});
export const viewLoaded = (view: ViewTypes): ViewAction => ({
  type: VIEW_LOADED,
  payload: { view }
});
export const showToast = (message: string): ShowToastAction => ({
  type: SHOW_TOAST,
  payload: { message }
});
export const hideToast = (): HideToastAction => ({ type: HIDE_TOAST });
