// @flow
import type { Dispatch } from 'redux';
import type { AcceptOrderState } from '../reducers/accept-order.reducer';
import type { UserDetailsState } from 'src/reducers/user-details.reducer';

import React from 'react';
import { connect } from 'react-redux';

import SimpleLayout from 'src/layouts/Simple';
import { acceptOrder, rejectOrder } from '../actions/accept-order.actions';
import OrderDetails from '../components/OrderDetails';
import OrderAccepted from '../components/OrderAccepted';
import OrderRejected from '../components/OrderRejected';
import OrderError from '../components/OrderError';
import acceptOrderStateSelector from 'src/partner/selectors/accept-order.selector';
import { userDetailsStateSelector } from 'src/selectors/user-state.selector';

type AcceptOrderProps = {
  onAcceptOrder: (orderId: string) => void,
  onRejectOrder: () => void,
  orderState: AcceptOrderState,
  userDetailsState: UserDetailsState
};

const AcceptOrder = (props: AcceptOrderProps) => {
  const { orderState, userDetailsState, onAcceptOrder, onRejectOrder } = props;
  const userDetails =
    userDetailsState.status === 'loaded' ? userDetailsState.userDetails : null;
  if (orderState.status === 'error') {
    return <OrderError message={orderState.message} />;
  } else if (orderState.status === 'empty') {
    return <div />;
  } else if (orderState.status === 'accepted') {
    return <OrderAccepted link={orderState.link} />;
  } else if (orderState.status === 'rejected') {
    return <OrderRejected />;
  } else {
    return (
      <OrderDetails
        {...{ orderState, userDetails, onAcceptOrder, onRejectOrder }}
      />
    );
  }
};

function mapStateToProps(state) {
  return {
    orderState: acceptOrderStateSelector(state),
    userDetailsState: userDetailsStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onAcceptOrder: (orderId) => dispatch(acceptOrder(orderId)),
    onRejectOrder: () => dispatch(rejectOrder())
  };
}

const AcceptOrderWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleLayout(AcceptOrder));

export default AcceptOrderWrapped;
