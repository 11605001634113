// @flow
import React from 'react';
import { Checkbox } from '@hc/component-lib/lib';

const FormCheckbox = ({ input, meta, theme, ...props }: any) => (
  <div>
    <Checkbox {...input} {...props} />
    {meta.touched && meta.error && (
      <div className={theme.error}>{meta.error}</div>
    )}
  </div>
);

export default FormCheckbox;
