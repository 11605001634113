// @flow
import type { OrderStatus } from 'src/client/types/order';
import type { OrderItemStatus } from 'src/client/types/order-item';
import type { OrderExportWebsocketUpdate } from 'src/client/types/order-export';

export const CONNECT_WEBSOCKET = 'CONNECT_WEBSOCKET';
export const WEBSOCKET_ORDER_UPDATED = 'WEBSOCKET_ORDER_UPDATED';
export const WEBSOCKET_ORDER_ITEM_UPDATED = 'WEBSOCKET_ORDER_ITEM_UPDATED';
export const WEBSOCKET_ORDER_EXPORT_UPDATED = 'WEBSOCKET_ORDER_EXPORT_UPDATED';
export const CLOSE_WEBSOCKET = 'CLOSE_WEBSOCKET';

export type ConnectWebsocketAction = { type: 'CONNECT_WEBSOCKET' };
export type WebsocketOrderUpdatedAction = {
  type: 'WEBSOCKET_ORDER_UPDATED',
  payload: { orderId: string, status: OrderStatus, cancelled: boolean }
};
export type WebsocketOrderItemUpdatedAction = {
  type: 'WEBSOCKET_ORDER_ITEM_UPDATED',
  payload: {
    orderId: string,
    orderItemId: string,
    status: OrderItemStatus,
    cancelled: boolean
  }
};
export type WebsocketOrderExportUpdatedAction = {
  type: 'WEBSOCKET_ORDER_EXPORT_UPDATED',
  payload: { orderId: string, exportUpdateObj: OrderExportWebsocketUpdate }
};
export type CloseWebsocketAction = { type: 'CLOSE_WEBSOCKET' };

export type WebsocketAction =
  | ConnectWebsocketAction
  | WebsocketOrderUpdatedAction
  | WebsocketOrderItemUpdatedAction
  | WebsocketOrderExportUpdatedAction
  | CloseWebsocketAction;

export const connectWebsocket = (): ConnectWebsocketAction => ({
  type: CONNECT_WEBSOCKET
});
export const websocketOrderUpdated = (
  orderId: string,
  status: OrderStatus,
  cancelled: boolean
): WebsocketOrderUpdatedAction => ({
  type: WEBSOCKET_ORDER_UPDATED,
  payload: { orderId, status, cancelled }
});
export const websocketOrderItemUpdated = (
  orderId: string,
  orderItemId: string,
  status: OrderItemStatus,
  cancelled: boolean
): WebsocketOrderItemUpdatedAction => ({
  type: WEBSOCKET_ORDER_ITEM_UPDATED,
  payload: { orderId, orderItemId, status, cancelled }
});
export const websocketOrderExportUpdated = (
  orderId: string,
  exportUpdateObj: OrderExportWebsocketUpdate
): WebsocketOrderExportUpdatedAction => ({
  type: WEBSOCKET_ORDER_EXPORT_UPDATED,
  payload: { orderId, exportUpdateObj }
});
export const closeWebsocket = (): CloseWebsocketAction => ({
  type: CLOSE_WEBSOCKET
});
