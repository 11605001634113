// @flow
const services = new Map();

export const Factory = {
  get (className : string, Service : Class<*>, ...args : window.arguments) : any {
    if (!services.has(className)) {
      const instance = new Service(...args);
      services.set(className, instance);
      return instance;
    } else {
      return services.get(className);
    }
  }
};
