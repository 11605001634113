// @flow
import type { UserDetails } from 'src/types/user';

import React from 'react';
import {
  RTThemedCard,
  RTThemedCardText,
  RTThemedCardTitle,
  RTThemedCardActions,
  RTThemedButton
} from '@hc/component-lib/lib/rt-themed';

import type { AcceptOrderLoadedState } from 'src/partner/reducers/accept-order.reducer';
import { ORDER_TYPE_LABELS } from '../constants/order-type';

type OrderDetailsProps = {
  onAcceptOrder: (orderId: string) => void,
  onRejectOrder: () => void,
  orderState: AcceptOrderLoadedState,
  userDetails: ?UserDetails
};

const OrderDetails = ({
  onAcceptOrder,
  orderState,
  userDetails
}: OrderDetailsProps) => {
  const cardSubtitle = userDetails
    ? `Accepting order as ${userDetails.firstName} ${userDetails.lastName} (${userDetails.email}) in organization ${userDetails.orgName}`
    : '';
  return (
    <RTThemedCard raised>
      <RTThemedCardTitle title="Accept Order" subtitle={cardSubtitle} />
      <RTThemedCardText>
        <div>
          <span>Order Type:</span>{' '}
          <span>{ORDER_TYPE_LABELS[orderState.orderDetails.orderType]}</span>
        </div>
        <div>
          <span>Address:</span>{' '}
          <span>
            {orderState.orderDetails.address} {orderState.orderDetails.unit}
          </span>
        </div>
        <div>
          <span>City:</span> <span>{orderState.orderDetails.city}</span>
        </div>
        <div>
          <span>State:</span> <span>{orderState.orderDetails.state}</span>
        </div>
        <div>
          <span>Zipcode:</span> <span>{orderState.orderDetails.zipcode}</span>
        </div>
      </RTThemedCardText>
      <RTThemedCardText>
        <div>
          If you do not wish to accept this order, please close this page and
          contact the AMC so the order can be reassigned.
        </div>
      </RTThemedCardText>
      <RTThemedCardActions>
        {orderState.status === 'accepting' ? (
          <RTThemedButton onClick={() => {}} disabled>
            Accepting...
          </RTThemedButton>
        ) : (
          <RTThemedButton
            onClick={() => onAcceptOrder(orderState.orderDetails.orderId)}
          >
            Accept
          </RTThemedButton>
        )}
      </RTThemedCardActions>
    </RTThemedCard>
  );
};

export default OrderDetails;
