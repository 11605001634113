// @flow
import type { Action } from '../actions';
import type { ValuationCommentDetails } from 'src/partner/types/valuation-comment';

import {
  VALUATION_COMMENT_RESET,
  VALUATION_COMMENT_LOAD_COMPLETE,
  VALUATION_COMMENT_LOAD_ERROR,
  VALUATION_COMMENT_ADD,
  VALUATION_COMMENT_ADD_COMPLETE
} from '../actions/valuation-comment.actions';

type ValuationCommentEmptyState = {
  status: 'empty'
};

export type ValuationCommentLoadedState = {
  status: 'loaded',
  orderId: string,
  details: ValuationCommentDetails,
  token: string,
  actionStatus: 'initial' | 'pending' | 'success' | 'failure'
};

export type ValuationCommentErrorState = {
  status: 'error',
  message: string
};

export type ValuationCommentState =
  | ValuationCommentEmptyState
  | ValuationCommentLoadedState
  | ValuationCommentErrorState;

const defaultState = {
  status: 'empty'
};

export function valuationCommentReducer(
  previousState: ValuationCommentState = defaultState,
  action: Action
): ValuationCommentState {
  switch (action.type) {
    case VALUATION_COMMENT_RESET: {
      return defaultState;
    }
    case VALUATION_COMMENT_LOAD_COMPLETE: {
      return {
        status: 'loaded',
        orderId: action.payload.orderId,
        details: action.payload.details,
        token: action.payload.token,
        actionStatus: 'initial'
      };
    }
    case VALUATION_COMMENT_LOAD_ERROR: {
      return {
        status: 'error',
        message: action.payload.message
      };
    }
    case VALUATION_COMMENT_ADD: {
      if (previousState.status === 'loaded') {
        const newState: ValuationCommentLoadedState = {
          ...previousState,
          actionStatus: 'pending'
        };
        return newState;
      } else {
        return previousState;
      }
    }
    case VALUATION_COMMENT_ADD_COMPLETE: {
      if (previousState.status === 'loaded') {
        const newState: ValuationCommentLoadedState = {
          ...previousState,
          actionStatus: action.payload.success ? 'success' : 'failure'
        };
        return newState;
      } else {
        return previousState;
      }
    }
    default: {
      return previousState;
    }
  }
}
