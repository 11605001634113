// @flow
import { type AddressParts } from '../types/address';

export const getFullAddress = ({
  address,
  unit,
  unitDesignator,
  city,
  state,
  zipcode
}: AddressParts) => {
  const fullUnit = unit
    ? unitDesignator
      ? `${unitDesignator} ${unit}`
      : `${unit}`
    : null;
  return fullUnit
    ? `${address} ${fullUnit}, ${city}, ${state}, ${zipcode}`
    : `${address}, ${city}, ${state}, ${zipcode}`;
};
