// @flow
export const NAVIGATION_BAR_PREFIX = 'global_nav';

// title
export const HOUSECANARY_LOGO_CLICK = 'click_hc_logo';
export const PRODUCT_NAME_CLICK = 'click_product_name';

// icon clicks
export const PRODUCT_LAUNCHER_CLICK = 'click_product_launcher_icon';
export const NOTIFICATION_ICON_CLICK = 'click_notification_icon';
export const PROFILE_ICON_CLICK = 'click_profile_icon';

// product launcher
export const PRODUCT_LAUNCHER_TYPE = 'product_launcher';
export const PRODUCT_CLICK = 'select_product';
export const VIEW_ALL_PRODUCTS_CLICK = 'click_view_all_products';

// notification
export const NOTIFICATIONS_TYPE = 'notifications';
export const NOTIFICATION_ITEM_CLICK = 'click_notification';

// account menu
export const ACCOUNT_MENU_TYPE = 'account_menu';
export const EDIT_PROFILE_AVATAR = 'click_edit_profile_avatar';
export const ORG_SETTINGS_CLICK = 'click_organization_settings';
export const SWITCH_ORG_CLICK = 'click_switch_organization';
export const EDIT_PROFILE_CLICK = 'click_edit_profile';
export const SUPPORT_CLICK = 'click_support';
export const LOGOUT_CLICK = 'click_logout';
export const USAGE_BILLING_CLICK = 'click_usage_billing_click';
export const USAGE_CLICK = 'click_usage';

export const NAVIGATION_BAR_TYPES = {
  PRODUCT_LAUNCHER_TYPE,
  NOTIFICATIONS_TYPE,
  ACCOUNT_MENU_TYPE
};

export const NAVIGATION_BAR_EVENTS = {
  HOUSECANARY_LOGO_CLICK,
  PRODUCT_NAME_CLICK,

  PRODUCT_LAUNCHER_CLICK,
  NOTIFICATION_ICON_CLICK,
  PROFILE_ICON_CLICK,

  PRODUCT_CLICK,
  VIEW_ALL_PRODUCTS_CLICK,

  NOTIFICATION_ITEM_CLICK,

  EDIT_PROFILE_AVATAR,
  ORG_SETTINGS_CLICK,
  SWITCH_ORG_CLICK,
  EDIT_PROFILE_CLICK,
  SUPPORT_CLICK,
  LOGOUT_CLICK,
  USAGE_BILLING_CLICK,
  USAGE_CLICK
};
