// @flow
import React from 'react';
import { themr } from 'react-css-themr';
import { RTThemedProgressBar } from '@hc/component-lib/lib/rt-themed';

import defaultTheme from './ProgressBar.css';

type ProgressBarProps = {
  value: ?number,
  mode: 'determinate' | 'indeterminate',
  theme: {}
};

const ProgressBar = ({ theme, value, mode }: ProgressBarProps) => (
  <RTThemedProgressBar mode={mode} theme={theme} value={value} />
);

ProgressBar.defaultProps = {
  mode: 'determinate',
  value: null
};

export default themr('ProgressBar', defaultTheme)(ProgressBar);
