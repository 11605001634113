// @flow
// $FlowFixMe
import { createSelector } from 'reselect';
import type { State } from 'src/reducers';
import clientSelector from 'src/client/selectors/client.selector';
import type { OrgUsersState } from 'src/client/reducers/org-users.reducer';

export const orgUsersStateSelector = (state: State): OrgUsersState =>
  clientSelector(state).orgUsers;

export const orgUsersSelector = createSelector(
  orgUsersStateSelector,
  (orgUsersState: OrgUsersState): any => {
    if (orgUsersState.status === 'loaded') {
      return orgUsersState.orgUsers.objects.map((orgUser) => {
        return {
          value: orgUser.id,
          label: orgUser.email,
          firstName: orgUser.first_name,
          lastName: orgUser.last_name
        };
      });
    }
  }
);
