// @flow
import type { AddOrExtendOrderItemFields } from 'src/client/types/add-order';

export const SHOW_EXTEND_ORDER_DIALOG = 'SHOW_EXTEND_ORDER_DIALOG';
export const DISMISS_EXTEND_ORDER_DIALOG = 'DISMISS_EXTEND_ORDER_DIALOG';
export const EXTEND_ORDER = 'EXTEND_ORDER';
export const EXTEND_ORDER_SUCCESS = 'EXTEND_ORDER_SUCCESS';
export const EXTEND_ORDER_ERROR = 'EXTEND_ORDER_ERROR';

export type ShowExtendOrderDialogAction = { type: 'SHOW_EXTEND_ORDER_DIALOG' };
export type DismissExtendOrderDialogAction = {
  type: 'DISMISS_EXTEND_ORDER_DIALOG'
};
export type ExtendOrderActionType = {
  type: 'EXTEND_ORDER',
  payload: { values: AddOrExtendOrderItemFields, id: string }
};
export type ExtendOrderSuccessAction = { type: 'EXTEND_ORDER_SUCCESS' };
export type ExtendOrderErrorAction = {
  type: 'EXTEND_ORDER_ERROR',
  payload: { errorMessage: string }
};

export type ExtendOrderAction =
  | ShowExtendOrderDialogAction
  | DismissExtendOrderDialogAction
  | ExtendOrderActionType
  | ExtendOrderSuccessAction
  | ExtendOrderErrorAction;

export const showExtendOrderDialog = (): ShowExtendOrderDialogAction => ({
  type: SHOW_EXTEND_ORDER_DIALOG
});
export const dismissExtendOrderDialog = (): DismissExtendOrderDialogAction => ({
  type: DISMISS_EXTEND_ORDER_DIALOG
});
export const extendOrder = (
  orderId: string,
  values: AddOrExtendOrderItemFields
): ExtendOrderActionType => ({
  type: EXTEND_ORDER,
  payload: { values: values, id: orderId }
});
export const extendOrderSuccess = (): ExtendOrderSuccessAction => ({
  type: EXTEND_ORDER_SUCCESS
});
export const extendOrderError = (
  errorMessage: string
): ExtendOrderErrorAction => ({
  type: EXTEND_ORDER_ERROR,
  payload: { errorMessage }
});
