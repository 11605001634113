// @flow
import type { Saga } from 'redux-saga';
import type {
  ReviewValuationAcceptedAction,
  ReviewValuationRejectedAction
} from '../actions/review-valuation.actions';

import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Client } from 'src/api/order-manager-api-client';
import {
  resetReviewValuation,
  reviewValuationLoadComplete,
  reviewValuationLoadError,
  reviewValuationAcceptComplete,
  reviewValuationRejectComplete,
  REVIEW_VALUATION_ACCEPTED,
  REVIEW_VALUATION_REJECTED
} from '../actions/review-valuation.actions';
import reviewValuationStateSelector from '../selectors/review-valuation.selector';
import { handleStandardExceptions } from 'src/sagas/helpers';
import * as logger from 'src/logger';

export function* loadReviewValuationDetails({
  orderId,
  tok
}: {
  orderId: string,
  tok: string
}): Saga<void> {
  yield put(resetReviewValuation());

  try {
    // Call the API to get the order details
    const client = new Client(tok);
    const response = yield call(
      [client, client.getReviewValuationOrder],
      orderId
    );
    yield put(
      reviewValuationLoadComplete(
        {
          orderId,
          address: response.address,
          unit: response.unit,
          city: response.city,
          state: response.state,
          zipcode: response.zipcode,
          valuationDate: new Date(response.valuation_date),
          value: response.value,
          valuationReport: response.valuation_report
        },
        tok
      )
    );
  } catch (e) {
    if (e.name === 'OrderNotAvailableError') {
      yield put(
        reviewValuationLoadError(
          'This order is not available for review at this time.  The most likely cause of this is that the order has already been reviewed.  Please confirm you are viewing the correct order.'
        )
      );
    } else {
      yield call(handleStandardExceptions, e);
      yield put(reviewValuationLoadError(e.message));
    }
  }
}

export function* reviewValuationAcceptReject(
  action: ReviewValuationAcceptedAction | ReviewValuationRejectedAction
): Saga<void> {
  const reviewValuationState = yield select(reviewValuationStateSelector);
  if (reviewValuationState.status !== 'loaded') {
    logger.logException(
      new Error(
        `Cannot accept a valuation in state ${reviewValuationState.status}`
      )
    );
    return;
  }
  const client = new Client(reviewValuationState.token);
  try {
    const payload =
      action.type === REVIEW_VALUATION_REJECTED
        ? { status: 'Reject', message: action.payload.message }
        : { status: 'Pass' };
    yield call(
      [client, client.submitReviewValuationOrder],
      reviewValuationState.details.orderId,
      payload
    );
    if (action.type === REVIEW_VALUATION_REJECTED) {
      yield put(reviewValuationRejectComplete(true));
    } else {
      yield put(reviewValuationAcceptComplete(true));
    }
  } catch (e) {
    logger.logException(new Error(`Error updating review status ${e}`));
    if (action.type === REVIEW_VALUATION_REJECTED) {
      yield put(reviewValuationRejectComplete(false));
    } else {
      yield put(reviewValuationAcceptComplete(false));
    }
  }
}

export function* reviewValuationRejectSaga(): Saga<void> {
  yield takeEvery(REVIEW_VALUATION_REJECTED, reviewValuationAcceptReject);
}

export function* reviewValuationAcceptSaga(): Saga<void> {
  yield takeEvery(REVIEW_VALUATION_ACCEPTED, reviewValuationAcceptReject);
}
