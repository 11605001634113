// @flow
import kebabCase from 'lodash.kebabcase';

export const HC_PRODUCT_NAME = 'product-name';

export const HC_LAUNCHER = 'product-launcher';
export const HC_LAUNCHER_PREFIX = 'launcher-';
export const HC_LAUNCHER_VIEW_ALL = 'launcher-view-all-products';

export const getHCLauncherProductName = (productName: string): string => `${HC_LAUNCHER_PREFIX}${kebabCase(productName)}`;

export const HC_NAV_BAR_TITLE = 'nav-title-section';
export const HC_NAV_BAR_ICONS = 'nav-icon-section';
export const HC_NAV_BAR_ADDITIONAL = 'nav-additional-section';
export const HC_PROFILE_MENU = 'profile-menu';
export const HC_NOTIFICATIONS_MENU = 'notification-menu';
export const HC_NOTIFICATIONS_COUNTER = 'notification-counter';
export const HC_NOTIFICATIONS_CLOSE = 'notification-close';
export const HC_PROFILE_AVATAR = 'profile-avatar';
export const HC_PROFILE_NAME = 'profile-name';
export const HC_PROFILE_ORG_NAME = 'profile-org-name';
export const HC_PROFILE_ORG_SETTINGS = 'profile-org-settings';
export const HC_PROFILE_SWITCH_ORG = 'profile-switch-org';
export const HC_PROFILE_EDIT = 'profile-edit';
export const HC_PROFILE_SUPPORT = 'profile-support';
export const HC_PROFILE_LOGOUT = 'profile-logout';
export const HC_PROFILE_CUSTOM_CONTENT = 'profile-custom-content';
export const HC_PROFILE_USAGE_BILLING = 'profile-usage-billing';

export const NAV_BAR_HC_NAMES = {
  HC_NAV_BAR_TITLE,
  HC_NAV_BAR_ICONS,
  HC_NAV_BAR_ADDITIONAL,
  HC_PRODUCT_NAME,
  HC_LAUNCHER,
  HC_LAUNCHER_PREFIX,
  HC_LAUNCHER_VIEW_ALL,
  HC_PROFILE_MENU,
  HC_NOTIFICATIONS_MENU,
  HC_NOTIFICATIONS_COUNTER,
  HC_NOTIFICATIONS_CLOSE,
  HC_PROFILE_AVATAR,
  HC_PROFILE_NAME,
  HC_PROFILE_ORG_NAME,
  HC_PROFILE_ORG_SETTINGS,
  HC_PROFILE_SWITCH_ORG,
  HC_PROFILE_EDIT,
  HC_PROFILE_SUPPORT,
  HC_PROFILE_LOGOUT,
  HC_PROFILE_CUSTOM_CONTENT,
  HC_PROFILE_USAGE_BILLING
};
