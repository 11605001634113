// @flow
import * as React from 'react';
import { RTThemedFontIcon } from '@hc/component-lib/lib/rt-themed';
import classNames from 'classnames';

import styles from './OrderHint.css';

type OrderHintProps = {
  label: string,
  isNotification?: boolean,
  icon: string | React.Node,
  onHintClick: (e: any, string) => void
};

const OrderHint = (props: OrderHintProps) => {
  const { label, isNotification, icon, onHintClick } = props;

  return (
    <div
      onClick={onHintClick}
      className={classNames(styles.hint, {
        [styles.isNotification]: isNotification
      })}
    >
      <span>{label}</span>
      <RTThemedFontIcon className={styles.icon} value={icon} />
    </div>
  );
};

export default OrderHint;
