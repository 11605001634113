// @flow

import React from 'react';

import type { Links } from 'src/types/link';
import RoutingIconButton from './routing/RoutingIconButton';

export type routeGeneratorFn = (page: ?number) => string;

export type PaginationProps = {
  routeGeneratorFn: (page: ?number) => string,
  links: Links
};

const Pagination = ({ routeGeneratorFn, links }: PaginationProps) => {
  return !links.prev && !links.next ? null : (
    <div data-hc-name="page-controls">
      <RoutingIconButton
        data-hc-name="page-first-link"
        icon="first_page"
        to={routeGeneratorFn(1)}
        disabled={!links.prev}
      />
      <RoutingIconButton
        data-hc-name="page-previous-link"
        icon="chevron_left"
        to={routeGeneratorFn(links.prev && links.prev.page)}
        disabled={!links.prev}
      />
      <RoutingIconButton
        data-hc-name="page-next-link"
        icon="chevron_right"
        to={routeGeneratorFn(links.next && links.next.page)}
        disabled={!links.next}
      />
      <RoutingIconButton
        data-hc-name="page-last-link"
        icon="last_page"
        to={routeGeneratorFn(links.last.page)}
        disabled={!links.next}
      />
    </div>
  );
};

export default Pagination;
