// @flow
import React from 'react';

import type { Order } from 'src/client/types/order';

import OrdersSummary from './OrdersSummary';
import CollapsibleRow from './CollapsibleRow';

import styles from './MonthSummary.css';

type MonthSummaryProps = {
  label: string,
  monthSummary: Order[]
};

const MonthSummary = (props: MonthSummaryProps) => {
  const { label, monthSummary } = props;

  return (
    <CollapsibleRow
      dataHcNameHeader="month-header"
      header={label}
      theme={styles}
      hasNestedCollapse
    >
      <OrdersSummary orders={monthSummary} />
    </CollapsibleRow>
  );
};

export default MonthSummary;
