// @flow

import {
  EHRMANTRAUT,
  PLATFORM,
  DEVELOPER_CENTER,
  SUPPORT,
  LOGIN,
  BEACON,
  VALUE_REPORT,
  ANALYTICS_API,
  MARKET_INSIGHTS_PRO,
  ORDER_MANAGER,
  LOGIN_SERVICE
} from './products.constants';
import { DATA_FACTORY_TOOLS } from './internal-products.constants';

export const ENVS_TO_URLS = {
  'LOCAL': {
    // auth, access, or logging url
    [EHRMANTRAUT]: 'https://api.dev.hc.housecanary.net',
    [BEACON]: 'https://beacon.dev.hc.housecanary.net',
    [LOGIN_SERVICE]: 'https://login.dev.hc.housecanary.net/v1/',
    // products
    [PLATFORM]: 'https://solutions.dev.hc.housecanary.net',
    [VALUE_REPORT]: 'https://pexp.dev.hc.housecanary.net',
    [ANALYTICS_API]: 'https://dexp.dev.hc.housecanary.net',
    [MARKET_INSIGHTS_PRO]: 'https://mexp.dev.hc.housecanary.net/',
    [ORDER_MANAGER]: 'https://order.dev.hc.housecanary.net',
    [DEVELOPER_CENTER]: 'https://solutions.dev.hc.housecanary.net/my-user/api-keys',

    [SUPPORT]: 'https://support.housecanary.com'
  },
  'DEV': {
    // auth, access or logging urls
    [EHRMANTRAUT]: 'https://api.dev.hc.housecanary.net',
    [BEACON]: 'https://beacon.dev.hc.housecanary.net',
    [LOGIN_SERVICE]: 'https://login.dev.hc.housecanary.net/v1/',
    // products
    [PLATFORM]: 'https://solutions.dev.hc.housecanary.net',
    [VALUE_REPORT]: 'https://pexp.dev.hc.housecanary.net',
    [ANALYTICS_API]: 'https://dexp.dev.hc.housecanary.net',
    [MARKET_INSIGHTS_PRO]: 'https://mexp.dev.hc.housecanary.net/',
    [ORDER_MANAGER]: 'https://order.dev.hc.housecanary.net',
    [DEVELOPER_CENTER]: 'https://solutions.dev.hc.housecanary.net/my-user/api-keys',
    [DATA_FACTORY_TOOLS]: 'https://df-tools.dev.df.housecanary.net/',

    [SUPPORT]: 'https://support.housecanary.com'
  },
  'QA': {
    // new environment
    // auth, access or logging urls
    [EHRMANTRAUT]: 'https://api.qa.hc.housecanary.net',
    [LOGIN_SERVICE]: 'https://login.qa.hc.housecanary.net/v1/',
    [BEACON]: 'https://beacon.qa.hc.housecanary.net',

    // products
    [PLATFORM]: 'https://solutions.qa.hc.housecanary.net',
    [VALUE_REPORT]: 'https://pexp.qa.hc.housecanary.net',
    [ANALYTICS_API]: 'https://dexp.qa.hc.housecanary.net',
    [MARKET_INSIGHTS_PRO]: 'https://mexp.qa.hc.housecanary.net/',
    [ORDER_MANAGER]: 'https://order.qa.hc.housecanary.net',
    [DEVELOPER_CENTER]: 'https://solutions.qa.hc.housecanary.net/my-user/api-keys',

    [SUPPORT]: 'https://support.housecanary.com'
  },

  'STAGE': {
    // auth, access, or logging urls
    // pointing to STAGE environments for testing
    [EHRMANTRAUT]: 'https://api.stage.hc.housecanary.net',
    [LOGIN_SERVICE]: 'https://login.stage.hc.housecanary.net/v1/',
    [BEACON]: 'https://beacon.stage.hc.housecanary.net',
    [LOGIN]: 'https://login.stage.hc.housecanary.net',

    [PLATFORM]: 'https://solutions.stage.hc.housecanary.net',
    [VALUE_REPORT]: 'https://pexp.stage.hc.housecanary.net',
    [ANALYTICS_API]: 'https://dexp.stage.hc.housecanary.net',
    [MARKET_INSIGHTS_PRO]: 'https://mexp.stage.hc.housecanary.net/',
    [ORDER_MANAGER]: 'https://order.stage.hc.housecanary.net',
    [DEVELOPER_CENTER]:
      'https://solutions.stage.hc.housecanary.net/my-user/api-keys',

    [SUPPORT]: 'https://support.housecanary.com'
  },

  'PROD': {
    // auth, access, or logging urls
    [EHRMANTRAUT]: 'https://api.housecanary.com',
    [LOGIN_SERVICE]: 'https://login.housecanary.com/v1/',
    [BEACON]: 'https://beacon.housecanary.com',

    [PLATFORM]: 'https://solutions.housecanary.com',
    [VALUE_REPORT]: 'https://pexp.housecanary.com',
    [ANALYTICS_API]: 'https://dexp.housecanary.com',
    [MARKET_INSIGHTS_PRO]: 'https://mexp.housecanary.com/',
    [ORDER_MANAGER]: 'https://order.housecanary.com',
    [DEVELOPER_CENTER]: 'https://solutions.housecanary.com/my-user/api-keys',
    [DATA_FACTORY_TOOLS]: 'https://df-tools.prod.df.housecanary.net/',

    [SUPPORT]: 'https://support.housecanary.com'
  }
};

export const getEnvUrl = (env: 'LOCAL' | 'DEV' | 'QA' | 'STAGE' | 'PROD' = 'PROD', key: string) => {
  return (ENVS_TO_URLS[env] && ENVS_TO_URLS[env][key]) || ENVS_TO_URLS.PROD[key]; // fallback on PROD
};
