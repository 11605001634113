// @flow
import type { Action } from 'src/client/actions';

import {
  REQUEST_ORDER_EXPORT,
  REQUEST_ORDER_EXPORT_ERROR,
  REQUEST_ORDER_EXPORT_SUCCESS
} from 'src/client/actions/order-export.actions';

type OrderExportRequestDefaultState = {
  status: 'not_loading'
};
type OrderExportRequestLoadingState = {
  status: 'loading',
  orderId: string
};
type OrderExportRequestErrorState = {
  status: 'error',
  errorMessage: string,
  orderId: string
};
export type OrderExportRequestState =
  | OrderExportRequestDefaultState
  | OrderExportRequestLoadingState
  | OrderExportRequestErrorState;

const defaultState = { status: 'not_loading' };

export function orderExportRequestReducer(
  _: OrderExportRequestState,
  action: Action
): OrderExportRequestState {
  switch (action.type) {
    case REQUEST_ORDER_EXPORT: {
      return {
        status: 'loading',
        orderId: action.payload.orderId
      };
    }
    case REQUEST_ORDER_EXPORT_ERROR: {
      return {
        status: 'error',
        errorMessage: action.payload.errorMessage,
        orderId: action.payload.orderId
      };
    }
    case REQUEST_ORDER_EXPORT_SUCCESS: {
      return defaultState;
    }
    default:
      return defaultState;
  }
}
