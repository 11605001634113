// @flow
import React, { type Node } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import { Tooltip } from '@hc/component-lib/hclib';

import { AppLauncherIcon } from '../../../../icons';
import { type ProductDetailsWithApplicationType } from '../../../../types';

import { withUrls } from '../../Urls';
import { withTracker } from '../../Tracker';
import AppsMenuContent, { type AppsMenuContentThemeType } from './AppsMenuContent';

import defaultTheme from './AppsMenu.scss';
import { NAVIGATION_BAR_PREFIX, NAVIGATION_BAR_EVENTS } from '../../constants/events.constants';
import { NAV_BAR_HC_NAMES } from '../../hc.names';

export type AppsMenuThemeType = {
    AppsMenu: string
  } &
  AppsMenuContentThemeType

export type AppsMenuProps = {
  className?: string,
  theme: AppsMenuThemeType,
  children?: Node,
  content?: Node,
  products: ProductDetailsWithApplicationType[],
  platformBaseUrl?: string,
  pro: {
    hasAccess: true,
    product: ProductDetailsWithApplicationType
  } | {
    hasAccess: false
  }
}

const AppsMenuContentWithUrls = withUrls(AppsMenuContent);
const AppsLauncherWithTracker = withTracker(AppLauncherIcon);

const AppsMenu = (props: AppsMenuProps) => {
  const {
    className,
    theme,
    children,
    products,
    platformBaseUrl,
    pro,
    content = (
      <AppsMenuContentWithUrls
        theme={theme}
        products={products}
        pro={pro}>
        {children}
      </AppsMenuContentWithUrls>
    ),
    ...rest
  } = props;

  return (
    <Tooltip
      className={classNames(theme.AppsMenu, className)}
      theme={theme}
      shift='left'
      clickToTrigger
      trigger={(
        <AppsLauncherWithTracker
          type={NAVIGATION_BAR_PREFIX}
          event={NAVIGATION_BAR_EVENTS.PRODUCT_LAUNCHER_CLICK}
          data-hc-name={NAV_BAR_HC_NAMES.HC_LAUNCHER}
        />
      )}
      content={content}
      {...rest}
    />
  );
};

export default themr('AppsMenuThemed', defaultTheme)(AppsMenu);
