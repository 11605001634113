// @flow
import React from 'react';
import { themr } from 'react-css-themr';

import { ChevronLink } from '@hc/component-lib';

import { CloseIcon } from '../../../../../icons';
import {
  DataExplorerIcon,
  PlatformHero,
  PropertyExplorerIcon,
  MarketExplorerIcon,
  DeveloperCenterIcon,
  AgileSuiteIcon,
  AgileInsightsIcon
} from '../../../../../icons/images';

import defaultTheme from './PlatformAnnouncementModal.scss';

type PlatformAnnoucementModalContentThemeType = {
  PlatformAnnouncement: string,
  AnnouncementBackground: string,
  AnnouncementCloseRow: string,
  AnnouncementContent: string,
  AnnouncementHero: string,
  AnnouncementDetails: string,
  LearnMoreRow: string,
  New: string,
  AnnouncementScreenshot: string,
  AnnouncementProducts: string,
  AnnouncementProductsList: string,
  AnnouncementProductsListMobile: string,
  AnnouncementProduct: string,
  AnnouncementProductNames: string,
  Name: string,
  HintText: string,
  Icon: string
}

type PlatformAnnoucementModalContentProps = {
  theme: PlatformAnnoucementModalContentThemeType,
  active: boolean,
  onClose: () => void
}

const ANNOUNCEMENT_PRODUCTS = [
  {
    name: 'Data Explorer',
    hintText: 'Formerly Match & Append',
    icon: <DataExplorerIcon />
  },
  {
    name: 'Property Explorer',
    hintText: 'Formerly Value Report',
    icon: <PropertyExplorerIcon />
  },
  {
    name: 'Market Explorer',
    hintText: 'Formerly Market Insights Pro',
    icon: <MarketExplorerIcon />
  },
  {
    name: 'Developer Center',
    hintText: 'Formerly Data & Analytics',
    icon: <DeveloperCenterIcon />
  },
  {
    name: 'Agile Suite',
    hintText: 'Delivered by Order Manager',
    icon: <AgileSuiteIcon />
  },
  {
    name: 'Agile Insights',
    hintText: 'Delivered by Order Manager',
    icon: <AgileInsightsIcon />
  }
];

const corpSiteLearnMoreUrl = 'https://www.housecanary.com/resources/housecanary-analytics-platform-launch-2019';

const AnnouncementProduct = ({ theme, icon, name, hintText }) => {
  return (
    <div className={theme.AnnouncementProduct}>
      <div className={theme.Icon}>
        {icon}
      </div>
      <div className={theme.AnnouncementProductNames}>
        <div className={theme.Name}>{name}</div>
        <div className={theme.HintText}>{hintText}</div>
      </div>
    </div>
  );
};

const PlatformAnnouncementModalContent = (props: PlatformAnnoucementModalContentProps) => {
  const { theme, onClose } = props;
  return (
    <div className={theme.PlatformAnnouncement}>
      <div className={theme.AnnouncementBackground}>
        <div className={theme.AnnouncementCloseRow}>
          <CloseIcon onClick={onClose} />
        </div>
        <div className={theme.AnnouncementHero}>
          <div className={theme.AnnouncementDetails}>
            <div className={theme.New}>NEW!</div>
            <h1>Analytics Platform</h1>
            <p>
              We've re-named some of our products and brought all of them together in one place to provide you with a better experience!
            </p>
            <div className={theme.LearnMoreRow}>
              <ChevronLink target='_blank' href={corpSiteLearnMoreUrl}>Learn More</ChevronLink>
            </div>
          </div>
          <div className={theme.AnnouncementScreenshot}>
            <PlatformHero />
          </div>
        </div>
      </div>

      <div className={theme.AnnouncementProducts}>
        <h2>Updated Product Names</h2>

        <div className={theme.AnnouncementProductsList}>
          {
            ANNOUNCEMENT_PRODUCTS.slice(0, 3).map((product) =>
              <AnnouncementProduct key={product.name} theme={theme} {...product} />
            )
          }
        </div>
        <div className={theme.AnnouncementProductsList}>
          {
            ANNOUNCEMENT_PRODUCTS.slice(3, 6).map((product) =>
              <AnnouncementProduct key={product.name} theme={theme} {...product} />
            )
          }
        </div>

        {/** mobile */}
        <div className={theme.AnnouncementProductsListMobile}>
          {
            ANNOUNCEMENT_PRODUCTS.slice(0, 2).map((product) =>
              <AnnouncementProduct key={product.name} theme={theme} {...product} />
            )
          }
        </div>
        <div className={theme.AnnouncementProductsListMobile}>
          {
            ANNOUNCEMENT_PRODUCTS.slice(2, 4).map((product) =>
              <AnnouncementProduct key={product.name} theme={theme} {...product} />
            )
          }
        </div>
        <div className={theme.AnnouncementProductsListMobile}>
          {
            ANNOUNCEMENT_PRODUCTS.slice(4, 6).map((product) =>
              <AnnouncementProduct key={product.name} theme={theme} {...product} />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default themr('PlatformAnnouncementModalContentThemed', defaultTheme)(PlatformAnnouncementModalContent);
