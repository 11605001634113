// @flow
import type { OrderItemProcessCounts } from '../types/order-item';
import type { Action } from '../actions';

import {
  FETCH_ORDER_ITEM_PROCESS_COUNTS,
  FETCH_ORDER_ITEM_PROCESS_COUNTS_SUCCESS
} from '../actions/order-items.actions';

export type OrderItemProcessCountsState =
  | { status: 'empty' }
  | { status: 'loading' }
  | { status: 'loaded', processCountsDetails: OrderItemProcessCounts };

const defaultState = {
  status: 'empty'
};

export function orderItemProcessCountsReducer(
  state: OrderItemProcessCountsState = defaultState,
  action: Action
): OrderItemProcessCountsState {
  switch (action.type) {
    case FETCH_ORDER_ITEM_PROCESS_COUNTS: {
      return {
        status: 'loading'
      };
    }
    case FETCH_ORDER_ITEM_PROCESS_COUNTS_SUCCESS: {
      const { orderItemProcessCounts } = action.payload;
      return {
        status: 'loaded',
        processCountsDetails: {
          ...orderItemProcessCounts
        }
      };
    }
    default: {
      return state;
    }
  }
}
