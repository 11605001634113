import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import { Link } from '@hc/component-lib/lib/rt-themed';
import desktopSample from '../../../../files/agile-appraisal-desktop-sample-order.csv';
import appraisalExteriorSample from '../../../../files/agile-appraisal-exterior-sample-order.csv';
import appraisalInteriorSample from '../../../../files/agile-appraisal-interior-sample-order.csv';
import evalExteriorSample from '../../../../files/agile-evaluation-exterior-sample-order.csv';
import evalInteriorSample from '../../../../files/agile-evaluation-interior-sample-order.csv';
import exteriorInspectionSample from '../../../../files/exterior-inspection-sample-order.csv';
import interiorInspectionSample from '../../../../files/interior-inspection-sample-order.csv';
import evalExteriorCertifiedInspectionSample from '../../../../files/agile-evaluation-certified-sample-order.csv';
import sampleOrderFile from '../../../../files/sample-order.csv';
import { ADD_ORDER_FORM_NAME } from '../../../constants/add-order';

import type { OrderTypeId } from '../../../types/order';
import type { MapStateToProps } from 'react-redux';
import type { State } from '../../../../reducers';

import styles from './add-order-form.css';

const ORDER_TYPE_ID_TO_FILE_MAP: { [orderTypeId: OrderTypeId]: string } = {
  evaluate: desktopSample,
  inspectAndEvaluate: appraisalExteriorSample,
  devFakeInspectAndEvaluate: appraisalExteriorSample,
  devFakeInspectV2AndEvaluate: appraisalExteriorSample,
  devFakeInspectAndFakeEvaluate: appraisalExteriorSample,
  devFakeInspectV2AndFakeEvaluate: appraisalExteriorSample,
  inspectV2AndEvaluate: appraisalExteriorSample,
  interiorExteriorInspectAndEvaluate: appraisalInteriorSample,
  devFakeInteriorExteriorInspectAndEvaluate: appraisalInteriorSample,
  devFakeInteriorExteriorInspectAndFakeEvaluate: appraisalInteriorSample,
  exteriorInspectionAndValueReport: evalExteriorSample,
  exteriorInspectionV2AndValueReport: evalExteriorSample,
  interiorExteriorInspectionAndValueReport: evalInteriorSample,
  exteriorInspection: exteriorInspectionSample,
  exteriorInspectionV2: exteriorInspectionSample,
  interiorExteriorInspection: interiorInspectionSample,
  valueReport: sampleOrderFile,
  exteriorInspectionAndAgileEvaluationCertified:
    evalExteriorCertifiedInspectionSample
};

const getSampleFile = (orderTypeId: OrderTypeId) => {
  if (
    Object.prototype.hasOwnProperty.call(ORDER_TYPE_ID_TO_FILE_MAP, orderTypeId)
  ) {
    return ORDER_TYPE_ID_TO_FILE_MAP[orderTypeId];
  }
  return sampleOrderFile;
};

type SampleBulkFileProps = {
  orderTypeId: ?OrderTypeId
};
const SampleBulkFile = (props: SampleBulkFileProps) => {
  const sampleFile: string = getSampleFile(props.orderTypeId);
  return (
    <div className={styles.sampleLinkContainer}>
      <Link
        data-hc-name="download-sample-link"
        href={sampleFile}
        label="Download Sample CSV"
      />
    </div>
  );
};

const mapStateToProps: MapStateToProps<*, *, *> = (state: State) => {
  const selector = getFormValues(ADD_ORDER_FORM_NAME);
  const formValues = selector(state);
  const orderTypeId: ?OrderTypeId = formValues.orderType;
  return {
    orderTypeId
  };
};

const SampleBulkFileConnected = connect(mapStateToProps)(SampleBulkFile);

export default SampleBulkFileConnected;
