// @flow
import React from 'react';

import routeTo from './route-to';

type WithRouterLinkProps = {
  activeClassName?: ?string,
  className?: ?string,
  to: string,
  onClick?: () => void
};

type WithRouterLinkState = {
  isActive: boolean
};

const WithRouterLink = (Component: any) =>
  class WithRouterLink extends React.Component<
    WithRouterLinkProps,
    WithRouterLinkState
  > {
    constructor(props: WithRouterLinkProps) {
      super(props);
      this.state = {
        isActive: false
      };
    }

    onClick = (e: Event) => {
      this.setState({ isActive: true });
      e.stopPropagation();
      e.preventDefault();
      routeTo(this.props.to);
      if (this.props.onClick) {
        this.props.onClick();
      }
      this.setState({ isActive: false });
    };

    render() {
      const { to, className, activeClassName, ...rest } = this.props;
      let _className = null;
      if (className || activeClassName) {
        _className = this.state.isActive
          ? `${className || ''} ${activeClassName || ''}`
          : className;
      }

      return (
        <Component
          {...rest}
          href={to}
          className={_className}
          onClick={this.onClick}
        />
      );
    }
  };

export default WithRouterLink;
