import type {
  ApplicationSubscription
} from '../clients/subscription-client';

export const isApplicationActiveSubscription = (applicationSubscription: ApplicationSubscription): boolean => {
  return applicationSubscription.active;
};

export const findApplicationActiveSubscription = (subscriptions: ApplicationSubscription[], applicationName): ?ApplicationSubscription => {
  return subscriptions.find(applicationSubscription => {
    return applicationSubscription.application.name === applicationName && isApplicationActiveSubscription(applicationSubscription);
  });
};

export const userHasActiveApplicationSubscription = (subscriptions, applicationName): boolean => {
  return !!findApplicationActiveSubscription(subscriptions, applicationName);
};

/**
 * Determine if user's application subscription is trial subscription
 * @param {ApplicationSubscription} applicationSubscription
 * @return {Boolean}
 */
export const isApplicationTrialSubscription = (applicationSubscription: ApplicationSubscription) => {
  return applicationSubscription.trial;
};

/**
 * Determine if user's application trial subscription is active
 * @param {ApplicationSubscription} applicationSubscription
 * @return {Boolean}
 */
export const isActiveApplicationTrialSubscription = (applicationSubscription: ApplicationSubscription) => {
  return !applicationSubscription.trial_ended;
};

/**
 * Determine if user's application subscription is an "Active" trial subscription
 * @param {ApplicationSubscription} applicationSubscription
 * @return {Boolean}
 */
export const isValidApplicationTrialSubscription = (applicationSubscription: ApplicationSubscription) => {
  return isApplicationTrialSubscription(applicationSubscription) && isActiveApplicationTrialSubscription(applicationSubscription);
};
