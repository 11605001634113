// @flow
import { type ValuationRevision } from '../types/valuation-revision';
import {
  type ValuationRevisionAction,
  VALUATION_REVISION_SUCCESS,
  VALUATION_REVISION_ERROR,
  VALUATION_REVISION_RESPONSE,
  VALUATION_REVISION_RESPONSE_ERROR,
  VALUATION_REVISION_RESPONSE_SUCCESS
} from '../actions/valuation-revision.actions';
import { omit } from 'lodash-es';

type ValuationRevisionInitialState = {
  status: 'INIT'
};

export type ValuationRevisionLoadedState = {
  status: 'SUCCESS' | 'SUBMITTING' | 'SUBMITTED',
  data: {
    valuation: ValuationRevision
  }
};

export type ValuationRevisionErrorState = {
  status: 'ERROR',
  error: string
};

export type ValuationRevisionResponseErrorState = {
  status: 'SUBMIT_ERROR',
  submitError: string,
  data: {
    valuation: ValuationRevision
  }
};

export type ValuationRevisionState =
  | ValuationRevisionInitialState
  | ValuationRevisionLoadedState
  | ValuationRevisionErrorState
  | ValuationRevisionResponseErrorState;

const defaultState = {
  status: 'INIT'
};

export function valuationRevisionReducer(
  previousState: ValuationRevisionState = defaultState,
  action: ValuationRevisionAction
): ValuationRevisionState {
  switch (action.type) {
    case VALUATION_REVISION_SUCCESS: {
      return {
        status: 'SUCCESS',
        data: {
          valuation: action.payload.valuation
        }
      };
    }
    case VALUATION_REVISION_ERROR: {
      return {
        status: 'ERROR',
        error: action.payload.error
      };
    }
    case VALUATION_REVISION_RESPONSE: {
      if (
        previousState.status === 'SUCCESS' ||
        previousState.status === 'SUBMIT_ERROR'
      ) {
        const newState: ValuationRevisionLoadedState = {
          ...omit(previousState, ['status']),
          status: 'SUBMITTING'
        };
        return newState;
      } else {
        return previousState;
      }
    }
    case VALUATION_REVISION_RESPONSE_SUCCESS: {
      if (previousState.status === 'SUBMITTING') {
        const newState: ValuationRevisionLoadedState = {
          ...omit(previousState, ['status']),
          status: 'SUBMITTED'
        };
        return newState;
      } else {
        return previousState;
      }
    }

    case VALUATION_REVISION_RESPONSE_ERROR: {
      if (previousState.status === 'SUBMITTING') {
        const newState: ValuationRevisionResponseErrorState = {
          ...omit(previousState, ['status']),
          status: 'SUBMIT_ERROR',
          submitError: action.payload.error
        };
        return newState;
      } else {
        return previousState;
      }
    }
    default: {
      return previousState;
    }
  }
}
