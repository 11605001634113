// @flow
import { getAuthClient } from '../clients/auth-api-client';
import { type UserContext } from '../types';

export const EMAIL_TEST = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MAX_EMAIL_LENGTH = 128;
export const MAX_NAME_LENGTH = 32;
export const MAX_ORG_LENGTH = 64;
export const MIN_PASSWORD_LENGTH = 8;

export type PasswordValidation = boolean
export type EmailValidation = boolean
export type RequiredValidation = boolean

type Email = string

export class ValidationError extends Error {
  constructor (msg: string) {
    super();
    this.name = 'ValidationError';
    this.message = msg;
  }
}

export const validateEmail = (val: Email): EmailValidation => {
  return EMAIL_TEST.test(val);
};

export const validatePassword = (val: string, minLength: number = MIN_PASSWORD_LENGTH): PasswordValidation => {
  return val != null && val.length >= minLength;
};

export const validateRequired = (val: any) : RequiredValidation => {
  return val !== '' && val != null;
};

export const validateFieldLength = (val: string, maxLength: number) => {
  return val != null && val.length <= maxLength;
};

/* Validates Phone numbers in the following formats:
  XXXXXXXXXX
  XXX-XXX-XXXX
  XXX.XXX.XXXX
  XXX XXX XXXX
  (XXX) XXX XXXX
*/
export const validatePhone = (val: string): boolean => {
  const withoutDelim = /^\d{10}$/;
  const withDelim = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return !val || !!val.match(withoutDelim) || !!val.match(withDelim);
};

export const fieldLengthValidator = (value: string, maxLength: number, fieldDescription: string = 'Field'): Promise<?string> => {
  const isValid = validateFieldLength(value, maxLength);
  if (isValid) {
    return Promise.resolve(null);
  } else {
    return Promise.reject(new ValidationError(`${fieldDescription} must be less than ${maxLength} characters`));
  }
};
export const emailLengthValidator = (value: string) => fieldLengthValidator(value, MAX_EMAIL_LENGTH, 'Emails');
export const nameLengthValidator = (value: string) => fieldLengthValidator(value, MAX_NAME_LENGTH, 'Names');
export const orgLengthValidator = (value: string) => fieldLengthValidator(value, MAX_ORG_LENGTH, 'Organization');

export const isRequiredValidator = (value : any) : Promise<?string> => {
  const isValid = validateRequired(value);
  if (isValid) {
    return Promise.resolve(null);
  } else {
    return Promise.reject(new ValidationError('Required'));
  }
};

export const passwordValidator = (value : string) : Promise<?string> => {
  const isValid : PasswordValidation = validatePassword(value);
  if (isValid) {
    return Promise.resolve(null);
  } else {
    return Promise.reject(new ValidationError('Password must be at least 8 characters'));
  }
};

export const phoneValidator = (value : string) : Promise<?string> => {
  const isValid : boolean = validatePhone(value);
  if (isValid) {
    return Promise.resolve(null);
  } else {
    return Promise.reject(new ValidationError('Please enter a valid format (xxx-xxx-xxxx)'));
  }
};

// There are different ways to approach this
// Example uses the native DOM validity api
export const emailValidator = (value : Email) : Promise<?string> => {
  const isValid : EmailValidation = validateEmail(value);
  if (isValid) {
    return Promise.resolve(null);
  } else {
    return Promise.reject(new ValidationError('Invalid email'));
  }
};

/**
 * Example:
 * This is the signup email validator
 * It is passed into the SignupEmailField as a prop
 */
export const getEmailAvailableValidator = (loginServiceUrl: string, authApiUrl : string, updateContextCallback: (userContext: UserContext) => void, params?: {[key: string]: string}) : Function => {
  const client = getAuthClient(loginServiceUrl, `${authApiUrl}`, updateContextCallback);

  return (value: Email) : Promise<?string> => {
    if (!value) return Promise.resolve(null);
    return new Promise((resolve, reject) => {
      client.checkEmail(value, params)
        .then(result => {
          if (result) {
            return resolve(null);
          } else {
            return reject(new ValidationError('Email already taken'));
          }
        })
        .catch(e => reject(e))
      ;
    });
  };
};

export const getValidator = () => ({ validateEmail, validatePassword, isRequiredValidator });
