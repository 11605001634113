// @flow
import type { OrderingParams } from 'src/types/list';

const ADDRESS: OrderingParams = {
  field: 'address',
  asc: true
};
const CUSTOMER_ITEM_ID: OrderingParams = {
  field: 'customerItemId',
  asc: true
};
const STATE: OrderingParams = {
  field: 'state',
  asc: true
};
const CITY: OrderingParams = {
  field: 'city',
  asc: true
};
const UNIT: OrderingParams = {
  field: 'unit',
  asc: true
};
const ID: OrderingParams = {
  field: 'id',
  asc: true
};
const PROPERTY_TYPE: OrderingParams = {
  field: 'propertyType',
  asc: true
};
const ZIPCODE: OrderingParams = {
  field: 'zipcode',
  asc: true
};
const VALIDATION_ISSUES_SUMMARY: OrderingParams = {
  field: 'validationIssueSummary',
  asc: true
};
const CAN_OVERRIDE_ALL_ISSUES: OrderingParams = {
  field: 'canOverrideAllIssues',
  asc: false
};

export const COMMON_ORDERING_PARAMS: {
  [orderingField: string]: OrderingParams[]
} = {
  customerItemId: [CUSTOMER_ITEM_ID, STATE, CITY, ADDRESS, UNIT, ID],
  propertyType: [
    PROPERTY_TYPE,
    STATE,
    CITY,
    ADDRESS,
    UNIT,
    CUSTOMER_ITEM_ID,
    ID
  ],
  address: [ADDRESS, STATE, CITY, UNIT, CUSTOMER_ITEM_ID, ID],
  city: [CITY, STATE, ADDRESS, UNIT, CUSTOMER_ITEM_ID, ID],
  state: [STATE, CITY, ADDRESS, UNIT, CUSTOMER_ITEM_ID, ID],
  zipcode: [ZIPCODE, STATE, CITY, ADDRESS, UNIT, ID],
  validationIssueSummary: [
    CAN_OVERRIDE_ALL_ISSUES,
    VALIDATION_ISSUES_SUMMARY,
    STATE,
    CITY,
    ADDRESS,
    UNIT,
    ID
  ]
};
