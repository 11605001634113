// @flow
import type { Action } from '../actions';

import { LOGIN, LOGIN_ERROR, LOGIN_SUCCESS } from '../actions/user.actions';

type LoginDefaultState = {
  loading: false
};
type LoginLoadingState = {
  loading: true
};
type LoginErrorState = {
  loading: false,
  errorMessage: string
};
export type LoginState =
  | LoginDefaultState
  | LoginLoadingState
  | LoginErrorState;

const defaultState = {
  loading: false
};

function loginReducer(
  previousState: LoginState = defaultState,
  action: Action
): LoginState {
  switch (action.type) {
    case LOGIN: {
      return {
        loading: true
      };
    }
    case LOGIN_ERROR: {
      return {
        loading: false,
        errorMessage: action.payload.errorMessage
      };
    }
    case LOGIN_SUCCESS: {
      return {
        loading: false
      };
    }
    default: {
      return previousState;
    }
  }
}

export default loginReducer;
