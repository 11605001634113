// @flow
/* globals SyntheticInputEvent */
import * as React from 'react';
import classNames from 'classnames';
import { Input } from '@hc/component-lib';
import { themr } from 'react-css-themr';

import defaultTheme from './SearchAutoComplete.css';

type SearchAutoCompleteTheme = {
  component: string,
  searchInput: string,
  results: string,
  result: string
};

export type Result = {
  id: string,
  label: string,
  [string]: any
};

type SearchAutoCompleteProps = {
  theme?: SearchAutoCompleteTheme,
  ResetIcon?: React.Node,
  SearchIcon?: React.Node,
  placeholder?: string,
  results: Result[],
  resultsAreLoading: boolean,
  renderResult: (index: number) => React.Node,
  onSearch: (value: string) => void,
  onClearResults: () => void,
  onSelectResult: (index: number) => void,
  value?: string,
  active: boolean,
  error?: string,
  dataHcName: string,
  label?: string,
  onBlur?: (string) => void
};

type SearchAutoCompleteState = {
  query: string,
  active: boolean,
  value: string
};

class SearchAutoComplete extends React.Component<
  SearchAutoCompleteProps,
  SearchAutoCompleteState
> {
  onBlurTimeout: ?number;

  state = {
    query: this.props.value || '',
    active: true,
    value: this.props.value || ''
  };

  updateQueryString = (rawQueryString: string): void => {
    if (!rawQueryString || !rawQueryString.trim().length) {
      this.setState({ query: '' });
      return;
    }
    // convert all whitespace characters to regular blank
    this.setState({ query: rawQueryString.replace(/\s{2,}/g, ' ') });
  };

  resetValue = (): void => {
    this.updateQueryString('');
  };

  handleChange = (value: string) => {
    this.updateQueryString(value);
    this.props.onSearch(value);
    this.setState({ value });
  };

  handleBlur = () => {
    const { onBlur, onClearResults } = this.props;
    const { value } = this.state;
    this.onBlurTimeout = window.setTimeout(() => {
      this.setState({ active: false });
      onClearResults();
      if (onBlur) {
        onBlur(value);
      }
    }, 150);
  };

  handleFocus = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const value: string = e.currentTarget.value;
    this.props.onSearch(value);
    this.setState({ active: true });
  };

  handleSelect = (idx) => () => {
    const { onSelectResult, results } = this.props;
    onSelectResult(idx);
    this.setState({ active: false });
    this.setState({ value: results[idx].label });
  };

  componentWillUnmount = () => {
    window.clearTimeout(this.onBlurTimeout);
  };

  render() {
    const {
      theme = {},
      placeholder,
      results = [],
      renderResult,
      dataHcName,
      error,
      label
    } = this.props;
    const { active, value } = this.state;
    return (
      <div className={theme.component}>
        <Input
          value={this.state.query}
          type="text"
          data-hc-name={dataHcName}
          className={classNames(defaultTheme.searchInput, theme.searchInput)}
          placeholder={placeholder}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          autoComplete="off"
          value={value}
          canClear
          error={error}
          label={label}
        />
        {results.length > 0 && active && (
          <ul
            className={classNames(defaultTheme.results, theme.results)}
            data-hc-name="header-search-results-list"
          >
            {results.map((r: Result, index: number) => {
              return (
                <li
                  key={r.id}
                  className={classNames(defaultTheme.result, theme.result)}
                  onMouseDown={this.handleSelect(index)}
                >
                  {renderResult(index)}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default themr(
  'SearchAutoCompleteThemed',
  defaultTheme
)(SearchAutoComplete);
