import qs from 'query-string';
import Cookies from 'js-cookie';

export const URL_PARAMS : Array<string> = ['utm_adgroup', 'utm_campaign', 'utm_medium', 'utm_region', 'utm_source', 'utm_term', 'ref', '_ga', 'segment', 'sub_segment'];
export const URL_API_MAPPING : {} = {
  ref: 'referrer',
  '_ga': 'google_analytics_id'
};
export const COOKIE_PARAMS : Array<string> = ['_mkto_trk'];

export const getUrlTrackingValues = (search : string = window.location.search, PARAMS : Array<string> = URL_PARAMS, MAPPING : {} = URL_API_MAPPING) : {} => {
  const urlParams = qs.parse(search);
  return PARAMS.reduce((acm, p) => {
    const k = MAPPING.hasOwnProperty(p) ? MAPPING[p] : p;
    if (urlParams[p]) {
      acm[k] = urlParams[p];
    }
    return acm;
  }, {});
};

export const getCookieTrackingValues = (PARAMS : Array<string> = COOKIE_PARAMS) : {} => {
  return PARAMS.reduce((acm, c) => {
    const value = Cookies.get(c);
    if (value) {
      acm[c] = value;
    }
    return acm;
  }, {});
};

export const getAllTrackingValues = () : {} => {
  return {
    ...getUrlTrackingValues(),
    ...getCookieTrackingValues()
  };
};
