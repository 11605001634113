// @flow
import type { State } from 'src/reducers';
import type { Action } from 'src/actions';

import { LOGOUT_COMPLETE } from 'src/actions/user.actions';

const clearOnLogout = (reducer: any) => {
  return (state: State, action: Action) => {
    if (action.type === LOGOUT_COMPLETE) {
      return reducer(undefined, action);
    } else {
      return reducer(state, action);
    }
  };
};

export default clearOnLogout;
