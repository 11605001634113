// @flow
import React, { type Node } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import HouseCanaryLogo from '@hc/component-lib/hclib/components/atoms/logos/housecanary-logo/HouseCanaryLogo';

import ClickableLink from '../hyperlink/ClickableLink';
import Link from '../hyperlink/Link';
import { PLATFORM } from './constants/products.constants';
import { getEnvUrl } from './constants/url.constants';
import {
  NAVIGATION_BAR_PREFIX,
  HOUSECANARY_LOGO_CLICK,
  PRODUCT_NAME_CLICK
} from './constants/events.constants';

import { NAV_BAR_HC_NAMES } from './hc.names';

import defaultTheme from './NavigationBarTitle.scss';
import { withTracker } from './Tracker';

import { type ProductSlugType } from '../../types/products.types.js';

export type NavigationBarTitleProps = {
  className: ?string,
  env?: 'LOCAL' | 'DEV' | 'STAGE' | 'PROD',
  productSlug: ProductSlugType,
  theme: { [key: string]: string },
  productName: string,
  ProductClickAreaComponent: () => Node,
  platformBaseUrl: string,
  onProductClick: ({ eventType: string, eventName: string }) => void,
  isUserInTestOrg: boolean
};

const ClickableLinkWithTracker = withTracker(ClickableLink);
const LinkWithTracker = withTracker(Link);

class NavigationBarTitle extends React.Component<NavigationBarTitleProps> {
  handelProductClick = () => {
    const { env, productSlug } = this.props;
    const productUrl =
      env === 'LOCAL' ? window.location.href : getEnvUrl(env, productSlug);

    if (productUrl) {
      const url = productSlug === PLATFORM ? `${productUrl}?v=2` : productUrl;
      window.location.assign(window.decodeURIComponent(url));
    }
  };

  render() {
    const {
      className,
      theme,
      env,
      productName,
      ProductClickAreaComponent,
      platformBaseUrl,
      isUserInTestOrg
    } = this.props;

    const ProductNameLink =
      ProductClickAreaComponent || ClickableLinkWithTracker;
    const platformUrl = platformBaseUrl || getEnvUrl(env, PLATFORM);

    return (
      <div
        data-hc-name={NAV_BAR_HC_NAMES.HC_NAV_BAR_TITLE}
        className={classNames(className, theme.NavigationBarTitle)}
      >
        <div className={theme.NavigationBarTitleContainer}>
          <LinkWithTracker
            className={classNames(theme.NavigationBarLogo)}
            href={`${platformUrl}?v=2`}
            type={NAVIGATION_BAR_PREFIX}
            event={HOUSECANARY_LOGO_CLICK}
          >
            <HouseCanaryLogo theme={theme} />
          </LinkWithTracker>
          {productName && [
            <div
              key="nav-bar-title-divider"
              className={theme.NavigationBarDivider}
            />,
            <ProductNameLink
              key="nav-bar-title-name"
              className={classNames(
                theme.NavigationBarProductName,
                theme.NavigationBarClickArea
              )}
              type={NAVIGATION_BAR_PREFIX}
              event={PRODUCT_NAME_CLICK}
              onClick={!ProductClickAreaComponent && this.handelProductClick}
            >
              <span data-hc-name={NAV_BAR_HC_NAMES.HC_PRODUCT_NAME}>
                {productName}
              </span>
            </ProductNameLink>
          ]}
          {isUserInTestOrg && (
            <span
              data-hc-name="test-banner"
              className={defaultTheme.TestOrgChip}
            >
              Test Organization
            </span>
          )}
        </div>
      </div>
    );
  }
}

export const NavigationBarTitleThemed = themr(
  'NavigationBarTitleThemed',
  defaultTheme
)(NavigationBarTitle);

export default NavigationBarTitleThemed;
