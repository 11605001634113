// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import {
  RTThemedLayout,
  RTThemedPanel,
  RTThemedDialog
} from '@hc/component-lib/lib/rt-themed';

import type { Dispatch } from 'redux';
import type { SelectedOrderState } from '../reducers/selected-order.reducer';
import type { HistoryState } from '../../reducers/history.reducer';
import type { Order } from '../types/order';
import type { OrderItemGroup } from '../types/order-item';
import type { State } from '../../reducers';
import type { OrderItemProcessCountsState } from '../reducers/order-item-process-counts.reducer';
import type { OrderItemsState } from '../reducers/order-items.reducer';
import type { ReviewOrderState } from '../reducers/review-order.reducer';
import type { MoveOrderItemsState } from '../reducers/move-order-items.reducer';
import type { CancelOrderState } from '../reducers/cancel-order.reducer';
import type { ExtendOrderState } from '../reducers/extend-order.reducer';
import type { AddOrExtendOrderItemFields } from '../types/add-order';
import type { UserDetailsState } from '../../reducers/user-details.reducer';
import { userDetailsStateSelector } from '../../selectors/user-state.selector';

import extendStyles from './components/ExtendOrder.css';

import RoutingIconButton from '../../components/routing/RoutingIconButton';
import Header from './components/Header';
import {
  selectedOrderStateSelector,
  headerStatusGroupDisplaySelector,
  type HeaderStatusGroupDisplay,
  certifiablePercentageSelector,
  type CertifiablePercentage,
  processCountsStateSelector,
  orderItemsStateSelector,
  reviewOrderStateSelector,
  moveOrderItemsStateSelector
} from 'src/client/selectors/order-items.selectors';
import { downloadOrderSummary } from 'src/client/actions/orders.actions';
import historyStateSelector from 'src/selectors/history-state.selector';
import { ORDERS_ROUTE } from '../routes';
import { openOrderExportsSidePanel } from 'src/client/actions/side-panel.actions';
import { ListTabs, ListTab } from 'src/components/ListTabs';
import InOrderContainer from './InOrderContainer';
import NeedReviewContainer from './NeedReviewContainer';
import RemovedContainer from './RemovedContainer';
import {
  IN_ORDER_GROUP,
  NEED_REVIEW_GROUP,
  REMOVED_GROUP
} from 'src/client/constants/order-items';
import {
  selectOrderItemGroup,
  showReviewCancelOrderDialog,
  dismissReviewCancelOrderDialog,
  reviewAcceptOrder,
  reviewCancelOrder,
  dismissMoveCompleteDialog
} from 'src/client/actions/order-items.actions';
import {
  showExtendOrderDialog,
  dismissExtendOrderDialog,
  extendOrder
} from 'src/client/actions/extend-order.actions';
import ReviewCancelOrderDialog from 'src/client/order-items/components/ReviewCancelOrderDialog';
import { ORDER_STATUSES } from 'src/client/constants/order-statuses';
import MoveCompleteDialog from './components/MoveCompleteDialog';
import { cancelOrderStateSelector } from '../selectors/cancel-order.selectors';
import CancelOrderOrItemDialog from '../components/CancelOrderOrItemDialog';
import { extendOrderStateSelector } from '../selectors/extend-order.selectors';
import {
  showCancelOrderDialog,
  cancelOrder,
  dismissCancelOrderDialog
} from '../actions/cancel-order.actions';
import ExtendOrder from './components/ExtendOrder';
import commonStyles from 'src/containers/common.css';

type OrderItemsProps = {
  selectedOrderState: SelectedOrderState,
  historyState: HistoryState,
  headerStatusGroupsDisplay: HeaderStatusGroupDisplay[],
  processCountsState: OrderItemProcessCountsState,
  orderItemsState: OrderItemsState,
  reviewOrderState: ReviewOrderState,
  moveOrderItemsState: MoveOrderItemsState,
  cancelOrderState: CancelOrderState,
  extendOrderState: ExtendOrderState,
  onDownloadSummary: (orderId: string, orderName: string) => void,
  onShowOrderExports: (order: Order) => void,
  onSelectOrderItemGroup: (orderItemGroup: OrderItemGroup) => void,
  onShowReviewCancelOrderDialog: () => void,
  onDismissReviewCancelDialog: () => void,
  onReviewCancelOrder: (orderId: string) => void,
  onAcceptOrder: (orderId: string) => void,
  onDimissMoveCompleteDialog: () => void,
  onCancelOrder: (string) => void,
  onShowCancelOrderDialog: (string) => void,
  onDismissCancelOrderDialog: () => void,
  onShowExtendOrderDialog: () => void,
  onDismissExtendOrderDialog: () => void,
  onExtendOrder: () => void,
  userDetailsState: UserDetailsState,
  certifiablePercentage: CertifiablePercentage
};

class OrderItems extends React.Component<OrderItemsProps> {
  render() {
    const {
      selectedOrderState,
      historyState,
      headerStatusGroupsDisplay,
      processCountsState,
      orderItemsState,
      reviewOrderState,
      moveOrderItemsState,
      cancelOrderState,
      extendOrderState,
      onDownloadSummary,
      onShowOrderExports,
      onSelectOrderItemGroup,
      onDismissReviewCancelDialog,
      onReviewCancelOrder,
      onAcceptOrder,
      onDimissMoveCompleteDialog,
      onShowCancelOrderDialog,
      onDismissCancelOrderDialog,
      onCancelOrder,
      onShowExtendOrderDialog,
      onDismissExtendOrderDialog,
      onExtendOrder,
      userDetailsState,
      certifiablePercentage
    } = this.props;

    if (selectedOrderState.status !== 'loaded') {
      // Can happen while the order is being loaded.  Wait until next render to show.
      return <div />;
    }

    const userDetails =
      userDetailsState.status === 'loaded'
        ? userDetailsState.userDetails
        : null;

    return (
      <RTThemedLayout>
        <RTThemedPanel>
          <div className={commonStyles.blueGradientBg}>
            <RoutingIconButton
              data-hc-name="back-link"
              icon="arrow_back"
              white
              to={
                historyState.isCheckpointSet
                  ? historyState.lastCheckpointRoute
                  : ORDERS_ROUTE
              }
            />
          </div>
          <div className={commonStyles.panelContent}>
            <div className={commonStyles.clientHeader}>
              <Header
                order={selectedOrderState.order}
                headerStatusGroupsDisplay={headerStatusGroupsDisplay}
                certifiablePercentage={certifiablePercentage}
                maxPercentInvalidItems={
                  selectedOrderState.order.maxPercentInvalidItems
                }
                cancelDisabled={
                  reviewOrderState.status === 'canceling' ||
                  reviewOrderState.status === 'ordering'
                }
                startProcessingDisabled={
                  reviewOrderState.status === 'ordering' ||
                  (processCountsState.status === 'loaded'
                    ? processCountsState.processCountsDetails.includedItems ===
                      0
                    : true)
                }
                onStartProcessingClick={() =>
                  onAcceptOrder(selectedOrderState.order.id)
                }
                onDownloadSummaryClick={onDownloadSummary}
                onDownloadAllReportsClick={() =>
                  onShowOrderExports(selectedOrderState.order)
                }
                onShowCancelOrderDialogClick={onShowCancelOrderDialog}
                onShowExtendOrderDialogClick={onShowExtendOrderDialog}
                canExtendOrder={
                  userDetails ? userDetails.canCreateOrders : false
                }
              />
            </div>
            <RTThemedDialog
              onEscKeyDown={onDismissExtendOrderDialog}
              active={extendOrderState.dialogVisible}
              theme={extendStyles}
            >
              {extendOrderState.dialogVisible ? (
                <ExtendOrder
                  visible={extendOrderState.dialogVisible}
                  order={selectedOrderState.order}
                  onDismissExtendOrderDialogClick={onDismissExtendOrderDialog}
                  onExtendOrder={onExtendOrder}
                />
              ) : (
                <div />
              )}
            </RTThemedDialog>
            <div className={commonStyles.listContainer}>
              <ListTabs dataHcName="order-tabs">
                <ListTab
                  label="In Order"
                  count={
                    processCountsState.status === 'loaded'
                      ? processCountsState.processCountsDetails.includedItems
                      : 0
                  }
                  isActive={orderItemsState.orderItemGroup === IN_ORDER_GROUP}
                  isPaused={
                    selectedOrderState.order.status ===
                    ORDER_STATUSES.CLIENT_REVIEW
                  }
                  onClick={() => onSelectOrderItemGroup(IN_ORDER_GROUP)}
                />
                <ListTab
                  label="Need Review"
                  count={
                    processCountsState.status === 'loaded'
                      ? processCountsState.processCountsDetails.verifyItems
                      : 0
                  }
                  isActive={
                    orderItemsState.orderItemGroup === NEED_REVIEW_GROUP
                  }
                  hasNotification={
                    processCountsState.status === 'loaded'
                      ? processCountsState.processCountsDetails.verifyItems > 0
                      : false
                  }
                  onClick={() => onSelectOrderItemGroup(NEED_REVIEW_GROUP)}
                />
                <ListTab
                  label="Removed"
                  count={
                    processCountsState.status === 'loaded'
                      ? processCountsState.processCountsDetails.excludedItems
                      : 0
                  }
                  isActive={orderItemsState.orderItemGroup === REMOVED_GROUP}
                  onClick={() => onSelectOrderItemGroup(REMOVED_GROUP)}
                />
              </ListTabs>
              {orderItemsState.orderItemGroup === IN_ORDER_GROUP && (
                <InOrderContainer />
              )}
              {orderItemsState.orderItemGroup === NEED_REVIEW_GROUP && (
                <NeedReviewContainer />
              )}
              {orderItemsState.orderItemGroup === REMOVED_GROUP && (
                <RemovedContainer />
              )}
            </div>
          </div>
          <ReviewCancelOrderDialog
            show={reviewOrderState.showCancelDialog}
            loading={reviewOrderState.status === 'canceling'}
            onCancel={() => onReviewCancelOrder(selectedOrderState.order.id)}
            onDismiss={onDismissReviewCancelDialog}
            errorMessage={
              reviewOrderState.status === 'cancelError'
                ? reviewOrderState.errorMessage
                : null
            }
          />
          <MoveCompleteDialog
            active={moveOrderItemsState.moveCompleteDialog.active}
            onDismiss={onDimissMoveCompleteDialog}
            onTimeout={onDimissMoveCompleteDialog}
            timeout={2000}
            succeededMessage={
              moveOrderItemsState.moveCompleteDialog.succeededMessage
            }
            failedMessage={moveOrderItemsState.moveCompleteDialog.failedMessage}
          />
          <CancelOrderOrItemDialog
            type="order"
            orderId={
              cancelOrderState.dialogVisible ? cancelOrderState.orderId : null
            }
            show={cancelOrderState.dialogVisible}
            onCancel={onCancelOrder}
            onDismiss={onDismissCancelOrderDialog}
            loading={cancelOrderState.status === 'loading'}
            cancelled={cancelOrderState.status === 'cancelled'}
            error={
              cancelOrderState.status === 'error'
                ? cancelOrderState.error
                : null
            }
          />
        </RTThemedPanel>
      </RTThemedLayout>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    selectedOrderState: selectedOrderStateSelector(state),
    historyState: historyStateSelector(state),
    headerStatusGroupsDisplay: headerStatusGroupDisplaySelector(state),
    certifiablePercentage: certifiablePercentageSelector(state),
    processCountsState: processCountsStateSelector(state),
    orderItemsState: orderItemsStateSelector(state),
    reviewOrderState: reviewOrderStateSelector(state),
    moveOrderItemsState: moveOrderItemsStateSelector(state),
    cancelOrderState: cancelOrderStateSelector(state),
    extendOrderState: extendOrderStateSelector(state),
    userDetailsState: userDetailsStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onDownloadSummary: (orderId: string, orderName: string) =>
      dispatch(downloadOrderSummary(orderId, orderName)),
    onShowOrderExports: (order: Order) =>
      dispatch(openOrderExportsSidePanel(order)),
    onSelectOrderItemGroup: (orderItemGroup: OrderItemGroup) =>
      dispatch(selectOrderItemGroup(orderItemGroup)),
    onShowReviewCancelOrderDialog: () =>
      dispatch(showReviewCancelOrderDialog()),
    onDismissReviewCancelDialog: () =>
      dispatch(dismissReviewCancelOrderDialog()),
    onReviewCancelOrder: (orderId: string) =>
      dispatch(reviewCancelOrder(orderId)),
    onAcceptOrder: (orderId: string) => dispatch(reviewAcceptOrder(orderId)),
    onDimissMoveCompleteDialog: () => dispatch(dismissMoveCompleteDialog()),
    onShowCancelOrderDialog: (orderId: string) =>
      dispatch(showCancelOrderDialog(orderId)),
    onCancelOrder: (id: string) => dispatch(cancelOrder(id)),
    onDismissCancelOrderDialog: () => dispatch(dismissCancelOrderDialog()),
    onShowExtendOrderDialog: () => dispatch(showExtendOrderDialog()),
    onDismissExtendOrderDialog: () => {
      dispatch(dismissExtendOrderDialog());
    },
    onExtendOrder: (orderId: string, values: AddOrExtendOrderItemFields) =>
      dispatch(extendOrder(orderId, values))
  };
}

const OrderItemsWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderItems);
export default OrderItemsWrapped;
