// @flow
import type { State } from 'src/reducers';
import type { UserState } from 'src/reducers/user.reducer';
import type { UserDetailsState } from 'src/reducers/user-details.reducer';
import type { LoginState } from 'src/reducers/login.reducer';
import type { ForgotPasswordState } from 'src/reducers/forgot-password.reducer';
import type { ResetPasswordState } from 'src/reducers/reset-password.reducer';
// $FlowFixMe
import { createSelector } from 'reselect';
import { get } from 'lodash-es';

export const userStateSelector = (state: State): UserState => state.user;

export const userDetailsStateSelector = (state: State): UserDetailsState =>
  userStateSelector(state).userDetails;
export const isAdminUserSelector = createSelector(
  userDetailsStateSelector,
  (userDetailsState) => get(userDetailsState, 'userDetails.isAdminUser', false)
);
export const loginStateSelector = (state: State): LoginState =>
  userStateSelector(state).login;
export const forgotPasswordStateSelector = (
  state: State
): ForgotPasswordState => userStateSelector(state).forgotPassword;
export const resetPasswordStateSelector = (state: State): ResetPasswordState =>
  userStateSelector(state).resetPassword;

export default userStateSelector;
