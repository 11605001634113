import React from 'react';

import agileAppraisalIcon from '../assets/images/agile_appraisal.external.svg';
import agileCertifiedIcon from '../assets/images/agile_certified.external.svg';
import agileEvaluationIcon from '../assets/images/agile_evaluation.external.svg';
import agileInsightsIcon from '../assets/images/agile_insights.external.svg';
import agileInspectionIcon from '../assets/images/agile_inspection.external.svg';
import agileSuiteIcon from '../assets/images/agile_suite.external.svg';
import dataExplorerIcon from '../assets/images/data_explorer.external.svg';
import developerCenterIcon from '../assets/images/developer_center.external.svg';
import marketExplorerIcon from '../assets/images/market_explorer.external.svg';
import platformIcon from '../assets/images/platform.external.svg';
import platformHero from '../assets/images/platform_hero.external.svg';
import propertyExplorerIcon from '../assets/images/property_explorer.external.svg';

export const AgileAppraisalIcon = ({ ...props }) => (
  <img src={agileAppraisalIcon} {...props} />
);
export const AgileCertifiedIcon = ({ ...props }) => (
  <img src={agileCertifiedIcon} {...props} />
);
export const AgileEvaluationIcon = ({ ...props }) => (
  <img src={agileEvaluationIcon} {...props} />
);
export const AgileInsightsIcon = ({ ...props }) => (
  <img src={agileInsightsIcon} {...props} />
);
export const AgileInspectionIcon = ({ ...props }) => (
  <img src={agileInspectionIcon} {...props} />
);
export const AgileSuiteIcon = ({ ...props }) => (
  <img src={agileSuiteIcon} {...props} />
);
export const DataExplorerIcon = ({ ...props }) => (
  <img src={dataExplorerIcon} {...props} />
);
export const DeveloperCenterIcon = ({ ...props }) => (
  <img src={developerCenterIcon} {...props} />
);
export const MarketExplorerIcon = ({ ...props }) => (
  <img src={marketExplorerIcon} {...props} />
);
export const PlatformIcon = ({ ...props }) => (
  <img src={platformIcon} {...props} />
);
export const PlatformHero = ({ ...props }) => (
  <img src={platformHero} {...props} />
);
export const PropertyExplorerIcon = ({ ...props }) => (
  <img src={propertyExplorerIcon} {...props} />
);
