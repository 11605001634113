// @flow
export {
  getLogService,
  log,
  logError,
  logWarning,
  logInfo
} from './services/log';
export {
  getAllTrackingValues,
  getCookieTrackingValues,
  getUrlTrackingValues,
  URL_PARAMS,
  COOKIE_PARAMS,
  URL_API_MAPPING
} from './services/tracking';
export {
  getValidator,
  validateEmail,
  validatePassword,
  validateRequired,
  emailValidator,
  isRequiredValidator,
  passwordValidator,
  getEmailAvailableValidator,
  ValidationError
} from './services/validator';
export {
  getUserApplicationsPermissions,
  userHasApplicationPermissions,
  userHasComponentPermissions
} from './services/permissions';
export {
  defaultQuery,
  EQ,
  I_LIKE,
  LIKE,
  HAS,
  ANY,
  DEFAULT_QUERY_LIMIT
} from './services/query';
export type { Query } from './services/query';

export {
  isApplicationActiveSubscription,
  isApplicationTrialSubscription,
  isActiveApplicationTrialSubscription,
  isValidApplicationTrialSubscription,
  userHasActiveApplicationSubscription
} from './services/subscription';
