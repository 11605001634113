// @flow
import React from 'react';
import { themr } from 'react-css-themr';
import { RTThemedDrawer } from '@hc/component-lib/lib/rt-themed';

import defaultTheme from './Drawer.css';

type DrawerProps = {
  side: string,
  show: boolean,
  children?: any,
  onOverlayClick?: () => void,
  theme: {
    drawer: string
  }
};

type DrawerState = {
  show: boolean,
  side?: string
};

class Drawer extends React.PureComponent<DrawerProps, DrawerState> {
  constructor(props: DrawerProps) {
    super(props);
    this.state = { show: props.show };
  }

  UNSAFE_componentWillReceiveProps(nextProps: DrawerProps) {
    this.setState({
      side: nextProps.side,
      show: nextProps.show
    });
  }

  handleToggle = () => {
    this.setState({ show: !this.state.show });
    if (this.props.onOverlayClick) {
      this.props.onOverlayClick();
    }
  };

  render() {
    const { theme, side, children } = this.props;
    const { show } = this.state;
    return (
      <RTThemedDrawer
        theme={theme}
        type={side}
        active={show}
        onOverlayClick={this.handleToggle}
      >
        {children}
      </RTThemedDrawer>
    );
  }
}

export default themr('ThemedDrawer', defaultTheme)(Drawer);
