// @flow
import React from 'react';
import debounce from 'lodash.debounce';
import { Input } from '@hc/component-lib';

import styles from './Search.css';

type OnSearch = (string) => void;

type SearchProps = {
  query: ?string,
  className?: string,
  placeholder?: string,
  onSearch: OnSearch,
  dataHcName?: string
};

type SearchState = {
  updatedQuery: ?string,
  debouncedOnSearch: OnSearch
};

class Search extends React.Component<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);
    this.state = {
      updatedQuery: props.query,
      debouncedOnSearch: debounce(props.onSearch, 500)
    };
  }

  handleChange = (query: string) => {
    this.setState({ updatedQuery: query });
    this.state.debouncedOnSearch(query);
  };

  render() {
    const { className, placeholder = 'Search...', dataHcName } = this.props;

    return (
      <Input
        value={this.state.updatedQuery}
        className={className}
        theme={styles}
        placeholder={placeholder}
        onChange={this.handleChange}
        data-hc-name={dataHcName}
        canClear
      />
    );
  }
}

export default Search;
