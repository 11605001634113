// @flow
// $FlowFixMe
import { createSelector } from 'reselect';

import type { State } from '../../reducers';
import type { OrderItemsState } from '../reducers/order-items.reducer';
import type { InOrderItemsState } from '../reducers/in-order-items.reducer';

import type { RemovedItemsState } from '../reducers/removed-items.reducer';
import type { SelectedOrderState } from '../reducers/selected-order.reducer';
import type { OrderProgressState } from '../reducers/order-progress.reducer';
import type { StatusGroup } from '../types/order';
import type { SelectedOrderOwnerState } from '../reducers/selected-order-owner.reducer';
import type { OrderItemProcessCountsState } from '../reducers/order-item-process-counts.reducer';
import type { NeedReviewItemsState } from '../reducers/need-review-items.reducer';
import type { UpdateReverifyItemState } from '../reducers/update-reverify-item.reducer';
import type { ReviewOrderState } from '../reducers/review-order.reducer';
import type { MoveOrderItemsState } from '../reducers/move-order-items.reducer';

import { orderProgressStateSelector } from './order-progress.selectors';
import clientStateSelector from './client.selector';

export const orderItemsStateSelector = (state: State): OrderItemsState =>
  clientStateSelector(state).orderItems.orderItems;

export const inOrderItemsStateSelector = (state: State): InOrderItemsState =>
  clientStateSelector(state).orderItems.inOrderItems;

export const selectedOrderStateSelector = (state: State): SelectedOrderState =>
  clientStateSelector(state).orderItems.selectedOrder;

export const selectedOrderOwnerStateSelector = (
  state: State
): SelectedOrderOwnerState =>
  clientStateSelector(state).orderItems.selectedOrderOwner;

export type HeaderStatusGroupDisplay = {
  label: string,
  percentage: number,
  key: string
};
export const headerStatusGroupDisplaySelector = createSelector(
  selectedOrderStateSelector,
  orderProgressStateSelector,
  (
    selectedOrderState: SelectedOrderState,
    orderProgressState: OrderProgressState
  ): HeaderStatusGroupDisplay[] => {
    if (
      orderProgressState.status !== 'loaded' ||
      selectedOrderState.status !== 'loaded'
    ) {
      return [];
    }
    const order = selectedOrderState.order;
    const orderProgress = orderProgressState.orderProgress;
    return order.orderTypeDescriptor.headerStatusGroups.map(
      (headerStatusGroup: StatusGroup) => {
        const statusGroupCount: number = Object.keys(orderProgress).reduce(
          (count, orderItemStatus) => {
            if (
              headerStatusGroup.includedStatuses.indexOf(orderItemStatus) !== -1
            ) {
              count = count + orderProgress[orderItemStatus];
            }
            return count;
          },
          0
        );
        const statusGroupPercentage =
          statusGroupCount > 0 && order.orderItemsToProcessCount > 0
            ? Math.round(
                (statusGroupCount / order.orderItemsToProcessCount) * 100
              )
            : 0;
        return {
          label: headerStatusGroup.name,
          percentage: statusGroupPercentage,
          key: headerStatusGroup.name.replace(/\s+/g, '-').toLowerCase()
        };
      }
    );
  }
);

export type CertifiablePercentage = number;

export const certifiablePercentageSelector = createSelector(
  selectedOrderStateSelector,
  orderProgressStateSelector,
  (
    selectedOrderState: SelectedOrderState,
    orderProgressState: OrderProgressState
  ): CertifiablePercentage => {
    if (
      orderProgressState.status !== 'loaded' ||
      selectedOrderState.status !== 'loaded'
    ) {
      return 0;
    }
    const { orderItemsCertifiedCount, orderItemsToProcessCount } =
      selectedOrderState.order;
    return orderItemsCertifiedCount && orderItemsToProcessCount > 0
      ? Math.round((orderItemsCertifiedCount / orderItemsToProcessCount) * 100)
      : 0;
  }
);

export const processCountsStateSelector = (
  state: State
): OrderItemProcessCountsState =>
  clientStateSelector(state).orderItems.processCounts;

export const needReviewItemsStateSelector = (
  state: State
): NeedReviewItemsState =>
  clientStateSelector(state).orderItems.needReviewItems;

export const removedItemsStateSelector = (state: State): RemovedItemsState =>
  clientStateSelector(state).orderItems.removedItems;

export const updateReverifyItemStateSelector = (
  state: State
): UpdateReverifyItemState =>
  clientStateSelector(state).orderItems.updateReverifyItem;

export const reviewOrderStateSelector = (state: State): ReviewOrderState =>
  clientStateSelector(state).orderItems.reviewOrder;

export const moveOrderItemsStateSelector = (
  state: State
): MoveOrderItemsState => clientStateSelector(state).orderItems.moveOrderItems;
