import { sagaMiddleware } from 'src/sagas';
import { adminLoginSaga, adminLogoutSaga } from './admin-user.saga';
import { registerWorkflowWorkerSagas } from './workflow-workers.saga';
import { registerWorkflowExternalTasksSagas } from './workflow-external-tasks.saga';

export function runSagas() {
  sagaMiddleware.run(adminLoginSaga);
  sagaMiddleware.run(adminLogoutSaga);
  sagaMiddleware.run(registerWorkflowWorkerSagas);
  sagaMiddleware.run(registerWorkflowExternalTasksSagas);
}
