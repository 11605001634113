// @flow
import { type ValuationRevision } from '../types/valuation-revision';

export const VALUATION_REVISION_SUCCESS = 'VALUATION_REVISION_SUCCESS';
export const VALUATION_REVISION_ERROR = 'VALUATION_REVISION_ERROR';
export const VALUATION_REVISION_RESPONSE = 'VALUATION_REVISION_RESPONSE';
export const VALUATION_REVISION_RESPONSE_SUCCESS =
  'VALUATION_REVISION_RESPONSE_SUCCESS';
export const VALUATION_REVISION_RESPONSE_ERROR =
  'VALUATION_REVISION_RESPONSE_ERROR';

export type ValuationRevisionSuccessAction = {
  type: 'VALUATION_REVISION_SUCCESS',
  payload: {
    valuation: ValuationRevision
  }
};

export type ValuationRevisionErrorAction = {
  type: 'VALUATION_REVISION_ERROR',
  payload: {
    error: string
  }
};

export type ValuationRevisionResponseAction = {
  type: 'VALUATION_REVISION_RESPONSE',
  payload: {
    response: 'accept' | 'reject',
    comment: ?string
  }
};

export type ValuationRevisionResponseSuccessAction = {
  type: 'VALUATION_REVISION_RESPONSE_SUCCESS'
};

export type ValuationRevisionResponseErrorAction = {
  type: 'VALUATION_REVISION_RESPONSE_ERROR',
  payload: {
    error: string
  }
};

export type ValuationRevisionAction =
  | ValuationRevisionSuccessAction
  | ValuationRevisionErrorAction
  | ValuationRevisionResponseAction
  | ValuationRevisionResponseSuccessAction
  | ValuationRevisionResponseErrorAction;

export const valuationRevisionSuccess = (
  valuation: ValuationRevision
): ValuationRevisionSuccessAction => ({
  type: VALUATION_REVISION_SUCCESS,
  payload: { valuation }
});
export const valuationRevisionError = (
  error: string
): ValuationRevisionErrorAction => ({
  type: VALUATION_REVISION_ERROR,
  payload: { error }
});

export const valuationRevisionResponse = (
  response: 'accept' | 'reject',
  comment: ?string
): ValuationRevisionResponseAction => ({
  type: VALUATION_REVISION_RESPONSE,
  payload: { response, comment }
});
export const valuationRevisionResponseSuccess =
  (): ValuationRevisionResponseSuccessAction => ({
    type: VALUATION_REVISION_RESPONSE_SUCCESS
  });
export const valuationRevisionResponseError = (
  error: string
): ValuationRevisionResponseErrorAction => ({
  type: VALUATION_REVISION_RESPONSE_ERROR,
  payload: { error }
});
