import React from 'react';
import { FieldArray } from 'redux-form';

import AddressFields from './AddressFields';

const validateIndividualItems = (value) => {
  if (!value) {
    return 'Enter at least one address';
  }
  let hasAtLeastOne = false;
  value.forEach((addressValue) => {
    if (
      addressValue &&
      addressValue.address &&
      Object.keys(addressValue.address).length > 0
    ) {
      hasAtLeastOne = true;
    }
  });
  if (!hasAtLeastOne) {
    return 'Enter at least one address';
  }
};

const IndividualAddresses = (props) => {
  return (
    <FieldArray
      name="items"
      component={AddressFields}
      validate={validateIndividualItems}
      props={props}
    />
  );
};

export default IndividualAddresses;
