// @flow
import React from 'react';
import { connect } from 'react-redux';

import type { Dispatch } from 'redux';
import type { SelectedOrderState } from 'src/client/reducers/selected-order.reducer';
import type { OrderItemsState } from 'src/client/reducers/order-items.reducer';
import type { State } from 'src/reducers';
import type { NeedReviewItemsState } from 'src/client/reducers/need-review-items.reducer';
import type { UpdateReverifyItemState } from 'src/client/reducers/update-reverify-item.reducer';
import type { MoveOrderItemsState } from 'src/client/reducers/move-order-items.reducer';
import type { OrderingParams } from 'src/types/list';
import type { OrderItem } from 'src/client/types/order-item';

import NeedReviewList from './components/NeedReviewList';
import {
  selectedOrderStateSelector,
  orderItemsStateSelector,
  updateReverifyItemStateSelector,
  needReviewItemsStateSelector,
  moveOrderItemsStateSelector
} from 'src/client/selectors/order-items.selectors';
import {
  toggleItemSelect,
  toggleAllItemsSelect,
  updateReverifyItem,
  sortOrderItems,
  searchOrderItems,
  addItemsToOrder,
  removeItemsFromOrder
} from 'src/client/actions/order-items.actions';
import * as logger from 'src/logger';
import LoadingButton from 'src/components/loading-button/LoadingButton';
import { NEED_REVIEW_GROUP } from 'src/client/constants/order-items';
import {
  buildListLink,
  shouldDisplayList
} from 'src/client/helpers/list-helpers';
import EmptyListPlaceholder from './components/EmptyListPlaceholder';
import Search from 'src/components/Search.jsx';
import { ORDER_STATUSES } from 'src/client/constants/order-statuses';

import styles from './order-items.css';

type NeedReviewContainerProps = {
  selectedOrderState: SelectedOrderState,
  orderItemsState: OrderItemsState,
  needReviewItemsState: NeedReviewItemsState,
  updateReverifyItemState: UpdateReverifyItemState,
  moveOrderItemsState: MoveOrderItemsState,
  onAddItemsToOrder: (orderId: string, selectedOrderItemIds: string[]) => void,
  onRemoveItemsFromOrder: (
    orderId: string,
    selectedOrderItemIds: string[]
  ) => void,
  onSearch: (query: string) => void,
  onSort: (OrderingParams[]) => void,
  onUpdateReverifyItem: (
    orderId: string,
    orderItemId: string,
    onUpdateComplete: () => void,
    formName: string
  ) => void,
  onToggleItemSelect: (orderItemId: string) => void,
  onToggleAllItemsSelect: (orderItemIds: string[], toggleOn: boolean) => void,
  onDimissMoveCompleteDialog: () => void
};

type NeedReviewContainerState = {
  canAnySelectedBeAdded: boolean
};

class NeedReviewItems extends React.Component<
  NeedReviewContainerProps,
  NeedReviewContainerState
> {
  state = {
    canAnySelectedBeAdded: false
  };

  getSelectedOrderItems = (orderItemsState: OrderItemsState): string[] => {
    return orderItemsState.selectedItemsByGroup[NEED_REVIEW_GROUP];
  };

  UNSAFE_componentWillReceiveProps = (nextProps: NeedReviewContainerProps) => {
    const selectedOrderItems = this.getSelectedOrderItems(
      nextProps.orderItemsState
    );
    if (selectedOrderItems.length > 0) {
      if (
        (nextProps.orderItemsState !== this.props.orderItemsState ||
          nextProps.needReviewItemsState !== this.props.needReviewItemsState) &&
        nextProps.needReviewItemsState.status === 'loaded'
      ) {
        const pageItemsById: {
          [id: string]: OrderItem
        } = nextProps.needReviewItemsState.pageItems.reduce(
          (accum, pageItem: OrderItem) => {
            accum[pageItem.id] = pageItem;
            return accum;
          },
          {}
        );
        this.setState({
          canAnySelectedBeAdded: selectedOrderItems.some(
            (id: string) => pageItemsById[id].isOverridable
          )
        });
      }
    } else {
      this.setState({
        canAnySelectedBeAdded: false
      });
    }
  };

  paginationRouteGeneratorFn = (page: ?number): string => {
    const { needReviewItemsState } = this.props;
    const { selectedOrderState } = this.props;
    if (
      needReviewItemsState.status === 'loaded' &&
      selectedOrderState.status === 'loaded'
    ) {
      return buildListLink(
        `/client/order/${selectedOrderState.order.id}/${NEED_REVIEW_GROUP}`,
        needReviewItemsState.query,
        needReviewItemsState.ordering,
        page
      );
    } else {
      logger.logException(
        new Error(
          'InReviewItemsContainer#paginationRouteGeneratorFn selectedOrder is not defined or orderItems not loaded'
        )
      );
      return '';
    }
  };

  render() {
    const {
      needReviewItemsState,
      orderItemsState,
      selectedOrderState,
      updateReverifyItemState,
      moveOrderItemsState,
      onAddItemsToOrder,
      onRemoveItemsFromOrder,
      onUpdateReverifyItem,
      onSearch,
      onSort,
      onToggleItemSelect,
      onToggleAllItemsSelect
    } = this.props;

    const selectedOrderItems: string[] =
      this.getSelectedOrderItems(orderItemsState);

    return selectedOrderState.status === 'loaded' ? (
      <div>
        <div className={styles.listContainer}>
          {needReviewItemsState.status === 'error' && (
            <div>{needReviewItemsState.errorMessage}</div>
          )}
          {needReviewItemsState.status === 'loaded' &&
            shouldDisplayList(
              needReviewItemsState.pageItems,
              needReviewItemsState.query
            ) && (
              <div>
                {(selectedOrderState.order.partialEnabled ||
                  selectedOrderState.order.status ===
                    ORDER_STATUSES.CLIENT_REVIEW) && (
                  <div>
                    <div className={styles.disclaimer}>
                      There were problems with the address(es) below and we
                      couldn't fully process this order. Please review the
                      address information, make any necessary changes, and then
                      click either "add to order" or "remove" to proceed.
                    </div>
                    <div className={styles.listActionsContainer}>
                      <div>
                        <LoadingButton
                          className={styles.listAction}
                          primary={false}
                          micro
                          label="Add to Order"
                          loading={
                            moveOrderItemsState.status === 'loading' &&
                            moveOrderItemsState.movingType === 'add'
                          }
                          disabled={
                            !(selectedOrderItems.length > 0) ||
                            this.state.canAnySelectedBeAdded !== true ||
                            updateReverifyItemState.status === 'canceling' ||
                            needReviewItemsState.fetching ||
                            moveOrderItemsState.status === 'loading'
                          }
                          onClick={() =>
                            onAddItemsToOrder(
                              selectedOrderState.order.id,
                              selectedOrderItems
                            )
                          }
                        />
                        <LoadingButton
                          className={styles.listAction}
                          primary={false}
                          micro
                          label="Remove"
                          loading={
                            moveOrderItemsState.status === 'loading' &&
                            moveOrderItemsState.movingType === 'remove'
                          }
                          disabled={
                            !(selectedOrderItems.length > 0) ||
                            updateReverifyItemState.status === 'canceling' ||
                            needReviewItemsState.fetching ||
                            moveOrderItemsState.status === 'loading'
                          }
                          onClick={() =>
                            onRemoveItemsFromOrder(
                              selectedOrderState.order.id,
                              selectedOrderItems
                            )
                          }
                        />
                      </div>
                      <Search
                        query={needReviewItemsState.query}
                        onSearch={onSearch}
                      />
                    </div>
                  </div>
                )}
                <NeedReviewList
                  orderItems={needReviewItemsState.pageItems}
                  loading={needReviewItemsState.fetching}
                  links={needReviewItemsState.links}
                  paginationRouteGeneratorFn={this.paginationRouteGeneratorFn}
                  ordering={
                    needReviewItemsState.ordering.length > 0
                      ? needReviewItemsState.ordering
                      : needReviewItemsState.defaultOrdering
                  }
                  selectedOrderItems={selectedOrderItems}
                  updatingOrderItemId={
                    updateReverifyItemState.status === 'loading'
                      ? updateReverifyItemState.orderItemId
                      : null
                  }
                  propertyTypeChoices={
                    selectedOrderState.order.orderTypeDescriptor.propertyTypes
                  }
                  onSort={onSort}
                  onToggleOrderItem={onToggleItemSelect}
                  onToggleAll={onToggleAllItemsSelect}
                  onUpdateReverify={(orderItemId, onUpdateComplete, formName) =>
                    onUpdateReverifyItem(
                      selectedOrderState.order.id,
                      orderItemId,
                      onUpdateComplete,
                      formName
                    )
                  }
                />
              </div>
            )}
          {needReviewItemsState.status === 'loaded' &&
            !shouldDisplayList(
              needReviewItemsState.pageItems,
              needReviewItemsState.query
            ) && <EmptyListPlaceholder descriptor="No addresses need review" />}
        </div>
      </div>
    ) : (
      <div />
    );
  }
}

function mapStateToProps(state: State) {
  return {
    selectedOrderState: selectedOrderStateSelector(state),
    orderItemsState: orderItemsStateSelector(state),
    needReviewItemsState: needReviewItemsStateSelector(state),
    updateReverifyItemState: updateReverifyItemStateSelector(state),
    moveOrderItemsState: moveOrderItemsStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onAddItemsToOrder: (orderId: string, orderItemIds: string[]) =>
      dispatch(addItemsToOrder(NEED_REVIEW_GROUP, orderId, orderItemIds)),
    onRemoveItemsFromOrder: (orderId: string, orderItemsId: string[]) =>
      dispatch(removeItemsFromOrder(NEED_REVIEW_GROUP, orderId, orderItemsId)),
    onSearch: (query: string) =>
      dispatch(searchOrderItems(NEED_REVIEW_GROUP, query)),
    onSort: (ordering: OrderingParams[]) =>
      dispatch(sortOrderItems(NEED_REVIEW_GROUP, ordering)),
    onUpdateReverifyItem: (
      orderId: string,
      orderItemId: string,
      onUpdateComplete: () => void,
      formName: string
    ) =>
      dispatch(
        updateReverifyItem(orderId, orderItemId, onUpdateComplete, formName)
      ),
    onToggleItemSelect: (orderItemId: string) =>
      dispatch(toggleItemSelect(NEED_REVIEW_GROUP, orderItemId)),
    onToggleAllItemsSelect: (orderItemIds: string[], toggleOn: boolean) =>
      dispatch(toggleAllItemsSelect(NEED_REVIEW_GROUP, orderItemIds, toggleOn))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NeedReviewItems);
