// @flow
import type { Action } from '../actions';

import {
  SUBMIT_FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS
} from '../actions/forgot-password.actions';

type ForgotPasswordDefaultState = {
  loading: false
};
type ForgotPasswordLoadingState = {
  loading: true
};
type ForgotPasswordErrorState = {
  loading: false,
  errorMessage: string
};
export type ForgotPasswordState =
  | ForgotPasswordDefaultState
  | ForgotPasswordLoadingState
  | ForgotPasswordErrorState;

const defaultState = {
  loading: false
};

function forgotPasswordReducer(
  previousState: ForgotPasswordState = defaultState,
  action: Action
): ForgotPasswordState {
  switch (action.type) {
    case SUBMIT_FORGOT_PASSWORD: {
      return {
        loading: true
      };
    }
    case FORGOT_PASSWORD_ERROR: {
      return {
        loading: false,
        errorMessage: action.payload.errorMessage
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        loading: false
      };
    }
    default: {
      return previousState;
    }
  }
}

export default forgotPasswordReducer;
