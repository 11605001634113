// @flow
import React from 'react';
import { connect } from 'react-redux';

import type { Dispatch } from 'redux';
import type { ValuationCommentState } from '../reducers/valuation-comment.reducer.js';

import valuationCommentStateSelector from '../selectors/valuation-comment.selector.js';

import { HcHeader } from '../../components/HcHeader';
import ValuationCommentsForm from '../components/ValuationCommentsForm';
import ValuationCommentsError from '../components/ValuationCommentsError';
import { valuationCommentAdd } from '../actions/valuation-comment.actions.js';

import commonStyles from '../../containers/common.css';

type ValuationCommentsProps = {
  valuationCommentState: ValuationCommentState,
  onAddMessage: (comment: string) => void
};

function mapMessage(message) {
  return { ...message, isIncoming: !message.isAmcComment };
}

class ValuationComments extends React.Component<ValuationCommentsProps, void> {
  render() {
    const { valuationCommentState, onAddMessage } = this.props;

    return (
      <div>
        <HcHeader showAppLinks={false} userDetails={null} userContext={null} />
        <div className={commonStyles.containerContentWithHcHeader}>
          <div style={{ paddingTop: '75px' }} />
          {valuationCommentState.status === 'error' ? (
            <ValuationCommentsError message={valuationCommentState.message} />
          ) : null}
          {valuationCommentState.status === 'loaded' ? (
            <ValuationCommentsForm
              address={valuationCommentState.details.address}
              unit={valuationCommentState.details.unit}
              city={valuationCommentState.details.city}
              state={valuationCommentState.details.state}
              zipcode={valuationCommentState.details.zipcode}
              comments={valuationCommentState.details.comments.map(mapMessage)}
              onAddComment={onAddMessage}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuationCommentState: valuationCommentStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onAddMessage: (comment: string) =>
      dispatch(valuationCommentAdd(comment, false)) // FUTURE: Allow the user to set action required flag?
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ValuationComments);
