// @flow
import React from 'react';
import { ValidatedEmailInput } from '../email-field';
import {
  getEmailAvailableValidator,
  emailValidator,
  isRequiredValidator,
  emailLengthValidator
} from '../../services/validator';
import { type UserContext } from '../../types';

export type Props = {
  loginUrl: string,
  authApiUrl: string,
  updateContextCallback: (userContext: UserContext) => void
};

/**
 * Email Field with email available validator tied in.
 * Verifies if the email address is valid and does not already exist in ehrmantraut
 * @param {*} param0
 */
export const SignupEmailField = ({ loginUrl, authApiUrl, updateContextCallback, ...props }: Props) => {
  const emailAvailable = getEmailAvailableValidator(loginUrl, authApiUrl, updateContextCallback);
  return (
    <ValidatedEmailInput
      validate={[
        isRequiredValidator,
        emailValidator,
        emailAvailable,
        emailLengthValidator
      ]}
      {...props}
    />
  );
};

export default SignupEmailField;
