// @flow
import React, { type Node } from 'react';
import SVGInline from 'react-svg-inline';

import { type SvgInlineProps } from './icons.types';

import alertIcon from '../assets/svg-inline/alert.svg';
import announcementIcon from '../assets/svg-inline/announcement.svg';
import appLauncherIcon from '../assets/svg-inline/app_launcher.svg';
import closeIcon from '../assets/svg-inline/close.svg';
import editProfileIcon from '../assets/svg-inline/edit_profile.svg';
import logoutIcon from '../assets/svg-inline/logout.svg';
import orgSettingsIcon from '../assets/svg-inline/org_settings.svg';
import searchIcon from '../assets/svg-inline/search.svg';
import supportIcon from '../assets/svg-inline/support.svg';
import switchOrgIcon from '../assets/svg-inline/switch_org.svg';
import userIcon from '../assets/svg-inline/user.svg';
import usageAndBillingIcon from '../assets/svg-inline/usage_billing.svg';

export const AlertIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={alertIcon} />
);
export const AnnouncementIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={announcementIcon} />
);
export const AppLauncherIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={appLauncherIcon} />
);
export const CloseIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={closeIcon} />
);
export const EditProfileIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={editProfileIcon} />
);
export const LogoutIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={logoutIcon} />
);
export const OrgSettingsIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={orgSettingsIcon} />
);
export const SearchIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={searchIcon} />
);
export const SupportIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={supportIcon} />
);
export const SwitchOrgIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={switchOrgIcon} />
);
export const UserIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={userIcon} />
);
export const UsageAndBillingIcon = (props: SvgInlineProps): Node => (
  <SVGInline {...props} svg={usageAndBillingIcon} />
);

export * from './icons.types';
