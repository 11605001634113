// @flow
import React from 'react';
import classNames from 'classnames';

import styles from './ProgressBar.css';

type ProgressBarProps = {
  percentage: number,
  className?: string
};

const ProgressBar = (props: ProgressBarProps) => {
  const { percentage, className } = props;

  const progressStyle = {
    width: `${percentage}%`
  };

  return (
    <div className={classNames(styles.progressBar, className)}>
      <div className={styles.progress} style={progressStyle} />
    </div>
  );
};

export default ProgressBar;
