// @flow
import React from 'react';
import {
  RTThemedTable,
  RTThemedTableHead,
  RTThemedTableRow,
  RTThemedTableCell,
  RTThemedCheckbox,
  RTThemedFontIcon
} from '@hc/component-lib/lib/rt-themed';

import type { routeGeneratorFn } from '../../../components/Pagination';
import type { Links } from '../../../types/link';
import type { OrderingParams } from '../../../types/list';
import type { OrderItem } from '../../types/order-item';

import Pagination from '../../../components/Pagination';
import SortableTableCell from '../../../components/SortableTableCell';
import ItemIssues from './ItemIssues';
import { PROPERTY_TYPES_TO_LABEL } from '../../../constants/property-types';
import AddressLink from './AddressLink';
import { COMMON_ORDERING_PARAMS } from './common-ordering-params';
import { getFullAddresFromOrderItem } from '../../helpers/order-item.helpers';

import styles from './item-list.css';

export type RemovedListProps = {
  orderItems: OrderItem[],
  links: Links,
  paginationRouteGeneratorFn: routeGeneratorFn,
  ordering: OrderingParams[],
  selectedOrderItems: string[],
  onSort: (OrderingParams[]) => void,
  onToggleOrderItem: (orderItemId: string) => void,
  onToggleAll: (orderItemIds: string[], toggleOn: boolean) => void
};

class RemovedList extends React.Component<RemovedListProps, void> {
  render() {
    const {
      orderItems,
      links,
      paginationRouteGeneratorFn,
      ordering,
      selectedOrderItems,
      onSort,
      onToggleOrderItem,
      onToggleAll
    } = this.props;

    const areAllSelected =
      selectedOrderItems.length === orderItems.length && orderItems.length > 0;

    return (
      <div>
        <RTThemedTable theme={styles} selectable={false}>
          <RTThemedTableHead className={styles.tableHead}>
            <RTThemedTableCell className={styles.selectableTableCell}>
              <RTThemedCheckbox
                theme={styles}
                onChange={() =>
                  onToggleAll(
                    orderItems.map((orderItem: OrderItem) => orderItem.id),
                    !areAllSelected
                  )
                }
                checked={areAllSelected}
              />
            </RTThemedTableCell>
            <SortableTableCell
              onSort={onSort}
              orderingField="customerItemId"
              customOnSortOrdering={COMMON_ORDERING_PARAMS.customItemId}
              ordering={ordering}
            >
              <span>ID</span>
            </SortableTableCell>
            <SortableTableCell
              onSort={onSort}
              orderingField="propertyType"
              customOnSortOrdering={COMMON_ORDERING_PARAMS.propertyType}
              ordering={ordering}
            >
              <span>Property Type</span>
            </SortableTableCell>
            <SortableTableCell
              onSort={onSort}
              orderingField="address"
              customOnSortOrdering={COMMON_ORDERING_PARAMS.address}
              ordering={ordering}
            >
              <span>Address</span>
            </SortableTableCell>
            <SortableTableCell
              onSort={onSort}
              orderingField="city"
              customOnSortOrdering={COMMON_ORDERING_PARAMS.city}
              ordering={ordering}
            >
              <span>City</span>
            </SortableTableCell>
            <SortableTableCell
              onSort={onSort}
              orderingField="state"
              customOnSortOrdering={COMMON_ORDERING_PARAMS.state}
              ordering={ordering}
            >
              <span>State</span>
            </SortableTableCell>
            <SortableTableCell
              onSort={onSort}
              orderingField="zipcode"
              customOnSortOrdering={COMMON_ORDERING_PARAMS.zipcode}
              ordering={ordering}
            >
              <span>Zipcode</span>
            </SortableTableCell>
            <SortableTableCell
              onSort={onSort}
              orderingField="validationIssueSummary"
              customOnSortOrdering={
                COMMON_ORDERING_PARAMS.validationIssueSummary
              }
              customOrderingIndex={1}
              ordering={ordering}
            >
              <span>Issue(s)</span>
            </SortableTableCell>
          </RTThemedTableHead>
          {orderItems.map((orderItem: OrderItem) => {
            return (
              <RTThemedTableRow key={orderItem.id} className={styles.tableRow}>
                <RTThemedTableCell className={styles.selectableTableCell}>
                  <RTThemedCheckbox
                    theme={styles}
                    checked={selectedOrderItems.indexOf(orderItem.id) !== -1}
                    onChange={() => onToggleOrderItem(orderItem.id)}
                  />
                </RTThemedTableCell>
                <RTThemedTableCell className={styles.idCell}>
                  <span>{orderItem.customerItemId}</span>
                </RTThemedTableCell>
                <RTThemedTableCell className={styles.propertyTypeCell}>
                  <span>
                    {orderItem.propertyType &&
                      PROPERTY_TYPES_TO_LABEL[orderItem.propertyType]}
                  </span>
                </RTThemedTableCell>
                <RTThemedTableCell>
                  <RTThemedFontIcon
                    className={styles.addressIcon}
                    value="place"
                  />
                  <AddressLink
                    className={styles.addressLink}
                    fullAddress={getFullAddresFromOrderItem(orderItem)}
                    label={`${orderItem.address} ${
                      orderItem.unit ? orderItem.unit : ''
                    }`}
                  />
                </RTThemedTableCell>
                <RTThemedTableCell>
                  <span>{orderItem.city}</span>
                </RTThemedTableCell>
                <RTThemedTableCell>
                  <span>{orderItem.state}</span>
                </RTThemedTableCell>
                <RTThemedTableCell>
                  <span>{orderItem.zipcode}</span>
                </RTThemedTableCell>
                <RTThemedTableCell>
                  <ItemIssues validationResults={orderItem.validationResults} />
                </RTThemedTableCell>
              </RTThemedTableRow>
            );
          })}
        </RTThemedTable>
        <Pagination
          routeGeneratorFn={paginationRouteGeneratorFn}
          links={links}
        />
      </div>
    );
  }
}

export default RemovedList;
