// @flow
import React from 'react';
import { themr } from 'react-css-themr';

import { UsageAndBillingIcon } from '../../../../icons';

import MenuItem, { type MenuItemThemeType } from '../MenuItem';

import { withTracker } from '../../Tracker';
import { NAVIGATION_BAR_TYPES, NAVIGATION_BAR_EVENTS } from '../../constants/events.constants';
import { NAV_BAR_HC_NAMES } from '../../hc.names';

export type AccountUsageItemProps = {
  theme: MenuItemThemeType,
  platformBaseUrl: string,
  isAdminUser: boolean
}

const MenuItemWithTracker = withTracker(MenuItem);

const AccountUsageItem = (props: AccountUsageItemProps) => {
  const { theme, platformBaseUrl, isAdminUser } = props;
  const usageItem = {
    icon: <UsageAndBillingIcon />,
    label: isAdminUser ? 'Usage & Billing' : 'Usage',
    url: isAdminUser ? `${platformBaseUrl}/org-admin/usage` : `${platformBaseUrl}/my-user/usage`,
    dataHcName: NAV_BAR_HC_NAMES.HC_PROFILE_USAGE_BILLING,
    type: NAVIGATION_BAR_TYPES.ACCOUNT_MENU_TYPE,
    event: isAdminUser ? NAVIGATION_BAR_EVENTS.USAGE_BILLING_CLICK : NAVIGATION_BAR_EVENTS.USAGE_CLICK
  };
  return (
    <React.Fragment>
      <MenuItemWithTracker theme={theme} {...usageItem} />
    </React.Fragment>
  );
};

export default themr('AccountUsageItemThemed')(AccountUsageItem);
