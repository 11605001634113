// @flow
import type { Action } from '../actions';
import type { ReviewValuationDetails } from 'src/partner/types/review-valuation';

import {
  REVIEW_VALUATION_RESET,
  REVIEW_VALUATION_LOAD_COMPLETE,
  REVIEW_VALUATION_LOAD_ERROR,
  REVIEW_VALUATION_ACCEPTED,
  REVIEW_VALUATION_ACCEPTED_COMPLETE,
  REVIEW_VALUATION_REJECTED,
  REVIEW_VALUATION_REJECTED_COMPLETE
} from '../actions/review-valuation.actions';

type ReviewValuationEmptyState = {
  status: 'empty'
};

export type ReviewValuationLoadedState = {
  status: 'loaded',
  details: ReviewValuationDetails,
  token: string,
  actionStatus: 'initial' | 'pending' | 'success' | 'failure'
};

export type ReviewValuationErrorState = {
  status: 'error',
  message: string
};

export type ReviewValuationState =
  | ReviewValuationEmptyState
  | ReviewValuationLoadedState
  | ReviewValuationErrorState;

const defaultState = {
  status: 'empty'
};

export function reviewValuationReducer(
  previousState: ReviewValuationState = defaultState,
  action: Action
): ReviewValuationState {
  switch (action.type) {
    case REVIEW_VALUATION_RESET: {
      return defaultState;
    }
    case REVIEW_VALUATION_LOAD_COMPLETE: {
      return {
        status: 'loaded',
        details: action.payload.details,
        token: action.payload.token,
        actionStatus: 'initial'
      };
    }
    case REVIEW_VALUATION_LOAD_ERROR: {
      return {
        status: 'error',
        message: action.payload.message
      };
    }
    case REVIEW_VALUATION_ACCEPTED:
    case REVIEW_VALUATION_REJECTED: {
      if (previousState.status === 'loaded') {
        const newState: ReviewValuationLoadedState = {
          ...previousState,
          actionStatus: 'pending'
        };
        return newState;
      } else {
        return previousState;
      }
    }
    case REVIEW_VALUATION_ACCEPTED_COMPLETE:
    case REVIEW_VALUATION_REJECTED_COMPLETE: {
      if (previousState.status === 'loaded') {
        const newState: ReviewValuationLoadedState = {
          ...previousState,
          actionStatus: action.payload.success ? 'success' : 'failure'
        };
        return newState;
      } else {
        return previousState;
      }
    }
    default: {
      return previousState;
    }
  }
}
