// @flow
import type { Action } from 'src/client/actions';

import {
  POST_MESSAGE,
  POST_MESSAGE_SUCCESS,
  POST_MESSAGE_ERROR
} from 'src/client/actions/order-item-messages.actions';

type PostMessageNotLoadingState = {
  loading: false,
  status: ''
};
type PostMessageLoadingState = {
  loading: true,
  status: ''
};
type PostMessageErrorState = {
  loading: false,
  error: string,
  status: 'error'
};
export type OrderItemPostMessageState =
  | PostMessageNotLoadingState
  | PostMessageLoadingState
  | PostMessageErrorState;

const defaultState = {
  loading: false,
  status: ''
};

export function orderItemPostMessageReducer(
  state: OrderItemPostMessageState = defaultState,
  action: Action
): OrderItemPostMessageState {
  switch (action.type) {
    case POST_MESSAGE: {
      return {
        loading: true,
        status: ''
      };
    }
    case POST_MESSAGE_SUCCESS: {
      return {
        loading: false,
        status: ''
      };
    }
    case POST_MESSAGE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        loading: false,
        status: 'error',
        error: errorMessage
      };
    }
    default: {
      return state;
    }
  }
}
