// @flow
import type { UserContext } from '../auth-lib/types/user.types';
import type { Action } from '../actions';
import type { UserDetails } from 'src/types/user';
import { USER_LOAD_COMPLETE, CLEAR_USER } from '../actions/user.actions';

type UserDetailsNotLoadedState = {
  status: 'not_loaded'
};

type UserDetailsLoadedState = {
  status: 'loaded',
  userDetails: UserDetails,
  userContext: UserContext
};

export type UserDetailsState =
  | UserDetailsNotLoadedState
  | UserDetailsLoadedState;

const defaultState = {
  status: 'not_loaded'
};

export function userDetailsReducer(
  previousState: UserDetailsState = defaultState,
  action: Action
): UserDetailsState {
  switch (action.type) {
    case USER_LOAD_COMPLETE: {
      return {
        status: 'loaded',
        userDetails: action.payload.userDetails,
        userContext: action.payload.userContext
      };
    }
    case CLEAR_USER: {
      return defaultState;
    }
    default: {
      return previousState;
    }
  }
}
