// @flow
export const SHOW_CANCEL_ORDER_DIALOG = 'SHOW_CANCEL_ORDER_DIALOG';
export const DISMISS_CANCEL_ORDER_DIALOG = 'DISMISS_CANCEL_ORDER_DIALOG';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR';

export type ShowCancelOrderDialogAction = {
  type: 'SHOW_CANCEL_ORDER_DIALOG',
  payload: { id: string, reloadItems: ?boolean }
};
export type DismissCancelOrderDialogAction = {
  type: 'DISMISS_CANCEL_ORDER_DIALOG'
};
export type CancelOrderActionType = {
  type: 'CANCEL_ORDER',
  payload: { id: string, reloadItems: ?boolean }
};
export type CancelOrderSuccessAction = {
  type: 'CANCEL_ORDER_SUCCESS',
  payload: { id: string }
};
export type CancelOrderErrorAction = {
  type: 'CANCEL_ORDER_ERROR',
  payload: { id: string, errorMessage: string }
};

export type CancelOrderAction =
  | ShowCancelOrderDialogAction
  | DismissCancelOrderDialogAction
  | CancelOrderActionType
  | CancelOrderSuccessAction
  | CancelOrderErrorAction;

export const showCancelOrderDialog = (
  orderId: string,
  reloadItems: ?boolean
): ShowCancelOrderDialogAction => ({
  type: SHOW_CANCEL_ORDER_DIALOG,
  payload: { id: orderId, reloadItems }
});
export const dismissCancelOrderDialog = (): DismissCancelOrderDialogAction => ({
  type: DISMISS_CANCEL_ORDER_DIALOG
});
export const cancelOrder = (
  orderId: string,
  reloadItems: ?boolean
): CancelOrderActionType => ({
  type: CANCEL_ORDER,
  payload: { id: orderId, reloadItems }
});
export const cancelOrderSuccess = (
  orderId: string
): CancelOrderSuccessAction => ({
  type: CANCEL_ORDER_SUCCESS,
  payload: { id: orderId }
});
export const cancelOrderError = (
  orderId: string,
  errorMessage: string
): CancelOrderErrorAction => ({
  type: CANCEL_ORDER_ERROR,
  payload: { id: orderId, errorMessage }
});
