// @flow
import React from 'react';
import { connect } from 'react-redux';

import type { Dispatch } from 'redux';

import { HcHeader } from '../../components/HcHeader';
import { getFullAddress } from '../../helpers/address-helpers';

import { valuationRevisionResponse as valuationRevisionResponseAction } from '../actions/valuation-revision.actions';
import ValuationRevisionResponse from '../components/ValuationRevisionResponse';
import { type ValuationRevisionState } from '../reducers/valuation-revision.reducer';
import { type State } from '../../reducers';
import { CheckmarkIcon } from '@hc/component-lib';

import commonStyles from '../../containers/common.css';

export type ValuationRevisionProps = {
  valuationRevisionState: ValuationRevisionState,
  onValuationRevisionResponse: (
    response: 'accept' | 'reject',
    comment: ?string
  ) => void
};

const Content = ({ children }) => (
  <div className={commonStyles.containerContentWithHcHeader}>
    <div className={commonStyles.containerContentWithHcHeaderSpacer}>
      {children}
    </div>
  </div>
);

class ValuationRevision extends React.Component<ValuationRevisionProps, void> {
  render() {
    const fullAddress =
      this.props.valuationRevisionState.status !== 'INIT' &&
      this.props.valuationRevisionState.status !== 'ERROR'
        ? getFullAddress({
            address: this.props.valuationRevisionState.data.valuation.address,
            unit: this.props.valuationRevisionState.data.valuation.unit,
            city: this.props.valuationRevisionState.data.valuation.city,
            state: this.props.valuationRevisionState.data.valuation.state,
            zipcode: this.props.valuationRevisionState.data.valuation.zipcode
          })
        : '';
    const message =
      this.props.valuationRevisionState.status !== 'INIT' &&
      this.props.valuationRevisionState.status !== 'ERROR'
        ? this.props.valuationRevisionState.data.valuation.message
        : '';
    return (
      <div>
        <HcHeader showAppLinks={false} userDetails={null} userContext={null} />
        <Content>
          <ValuationRevisionResponse
            fullAddress={fullAddress}
            message={message}
            disableActions={
              this.props.valuationRevisionState.status === 'SUBMITTING' ||
              this.props.valuationRevisionState.status === 'SUBMITTED'
            }
            renderSubmitStatusMessage={() => {
              if (this.props.valuationRevisionState.status === 'SUBMITTING') {
                return <p>Submitting...</p>;
              } else if (
                this.props.valuationRevisionState.status === 'SUBMITTED'
              ) {
                return (
                  <React.Fragment>
                    <CheckmarkIcon />
                    <p>Your response has been submitted</p>
                  </React.Fragment>
                );
              }
            }}
            error={
              (this.props.valuationRevisionState.status === 'ERROR' &&
                this.props.valuationRevisionState.error) ||
              (this.props.valuationRevisionState.status === 'SUBMIT_ERROR' &&
                this.props.valuationRevisionState.submitError)
            }
            onValuationRevisionResponse={this.props.onValuationRevisionResponse}
          />
        </Content>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    valuationRevisionState: state.partner.valuationRevision
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onValuationRevisionResponse: (
      response: 'accept' | 'reject',
      comment: ?string
    ) => {
      dispatch(valuationRevisionResponseAction(response, comment));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ValuationRevision);
