// @flow
import React from 'react';
import {
  RTThemedDialog,
  RTThemedIconButton,
  RTThemedButton
} from '@hc/component-lib/lib/rt-themed';
import { FlatButton } from '@hc/component-lib';

import LoadingButton from 'src/components/loading-button/LoadingButton';

import styles from './CancelOrderOrItemDialog.css';

const ORDER_DISPLAY_SINGULAR = 'order';
const ORDER_DISPLAY_PLURAL = 'orders';
const ORDER_ITEM_DISPLAY_SINGULAR = 'address';
const ORDER_ITEM_DISPLAY_PLURAL = 'addresses';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

type CommonProps = {
  orderId: ?string,
  show: boolean,
  loading: boolean,
  cancelled: boolean,
  error: ?string,
  onCancel: (string, ?string) => void,
  onDismiss: () => void
};
type CancelOrderDialogProps = {
  type: 'order'
} & CommonProps;
type CancelOrderItemDialogProps = {
  type: 'orderItem',
  address: ?string,
  orderItemId: ?string
} & CommonProps;
type CancelOrderOrItemDialogProps =
  | CancelOrderDialogProps
  | CancelOrderItemDialogProps;

type CancelOrderOrItemDialogState = {
  address: ?string,
  orderId: ?string,
  orderItemId: ?string,
  loading: boolean,
  cancelled: boolean,
  error: ?string
};

const defaultState = {
  address: null,
  orderId: null,
  orderItemId: null,
  loading: false,
  cancelled: false,
  error: null
};

class CancelOrderOrItemDialogDialog extends React.Component<
  CancelOrderOrItemDialogProps,
  CancelOrderOrItemDialogState
> {
  state = defaultState;

  // bit of hackery to make the modal state stay showing even as the state is cleared on dismiss
  // (since modal animates closed, would otherwise see a flicker of default state)
  UNSAFE_componentWillReceiveProps(nextProps: CancelOrderOrItemDialogProps) {
    if (!(this.props.show === true && nextProps.show === false)) {
      this.setState({
        address: nextProps.type === 'orderItem' ? nextProps.address : null,
        orderId: nextProps.orderId,
        orderItemId:
          nextProps.type === 'orderItem' ? nextProps.orderItemId : null,
        loading: nextProps.loading,
        cancelled: nextProps.cancelled,
        error: nextProps.error
      });
    }
  }

  componentWillUnmount() {
    this.setState(defaultState);
  }

  handleCancel = () => {
    if (this.props.type === 'order' && this.props.orderId) {
      this.props.onCancel(this.props.orderId);
    } else if (
      this.props.type === 'orderItem' &&
      this.props.orderId &&
      this.props.orderItemId
    ) {
      this.props.onCancel(this.props.orderId, this.props.orderItemId);
    }
  };

  render() {
    const { type, show, onDismiss } = this.props;
    const { loading, cancelled, address } = this.state;
    const error = this.state.error;

    let DISPLAY_SINGULAR = '';
    let DISPLAY_PLURAL = '';
    let dialogTitle = '';
    if (type === 'order') {
      DISPLAY_SINGULAR = ORDER_DISPLAY_SINGULAR;
      DISPLAY_PLURAL = ORDER_DISPLAY_PLURAL;
      dialogTitle = error
        ? `Unable to cancel ${DISPLAY_SINGULAR}`
        : `Canceling ${capitalize(DISPLAY_SINGULAR)}`;
    } else if (type === 'orderItem') {
      DISPLAY_SINGULAR = ORDER_ITEM_DISPLAY_SINGULAR;
      DISPLAY_PLURAL = ORDER_ITEM_DISPLAY_PLURAL;
      dialogTitle = error
        ? `Unable to cancel ${DISPLAY_SINGULAR}: ${address || ''}`
        : `Canceling ${capitalize(DISPLAY_SINGULAR)}: ${address || ''}`;
    }

    return (
      <RTThemedDialog
        theme={cancelled ? { ...styles, title: styles.disabledTitle } : styles}
        title={dialogTitle}
        onEscKeyDown={onDismiss}
        active={show}
      >
        <RTThemedIconButton
          icon="close"
          className={styles.closeIcon}
          onClick={onDismiss}
        />

        {error && <div>Error: {error}</div>}

        {!error && (
          <div>
            <div className={cancelled ? styles.disabledText : null}>
              Are you sure you want to cancel this {DISPLAY_SINGULAR}? Any{' '}
              {DISPLAY_PLURAL} cancelled may incur a fee. We will reach out to
              our partner(s) to cancel the {DISPLAY_SINGULAR}.
            </div>
            {cancelled && (
              <div className={styles.cancelled}>
                The {DISPLAY_SINGULAR} is being cancelled. We will send an email
                when the process is complete.
              </div>
            )}
          </div>
        )}

        {!cancelled && !error && (
          <nav role="navigation" className={styles.navigation}>
            <FlatButton
              label={`No, keep this ${DISPLAY_SINGULAR}`}
              primary={false}
              disabled={loading}
              onClick={onDismiss}
            />

            <LoadingButton
              className={styles.loadingButton}
              theme={styles}
              loading={loading}
              disabled={loading}
              onClick={this.handleCancel}
            >
              <span>Cancel {capitalize(DISPLAY_SINGULAR)}</span>
            </LoadingButton>
          </nav>
        )}
        {error && (
          <nav role="navigation" className={styles.navigation}>
            <RTThemedButton label="Okay" onClick={onDismiss} />
          </nav>
        )}
      </RTThemedDialog>
    );
  }
}

export default CancelOrderOrItemDialogDialog;
