/**
 * NOTE: type identifiers are messed up in flow.
 * React docgen expects 3 types, flow only supports 2
 * Once that is resolved, enable flow
 */
import React, { cloneElement, Component } from 'react';
import { themr } from 'react-css-themr';
import { Button } from '@hc/component-lib';
import { ErrorMessage } from '../error-message';
import { PasswordField } from '../password-field';
import { EmailField } from '../email-field';
import { Link } from '../hyperlink';
import { SignUpLink } from '../sign-up-link';
import { Row } from '../row';

import loginTheme from './Login.scss';

type LoginFormTheme = {
  LoginForm: string,
  LoginTitle: string
}

type SignupUser = {
  username: string,
  password: string
};

type Props = {
  /** Email Component */
  emailField: ?any,
  /** Password Component */
  passwordField: ?any,
  /** Url to forgot password link */
  forgotPasswordUrl: ?string,
  /** Signup url string */
  signupUrl: ?string,
  /** Tells the LoginForm to go into loading state */
  isLoading: ?boolean,
  /** Title */
  title: ?string,
  /** Optional Enterprise link url when used with `openEnterpriseLinkForSignUp` */
  enterpriseLinkUrl: ?string,
  /** Leverage corp site contact us page for enterprise manual sign ups */
  openEnterpriseLinkForSignUp: ?boolean,
  /** Signup event handler */
  onLogin: SignupUser => mixed,
  /** optional callback when "don't have an account?" link is clicked */
  handleSignupClick?: {} => void,
  /** optional callback when the forgot password link is clicked */
  handleForgotPasswordClick?: {} => void,
  /** Error message to show in form */
  errorMessage: ?string,
  /** Focus on the email field when the page loads */
  focusFirstFieldOnMount: boolean,
  /** React Css Themr theme */
  theme: LoginFormTheme
};

type State = SignupUser;

type ForgotPasswordProps = {
  href: string,
  onClick?: () => void,
  theme: {
    Link: string
  }
}
function ForgotPasswordLink (props : ForgotPasswordProps) {
  return (<Link data-hc-name='forgot-password-link' {...props}>Forgot your password?</Link>);
}

export class LoginForm extends Component <Props, State, void> {
  state = {
    username: '',
    password: '',
    userNameValid: false,
    passwordValid: false
  };

  static defaultProps = {
    title: 'Log In',
    onLogin: () => {},
    actions: null,
    emailField: (<EmailField data-hc-name='username-field' label='Email' />),
    passwordField: (<PasswordField data-hc-name='password-field' label='Password' />),
    isLoading: false,
    focusFirstFieldOnMount: true
  };

  constructor (props) {
    super(props);
    this.state.username = props.emailField.props.value || '';
    this.state.password = props.passwordField.props.value || '';
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleLogin = (e) => {
    const {
      username,
      password,
      userNameValid,
      passwordValid
    } = this.state;
    if (e) {
      e.preventDefault();
    }
    if (userNameValid && passwordValid) {
      this.props.onLogin({ username, password });
    }
  }

  handleEnterpriseClick = () => {
    window.open(this.props.enterpriseLinkUrl || 'https://www.housecanary.com/contact-sales?target=sales');
  }

  handleValid = (name: string) => {
    this.setState({
      [name]: true
    });
  }

  handleInvalid = (name: string) => {
    this.setState({
      [name]: false
    });
  }

  handleUserNameValid = () => { this.handleValid('userNameValid'); };
  handleUserNameInvalid = () => { this.handleInvalid('userNameValid'); };
  handlePasswordValid = () => { this.handleValid('passwordValid'); };
  handlePasswordInvalid = () => { this.handleInvalid('passwordValid'); };

  render () {
    const {
      theme,
      emailField,
      passwordField,
      title,
      actions,
      isLoading,
      signupUrl,
      forgotPasswordUrl,
      handleSignupClick,
      handleForgotPasswordClick,
      errorMessage,
      focusFirstFieldOnMount,
      openEnterpriseLinkForSignUp
    } = this.props;
    const { password, username, passwordValid, userNameValid } = this.state;
    const isValid = passwordValid && userNameValid;
    return (
      <div className={theme.LoginForm}>
        {title && <h2 data-hc-name='login-header-text' className={theme.LoginTitle}>{title}</h2>}
        <form onSubmit={this.handleLogin}>
          {
            cloneElement(emailField,
              {
                onChange: this.handleChange,
                onValid: this.handleUserNameValid,
                onInvalid: this.handleUserNameInvalid,
                'data-hc-name': 'username-field',
                value: username,
                name: 'username',
                debounce: 750,
                autoFocus: focusFirstFieldOnMount
              }
            )
          }
          {
            cloneElement(passwordField,
              {
                onChange: this.handleChange,
                onValid: this.handlePasswordValid,
                onInvalid: this.handlePasswordInvalid,
                'data-hc-name': 'password-field',
                value: password,
                name: 'password',
                debounce: 250
              }
            )
          }
          <Row theme={theme}>
            {(forgotPasswordUrl || handleForgotPasswordClick) &&
              <ForgotPasswordLink
                theme={theme}
                href={forgotPasswordUrl}
                onClick={handleForgotPasswordClick}
              />
            }
          </Row>
          {actions
            ? cloneElement(actions, {
              'data-hc-name': 'login-actions',
              isLoading,
              isValid,
              onLogin: this.handleLogin
            })
            : <Row center>
              <Button
                dataHcName='login-button'
                type='submit'
                disabled={isLoading || !isValid}
                onClick={this.handleLogin}>
                Log In
              </Button>
            </Row>
          }
          {
            (signupUrl || handleSignupClick || openEnterpriseLinkForSignUp) &&
              <Row center>
                <SignUpLink
                  theme={theme}
                  data-hc-name='signup-link'
                  url={signupUrl}
                  handleClick={openEnterpriseLinkForSignUp ? this.handleEnterpriseClick : handleSignupClick} />
              </Row>
          }
        </form>
        {errorMessage && <ErrorMessage data-hc-name='login-fail-text' value={errorMessage} />}
      </div>
    );
  }
}

export default themr('LoginFormThemed', loginTheme)(LoginForm);
