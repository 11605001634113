// @flow
import * as React from 'react';
import debounce from 'lodash.debounce';
import fetch from 'isomorphic-fetch';
import { RTThemedFontIcon } from '@hc/component-lib/lib/rt-themed';
import reactStringReplace from 'react-string-replace';

import routeTo from '../../history/route-to';
import { searchOrderItemsPath } from '../../helpers/url-helpers';
import {
  getFullAddress,
  getOrderItemGroup
} from '../helpers/order-item.helpers';
import { snakeToCamel } from '../../sagas/helpers';
import { handleStandardExceptionsAction } from '../actions/auth.actions';
import SearchAutoComplete from '../../components/autocomplete/SearchAutoComplete';
import * as logger from '../../logger';
import { ORDER_ITEM_STATUSES } from '../constants/order-item-statuses';
import { dateTimeFormatter } from '../helpers/formatter-helpers';

import type {
  SearchResultOrderItem,
  OrderItemGroup
} from '../types/order-item';

import styles from './OrderItemsSearch.css';
import { authClient } from '../../sagas/auth-client-instance';
import { LoginRequiredError } from '../../api/order-manager-api-client';
import { connect } from 'react-redux';

const SETTINGS = window.SETTINGS;
const CANCELLED_ERROR_MESSAGE = 'cancelled';

const MIN_CHARS = 2;
const RESULTS_LIMIT = 5;
const DATE_FORMAT = 'MMM dd, yyyy';

const HighlightedText = (props: { text: string }): React.Node => (
  <span className={styles.highlighted}>{props.text}</span>
);

class OrderItemsSearchService {
  apiHost: string;

  token: string;

  currentRequestId: number;

  constructor(config) {
    this.apiHost = config.apiHost;
    this.token = config.token;
    this.currentRequestId = 0;
  }

  maybeRefreshToken(response, searchString) {
    if (response.status === 401) {
      return this.refreshToken(searchString);
    }
  }

  async refreshToken(searchString) {
    try {
      const userContext = await authClient.checkOrRefreshToken();
      const newToken = userContext.validity.token;
      if (newToken) {
        this.token = newToken;
        return this.makeRequest(searchString);
      }
    } catch (e) {
      throw new LoginRequiredError();
    }
  }

  makeRequest = (searchString: string) => {
    const searchUrl = `${this.apiHost}${searchOrderItemsPath(
      searchString,
      RESULTS_LIMIT
    )}`;
    const requestId = this.currentRequestId + 1;
    this.currentRequestId = requestId;
    return fetch(searchUrl, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${this.token}`
      }
    })
      .then((response) => {
        if (this.currentRequestId !== requestId) {
          throw Error(CANCELLED_ERROR_MESSAGE);
        }

        return (
          this.maybeRefreshToken(response, searchString) || response.json()
        );
      })
      .then((orderItemsResponse: ?(any[])): SearchResultOrderItem[] => {
        if (this.currentRequestId !== requestId) {
          throw Error(CANCELLED_ERROR_MESSAGE);
        }
        const orderItems: SearchResultOrderItem[] = (
          orderItemsResponse || []
        ).map((item) => snakeToCamel(item, Object.keys(item), {}, false));
        return orderItems;
      });
  };
}

type OrderItemsSearchProps = {
  token: string,
  handleStandardExceptions: (Error) => void
};

type OrderItemsSearchState = {
  results: SearchResultOrderItem[]
};

class OrderItemsSearch extends React.Component<
  OrderItemsSearchProps,
  OrderItemsSearchState
> {
  service: OrderItemsSearchService;

  query: string;

  state = {
    results: []
  };

  componentDidMount = () => {
    this.query = '';
    this.service = new OrderItemsSearchService({
      apiHost: SETTINGS.ORDER_MANAGER_API_URL,
      token: this.props.token
    });
  };

  highlightQuery = (text: string) => {
    return reactStringReplace(text, this.query, (match: string, i: number) => (
      <HighlightedText key={i} text={match} />
    ));
  };

  renderItem = (index: number) => {
    const orderItem: SearchResultOrderItem = this.state.results[index];
    if (!orderItem) {
      return null;
    }

    const fullAddress = getFullAddress({
      address: orderItem.address,
      unit: orderItem.unit,
      city: orderItem.city,
      state: orderItem.state,
      zipcode: orderItem.zipcode
    });
    const highlightedFullAddress = this.highlightQuery(fullAddress);
    const highlightedItemId = this.highlightQuery(orderItem.customerItemId);

    return (
      <div className={styles.resultContainer}>
        <div className={styles.infoContainer}>
          <div className={styles.address}>{highlightedFullAddress}</div>
          <div className={styles.orderInfo}>
            <span>
              {orderItem.orderName} ({orderItem.orderTypeName})
            </span>
          </div>
          <div className={styles.metaInfo}>
            <span>
              {orderItem.status === ORDER_ITEM_STATUSES.COMPLETE ? (
                <React.Fragment>
                  Completed{' '}
                  {dateTimeFormatter(orderItem.completionDate, DATE_FORMAT)}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Created: {dateTimeFormatter(orderItem.createdAt, DATE_FORMAT)}
                </React.Fragment>
              )}
            </span>
            <span>FILE ID: {highlightedItemId}</span>
          </div>
        </div>
        <RTThemedFontIcon
          className={styles.routeIcon}
          value="keyboard_arrow_right"
        />
      </div>
    );
  };

  updateResults = debounce(
    (query) => {
      this.service
        .makeRequest(query)
        .then((orderItems: SearchResultOrderItem[]) => {
          this.setState({
            results: orderItems.map((orderItem) => {
              return { label: orderItem.address, ...orderItem };
            })
          });
        })
        .catch((requestResult) => {
          if (
            requestResult &&
            requestResult.message !== CANCELLED_ERROR_MESSAGE
          ) {
            this.props.handleStandardExceptions(requestResult);
            logger.logWarning(
              new Error(`Cannot load suggestions: ${requestResult}`),
              'order item search'
            );
          }
        });
    },
    500,
    { leading: true, trailing: true }
  );

  clearResults = () => {
    this.setState({
      results: []
    });
  };

  handleSearch = (value: string) => {
    if (!value) {
      this.updateResults.cancel();
      this.query = '';
      this.setState({ results: [] });
    } else {
      this.query = value;
      if (value.length >= MIN_CHARS) {
        this.updateResults(value);
      }
    }
  };

  handleSelectResult = (index: number) => {
    const orderItem: SearchResultOrderItem = this.state.results[index];
    if (!orderItem) {
      logger.logException(
        new Error(
          `OrderItemsSearch: results out of sync with SearchAutoComplete`
        ),
        'OrderItemsSearch'
      );
    }
    const orderItemGroup: OrderItemGroup = getOrderItemGroup(
      orderItem.processItem
    );
    routeTo(
      `/client/order/${orderItem.orderId}/${orderItemGroup}?query=id:${orderItem.customerItemId}`,
      false,
      true
    );
  };

  render() {
    return (
      <div className={styles.searchContainer}>
        <SearchAutoComplete
          placeholder="Search for address or client file id"
          onSearch={this.handleSearch}
          onClearResults={this.clearResults}
          results={this.state.results}
          resultsAreLoading={false}
          renderResult={this.renderItem}
          onSelectResult={this.handleSelectResult}
          dataHcName="header-search-field"
        />
      </div>
    );
  }
}

export default connect(null, {
  handleStandardExceptions: handleStandardExceptionsAction
})(OrderItemsSearch);
