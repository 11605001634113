// @flow
import type { OrderItem } from 'src/client/types/order-item';
import type { Order } from 'src/client/types/order';

export const OPEN_ORDER_ITEM_DETAILS_SIDE_PANEL =
  'OPEN_ORDER_ITEM_DETAILS_SIDE_PANEL';
export const OPEN_ORDER_ITEM_MESSAGES_SIDE_PANEL =
  'OPEN_ORDER_ITEM_MESSAGES_SIDE_PANEL';
export const OPEN_ORDER_EXPORTS_SIDE_PANEL = 'OPEN_ORDER_EXPORTS_SIDE_PANEL';
export const CLOSE_SIDE_PANEL = 'CLOSE_SIDE_PANEL';

type OpenOrderItemDetailsSidePanelAction = {
  type: 'OPEN_ORDER_ITEM_DETAILS_SIDE_PANEL',
  payload: { orderItem: OrderItem }
};
type OpenOrderItemMessagesSidePanelAction = {
  type: 'OPEN_ORDER_ITEM_MESSAGES_SIDE_PANEL',
  payload: { orderItem: OrderItem }
};
export type OpenOrderExportsSidePanelAction = {
  type: 'OPEN_ORDER_EXPORTS_SIDE_PANEL',
  payload: { order: Order }
};
type CloseSidePanel = { type: 'CLOSE_SIDE_PANEL' };

export type SidePanelAction =
  | OpenOrderItemDetailsSidePanelAction
  | OpenOrderItemMessagesSidePanelAction
  | OpenOrderExportsSidePanelAction
  | CloseSidePanel;

export const openOrderItemDetailsSidePanel = (
  orderItem: OrderItem
): OpenOrderItemDetailsSidePanelAction => ({
  type: OPEN_ORDER_ITEM_DETAILS_SIDE_PANEL,
  payload: { orderItem }
});
export const openMessagesSidePanel = (
  orderItem: OrderItem
): OpenOrderItemMessagesSidePanelAction => ({
  type: OPEN_ORDER_ITEM_MESSAGES_SIDE_PANEL,
  payload: { orderItem }
});
export const openOrderExportsSidePanel = (
  order: Order
): OpenOrderExportsSidePanelAction => ({
  type: OPEN_ORDER_EXPORTS_SIDE_PANEL,
  payload: { order }
});
export const closeSidePanel = (): CloseSidePanel => ({
  type: CLOSE_SIDE_PANEL
});
