// @flow
import type { Action } from 'src/client/actions';
import type { Order } from 'src/client/types/order';

import {
  REPORT_SUMMARY_SELECT_FILTER,
  REPORT_SUMMARY_UNSELECT_FILTER,
  REPORT_SUMMARY_LOAD_SUCCESS,
  CLEAR_REPORT_SUMMARY
} from 'src/client/actions/report-summary.actions';

export type ReportSummaryState = {
  orders: Order[], // storing flat list for easier filtering
  availableFilters: string[],
  selectedFilters: string[]
};

const defaultState = {
  orders: [],
  availableFilters: [],
  selectedFilters: []
};

export function reportSummaryReducer(
  state: ReportSummaryState = defaultState,
  action: Action
): ReportSummaryState {
  switch (action.type) {
    case REPORT_SUMMARY_LOAD_SUCCESS: {
      const { orders } = action.payload;
      const availableFilters = orders.reduce((filterList, order) => {
        const { label, actualDeliveryDate } = order;

        if (!actualDeliveryDate) {
          return filterList;
        }

        if (label && filterList.indexOf(label) === -1) {
          filterList.push(label);
        }

        return filterList;
      }, []);
      return {
        ...state,
        orders,
        availableFilters,
        selectedFilters: []
      };
    }
    case REPORT_SUMMARY_SELECT_FILTER: {
      const { filter } = action.payload;
      const selectedFilters = state.selectedFilters;
      const updatedFilters = ((): string[] => {
        if (!filter) {
          // reset filters
          return [];
        } else if (selectedFilters.indexOf(filter) === -1) {
          // add filter
          return [...selectedFilters, filter];
        } else {
          // filter already exists
          return selectedFilters;
        }
      })();
      return {
        ...state,
        selectedFilters: updatedFilters
      };
    }
    case REPORT_SUMMARY_UNSELECT_FILTER: {
      const { filter } = action.payload;
      const selectedFilters = state.selectedFilters;
      const indexOf = selectedFilters.indexOf(filter);
      if (indexOf === -1) {
        return state;
      }
      return {
        ...state,
        selectedFilters: [
          ...selectedFilters.slice(0, indexOf),
          ...selectedFilters.slice(indexOf + 1)
        ]
      };
    }
    case CLEAR_REPORT_SUMMARY:
      return defaultState;
    default: {
      return state;
    }
  }
}
