// @flow
import React from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import { Avatar } from '@hc/component-lib/hclib';

import UserPlaceholderIcon from '@hc/component-lib/hclib/components/atoms/avatar/UserPlaceholder';

import { type UserContext } from '../../../../types';

import { withTracker } from '../../Tracker';
import { NAVIGATION_BAR_TYPES, NAVIGATION_BAR_EVENTS } from '../../constants/events.constants';

import defaultTheme from './AccountDetails.scss';
import { NAV_BAR_HC_NAMES } from '../../hc.names';

export type AccountDetailsThemeType = {
  AccountDetails: string,
  AccountUser: string,
  AccountUserNames: string,
  AccountOrganization: string,
  Initials: string
}

export type AccountDetailsProps = {
  className?: string,
  theme: AccountDetailsThemeType,
  userContext: UserContext,
  onProfileClick: () => void
}

const AvatarWithTracker = withTracker(Avatar);

const AccountDetails = (props: AccountDetailsProps) => {
  const {
    className,
    theme,
    userContext,
    onProfileClick
  } = props;

  const user = userContext.user || {};
  const currentOrganization = userContext.current_organization;

  const { first_name: firstName, last_name: lastName } = user;
  const initials = firstName && lastName &&
    `${firstName.toUpperCase().charAt(0)}${lastName.toUpperCase().charAt(0)}`;

  return (
    <div className={classNames(theme.AccountDetails, className)} >
      <AvatarWithTracker
        type={NAVIGATION_BAR_TYPES.ACCOUNT_MENU_TYPE}
        event={NAVIGATION_BAR_EVENTS.EDIT_PROFILE_AVATAR}
        theme={theme}
        data-hc-name={NAV_BAR_HC_NAMES.HC_PROFILE_AVATAR}
        onClick={onProfileClick}>
        {
          initials
            ? <span className={theme.Initials}>{initials}</span>
            : <UserPlaceholderIcon />
        }
      </AvatarWithTracker>
      <div className={theme.AccountUser}>
        <div className={theme.AccountUserNames} data-hc-name={NAV_BAR_HC_NAMES.HC_PROFILE_NAME}>{user.first_name} {user.last_name}</div>
      </div>
      <div className={theme.AccountOrganization}>
        <span data-hc-name={NAV_BAR_HC_NAMES.HC_PROFILE_ORG_NAME}>{currentOrganization && currentOrganization.name}</span>
      </div>
    </div>
  );
};

export const ThemedAccountDetails = themr('AccountMenuThemed', defaultTheme)(AccountDetails);

export default ThemedAccountDetails;
