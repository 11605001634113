// @flow
import {
  VALIDATE_ADDRESS_FIELD,
  VALIDATE_ADDRESS_FIELD_SUCCESS,
  VALIDATE_ADDRESS_FIELD_ERROR
} from 'src/client/actions/address-validation.actions';

import type { Action } from 'src/client/actions';

type AddressValidationNotLoadingState = {
  status: 'not_loading'
};
type AddressValidationLoadingState = {
  status: 'loading',
  field: string,
  formName: string
};
type AddressValidationErrorState = {
  status: 'error',
  field: string,
  formName: string,
  errorMessage: string
};
export type AddressValidationState =
  | AddressValidationNotLoadingState
  | AddressValidationLoadingState
  | AddressValidationErrorState;

const defaultState = {
  status: 'not_loading'
};

export function addressValidationReducer(
  state: AddressValidationState = defaultState,
  action: Action
): AddressValidationState {
  switch (action.type) {
    case VALIDATE_ADDRESS_FIELD: {
      return {
        status: 'loading',
        field: action.payload.field,
        formName: action.payload.formName
      };
    }
    case VALIDATE_ADDRESS_FIELD_SUCCESS: {
      return defaultState;
    }
    case VALIDATE_ADDRESS_FIELD_ERROR: {
      return {
        status: 'error',
        field: action.payload.field,
        formName: action.payload.formName,
        errorMessage: action.payload.errorMessage
      };
    }
    default: {
      return state;
    }
  }
}
