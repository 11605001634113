// @flow
import { type WorkflowWorker } from '../types/workflow';

export const WORKFLOW_WORKERS_REFRESH = 'WORKFLOW_WORKERS_REFRESH';
export const WORKFLOW_WORKERS_LOAD_SUCCESS = 'WORKFLOW_WORKERS_LOAD_SUCCESS';
export const WORKFLOW_WORKERS_LOAD_ERROR = 'WORKFLOW_WORKERS_LOAD_ERROR';

export const WORKFLOW_WORKER_TASKS_COUNT_SUCCESS =
  'WORKFLOW_WORKER_TASKS_COUNT_SUCCESS';

export type WorkfllowWorkersRefreshAction = {
  type: 'WORKFLOW_WORKERS_REFRESH'
};
export type WorkflowWorkersLoadSuccessAction = {
  type: 'WORKFLOW_WORKERS_LOAD_SUCCESS',
  payload: { workers: WorkflowWorker[] }
};
export type WorkflowWorkersLoadErrorAction = {
  type: 'WORKFLOW_WORKERS_LOAD_ERROR',
  payload: { error: string }
};

export type WorkflowWorkerTasksCountSuccessAction = {
  type: 'WORKFLOW_WORKER_TASKS_COUNT_SUCCESS',
  payload: { workersTaskCounts: { [workerId: string]: number } }
};

export type WorkflowWorkerExternalTasksAction = {
  type: 'WORKFLOW_WORKER_EXTERNAL_TASKS',
  payload: { workerId: string }
};

export type WorkflowWorkerAction =
  | WorkfllowWorkersRefreshAction
  | WorkflowWorkersLoadSuccessAction
  | WorkflowWorkersLoadErrorAction
  | WorkflowWorkerTasksCountSuccessAction;

export const refreshWorkflowWorker = (): WorkfllowWorkersRefreshAction => ({
  type: WORKFLOW_WORKERS_REFRESH
});
export const loadWorkflowWorkersSuccess = (
  workers: WorkflowWorker[]
): WorkflowWorkersLoadSuccessAction => ({
  type: WORKFLOW_WORKERS_LOAD_SUCCESS,
  payload: { workers }
});
export const loadWorkflowWorkersError = (
  error: string
): WorkflowWorkersLoadErrorAction => ({
  type: WORKFLOW_WORKERS_LOAD_ERROR,
  payload: { error }
});

export const loadWorkflowWorkerCountSuccess = (workersTaskCounts: {
  [workerId: string]: number
}): WorkflowWorkerTasksCountSuccessAction => ({
  type: WORKFLOW_WORKER_TASKS_COUNT_SUCCESS,
  payload: { workersTaskCounts }
});
