import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'react-css-themr';
import Raven from 'raven-js';
import store from './store';
import theme from './theme';
import { AppContainer } from 'react-hot-loader';
import App from './containers/App';

var SETTINGS = window.SETTINGS;
if (Object.prototype.hasOwnProperty.call(SETTINGS, 'SENTRY_TOKEN')) {
  Raven.config(SETTINGS.SENTRY_TOKEN, {
    release: window.SENTRY_RELEASE
  }).install();
}

const render = (Component) => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AppContainer>
          <Component />
        </AppContainer>
      </Provider>
    </ThemeProvider>,
    document.querySelector('#app')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./containers/App', () => render(App));
}
