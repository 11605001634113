// @flow
import { takeEvery, select, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { RevisionRequestActionType } from 'src/client/actions/revision-request.actions';
import {
  REVISION_REQUEST,
  revisionRequestSuccess,
  revisionRequestErrors
} from 'src/client/actions/revision-request.actions';
import { Client } from 'src/api/order-manager-api-client';
import { handleStandardExceptions } from 'src/sagas/helpers';
import userTokenSelector from 'src/selectors/user-token.selector';
import type { OrderItem } from 'src/client/types/order-item';
import { loadCurrentOrderItems } from './order-item.saga';

export function* handleRevisionRequest(
  action: RevisionRequestActionType
): Saga<void> {
  const orderId = action.payload.orderId;
  const orderItem: OrderItem = action.payload.orderItem;
  const explanation = action.payload.explanation;
  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    yield call(
      [client, client.submitRevisionRequest],
      orderId,
      orderItem.id,
      explanation
    );
    yield put(revisionRequestSuccess(orderId, orderItem));
    yield call(loadCurrentOrderItems);
  } catch (e) {
    yield call(handleStandardExceptions, e);
    const errorObj: { [message: string]: string[] } = JSON.parse(e.message);
    yield put(revisionRequestErrors(orderId, orderItem, errorObj.message));
  }
}

export function* registerRevisionRequestSaga(): Saga<void> {
  yield takeEvery(REVISION_REQUEST, handleRevisionRequest);
}
