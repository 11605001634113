// @flow

import type { Saga } from 'redux-saga';

import { fork } from 'redux-saga/effects';
import { router } from 'redux-saga-router';
import { history } from '../history/history';
import { ROUTE_ACTIONS } from '../route.actions';

export function* routerSaga(): Saga<void> {
  yield fork(router, history, ROUTE_ACTIONS);
}

if (module.hot) {
  (module.hot: any).accept('../routes', () => {});
}
