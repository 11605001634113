// @flow
export type AddressObj = {
  parsedValid: boolean,
  addressUnparsed: string,
  address: string,
  unit: string,
  city: string,
  state: string,
  zipcode: string
};

export const validateAddress = (address: ?AddressObj) => {
  if (address == null) {
    return 'Please enter an address';
  }

  if (
    Object.prototype.hasOwnProperty.call(address, 'parsedValid') &&
    !address.parsedValid
  ) {
    return 'Invalid address';
  }
};

export const formatAddress = (address: ?AddressObj): string => {
  if (
    !address ||
    !Object.prototype.hasOwnProperty.call(address, 'addressUnparsed')
  ) {
    return '';
  }

  if (address.parsedValid) {
    const addressAndUnit =
      address.address + (address.unit ? ` ${address.unit}` : '');
    let cityAndState = '';
    if (address.city && address.state) {
      cityAndState = `, ${address.city}, ${address.state}`;
    } else if (address.city) {
      cityAndState = `, ${address.city}`;
    } else if (address.state) {
      cityAndState = `, ${address.state}`;
    }
    const zipcode = address.zipcode ? ` ${address.zipcode}` : '';
    return `${addressAndUnit}${cityAndState}${zipcode}`;
  } else {
    return address.addressUnparsed;
  }
};
