// @flow
import React, { type Node } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import defaultTheme from './MenuSection.scss';

export type MenuSectionThemeType = {
  MenuSection: string
}

export type MenuSectionProps = {
  className?: string,
  theme: MenuSectionThemeType,
  children: Node
}

const MenuSection = (props: MenuSectionProps) => {
  const {
    className,
    theme,
    children
  } = props;
  return (
    <div className={classNames(theme.MenuSection, className)}>
      {children}
    </div>
  );
};

export default themr('MenuSection', defaultTheme)(MenuSection);
