// @flow
import { takeEvery, select, call, put, all } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { ExtendOrderActionType } from 'src/client/actions/extend-order.actions';
import {
  EXTEND_ORDER,
  extendOrderSuccess
} from 'src/client/actions/extend-order.actions';
import { Client } from 'src/api/order-manager-api-client';
import userTokenSelector from 'src/selectors/user-token.selector';
import {
  loadCurrentOrderItems,
  requestOrderItemProcessCounts
} from './order-item.saga';
import {
  handleStandardExceptions,
  snakeToCamel,
  camelToSnake
} from '../../sagas/helpers';
import type {
  AddOrExtendItem,
  AddOrExtendFileFields,
  AddOrExtendJSONFields
} from '../types/add-order';
import { showToast } from 'src/actions/view.actions';
import type { SupportingDocument } from '../types/supporting-documents';

function* handleExtendOrder(action: ExtendOrderActionType): Saga<void> {
  try {
    const { values, id } = action.payload;
    const { itemsSource, items, orderFile } = values;
    if (itemsSource === 'csv' && orderFile) {
      const extendWithFile: AddOrExtendFileFields = {
        itemsSource: 'csv',
        orderFile
      };
      yield call(extendOrderCsv, extendWithFile, id);
    } else if (itemsSource === 'entry' && items) {
      const extendWithJSON: AddOrExtendJSONFields = {
        items: items.map((item) => {
          const { address: addressParts, ...restItemValues } = item;
          const addOrderItem: AddOrExtendItem = {
            ...restItemValues,
            address: addressParts.address,
            unit: addressParts.unit,
            city: addressParts.city,
            state: addressParts.state,
            zipcode: addressParts.zipcode,
            addressUnparsed: addressParts.addressUnparsed,
            // backend only cares about document ids
            supportingDocuments: item.supportingDocuments
              ? item.supportingDocuments.map(
                  (supportingDocument: SupportingDocument) =>
                    supportingDocument.id
                )
              : undefined
          };
          return addOrderItem;
        })
      };
      yield call(extendOrderJson, extendWithJSON, id);
    } else {
      throw Error(
        'handleExtendOrder saga: values did not have orderFile or items'
      );
    }

    yield put(extendOrderSuccess());
    yield all([
      call(loadCurrentOrderItems),
      call(requestOrderItemProcessCounts, id)
    ]);
    yield put(showToast('We are processing your request.'));
  } catch (e) {
    if (e.name === 'ForbiddenError') {
      yield put(
        showToast(
          'You do not have permission to modify this order. Please contact your administrator.'
        )
      );
      return;
    } else if (e.name === 'ValidationError') {
      const validationErrors = snakeToCamel(
        e.data,
        Object.keys(e.data),
        {},
        false
      );
      let msg = 'Cannot create order';
      if (validationErrors.orderFile) {
        if (validationErrors.orderFile.constructor === Array) {
          validationErrors.orderFile.forEach((item) => {
            msg += `; ${item}`;
          });
        } else {
          msg += `; ${validationErrors.orderFile}`;
        }
      }
      yield put(showToast(msg));
      return;
    }
    yield call(handleStandardExceptions, e);
    yield put(showToast(e.message));
  }
}

export function* extendOrderCsv(
  extendWithFile: AddOrExtendFileFields,
  id: string
): Saga<void> {
  const extendWithFileSnake = camelToSnake(
    extendWithFile,
    Object.keys(extendWithFile)
  );
  const formData: window.FormData = Object.keys(extendWithFileSnake).reduce(
    (formData: window.FormData, k) => {
      formData.append(k, extendWithFileSnake[k]);
      return formData;
    },
    new window.FormData()
  );

  const userToken = yield select(userTokenSelector);
  const client = new Client(userToken);
  yield call([client, client.extendOrderCsv], formData, id);
}

export function* extendOrderJson(
  extendWithJSON: AddOrExtendJSONFields,
  id: string
): Saga<void> {
  const extendWithJSONSnake = {
    items: extendWithJSON.items.map((item) =>
      camelToSnake(item, Object.keys(item))
    )
  };
  const userToken = yield select(userTokenSelector);
  const client = new Client(userToken);
  yield call([client, client.extendOrderJson], extendWithJSONSnake, id);
}

export function* registerExtendOrderSaga(): Saga<void> {
  yield takeEvery(EXTEND_ORDER, handleExtendOrder);
}
