// @flow
import type { Message } from 'src/client/types/message';

export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'FETCH_MESSAGES_ERROR';
export const POST_MESSAGE = 'POST_MESSAGE';
export const POST_MESSAGE_SUCCESS = 'POST_MESSAGE_SUCCESS';
export const POST_MESSAGE_ERROR = 'POST_MESSAGE_ERROR';

type MessageOrdering = 'asc' | 'desc';

export type FetchMessagesAction = {
  type: 'FETCH_MESSAGES',
  payload: {
    orderId: string,
    orderItemId: string,
    page?: number,
    pageSize?: number,
    ordering?: MessageOrdering
  }
};

export type FetchMessagesSuccessAction = {
  type: 'FETCH_MESSAGES_SUCCESS',
  payload: {
    messages: Message[]
  }
};

export type FetchMessagesErrorAction = {
  type: 'FETCH_MESSAGES_ERROR',
  payload: {
    errorMessage: string
  }
};

export type PostMessageAction = {
  type: 'POST_MESSAGE',
  payload: {
    orderId: string,
    orderItemId: string,
    comment: string
  }
};

export type PostMessageSuccessAction = {
  type: 'POST_MESSAGE_SUCCESS',
  payload: {
    message: Message
  }
};

export type PostMessageErrorAction = {
  type: 'POST_MESSAGE_ERROR',
  payload: {
    errorMessage: string
  }
};

export type MessageAction =
  | FetchMessagesAction
  | FetchMessagesSuccessAction
  | FetchMessagesErrorAction
  | PostMessageAction
  | PostMessageSuccessAction
  | PostMessageErrorAction;

export const fetchMessages = (
  orderId: string,
  orderItemId: string,
  page?: number,
  pageSize?: number,
  ordering?: MessageOrdering
): FetchMessagesAction => ({
  type: FETCH_MESSAGES,
  payload: { orderId, orderItemId, page, pageSize, ordering }
});
export const fetchMessagesSuccess = (
  messages: Message[]
): FetchMessagesSuccessAction => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages }
});
export const fetchMessageError = (
  errorMessage: string
): FetchMessagesErrorAction => ({
  type: FETCH_MESSAGES_ERROR,
  payload: { errorMessage }
});
export const postMessage = (
  orderId: string,
  orderItemId: string,
  comment: string
): PostMessageAction => ({
  type: POST_MESSAGE,
  payload: { orderId, orderItemId, comment }
});
export const postMessageSuccess = (
  message: Message
): PostMessageSuccessAction => ({
  type: POST_MESSAGE_SUCCESS,
  payload: { message }
});
export const postMessageError = (
  errorMessage: string
): PostMessageErrorAction => ({
  type: POST_MESSAGE_ERROR,
  payload: { errorMessage }
});
