// @flow
export const LIKE = 'like';
export const I_LIKE = 'ilike';
export const HAS = 'has';
export const ANY = 'any';
export const EQ = 'eq';

type Operation = 'eq' | 'neq' | 'like' | 'ilike' | 'has' | 'any' | 'is_not_null' | 'is_null'

type Filter = {
  op: Operation,
  type?: 'string' | 'int' | null,
  val: any
}

export const DEFAULT_QUERY_LIMIT = 10;
export const DEFAULT_QUERY_PAGE = 1;
export const USER_QUERY_ORDER = 'email';

export type Query = {
  limit: number,
  page: number,
  order: string,
  filter?: {[key: string]: Filter}
}

export const defaultQuery: Query = {
  limit: DEFAULT_QUERY_LIMIT,
  page: DEFAULT_QUERY_PAGE,
  order: USER_QUERY_ORDER
};

const translationTable = {
  limit: 'results_per_page',
  page: 'page',
  order (key, value, params = {}) {
    const orderBy: {
      field: string,
      direction: 'asc' | 'desc'
    } = value.lastIndexOf('-') === 0
      ? value.substr(1).split(',').map(val => ({
        field: val,
        direction: 'desc'
      }))
      : value.split(',').map(val => ({
        field: val,
        direction: 'asc'
      }));
    return {
      ...params,
      order_by: orderBy
    };
  },
  filter (key, filter, params = {}) {
    const filters = Object.keys(filter).reduce((list, key: string) => {
      const filterObj: Filter = filter[key];
      const { op = EQ, type, val } = filterObj;

      if (val === undefined) { return list; }

      const condition : {
        name: string,
        op: Operation,
        val?: string | number
      } = {
        name: key,
        op: op
      };
      let transformedVal = val;
      let transformedOp = op;

      if (transformedVal === null) {
        transformedOp = (op === 'neq')
          ? 'is_not_null'
          : 'is_null';
      } else {
        if (op === LIKE || op === I_LIKE) {
          if (transformedVal === '') {
            return list;
          }
          transformedVal = `%${transformedVal}`;
        }
        if (op === HAS || op === ANY) {
          if (transformedVal === '') {
            return list;
          }
        }
        switch (type || null) {
          case 'string':
            condition.val = `${transformedVal}`;
            break;
          case 'int':
            condition.val = parseInt(transformedVal, 10);
            break;
          default:
            condition.val = transformedVal;
        }
      }

      condition.op = transformedOp;
      return list.concat([condition]);
    }, []);
    return {
      ...params,
      filters: filters.length
        ? [{ or: filters }]
        : []
    };
  }
};

export const translateQuery = (query: Query) => {
  const queryKeys = Object.keys(query);
  const newQuery = queryKeys.reduce((queryTable, queryKey) => {
    const value = query[queryKey];
    if (translationTable.hasOwnProperty(queryKey)) {
      const translation : string | Function = translationTable[queryKey];
      if (typeof translation === 'string') {
        queryTable[translation] = value;
      } else if (isFunction(translation)) {
        queryTable.q = translation(queryKey, value, queryTable.q);
      }
    } else {
      queryTable[queryKey] = value;
    }
    return queryTable;
  }, {});

  if (newQuery.hasOwnProperty('q')) {
    newQuery.q = JSON.stringify(newQuery.q);
  }

  return newQuery;
};

function isFunction (functionToCheck) {
  var getType = {};
  return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
}
