// @flow
import type { ReviewValuationDetails } from 'src/partner/types/review-valuation';

export const REVIEW_VALUATION_RESET = 'REVIEW_VALUATION_RESET';
export const REVIEW_VALUATION_LOAD_COMPLETE = 'REVIEW_VALUATION_LOAD_COMPLETE';
export const REVIEW_VALUATION_LOAD_ERROR = 'REVIEW_VALUATION_LOAD_ERROR';
export const REVIEW_VALUATION_ACCEPTED = 'REVIEW_VALUATION_ACCEPTED';
export const REVIEW_VALUATION_ACCEPTED_COMPLETE =
  'REVIEW_VALUATION_ACCEPTED_COMPLETE';
export const REVIEW_VALUATION_REJECTED = 'REVIEW_VALUATION_REJECTED';
export const REVIEW_VALUATION_REJECTED_COMPLETE =
  'REVIEW_VALUATION_REJECTED_COMPLETE';

export type ReviewValuationResetAction = {
  type: 'REVIEW_VALUATION_RESET'
};

export type ReviewValuationLoadCompleteAction = {
  type: 'REVIEW_VALUATION_LOAD_COMPLETE',
  payload: {
    details: ReviewValuationDetails,
    token: string
  }
};

export type ReviewValuationLoadErrorAction = {
  type: 'REVIEW_VALUATION_LOAD_ERROR',
  payload: {
    message: string
  }
};

export type ReviewValuationAcceptedAction = {
  type: 'REVIEW_VALUATION_ACCEPTED'
};

export type ReviewValuationAcceptedCompleteAction = {
  type: 'REVIEW_VALUATION_ACCEPTED_COMPLETE',
  payload: {
    success: boolean
  }
};

export type ReviewValuationRejectedAction = {
  type: 'REVIEW_VALUATION_REJECTED',
  payload: {
    message: string
  }
};

export type ReviewValuationRejectedCompleteAction = {
  type: 'REVIEW_VALUATION_REJECTED_COMPLETE',
  payload: {
    success: boolean
  }
};

export type ReviewValuationAction =
  | ReviewValuationResetAction
  | ReviewValuationLoadCompleteAction
  | ReviewValuationLoadErrorAction
  | ReviewValuationAcceptedAction
  | ReviewValuationAcceptedCompleteAction
  | ReviewValuationRejectedAction
  | ReviewValuationRejectedCompleteAction;

export const resetReviewValuation = (): ReviewValuationAction => ({
  type: REVIEW_VALUATION_RESET
});
export const reviewValuationLoadComplete = (
  details: ReviewValuationDetails,
  token: string
): ReviewValuationAction => ({
  type: REVIEW_VALUATION_LOAD_COMPLETE,
  payload: { details, token }
});
export const reviewValuationLoadError = (
  message: string
): ReviewValuationAction => ({
  type: REVIEW_VALUATION_LOAD_ERROR,
  payload: { message }
});
export const reviewValuationAccept = (): ReviewValuationAction => ({
  type: REVIEW_VALUATION_ACCEPTED
});
export const reviewValuationAcceptComplete = (
  success: boolean
): ReviewValuationAction => ({
  type: REVIEW_VALUATION_ACCEPTED_COMPLETE,
  payload: { success }
});
export const reviewValuationReject = (
  message: string
): ReviewValuationAction => ({
  type: REVIEW_VALUATION_REJECTED,
  payload: { message }
});
export const reviewValuationRejectComplete = (
  success: boolean
): ReviewValuationAction => ({
  type: REVIEW_VALUATION_REJECTED_COMPLETE,
  payload: { success }
});
