// @flow
import React from 'react';
import {
  RTThemedTable,
  RTThemedTableHead,
  RTThemedTableRow,
  RTThemedTableCell,
  RTThemedIconMenu,
  RTThemedMenuItem,
  RTThemedIconButton,
  RTThemedFontIcon
} from '@hc/component-lib/lib/rt-themed';
import { CircleCheckIcon, CircleXIcon } from '@hc/component-lib';

import type { routeGeneratorFn } from '../../../components/Pagination';
import type { OrderItem, AvailableDownloadable } from '../../types/order-item';
import type { Links } from '../../../types/link';
import type { OrderingParams } from '../../../types/list';
import type {
  Order,
  OrderTypeDescriptor,
  ResultColumn
} from '../../types/order';

import Pagination from '../../../components/Pagination';
import ListHeader from '../../../components/ListHeader.jsx';
import SortableTableCell from '../../../components/SortableTableCell';
import { ORDER_ITEM_STATUSES } from '../../constants/order-item-statuses';
import { ORDER_STATUSES } from '../../constants/order-statuses';
import { PROPERTY_TYPES_TO_LABEL } from '../../../constants/property-types';
import {
  getFormatterForDataType,
  dateTimeFormatter
} from '../../helpers/formatter-helpers';
import { getOrderItemStatusLabel } from '../../helpers/status-helpers';
import { COMMON_ORDERING_PARAMS } from './common-ordering-params';
import { getFullAddresFromOrderItem } from '../../helpers/order-item.helpers';
import AddressLink from './AddressLink';
import Popover from '../../../components/Popover';

import styles from './InOrderList.css';
import listStyles from './item-list.css';

const HeaderInfoLink = () => {
  return <RTThemedFontIcon value="info" className={styles.headerInfoIcon} />;
};

const DueDateInfo = () => {
  return (
    <Popover
      theme={styles}
      PopoverLinkComponent={<HeaderInfoLink />}
      PopoverContentComponent={
        <div>
          <div className={styles.headerPopoverHeader}>Due Date</div>
          <div className={styles.headerPopoverContent}>
            Orders are due on the provided date by 5:00 PM PST.
          </div>
        </div>
      }
    />
  );
};

const CertifiedCellData = (props: { isCertified: ?boolean }) => {
  const { isCertified } = props;
  return typeof isCertified === 'boolean' ? (
    <div>
      {isCertified ? (
        <CircleCheckIcon width="26px" />
      ) : (
        <CircleXIcon width="26px" />
      )}
    </div>
  ) : (
    '—'
  );
};

const CertifiedInfo = () => {
  return (
    <Popover
      theme={styles}
      PopoverLinkComponent={<HeaderInfoLink />}
      PopoverContentComponent={
        <div>
          <div className={styles.headerPopoverHeader}>
            Certified Eligibility
          </div>
          <div className={styles.headerPopoverContent}>
            Some addresses may not qualify for Agile Evaluation Certified and
            will be automatically converted to Agile Evaluation + Exterior, and
            identified accordingly.
          </div>
        </div>
      }
    />
  );
};

export type InOrderListProps = {
  loading: boolean,
  orderItems: OrderItem[],
  links: Links,
  query: ?string,
  ordering: OrderingParams[],
  paginationRouteGeneratorFn: routeGeneratorFn,
  onShowOrderItemDetails: (OrderItem) => void,
  onShowOrderItemMessages: (OrderItem) => void,
  onDownloadOrderItem: (orderItemId: string, address: string) => void,
  onViewOrderItemReport: (orderItemId: string, pdfType: string) => void,
  onSearch: (string) => void,
  onSort: (OrderingParams[]) => void,
  selectedOrderItem: ?OrderItem,
  order: Order,
  onShowCancelOrderItemDialog: (orderItemId: string, address: string) => void,
  onShowRevisionRequestDialog: (orderItem: OrderItem) => void,
  selectedOrderTypeDescriptor: OrderTypeDescriptor,
  onRemoveItemFromOrder: (orderId: string, orderItemId: string) => void
};

const InOrderList = ({
  orderItems,
  links,
  loading,
  query,
  ordering,
  paginationRouteGeneratorFn,
  onShowOrderItemDetails,
  onShowOrderItemMessages,
  onDownloadOrderItem,
  onViewOrderItemReport,
  onSearch,
  onSort,
  selectedOrderItem,
  order,
  onShowCancelOrderItemDialog,
  onShowRevisionRequestDialog,
  selectedOrderTypeDescriptor,
  onRemoveItemFromOrder
}: InOrderListProps) => {
  const shouldShowDueDate =
    order.orderTypeDescriptor.needsInspection &&
    !order.orderTypeDescriptor.needsAppraisal;
  return (
    <div>
      <ListHeader
        title=""
        search={{
          query,
          onSearch
        }}
        loading={loading}
      />
      <RTThemedTable selectable={false} multiSelectable={false}>
        <RTThemedTableHead>
          <SortableTableCell
            onSort={onSort}
            orderingField="customerItemId"
            customOnSortOrdering={COMMON_ORDERING_PARAMS.customItemId}
            ordering={ordering}
          >
            <span>ID</span>
          </SortableTableCell>
          <SortableTableCell
            onSort={onSort}
            orderingField="propertyType"
            customOnSortOrdering={COMMON_ORDERING_PARAMS.propertyType}
            ordering={ordering}
          >
            <span>Property Type</span>
          </SortableTableCell>
          <SortableTableCell
            onSort={onSort}
            orderingField="address"
            customOnSortOrdering={COMMON_ORDERING_PARAMS.address}
            ordering={ordering}
          >
            <span>Address</span>
          </SortableTableCell>
          <SortableTableCell
            onSort={onSort}
            orderingField="createdAt"
            ordering={ordering}
          >
            <span>Created</span>
          </SortableTableCell>
          {selectedOrderTypeDescriptor.resultColumns.map(
            (resultColumn: ResultColumn) => {
              const { columnName } = resultColumn;
              return (
                <RTThemedTableCell key={resultColumn.header}>
                  {columnName === 'isCertified' ? (
                    <React.Fragment>
                      {resultColumn.header}
                      <CertifiedInfo />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>{resultColumn.header}</React.Fragment>
                  )}
                </RTThemedTableCell>
              );
            }
          )}
          {shouldShowDueDate && (
            <SortableTableCell
              onSort={onSort}
              orderingField="dueDate"
              ordering={ordering}
              className={styles.dueDateHeader}
            >
              <React.Fragment>
                <span className={styles.dueDateHeaderLabel}>Due</span>
                <DueDateInfo />
              </React.Fragment>
            </SortableTableCell>
          )}
          {selectedOrderTypeDescriptor.canMessage && (
            <RTThemedTableCell className={styles.messageCell}>
              Messages
            </RTThemedTableCell>
          )}
          <RTThemedTableCell>Status</RTThemedTableCell>
          <RTThemedTableCell>&nbsp;</RTThemedTableCell>
        </RTThemedTableHead>
        {orderItems.map((orderItem: OrderItem) => {
          const fullAddress = getFullAddresFromOrderItem(orderItem);
          const selectedClass =
            selectedOrderItem && selectedOrderItem.id === orderItem.id
              ? styles.selected
              : '';
          return (
            <RTThemedTableRow
              key={orderItem.id}
              onClick={() => onShowOrderItemDetails(orderItem)}
              title="See Details"
              className={`${styles.row} ${selectedClass} ${listStyles.tableRow}`}
            >
              <RTThemedTableCell data-hc-name="id-col">
                <span>{orderItem.customerItemId}</span>
              </RTThemedTableCell>
              <RTThemedTableCell data-hc-name="property-type-col">
                <span>
                  {orderItem.propertyType &&
                    PROPERTY_TYPES_TO_LABEL[orderItem.propertyType]}
                </span>
              </RTThemedTableCell>
              <RTThemedTableCell data-hc-name="address-col">
                <RTThemedFontIcon
                  className={listStyles.addressIcon}
                  value="place"
                />
                <AddressLink
                  className={listStyles.addressLink}
                  fullAddress={fullAddress}
                  label={fullAddress}
                />
              </RTThemedTableCell>
              <RTThemedTableCell data-hc-name="created-col">
                <span>{dateTimeFormatter(orderItem.createdAt)}</span>
              </RTThemedTableCell>
              {selectedOrderTypeDescriptor.resultColumns.map(
                (resultColumn: ResultColumn) => {
                  const { columnName, dataType } = resultColumn;
                  const formatter = getFormatterForDataType(dataType);
                  const columnData = orderItem[columnName];
                  return (
                    <RTThemedTableCell
                      data-hc-name={`${columnName}-col`}
                      key={columnName}
                    >
                      {columnName === 'isCertified' ? (
                        <CertifiedCellData isCertified={columnData} />
                      ) : (
                        <React.Fragment>
                          {formatter(columnData) || '—'}
                        </React.Fragment>
                      )}
                    </RTThemedTableCell>
                  );
                }
              )}
              {shouldShowDueDate && (
                <RTThemedTableCell data-hc-name="due-col">
                  <span>
                    {orderItem.dueDate
                      ? dateTimeFormatter(orderItem.dueDate)
                      : null}
                  </span>
                </RTThemedTableCell>
              )}
              {selectedOrderTypeDescriptor.canMessage && (
                <RTThemedTableCell
                  data-hc-name="message-col"
                  className={styles.messageCell}
                >
                  {orderItem.status !== ORDER_ITEM_STATUSES.PROCESSING &&
                  orderItem.status !== ORDER_ITEM_STATUSES.CANCELLED ? (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onShowOrderItemMessages(orderItem);
                      }}
                    >
                      <RTThemedIconButton
                        className={styles.messageCellButton}
                        icon="email"
                      />{' '}
                      {orderItem.incomingCommentsCount}
                    </span>
                  ) : null}
                </RTThemedTableCell>
              )}
              <RTThemedTableCell data-hc-name="status-col">
                <span>{getOrderItemStatusLabel(orderItem.status)}</span>
              </RTThemedTableCell>
              <RTThemedTableCell
                data-hc-name="action-col"
                onClick={(e) => e.stopPropagation()}
              >
                <RTThemedIconMenu
                  data-hc-name="action-col-dropdown"
                  icon="more_vert"
                >
                  <RTThemedMenuItem
                    data-hc-name="see-details-action"
                    value="see_details"
                    caption="See Details"
                    onClick={() => onShowOrderItemDetails(orderItem)}
                  />
                  {orderItem.availableDownloadables.map(
                    (availableDownloadable: AvailableDownloadable) => {
                      return (
                        <RTThemedMenuItem
                          data-hc-name={`download-${availableDownloadable.key}-action`}
                          key={availableDownloadable.key}
                          value={availableDownloadable.displayName}
                          caption={availableDownloadable.displayName}
                          onClick={() =>
                            onViewOrderItemReport(
                              orderItem.id,
                              availableDownloadable.key
                            )
                          }
                        />
                      );
                    }
                  )}
                  {(order.status === ORDER_STATUSES.COMPLETE ||
                    orderItem.status === ORDER_ITEM_STATUSES.COMPLETE) && (
                    <RTThemedMenuItem
                      data-hc-name="download-all-data-action"
                      value="Download All Data"
                      caption="Download All Data"
                      onClick={() =>
                        onDownloadOrderItem(orderItem.id, fullAddress)
                      }
                    />
                  )}
                  {order.status !== ORDER_STATUSES.CLIENT_REVIEW &&
                    orderItem.canCancel && (
                      <RTThemedMenuItem
                        data-hc-name="cancel-action"
                        value="cancel_address"
                        caption="Cancel Address"
                        onClick={() =>
                          onShowCancelOrderItemDialog(
                            orderItem.id,
                            orderItem.address
                          )
                        }
                      />
                    )}
                  {orderItem.canRequestRevision && (
                    <RTThemedMenuItem
                      data-hc-name="revision-request-action"
                      value="revision_request"
                      caption="Request Revision"
                      onClick={() => onShowRevisionRequestDialog(orderItem)}
                    />
                  )}
                  {order.status === ORDER_STATUSES.CLIENT_REVIEW && (
                    <RTThemedMenuItem
                      data-hc-name="remove-action"
                      value="remove_address"
                      caption="Remove Address"
                      onClick={() =>
                        onRemoveItemFromOrder(order.id, orderItem.id)
                      }
                    />
                  )}
                </RTThemedIconMenu>
              </RTThemedTableCell>
            </RTThemedTableRow>
          );
        })}
      </RTThemedTable>
      <Pagination routeGeneratorFn={paginationRouteGeneratorFn} links={links} />
    </div>
  );
};

export default InOrderList;
