// @flow
import type { OrderItem } from 'src/client/types/order-item';

export const SHOW_REVISION_REQUEST_DIALOG = 'SHOW_REVISION_REQUEST_DIALOG';
export const DISMISS_REVISION_REQUEST_DIALOG =
  'DISMISS_REVISION_REQUEST_DIALOG';
export const REVISION_REQUEST = 'REVISION_REQUEST';
export const REVISION_REQUEST_SUCCESS = 'REVISION_REQUEST_SUCCESS';
export const REVISION_REQUEST_ERRORS = 'REVISION_REQUEST_ERRORS';

export type ShowRevisionRequestDialogAction = {
  type: 'SHOW_REVISION_REQUEST_DIALOG',
  payload: { orderId: string, orderItem: OrderItem }
};
export type DismissRevisionRequestDialogAction = {
  type: 'DISMISS_REVISION_REQUEST_DIALOG'
};
export type RevisionRequestActionType = {
  type: 'REVISION_REQUEST',
  payload: { orderId: string, orderItem: OrderItem, explanation: string }
};
export type RevisionRequestSuccessAction = {
  type: 'REVISION_REQUEST_SUCCESS',
  payload: { orderId: string, orderItem: OrderItem }
};
export type RevisionRequestErrorsAction = {
  type: 'REVISION_REQUEST_ERRORS',
  payload: { orderId: string, orderItem: OrderItem, errors: string[] }
};

export type RevisionRequestAction =
  | ShowRevisionRequestDialogAction
  | DismissRevisionRequestDialogAction
  | RevisionRequestActionType
  | RevisionRequestSuccessAction
  | RevisionRequestErrorsAction;

export const showRevisionRequestDialog = (
  orderId: string,
  orderItem: OrderItem
): ShowRevisionRequestDialogAction => ({
  type: SHOW_REVISION_REQUEST_DIALOG,
  payload: { orderId, orderItem }
});
export const dismissRevisionRequestDialog =
  (): DismissRevisionRequestDialogAction => ({
    type: DISMISS_REVISION_REQUEST_DIALOG
  });
export const revisionRequest = (
  orderId: string,
  orderItem: OrderItem,
  explanation: string
): RevisionRequestActionType => ({
  type: REVISION_REQUEST,
  payload: { orderId, orderItem, explanation }
});
export const revisionRequestSuccess = (
  orderId: string,
  orderItem: OrderItem
): RevisionRequestSuccessAction => ({
  type: REVISION_REQUEST_SUCCESS,
  payload: { orderId, orderItem }
});
export const revisionRequestErrors = (
  orderId: string,
  orderItem: OrderItem,
  errors: string[]
): RevisionRequestErrorsAction => ({
  type: REVISION_REQUEST_ERRORS,
  payload: { orderId, orderItem, errors }
});
