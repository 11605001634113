// // @flow
import * as React from 'react';
import {
  RTThemedIconMenu,
  RTThemedMenuItem
} from '@hc/component-lib/lib/rt-themed';
import { Button, Dropdown } from '@hc/component-lib';
import type { RowType } from '@hc/component-lib/lib/components/organisms/data-table/types';
import { Dialog } from '@hc/component-lib/hclib/components/organisms/dialog';
import Drawer from '../../../components/Drawer';
import Link from 'src/components/Link';
import type { OrderingParams } from '../../../types/list';
import Search from '../../../components/Search';
import { DataTableWithOrdering } from '../../../components/DataTableWithOrdering';

import styles from './OrderList.css';

type OrderListProps = {
  orders: [],
  ordering: OrderingParams[],
  onSearch: (string) => void,
  onSort: (OrderingParams[]) => void
};

type OrderListState = {
  showExpandablePanel: boolean,
  showAssignDialog: boolean,
  assigneeState: string
};
export default class OrderListManageOrderItems extends React.Component<
  OrderListProps,
  OrderListState
> {
  constructor(props: OrderListProps) {
    super(props);
    this.state = {
      showExpandablePanel: false,
      showAssignDialog: false,
      assigneeState: ''
    };
  }

  handleSelectOrder = (row: RowType) => {
    this.setState({ assigneeState: row.data.status });
  };

  ActionCellContent = () => {
    return (
      <RTThemedIconMenu data-hc-name="action-col-dropdown" icon="more_vert">
        <RTThemedMenuItem
          caption={
            this.state.assigneeState === 'Not Assigned'
              ? 'Assign'
              : this.state.assigneeState === 'Assigned'
              ? 'Reassign'
              : 'Review'
          }
          onClick={() => this.setState({ showAssignDialog: true })}
        />
        <RTThemedMenuItem caption="Edit Address" />
        <RTThemedMenuItem
          caption="Attachments"
          onClick={() =>
            this.setState({
              showExpandablePanel: !this.state.showExpandablePanel
            })
          }
        />
      </RTThemedIconMenu>
    );
  };

  render() {
    const { orders, onSort, ordering, onSearch } = this.props;
    const { showExpandablePanel, showAssignDialog } = this.state;
    const columns = [
      {
        label: 'Order Name',
        attribute: 'order.name',
        align: 'left'
      },
      {
        label: 'Label',
        attribute: 'order.label',
        align: 'left'
        // CellContent: LabelCellContent
      },
      {
        label: 'Client File ID',
        attribute: 'file',
        align: 'left'
      },
      {
        label: 'Address',
        attribute: 'order_item.address',
        align: 'left'
      },
      {
        label: 'Created',
        attribute: 'createdAt',
        align: 'center'
      },
      {
        label: 'Due Date',
        attribute: 'dueDate',
        align: 'center'
      },
      {
        label: 'Client',
        attribute: 'order.client_name',
        align: 'left'
      },
      {
        label: 'Product',
        attribute: 'order.order_type',
        align: 'left'
      },
      {
        label: 'Status',
        attribute: 'status',
        align: 'center',
        cellStyleHeader: {
          width: '100px',
          textAlign: 'center'
        },
        sortable: false
      },
      {
        label: 'Asignee',
        attribute: 'assigned_valuer.full_name',
        align: 'left'
      },
      {
        label: 'Actions',
        attribute: 'actions',
        align: 'left',
        CellContent: this.ActionCellContent,
        sortable: false
      }
    ];

    const data = orders.map((order) => {
      return {
        data: order
      };
    });
    return (
      <div>
        New Order
        <div
          className={styles.searchAndFilterContainer}
          data-hc-name="order-search-container"
        >
          <Dropdown
            className={styles.filter}
            theme={styles}
            label={'All Clients'}
            dataHcName="order-search-dropdown"
          />
          <Dropdown
            className={styles.filter}
            theme={styles}
            label={'All Status'}
            dataHcName="order-search-dropdown"
          />
          <Dropdown
            className={styles.filter}
            theme={styles}
            label={'All Assignees'}
            dataHcName="order-search-dropdown"
          />
          <Search
            className={styles.filter}
            onSearch={onSearch}
            placeholder="Search Name, Client File ID, or Label"
            query={undefined}
            dataHcName="order-search-input"
          />
        </div>
        {showExpandablePanel && (
          <Drawer side="right" show={showExpandablePanel}>
            Attachments
            <Link label="Attachment_1.pdf" />
            <Link label="Attachment_1.pdf" />
            <Link label="Attachment_1.pdf" />
            <Link label="Attachment_1.pdf" />
            <Link label="Attachment_1.pdf" />
          </Drawer>
        )}
        {showAssignDialog && (
          <div>
            <Dialog
              active={showAssignDialog}
              title="Assign Order"
              onClose={() =>
                this.setState({ showAssignDialog: !showAssignDialog })
              }
              closeButton
              // actions={actions}
            >
              <span>Assignee</span>
              <Dropdown theme={styles} label={'[ Selector ]'} />
              <Button primary={true}>
                Assign and Transfer BPO Comps to Appraisal{' '}
              </Button>
              <Button primary={false}>Cancel</Button>
            </Dialog>
          </div>
        )}
        <DataTableWithOrdering
          columns={columns}
          ordering={ordering}
          data={data}
          loading={false}
          onSortColumn={onSort}
          onRowClick={this.handleSelectOrder}
          theme={styles}
        />
      </div>
    );
  }
}
