// @flow
import * as React from 'react';
import {
  RTThemedCard,
  RTThemedCardText,
  RTThemedCardTitle
} from '@hc/component-lib/lib/rt-themed';
import Message from 'src/components/message/Message.jsx';
import MessageComposer from 'src/components/message/MessageComposer.jsx';

import styles from './ValuationCommentsForm.css';

type CommentData = {
  id: string,
  comment: string,
  isIncoming: boolean,
  isActionRequired: boolean,
  createdAt: Date
};

type ValuationCommentFormProps = {
  address: string,
  unit: ?string,
  city: string,
  state: string,
  zipcode: string,
  comments: CommentData[],
  onAddComment: (message: string) => void
};

class ValuationCommentForm extends React.Component<
  ValuationCommentFormProps,
  void
> {
  render() {
    const { address, unit, city, state, zipcode, comments, onAddComment } =
      this.props;

    const body = (
      <RTThemedCardText>
        <div className={styles.content}>
          <div className={styles.messagesContainer}>
            <div className={styles.messages}>
              {comments.map((message) => (
                <Message key={message.id} message={message} />
              ))}
            </div>
          </div>
          <div className={styles.messageComposerContainer}>
            <MessageComposer
              onSendMessage={onAddComment}
              postMessageError={null}
            />
          </div>
        </div>
      </RTThemedCardText>
    );

    var addressLine = address;
    if (unit && unit.length) {
      addressLine += ' ' + unit;
    }
    addressLine += ', ' + city;
    addressLine += ', ' + state;
    addressLine += ' ' + zipcode;
    return (
      <div style={{ width: '100%' }}>
        <RTThemedCard
          raised
          style={{ width: '25%', margin: 'auto', minWidth: '600px' }}
        >
          <RTThemedCardTitle title="Order Comments" subtitle={addressLine} />
          {body}
        </RTThemedCard>
      </div>
    );
  }
}

export default ValuationCommentForm;
