// @flow
/* global TimeoutID */
import React from 'react';
import {
  dateFormatter,
  dateTimeFormatter
} from 'src/client/helpers/formatter-helpers';
import { RTThemedFontIcon } from '@hc/component-lib/lib/rt-themed';
import { FlatButton } from '@hc/component-lib/hclib/components/atoms/button';
import { Button, DownloadIcon } from '@hc/component-lib';

import { ORDER_STATUSES } from 'src/client/constants/order-statuses';
import Popover from '../../../components/Popover';

import type { Order } from 'src/client/types/order';
import type {
  HeaderStatusGroupDisplay,
  CertifiablePercentage
} from 'src/client/selectors/order-items.selectors';
import {
  ActionsMenu,
  ActionItem,
  ActionDivider
} from '../../../components/ActionsMenu';

import styles from './Header.css';

const OrderPausedLinkComponent = () => {
  return (
    <div className={styles.orderPausedExplainerPopoverLink}>
      Why did this happen?
    </div>
  );
};

type OrderItemHeaderProps = {
  order: Order,
  headerStatusGroupsDisplay: HeaderStatusGroupDisplay[],
  certifiablePercentage: CertifiablePercentage,
  maxPercentInvalidItems: number,
  cancelDisabled: boolean,
  startProcessingDisabled: boolean,
  onStartProcessingClick: (orderId: string) => void,
  onDownloadSummaryClick: (orderId: string, orderNam: string) => void,
  onDownloadAllReportsClick: (order: Order) => void,
  onShowCancelOrderDialogClick: (orderId: string, reloadItems: boolean) => void,
  onShowExtendOrderDialogClick: (order: Order) => void,
  canExtendOrder: boolean
};

type OrderItemHeaderState = {
  downloadOptionsOpen: boolean,
  pausedOverlayVisible: boolean
};

class OrderItemHeader extends React.Component<
  OrderItemHeaderProps,
  OrderItemHeaderState
> {
  constructor(props: OrderItemHeaderProps) {
    super(props);
    this.state = {
      downloadOptionsOpen: false,
      pausedOverlayVisible: props.order.status === ORDER_STATUSES.CLIENT_REVIEW
    };
  }

  overlayTimeoutId: ?TimeoutID;

  handleMenuShow = () => {
    this.setState({ downloadOptionsOpen: true });
  };

  handleMenuHide = () => {
    this.setState({ downloadOptionsOpen: false });
  };

  handleOverlayHide = () => {
    if (this.state.pausedOverlayVisible) {
      this.setState({
        pausedOverlayVisible: false
      });
    }
  };

  componentDidMount = () => {
    if (this.state.pausedOverlayVisible) {
      this.overlayTimeoutId = setTimeout(() => {
        this.handleOverlayHide();
      }, 4000);
    }
  };

  componentWillUnmount = () => {
    if (this.overlayTimeoutId) {
      clearTimeout(this.overlayTimeoutId);
    }
  };

  render() {
    const {
      order,
      headerStatusGroupsDisplay,
      maxPercentInvalidItems,
      cancelDisabled,
      startProcessingDisabled,
      onStartProcessingClick,
      onDownloadSummaryClick,
      onDownloadAllReportsClick,
      onShowCancelOrderDialogClick,
      onShowExtendOrderDialogClick,
      canExtendOrder,
      certifiablePercentage
    } = this.props;
    const menuEnabled = order.canCancel || order.status !== ORDER_STATUSES.NEW;
    const showCertifiedCount =
      order.status === ORDER_STATUSES.COMPLETE &&
      order.orderItemsCertifiedCount !== null;
    return (
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <h2>
            {order.name}{' '}
            <span className={styles.orderType}>
              ({order.orderTypeDescriptor.name})
            </span>
          </h2>
          <div className={styles.orderDetailsContainer}>
            {order.customerOrderId && (
              <div>Order ID: {order.customerOrderId}</div>
            )}
            <div data-hc-name="date-created-summary">
              Created: {dateTimeFormatter(order.createdAt)}
            </div>
            {order.status === ORDER_STATUSES.COMPLETE && (
              <div data-hc-name="date-completed-summary">
                Completed:{' '}
                {order.actualDeliveryDate
                  ? dateFormatter(order.actualDeliveryDate)
                  : '-'}
              </div>
            )}
            {order.status !== ORDER_STATUSES.COMPLETE &&
              headerStatusGroupsDisplay.map(
                (headerStatus: HeaderStatusGroupDisplay) => {
                  return (
                    <div
                      data-hc-name={`${headerStatus.key}-summary`}
                      key={headerStatus.label}
                    >
                      {headerStatus.label}: {headerStatus.percentage} %
                    </div>
                  );
                }
              )}
            {showCertifiedCount && (
              <div data-hc-name="certifiable-summary">
                Certifiable: {certifiablePercentage}% (
                {order.orderItemsCertifiedCount})
              </div>
            )}
          </div>
        </div>
        {order.status !== ORDER_STATUSES.CLIENT_REVIEW ? (
          <ActionsMenu
            label="Order Actions"
            disabled={!menuEnabled}
            theme={styles}
          >
            <ActionItem
              icon="add"
              label="Add More Addresses"
              disabled={
                order.cancelled ||
                order.status === ORDER_STATUSES.COMPLETE ||
                !canExtendOrder
              }
              onClick={() => {
                onShowExtendOrderDialogClick(order);
              }}
            />
            <ActionDivider />
            <ActionItem
              disabled={order.status === ORDER_STATUSES.NEW}
              icon={<DownloadIcon height="14px" width="14px" />}
              label="Download Spreadsheet"
              onClick={() => onDownloadSummaryClick(order.id, order.name)}
            />
            <ActionItem
              disabled={order.status === ORDER_STATUSES.NEW}
              icon={<DownloadIcon height="14px" width="14px" />}
              label="Download All Reports"
              onClick={() => onDownloadAllReportsClick(order)}
            />
            <ActionDivider />
            <ActionItem
              className={styles.cancelItem}
              disabled={!order.canCancel}
              icon="close"
              label="Cancel Order"
              onClick={() => onShowCancelOrderDialogClick(order.id, true)}
            />
          </ActionsMenu>
        ) : (
          <div>
            {this.state.pausedOverlayVisible && (
              <div
                onClick={this.handleOverlayHide}
                className={styles.overlay}
              />
            )}
            <div className={styles.orderPausedContainer}>
              <RTThemedFontIcon
                className={styles.paused}
                value="pause_circle_filled"
              />
              <div className={styles.orderPausedExplainer}>
                <div>Order Paused</div>
                <Popover
                  theme={styles}
                  PopoverLinkComponent={OrderPausedLinkComponent}
                  PopoverContentComponent={
                    <div className={styles.orderPausedExplainerPopoverContent}>
                      Unfortunately, over {maxPercentInvalidItems}% of the
                      addresses in this order had issues. To avoid unnecessary
                      charges we ask that you review the file for errors and
                      either start processing this order, or cancel and try
                      again.
                    </div>
                  }
                />
              </div>
              <FlatButton
                className={styles.cancelButton}
                label="Cancel Order"
                disabled={cancelDisabled}
                onClick={() => onShowCancelOrderDialogClick(order.id, true)}
              />
              <Button
                micro
                label="Start Processing"
                disabled={startProcessingDisabled}
                onClick={() => onStartProcessingClick(order.id)}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default OrderItemHeader;
