// @flow
import React from 'react';
import { themr } from 'react-css-themr';
import {
  RTThemedInput,
  RTThemedIconButton
} from '@hc/component-lib/lib/rt-themed';

import defaultTheme from './MessageComposer.css';

type MessageComposerTheme = {
  container: string,
  inputContainer: string,
  sendContainer: string,
  messageError: string
};

type MessageComposerProps = {
  onSendMessage: (comment: string) => void,
  postMessageError: ?string,
  theme: MessageComposerTheme,
  skipOnEnterKeyPress?: boolean,
  multiline?: boolean,
  onMessageChange?: (comment: string) => void
};

type MessageComposerState = {
  comment: string
};

class MessageComposer extends React.PureComponent<
  MessageComposerProps,
  MessageComposerState
> {
  constructor(props: MessageComposerProps) {
    super(props);
    this.state = { comment: '' };
  }

  static defaultProps = {
    skipOnEnterKeyPress: false,
    multiline: true,
    onMessageChange: () => {}
  };

  componentWillMount = () => {
    window.addEventListener('keydown', this.handleEnterKeyDown);
  };

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleEnterKeyDown);
  };

  handleEnterKeyDown = (e: window.Event) => {
    if (e.keyCode === 13) {
      if (!e.shiftKey && this.props.skipOnEnterKeyPress !== true) {
        e.stopPropagation();
        e.preventDefault();

        const { comment } = this.state;

        if (comment) {
          this.handleMessageSubmit(comment);
        } else {
          // do nothing
        }
      }
    }
  };

  handleMessageSubmit = (comment: string) => {
    this.props.onSendMessage(comment);
    this.setState({ comment: '' });
  };

  handleCommentChange = (value: string) => {
    this.setState({ comment: value });

    // defaultProps should handle this
    // this if statement is to silence flow error as onMessageChange is not required
    if (this.props.onMessageChange) {
      this.props.onMessageChange(value);
    }
  };

  render() {
    const { postMessageError, multiline, theme } = this.props;
    const { comment } = this.state;

    return (
      <div>
        <div className={theme.container}>
          <div className={theme.inputContainer}>
            <RTThemedInput
              theme={theme}
              type="text"
              multiline={multiline}
              hint="Type your message here"
              value={comment}
              onChange={this.handleCommentChange}
            />
          </div>

          <div className={theme.sendContainer}>
            <RTThemedIconButton
              icon="send"
              disabled={!comment}
              onClick={() => this.handleMessageSubmit(comment)}
            />
          </div>
        </div>
        {postMessageError != null && (
          <span className={theme.messageError}>
            Message failed to send: {postMessageError}
          </span>
        )}
      </div>
    );
  }
}

export default themr('ThemedMessageComposer', defaultTheme)(MessageComposer);
