// @flow
import {
  REPORT_SUMMARY_ITEMS_LOADING,
  REPORT_SUMMARY_ITEMS_LOAD_SUCCESS,
  REPORT_SUMMARY_ITEMS_LOAD_ERROR,
  CLEAR_REPORT_SUMMARY
} from 'src/client/actions/report-summary.actions';

import type { Action } from 'src/client/actions';
import type { OrderItem } from 'src/client/types/order-item';
import type { Links } from 'src/types/link';
import type { OrderingParams } from 'src/types/list';

type OrderItemsLoadingState = {
  status: 'loading',
  fetching: true
};
type OrderItemsLoadedState = {
  status: 'loaded',
  fetching: boolean,
  links: Links,
  pageItems: OrderItem[],
  page: ?number,
  query: ?string,
  ordering: OrderingParams[]
};
type OrderItemsErrorState = {
  status: 'error',
  fetching: false,
  errorMessage: string
};
export type ReportSummaryOrderItemsState = {
  orderItemsByOrderId: {
    [orderId: string]:
      | OrderItemsLoadingState
      | OrderItemsLoadedState
      | OrderItemsErrorState
  }
};

const defaultState = {
  orderItemsByOrderId: {}
};

export function reportSummaryOrderItemsReducer(
  state: ReportSummaryOrderItemsState = defaultState,
  action: Action
): ReportSummaryOrderItemsState {
  switch (action.type) {
    case REPORT_SUMMARY_ITEMS_LOADING: {
      const { orderId } = action.payload;
      const orderIdState = state.orderItemsByOrderId[orderId];
      return {
        orderItemsByOrderId: {
          ...state.orderItemsByOrderId,
          [orderId]:
            orderIdState !== undefined && orderIdState.status === 'loaded'
              ? {
                  status: 'loaded',
                  fetching: true,
                  links: orderIdState.links,
                  pageItems: orderIdState.pageItems,
                  page: orderIdState.page,
                  query: orderIdState.query,
                  ordering: orderIdState.ordering
                }
              : {
                  status: 'loading',
                  fetching: true
                }
        }
      };
    }
    case REPORT_SUMMARY_ITEMS_LOAD_SUCCESS: {
      const { orderId, orderItems, links, params } = action.payload;
      const { query, ordering, page } = params;
      return {
        orderItemsByOrderId: {
          ...state.orderItemsByOrderId,
          [orderId]: {
            status: 'loaded',
            fetching: false,
            links,
            pageItems: orderItems,
            page,
            query,
            ordering: ordering || []
          }
        }
      };
    }
    case REPORT_SUMMARY_ITEMS_LOAD_ERROR: {
      const { orderId, errorMessage } = action.payload;
      return {
        orderItemsByOrderId: {
          ...state.orderItemsByOrderId,
          [orderId]: {
            status: 'error',
            fetching: false,
            errorMessage
          }
        }
      };
    }
    case CLEAR_REPORT_SUMMARY: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
}
