// @flow
import type { Saga } from 'redux-saga';
import snakeCase from 'lodash.snakecase';
import camelCase from 'lodash.camelcase';

import routeTo from 'src/history/route-to';
import * as logger from 'src/logger';
import { call, put } from 'redux-saga/effects';
import { history } from 'src/history/history';
import { clearUser } from 'src/actions/user.actions';
import { showToast } from 'src/actions/view.actions';
import { ORDERS_ROUTE } from '../client/routes';

export function redirectToLogin(markCheckpoint: boolean = true): void {
  routeTo('/login', false, markCheckpoint);
}

export function* handleStandardExceptions(
  e: any,
  reRouteOnError: boolean = false
): Saga<void> {
  if (e.name === 'ForbiddenError') {
    yield put(
      showToast(
        'Access to the requested item was denied.  Please contact your administrator to request permission.'
      )
    );
    if (reRouteOnError) {
      yield call([history, history.replace], ORDERS_ROUTE);
    } else {
      // re-throw in case this is being called as part of a resolve function
      throw e;
    }
    logger.logWarning(e);
  } else if (e.name === 'NotFoundError') {
    yield put(showToast('Requested resource not found'));
    if (reRouteOnError) {
      yield call([history, history.replace], ORDERS_ROUTE);
    } else {
      // re-throw in case this is being called as part of a resolve function
      throw e;
    }
    logger.logWarning(e);
  } else if (e.name === 'LoginRequiredError') {
    console.log('login required error');
    yield put(clearUser());
    yield put(showToast('Session expired. Please login first.'));
    yield call(redirectToLogin);
  } else if (e.name === 'ReRouteError') {
    yield call([history, history.replace], e.message);
  } else {
    const errorMessage = e.message
      ? `An error occurred: ${e.message}`
      : 'An error occurred.';
    yield put(showToast(errorMessage));
    logger.logException(e, 'Error in client handleStandardExceptions');
  }
}

export function camelToSnake(
  source: { [key: string]: any },
  fields: string[],
  target: { [key: string]: any } = {},
  omitNull: boolean = true
) {
  for (const field of fields) {
    const value = source[field];
    if (omitNull && value == null) {
      continue;
    }
    target[snakeCase(field)] = value;
  }
  return target;
}

export function snakeToCamel(
  source: { [key: string]: any },
  fields: string[],
  target: { [key: string]: any } = {},
  omitNull: boolean = true
) {
  for (const field of fields) {
    const value = source[field];
    if (omitNull && value == null) {
      continue;
    }
    target[camelCase(field)] = value;
  }
  return target;
}

export function ReRouteError(route: ?string) {
  this.name = 'ReRouteError';
  this.message = route || '/';
  this.stack = new Error().stack;
}
ReRouteError.prototype = Object.create(Error.prototype);
ReRouteError.prototype.constructor = ReRouteError;
