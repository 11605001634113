// @flow
import React from 'react';
import { connect } from 'react-redux';

import type { Dispatch } from 'redux';
import type { ReviewValuationState } from '../reducers/review-valuation.reducer';

import {
  reviewValuationAccept,
  reviewValuationReject
} from '../actions/review-valuation.actions';
import reviewValuationStateSelector from 'src/partner/selectors/review-valuation.selector';
import ReviewValuationForm from '../components/ReviewValuationForm';
import ReviewValuationError from '../components/ReviewValuationError';
import { HcHeader } from '../../components/HcHeader';

import commonStyles from 'src/containers/common.css';

type ReviewValuationProps = {
  reviewValuationState: ReviewValuationState,
  onReviewAccept: () => void,
  onReviewReject: (message: string) => void
};

class ReviewValuation extends React.Component<ReviewValuationProps, void> {
  props: ReviewValuationProps;

  render() {
    const { reviewValuationState, onReviewAccept, onReviewReject } = this.props;

    return (
      <div>
        <HcHeader showAppLinks={false} userDetails={null} userContext={null} />
        <div className={commonStyles.containerContentWithHcHeader}>
          <div style={{ paddingTop: '75px' }} />
          {reviewValuationState.status === 'error' && (
            <ReviewValuationError message={reviewValuationState.message} />
          )}
          {reviewValuationState.status === 'empty' && <div>Loading...</div>}
          {reviewValuationState.status === 'loaded' && (
            <ReviewValuationForm
              key={reviewValuationState.details.orderId}
              reviewValuationState={reviewValuationState}
              onReviewAccept={onReviewAccept}
              onReviewReject={onReviewReject}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reviewValuationState: reviewValuationStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onReviewAccept: () => dispatch(reviewValuationAccept()),
    onReviewReject: (message: string) =>
      dispatch(reviewValuationReject(message))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewValuation);
