// @flow
import React from 'react';
import { connect } from 'react-redux';

import type { State } from 'src/reducers';
import type { Dispatch } from 'redux';
import type { LoginData } from 'src/types/user';
import type { LoginState } from 'src/reducers/login.reducer';

import { LoginForm } from '../auth-lib/components';
import { login } from 'src/actions/user.actions';
import { loginStateSelector } from 'src/selectors/user-state.selector';
import { HcHeader } from 'src/components/HcHeader';

import commonStyles from 'src/containers/common.css';
import styles from './Login.css';

type LoginProps = {
  onLogin: (loginData: LoginData) => void,
  loginState: LoginState
};

const Login = (props: LoginProps) => {
  const { onLogin, loginState } = props;

  return (
    <div>
      <HcHeader showAppLinks={false} userDetails={null} userContext={null} />
      <div className={commonStyles.containerContentWithHcHeader}>
        <LoginForm
          theme={styles}
          openEnterpriseLinkForSignUp
          forgotPasswordUrl="/forgot-password"
          onLogin={onLogin}
          isLoading={loginState.loading}
          errorMessage={loginState.errorMessage || null}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state: State) {
  return {
    loginState: loginStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onLogin: (loginData: LoginData) => dispatch(login(loginData))
  };
}

const LoginWrapped = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginWrapped;
