// @flow
import React, { Component } from 'react';
import debounce from 'lodash.debounce';

type CancelableType = {
  cancel: () => mixed,
  promise: Promise<*>
}

type ValidatorType = {
  validating: boolean,
  error: ?string,
  dirty: boolean
}

type ValidatorState = ValidatorType & {
  valid: boolean
}

type ValidateFunc = (any, ...any) => Promise<?string>;

const VALIDATION_DEBOUNCE = 400;

class ValidatorException extends Error {
  isCancelled: boolean;
  constructor (isCancelled, message) {
    super(message);
    this.name = 'ValidatorException';
    this.isCancelled = isCancelled;
  }
}

// creating a manual input event
type ManualInputEvent = {
  type: string,
  target: {
    [key: string]: any,
    /** matching type of name prop */
    name: ?string,
    value: ?any
  }
}

type InputEvent = SyntheticInputEvent<*> | ManualInputEvent;

export type ValidatorProps = {
  validate: ValidateFunc | ValidateFunc[],
  onValid: (any, InputEvent) => mixed,
  onInvalid: (string, InputEvent) => mixed,
  onChange: (InputEvent) => mixed,
  debounce: number,
  /** making this optional since name attr is not required for input element */
  name: ?string,
  value: ?any
}

// Makes it possible to handle cancelling and acting on promises
export function makeCancelable (prom : Promise<*>) : CancelableType {
  let isCancelled = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    return prom.then((reason) => {
      return resolve({ isCancelled, reason });
    }).catch((err) => {
      const message = typeof err === 'string' ? err : err.message;
      return reject(new ValidatorException(isCancelled, message));
    });
  });
  return {
    cancel: () => {
      isCancelled = true;
    },
    promise: wrappedPromise
  };
}

// Validator HOC
export const Validator = (WrappedComponent: any) => {
  class ValidatedField extends Component<ValidatorProps, ValidatorState> {
    state: ValidatorState = {
      error: null,
      valid: true,
      validating: false,
      dirty: false
    }

    handleChange: Function = this.handleChange.bind(this);
    handleDebounced: Function = debounce(this.handleDebounced.bind(this), this.props);
    currentValidator: ?CancelableType = null;

    static defaultProps = {
      validate: [(value:string) => Promise.resolve(null)],
      onValid: () => {},
      onInvalid: () => {},
      onChange: () => {},
      debounce: VALIDATION_DEBOUNCE
    };

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount () {
      const value = this.props.value;
      // Initialize validity
      if (value) {
        this.handleChange(value);
      }
    }

    componentWillUnmount () {
      this.cancelCurrentValidator();
      this.currentValidator = null;
    }

    cancelCurrentValidator () {
      if (this.currentValidator) {
        this.currentValidator.cancel();
      }
    }

    validateValue (value: any) {
      const validators = Array.isArray(this.props.validate) ? this.props.validate : [this.props.validate];
      const promises = validators.map(vFn => vFn(value));
      return Promise.all(promises);
    }

    getValidatorPromise (value: any) {
      this.cancelCurrentValidator();
      this.setState({
        validating: true
      });
      this.currentValidator = makeCancelable(this.validateValue(value));
      return this.currentValidator.promise;
    }

    handleResolve (value: any, e: InputEvent, { isCancelled } : {isCancelled: boolean}) {
      const isValid = !!value;
      if (!isCancelled) {
        this.setState({ error: null, valid: isValid, validating: false });
        if (e) {
          this.props.onValid(value, e);
        }
      }
    }

    handleReject (value: any, e: InputEvent, { isCancelled, message } : ValidatorException) {
      if (!isCancelled) {
        this.setState({ error: message, valid: false, validating: false });
        if (e) {
          this.props.onInvalid(message, e);
        }
      }
    }

    handleDebounced (value: any, e: InputEvent) {
      // Call the promise based validator.
      this.getValidatorPromise(value)
        .then(this.handleResolve.bind(this, value, e))
        .catch(this.handleReject.bind(this, value, e));
    }

    handleChange (value: any, e: SyntheticInputEvent<*>) {
      if (e) {
        e.persist();
      }
      const event = e || { type: 'change', target: { name: this.props.name, value } };

      this.props.onChange(event);

      this.setState({
        validating: true,
        dirty: true
      });
      this.handleDebounced(value, event);
    }
    render () {
      const { onInvalid, validate, onValid, debounce, ...props } = this.props;
      return <WrappedComponent
        {...props}
        {...this.state}
        onChange={this.handleChange} />;
    }
  }
  return ValidatedField;
};

export default Validator;
