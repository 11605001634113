// @flow
// import type { Action } from 'src/client/actions';
import { EVALUATION_ORDERS_LOAD_SUCCESS } from '../actions/evaluation-order.actions';

export type EvaluationOrdersState = {
  evaluationOrders: []
};

const defaultState = {
  evaluationOrders: []
};

export function evaluationOrdersReducer(
  state: EvaluationOrdersState = defaultState,
  action: { type: '', payload: [] }
): EvaluationOrdersState {
  switch (action.type) {
    case EVALUATION_ORDERS_LOAD_SUCCESS: {
      return {
        evaluationOrders: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
