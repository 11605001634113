// @flow
import type { Action } from '../actions';
import type { OrderItem } from 'src/client/types/order-item';

import {
  SHOW_REVISION_REQUEST_DIALOG,
  DISMISS_REVISION_REQUEST_DIALOG,
  REVISION_REQUEST,
  REVISION_REQUEST_SUCCESS,
  REVISION_REQUEST_ERRORS
} from 'src/client/actions/revision-request.actions';

type RevisionRequestClosedState = {
  dialogVisible: false,
  status: 'closed',
  orderId: null,
  orderItem: null
};

type RevisionRequestStateType = {
  dialogVisible: true,
  status: 'untouched' | 'loading' | 'submitted',
  orderId: string,
  orderItem: OrderItem
};

type RevisionRequestErrorState = {
  dialogVisible: true,
  status: 'error',
  errors: string[],
  address: ?string,
  orderId: string,
  orderItem: OrderItem
};

export type RevisionRequestState =
  | RevisionRequestClosedState
  | RevisionRequestStateType
  | RevisionRequestErrorState;

const defaultState = {
  dialogVisible: false,
  status: 'closed',
  address: null,
  orderId: null,
  orderItem: null
};

export function revisionRequestReducer(
  state: RevisionRequestState = defaultState,
  action: Action
) {
  switch (action.type) {
    case SHOW_REVISION_REQUEST_DIALOG: {
      return {
        dialogVisible: true,
        status: 'untouched',
        orderId: action.payload.orderId,
        orderItem: action.payload.orderItem
      };
    }
    case DISMISS_REVISION_REQUEST_DIALOG: {
      return {
        dialogVisible: false,
        status: 'closed'
      };
    }
    case REVISION_REQUEST: {
      return {
        dialogVisible: true,
        status: 'loading',
        orderId: action.payload.orderId,
        orderItem: action.payload.orderItem
      };
    }
    case REVISION_REQUEST_SUCCESS: {
      if (
        action.payload.orderId !== state.orderId ||
        !state.orderItem ||
        action.payload.orderItem.id !== state.orderItem.id
      ) {
        return state;
      }
      return {
        dialogVisible: true,
        status: 'submitted',
        orderId: action.payload.orderId,
        orderItem: action.payload.orderItem
      };
    }
    case REVISION_REQUEST_ERRORS: {
      if (
        action.payload.orderId !== state.orderId ||
        !state.orderItem ||
        action.payload.orderItem.id !== state.orderItem.id
      ) {
        return state;
      }
      return {
        dialogVisible: true,
        status: 'error',
        errors: action.payload.errors,
        orderId: action.payload.orderId,
        orderItem: action.payload.orderItem
      };
    }
    default: {
      return state;
    }
  }
}
