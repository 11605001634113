// @flow
import type { RouteDefinition } from '../types/route';

import { loadLoginSaga } from './sagas/admin-user.saga';
import { routeWorkflowWorkers } from './sagas/workflow-workers.saga';
import { routeWorkflowTasks } from './sagas/workflow-external-tasks.saga';
import AdminLogin from './containers/AdminLogin';
import AdminCamundaWorkflowDashboard from './containers/AdminCamundaWorkflowDashboard';
import AdminCamundaWorkflowTasksDashboard from './containers/AdminCamundaWorkflowTasksDashboard';

export const ADMIN_ROUTES: RouteDefinition = {
  '/admin/login/:route?': {
    id: 'admin-login',
    saga: loadLoginSaga,
    view: AdminLogin,
    requiresLogin: false
  },
  '/admin/dashboard/camunda/workers': {
    id: 'admin-camunda-workers',
    saga: routeWorkflowWorkers,
    view: AdminCamundaWorkflowDashboard,
    requiresLogin: false
  },
  '/admin/dashboard/camunda/tasks': {
    id: 'admin-camunda-tasks',
    saga: routeWorkflowTasks,
    view: AdminCamundaWorkflowTasksDashboard,
    requiresLogin: false
  }
};
