// @flow
import * as React from 'react';
import {
  RTThemedIconButton,
  RTThemedFontIcon
} from '@hc/component-lib/lib/rt-themed';
import {
  dateFormatter,
  dateTimeFormatter
} from '../../helpers/formatter-helpers';
import { Button, Chip, Dropdown, DownloadIcon } from '@hc/component-lib';
import type {
  CellContentProps,
  RowType
} from '@hc/component-lib/lib/components/organisms/data-table/types';
import { OrgUsersTypeahead } from './OrgUsersTypeahead';

import type { Order, OrgUsers } from '../../types/order';
import type { Link as LinkType } from '../../../types/link';
import type { routeGeneratorFn } from '../../../components/Pagination';
import type { OrderProgressState } from '../../reducers/order-progress.reducer';
import type { OrderSet } from '../../types/order-set';
import type { OrderingParams } from '../../../types/list';

import Pagination from '../../../components/Pagination';
import OrderProgress from './order-progress/OrderProgress';
import OrderHint from './OrderHint';
import { orderPercentComplete } from '../../helpers/progress-helpers';
import { ORDER_STATUSES } from '../../constants/order-statuses';
import { cancelAndCall } from '../../../helpers/click-helpers';
import { ORDER_SETS } from '../../constants/order-sets';
import Search from '../../../components/Search';
import { DataTableWithOrdering } from '../../../components/DataTableWithOrdering';
import Popover from '../../../components/Popover';
import { AGILE_APPRAISAL_GROUP_KEY } from '../../constants/order-choices';

import styles from './OrderList.css';

const ORDER_SET_CHOICES: { label: string, value: OrderSet }[] = [
  { label: 'All Orders', value: ORDER_SETS.ALL },
  { label: 'In Progress Only', value: ORDER_SETS.ACTIVE },
  { label: 'Complete Only', value: ORDER_SETS.COMPLETED },
  { label: 'Cancelled Only', value: ORDER_SETS.CANCELLED }
];

const MAX_EMAIL_LENGTH = 16;

const _trimEmail = (email: string) => {
  return email.length > MAX_EMAIL_LENGTH ? `${email.slice(0, 16)}...` : email;
};

const extractOrderFromCellContentProps = (props: CellContentProps): Order => {
  const order = (props.row.data: Order);
  return order;
};

const extractOrderFromRow = (row: RowType): Order => {
  const order = (row.data: Order);
  return order;
};

const LabelCellContent = (props: CellContentProps) => {
  const order = extractOrderFromCellContentProps(props);
  return order.label ? <Chip>{order.label}</Chip> : null;
};

const CreatedAtCellContent = (props: CellContentProps) => {
  const order = extractOrderFromCellContentProps(props);
  return order.createdAt ? dateTimeFormatter(order.createdAt) : '';
};

const CreatedByCellContent = (props: CellContentProps) => {
  const order = extractOrderFromCellContentProps(props);
  const email = order.ownerEmail ? order.ownerEmail : '';
  return email ? (
    <div>
      <Popover
        theme={styles}
        PopoverLinkComponent={<span>{_trimEmail(email)}</span>}
        PopoverContentComponent={<span>{email}</span>}
      />
    </div>
  ) : (
    ''
  );
};

const CompletedCellContent = (props: CellContentProps) => {
  const order = extractOrderFromCellContentProps(props);
  const cellContent =
    order.status === ORDER_STATUSES.COMPLETE && order.actualDeliveryDate
      ? dateFormatter(order.actualDeliveryDate)
      : '';
  return cellContent;
};

const PropertiesCellContent = (props: CellContentProps) => {
  const order = extractOrderFromCellContentProps(props);
  const cellContent =
    order.status === ORDER_STATUSES.NEW ||
    order.status === ORDER_STATUSES.CLIENT_REVIEW
      ? order.orderItemsCount
      : order.orderItemsToProcessCount;
  return cellContent;
};

const ProductCellContent = (props: CellContentProps) => {
  const order = extractOrderFromCellContentProps(props);
  return order.orderTypeDescriptor.name;
};

const MessagesCellContent = (props: CellContentProps) => {
  const order = extractOrderFromCellContentProps(props);
  return order.status !== ORDER_STATUSES.NEW &&
    order.status !== ORDER_STATUSES.CLIENT_REVIEW &&
    order.orderTypeDescriptor.canMessage ? (
    <span>
      <RTThemedIconButton
        className={styles.messageCellIcon}
        theme={styles}
        icon="email"
      />
      {order.incomingCommentsCount}
    </span>
  ) : null;
};

type OrderListProps = {
  orders: Order[],
  links: {
    prev?: LinkType,
    next?: LinkType,
    last: LinkType
  },
  paginationRouteGeneratorFn: routeGeneratorFn,
  query: ?string,
  ordering: OrderingParams[],
  orderProgressState: OrderProgressState,
  showNewOrderButton: boolean,
  loading: boolean,
  orderSet: OrderSet,
  orgUsers: OrgUsers,
  ownerId: ?string,
  isAdminUser: boolean,
  onNewOrderClick: () => void,
  onSelectOrder: (string) => void,
  onReviewClick: (string) => void,
  onDownloadClick: (order: Order) => void,
  onOrderProgressShow: (Order) => void,
  onSearch: (string) => void,
  onSort: (OrderingParams[]) => void,
  onChangeOrderSet: (orderSet: OrderSet) => void,
  onChangeOwnerId: (ownerId: string) => void,
  onCancelClick: (string) => void,
  onViewOrderReport: (orderId: string) => void
};

type OrderListState = {
  wrappedOnReviewClick: (any, string) => void,
  wrappedOnSelectOrderClick: (any, string) => void,
  wrappedOnDownloadClick: (any, Order) => void,
  wrappedOnCancelClick: (any, string) => void,
  wrappedOnViewOrder: (any, string) => void
};

class OrderList extends React.Component<OrderListProps, OrderListState> {
  constructor(props: OrderListProps) {
    super(props);
    this.state = this.getWrappedClickFunctions();
  }

  getWrappedClickFunctions = () => {
    const {
      onReviewClick,
      onSelectOrder,
      onDownloadClick,
      onCancelClick,
      onViewOrderReport
    } = this.props;
    return {
      wrappedOnReviewClick: cancelAndCall(onReviewClick),
      wrappedOnSelectOrderClick: cancelAndCall(onSelectOrder),
      wrappedOnDownloadClick: cancelAndCall(onDownloadClick),
      wrappedOnCancelClick: cancelAndCall(onCancelClick),
      wrappedOnViewOrder: cancelAndCall(onViewOrderReport)
    };
  };

  orderNeedsReview = (order: Order): boolean => {
    return (
      !order.cancelled &&
      (order.status === ORDER_STATUSES.CLIENT_REVIEW ||
        order.orderItemsToReviewCount > 0)
    );
  };

  handleSelectOrder = (row: RowType) => {
    const order = extractOrderFromRow(row);
    const { onReviewClick, onSelectOrder } = this.props;
    if (this.orderNeedsReview(order)) {
      onReviewClick(order.id);
    } else if (order.status !== ORDER_STATUSES.NEW) {
      onSelectOrder(order.id);
    }
  };

  componentDidUpdate(prevProps: OrderListProps) {
    if (
      this.props.onReviewClick !== prevProps.onReviewClick ||
      this.props.onSelectOrder !== prevProps.onSelectOrder ||
      this.props.onDownloadClick !== prevProps.onDownloadClick ||
      this.props.onCancelClick !== prevProps.onCancelClick ||
      this.props.onViewOrderReport !== prevProps.onViewOrderReport
    ) {
      this.setState(this.getWrappedClickFunctions());
    }
  }

  StatusCellContent = (props: CellContentProps) => {
    const order = extractOrderFromCellContentProps(props);
    const { orderProgressState, onOrderProgressShow } = this.props;
    if (order.status === ORDER_STATUSES.NEW) {
      return 'Verifying Addresses';
    } else if (order.status === ORDER_STATUSES.CLIENT_REVIEW) {
      return (
        <RTThemedFontIcon
          className={styles.pauseIcon}
          value="pause_circle_filled"
        />
      );
    } else if (order.status === ORDER_STATUSES.ACCEPTED) {
      return (
        <OrderProgress
          order={order}
          customerOrderId={order.customerOrderId}
          completedOrderPercentage={orderPercentComplete(order)}
          orderProgressState={orderProgressState}
          onOrderProgressShow={() => onOrderProgressShow(order)}
          total={order.orderItemsToProcessCount}
        />
      );
    } else {
      return order.status;
    }
  };

  ActionCellContent = (props: CellContentProps) => {
    const order = extractOrderFromCellContentProps(props);
    const {
      wrappedOnReviewClick,
      wrappedOnDownloadClick,
      wrappedOnSelectOrderClick,
      wrappedOnCancelClick,
      wrappedOnViewOrder
    } = this.state;

    if (order.status === ORDER_STATUSES.NEW && !order.cancelled) {
      // new and not in the middle of being cancelled
      return (
        <OrderHint
          label="Cancel Order"
          isNotification
          icon="close"
          onHintClick={(e: any) => wrappedOnCancelClick(e, order.id)}
        />
      );
    } else if (this.orderNeedsReview(order)) {
      return (
        <OrderHint
          label="Needs Review"
          isNotification
          icon={<DownloadIcon height="14px" width="23px" />}
          onHintClick={(e: any) => wrappedOnReviewClick(e, order.id)}
        />
      );
    } else if (order.status === ORDER_STATUSES.COMPLETE) {
      if (
        order.orderItemsCount === 1 &&
        order.orderTypeDescriptor.groupKey !== AGILE_APPRAISAL_GROUP_KEY
      ) {
        return (
          <OrderHint
            label="Download Report"
            icon="keyboard_arrow_right"
            onHintClick={(e: any) => wrappedOnViewOrder(e, order.id)}
          />
        );
      } else {
        return (
          <OrderHint
            label="Export Order"
            icon={<DownloadIcon height="14px" width="23px" />}
            onHintClick={(e: any) => wrappedOnDownloadClick(e, order)}
          />
        );
      }
    } else {
      return (
        <OrderHint
          label="View Order"
          icon="keyboard_arrow_right"
          onHintClick={(e: any) => wrappedOnSelectOrderClick(e, order.id)}
        />
      );
    }
  };

  render() {
    const {
      orders,
      links,
      paginationRouteGeneratorFn,
      query,
      ordering,
      orderSet,
      loading,
      showNewOrderButton,
      onNewOrderClick,
      onSearch,
      onChangeOrderSet,
      onChangeOwnerId,
      onSort,
      ownerId,
      orgUsers,
      isAdminUser
    } = this.props;

    const columns = [
      {
        label: 'Order Name',
        attribute: 'name',
        align: 'left'
      },
      {
        label: 'Label',
        attribute: 'label',
        align: 'left',
        CellContent: LabelCellContent
      },
      {
        label: 'Created',
        attribute: 'createdAt',
        align: 'center',
        CellContent: CreatedAtCellContent
      },
      {
        label: 'Completed',
        attribute: 'actualDeliveryDate',
        align: 'center',
        CellContent: CompletedCellContent
      },
      {
        label: 'Properties',
        attribute: '',
        align: 'center',
        CellContent: PropertiesCellContent,
        sortable: false
      },
      {
        label: 'Product',
        attribute: 'orderType',
        align: 'left',
        CellContent: ProductCellContent
      },
      {
        label: 'Messages',
        attribute: '',
        align: 'center',
        CellContent: MessagesCellContent,
        sortable: false
      },
      {
        label: 'Status',
        attribute: '',
        align: 'center',
        cellStyleHeader: {
          width: '100px',
          textAlign: 'center'
        },
        CellContent: this.StatusCellContent,
        sortable: false
      },
      {
        label: 'Action',
        attribute: '',
        align: 'left',
        CellContent: this.ActionCellContent,
        sortable: false
      }
    ];

    if (isAdminUser) {
      columns.splice(3, 0, {
        label: 'Created By',
        attribute: 'createdBy',
        CellContent: CreatedByCellContent,
        sortable: false
      });
    }

    const data = orders.map((order) => {
      return {
        data: order
      };
    });

    return (
      <div>
        <div className={styles.orderListHeader}>
          {showNewOrderButton && (
            <Button
              className={styles.addButton}
              micro
              onClick={onNewOrderClick}
              dataHcName="new-order-button"
            >
              <RTThemedFontIcon value="add" className={styles.addButtonIcon} />{' '}
              New Order
            </Button>
          )}
          <div
            className={styles.searchAndFilterContainer}
            data-hc-name="order-search-container"
          >
            <Dropdown
              className={styles.filter}
              theme={styles}
              onChange={(orderSet: OrderSet) => {
                onChangeOrderSet(orderSet);
              }}
              options={ORDER_SET_CHOICES}
              value={orderSet}
              dataHcName="order-search-dropdown"
            />
            {isAdminUser && (
              <OrgUsersTypeahead
                onChangeOwnerId={onChangeOwnerId}
                ownerId={ownerId}
                orgUsers={orgUsers}
              />
            )}
            <Search
              className={styles.filter}
              onSearch={onSearch}
              placeholder="Find by name, label, or id"
              query={query}
              dataHcName="order-search-input"
            />
          </div>
        </div>
        <DataTableWithOrdering
          columns={columns}
          ordering={ordering}
          data={data}
          loading={loading}
          onSortColumn={onSort}
          onRowClick={this.handleSelectOrder}
          theme={styles}
        />
        <Pagination
          routeGeneratorFn={paginationRouteGeneratorFn}
          links={links}
        />
      </div>
    );
  }
}

export default OrderList;
