// Example Indicator Component
import React from 'react';
import { ValidatingIcon } from '@hc/component-lib';
import { themr } from 'react-css-themr';
import validationIndicatorTheme from './ValidationIndicator.scss';

export type Props = {
  // is in validating state
  validating: boolean,
  // css module theme
  theme: {}
};

/**
 * Displays validating message when in validating state
 */
export const ValidationIndicator = ({ theme, validating }: Props) => {
  return validating ? (
    <div className={theme.ValidationIndicator}>
      <ValidatingIcon />
    </div>
  ) : null;
};

export const ThemedValidationIndicator = themr(
  'ThemedValidationIndicator',
  validationIndicatorTheme
)(ValidationIndicator);

export default ThemedValidationIndicator;
