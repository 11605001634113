// @flow
import React from 'react';
import {
  RTThemedCard,
  RTThemedCardText
} from '@hc/component-lib/lib/rt-themed';

const OrderAccepted = ({ link }: { link: string }) => (
  <RTThemedCard raised>
    <RTThemedCardText>
      <div>Order Accepted</div>
      <div>
        <a href={link}>Click here to get started</a>
      </div>
    </RTThemedCardText>
  </RTThemedCard>
);

export default OrderAccepted;
