// @flow
import React from 'react';
import { ProductButton } from '@hc/component-lib';
import { reduxForm } from 'redux-form';
import { ADD_ORDER_FORM_NAME } from '../../../constants/add-order';
import type {
  ProductType,
  ProductTypeDescriptor
} from '../../../types/add-order';
import type { OrderTypeDescriptor } from '../../../types/order';
import { PRODUCT_IDS } from '../../../constants/order-choices';
import styles from './add-order-form.css';

type SelectProductProps = {
  updateStep: (number, ?ProductType) => void,
  updateOrderTypes: (OrderTypeDescriptor[]) => void,
  productTypeChoices: ProductTypeDescriptor[],
  onSubmit: (any) => void
};

type SelectProductFormState = {
  productType: ?ProductType
};

class SelectProductFormContent extends React.Component<
  SelectProductProps,
  SelectProductFormState
> {
  state = { productType: null };

  handleSubmit = (e) => {
    e.preventDefault();
    const { productType } = this.state;
    const { onSubmit } = this.props;
    onSubmit(productType);
  };

  handleClick = (e) => {
    const { updateOrderTypes } = this.props;
    const orderTypes = e.product.orderTypes;
    this.setState({ productType: e.productType });
    updateOrderTypes(orderTypes);
  };

  render() {
    const { productTypeChoices } = this.props;
    return (
      <form onSubmit={this.handleSubmit} data-hc-name="select-product-form">
        <h3>Choose a Product</h3>
        <div
          className={styles.selectProductContainer}
          data-hc-name="product-buttons"
        >
          {productTypeChoices &&
            productTypeChoices.map((choice, idx) => {
              const productType = PRODUCT_IDS[choice.groupKey] || null;
              if (!productType) return null;
              return (
                <ProductButton
                  dataHcNames={{ product: productType }}
                  productType={productType}
                  product={choice}
                  onClick={this.handleClick}
                  theme={styles}
                  key={idx}
                />
              );
            })}
        </div>
      </form>
    );
  }
}

const SelectProductForm = reduxForm({
  form: ADD_ORDER_FORM_NAME,
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
  initialValues: {
    items: [{ address: null, propertyType: null }],
    itemsSource: 'entry',
    orderType: null
  }
})(SelectProductFormContent);

export default SelectProductForm;
