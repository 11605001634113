/**
 * NOTE: type identifiers are messed up in flow.
 * React docgen expects 3 types, flow only supports 2
 * Once that is resolved, enable flow
 */
import React, { Component } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import { FormSubmit } from '../form-submit';

import theme from './ConfirmEmailError.scss';

type ConfirmEmailErrorTheme = {
  ConfirmEmailErrorTitle: string,
  ConfirmEmailError: string,
  FormSubmit: string,
  ErrorMessage: string
}

type Props = {
  title: ?string,
  /** redirect to login callback */
  onRedirectToLogin: () => void,
  /** Resend confirmation callback */
  onResendConfirmEmail: () => void,
  /** is the token missing? */
  isMissingToken: boolean,
  /** message to display for missing token */
  missingTokenMessage: string,
  /** is the token expired */
  isExpiredToken: boolean,
  /** message to display for expired token */
  expiredTokenMessage: string,
  /** Error on join */
  errorMessage?: ?string,
  /** currently submitting */
  isSubmitting: boolean,
  /** Optional className */
  className?: string,
  /** React Css Themr theme */
  theme: ConfirmEmailErrorTheme
}

type State = {}

export class ConfirmEmailError extends Component <Props, State, void> {
  static defaultProps = {
    title: null,
    onResendConfirmEmail: () => {},
    onRedirectToLogin: () => {},
    isMissingToken: false,
    missingTokenMessage: 'Verification link did not include a token.',
    isExpiredToken: false,
    expiredTokenMessage: 'Verification link has expired.',
    errorMessage: null,
    isSubmitting: false
  }

  render () {
    const {
      title,
      onResendConfirmEmail,
      onRedirectToLogin,
      isMissingToken,
      missingTokenMessage,
      isExpiredToken,
      expiredTokenMessage,
      errorMessage,
      isSubmitting,
      className,
      theme
    } = this.props;
    const combinedClassName = className ? classNames(theme.ConfirmEmailError, className) : theme.ConfirmEmailError;

    return (
      <div className={combinedClassName}>
        {title && <h2 className={theme.ConfirmEmailErrorTitle}>{title}</h2>}
        <div>
          {errorMessage && <div className={theme.ErrorMessage}>{errorMessage}</div>}
          {isMissingToken && <div className={theme.ErrorMessage}>{missingTokenMessage}</div>}
          {isExpiredToken && <div className={theme.ErrorMessage}>{expiredTokenMessage}</div>}
        </div>

        {isExpiredToken && <FormSubmit
          theme={theme}
          disabled={isSubmitting}
          onSubmit={onResendConfirmEmail}
          label={isSubmitting ? 'Resending' : 'Resend confirmation email'} />
        }
        {!isExpiredToken && !isMissingToken &&
          <FormSubmit
            disabled={false}
            theme={theme}
            onSubmit={onRedirectToLogin}
            label='Login' />
        }
      </div>
    );
  }
}

export default themr('ConfirmEmailError', theme)(ConfirmEmailError);
