import { select, call, put, takeEvery } from 'redux-saga/effects';
import { Saga } from 'redux-saga';
import { Client } from '../../api/order-manager-api-client';
import userTokenSelector from '../../selectors/user-token.selector';
import { evaluationOrdersLoadSuccess } from '../actions/evaluation-order.actions';

export function* evaluateOrders(): Saga<void> {
  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const data = yield call([client, client.getEvaluateOrders]);
    yield put(evaluationOrdersLoadSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

export function* registerEvaluationOrders(): Saga<void> {
  yield takeEvery('EVALUATE_ORDERS', evaluateOrders);
}
