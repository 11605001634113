// @flow
import type { Saga } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { startAsyncValidation, stopAsyncValidation, change } from 'redux-form';
import { set as buildObject } from 'lodash';

import { handleStandardExceptions } from 'src/sagas/helpers';

import { requestAddressParts } from 'src/client/sagas/address-parts.saga';

import {
  type ValidateAddressFieldAction,
  VALIDATE_ADDRESS_FIELD,
  validateAddressFieldSuccess,
  validateAddressFieldError
} from 'src/client/actions/address-validation.actions';

export function* validateAddress(
  action: ValidateAddressFieldAction
): Saga<void> {
  const { address, field, addressId, formName } = action.payload;
  const invalidValue = {
    addressUnparsed: address,
    parsedValid: false
  };
  try {
    const errors = {};

    // redux-form exported their action creator types wrong
    // $FlowFixMe
    yield put(startAsyncValidation(formName));

    const addressParts = yield call(requestAddressParts, address, addressId);
    const { address: streetAddress, city, state, zipcode } = addressParts;

    if (streetAddress && ((city && state) || zipcode)) {
      // valid address update hidden field
      yield put(
        change(formName, field, {
          ...addressParts,
          addressUnparsed: address,
          parsedValid: true
        })
      );
      yield put(validateAddressFieldSuccess(field, formName));
    } else {
      // invalid address
      buildObject(errors, field, 'Unable to parse address');
      yield put(change(formName, field, invalidValue));
      yield put(
        validateAddressFieldError(field, formName, 'Unable to parse address')
      );
    }
    yield put(stopAsyncValidation(formName, errors));
  } catch (e) {
    const errors = {};
    yield call(handleStandardExceptions, e);
    const errorMessage = e.address_string ? e.address_string : e.detail;

    buildObject(errors, field, errorMessage);
    yield put(change(formName, field, invalidValue));
    yield put(stopAsyncValidation(formName, errors));
    yield put(validateAddressFieldError(field, formName, errorMessage));
  }
}

export function* registerValidationAddress(): Saga<void> {
  yield takeLatest(VALIDATE_ADDRESS_FIELD, validateAddress);
}
