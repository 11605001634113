// @flow
import React from 'react';
import {
  RTThemedLayout,
  RTThemedPanel,
  RTThemedAppBar
} from '@hc/component-lib/lib/rt-themed';

const SimpleLayout = (Wrapped: any) => {
  return (props: any) => (
    <RTThemedLayout>
      <RTThemedPanel>
        <RTThemedAppBar title="HouseCanary Order Manager" />
        <div style={{ flex: 1, overflowY: 'auto', padding: '1.8rem' }}>
          <Wrapped {...props} />
        </div>
      </RTThemedPanel>
    </RTThemedLayout>
  );
};

export default SimpleLayout;
