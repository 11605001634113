// @flow
import camelCase from 'lodash.camelcase';

import type { OrderingParams } from 'src/types/list';

import { parseOrderingToQueryParams } from 'src/helpers/url-helpers';

export const getOrderingParamsFromQS = (
  orderingQSValues: ?string
): OrderingParams[] => {
  if (orderingQSValues) {
    const splitValues = orderingQSValues.split(',');
    return splitValues.map((qsValue: string) => {
      if (qsValue.startsWith('-')) {
        return { field: camelCase(qsValue.substr(1)), asc: false };
      } else {
        return { field: camelCase(qsValue), asc: true };
      }
    });
  }
  return [];
};

export const buildListLink = (
  baseUrl: string,
  query: ?string,
  ordering: OrderingParams[],
  page: ?number,
  orderSet?: string,
  ownerId?: ?string
): string => {
  const queryStringParts = [];
  if (ordering) {
    const orderingQueryString: string = parseOrderingToQueryParams(ordering);
    if (orderingQueryString) {
      queryStringParts.push(orderingQueryString);
    }
  }
  if (query) {
    queryStringParts.push(`query=${query}`);
  }
  if (page) {
    queryStringParts.push(`page=${page}`);
  }
  if (orderSet) {
    queryStringParts.push(`orderSet=${orderSet}`);
  }
  if (ownerId) {
    queryStringParts.push(`ownerId=${ownerId}`);
  }
  return queryStringParts.length > 0
    ? `${baseUrl}?${queryStringParts.join('&')}`
    : baseUrl;
};

export const shouldDisplayList = (items: Array<any>, query: ?string) => {
  return (
    items.length > 0 || (query !== null && query !== undefined && query !== '')
  );
};
