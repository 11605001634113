// @flow
export const VALIDATE_ADDRESS_FIELD = 'VALIDATE_ADDRESS_FIELD';
export const VALIDATE_ADDRESS_FIELD_SUCCESS = 'VALIDATE_ADDRESS_FIELD_SUCCESS';
export const VALIDATE_ADDRESS_FIELD_ERROR = 'VALIDATE_ADDRESS_FIELD_ERROR';

export type ValidateAddressFieldAction = {
  type: 'VALIDATE_ADDRESS_FIELD',
  payload: {
    addressId: ?string,
    address: string,
    field: string,
    formName: string
  }
};
export type ValidateAddressFieldSuccessAction = {
  type: 'VALIDATE_ADDRESS_FIELD_SUCCESS',
  payload: {
    field: string,
    formName: string
  }
};
export type ValidateAddressFieldErrorAction = {
  type: 'VALIDATE_ADDRESS_FIELD_ERROR',
  payload: {
    field: string,
    formName: string,
    errorMessage: string
  }
};

export type AddressValidationAction =
  | ValidateAddressFieldAction
  | ValidateAddressFieldSuccessAction
  | ValidateAddressFieldErrorAction;

export const validateAddressField = (
  address: string,
  field: string,
  addressId: ?string,
  formName: string
): ValidateAddressFieldAction => ({
  type: VALIDATE_ADDRESS_FIELD,
  payload: { address, field, addressId, formName }
});
export const validateAddressFieldSuccess = (
  field: string,
  formName: string
): ValidateAddressFieldSuccessAction => ({
  type: VALIDATE_ADDRESS_FIELD_SUCCESS,
  payload: { field, formName }
});
export const validateAddressFieldError = (
  field: string,
  formName: string,
  errorMessage: string
): ValidateAddressFieldErrorAction => ({
  type: VALIDATE_ADDRESS_FIELD_ERROR,
  payload: { field, formName, errorMessage }
});
