// @flow

import type { Order } from 'src/client/types/order';
import type { OrderItem } from 'src/client/types/order-item';
import type { Links } from 'src/types/link';
import type { QueryParams } from 'src/types/list';

export const REPORT_SUMMARY_LOAD_SUCCESS = 'REPORT_SUMMARY_LOAD_SUCCESS';
export const REPORT_SUMMARY_LOAD_ERROR = 'REPORT_SUMMARY_LOAD_ERROR';
export const REPORT_SUMMARY_SELECT_FILTER = 'REPORT_SUMMARY_SELECT_FILTER';
export const REPORT_SUMMARY_UNSELECT_FILTER = 'REPORT_SUMMARY_UNSELECT_FILTER';
export const REPORT_SUMMARY_ITEMS_LOAD = 'REPORT_SUMMARY_ITEMS_LOAD';
export const REPORT_SUMMARY_ITEMS_LOADING = 'REPORT_SUMMARY_ITEMS_LOADING';
export const REPORT_SUMMARY_ITEMS_LOAD_SUCCESS =
  'REPORT_SUMMARY_ITEMS_LOAD_SUCCESS';
export const REPORT_SUMMARY_ITEMS_LOAD_ERROR =
  'REPORT_SUMMARY_ITEMS_LOAD_ERROR';
export const REPORT_SUMMARY_ITEMS_GO_TO_PAGE =
  'REPORT_SUMMARY_ITEMS_GO_TO_PAGE';
export const REPORT_SUMMARY_DOWNLOAD_CSV = 'REPORT_SUMMARY_DOWNLOAD_CSV';
export const CLEAR_REPORT_SUMMARY = 'CLEAR_REPORT_SUMMARY';

export type ReportSummaryLoadSuccessAction = {
  type: 'REPORT_SUMMARY_LOAD_SUCCESS',
  payload: { orders: Order[] }
};
export type ReportSummaryLoadErrorAction = {
  type: 'REPORT_SUMMARY_LOAD_ERROR',
  payload: { errorMessage: string }
};
export type ReportSummarySelectFilterAction = {
  type: 'REPORT_SUMMARY_SELECT_FILTER',
  payload: { filter: ?string }
};
export type ReportSummaryUnselectFilterAction = {
  type: 'REPORT_SUMMARY_UNSELECT_FILTER',
  payload: { filter: string }
};
export type ReportSummaryItemsLoadAction = {
  type: 'REPORT_SUMMARY_ITEMS_LOAD',
  payload: { orderId: string }
};
export type ReportSummaryItemsLoadingAction = {
  type: 'REPORT_SUMMARY_ITEMS_LOADING',
  payload: { orderId: string }
};
export type ReportSummaryItemsLoadSuccessAction = {
  type: 'REPORT_SUMMARY_ITEMS_LOAD_SUCCESS',
  payload: {
    orderId: string,
    orderItems: OrderItem[],
    links: Links,
    params: QueryParams
  }
};
export type ReportSummaryItemsLoadErrorAction = {
  type: 'REPORT_SUMMARY_ITEMS_LOAD_ERROR',
  payload: { orderId: string, errorMessage: string }
};
export type ReportSummaryItemsGoToPageAction = {
  type: 'REPORT_SUMMARY_ITEMS_GO_TO_PAGE',
  payload: { orderId: string, page: number }
};
export type ReportSummaryDownloadCSVAction = {
  type: 'REPORT_SUMMARY_DOWNLOAD_CSV',
  payload: { orderId: string, filename: string }
};
export type ClearReportSummaryAction = { type: 'CLEAR_REPORT_SUMMARY' };

export type ReportSummaryAction =
  | ReportSummaryLoadSuccessAction
  | ReportSummaryLoadErrorAction
  | ReportSummarySelectFilterAction
  | ReportSummaryUnselectFilterAction
  | ReportSummaryItemsLoadAction
  | ReportSummaryItemsLoadingAction
  | ReportSummaryItemsLoadSuccessAction
  | ReportSummaryItemsLoadErrorAction
  | ReportSummaryItemsGoToPageAction
  | ReportSummaryDownloadCSVAction
  | ClearReportSummaryAction;

export const reportSummaryLoadSuccess = (
  orders: Order[]
): ReportSummaryLoadSuccessAction => ({
  type: REPORT_SUMMARY_LOAD_SUCCESS,
  payload: { orders }
});
export const reportSummaryLoadError = (
  errorMessage: string
): ReportSummaryLoadErrorAction => ({
  type: REPORT_SUMMARY_LOAD_ERROR,
  payload: { errorMessage }
});
export const reportSummarySelectFilter = (
  filter: ?string
): ReportSummarySelectFilterAction => ({
  type: REPORT_SUMMARY_SELECT_FILTER,
  payload: { filter }
});
export const reportSummaryUnselectFilter = (
  filter: string
): ReportSummaryUnselectFilterAction => ({
  type: REPORT_SUMMARY_UNSELECT_FILTER,
  payload: { filter }
});
export const reportSummaryItemsLoad = (
  orderId: string
): ReportSummaryItemsLoadAction => ({
  type: REPORT_SUMMARY_ITEMS_LOAD,
  payload: { orderId }
});
export const reportSummaryItemsLoading = (
  orderId: string
): ReportSummaryItemsLoadingAction => ({
  type: REPORT_SUMMARY_ITEMS_LOADING,
  payload: { orderId }
});
export const reportSummaryItemsLoadSuccess = (
  orderId: string,
  orderItems: OrderItem[],
  links: Links,
  params: QueryParams
): ReportSummaryItemsLoadSuccessAction => ({
  type: REPORT_SUMMARY_ITEMS_LOAD_SUCCESS,
  payload: { orderId, orderItems, links, params }
});
export const reportSummaryItemsLoadError = (
  orderId: string,
  errorMessage: string
): ReportSummaryItemsLoadErrorAction => ({
  type: REPORT_SUMMARY_ITEMS_LOAD_ERROR,
  payload: { orderId, errorMessage }
});
export const reportSummaryItemsGoToPage = (
  orderId: string,
  page: number
): ReportSummaryItemsGoToPageAction => ({
  type: REPORT_SUMMARY_ITEMS_GO_TO_PAGE,
  payload: { orderId, page }
});
export const reportSummaryDownloadCSV = (
  orderId: string,
  filename: string
): ReportSummaryDownloadCSVAction => ({
  type: REPORT_SUMMARY_DOWNLOAD_CSV,
  payload: { orderId, filename }
});
export const clearReportSummary = (): ClearReportSummaryAction => ({
  type: CLEAR_REPORT_SUMMARY
});
