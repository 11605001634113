// @flow
// $FlowFixMe
import { createSelector } from 'reselect';
import { format } from 'date-fns';

import type { State } from 'src/reducers';
import type { Order } from 'src/client/types/order';
import type { ReportSummary } from 'src/client/types/report-summary';
import type { ReportSummaryOrderItemsState } from 'src/client/reducers/report-summary-order-items.reducer';
import type { ReportSummaryState } from 'src/client/reducers/report-summary.reducer';

import clientSelector from 'src/client/selectors/client.selector';
import { MONTHS } from 'src/constants/time';
import * as logger from 'src/logger';

export const reportSummaryOrderItemsStateSelector = (
  state: State
): ReportSummaryOrderItemsState =>
  clientSelector(state).reportSummaryOrderItems;
export const reportSummaryStateSelector = (state: State): ReportSummaryState =>
  clientSelector(state).reportSummary;
export const reportSummaryOrdersSelector = (state: State) =>
  reportSummaryStateSelector(state).orders;
export const reportSummarySelectedFilters = (state: State) =>
  reportSummaryStateSelector(state).selectedFilters;

export const reportSummaryAvailableFilters = createSelector(
  reportSummaryStateSelector,
  (reportSummaryState) => reportSummaryState.availableFilters.sort()
);

export const filteredReportSummaryOrdersSelector = createSelector(
  reportSummarySelectedFilters,
  reportSummaryOrdersSelector,
  (selectedFilters: string[], completedOrders: Order[]): Order[] => {
    return selectedFilters.length
      ? completedOrders.filter((o) => {
          return o.label ? selectedFilters.indexOf(o.label) > -1 : false;
        })
      : completedOrders; // return full list when filters are not applied
  }
);

export const transformedReportSummarySelector = createSelector(
  filteredReportSummaryOrdersSelector,
  (filteredReportSummaryOrders: Order[]): ReportSummary => {
    const reportSummary = filteredReportSummaryOrders.reduce(
      (reportSummary, order) => {
        if (!order.actualDeliveryDate) {
          logger.logWarning(
            new Error(
              `reportSummaryMonthsSelector: date is not provided, order.actualDeliveryDate = ${order.actualDeliveryDate}`
            ),
            order
          );
          return reportSummary;
        }
        const actualDeliveryDate = new Date(order.actualDeliveryDate);

        // splitting formatted string instead of calling format twice
        const [month, year] = format(actualDeliveryDate, 'MMMM|yyyy').split(
          '|'
        );

        if (!year || !month) {
          logger.logWarning(
            new Error(
              `reportSummaryMonthsSelector: failed to parse/format date in order.actualDeliveryDate = ${order.actualDeliveryDate}`
            ),
            order
          );
          return reportSummary;
        }

        if (!reportSummary[year]) {
          // year does not exist, therefore create year and month with order
          reportSummary.years.push(year);
          reportSummary[year] = {
            months: [month],
            [month]: [order]
          };
        } else {
          if (!reportSummary[year][month]) {
            // year exists but month does not, create month with order
            reportSummary[year].months.push(month);
            reportSummary[year][month] = [order];
          } else {
            // year and month exist, add it to month list
            reportSummary[year][month].push(order);
          }
        }

        return reportSummary;
      },
      { years: [] }
    );

    reportSummary.years = reportSummary.years.sort((a, b) => (b < a ? -1 : 1));
    return reportSummary;
  }
);

export const yearSummarySortedMonthsSelector = createSelector(
  (yearSummary) => yearSummary,
  (yearSummary) => {
    return yearSummary.months.sort(
      (a, b) => MONTHS.indexOf(a) - MONTHS.indexOf(b)
    );
  }
);
