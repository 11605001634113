// @flow
import type { OrderSet } from 'src/client/types/order-set';
import type { OrderStatus } from 'src/client/types/order';

import { ORDER_STATUSES } from './order-statuses';

type ORDER_SETS_TYPE = { [key: string]: OrderSet };

export const ORDER_SETS: ORDER_SETS_TYPE = {
  ALL: 'allOrders',
  ACTIVE: 'activeOrders',
  COMPLETED: 'completedOrders',
  CANCELLED: 'cancelledOrders'
};

export const ORDER_SET_TO_STATUSES: { [key: OrderSet]: OrderStatus[] } = {
  [ORDER_SETS.ACTIVE]: [
    ORDER_STATUSES.NEW,
    ORDER_STATUSES.CLIENT_REVIEW,
    ORDER_STATUSES.ACCEPTED
  ],
  [ORDER_SETS.COMPLETED]: [ORDER_STATUSES.COMPLETE],
  [ORDER_SETS.CANCELLED]: [
    ORDER_STATUSES.CANCELLED,
    ORDER_STATUSES.CANCEL_PENDING
  ]
};

export const ORDER_SET_TO_LABEL: { [key: OrderSet]: string } = {
  [ORDER_SETS.ALL]: 'All',
  [ORDER_SETS.ACTIVE]: 'In Progress',
  [ORDER_SETS.COMPLETED]: 'Completed',
  [ORDER_SETS.CANCELLED]: 'Cancelled'
};
