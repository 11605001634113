// @flow
import type { ResellerInfo } from 'src/types/reseller';

import accurateLogo from 'src/reseller-logos/AccurateAppraisal.png';
import adnersonLogo from 'src/reseller-logos/Anderson_Logo_3in.png';
import apexLogo from 'src/reseller-logos/APEX3.png';
import appleLogo from 'src/reseller-logos/AppleAppraisal.png';
import appraisaltekLogo from 'src/reseller-logos/Appraisal_tek_large.jpg';
import axisLogo from 'src/reseller-logos/axis_logo_hi_res.png';
import betterLogo from 'src/reseller-logos/better-logo-lockup-31766F-large.png';
import classLogo from 'src/reseller-logos/Class_Appraisal_Logo.png';
import dartLogo from 'src/reseller-logos/DartAppraisal_Logo.png';
import firstrepublicLogo from 'src/reseller-logos/first-republic-bank-logo.png';
import palmLogo from 'src/reseller-logos/Palm-Beach-logo-2.png';
import pcvLogo from 'src/reseller-logos/pcv_murcor.png';
import southeasternLogo from 'src/reseller-logos/Southeastern.png';
import strategicLogo from 'src/reseller-logos/StrategicInformationResources.png';
import usresLogo from 'src/reseller-logos/USRES.jpg';
import ucsLogo from 'src/reseller-logos/UniversalCredit.png';
import roofstockLogo from 'src/reseller-logos/Roofstock_logo.jpg';

const SETTINGS = window.SETTINGS;

type SubDomainToReseller = {
  [subdomain: string]: ResellerInfo
};

const createResellerInfo = (companyName: string, relativeUrl: string) => {
  return {
    companyName,
    relativeCompanyLogoUrl: relativeUrl,
    fullCompanyLogoUrl: `${SETTINGS.ORDER_MANAGER_URL}${relativeUrl}`
  };
};

export const SUBDOMAIN_TO_RESELLER: SubDomainToReseller = {
  accurate: createResellerInfo(
    'Accurate Appraisal & Review Service',
    accurateLogo
  ),
  anderson: createResellerInfo('Anderson Appraisal Services', adnersonLogo),
  apex: createResellerInfo('APEX Appraisal Service', apexLogo),
  apple: createResellerInfo('Apple Appraisal', appleLogo),
  appraisaltek: createResellerInfo('AppraisalTek', appraisaltekLogo),
  axis: createResellerInfo('AXIS', axisLogo),
  better: createResellerInfo('Better Mortgage', betterLogo),
  class: createResellerInfo('Class Appraisal', classLogo),
  dart: createResellerInfo('Dart Appraisal', dartLogo),
  firstrepublic: createResellerInfo('First Republic', firstrepublicLogo),
  palmbeach: createResellerInfo(
    'Palm Beach National Appraisal Management',
    palmLogo
  ),
  pcv: createResellerInfo('PCV Murcor Appraisal Management Company', pcvLogo),
  southeastern: createResellerInfo('Southeastern Evaluation', southeasternLogo),
  strategic: createResellerInfo(
    'Strategic Information Resources',
    strategicLogo
  ),
  usres: createResellerInfo('US Real Estate Services', usresLogo),
  ucs: createResellerInfo('Universal Credit Services', ucsLogo),
  roofstock: createResellerInfo('Roofstock', roofstockLogo)
};
