import type { Order } from 'src/client/types/order';

export const EVALUATION_ORDERS_LOAD = 'EVALUATION_ORDERS_LOAD';
export const EVALUATION_ORDERS_LOAD_SUCCESS = 'EVALUATION_ORDERS_LOAD_SUCCESS';

export type EvaluationOrdersLoadAction = { type: 'EVALUATION_ORDERS_LOAD' };

type LoadSuccessPayload = {
  orders: Order[]
};

export type EvaluationOrdersLoadSuccessAction = {
  type: 'ORDER_LOAD_SUCCESS',
  payload: LoadSuccessPayload
};

export type EvaluationOrdersAction =
  | EvaluationOrdersLoadAction
  | EvaluationOrdersLoadSuccessAction;

export const evaluationOrdersLoad = (): EvaluationOrdersLoadAction => ({
  type: EVALUATION_ORDERS_LOAD
});
export const evaluationOrdersLoadSuccess = (
  payload: LoadSuccessPayload
): EvaluationOrdersLoadSuccessAction => ({
  type: EVALUATION_ORDERS_LOAD_SUCCESS,
  payload
});
