type LogMethods = 'log' | 'warn' | 'error' | 'info';

const invokeLog = (method: LogMethods, ...args: Array<any>) => {
  if (process.env.NODE_ENV === 'development' && console[method]) {
    console[method](...args);
  }
};

export const log = (...args: Array<any>) => invokeLog('log', ...args);
export const logWarning = (...args: Array<any>) => invokeLog('warn', ...args);
export const logError = (...args: Array<any>) => invokeLog('error', ...args);
export const logInfo = (...args: Array<any>) => invokeLog('info', ...args);

export const getLogService = () => ({
  log,
  warning: logWarning,
  error: logError,
  info: logInfo,
  invoke: invokeLog
});
