// @flow
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import classNames from 'classnames';

import type { MapStateToProps } from 'react-redux';
import type { State } from '../../../../reducers';
import type { FieldMeta, FieldMetaByOrderType } from '../../../types/add-order';
import type { DeliverySpeed } from '../../../types/order';

import { ADD_ORDER_FORM_NAME, FIELD_NAMES } from '../../../constants/add-order';
import FormInput from '../../../components/FormInput';
import FormCheckbox from '../../../components/FormCheckbox';
import FormDropdown from '../../../components/FormDropdown';
import FormTextarea from '../../../components/FormTextarea';
import us from 'us';

import { userDetailsStateSelector } from '../../../../selectors/user-state.selector';
import requiredValidator from '../../../components/required-validator';
import passthroughValidator from '../../../components/passthrough-validator';
import {
  fieldMetaByOrderTypeSelector,
  orderTypeDescriptorsStateSelector
} from '../../../selectors/add-order.selectors';
import { removeFileExtension } from '../../../../helpers/file-name-helpers';
import { deliverySpeedById } from '../../../helpers/order-helpers';
import { dateTimeFormatter } from '../../../helpers/formatter-helpers';

import styles from '../../../orders/components/add-order-form/add-order-form.css';
import commonFormDialogStyles from '../../../components/form-dialog-common.css';

const STATES = [
  { label: '-', value: null },
  ...us.STATES.map((state) => ({
    label: state.abbr,
    value: state.abbr
  }))
];
const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength50 = maxLength(50);

type AddOrderInfoFormContentProps = {
  invalid: boolean,
  initialValues: { [field: string]: any },
  intendedUserSameValue: boolean,
  errorMessage: ?string,
  loading: boolean,
  fieldMeta: FieldMeta,
  deliverySpeed: ?DeliverySpeed,
  onCancel: () => void,
  handleSubmit: () => void // this has to be called handleSubmit (not onSubmit) or redux-form gets weird
};

class AddOrderInfoFormContent extends React.Component<AddOrderInfoFormContentProps> {
  render() {
    const {
      errorMessage,
      invalid,
      loading,
      fieldMeta,
      handleSubmit,
      deliverySpeed
    } = this.props;

    return (
      <form onSubmit={handleSubmit} data-hc-name="add-order-info-form">
        <h3>Enter Order Details</h3>
        <div data-hc-name="dialog-content">
          <div className={styles.inputColumn}>
            {fieldMeta[FIELD_NAMES.ORDER_NAME].shown && (
              <Field
                dataHcName="order-name-input"
                required={fieldMeta[FIELD_NAMES.ORDER_NAME].required}
                validate={
                  fieldMeta[FIELD_NAMES.ORDER_NAME].required
                    ? requiredValidator
                    : passthroughValidator
                }
                name={FIELD_NAMES.ORDER_NAME}
                component={FormInput}
                type="text"
                label="Order Name"
                theme={styles}
                className={styles.formInput}
              />
            )}
            {fieldMeta[FIELD_NAMES.CUSTOMER_ORDER_ID].shown && (
              <Field
                dataHcName="order-id-input"
                name={FIELD_NAMES.CUSTOMER_ORDER_ID}
                component={FormInput}
                type="text"
                label="Order ID"
                required={fieldMeta[FIELD_NAMES.CUSTOMER_ORDER_ID].required}
                validate={
                  fieldMeta[FIELD_NAMES.CUSTOMER_ORDER_ID].required
                    ? [requiredValidator, maxLength50]
                    : maxLength50
                }
                theme={styles}
                className={styles.formInput}
              />
            )}
          </div>
          {fieldMeta[FIELD_NAMES.CLIENT_NAME].shown && (
            <Field
              dataHcName="customer-name-input"
              name={FIELD_NAMES.CLIENT_NAME}
              required={fieldMeta[FIELD_NAMES.CLIENT_NAME].required}
              validate={
                fieldMeta[FIELD_NAMES.CLIENT_NAME].required
                  ? requiredValidator
                  : passthroughValidator
              }
              component={FormInput}
              type="text"
              label="Customer Name"
              theme={styles}
              className={styles.formInput}
            />
          )}
          {fieldMeta[FIELD_NAMES.CLIENT_ADDRESS].shown && (
            <Field
              dataHcName="customer-address-input"
              name={FIELD_NAMES.CLIENT_ADDRESS}
              required={fieldMeta[FIELD_NAMES.CLIENT_ADDRESS].required}
              validate={
                fieldMeta[FIELD_NAMES.CLIENT_ADDRESS].required
                  ? requiredValidator
                  : passthroughValidator
              }
              component={FormInput}
              type="text"
              label="Customer Address"
              theme={styles}
              className={styles.formInput}
            />
          )}
          <div className={styles.inputColumn}>
            {fieldMeta[FIELD_NAMES.CLIENT_CITY].shown && (
              <Field
                dataHcName="customer-city-input"
                name={FIELD_NAMES.CLIENT_CITY}
                required={fieldMeta[FIELD_NAMES.CLIENT_CITY].required}
                validate={
                  fieldMeta[FIELD_NAMES.CLIENT_CITY].required
                    ? requiredValidator
                    : passthroughValidator
                }
                component={FormInput}
                type="text"
                label="Customer City"
                theme={styles}
                className={styles.formInput}
              />
            )}
            {fieldMeta[FIELD_NAMES.CLIENT_STATE].shown && (
              <Field
                dataHcName="customer-state-input"
                name={FIELD_NAMES.CLIENT_STATE}
                required={fieldMeta[FIELD_NAMES.CLIENT_STATE].required}
                validate={
                  fieldMeta[FIELD_NAMES.CLIENT_STATE].required
                    ? requiredValidator
                    : passthroughValidator
                }
                component={FormDropdown}
                auto
                label="Customer State"
                options={STATES}
                theme={styles}
                className={styles.formInput}
              />
            )}
            {fieldMeta[FIELD_NAMES.CLIENT_ZIPCODE].shown && (
              <Field
                dataHcName="customer-zipcode-input"
                name={FIELD_NAMES.CLIENT_ZIPCODE}
                required={fieldMeta[FIELD_NAMES.CLIENT_ZIPCODE].required}
                validate={
                  fieldMeta[FIELD_NAMES.CLIENT_ZIPCODE].required
                    ? requiredValidator
                    : passthroughValidator
                }
                component={FormInput}
                type="text"
                label="Customer Zipcode"
                theme={styles}
                className={styles.formInput}
              />
            )}
          </div>
          {fieldMeta[FIELD_NAMES.LENDER_NAME].shown && (
            <div className={`${styles.checkboxContainer} ${styles.above}`}>
              <Field
                data-hc-name="intended-user-same-checkbox"
                className={classNames(styles.checkbox, styles.formInput)}
                name="intendedUserSame"
                component={FormCheckbox}
                type="checkbox"
                label="Intended user is the same as customer info"
                theme={styles}
              />
            </div>
          )}
          {fieldMeta[FIELD_NAMES.LENDER_NAME].shown &&
            !this.props.intendedUserSameValue && (
              <div>
                {fieldMeta[FIELD_NAMES.LENDER_NAME].shown && (
                  <Field
                    dataHcName="intended-user-name-input"
                    name={FIELD_NAMES.LENDER_NAME}
                    required={fieldMeta[FIELD_NAMES.LENDER_NAME].required}
                    validate={
                      fieldMeta[FIELD_NAMES.LENDER_NAME].required
                        ? requiredValidator
                        : passthroughValidator
                    }
                    component={FormInput}
                    type="text"
                    label="Intended User Name"
                    theme={styles}
                    className={styles.formInput}
                  />
                )}
                {fieldMeta[FIELD_NAMES.LENDER_ADDRESS].shown && (
                  <Field
                    dataHcName="intended-user-address-input"
                    name={FIELD_NAMES.LENDER_ADDRESS}
                    required={fieldMeta[FIELD_NAMES.LENDER_ADDRESS].required}
                    validate={
                      fieldMeta[FIELD_NAMES.LENDER_ADDRESS].required
                        ? requiredValidator
                        : passthroughValidator
                    }
                    component={FormInput}
                    type="text"
                    label="Intended User Address"
                    theme={styles}
                    className={styles.formInput}
                  />
                )}
                <div className={styles.inputColumn}>
                  {fieldMeta[FIELD_NAMES.LENDER_CITY].shown && (
                    <Field
                      dataHcName="intended-user-city-input"
                      name={FIELD_NAMES.LENDER_CITY}
                      required={fieldMeta[FIELD_NAMES.LENDER_CITY].required}
                      validate={
                        fieldMeta[FIELD_NAMES.LENDER_CITY].required
                          ? requiredValidator
                          : passthroughValidator
                      }
                      component={FormInput}
                      type="text"
                      label="Intended User City"
                      theme={styles}
                      className={styles.formInput}
                    />
                  )}
                  {fieldMeta[FIELD_NAMES.LENDER_STATE].shown && (
                    <div data-hc-name="intended-user-state">
                      <Field
                        dataHcName="intended-user-state-input"
                        name={FIELD_NAMES.LENDER_STATE}
                        required={fieldMeta[FIELD_NAMES.LENDER_STATE].required}
                        validate={
                          fieldMeta[FIELD_NAMES.LENDER_STATE].required
                            ? requiredValidator
                            : passthroughValidator
                        }
                        component={FormDropdown}
                        auto
                        label="Intended User State"
                        options={STATES}
                        theme={styles}
                        className={styles.formInput}
                      />
                    </div>
                  )}
                  {fieldMeta[FIELD_NAMES.LENDER_ZIPCODE].shown && (
                    <Field
                      dataHcName="intended-user-input"
                      name={FIELD_NAMES.LENDER_ZIPCODE}
                      required={fieldMeta[FIELD_NAMES.LENDER_ZIPCODE].required}
                      validate={
                        fieldMeta[FIELD_NAMES.LENDER_ZIPCODE].required
                          ? requiredValidator
                          : passthroughValidator
                      }
                      component={FormInput}
                      type="text"
                      label="Intended User Zipcode"
                      theme={styles}
                      className={styles.formInput}
                    />
                  )}
                </div>
              </div>
            )}
          <div className={styles.inputColumn}>
            {fieldMeta[FIELD_NAMES.INTENDED_USE].shown && (
              <Field
                dataHcName="intended-use-input"
                name={FIELD_NAMES.INTENDED_USE}
                required={fieldMeta[FIELD_NAMES.INTENDED_USE].required}
                validate={
                  fieldMeta[FIELD_NAMES.INTENDED_USE].required
                    ? requiredValidator
                    : passthroughValidator
                }
                component={FormInput}
                type="text"
                label="Intended Use"
                theme={styles}
                className={styles.formInput}
              />
            )}
            {fieldMeta[FIELD_NAMES.LABEL].shown && (
              <Field
                dataHcName="label-input"
                name={FIELD_NAMES.LABEL}
                component={FormInput}
                type="text"
                label="Label"
                hint="Additional tag (Internal use only): Branch ID, etc."
                required={fieldMeta[FIELD_NAMES.LABEL].required}
                validate={
                  fieldMeta[FIELD_NAMES.LABEL].required
                    ? requiredValidator
                    : passthroughValidator
                }
                theme={styles}
                className={styles.formInput}
              />
            )}
          </div>
          {fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION].shown && (
            <Field
              dataHcName="special-comments-inpsection-input"
              name={FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION}
              component={FormTextarea}
              type="text"
              label="Special Comments (inspection)"
              required={
                fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION].required
              }
              validate={
                fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION].required
                  ? requiredValidator
                  : passthroughValidator
              }
              theme={styles}
              props={{ multiline: true }}
              className={styles.formInput}
            />
          )}
          {fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_AMC].shown && (
            <Field
              dataHcName="special-comments-amc-input"
              name={FIELD_NAMES.SPECIAL_COMMENTS_AMC}
              component={FormTextarea}
              type="text"
              label="Special Comments (AMC)"
              required={fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_AMC].required}
              validate={
                fieldMeta[FIELD_NAMES.SPECIAL_COMMENTS_AMC].required
                  ? requiredValidator
                  : passthroughValidator
              }
              theme={styles}
              props={{ multiline: true }}
              className={styles.formInput}
            />
          )}
          {deliverySpeed &&
            deliverySpeed.estimatedDueDate &&
            deliverySpeed.numberOfDays != null && (
              <div className={styles.dueDateContainer}>
                Due date: Reports with no issues will be delivered by{' '}
                {dateTimeFormatter(
                  deliverySpeed.estimatedDueDate,
                  "h:mm a 'on' MMMM d, yyyy"
                )}
                . If we find issues with addresses in your order, those
                addresses will be due {deliverySpeed.numberOfDays} business days
                after the issues are resolved.
              </div>
            )}
        </div>
        <div data-hc-name="dialog-footer" className={styles.footer}>
          <LoadingButton
            dataHcName="continue-button"
            className={commonFormDialogStyles.actionButton}
            type="submit"
            disabled={invalid || loading}
            loading={loading}
          >
            <span>Submit Order</span>
          </LoadingButton>
        </div>
        {errorMessage && (
          <div className={styles.footerError}>
            {errorMessage.split('\n').map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        )}
      </form>
    );
  }
}

let AddOrderInfoForm = reduxForm({
  form: ADD_ORDER_FORM_NAME,
  enableReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(AddOrderInfoFormContent);

const mapStateToProps: MapStateToProps<*, *, *> = (state: State) => {
  const selector = getFormValues(ADD_ORDER_FORM_NAME);
  const userDetailsState = userDetailsStateSelector(state);
  const formValues = selector(state);
  const fieldMetaByOrderType: FieldMetaByOrderType =
    fieldMetaByOrderTypeSelector(state);
  const orderTypeValue = formValues.orderType;
  const fieldMeta = orderTypeValue
    ? fieldMetaByOrderType[orderTypeValue]
    : fieldMetaByOrderType.default;
  const intendedUserSameValue = formValues['intendedUserSame'];
  const itemsSource = formValues['itemsSource'];
  let name = null;
  if (itemsSource === 'entry') {
    name = formValues['items'][0].address.address;
  } else if (itemsSource === 'csv') {
    name = formValues['orderFile'].name;
    if (name) {
      name = removeFileExtension(name);
    }
  }
  if (name && name.length > 100) {
    name = name.substr(0, 100);
  }
  const userDetails =
    userDetailsState.status === 'loaded' ? userDetailsState.userDetails : {};
  const orderTypeDescriptorState = orderTypeDescriptorsStateSelector(state);
  const deliverySpeed = deliverySpeedById(
    orderTypeDescriptorState,
    orderTypeValue,
    formValues.deliverySpeed
  );

  return {
    intendedUserSameValue,
    initialValues: {
      [FIELD_NAMES.CLIENT_NAME]: userDetails.orgName,
      [FIELD_NAMES.CLIENT_ADDRESS]: userDetails.address,
      [FIELD_NAMES.CLIENT_CITY]: userDetails.city,
      [FIELD_NAMES.CLIENT_STATE]: userDetails.state,
      [FIELD_NAMES.CLIENT_ZIPCODE]: userDetails.zipcode,
      intendedUserSame: true,
      name,
      ...formValues
    },
    fieldMeta,
    deliverySpeed
  };
};

AddOrderInfoForm = connect(mapStateToProps)(AddOrderInfoForm);

export default AddOrderInfoForm;
