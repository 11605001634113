// @flow
import React from 'react';
import { isRequiredValidator, orgLengthValidator } from '../../services/validator';
import { Input } from '@hc/component-lib/hclib/components/atoms/input';
import { ValidatorInput } from '../validator';

const Field = ValidatorInput(Input);

/**
 * Standard text field with required validator
 */
export const OrgField = (props : {}) => {
  return <Field validate={[isRequiredValidator, orgLengthValidator]} {...props} />;
};

export default OrgField;
