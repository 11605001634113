// @flow
// TODO: someday, these should come from the backend
export const PROPERTY_TYPES_TO_LABEL = {
  singlefamily: 'Single Family',
  condo: 'Condo',
  multifamily: 'Multi-Family',
  mobile: 'Manufactured/Mobile',
  vacantlot: 'Vacant Lot',
  townhouse: 'Townhouse'
};
