// @flow
import React from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import { ChevronLink } from '@hc/component-lib';

import { type ProductDetailsWithApplicationType } from '../../../../types/products.types.js';

import MenuSection from '../MenuSection';
import MenuItem, { type MenuItemThemeType } from '../MenuItem';
import { withTracker } from '../../Tracker';
import { NAVIGATION_BAR_TYPES, NAVIGATION_BAR_EVENTS } from '../../constants/events.constants';
import { NAV_BAR_HC_NAMES } from '../../hc.names';

import defaultTheme from './AppsMenuContent.scss';

export type AppsMenuContentThemeType = {
  AppsMenuContent: string,
  ViewAllProductsSection: string,
  AppLink: string,
  AppNames: string,
  HintText: string,
  active: string
} & MenuItemThemeType

export type AppsMenuContentProps = {
  className?: string,
  theme: AppsMenuContentThemeType,
  products: ProductDetailsWithApplicationType[],
  platformBaseUrl?: string
}

const ClickableChevronLink = ({ onClick, children, dataHcName, ...props }) => {
  return (
    <span onClick={onClick} data-hc-name={dataHcName}>
      <ChevronLink {...props}>{children}</ChevronLink>
    </span>
  );
};
const ClickableChevronLinkWithTracker = withTracker(ClickableChevronLink);
const ChevronLinkWithTracker = withTracker(ClickableChevronLink);

const AppLink = ({ theme, url, label, hintText, dataHcName }) => {
  return (
    <div className={classNames(theme.AppLink)}>
      <ClickableChevronLinkWithTracker
        dataHcName={dataHcName}
        theme={theme}
        href={url}
        type={NAVIGATION_BAR_TYPES.PRODUCT_LAUNCHER_TYPE}
        event={NAVIGATION_BAR_EVENTS.PRODUCT_CLICK}
        eventData={{ selected_product: label }}>
        <span data-hc-name={dataHcName}>{label}</span>
      </ClickableChevronLinkWithTracker>
      {hintText && <span className={theme.HintText}>{hintText}</span>}
    </div>
  );
};

const AppsMenuContent = (props: AppsMenuContentProps) => {
  const {
    theme,
    products,
    platformBaseUrl
  } = props;

  return (
    <div className={classNames(theme.AppsMenuContent)}>
      <MenuSection theme={theme}>
        {
          products.map(app =>
            <MenuItem
              className={classNames({ [theme.active]: app.active })}
              key={`${app.name}-${app.label}`}
              theme={theme}
              {...app}
              Link={
                <AppLink
                  theme={theme}
                  url={app.url}
                  label={app.label}
                  hintText={app.hintText}
                  dataHcName={app.dataHcName}
                />
              }
            />
          )
        }
      </MenuSection>
      {
        platformBaseUrl &&
          <MenuSection className={theme.ViewAllProductsSection} theme={theme}>
            <ChevronLinkWithTracker
              theme={theme}
              dataHcName={NAV_BAR_HC_NAMES.HC_LAUNCHER_VIEW_ALL}
              href={`${platformBaseUrl}`}
              type={NAVIGATION_BAR_TYPES.PRODUCT_LAUNCHER_TYPE}
              event={NAVIGATION_BAR_EVENTS.VIEW_ALL_PRODUCTS_CLICK}>
              View All Products
            </ChevronLinkWithTracker>
          </MenuSection>
      }
    </div>
  );
};

export default themr('AppsMenuContentTheme', defaultTheme)(AppsMenuContent);
