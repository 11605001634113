// @flow
import type { Action } from 'src/client/actions';

import {
  REVIEW_SHOW_CANCEL_ORDER_DIALOG,
  REVIEW_DISMISS_CANCEL_ORDER_DIALOG,
  REVIEW_ACCEPT_ORDER,
  REVIEW_ACCEPT_ORDER_SUCCESS,
  REVIEW_ACCEPT_ORDER_ERROR,
  REVIEW_CANCEL_ORDER,
  REVIEW_CANCEL_ORDER_SUCCESS,
  REVIEW_CANCEL_ORDER_ERROR
} from 'src/client/actions/order-items.actions';

type ReviewOrderInitState = {
  status: 'init',
  showCancelDialog: boolean
};
type ReviewOrderOrderingState = {
  status: 'ordering' | 'orderSuccess',
  showCancelDialog: false
};
type ReviewOrderErrorState = {
  status: 'orderError',
  errorMessage: string,
  showCancelDialog: false
};
type ReviewOrderCancellingState = {
  status: 'canceling',
  showCancelDialog: true
};
type ReviewOrderCancelErrorState = {
  status: 'cancelError',
  showCancelDialog: true,
  errorMessage: string
};
export type ReviewOrderState =
  | ReviewOrderInitState
  | ReviewOrderOrderingState
  | ReviewOrderErrorState
  | ReviewOrderCancellingState
  | ReviewOrderCancelErrorState;

const defaultState = {
  status: 'init',
  showCancelDialog: false,
  updateReverify: {
    status: 'init'
  }
};

export function reviewOrderReducer(
  state: ReviewOrderState = defaultState,
  action: Action
): ReviewOrderState {
  switch (action.type) {
    case REVIEW_SHOW_CANCEL_ORDER_DIALOG: {
      return {
        status: 'init',
        showCancelDialog: true
      };
    }
    case REVIEW_DISMISS_CANCEL_ORDER_DIALOG: {
      return {
        status: 'init',
        showCancelDialog: false
      };
    }
    case REVIEW_ACCEPT_ORDER: {
      return {
        status: 'ordering',
        showCancelDialog: false
      };
    }
    case REVIEW_ACCEPT_ORDER_SUCCESS: {
      return {
        status: 'orderSuccess',
        showCancelDialog: false
      };
    }
    case REVIEW_ACCEPT_ORDER_ERROR: {
      return {
        status: 'orderError',
        errorMessage: action.payload.errorMessage,
        showCancelDialog: false
      };
    }
    case REVIEW_CANCEL_ORDER: {
      return {
        status: 'canceling',
        showCancelDialog: true
      };
    }
    case REVIEW_CANCEL_ORDER_SUCCESS: {
      return {
        status: 'init',
        showCancelDialog: false
      };
    }
    case REVIEW_CANCEL_ORDER_ERROR: {
      return {
        status: 'cancelError',
        showCancelDialog: true,
        errorMessage: action.payload.errorMessage
      };
    }
    default: {
      return state;
    }
  }
}
