// @flow
import React, { type Node } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import { ChevronLink } from '@hc/component-lib';

import defaultTheme from './Notification.scss';

type NotificationThemeType = {
  Notification: string,
  SeenNotification: string,
  NotificationWrapper: string,
  NotificationIcon: string,
  NotificationContent: string,
  NotificationTitle: string,
  NotificationDetails: string,
  NotificationDescription: string,
  NotificationChevron: string,
  NotificationSeenOn: string,
  ClickableNotification: string
}

type NotificationProps = {
  className?: string,
  theme: NotificationThemeType,
  icon: Node,
  title: string,
  description: string,
  seen: boolean,
  seenOn?: string,
  onClick?: () => void
}

const Notification = (props: NotificationProps) => {
  const {
    className,
    theme,
    icon,
    title,
    description,
    seen,
    seenOn,
    onClick
  } = props;

  return (
    <div
      className={classNames(theme.Notification, className, {
        [theme.SeenNotification]: seen,
        [theme.ClickableNotification]: !!onClick
      })} onClick={onClick}>

      <div className={theme.NotificationWrapper}>
        <div className={theme.NotificationIcon}>
          {icon}
        </div>

        <div className={theme.NotificationContent}>
          <h4 className={theme.NotificationTitle}>{title}</h4>

          <div className={theme.NotificationDescription}>{description}</div>
          {
            seen && seenOn &&
              <div className={theme.NotificationSeenOn}>{seenOn}</div>
          }
        </div>

        {
          onClick &&
            <div className={theme.NotificationChevron}>
              <ChevronLink theme={theme} />
            </div>
        }
      </div>
    </div>
  );
};

export default themr('NotificationThemed', defaultTheme)(Notification);
