import React from 'react';
import classNames from 'classnames';

import { type WorkflowAutoRefreshState } from '../reducers/workflow-auto-refresh.reducer';
import { RTThemedDropdown as Dropdown } from '@hc/component-lib/lib/rt-themed';
import { Checkbox } from '@hc/component-lib/hclib/components/atoms/checkbox';
import { Input } from '@hc/component-lib/hclib/components/atoms/input';

import { formatDistanceToNowWithPastMessageFormatter } from '../../client/helpers/formatter-helpers';

import {
  type WorkflowExternalTask,
  type ExternalTasksFilters
} from '../types/workflow';

import { DataTable } from '@hc/component-lib/hclib/components/organisms/data-table';

import theme from './WorkflowExternalTasks.css';

export type WorkflowExternalTasksProps = {
  filters: ExternalTasksFilters,
  topicSets: {
    [topicSet: string]: string[]
  },
  workers: { label: string, value: string }[],
  tasks: WorkflowExternalTask[],
  autoRefresh: WorkflowAutoRefreshState,
  onSelectTopic: (topicName: string) => void,
  onSelectWorker: (workerId: string) => void,
  onSelectShowErrors: () => void
};

const getColumns = () => [
  {
    label: 'External Task ID',
    attribute: 'id'
  },
  {
    label: 'Activity ID',
    attribute: 'activityId'
  },
  {
    label: 'Topic Name',
    attribute: 'topicName'
  },
  {
    label: 'Error Message',
    attribute: 'errorMessage'
  },
  {
    label: 'Lock Expiration',
    attribute: 'lockExpirationTime',
    formatter: (dateStr: ?string) =>
      dateStr
        ? formatDistanceToNowWithPastMessageFormatter(dateStr, {
            includeSeconds: true
          })
        : ''
  }
];

const renderWorkerItem = (config) => {
  return config.value ? (
    <div>
      {config.label} - {config.value}
    </div>
  ) : (
    <div>{config.label}</div>
  );
};

const renderItem = (config) => {
  return <div>{config.label}</div>;
};

const renderTopicItem = (config) => {
  return config.isSet ? renderTopicSet(config) : renderTopicName(config);
};

const renderTopicSet = (config) => {
  return <div>{config.name}</div>;
};

const renderTopicName = (config) => {
  return <div style={{ paddingLeft: 20 }}>{config.name}</div>;
};

class WorkflowExternalTasks extends React.Component<WorkflowExternalTasksProps> {
  handleSelectShowErrors = () => {
    this.props.onSelectShowErrors(!this.props.filters.showErrors);
  };

  render() {
    const {
      topicSets,
      tasks,
      workers,
      filters,
      autoRefresh,
      onSelectTopic,
      onSelectWorker,
      onSelectShowErrors,
      onDetailClick,
      onRefreshSpanChange,
      onRefreshUnitChange,
      onToggleAutoRefresh
    } = this.props;

    const flatTopicSets = Object.keys(topicSets).reduce(
      (flatTopicSets, topicSet) => {
        flatTopicSets.push({
          isSet: true,
          disabled: true,
          name: topicSet
        });
        topicSets[topicSet].forEach((topicName) =>
          flatTopicSets.push({
            isSet: false,
            name: topicName,
            value: topicName
          })
        );
        return flatTopicSets;
      },
      []
    );

    return (
      <div className={theme.WorkflowExternalTasks}>
        <div className={theme.WorkflowExternalTasksHeader}>
          <h3>Workflow External Tasks</h3>
        </div>
        <div className={theme.WorkflowExternalTasksControls}>
          <div className={theme.WorkflowExternalTasksControlsRow}>
            <div className={theme.Filter}>
              <Dropdown
                theme={theme}
                label="Topics"
                source={flatTopicSets}
                onChange={onSelectTopic}
                template={renderTopicItem}
                value={filters.topicName}
              />
            </div>
            <div className={theme.Filter}>
              <Dropdown
                theme={theme}
                label="Workers"
                source={[{ label: 'None', value: undefined }, ...workers]}
                onChange={onSelectWorker}
                template={renderWorkerItem}
                value={filters.workerId}
              />
            </div>
            <div className={classNames(theme.Filter, theme.ShowErrorsFilter)}>
              <Checkbox
                label="Show Errors"
                onChange={onSelectShowErrors}
                checked={filters.showErrors}
              />
            </div>
          </div>
          <div className={theme.WorkflowExternalTasksControlsRow}>
            <div className={theme.AutoRefreshControls}>
              <div className={theme.Control}>
                <Input
                  label="Refresh Span"
                  type="text"
                  onChange={onRefreshSpanChange}
                  value={autoRefresh.refreshTimeDifference.amount}
                />
              </div>
              <div className={theme.Control}>
                <Dropdown
                  theme={theme}
                  label="Span Unit"
                  source={[
                    { value: 'seconds', label: 'seconds' },
                    { value: 'minutes', label: 'minutes' }
                  ]}
                  onChange={onRefreshUnitChange}
                  template={renderItem}
                  value={autoRefresh.refreshTimeDifference.unit}
                />
              </div>
              <div
                className={classNames(theme.Control, theme.ToggleAutoRefresh)}
              >
                <Checkbox
                  label="Auto Refresh"
                  onChange={onToggleAutoRefresh}
                  checked={autoRefresh.status === 'ENABLED'}
                />
              </div>
            </div>
            <div className={theme.AutoRefreshTimestamp}>
              {autoRefresh.status === 'ENABLED' && autoRefresh.timestamp && (
                <span>
                  Last Updated:{' '}
                  {formatDistanceToNowWithPastMessageFormatter(
                    autoRefresh.timestamp,
                    { includeSeconds: true }
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
        <DataTable
          columns={getColumns(this.props)}
          data={tasks}
          onRowClick={onDetailClick}
        >
          {tasks.length === 0 && (
            <p className={theme.WorkflowExternalTasksEmptyText}>
              There are no external tasks
            </p>
          )}
        </DataTable>
      </div>
    );
  }
}

export default WorkflowExternalTasks;
