// @flow
import React, { type Node } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';

import { AppBar } from 'react-toolbox/lib/app_bar';

import { getAuthClient, AuthApiClient } from '../../clients/auth-api-client.js';
import {
  userHasApplicationPermissions,
  userInTestOrg
} from '../../services/permissions.js';

import NavigationBarTitle from './NavigationBarTitle';
import AppsMenu from './menus/apps-menu/AppsMenu';
import AccountMenu from './menus/account-menu/AccountMenu';
import NotificationsMenu from './menus/notifications-menu/NotificationsMenu';
import { withTrackingData } from './Tracker';
import { withUrls } from './Urls';

import {
  type Role,
  type UserContext,
  type NullUserContext,
  type ProductSlugType,
  type ProductDetailsType,
  type ProductDetailsWithApplicationType
} from '../../types';
import {
  PRODUCTS,
  PLATFORM,
  SUPPORT,
  EHRMANTRAUT,
  BEACON,
  SORTED_PRODUCT_LABELS,
  PRODUCT_SLUGS,
  MARKET_INSIGHTS_PRO,
  MARKET_EXPLORER_LEGACY_ACCESS_PRODUCT,
  LOGIN_SERVICE
} from './constants/products.constants';
import { INTERNAL_PRODUCTS } from './constants/internal-products.constants';
import { getEnvUrl } from './constants/url.constants';

import { ContextProviders } from './context';

import defaultTheme from './NavigationBar.scss';
import { NAV_BAR_HC_NAMES } from './hc.names.js';

export type NavigationBarThemeProps = {
  mobile: string,
  NavigationBar: string,
  NavigationBarContent: string,
  NavContentGrow: string,
  NavigationBarIcons: string,
  NavigationBarWithCustomContent: string
};

export type NavigationBarProps = {
  /** CSS className for parent element */
  className?: string,
  /** Theme for component */
  theme: NavigationBarThemeProps,
  /** UserContext from {ehrmantraut}/login response */
  userContext: UserContext,
  /** When the UserContext get's refreshed on token refresh, send back to the client */
  updateContextCallback: (userContext: UserContext) => void,
  /** Product Slug which is used to lookup label and icon if applicable. */
  productSlug: ProductSlugType,
  /** Event tracking options. Provide options such as tracking url. */
  trackingOptions?: {
    /** Base URL where `/api/v1/track/event` will be appended. */
    trackingBaseUrl?: string,
    /** Absolute url. */
    trackingUrl?: string
  },
  /** Environment flag to determine which urls to default to when `platformBaseUrl` and `supportUrl` are not supplied */
  env?: 'LOCAL' | 'DEV' | 'STAGE' | 'PROD',
  /** Platform Base URL which is used to direct users to edit profile and organization settings. */
  platformBaseUrl?: string,
  /** Support URL. */
  supportUrl?: string,
  /** mobile boolean to apply mobile styling **/
  mobile?: boolean,
  /** Determine if navigation bar should be position `fixed` or `relative` */
  fixed?: boolean,
  /** Render function for custom Account Menu content. */
  renderCustomAccountMenuContent?: () => Node,
  /** Custom component to wrap clickable product name area. */
  ProductClickAreaComponent?: () => Node,
  /** Content to be rendered in the center of navigation bar. */
  children?: Node,
  /** Logout options for custom logout url and handler */
  logoutOptions?: {
    url: string,
    onClick: () => void
  },
  /** Notifications options for drawer toggle callback */
  notificationsOptions?: {
    onDrawerToggle: () => void
  },
  /** Select org options for custom select org url and handler */
  selectOrgOptions?: {
    url: string,
    onClick: () => void
  },
  authClient?: AuthApiClient,
  /** Development prop to not show announcement on init */
  showAnnouncementOnInit?: boolean,
  /** Provide a callback after userContext has been refreshed */
  onRefreshUserContext?: (UserContext | NullUserContext) => void,
  /** Provide a callback for unauthorized response from refresh token call */
  onUnauthorizedResponse?: () => void,
  /** Skip refresh userContext request */
  skipUserContextRefresh?: boolean
};

type NavigationBarState = {
  appUserContext: UserContext | NullUserContext,
  products: any[],
  trackingContext: any,
  urlsContext: {
    platformBaseUrl: string,
    supportUrl: string
  },
  pro:
    | {
        hasAccess: true,
        product: ProductDetailsWithApplicationType
      }
    | {
        hasAccess: false
      }
};

const NotificationsMenuWithTrackingData = withTrackingData(NotificationsMenu);
const NavigationBarTitleWithUrls = withUrls(NavigationBarTitle);

/**
 * Global navigation bar component that displays an app launcher menu and account menu.
 *
 * Both menus are driven by the UserContext which is available via [shared-storage.client](http://authlib.housecanary.net/#sharedstorageclient) or [auth-api.client](http://authlib.housecanary.net/#authapiclient).
 *
 * App launcher menu displays applications a user has access to via subscription or trial.
 *
 * Account menu displays user details such as name and organization name in addition to
 * common links to account pages such as organization settings, edit profile (i.e. Accounts/Platform),
 * support, and logout.
 */
class NavigationBar extends React.Component<
  NavigationBarProps,
  NavigationBarState
> {
  state = {
    appUserContext: {
      applications: [],
      accessible_applications: [],
      current_organization: {},
      user: {},
      validity: {}
    },
    products: [],
    trackingContext: {},
    urlsContext: {
      platformBaseUrl: '',
      supportUrl: ''
    },
    notifications: [],
    pro: {
      hasAccess: false
    }
  };

  static defaultProps = {
    env: 'PROD',
    showAnnouncementOnInit: true,
    userContext: {}
  };

  componentDidMount() {
    const { skipUserContextRefresh, userContext } = this.props;

    // initial state
    skipUserContextRefresh || !this.getIsAuthenticated(userContext)
      ? this.setUserContext()
      : this.refreshUserContext()
          .then((appUserContext) => this.setState({ appUserContext }))
          .then(this.setRestOfState)
          .then(() => {
            if (this.props.onRefreshUserContext) {
              this.props.onRefreshUserContext(this.state.appUserContext);
            }
          });
  }

  componentDidUpdate(prevProps) {
    const { userContext = {} } = this.props;
    const { userContext: prevUserContext = {} } = prevProps;

    const {
      user,
      validity,
      accessible_applications: accessibleApplications,
      current_organization: currentOrganization
    } = userContext;

    const {
      user: prevUser,
      validity: prevValidity,
      accessible_applications: prevAccessibleApplications,
      current_organization: prevCurrentOrganization
    } = prevUserContext;

    const userContextChanged =
      !isEqual(user, prevUser) ||
      !isEqual(validity, prevValidity) ||
      !isEqual(accessibleApplications, prevAccessibleApplications) ||
      !isEqual(currentOrganization, prevCurrentOrganization);

    if (userContextChanged) {
      this.setUserContext();
    }
  }

  setUserContext = () => {
    const { userContext } = this.props;
    this.setState({ appUserContext: userContext }, this.setRestOfState);
  };

  setRestOfState = () => {
    const isAuthenticatedUser = this.getIsAuthenticated(
      this.state.appUserContext
    );

    if (!isAuthenticatedUser) {
      return;
    }

    const products = this.getProducts();
    const trackingContext = this.getTracking();
    const urlsContext = this.getUrls();

    this.setState({
      products,
      trackingContext,
      urlsContext
    });
  };

  getIsAuthenticated = (userContext) => {
    return (
      !!(userContext && userContext.validity && userContext.validity.token) ||
      false
    );
  };

  /**
   * Make an authenticated request to /check-token
   * which will return the latest user context
   */
  refreshUserContext = () => {
    const { env, userContext, onUnauthorizedResponse, updateContextCallback } =
      this.props;
    const apiUrl = getEnvUrl(env, EHRMANTRAUT);
    const loginServiceUrl = getEnvUrl(env, LOGIN_SERVICE);
    const client = getAuthClient(
      loginServiceUrl,
      `${apiUrl}/api/v1/`,
      updateContextCallback
    );
    return client.refreshUserContext(userContext.validity.token).catch((e) => {
      if (e.message === 'Invalid credentials' && onUnauthorizedResponse) {
        onUnauthorizedResponse();
      }
    });
  };

  getHasProSubscription = () => {
    const { accessible_applications: apps = [] } = this.state.appUserContext;
    return !!apps.find((p) => p.name === PRODUCT_SLUGS.MARKET_EXPLORER);
  };

  getHasProAccess = () => {
    const { applications = [] } = this.state.appUserContext;
    return !!applications.find((a) => a === PRODUCT_SLUGS.MARKET_EXPLORER);
  };

  getHasDataExplorerSubscription = () => {
    const { accessible_applications: apps = [] } = this.state.appUserContext;
    return !!apps.find((p) => p.name === PRODUCT_SLUGS.DATA_EXPLORER);
  };

  getHasOmSubscription = () => {
    const { accessible_applications: apps = [] } = this.state.appUserContext;
    return !!apps.find((p) => p.name === PRODUCT_SLUGS.ORDER_MANAGER);
  };

  getHasOmCreatePermissions = () => {
    const { user } = this.state.appUserContext;
    const roles: Role[] = user.roles || [];
    return !!roles.find((r) => r.name === 'OM Create');
  };

  getProducts = () => {
    const { env, productSlug } = this.props;
    const { appUserContext } = this.state;
    try {
      // Add Market Explorer to list of accessible applications for "Legacy Pro" users
      const accessibleApplicationsWithAccessAndPermissions = ((
        accessibleApplications = []
      ) => {
        if (!this.getHasProSubscription() && this.getHasProAccess()) {
          accessibleApplications.push({
            ...MARKET_EXPLORER_LEGACY_ACCESS_PRODUCT,
            url: getEnvUrl(env, MARKET_INSIGHTS_PRO)
          });
        }
        return accessibleApplications;
      })([...appUserContext.accessible_applications]);

      return (
        accessibleApplicationsWithAccessAndPermissions
          // filter out products that a user does not have permissions to
          .filter((app) => {
            if (
              appUserContext.user.id &&
              appUserContext.current_organization.id
            ) {
              const hasPermissions = userHasApplicationPermissions(
                // added check above to ensure valid object is passed
                // $FlowFixMe
                appUserContext,
                app.name
              );
              return hasPermissions;
            }
          })
          // filter out Appraiser
          .filter((app) => app.name !== 'Appraiser')
          .map((accessibleApplication) => {
            const productDetails = this.getProductDetails(
              accessibleApplication.name
            );
            const url = getEnvUrl(env, accessibleApplication.name);

            return {
              active: productDetails.key === productSlug,
              ...accessibleApplication,
              ...productDetails,
              url
            };
          })
          .filter((applicationDetails) => applicationDetails.label)
          .sort(
            (a, b) =>
              SORTED_PRODUCT_LABELS.indexOf(a.label) -
              SORTED_PRODUCT_LABELS.indexOf(b.label)
          )
      );
    } catch (e) {
      console.warn(
        'NavigationBar: UserContext is not in expected format. `accessible_applications` is expected. '
      );
      console.warn(e);
      return [];
    }
  };

  getProductDetails = (
    productLookupKey: ProductSlugType
  ): ProductDetailsType => {
    let details = { ...PRODUCTS, ...INTERNAL_PRODUCTS }[productLookupKey];
    if (!details) {
      console.info(
        `NavigationBar: Could not find product details for product slug: ${productLookupKey}`
      );
      details = {
        key: 'Unknown',
        label: '',
        trackingName: 'unknown',
        icon: <span />,
        dataHcName: ''
      };
    }
    return details;
  };

  getTracking = () => {
    const {
      appUserContext: { user, current_organization: currentOrganization }
    } = this.state;

    const { env, productSlug, trackingOptions = {} } = this.props;

    const productDetails = this.getProductDetails(productSlug);
    const productNames = this.getProducts().map((product) => product.name);
    return {
      data: {
        url: window.location.href,
        app: Array.isArray(productDetails)
          ? productDetails[0].trackingName
          : productDetails.trackingName,
        user,
        accessible_applications: productNames,
        current_organization: currentOrganization
      },
      options: {
        trackerUrl: trackingOptions.trackingUrl,
        trackerBaseUrl:
          trackingOptions.trackingBaseUrl || getEnvUrl(env, BEACON)
      }
    };
  };

  getUrls = () => {
    const { env, platformBaseUrl, supportUrl } = this.props;

    return {
      platformBaseUrl: platformBaseUrl || getEnvUrl(env, PLATFORM),
      supportUrl: supportUrl || getEnvUrl(env, SUPPORT)
    };
  };

  renderChildren = () => {
    const { theme, children } = this.props;
    return (
      children && (
        <div
          data-hc-name={NAV_BAR_HC_NAMES.HC_NAV_BAR_ADDITIONAL}
          className={classNames(theme.NavigationBarContent, {
            [theme.NavContentGrow]: children
          })}
        >
          {children}
        </div>
      )
    );
  };

  renderNavigationBar = (content) => {
    const {
      theme,
      mobile,
      env,
      className,
      productSlug,
      fixed = true,
      ProductClickAreaComponent,
      userContext,
      ...props
    } = this.props;

    const productDetails = this.getProductDetails(productSlug);
    const productDetail = Array.isArray(productDetails)
      ? productDetails[0]
      : productDetails;
    const isUserInTestOrg = userInTestOrg(userContext);

    return (
      <AppBar
        className={classNames(theme.NavigationBar, className, {
          [theme['mobile']]: mobile
        })}
        theme={theme}
        fixed={fixed}
        title={
          <NavigationBarTitleWithUrls
            theme={theme}
            env={env}
            productSlug={productSlug}
            productName={productDetail.title || productDetail.label}
            ProductClickAreaComponent={ProductClickAreaComponent}
            isUserInTestOrg={isUserInTestOrg}
          />
        }
        {...props}
      >
        {this.renderChildren()}
        {content}
      </AppBar>
    );
  };

  render() {
    const {
      env,
      theme,
      mobile,
      renderCustomAccountMenuContent,
      logoutOptions,
      notificationsOptions,
      selectOrgOptions,
      showAnnouncementOnInit
    } = this.props;

    const { appUserContext, trackingContext, urlsContext, products, pro } =
      this.state;

    const isAuthenticatedUser = this.getIsAuthenticated(appUserContext);

    if (!isAuthenticatedUser) {
      return this.renderNavigationBar();
    }

    return (
      <ContextProviders
        appUserContext={appUserContext}
        trackingContext={trackingContext}
        urlsContext={urlsContext}
      >
        {this.renderNavigationBar(
          <div
            data-hc-name={NAV_BAR_HC_NAMES.HC_NAV_BAR_ICONS}
            className={theme.NavigationBarIcons}
          >
            {(products.length > 0 || pro.hasAccess === true) && (
              <AppsMenu theme={theme} pro={pro} products={products} />
            )}

            {
              /** render notifications */
              <NotificationsMenuWithTrackingData
                env={env}
                mobile={mobile}
                notificationsOptions={notificationsOptions}
                showAnnouncementOnInit={showAnnouncementOnInit}
                appUserContext={appUserContext}
                theme={theme}
              />
            }

            {appUserContext.user && (
              <AccountMenu
                theme={theme}
                mobile={mobile}
                platformBaseUrl={this.state.urlsContext.platformBaseUrl}
                userContext={appUserContext}
                logoutOptions={logoutOptions}
                selectOrgOptions={selectOrgOptions}
              >
                {renderCustomAccountMenuContent &&
                  renderCustomAccountMenuContent()}
              </AccountMenu>
            )}
          </div>
        )}
      </ContextProviders>
    );
  }
}

export const NavigationBarThemed = themr(
  'NavigationBarThemed',
  defaultTheme
)(NavigationBar);

export default NavigationBarThemed;
