// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';

import type { Dispatch } from 'redux';
import type { OrderExportsState } from '../reducers/order-exports.reducer';
import type { InOrderItemsState } from '../reducers/in-order-items.reducer';
import type { SelectedOrderState } from '../reducers/selected-order.reducer';
import type { SidePanelState } from '../reducers/side-panel.reducer';
import type { OrderItem } from '../types/order-item';
import type { HistoryState } from '../../reducers/history.reducer';
import type { CancelOrderItemState } from '../reducers/cancel-order-item.reducer';
import type { RevisionRequestState } from '../reducers/revision-request.reducer';
import type { Order } from '../types/order';
import type { State } from '../../reducers';
import type { SelectedOrderOwnerState } from '../reducers/selected-order-owner.reducer';
import type { OrderingParams } from '../../types/list';

import { fetchMessages } from '../actions/order-item-messages.actions';

import {
  downloadOrderItem,
  viewOrderItemReport,
  sortOrderItems,
  searchOrderItems,
  fetchSelectedOrderOwner,
  removeItemsFromOrder
} from 'src/client/actions/order-items.actions';
import Drawer from 'src/components/Drawer.jsx';
import InOrderList from 'src/client/order-items/components/InOrderList';
import ItemDetails from 'src/client/order-items/components/ItemDetails';
import ItemMessagesContainer from 'src/client/order-items/components/ItemMessagesContainer';
import {
  inOrderItemsStateSelector,
  selectedOrderStateSelector,
  headerStatusGroupDisplaySelector,
  type HeaderStatusGroupDisplay,
  selectedOrderOwnerStateSelector
} from 'src/client/selectors/order-items.selectors';
import { downloadOrderSummary } from 'src/client/actions/orders.actions';
import historyStateSelector from 'src/selectors/history-state.selector';
import CancelOrderOrItemDialog from 'src/client/components/CancelOrderOrItemDialog';
import { cancelOrderItemStateSelector } from 'src/client/selectors/cancel-order-item.selectors';
import {
  showCancelOrderItemDialog,
  cancelOrderItem,
  dismissCancelOrderItemDialog
} from 'src/client/actions/cancel-order-item.actions';
import { revisionRequestStateSelector } from 'src/client/selectors/revision-request.selectors';
import {
  showRevisionRequestDialog,
  revisionRequest,
  dismissRevisionRequestDialog
} from 'src/client/actions/revision-request.actions';
import REVISION_REQUEST_FORM_NAME from 'src/client/order-items/components/revision-request/revision-request-form-name';
import RevisionRequestDialog from 'src/client/order-items/components/revision-request/RevisionRequestDialog';
import {
  openOrderItemDetailsSidePanel,
  openMessagesSidePanel,
  openOrderExportsSidePanel,
  closeSidePanel
} from 'src/client/actions/side-panel.actions';
import OrderExports from 'src/client/components/OrderExports';
import { sidePanelStateSelector } from 'src/client/selectors/sidepanel.selectors';
import * as logger from 'src/logger';
import { IN_ORDER_GROUP } from 'src/client/constants/order-items';
import EmptyListPlaceholder from './components/EmptyListPlaceholder';
import {
  buildListLink,
  shouldDisplayList
} from 'src/client/helpers/list-helpers';

import styles from './order-items.css';

type InOrderProps = {
  inOrderItemsState: InOrderItemsState,
  selectedOrderState: SelectedOrderState,
  sidePanelState: SidePanelState,
  onDownloadSummary: () => void,
  onDownloadReports: () => void,
  historyState: HistoryState,
  orderExportsState: OrderExportsState,
  selectedOrderOwnerState: SelectedOrderOwnerState,
  onShowOrderItemDetails: (orderItem: OrderItem) => void,
  onRequestMessages: (orderId: string, orderItemId: string) => void,
  onShowMessages: (orderItem: OrderItem) => void,
  onCloseSidebar: () => void,
  onDownloadSummary: (orderId: string, orderName: string) => void,
  onDownloadOrderItem: (
    orderId: string,
    orderItemId: string,
    address: string
  ) => void,
  onViewOrderItemReport: (
    orderId: string,
    orderItemId: string,
    pdfType: string
  ) => void,
  onSearch: (query: string) => void,
  onSort: (OrderingParams[]) => void,
  cancelOrderItemState: CancelOrderItemState,
  onCancelOrderItem: (string) => void,
  onShowCancelOrderItemDialog: (string, string, string) => void,
  onDismissCancelOrderItemDialog: () => void,
  onShowRevisionRequestDialog: (string, OrderItem) => void,
  onDismissRevisionRequestDialog: () => void,
  onSubmitRevisionRequest: (string, OrderItem, string) => void,
  revisionRequestState: RevisionRequestState,
  headerStatusGroupsDisplay: HeaderStatusGroupDisplay[],
  onShowOrderExports: (order: Order) => void,
  onFetchSelectedOrderOwner: (userId: string, selectedOrderId: string) => void,
  onRemoveItemFromOrder: (orderId: string, orderItemId: string) => void
};

function buildSidebarContents(props: InOrderProps, onCloseSidebar: () => void) {
  const sidePanelState = props.sidePanelState;
  if (!sidePanelState.open) {
    return null;
  }
  const selectedOrderState = props.selectedOrderState;
  const selectedOrderOwnerState = props.selectedOrderOwnerState;
  switch (sidePanelState.contentType) {
    case 'details':
      if (selectedOrderState.status === 'loaded') {
        return (
          <ItemDetails
            orderItem={sidePanelState.orderItem}
            onViewOrderItemReport={(orderItemId, pdfType) =>
              props.onViewOrderItemReport(
                selectedOrderState.order.id,
                orderItemId,
                pdfType
              )
            }
            onDownloadOrderItem={(orderItemId, address) =>
              props.onDownloadOrderItem(
                selectedOrderState.order.id,
                orderItemId,
                address
              )
            }
            selectedOrderTypeDescriptor={
              selectedOrderState.order.orderTypeDescriptor
            }
            selectedOrderOwner={
              selectedOrderOwnerState.status === 'loaded' &&
              selectedOrderOwnerState.selectedOrderId ===
                selectedOrderState.order.id
                ? selectedOrderOwnerState.orderOwner
                : null
            }
            onClose={onCloseSidebar}
          />
        );
      } else {
        logger.logException(
          new Error(
            'ClientOrder_buildSidebarContents orderItem or selectedOrder is not defined'
          )
        );
        return null;
      }
    case 'messages':
      return <ItemMessagesContainer />;
    case 'export':
      return <OrderExports />;
    default:
      return null;
  }
}

class InOrder extends React.Component<InOrderProps> {
  handleShowOrderItemMessages = (orderItem: OrderItem) => {
    if (this.props.selectedOrderState.status === 'loaded') {
      const { id: orderId } = this.props.selectedOrderState.order;
      this.props.onRequestMessages(orderId, orderItem.id);
      this.props.onShowMessages(orderItem);
    } else {
      logger.logException(
        new Error(
          'ClientOrder#handleShowOrderItemMessages selectedOrder is not defined'
        )
      );
    }
  };

  handleShowOrderItemDetails = (orderItem: OrderItem) => {
    if (this.props.selectedOrderState.status === 'loaded') {
      const selectedOrder = this.props.selectedOrderState.order;
      this.props.onFetchSelectedOrderOwner(
        selectedOrder.ownerId,
        selectedOrder.id
      );
      this.props.onShowOrderItemDetails(orderItem);
    } else {
      logger.logException(
        new Error(
          'ClientOrder#handleShowOrderItemDetails selectedOrder is not defined'
        )
      );
    }
  };

  paginationRouteGeneratorFn = (page: ?number): string => {
    const { inOrderItemsState } = this.props;
    const { selectedOrderState } = this.props;
    if (
      inOrderItemsState.status === 'loaded' &&
      selectedOrderState.status === 'loaded'
    ) {
      return buildListLink(
        `/client/order/${selectedOrderState.order.id}/${IN_ORDER_GROUP}`,
        inOrderItemsState.query,
        inOrderItemsState.ordering,
        page
      );
    } else {
      logger.logException(
        new Error(
          'OrderItemsContainer#paginationRouteGeneratorFn selectedOrder is not defined or orderItems not loaded'
        )
      );
      return '';
    }
  };

  componentWillUnmount = () => {
    if (this.props.sidePanelState.open) {
      this.props.onCloseSidebar();
    }
  };

  render() {
    const {
      selectedOrderState,
      inOrderItemsState,
      sidePanelState,
      onCloseSidebar,
      onDownloadOrderItem,
      onViewOrderItemReport,
      onSearch,
      onSort,
      cancelOrderItemState,
      revisionRequestState,
      onCancelOrderItem,
      onShowCancelOrderItemDialog,
      onDismissCancelOrderItemDialog,
      onShowRevisionRequestDialog,
      onDismissRevisionRequestDialog,
      onSubmitRevisionRequest,
      onRemoveItemFromOrder
    } = this.props;
    if (selectedOrderState.status !== 'loaded') {
      // Can happen while the order is being loaded.  Wait until next render to show.
      return <div />;
    }

    return (
      <div className={styles.listContainer}>
        {inOrderItemsState.status === 'error' && (
          <div>{inOrderItemsState.errorMessage}</div>
        )}
        {(inOrderItemsState.status === 'loading' ||
          inOrderItemsState.status === 'not_loaded') && <div>...</div>}
        {inOrderItemsState.status === 'loaded' &&
          shouldDisplayList(
            inOrderItemsState.pageItems,
            inOrderItemsState.query
          ) && (
            <InOrderList
              order={selectedOrderState.order}
              loading={inOrderItemsState.fetching}
              orderItems={inOrderItemsState.pageItems}
              links={inOrderItemsState.links}
              query={inOrderItemsState.query}
              ordering={
                inOrderItemsState.ordering.length > 0
                  ? inOrderItemsState.ordering
                  : inOrderItemsState.defaultOrdering
              }
              paginationRouteGeneratorFn={this.paginationRouteGeneratorFn}
              onShowOrderItemDetails={this.handleShowOrderItemDetails}
              onShowOrderItemMessages={this.handleShowOrderItemMessages}
              onDownloadOrderItem={(orderItemId, address) =>
                onDownloadOrderItem(
                  selectedOrderState.order.id,
                  orderItemId,
                  address
                )
              }
              onViewOrderItemReport={(orderItemId, pdfType) =>
                onViewOrderItemReport(
                  selectedOrderState.order.id,
                  orderItemId,
                  pdfType
                )
              }
              onSearch={onSearch}
              onSort={onSort}
              selectedOrderItem={
                sidePanelState.open &&
                (sidePanelState.contentType === 'details' ||
                  sidePanelState.contentType === 'messages')
                  ? sidePanelState.orderItem
                  : null
              }
              onShowCancelOrderItemDialog={(
                orderItemId: string,
                address: string
              ) =>
                onShowCancelOrderItemDialog(
                  selectedOrderState.order.id,
                  orderItemId,
                  address
                )
              }
              onShowRevisionRequestDialog={(orderItem: OrderItem) =>
                onShowRevisionRequestDialog(
                  selectedOrderState.order.id,
                  orderItem
                )
              }
              selectedOrderTypeDescriptor={
                selectedOrderState.order.orderTypeDescriptor
              }
              onRemoveItemFromOrder={onRemoveItemFromOrder}
            />
          )}
        {inOrderItemsState.status === 'loaded' &&
          !shouldDisplayList(
            inOrderItemsState.pageItems,
            inOrderItemsState.query
          ) && <EmptyListPlaceholder descriptor="No addresses in order" />}
        <Drawer side="right" show={sidePanelState.open}>
          {buildSidebarContents(this.props, onCloseSidebar)}
        </Drawer>
        <CancelOrderOrItemDialog
          type="orderItem"
          orderId={
            cancelOrderItemState.dialogVisible
              ? cancelOrderItemState.orderId
              : null
          }
          orderItemId={
            cancelOrderItemState.dialogVisible
              ? cancelOrderItemState.orderItemId
              : null
          }
          address={
            cancelOrderItemState.dialogVisible
              ? cancelOrderItemState.address
              : null
          }
          show={cancelOrderItemState.dialogVisible}
          onCancel={onCancelOrderItem}
          onDismiss={onDismissCancelOrderItemDialog}
          loading={cancelOrderItemState.status === 'loading'}
          cancelled={cancelOrderItemState.status === 'cancelled'}
          error={
            cancelOrderItemState.status === 'error'
              ? cancelOrderItemState.error
              : null
          }
        />

        <RevisionRequestDialog
          orderId={
            revisionRequestState.dialogVisible
              ? revisionRequestState.orderId
              : null
          }
          orderItem={
            revisionRequestState.dialogVisible
              ? revisionRequestState.orderItem
              : null
          }
          show={revisionRequestState.dialogVisible}
          onSubmit={onSubmitRevisionRequest}
          onDismiss={onDismissRevisionRequestDialog}
          loading={revisionRequestState.status === 'loading'}
          submitted={revisionRequestState.status === 'submitted'}
          errors={
            revisionRequestState.status === 'error'
              ? revisionRequestState.errors
              : null
          }
          onShowMessages={this.handleShowOrderItemMessages}
        />
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    inOrderItemsState: inOrderItemsStateSelector(state),
    selectedOrderState: selectedOrderStateSelector(state),
    sidePanelState: sidePanelStateSelector(state),
    historyState: historyStateSelector(state),
    cancelOrderItemState: cancelOrderItemStateSelector(state),
    revisionRequestState: revisionRequestStateSelector(state),
    headerStatusGroupsDisplay: headerStatusGroupDisplaySelector(state),
    selectedOrderOwnerState: selectedOrderOwnerStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onShowOrderItemDetails: (orderItem: OrderItem) =>
      dispatch(openOrderItemDetailsSidePanel(orderItem)),
    onRequestMessages: (orderId: string, orderItemId: string) =>
      dispatch(fetchMessages(orderId, orderItemId)),
    onCloseSidebar: () => dispatch(closeSidePanel()),
    onDownloadSummary: (orderId: string, orderName: string) =>
      dispatch(downloadOrderSummary(orderId, orderName)),
    onDownloadOrderItem: (
      orderId: string,
      orderItemId: string,
      address: string
    ) => dispatch(downloadOrderItem(orderId, orderItemId, address)),
    onViewOrderItemReport: (
      orderId: string,
      orderItemId: string,
      pdfType: string
    ) => dispatch(viewOrderItemReport(orderId, orderItemId, pdfType)),
    onSearch: (query: string) =>
      dispatch(searchOrderItems(IN_ORDER_GROUP, query)),
    onSort: (ordering: OrderingParams[]) =>
      dispatch(sortOrderItems(IN_ORDER_GROUP, ordering)),
    onShowMessages: (orderItem: OrderItem) =>
      dispatch(openMessagesSidePanel(orderItem)),
    onCancelOrderItem: (orderId: string, orderItemId: string) =>
      dispatch(cancelOrderItem(orderId, orderItemId)),
    onShowCancelOrderItemDialog: (
      orderId: string,
      orderItemId: string,
      address: string
    ) => dispatch(showCancelOrderItemDialog(orderId, orderItemId, address)),
    onDismissCancelOrderItemDialog: () =>
      dispatch(dismissCancelOrderItemDialog()),
    onShowRevisionRequestDialog: (orderId: string, orderItem: OrderItem) =>
      dispatch(showRevisionRequestDialog(orderId, orderItem)),
    onDismissRevisionRequestDialog: () => {
      dispatch(destroy(REVISION_REQUEST_FORM_NAME));
      dispatch(dismissRevisionRequestDialog());
    },
    onSubmitRevisionRequest: (
      orderId: string,
      orderItem: OrderItem,
      explanation: string
    ) => dispatch(revisionRequest(orderId, orderItem, explanation)),
    onShowOrderExports: (order: Order) =>
      dispatch(openOrderExportsSidePanel(order)),
    onFetchSelectedOrderOwner: (userId: string, selectedOrderId: string) =>
      dispatch(fetchSelectedOrderOwner(userId, selectedOrderId)),
    onRemoveItemFromOrder: (orderId: string, orderItemId: string) =>
      dispatch(removeItemsFromOrder(IN_ORDER_GROUP, orderId, [orderItemId]))
  };
}

const InOrderWrapped = connect(mapStateToProps, mapDispatchToProps)(InOrder);
export default InOrderWrapped;
