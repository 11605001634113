// @flow
import React, { type Node } from 'react';
import { themr } from 'react-css-themr';

import {
  RTThemedCard,
  RTThemedCardTitle,
  RTThemedCardText
} from '@hc/component-lib/lib/rt-themed';
import { Button } from '@hc/component-lib';
import { ErrorMessage } from '../../auth-lib/components';

import MessageComposer from 'src/components/message/MessageComposer.jsx';

import defaultTheme from './ValuationRevisionResponse.css';

type ValuationRevisionResponseFormTheme = {
  card: string,
  cardActions: string,
  messageComposer: string,
  submitStatusMessage: string,
  errorMessage: string
};
type ValuationRevisionResponseProps = {
  fullAddress: string,
  message: ?string,
  error: ?string,
  disableActions: Boolean,
  renderSubmitStatusMessage: () => Node,
  onValuationRevisionResponse: ('accept' | 'reject', comment: string) => void,
  theme: ValuationRevisionResponseFormTheme
};

type ValuationRevisionResponseState = {
  comment: ?string
};

class ValuationRevisionResponse extends React.Component<
  ValuationRevisionResponseProps,
  ValuationRevisionResponseState
> {
  state = {
    comment: null
  };

  handleCommentChange = (comment: string) => {
    this.setState({ comment });
  };

  handleRejectValuation = () => {
    this.props.onValuationRevisionResponse('reject', this.state.comment || '');
  };

  handleAcceptValuation = () => {
    this.props.onValuationRevisionResponse('accept', this.state.comment || '');
  };

  render() {
    const {
      fullAddress,
      message,
      error,
      disableActions,
      renderSubmitStatusMessage,
      theme
    } = this.props;

    return (
      <div>
        <RTThemedCard className={theme.card}>
          <RTThemedCardTitle
            title="Revision Requested"
            subtitle="Please respond to this request with any comments"
          />

          <RTThemedCardText>
            {fullAddress ? (
              <p>A revision has been requested for {fullAddress}</p>
            ) : (
              <p>A revision has been requested</p>
            )}

            <p>{message}</p>

            <div className={theme.messageComposer}>
              <MessageComposer
                theme={theme}
                skipOnEnterKeyPress
                onSendMessage={this.handleCommentChange}
                onMessageChange={this.handleCommentChange}
                postMessageError={null}
              />
            </div>
            <div className={theme.submitStatusMessage}>
              {renderSubmitStatusMessage()}
            </div>
            <ErrorMessage value={error} />
          </RTThemedCardText>

          <div className={theme.cardActions}>
            <Button
              primary={false}
              disabled={disableActions}
              onClick={this.handleRejectValuation}
            >
              Reject
            </Button>
            <Button
              disabled={disableActions}
              onClick={this.handleAcceptValuation}
            >
              Accept
            </Button>
          </div>
        </RTThemedCard>
      </div>
    );
  }
}

export default themr(
  'ThemedValuationRevisionResponseForm',
  defaultTheme
)(ValuationRevisionResponse);
