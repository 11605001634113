// @flow
import React, { type Node } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import defaultTheme from './Link.scss';

type ClickableLinkTheme = {
  Link: string
};

type ClickableLinkProps = {
  className?: string,
  theme: ClickableLinkTheme,
  onClick?: (e: window.MouseEvent) => void,
  children: Node,
};

class ClickableLink extends React.Component<ClickableLinkProps> {
  eventTargetKey: 'metaKey' | 'ctrlKey';

  static eventTargetKey = window.navigator.platform.indexOf('Mac') > -1 ? 'metaKey' : 'ctrlKey';

  static defaultProps = {
    onClick: () => {}
  }

  handleClick = (e: window.MosueEvent) => {
    const targetBlank = e[this.eventTargetKey] || e.shiftKey;
    const { onClick } = this.props;
    if (!targetBlank) {
      e.preventDefault();
    }
    if (onClick) {
      onClick();
    }
  }

  render () {
    const {
      className,
      theme,
      children,
      ...props
    } = this.props;
    return (
      <a
        className={classNames(theme.Link, className)}
        {...props}
        onClick={this.handleClick}
      >
        {children}
      </a>
    );
  }
}

export default themr('ClickableLinkTheme', defaultTheme)(ClickableLink);
