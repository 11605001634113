// @flow
import type { Saga } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import store from 'store/dist/store.modern';

import { logoutRequest } from '../actions/admin-user.actions';
import * as logger from '../../logger';
import { showToast } from '../../actions/view.actions';
import { ADMIN_USER_STORE_KEY } from '../constants/user';

export function* handleStandardExceptions(e: any): Saga<void> {
  if (e.name === 'ForbiddenError') {
    yield put(
      showToast(
        'Access to the requested item was denied.  Please contact your administrator to request permission.'
      )
    );
    logger.logWarning(e);
  } else if (e.name === 'LoginRequiredError') {
    yield put(showToast('Session expired. Please login first.'));
    yield put(logoutRequest(window.location.pathname));
  } else {
    yield put(showToast('An error occurred.'));
    logger.logException(e, 'Error in admin handleStandardExceptions');
  }
}

export function redirectToAdminLogin(route: ?string): void {
  let redirect = '/admin/login';
  if (route) {
    redirect += '/' + encodeURIComponent(route);
  }
  window.location.replace(redirect);
}

export function* requireAdminToken(): Saga<?string> {
  const user = yield call([store, store.get], ADMIN_USER_STORE_KEY);
  const token: ?string = user && user.token ? user.token : null;
  // SECURITY: false posititive, no timing attack, as only comparing token to null, and not comparing to a valid token (should be constant time) @john
  // eslint-disable-next-line security/detect-possible-timing-attacks
  if (token == null) {
    yield call(redirectToAdminLogin, window.location.pathname);
  }
  return token;
}
