// @flow
import React from 'react';

import emptyListSvg from './empty_list.svg';

import styles from './EmptyListPlaceholder.css';

type EmptyListPlaceholderProps = {
  descriptor: string
};

const EmptyListPlaceholder = (props: EmptyListPlaceholderProps) => {
  const { descriptor } = props;

  return (
    <div className={styles.placeholderContainer}>
      <div>
        <img src={emptyListSvg} />
        <div className={styles.descriptor}>{descriptor}</div>
      </div>
    </div>
  );
};

export default EmptyListPlaceholder;
