// @flow
import React from 'react';
import type { Node } from 'react';
import { themr } from 'react-css-themr';
import AdminHeader from './AdminHeader';
import { ListTabs, ListTab } from 'src/components/ListTabs';
import WithRouterLink from 'src/history/WithRouterLink';

import {
  RTThemedLayout as Layout,
  RTThemedPanel as Panel,
  RTThemedAppBar as AppBar
} from '@hc/component-lib/lib/rt-themed';

import defaultTheme from './AdminPage.css';
import commonStyles from 'src/containers/common.css';

const AdminListTab = (props) => <ListTab {...props} selectable ripple />;
const RoutedAdminListTab = WithRouterLink(AdminListTab);

type AdminPageProps = {
  dataHcName: string,
  activePage: string,
  sidebar?: Node,
  renderTabs: ((tabConfig: Object) => Node) => Node,
  renderHeader: () => Node,
  renderContent: () => Node,
  theme: {
    appBar: string,
    AdminPage: string,
    AdminBody: string,
    AdminHeaderContainer: string,
    AdminContentContainer: string
  }
};

export const AdminPage = (props: AdminPageProps) => {
  const { theme } = props;
  return (
    <Layout>
      <AppBar fixed className={`${commonStyles.appBar} ${props.theme.appBar}`}>
        <AdminHeader />
      </AppBar>
      <Panel>
        <div className={theme.AdminPage}>
          <ListTabs dataHcName={props.dataHcName}>
            {props.renderTabs &&
              props.renderTabs((tabConfig) => (
                <RoutedAdminListTab {...tabConfig} />
              ))}
          </ListTabs>

          <div className={theme.AdminBody}>
            {props.renderHeader && (
              <div className={theme.AdminHeaderContainer}>
                {props.renderHeader()}
              </div>
            )}
            <div className={theme.AdminContentContainer}>
              {props.renderContent()}
            </div>
          </div>
        </div>
      </Panel>
    </Layout>
  );
};

export default themr('AdminPageThemed', defaultTheme)(AdminPage);
