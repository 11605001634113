export const ADD_ORDER_FORM_NAME: string = 'AddOrderForm';

export const FIELD_NAMES = {
  CUSTOMER_ORDER_ID: 'customerOrderId',
  ORDER_NAME: 'name',
  CLIENT_NAME: 'clientName',
  CLIENT_ADDRESS: 'clientAddress',
  CLIENT_CITY: 'clientCity',
  CLIENT_STATE: 'clientState',
  CLIENT_ZIPCODE: 'clientZipcode',
  LABEL: 'label',
  INTENDED_USE: 'intendedUse',
  LENDER_NAME: 'lenderName',
  LENDER_ADDRESS: 'lenderAddress',
  LENDER_CITY: 'lenderCity',
  LENDER_ZIPCODE: 'lenderZipcode',
  LENDER_STATE: 'lenderState',
  SPECIAL_COMMENTS_INSPECTION: 'specialCommentsInspection',
  SPECIAL_COMMENTS_AMC: 'specialCommentsAmc',
  DELIVERY_SPEED: 'deliverySpeed'
};

export const ITEM_FIELD_NAMES = {
  CUSTOMER_ITEM_ID: 'customerItemId',
  ADDRESS: 'address',
  UNIT: 'unit',
  CITY: 'city',
  STATE: 'state',
  ZIPCODE: 'zipcode',
  COUNTY: 'county',
  PROPERTY_TYPE: 'propertyType',
  SPECIAL_COMMENTS_INSPECTION: 'specialCommentsInspection',
  SPECIAL_COMMENTS_AMC: 'specialCommentsAmc',
  INSPECTION_CONTACT_NAME: 'inspectionContactName',
  INSPECTION_CONTACT_PHONE: 'inspectionContactPhone',
  INSPECTION_CONTACT_PHONE_IS_CELL: 'inspectionContactPhoneIsCell',
  INSPECTION_CONTACT_EMAIL: 'inspectionContactEmail',
  SECONDARY_INSPECTION_CONTACT_NAME: 'secondaryInspectionContactName',
  SECONDARY_INSPECTION_CONTACT_PHONE: 'secondaryInspectionContactPhone',
  SECONDARY_INSPECTION_CONTACT_PHONE_IS_CELL:
    'secondaryInspectionContactPhoneIsCell',
  SECONDARY_INSPECTION_CONTACT_EMAIL: 'secondaryInspectionContactEmail',
  LOAN_VALUE: 'loanValue',
  SUPPORTING_DOCUMENTS: 'supportingDocuments'
};

export const OPT_OUT_STORAGE_KEY = 'addOrderConfirmOptOut';

export const INSPECTION_NAMES = {
  exterior: 'Exterior Only',
  interior_exterior: 'Interior & Exterior',
  desktop: 'Desktop Only'
};

export const AS_REPAIRED_VALUE_NAMES = {
  asIs: 'As-Is Value',
  asRepaired: 'As-Is & As-Repaired Value'
};
