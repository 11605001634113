// @flow
import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
import { getAdminWebsocketTokenExchangePath } from 'src/helpers/url-helpers';

import { type ExternalTaskParams } from '../types/workflow';

export const BASE_CAMUNDA_API_PATH = '/camunda-rest';
export const BASE_ADMIN_API_PATH = `/admin-api/v1`;
export const getTokenAuthUrl = () => `${BASE_ADMIN_API_PATH}/api-token-auth/`;
export const getUserDetailsUrl = () => `${BASE_ADMIN_API_PATH}/user/`;
export const getWorkersUrl = (alive: boolean, aliveWindow: boolean) =>
  `${BASE_ADMIN_API_PATH}/worker?${queryString.stringify({
    alive,
    alive_window: aliveWindow
  })}`;
export const getCamundaExternalTaskUrl = () =>
  `${BASE_CAMUNDA_API_PATH}/external-task`;
export const getCamundaExternalTaskWithParamsUrl = (
  params: ExternalTaskParams
) => `${getCamundaExternalTaskUrl()}?${queryString.stringify(params)}`;
export const getCamundaExternalTaskCountWithParamsUrl = (
  params: ExternalTaskParams
) => `${getCamundaExternalTaskUrl()}/count?${queryString.stringify(params)}`;

var SETTINGS = window.SETTINGS;

export function NotFoundError(message: ?string) {
  this.name = 'NotFoundError';
  this.message = message || 'Object not found';
  this.stack = new Error().stack;
}
NotFoundError.prototype = Object.create(Error.prototype);
NotFoundError.prototype.constructor = NotFoundError;

export function LoginRequiredError(message: ?string) {
  this.name = 'LoginRequiredError';
  this.message = message || 'Login Required';
  this.stack = new Error().stack;
}
LoginRequiredError.prototype = Object.create(Error.prototype);
LoginRequiredError.prototype.constructor = LoginRequiredError;

export function ValidationError(data: { [key: string]: any }) {
  this.name = 'ValidationError';
  this.message = 'Validation error';
  this.data = data;
  this.stack = new Error().stack;
}
ValidationError.prototype = Object.create(Error.prototype);
ValidationError.prototype.constructor = ValidationError;

export function ServerError(message: ?string) {
  this.name = 'ServerError';
  this.message = message || 'Internal server error';
  this.stack = new Error().stack;
}
ServerError.prototype = Object.create(Error.prototype);
ServerError.prototype.constructor = ServerError;

function maybeThrowApiError(response) {
  if (response.status === 401 || response.status === 403) {
    throw new LoginRequiredError();
  } else if (response.status === 404) {
    throw new NotFoundError();
  } else if (response.status === 204 || response.status === 202) {
    return {};
  } else if (response.status !== 200 && response.status !== 201) {
    return response.text().then((text) => {
      throw new ServerError(text);
    });
  }
}

export class Client {
  apiUrl: string;

  constructor() {
    this.apiUrl = SETTINGS.ORDER_MANAGER_API_URL;
  }

  login(username: string, password: string) {
    const url = `${this.apiUrl}${getTokenAuthUrl()}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    }).then((response) => {
      return maybeThrowApiError(response) || response.json();
    });
  }

  userDetails(token: string) {
    const url = `${this.apiUrl}${getUserDetailsUrl()}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${token}`
      }
    }).then((response) => {
      return maybeThrowApiError(response) || response.json();
    });
  }

  websocketTokenExchange(token: string) {
    const url = `${this.apiUrl}${getAdminWebsocketTokenExchangePath()}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => maybeThrowApiError(response) || response.json());
  }

  async getReportData(dataUrl: string) {
    const response = await fetch(dataUrl);
    return maybeThrowApiError(response) || response.json();
  }

  getWorkers(
    token: string,
    alive: boolean = true,
    aliveWindow: boolean = true
  ) {
    const url = `${this.apiUrl}${getWorkersUrl(alive, aliveWindow)}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return maybeThrowApiError(response) || response.json();
    });
  }

  getExternalTasksCount(token: string, params: ExternalTaskParams) {
    const url = `${this.apiUrl}${getCamundaExternalTaskCountWithParamsUrl(
      params
    )}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return maybeThrowApiError(response) || response.json();
    });
  }

  getExternalTasks(token: string, params: ExternalTaskParams) {
    const url = `${this.apiUrl}${getCamundaExternalTaskWithParamsUrl(params)}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return maybeThrowApiError(response) || response.json();
    });
  }
}
