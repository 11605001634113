// @flow
import { ORDERS_PARTIAL_LOAD_SUCCESS } from '../actions/orders.actions';
import {
  SELECT_ORDER,
  REVIEW_ACCEPT_ORDER_SUCCESS,
  CLEAR_ORDER_ITEMS
} from '../actions/order-items.actions';
import { WEBSOCKET_ORDER_UPDATED } from '../actions/websocket.actions';

import type { Action } from '../actions';
import type { Order } from '../types/order';

type SelectedOrderEmptyState = {
  status: 'empty'
};
type SelectedOrderLoadedState = {
  status: 'loaded',
  order: Order
};
export type SelectedOrderState =
  | SelectedOrderEmptyState
  | SelectedOrderLoadedState;

const defaultState = {
  status: 'empty'
};

export function selectedOrderReducer(
  state: SelectedOrderState = defaultState,
  action: Action
): SelectedOrderState {
  switch (action.type) {
    case SELECT_ORDER: {
      return {
        status: 'loaded',
        order: action.payload.order
      };
    }
    case REVIEW_ACCEPT_ORDER_SUCCESS: {
      return {
        status: 'loaded',
        order: action.payload.order
      };
    }
    case ORDERS_PARTIAL_LOAD_SUCCESS: {
      if (state.status === 'loaded') {
        const currentSelectedOrder: Order = state.order;
        const updatedSelectedOrder: ?Order = action.payload.orders.find(
          (order: Order) => order.id === currentSelectedOrder.id
        );
        if (updatedSelectedOrder) {
          return {
            status: 'loaded',
            order: updatedSelectedOrder
          };
        }
      }
      return state;
    }
    case WEBSOCKET_ORDER_UPDATED: {
      const { orderId, status, cancelled } = action.payload;
      if (state.status === 'loaded' && orderId === state.order.id) {
        return {
          status: 'loaded',
          order: {
            ...state.order,
            status,
            cancelled
          }
        };
      }
      return state;
    }
    case CLEAR_ORDER_ITEMS: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
}
