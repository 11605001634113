// @flow
import * as React from 'react';
import {
  RTThemedCard,
  RTThemedCardText,
  RTThemedCardTitle
} from '@hc/component-lib/lib/rt-themed';

type ReviewValuationErrorProps = {
  message: string
};

class ReviewValuationError extends React.Component<
  ReviewValuationErrorProps,
  void
> {
  render() {
    const { message } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <RTThemedCard
          raised
          style={{ width: '25%', margin: 'auto', minWidth: '600px' }}
        >
          <RTThemedCardTitle title="Error Loading Order Comments" />
          <RTThemedCardText>{message}</RTThemedCardText>
        </RTThemedCard>
      </div>
    );
  }
}

export default ReviewValuationError;
