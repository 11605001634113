import React from 'react';
import { ChevronLink } from '@hc/component-lib';
import loginLinkTheme from './LoginLink.scss';
import { themr } from 'react-css-themr';

type Props = {
  href: string,
  theme: {},
  handleClick: ({}) => void
};

const ChevronLinkComponent = ({ theme, href }) => (
  <ChevronLink theme={theme} href={href}>
    Log in
  </ChevronLink>
);

export const LoginLink = ({ theme, href, handleClick, ...props }: Props) => {
  return (
    <div className={theme.LoginLink} {...props}>
      Already have an account?&nbsp;
      {handleClick ? (
        <span onClick={handleClick}>
          <ChevronLinkComponent theme={theme} href={href} />
        </span>
      ) : (
        <ChevronLinkComponent theme={theme} href={href} />
      )}
    </div>
  );
};

export const LoginLinkThemed = themr('LoginLink', loginLinkTheme)(LoginLink);

export default LoginLinkThemed;
