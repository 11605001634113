// @flow
import type { Action } from 'src/actions';
import type { ViewTypes, Toast } from 'src/types/view';

import {
  VIEW_LOADING,
  VIEW_LOADED,
  SHOW_TOAST,
  HIDE_TOAST
} from 'src/actions/view.actions';

export type ViewState = {
  previousView: ViewTypes,
  currentView: ViewTypes,
  loading: boolean,
  toast: Toast
};

const defaultState = {
  previousView: 'home',
  currentView: 'home',
  loading: true,
  toast: { visible: false }
};

export function viewReducer(
  previousState: ViewState = defaultState,
  action: Action
): ViewState {
  switch (action.type) {
    case VIEW_LOADING: {
      return {
        ...previousState,
        previousView: previousState.currentView,
        currentView: action.payload.view,
        loading: true
      };
    }
    case VIEW_LOADED: {
      return {
        ...previousState,
        previousView: previousState.previousView,
        currentView: action.payload.view,
        loading: false
      };
    }
    case SHOW_TOAST: {
      return {
        ...previousState,
        toast: { visible: true, message: action.payload.message }
      };
    }
    case HIDE_TOAST: {
      return {
        ...previousState,
        toast: { visible: false }
      };
    }
    default: {
      return previousState;
    }
  }
}
