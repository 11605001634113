/**
 * NOTE: type identifiers are messed up in flow.
 * React docgen expects 3 types, flow only supports 2
 * Once that is resolved, enable flow
 */
import React, { cloneElement, Component } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import { ChevronLink } from '@hc/component-lib';
import { ErrorMessage } from '../error-message';
import { EmailField } from '../email-field';
import { FormSubmit } from '../form-submit';
import { SignUpLink } from '../sign-up-link';
import { Row } from '../row';

import defaultTheme from './ForgotPassword.scss';

type ForgotPasswordFormTheme = {
  ForgotPasswordForm: string,
  ForgotPasswordTitle: string
}

type Props = {
  title: ?string,
  /** Email Component */
  emailField: ?any,
  /** Optional content to render */
  content: ?any,
  /** Allows consuming app to notify form when in a loading state */
  isSubmitting: boolean,
  /** Submit handler */
  onSubmit: string => void,
  /** URL to the login page */
  loginUrl?: string,
  /** Optional Enterprise link url when used with `openEnterpriseLinkForSignUp` */
  enterpriseLinkUrl: ?string,
  /** Leverage corp site contact us page for enterprise manual sign ups */
  openEnterpriseLinkForSignUp: ?boolean,
  /** URL to the sign up page */
  signupUrl?: string,
  /** Focus on the email field when the page loads */
  focusEmailFieldOnMount: boolean,
  /** React Css Themr theme */
  theme: ForgotPasswordFormTheme
}

type State = {
  email: string,
  validEmail: boolean
}

export class ForgotPassword extends Component <Props, State, void> {
  state = {
    email: '',
    validEmail: false
  }

  static defaultProps = {
    title: null,
    isSubmitting: false,
    content: null,
    emailField: (<EmailField label='Email' />),
    onSubmit: () => {},
    focusEmailFieldOnMount: true
  }

  constructor (props) {
    super(props);
    this.state.email = props.emailField.props.value || '';
  }

  componentDidUpdate (prevProps, prevState) {
    const emailFromProps = this.props.emailField.props.value;
    if (prevProps.emailField.props.value !== emailFromProps && prevState.email !== emailFromProps) {
      this.setState({ email: emailFromProps });
    }
  }

  handleChange = (e) => {
    this.setState({ email: e.target.value });
  }

  handleValid = () => {
    this.setState({ validEmail: true });
  }

  handleInvalid = () => {
    this.setState({ validEmail: false });
  }

  submitForm = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.state.email);
  }

  handleEnterpriseClick = () => {
    window.open(this.props.enterpriseLinkUrl || 'https://www.housecanary.com/contact-sales?target=sales');
  }

  render () {
    const {
      className,
      title,
      emailField,
      content,
      errorMessage,
      isSubmitting,
      signupUrl,
      openEnterpriseLinkForSignUp,
      loginUrl,
      focusEmailFieldOnMount,
      theme
    } = this.props;

    const {
      email,
      validEmail
    } = this.state;

    const combinedClassName = className ? classNames(theme.ForgotPasswordForm, className) : theme.ForgotPasswordForm;

    return (
      <div className={combinedClassName}>
        {title && <h2 className={theme.ForgotPasswordTitle}>{title}</h2>}
        <form onSubmit={this.submitForm}>
          {content}
          {
            cloneElement(
              emailField, {
                'data-hc-name': 'email-field',
                name: 'email',
                value: email,
                onChange: this.handleChange,
                onValid: this.handleValid,
                onInvalid: this.handleInvalid,
                autoFocus: focusEmailFieldOnMount
              })
          }
          {errorMessage && <ErrorMessage theme={theme} value={errorMessage} />}
          <Row center>
            <FormSubmit
              data-hc-name='submit-button'
              theme={theme}
              disabled={isSubmitting || !validEmail}
              label={isSubmitting ? 'Submitting' : 'Submit'}
              onSubmit={this.submitForm} />
          </Row>
          {loginUrl &&
            <Row center>
              <ChevronLink href={loginUrl}>Log in</ChevronLink>
            </Row>
          }
          {(signupUrl || openEnterpriseLinkForSignUp) &&
            <Row center>
              <SignUpLink
                url={signupUrl}
                handleClick={openEnterpriseLinkForSignUp ? this.handleEnterpriseClick : undefined}
                theme={theme} />
            </Row>
          }
        </form>
      </div>
    );
  }
}

export default themr('ForgotPasswordThemed', defaultTheme)(ForgotPassword);
