// @flow
import React from 'react';
import {
  RTThemedCard,
  RTThemedCardText
} from '@hc/component-lib/lib/rt-themed';

type OrderErrorProps = {
  message: string
};

const OrderError = ({ message }: OrderErrorProps) => (
  <RTThemedCard raised>
    <RTThemedCardText>{message}</RTThemedCardText>
  </RTThemedCard>
);

export default OrderError;
