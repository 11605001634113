// @flow
import React from 'react';
import { connect } from 'react-redux';

import type { ReportSummary as ReportSummaryType } from 'src/client/types/report-summary';
import type { UserDetailsState } from 'src/reducers/user-details.reducer';
import type { Dispatch } from 'redux';
import type { ReportSummaryOrderItemsState } from 'src/client/reducers/report-summary-order-items.reducer';
import type { State } from 'src/reducers';

import {
  reportSummarySelectFilter,
  reportSummaryUnselectFilter
} from 'src/client/actions/report-summary.actions';

import {
  transformedReportSummarySelector,
  reportSummaryAvailableFilters,
  reportSummarySelectedFilters,
  reportSummaryOrderItemsStateSelector
} from 'src/client/selectors/report-summary.selectors';
import { userDetailsStateSelector } from 'src/selectors/user-state.selector';

import { HcHeader } from 'src/components/HcHeader';
import ReportSummary from './components/ReportSummary';
import ReportSummaryFilters from './components/ReportSummaryFilters';

import styles from './ReportSummaryContainer.css';
import commonStyles from 'src/containers/common.css';

type ReportSummaryContainerProps = {
  userDetailsState: UserDetailsState,
  reportSummary: ReportSummaryType,
  selectedFilters: string[],
  availableFilters: string[],
  orderItemsState: ReportSummaryOrderItemsState,
  onSelectFilter: (f: ?string) => void,
  onUnselectFilter: (f: string) => void,
  onExpandOrder: (string) => void,
  onCollapseOrder: (string) => void
};

class ReportSummaryContainer extends React.PureComponent<
  ReportSummaryContainerProps,
  void
> {
  render() {
    const {
      userDetailsState,
      reportSummary,
      availableFilters,
      selectedFilters,
      onSelectFilter,
      onUnselectFilter
    } = this.props;

    return (
      <div>
        <HcHeader
          userDetails={
            userDetailsState.status === 'loaded'
              ? userDetailsState.userDetails
              : null
          }
          userContext={
            userDetailsState.status === 'loaded'
              ? userDetailsState.userContext
              : null
          }
        />
        <div className={commonStyles.containerContentWithHcHeader}>
          <div className={styles.clientHeader}>
            <h1>Report Summary</h1>
          </div>

          <div className={styles.container}>
            <ReportSummaryFilters
              selectedFilters={selectedFilters}
              filters={availableFilters}
              onSelectFilter={onSelectFilter}
              onUnselectFilter={onUnselectFilter}
            />
          </div>

          <div className={styles.container}>
            <ReportSummary reportSummary={reportSummary} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    reportSummary: transformedReportSummarySelector(state),
    selectedFilters: reportSummarySelectedFilters(state),
    availableFilters: reportSummaryAvailableFilters(state),
    userDetailsState: userDetailsStateSelector(state),
    orderItemsState: reportSummaryOrderItemsStateSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<*>) => {
  return {
    onSelectFilter: (filter: ?string) =>
      dispatch(reportSummarySelectFilter(filter)),
    onUnselectFilter: (filter: string) =>
      dispatch(reportSummaryUnselectFilter(filter))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportSummaryContainer);
