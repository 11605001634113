// @flow
// $FlowFixMe
import React, { useState } from 'react';
import { themr } from 'react-css-themr';
import { Button } from '@hc/component-lib';
import {
  RTThemedMenu as Menu,
  RTThemedMenuItem as MenuItem,
  RTThemedMenuDivider as MenuDivider
} from '@hc/component-lib/lib/rt-themed';

import defaultTheme from './ActionsMenu.css';

import type { Node } from 'react';

type ActionItemProps = {
  label: string
};
export const ActionItem = ({ label, ...props }: ActionItemProps) => (
  <MenuItem theme={defaultTheme} caption={label} {...props} />
);

export const ActionDivider = (props: {}) => (
  <MenuDivider theme={defaultTheme} {...props} />
);

type ActionMenuProps = {
  theme: {
    ActionsMenu: string,
    ActionsMenuWrapper: string,
    button: string
  },
  position:
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'auto'
    | 'static',
  dataHcName?: string,
  label: string,
  disabled: boolean,
  children: Node
};

const StateFulActionsMenu = ({
  theme,
  label,
  dataHcName,
  disabled,
  position,
  children
}: ActionMenuProps) => {
  const [isActive, updateState] = useState(false);
  const handleClick = () => {
    updateState(!isActive);
  };
  const handleHide = () => updateState(false);
  return (
    <div className={theme.ActionsMenu}>
      <Button
        icon="expand_more"
        label={label}
        dataHcName={dataHcName}
        raised
        primary={false}
        disabled={disabled}
        theme={theme}
        onClick={handleClick}
      />
      <div className={theme.ActionsMenuWrapper}>
        <Menu
          theme={theme}
          position={position}
          active={isActive}
          onHide={handleHide}
        >
          {children}
        </Menu>
      </div>
    </div>
  );
};
StateFulActionsMenu.defaultProps = {
  position: 'topRight',
  disabled: false
};

export const ActionsMenu = themr(
  'ThemedActionMenu',
  defaultTheme
)(StateFulActionsMenu);
