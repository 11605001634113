// @flow
import React from 'react';

export class FileSelectButton extends React.Component<any> {
  props: any;

  handleInputChange(e: any) {
    const { input, handleBulkClick } = this.props;
    const files = [...e.target.files];
    if (files && files.length) {
      input.onChange(files[0]);
    } else {
      input.onChange(null);
    }
    handleBulkClick();
  }

  render() {
    const { input, meta, theme = {}, className } = this.props;
    const { error, touched } = meta;
    const { value } = input;
    const errorDisplay = error && touched && (
      <div className={theme.error}>{error}</div>
    );
    const fileName = value && value.name;
    return (
      <div>
        <input
          type="file"
          onChange={(e) => this.handleInputChange(e)}
          className={className}
        />
        <div>
          {fileName}
          {errorDisplay}
        </div>
      </div>
    );
  }
}
