// @flow
import queryString from 'query-string';
import base64 from 'base-64';
import HttpStatus from 'http-status-codes';
import { Factory } from '../services/factory';
import { getAllTrackingValues } from '../services/tracking';
import { type Query, translateQuery } from '../services/query';
import { type UserContext } from '../types';

import type {
  User,
  InvitatedUser,
  Validity,
  Permission,
  Principal,
  Organization,
  ComponentAccess
} from '../types';

// maintain backwards compatibility
export type GetCurrentOrganizationResponse = Organization;

type PaginatedResponse = {
  num_results: number,
  page: number,
  total_pages: number
};

export type GetUsersResponse = PaginatedResponse & {
  objects: User[]
};

export type OrderByFieldConfigType = {
  field: string,
  direction: 'ASC' | 'DESC'
};

export type GetOrgUsersParams = {
  search?: string,
  orderBy?: OrderByFieldConfigType[]
};

export type OrgUserObjectResponse = {
  user_id: ?number,
  invitation_id: ?number,
  organization_id: number,
  accepted: ?boolean,
  email: string,
  first_name: string,
  last_name: string,
  last_logged_in_on: string,
  role_ids: 1 | 2 | 3
};

export type GetOrgUsersResponse = {
  total_results: number,
  page: number,
  total_pages: number,
  objects: OrgUserObjectResponse[]
};

export type GetInvitationsResponse = PaginatedResponse & {
  objects: InvitatedUser[]
};

export type Credentials = {
  username: string,
  password: string
};

export type CreateUserPayload = {
  first_name: string,
  last_name: string,
  email: string,
  password: string
};

export type UpdateUserPayload = {
  first_name: string,
  last_name: string,
  city: string,
  state: string,
  zip_code: string
};

export type AuthOptions = {
  headers?: {
    'HC-Auth-Token'?: string,
    'Content-Type'?: string
  },
  method?: string
};

export type CreateUserDataPayload = CreateUserPayload & {
  application: string,
  tracking: {}
};

export type ForgotPasswordDataPayload = {
  email: string,
  confirm_url?: string,
  reset_password_url?: string,
  token_expiration?: number,
  email_params?: {
    template: string,
    company_logo_url: string,
    from_name?: string
  }
};

export type VerifyEmailDataPayload = {
  email: string,
  [ key: string ]: string
};

export type InviteUserPayload = {
  first_name: string,
  last_name: string,
  email: string,
  organization_id: number,
  applications?: {
    application_id: number,
    state: 'deny'
  }[],
  roles?: {
    id: number
  }[],
  email_params?: {
    template: string,
    company_logo_url: string,
    register_url?: string,
    from_name?: string,
    subject?: string
  },
  send_email: boolean,
  company_name?: string
};

// note that there's no point in adding an application with state:'allow' as applications are allowed by default
// adding in state: 'allow' as legacy
export type EditUserPayload = {
  id: number,
  first_name: string,
  last_name: string,
  applications?: {
    application_id: number,
    state: 'allow' | 'deny'
  }[],
  role_assignments?: {
    role_id: number
  }[]
};

export type GetMyPermissionsResponse = Permission[];

export type GetUserPermissionsResponse = {
  acl: Permission[],
  principal: Principal
}[];

export type EditUserPermissionsPayload = {
  resource_id: string,
  resource_type: string,
  permissions: string[]
}[];

const DefaultHeaders: AuthOptions = {
  headers: {
    'Content-Type': 'application/json'
  },
  method: 'POST'
};

// need this because of this issue https://github.com/babel/babel/issues/4485
function ExtendableBuiltin (cls: any) {
  function ExtendableBuiltin () {
    cls.apply(this, arguments);
  }

  ExtendableBuiltin.prototype = Object.create(cls.prototype);
  Object.setPrototypeOf(ExtendableBuiltin, cls);

  return ExtendableBuiltin;
}

// $FlowFixMe
export class ServerError extends ExtendableBuiltin(Error) {
  constructor (message: ?string, ...params: any) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }
    this.name = 'ServerError';
    this.message = message || 'Internal server error';
    this.stack = new Error().stack;
  }
}

// $FlowFixMe
export class ServerErrorResponse extends ExtendableBuiltin(Error) {
  response: window.Response;

  constructor (message: ?string, response: window.Response, ...params: any) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }
    this.name = 'ServerErrorResponse';
    this.message = message || 'Internal server error';
    this.response = response;
    this.stack = new Error().stack;
  }
}

export const USER_ROLE_ID = 2;
export const USER_ROLE_NAME = 'User';
export const POWER_USER_ROLE_ID = 3;
export const POWER_USER_ROLE_NAME = 'Power User';
export const ADMINISTRATOR_ROLE_NAME = 'Administrator';

export const INVITE_EMAIL_TEMPLATE_NAME = 'custom_invitation';
export const RESET_PASSWORD_EMAIL_TEMPLATE_NAME = 'custom_reset_password';

export const ORGANIZATION_USER_PRINCIPAL = 'OrganizationUserPrincipal';

/**
 * Authentication API Client
 * Service to make authentication calls against ehrmantraut
 */
export class AuthApiClient {
  apiUrl: string;
  authUrl: string;
  loginServiceUrl: string; // New auth service endpoints
  adminAuthUrl: string;
  aclUrl: string;
  updateContextCallback: (userContext: UserContext) => Promise<void> | void;
  aclAdminUrl: string;

  constructor (loginServiceUrl: string, apiUrl: string, updateContextCallback: (userContext: UserContext) => void) {
    this.loginServiceUrl = loginServiceUrl;
    this.apiUrl = apiUrl;
    this.updateContextCallback = updateContextCallback;
    this.authUrl = `${apiUrl}auth/`;
    this.adminAuthUrl = `${apiUrl}admin/`;
    this.aclUrl = `${apiUrl}acl/`;
    this.aclAdminUrl = `${apiUrl}acl_admin/`;
  }

  processServerError (error: Error) { // Will be of type ServerErrorResponse if it is an instance of it
    if (error instanceof ServerErrorResponse) {
      const contentType = error.response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return error.response.json().then((response) => {
          throw response;
        });
      } else {
        return error.response.text().then((response) => {
          throw new Error(response);
        });
      }
    } else {
      throw error;
    }
  }

  /**
   * Make Authentication request
   * @param {String} path - Path to call
   * @param {*} data - http body
   * @param {Object} options - Authentication options
   * @return {Promise}
   */
  async makeAuthRequest (
    host: string,
    path: string,
    data: any,
    options: AuthOptions = DefaultHeaders,
    retries: number = 0
  ): Promise<*> {
    let fetchOptions = data
      ? { ...options, body: JSON.stringify(data) }
      : options;
    if (fetchOptions.method === 'GET') {
      const noCacheHeader = { Pragma: 'no-cache' };
      fetchOptions.headers = fetchOptions.headers
        ? { ...fetchOptions.headers, ...noCacheHeader }
        : noCacheHeader;
    }

    try {
      const response = await window.fetch(`${host}${path}`, fetchOptions);
      if (!response.ok) {
        throw new ServerErrorResponse(null, response);
      }
      const contentType = response.headers.get('content-type');
      return (contentType && contentType.includes('application/json')) ? response.json() : response.text();
    } catch (error) {
      const ignoredPaths = ['login', 'saml2-idp-post-login', 'access-token'];
      if (retries < 1 && !ignoredPaths.includes(path) && error.response.status === HttpStatus.UNAUTHORIZED) { // If it's a validated call that is not refreshing token, check if we need to update
        // We need to remake the call after refreshing token
        try {
          const userContext = await this.checkOrRefreshToken();
          await this.updateContextCallback(userContext);
          if (options.headers) {
            const headers = options.headers;
            if (headers && headers['HC-Auth-Token'] !== undefined) {
              headers['HC-Auth-Token'] = userContext.validity.token;
              options.headers = headers;
            }
          }
          return this.makeAuthRequest(host, path, data, options, retries + 1);
        } catch (newError) { // Could not refresh token so throw the orig error
          return this.processServerError(newError);
        }
      } else {
        return this.processServerError(error);
      }
    }
  }

  /**
   * Check user invitation code
   * @param {String} code invitation code
   * @return {Promise}
   */
  checkInvitationCode (code: string): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(
        this.apiUrl,
        `invitation/code/${code}`,
        null,
        { method: 'GET' }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error ? error.message : 'Could not check invitation code'
            )
          )
        );
    });
  }

  /**
   * Adds user to org
   * @param {String} code invitation code
   * @param {String} email user's email
   * @return {Promise}
   */
  joinOrganization (code: string, email: string): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.authUrl, `register`, { code, email })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error ? error.message : 'Could not add user to organization'
            )
          )
        );
    });
  }

  /**
   * Creates user in accounts
   * @param {Object} user
   * @param {String} confirmUrl
   * @param {String} application
   * @param {Object} params extra params to be included in request such as company name and code which are optional
   * @return {Promise}
   */
  createUser (
    user: CreateUserPayload,
    confirmUrl: string,
    application: string = '',
    params?: { [ key: string ]: string }
  ): Promise<*> {
    return new Promise((resolve, reject) => {
      const data: CreateUserDataPayload = {
        ...user,
        ...params,
        application,
        confirm_url: confirmUrl,
        tracking: getAllTrackingValues()
      };
      return this.makeAuthRequest(this.authUrl, 'register', data)
        .then((response) => resolve(response))
        .catch((error) => {
          const message = (() => {
            try {
              return error.errors.password.join(', ');
            } catch (err) {
              return (error && error.message) || 'Failed to create user';
            }
          })();
          return reject(new ServerError(message));
        });
    });
  }

  /**
   * For user to update their own account details
   * @param {Object} userInfo
   * @param {Object} validity
   * @return {Promise}
   */
  updateUserInfo (userInfo: UpdateUserPayload, validity: Validity): Promise<*> {
    return new Promise((resolve, reject) => {
      const data: UpdateUserPayload = {
        ...userInfo,
        tracking: getAllTrackingValues()
      };
      return this.makeAuthRequest(this.authUrl, 'contact-info', data, {
        headers: { 'HC-Auth-Token': validity.token },
        method: 'POST'
      })
        .then((response) => resolve(response))
        .catch((error) => {
          const message = (() => {
            return (
              (error && error.message) ||
              'Unable to save changes. Please try again.'
            );
          })();
          return reject(new ServerError(message));
        });
    });
  }

  /**
   * Confirm new user email with token
   * @param {string} confirmToken - token to verify
   * @return {Promise}
   */
  confirmUserEmail (confirmToken: string): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(
        this.authUrl,
        `confirm/${confirmToken}`,
        null,
        { method: 'GET' }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(error ? error.message : 'Could not confirm user')
          )
        );
    });
  }

  /**
   * Resend confirm user email
   *  @param {string} token - user token
   */
  resendConfirmationEmail (token: string): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.authUrl, `reconfirm/${token}`, null, {
        method: 'GET'
      })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error ? error.message : 'Could not resend user confirmation'
            )
          )
        );
    });
  }

  /**
   * Check if user email exists or is valid
   * Return promise which resolves to true if the email is valid, throws error otherwise
   * @param {string} email - email to verify
   * @return {Promise}
   */
  checkEmail (email: string, params?: { [ key: string ]: string }): Promise<*> {
    return new Promise((resolve, reject) => {
      const data: VerifyEmailDataPayload = {
        email,
        ...params
      };
      return this.makeAuthRequest(this.authUrl, 'check_email', data)
        .then((response) => resolve(true))
        .catch((error) => {
          const message = (() => {
            try {
              return error.errors.email.join(', ');
            } catch (err) {
              return error && error.message
                ? error.message === 'The user has already been registered'
                  ? 'This email address is not valid'
                  : error.message
                : 'Could not verify email';
            }
          })();
          return reject(new ServerError(message));
        });
    });
  }

  /**
   * Login user and return a combined api response from check-token and accountDetails
   * @param {Object} creds - User object to store
   * @param {String} applicationName - app we are logging into
   */
  login (creds: Credentials): Promise<*> {
    return new Promise((resolve, reject) => {
      const headers = new global.Headers();
      headers.set(
        'Authorization',
        'Basic ' + base64.encode(creds.username + ':' + creds.password)
      );

      return this.makeAuthRequest(this.loginServiceUrl, 'login', null, {
        method: 'POST',
        credentials: 'include',
        headers: headers
      }).then((response) => resolve(response))
        .catch((error) => {
          const { message, ...errorProps } = error;
          const errorMessage = error
            ? error.locked_out === true
              ? 'Your account is locked. Check your email to reset your password.'
              : message
            : `Could not login user ${creds.username}`;
          reject(new ServerError(errorMessage, errorProps));
        });
    });
  }

  /* Combine check token api call with account details */
  async checkOrRefreshToken (token?: string): Promise<*> {
    if (token !== undefined) {
      // This returns the full userContext
      return this.makeAuthRequest(this.loginServiceUrl, 'validate', null, {
        headers: { 'HC-Auth-Token': token },
        method: 'GET'
      });
    } else {
      // This returns the full userContext
      return this.makeAuthRequest(this.loginServiceUrl, 'access-token', null, {
        method: 'POST',
        credentials: 'include'
      });
    }
  }

  async fetchAccountDetails (token: string): Promise<*> {
    return this.checkOrRefreshToken(token);
  }

  samlLogin (token: string): Promise<*> {
    return new Promise((resolve, reject) => {
      const data = {
        token
      };
      return this.makeAuthRequest(this.authUrl, 'saml2-idp-post-login', data, {
        method: 'POST'
      })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(error ? error.message : 'Could not login user')
          )
        );
    });
  }

  /**
   * Forgot Password
   * @param {ForgotPasswordDataPayload} : payload
   type ForgotPasswordDataPayload = {
      email: string,
      confirm_url?: string,
      reset_password_url?: string,
      token_expiration?: number,
      email_params?: {
        template: string,
        company_logo_url: string,
        from_name?: string
      }
    }
   * @returns {Promise<Object>}
   */
  forgotPassword (data: ForgotPasswordDataPayload): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.authUrl, 'forgot-password', data, {
        method: 'POST'
      })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(error ? error.message : 'Failed to reset password')
          )
        );
    });
  }

  /**
   * Verify reset password token
   * @param {String} resetPasswordToken - token used to validate password should be reset
   */
  verifyResetPasswordToken (resetPasswordToken: string): Promise<*> {
    const data = { token: resetPasswordToken };
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(
        this.authUrl,
        'check-reset-password-token',
        data,
        {
          method: 'POST'
        }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error
                ? error.message
                : 'Error response from check-reset-password-token'
            )
          )
        );
    });
  }

  /**
   * Reset password
   * @param {String} resetPasswordToken
   * @param {String} newPassword
   */
  resetPassword (resetPasswordToken: string, newPassword: string): Promise<*> {
    const data = { token: resetPasswordToken, new_password: newPassword };
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(
        this.authUrl,
        'confirm-reset-password',
        data,
        {
          method: 'POST'
        }
      )
        .then((response) => resolve(response))
        .catch((error) => {
          let message;
          if (error) {
            if (error.errors && 'new_password' in error.errors) {
              message = error.errors[ 'new_password' ].join(' ');
            } else {
              message = error.message;
            }
          }
          return reject(new ServerError(message || 'Could not reset password'));
        });
    });
  }

  /**
   * Logout user
   * @param {String} token - User token
   */
  logout (): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.loginServiceUrl, 'logout', null, {
        method: 'POST',
        credentials: 'include'
      })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(new ServerError(error ? error.message : 'Error on logout'))
        );
    });
  }

  /**
   * Select org
   * @param {String} token - User token
   * @param {Number} organizationId - id for organization
   */
  selectOrganization (token: string, organizationId: number): Promise<*> {
    const data = {
      organization_id: organizationId
    };
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.loginServiceUrl, 'select-org', data, {
        headers: { 'HC-Auth-Token': token, 'content-type': 'application/json' },
        credentials: 'include',
        method: 'POST'
      })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error ? error.message : 'Error selecting organization'
            )
          )
        );
    });
  }

  /**
   * Get users
   * @param {String} token - User token
   * @param {Query} query - Users query
   * type Query = {
    limit: number,
    page: number,
    order: string,
    filter: {
      [key: string]: {
        op: 'eq' | 'neq' | 'like' | 'ilike' | 'has' | 'any',
        type: 'string' | 'int' | null,
        val: string | number
      }
    }
   */
  getUsers (token: string, query: Query): Promise<GetUsersResponse> {
    return new Promise((resolve, reject) => {
      const tQuery = translateQuery(query);
      return this.makeAuthRequest(
        this.adminAuthUrl,
        `user?${queryString.stringify(tQuery)}`,
        null,
        {
          headers: { 'HC-Auth-Token': token },
          method: 'GET'
        }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(error ? error.message : 'Error retrieving users')
          )
        );
    });
  }

  /**
   * Get user
   * @param {String} token - User token
   * @param {Number} userId - id for user
   */
  getUser (token: string, id: number): Promise<GetUsersResponse> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.adminAuthUrl, `user/${id}`, null, {
        headers: { 'HC-Auth-Token': token },
        method: 'GET'
      })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(error ? error.message : 'Error retrieving user')
          )
        );
    });
  }

  /**
   * Fetch active and invited users within authenticated user's organization
   * @param {String} token auth token
   * @param {Number} page page number
   * @param {Number} itemsPerPage items to limit per page
   * @param {GetOrgUsersParams} params optional object with search and order by params
   */
  getOrgUsers (
    token: string,
    page: number,
    itemsPerPage: number,
    params: GetOrgUsersParams = {}
  ): Promise<GetOrgUsersResponse> {
    const query = {
      search: params.search,
      order_by: params.orderBy,
      page,
      items_per_page: itemsPerPage
    };
    return new Promise((resolve, reject) => {
      const urlSafeParams = Object.keys(query).reduce((urlSafeParams, key) => {
        if (
          query.hasOwnProperty(key) &&
          query[ key ] !== undefined &&
          query[ key ] !== null
        ) {
          const val = query[ key ];
          urlSafeParams[ key ] =
            typeof val === 'string' ? val : JSON.stringify(val);
        }
        return urlSafeParams;
      }, {});
      return this.makeAuthRequest(
        this.apiUrl,
        `org_users?${queryString.stringify(urlSafeParams)}`,
        null,
        {
          headers: { 'HC-Auth-Token': token },
          method: 'GET'
        }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error ? error.message : 'Error retrieving org users'
            )
          )
        );
    });
  }

  /**
   * Get invitations
   * @param {String} token - User token
   * @param {Query} query - Invitations query
   * type Query = {
    limit: number,
    page: number,
    order: string,
    filter: {
      [key: string]: {
        op: 'eq' | 'neq' | 'like' | 'ilike' | 'has' | 'any',
        type: 'string' | 'int' | null,
        val: string | number
      }
    }
   */
  getInvitations (token: string, query: Query): Promise<GetInvitationsResponse> {
    return new Promise((resolve, reject) => {
      const tQuery = translateQuery(query);
      return this.makeAuthRequest(
        this.adminAuthUrl,
        `invitation?${queryString.stringify(tQuery)}`,
        null,
        {
          headers: { 'HC-Auth-Token': token },
          method: 'GET'
        }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error ? error.message : 'Error retrieving invitations'
            )
          )
        );
    });
  }

  /**
   * Invite User
   * @param {String} token - User token
   * @param {InviteUserPayload} inviteUserPayload - payload for new invite
   * type InviteUserPayload = {
      firstName: string,
      lastName: string,
      email: string,
      organizationId: number,
      isAdmin?: boolean,
      applications?: {
        applicationId: number,
        state: 'deny'
      }[],
      roles?: {
        id: number
      }[]
    }
   */
  inviteUser (token: string, inviteUserPayload: InviteUserPayload): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(
        this.apiUrl,
        `invitation`,
        inviteUserPayload,
        {
          headers: {
            'HC-Auth-Token': token,
            'Content-Type': 'application/json'
          },
          method: 'POST'
        }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(error ? error.message : 'Error sending invitation')
          )
        );
    });
  }

  /**
   * Edit User: For admin to edit user details
   * @param {String} token - User token
   * @param {EditUserPayload} editUserPayload - payload to edit user
   * type EditUserPayload = {
      first_name: string,
      last_name: string,
      applications?: {
        applicationId: number,
        state: 'deny'
      }[],
      roles_assignments?: {
        role_id: number
      }[]
    }
   */
  editUser (token: string, editUserPayload: EditUserPayload): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(
        this.adminAuthUrl,
        `user/${editUserPayload.id}`,
        editUserPayload,
        {
          headers: {
            'HC-Auth-Token': token,
            'Content-Type': 'application/json'
          },
          method: 'PUT'
        }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(new ServerError(error ? error.message : 'Error editing user'))
        );
    });
  }

  /**
   * Delete user
   * @param {String} token - User token
   * @param {Number} userId - id for user
   */
  deleteUser (token: string, userId: number): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.adminAuthUrl, `user/${userId}`, null, {
        headers: { 'HC-Auth-Token': token },
        method: 'DELETE'
      })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(new ServerError(error ? error.message : 'Error deleting user'))
        );
    });
  }

  /**
   * Delete invitation
   * @param {String} token - User token
   * @param {Number} invitationId - id for invitation
   */
  deleteInvitation (token: string, invitationId: number): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(
        this.adminAuthUrl,
        `invitation/${invitationId}`,
        null,
        {
          headers: { 'HC-Auth-Token': token },
          method: 'DELETE'
        }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(error ? error.message : 'Error deleting invitation')
          )
        );
    });
  }

  /**
   * Resend invitation
   * @param {String} token - User token
   * @param {String} invitationId - id for invitation
   */
  resendInvitation (token: string, invitationId: string): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(
        this.apiUrl,
        'invitation/send-email',
        { invitationId: invitationId, emailType: 'invitation' },
        {
          headers: {
            'HC-Auth-Token': token,
            'Content-Type': 'application/json'
          },
          method: 'POST'
        }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error ? error.message : 'Error re-sending invitation'
            )
          )
        );
    });
  }

  /**
   * Get current organization details
   * @param {String} token - User token
   */
  getCurrentOrganization (token: string): Promise<Organization> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.authUrl, 'current-organization', null, {
        headers: { 'HC-Auth-Token': token },
        method: 'GET'
      })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error ? error.message : 'Error retrieving organization details'
            )
          )
        );
    });
  }

  /**
   * Get current user's permissions
   * @param {String} token - User token
   */
  getMyPermissions (token: string): Promise<GetMyPermissionsResponse> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.aclUrl, 'my_permissions', null, {
        headers: { 'HC-Auth-Token': token },
        method: 'GET'
      })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error
                ? error.message
                : 'Error retrieving current user\'s permissions'
            )
          )
        );
    });
  }

  checkComponentAccess (
    token: string,
    component: string
  ): Promise<ComponentAccess | ServerError> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(
        this.aclUrl,
        'component/check-my-access',
        null,
        {
          headers: { 'HC-AUTH-Token': token },
          method: 'GET'
        }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error
                ? error.message
                : `Error checking access for component: ${component}`
            )
          )
        );
    });
  }

  /**
   * Get a user's permissions
   * @param {String} token - User token
   * @param {Number} userId - User Id
   */
  getUserPermissions (
    token: string,
    userId: number
  ): Promise<GetUserPermissionsResponse> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.aclAdminUrl, `${userId}`, null, {
        headers: { 'HC-Auth-Token': token },
        method: 'GET'
      })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error
                ? error.message
                : 'Error retrieving current user\'s permissions'
            )
          )
        );
    });
  }

  /**
   * Edit user's permissions
   * @param {String} token - User token
   * @param {Number} userId - User Id
   * @param {JSON} payload edit permissions payload
   */
  editUserPermissions (
    token: string,
    userId: number,
    editUserPermissionsPayload: EditUserPermissionsPayload
  ): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(
        this.aclAdminUrl,
        `${userId}`,
        editUserPermissionsPayload,
        {
          headers: {
            'HC-Auth-Token': token,
            'Content-Type': 'application/json'
          },
          method: 'PUT'
        }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error ? error.message : 'Error editing user permissions'
            )
          )
        );
    });
  }

  /**
   * After making updates to a user, such as adding a trial,
   * consuming applications can use this endpoint to get an updated user context.
   * @param {String} token
   * @example <caption>Fetch User Context</caption>
   * const { SubHeading } = require('@hc/component-lib');
   * const apiUrl = 'https://api-dev.housecanary.net/api/v1/auth/';
   * const token = 'secret_token';
   *
   *
   * <SubHeading>Click Code below</SubHeading>
   */
  async getUserContext (token: string): Promise<*> {
    try {
      return this.checkOrRefreshToken(token);
    } catch (error) {
      throw new ServerError(
        error ? error.message : 'Error response from getUserContext',
        error
      );
    }
  }

  // Wrapper for check token with catch response for legacy clients
  async refreshUserContext (token?: string): Promise<*> {
    try {
      return this.checkOrRefreshToken(token);
    } catch (error) {
      throw new ServerError(
        error ? error.message : 'Error response from refreshUserContext',
        error
      );
    }
  }

  getMessages (token: string, includeSeenMessage: boolean = true): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(
        this.apiUrl,
        // eslint-disable-next-line
        `message?${queryString.stringify({ all: true })}`,
        null,
        {
          headers: { 'HC-Auth-Token': token },
          method: 'GET'
        }
      )
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerErrorResponse(
              error ? error.message : 'Error response from getMessages'
            )
          )
        );
    });
  }

  setMessageAsRead (token: string, messageId: number): Promise<*> {
    return new Promise((resolve, reject) => {
      return this.makeAuthRequest(this.apiUrl, `message/${messageId}`, null, {
        headers: { 'HC-Auth-Token': token },
        method: 'POST'
      })
        .then((response) => resolve(response))
        .catch((error) =>
          reject(
            new ServerError(
              error ? error.message : 'Error response from updateMessageAsRead'
            )
          )
        );
    });
  }
}

/**
 * Factory function, returns Client instance
 * @param {string} apiUrl
 * @param {string} hubUrl
 * @param {string} application
 * @return {Client}
 */
export const getAuthClient = (loginServiceUrl: string, apiUrl: string, updateContextCallback: (userContext: UserContext) => void) =>
  Factory.get('AuthApiClient', AuthApiClient, loginServiceUrl, apiUrl, updateContextCallback);
