// @flow
import {
  OPEN_ORDER_ITEM_DETAILS_SIDE_PANEL,
  OPEN_ORDER_ITEM_MESSAGES_SIDE_PANEL,
  OPEN_ORDER_EXPORTS_SIDE_PANEL,
  CLOSE_SIDE_PANEL
} from 'src/client/actions/side-panel.actions';

import type { Action } from 'src/client/actions';
import type { OrderItem } from 'src/client/types/order-item';
import type { Order } from 'src/client/types/order';

export type SidePanelContentType = 'details' | 'messages' | 'export';
export type SidePanelState =
  | {
      open: true,
      contentType: 'details' | 'messages',
      orderItem: OrderItem
    }
  | {
      contentType: 'none',
      open: false
    }
  | {
      open: true,
      contentType: 'export',
      order: Order
    };

const defaultState = {
  open: false,
  contentType: 'none'
};

export function sidePanelReducer(
  state: SidePanelState = defaultState,
  action: Action
): SidePanelState {
  switch (action.type) {
    case OPEN_ORDER_ITEM_DETAILS_SIDE_PANEL: {
      return {
        open: true,
        contentType: 'details',
        orderItem: action.payload.orderItem
      };
    }
    case OPEN_ORDER_ITEM_MESSAGES_SIDE_PANEL: {
      return {
        open: true,
        contentType: 'messages',
        orderItem: action.payload.orderItem
      };
    }
    case OPEN_ORDER_EXPORTS_SIDE_PANEL: {
      return {
        open: true,
        contentType: 'export',
        order: action.payload.order
      };
    }
    case CLOSE_SIDE_PANEL: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
}
