import React from 'react';
import { type MapStateToProps, connect } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import type { State } from 'src/reducers';
import type {
  FieldMetaByOrderType,
  FieldMeta,
  ChoicesByOrderType
} from '../../../types/add-order';
import type { PropertyType } from '../../../types/order';
import { Button } from '@hc/component-lib/lib';
import type { FieldProps } from 'redux-form';
import classNames from 'classnames';

import AddressAutoComplete from '../../../components/AddressAutoComplete';
import {
  itemFieldMetaByOrderTypeSelector,
  choicesByOrderTypeSelector
} from '../../../selectors/add-order.selectors';
import FormDropdown from '../../../components/FormDropdown';
import FormInput from '../../../components/FormInput';
import {
  ADD_ORDER_FORM_NAME,
  ITEM_FIELD_NAMES
} from '../../../constants/add-order';
import requiredValidator from '../../../components/required-validator';
import passthroughValidator from '../../../components/passthrough-validator';
import {
  formatAddress,
  validateAddress
} from '../../../helpers/address-autocomplete';
import FormCheckbox from '../../../components/FormCheckbox';
import FormTextarea from '../../../components/FormTextarea';
import { SupportingDocumentsFields } from './SupportingDocumentsFields';

import styles from './add-order-form.css';

const SETTINGS = window.SETTINGS;
export const ADDRESS_KEY = 'address';

type AddressFieldsProps = {
  itemFieldMeta: FieldMeta,
  propertyTypeChoices: PropertyType[],
  errorMessage: ?string,
  nextDisabled: boolean,
  fields: FieldProps,
  meta: any,
  showSubmit?: boolean
};
type AddressFieldsState = {
  hasSecondaryContact: {
    [index: number]: boolean
  }
};

const StyledAddressAutoComplete = (props: any) => {
  return (
    <AddressAutoComplete
      placeholder="Address"
      label="Address"
      onSearch={() => 'search'}
      required
      {...props}
    />
  );
};

class AddressFields extends React.Component<
  AddressFieldsProps,
  AddressFieldsState
> {
  state = {
    hasSecondaryContact: {}
  };

  addAddress = () => {
    this.props.fields.push();
  };

  removeAddress = (idx) => () => {
    this.props.fields.remove(idx);
  };

  getAddressStr(address) {
    return address.address
      ? `${address.address}, ${address.city}, ${address.state}`
      : null;
  }

  toggleHasSecondaryContact = (index: number) => () => {
    const updatedHasSecondaryContact = this.state.hasSecondaryContact;
    updatedHasSecondaryContact[index] = !updatedHasSecondaryContact[index];
    this.setState({ hasSecondaryContact: updatedHasSecondaryContact });
  };

  render() {
    const {
      fields,
      meta: { error, dirty },
      itemFieldMeta,
      propertyTypeChoices,
      nextDisabled,
      showSubmit
    } = this.props;
    return (
      <div>
        {fields.map((addressField, index) => {
          const isFirstAddress = index === 0;
          return (
            <div key={addressField} data-hc-name="address-section">
              {fields.length > 1 && (
                <div
                  className={styles.addressDivider}
                  data-hc-name="address-divider"
                >
                  <div className={styles.addressDividerNum}>{index + 1}</div>
                </div>
              )}
              <Field
                dataHcName="address-input"
                name={`${addressField}.address`}
                type="text"
                format={formatAddress}
                validate={validateAddress}
                component={StyledAddressAutoComplete}
                label="Address"
                theme={styles}
                required
                className={styles.formInput}
              />
              {(itemFieldMeta[ITEM_FIELD_NAMES.CUSTOMER_ITEM_ID].shown ||
                itemFieldMeta[ITEM_FIELD_NAMES.LOAN_VALUE].shown) && (
                <div className={styles.inputColumn}>
                  {itemFieldMeta[ITEM_FIELD_NAMES.CUSTOMER_ITEM_ID].shown && (
                    <Field
                      dataHcName="client-file-id-input"
                      name={`${addressField}.${ITEM_FIELD_NAMES.CUSTOMER_ITEM_ID}`}
                      required={
                        itemFieldMeta[ITEM_FIELD_NAMES.CUSTOMER_ITEM_ID]
                          .required
                      }
                      validate={
                        itemFieldMeta[ITEM_FIELD_NAMES.CUSTOMER_ITEM_ID]
                          .required
                          ? requiredValidator
                          : passthroughValidator
                      }
                      component={FormInput}
                      className={styles.formInput}
                      type="text"
                      label="Client File ID"
                      theme={styles}
                    />
                  )}
                  {itemFieldMeta[ITEM_FIELD_NAMES.LOAN_VALUE].shown && (
                    <Field
                      dataHcName="loan-id-input"
                      name={`${addressField}.${ITEM_FIELD_NAMES.LOAN_VALUE}`}
                      required={
                        itemFieldMeta[ITEM_FIELD_NAMES.LOAN_VALUE].required
                      }
                      validate={
                        itemFieldMeta[ITEM_FIELD_NAMES.LOAN_VALUE].required
                          ? requiredValidator
                          : passthroughValidator
                      }
                      component={FormInput}
                      className={styles.formInput}
                      type="number"
                      label="Loan Value"
                      theme={styles}
                    />
                  )}
                </div>
              )}
              <div className={styles.inputColumn}>
                {itemFieldMeta[ITEM_FIELD_NAMES.PROPERTY_TYPE].shown && (
                  <Field
                    dataHcName="property-type-dropdown"
                    name={`${addressField}.${ITEM_FIELD_NAMES.PROPERTY_TYPE}`}
                    required={
                      itemFieldMeta[ITEM_FIELD_NAMES.PROPERTY_TYPE].required
                    }
                    validate={
                      itemFieldMeta[ITEM_FIELD_NAMES.PROPERTY_TYPE].required
                        ? requiredValidator
                        : passthroughValidator
                    }
                    component={FormDropdown}
                    auto={false}
                    label="Property Type"
                    options={propertyTypeChoices}
                    className={styles.formInput}
                  />
                )}
                {itemFieldMeta[ITEM_FIELD_NAMES.COUNTY].shown && (
                  <Field
                    dataHcName="county-input"
                    name={`${addressField}.${ITEM_FIELD_NAMES.COUNTY}`}
                    required={itemFieldMeta[ITEM_FIELD_NAMES.COUNTY].required}
                    validate={
                      itemFieldMeta[ITEM_FIELD_NAMES.COUNTY].required
                        ? requiredValidator
                        : passthroughValidator
                    }
                    component={FormInput}
                    className={styles.formInput}
                    type="text"
                    label="County"
                    theme={styles}
                  />
                )}
              </div>
              {itemFieldMeta[ITEM_FIELD_NAMES.INSPECTION_CONTACT_NAME]
                .shown && (
                <div>
                  <div className={styles.inputColumn}>
                    <Field
                      dataHcName="contact-full-name-input"
                      name={`${addressField}.${ITEM_FIELD_NAMES.INSPECTION_CONTACT_NAME}`}
                      required={
                        itemFieldMeta[ITEM_FIELD_NAMES.INSPECTION_CONTACT_NAME]
                          .required
                      }
                      validate={
                        itemFieldMeta[ITEM_FIELD_NAMES.INSPECTION_CONTACT_NAME]
                          .required
                          ? requiredValidator
                          : passthroughValidator
                      }
                      component={FormInput}
                      type="text"
                      label="Contact Full Name"
                      theme={styles}
                      className={styles.formInput}
                    />
                    <Field
                      dataHcName="contact-phone-input"
                      name={`${addressField}.${ITEM_FIELD_NAMES.INSPECTION_CONTACT_PHONE}`}
                      required={
                        itemFieldMeta[ITEM_FIELD_NAMES.INSPECTION_CONTACT_PHONE]
                          .required
                      }
                      validate={
                        itemFieldMeta[ITEM_FIELD_NAMES.INSPECTION_CONTACT_PHONE]
                          .required
                          ? requiredValidator
                          : passthroughValidator
                      }
                      component={FormInput}
                      type="text"
                      label="Contact Phone"
                      theme={styles}
                      className={styles.formInput}
                    />
                  </div>
                  <div className={styles.checkboxContainer}>
                    <Field
                      data-hc-name="contact-can-receive-text-messages-checkbox"
                      className={styles.checkbox}
                      name={ITEM_FIELD_NAMES.INSPECTION_CONTACT_PHONE_IS_CELL}
                      component={FormCheckbox}
                      type="checkbox"
                      label="Contact Can Receive Text Messages"
                    />
                  </div>
                  <Field
                    dataHcName="contact-email-input"
                    name={`${addressField}.${ITEM_FIELD_NAMES.INSPECTION_CONTACT_EMAIL}`}
                    required={
                      itemFieldMeta[ITEM_FIELD_NAMES.INSPECTION_CONTACT_EMAIL]
                        .required
                    }
                    validate={
                      itemFieldMeta[ITEM_FIELD_NAMES.INSPECTION_CONTACT_EMAIL]
                        .required
                        ? requiredValidator
                        : passthroughValidator
                    }
                    component={FormInput}
                    type="text"
                    label="Contact Email"
                    theme={styles}
                    className={styles.formInput}
                  />
                  <div
                    className={`${styles.checkboxContainer} ${styles.above}`}
                  >
                    <Field
                      data-hc-name="add-secondary-contact-checkbox"
                      className={styles.checkbox}
                      name={`hasSecondaryContact_${index}`}
                      component={FormCheckbox}
                      onChange={this.toggleHasSecondaryContact(index)}
                      type="checkbox"
                      label="Add Secondary Contact"
                    />
                  </div>
                  {this.state.hasSecondaryContact[index] &&
                    itemFieldMeta[
                      ITEM_FIELD_NAMES.SECONDARY_INSPECTION_CONTACT_NAME
                    ].shown && (
                      <div>
                        <div className={styles.inputColumn}>
                          <Field
                            dataHcName="secondary-contact-full-name-input"
                            name={`${addressField}.${ITEM_FIELD_NAMES.SECONDARY_INSPECTION_CONTACT_NAME}`}
                            required={
                              itemFieldMeta[
                                ITEM_FIELD_NAMES
                                  .SECONDARY_INSPECTION_CONTACT_NAME
                              ].required
                            }
                            validate={
                              itemFieldMeta[
                                ITEM_FIELD_NAMES
                                  .SECONDARY_INSPECTION_CONTACT_NAME
                              ].required
                                ? requiredValidator
                                : passthroughValidator
                            }
                            component={FormInput}
                            type="text"
                            label="Secondary Contact Full Name"
                            theme={styles}
                            className={styles.formInput}
                          />
                          <Field
                            dataHcName="secondary-contact-phone-input"
                            name={`${addressField}.${ITEM_FIELD_NAMES.SECONDARY_INSPECTION_CONTACT_PHONE}`}
                            required={
                              itemFieldMeta[
                                ITEM_FIELD_NAMES
                                  .SECONDARY_INSPECTION_CONTACT_PHONE
                              ].required
                            }
                            validate={
                              itemFieldMeta[
                                ITEM_FIELD_NAMES
                                  .SECONDARY_INSPECTION_CONTACT_PHONE
                              ].required
                                ? requiredValidator
                                : passthroughValidator
                            }
                            component={FormInput}
                            type="text"
                            label="Secondary Contact Phone"
                            theme={styles}
                            className={styles.formInput}
                          />
                        </div>
                        <div className={styles.checkboxContainer}>
                          <Field
                            data-hc-name="secondary-inspection-contact-can-reveive-text-messages-checkbox"
                            className={classNames(
                              styles.checkbox,
                              styles.formInput
                            )}
                            name={
                              ITEM_FIELD_NAMES.SECONDARY_INSPECTION_CONTACT_PHONE_IS_CELL
                            }
                            component={FormCheckbox}
                            type="checkbox"
                            label="Secondary Inspection Contact Can Receive Text Messages"
                            theme={styles}
                          />
                        </div>
                        <Field
                          dataHcName="secondary-contact-email-input"
                          name={`${addressField}.${ITEM_FIELD_NAMES.SECONDARY_INSPECTION_CONTACT_EMAIL}`}
                          required={
                            itemFieldMeta[
                              ITEM_FIELD_NAMES
                                .SECONDARY_INSPECTION_CONTACT_EMAIL
                            ].required
                          }
                          validate={
                            itemFieldMeta[
                              ITEM_FIELD_NAMES
                                .SECONDARY_INSPECTION_CONTACT_EMAIL
                            ].required
                              ? requiredValidator
                              : passthroughValidator
                          }
                          component={FormInput}
                          type="text"
                          label="Secondary Contact Email"
                          theme={styles}
                          className={styles.formInput}
                        />
                      </div>
                    )}
                </div>
              )}
              {itemFieldMeta[ITEM_FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION]
                .shown && (
                <Field
                  dataHcName="comments-for-inspector-input"
                  name={`${addressField}.${ITEM_FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION}`}
                  required={
                    itemFieldMeta[ITEM_FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION]
                      .required
                  }
                  validate={
                    itemFieldMeta[ITEM_FIELD_NAMES.SPECIAL_COMMENTS_INSPECTION]
                      .required
                      ? requiredValidator
                      : passthroughValidator
                  }
                  component={FormTextarea}
                  type="text"
                  label="Comments for Inspector"
                  theme={styles}
                  props={{ multiline: 'true' }}
                />
              )}
              {SETTINGS.ENABLE_SUPPORTING_DOCUMENTS &&
                itemFieldMeta[ITEM_FIELD_NAMES.SUPPORTING_DOCUMENTS].shown && (
                  <Field
                    dataHcName="address-supporting-documents"
                    name={`${addressField}.${ITEM_FIELD_NAMES.SUPPORTING_DOCUMENTS}`}
                    component={SupportingDocumentsFields}
                  />
                )}
              {!isFirstAddress && (
                <button
                  data-hc-name="remove-address-button"
                  className={styles.removeItemButton}
                  onClick={this.removeAddress(index)}
                >
                  X Remove Address
                </button>
              )}
            </div>
          );
        })}
        <div className={styles.addressButtonsContainer}>
          <Button
            dataHcName="add-address-button"
            primary={false}
            onClick={this.addAddress}
            disabled={error || nextDisabled}
          >
            Add Address
          </Button>
          {showSubmit ? (
            <Button
              dataHcName="submit-button"
              type="submit"
              disabled={nextDisabled}
            >
              Submit Order
            </Button>
          ) : (
            <Button
              dataHcName="continue-button"
              type="submit"
              disabled={nextDisabled}
            >
              Continue
            </Button>
          )}
        </div>
        {dirty && error && <p>{error}</p>}
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<*, *, *> = (state: State) => {
  const selector = getFormValues(ADD_ORDER_FORM_NAME);
  const formValues = selector(state);
  const currentOrderType = formValues.orderType;
  const itemFieldMetaByOrderType: FieldMetaByOrderType =
    itemFieldMetaByOrderTypeSelector(state);
  const itemFieldMeta = currentOrderType
    ? itemFieldMetaByOrderType[currentOrderType]
    : itemFieldMetaByOrderType.default;
  const choicesByOrderType: ChoicesByOrderType =
    choicesByOrderTypeSelector(state);
  const propertyTypeChoices: PropertyType[] = currentOrderType
    ? choicesByOrderType[currentOrderType].propertyTypes
    : choicesByOrderType.default;
  return {
    itemFieldMeta,
    propertyTypeChoices
  };
};

export default connect(mapStateToProps)(AddressFields);
