// @flow
import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

import styles from './Message.css';

type MessageData = {
  id: string,
  comment: string,
  isIncoming: boolean,
  isActionRequired: boolean,
  createdAt: Date
};

type MessageProps = {
  message: MessageData
};

const Message = (props: MessageProps) => {
  const { message } = props;

  const messageContainer = classNames(styles.messageContainer, {
    [styles.incomingMessage]: message.isIncoming,
    [styles.sentMessage]: !message.isIncoming
  });

  const createdAtDate = new Date(message.createdAt);
  const date = format(createdAtDate, 'MM/dd/yy');
  const time = format(createdAtDate, 'HH:mm');

  return (
    <div className={messageContainer}>
      <div className={styles.message}>
        <div className={styles.messageContent}>{message.comment}</div>
      </div>
      <div className={styles.timestamp}>
        <div className={styles.timestampContent}>
          <div className="date">{date}</div>
          <div className="time">{time}</div>
        </div>
      </div>
    </div>
  );
};

export default Message;
