// @flow
import { ADMIN_ROUTES } from './routes';
import { routeActionsCreator } from '../sagas/route-actions-creator';
import { resolveUserSaga } from './sagas/admin-user.saga';
import { handleStandardExceptions } from './sagas/helpers';
export const ADMIN_ROUTE_ACTIONS = routeActionsCreator(
  ADMIN_ROUTES,
  resolveUserSaga,
  handleStandardExceptions
);
