// @flow
import React from 'react';
import classNames from 'classnames';

import type { OrderProgressState } from 'src/client/reducers/order-progress.reducer';
import type { Order } from 'src/client/types/order';

import ProgressBar from './ProgressBar';
import OrderProgressDetails from './OrderProgressDetails';
import { getOrderItemStatusLabel } from 'src/client/helpers/status-helpers';
import Popover from 'src/components/Popover';

import commonStyles from './order-progress-common.css';
import styles from './OrderProgress.css';

type ProgressPopoverLinkProps = {
  active?: boolean,
  completedOrderPercentage: number
};
const ProgressPopoverLink = (props: ProgressPopoverLinkProps) => (
  <div
    className={classNames({ [styles.progressPopoverLinkActive]: props.active })}
  >
    <ProgressBar percentage={props.completedOrderPercentage} />
  </div>
);
type OrderProgressProps = {
  order: Order,
  customerOrderId: string,
  completedOrderPercentage: number,
  orderProgressState: OrderProgressState,
  onOrderProgressShow: () => void,
  total: number
};

class OrderProgress extends React.Component<OrderProgressProps> {
  handleClick(e: any) {
    e.preventDefault();
  }

  render() {
    const {
      order,
      customerOrderId,
      completedOrderPercentage,
      orderProgressState,
      total
    } = this.props;
    const onlyOneItem: boolean = order.partialEnabled
      ? order.orderItemsToProcessCount + order.orderItemsToReviewCount === 1
      : order.orderItemsToProcessCount === 1;
    if (onlyOneItem) {
      return <span>{getOrderItemStatusLabel(order.maxItemStatus)}</span>;
    } else {
      return (
        <Popover
          theme={styles}
          onPopoverShow={this.props.onOrderProgressShow}
          PopoverLinkComponent={
            <ProgressPopoverLink
              onClick={this.handleClick}
              completedOrderPercentage={completedOrderPercentage}
            />
          }
          PopoverContentComponent={
            <div>
              <div className={commonStyles.row}>
                <div className={styles.customerOrderId}>
                  Order: {customerOrderId}
                </div>
                <div className={styles.total}>
                  Total: {total.toLocaleString()}
                </div>
              </div>
              <div>
                {orderProgressState.status === 'error' && (
                  <p>
                    Progress failed to load: {orderProgressState.errorMessage}
                  </p>
                )}
                {orderProgressState.status === 'loaded' && (
                  <OrderProgressDetails
                    orderNumber={customerOrderId}
                    orderProgress={orderProgressState.orderProgress}
                    statusGroups={order.orderTypeDescriptor.statusGroups}
                  />
                )}
              </div>
            </div>
          }
        />
      );
    }
  }
}

export default OrderProgress;
