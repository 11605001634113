// @flow
import type { Action } from 'src/client/actions';
import type { Message } from 'src/client/types/message';

import {
  FETCH_MESSAGES,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_ERROR,
  POST_MESSAGE_SUCCESS
} from 'src/client/actions/order-item-messages.actions';

type MessagesNotLoadedState = {
  status: 'not_loaded',
  loading: false
};
type MessagesLoadingState = {
  status: 'loading',
  loading: true
};
type MessagesLoadedState = {
  status: 'loaded',
  loading: false,
  messageList: Message[]
};
type MessagesErrorState = {
  status: 'error',
  loading: false,
  error: string
};
export type OrderItemMessagesState =
  | MessagesNotLoadedState
  | MessagesLoadingState
  | MessagesLoadedState
  | MessagesErrorState;

const defaultState = {
  status: 'not_loaded',
  loading: false
};

export function orderItemMessagesReducer(
  state: OrderItemMessagesState = defaultState,
  action: Action
): OrderItemMessagesState {
  switch (action.type) {
    case FETCH_MESSAGES: {
      return {
        status: 'loading',
        loading: true
      };
    }
    case FETCH_MESSAGES_SUCCESS: {
      const { messages } = action.payload;
      return {
        status: 'loaded',
        loading: false,
        messageList: [...messages]
      };
    }
    case FETCH_MESSAGES_ERROR: {
      const { errorMessage } = action.payload;
      return {
        status: 'error',
        loading: false,
        error: errorMessage
      };
    }
    case POST_MESSAGE_SUCCESS: {
      const { message } = action.payload;
      return state.status === 'loaded'
        ? {
            status: 'loaded',
            loading: false,
            messageList: [...state.messageList, message]
          }
        : state;
    }
    default: {
      return state;
    }
  }
}
