// @flow
import React from 'react';
import { themr } from 'react-css-themr';

import { OrgSettingsIcon } from '../../../../icons';

import { type ShortOrganization } from '../../../../types';

import MenuItem, { type MenuItemProps } from '../MenuItem';
import { NAV_BAR_HC_NAMES } from '../../hc.names';
import { withTracker } from '../../Tracker';
import {
  NAVIGATION_BAR_TYPES,
  NAVIGATION_BAR_EVENTS
} from '../../constants/events.constants';

type AccountOrganizationItemsProps = {
  theme: MenuItemProps,
  isAdminUser: boolean,
  availableOrganizations: ShortOrganization[],
  platformBaseUrl: string,
  selectOrgOptions?: {
    url: string,
    onClick: () => void
  }
};

const MenuItemWithTracker = withTracker(MenuItem);

export const AccountOrganizationItems = (
  props: AccountOrganizationItemsProps
) => {
  const { theme, isAdminUser, platformBaseUrl } = props;

  let items = [];

  if (isAdminUser) {
    items.push({
      icon: <OrgSettingsIcon />,
      label: 'Organization Settings',
      url: `${platformBaseUrl}/org-admin/usage`,
      dataHcName: NAV_BAR_HC_NAMES.HC_PROFILE_ORG_SETTINGS,
      type: NAVIGATION_BAR_TYPES.ACCOUNT_MENU_TYPE,
      event: NAVIGATION_BAR_EVENTS.ORG_SETTINGS_CLICK
    });
  }

  return (
    <React.Fragment>
      {items.map((item) => (
        <MenuItemWithTracker key={item.label} theme={theme} {...item} />
      ))}
    </React.Fragment>
  );
};

export default themr('AccountOrganizationItemsThemed')(
  AccountOrganizationItems
);
