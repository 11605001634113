// @flow
import { takeEvery, select, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { CancelOrderItemActionType } from 'src/client/actions/cancel-order-item.actions';
import {
  CANCEL_ORDER_ITEM,
  cancelOrderItemSuccess,
  cancelOrderItemError
} from 'src/client/actions/cancel-order-item.actions';
import { Client } from 'src/api/order-manager-api-client';
import { handleStandardExceptions } from 'src/sagas/helpers';
import userTokenSelector from 'src/selectors/user-token.selector';
import { loadCurrentOrderItems } from './order-item.saga';

export function* handleCancelItemOrder(
  action: CancelOrderItemActionType
): Saga<void> {
  const { orderId, orderItemId } = action.payload;
  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    yield call([client, client.cancelOrderItem], orderId, orderItemId);
    yield put(cancelOrderItemSuccess(orderId, orderItemId));
    yield call(loadCurrentOrderItems);
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(cancelOrderItemError(orderId, orderItemId, e.message));
  }
}

export function* registerCancelOrderItemSaga(): Saga<void> {
  yield takeEvery(CANCEL_ORDER_ITEM, handleCancelItemOrder);
}
