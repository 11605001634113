// @flow
import type { RouteDefinition } from '../types/route';

// resolve sagas
import {
  resolveLogin,
  resolveLogout,
  resolveForgotPassword,
  resolveResetPassword
} from '../sagas/user.saga';

import { resolveOrders } from './sagas/order.saga';
import { resolveOrderItems } from './sagas/order-item.saga';
import { resolveSamlLogin } from './sagas/saml-login.saga';

import { resolveReportSummary } from './sagas/report-summary.saga';
import { evaluateOrders } from './sagas/evaluation-order.saga';

// cleanup actions
import { clearOrders } from './actions/orders.actions';
import { clearOrderItems } from './actions/order-items.actions';
import { clearReportSummary } from './actions/report-summary.actions';
// containers
import Login from '../containers/Login';
import ForgotPassword from '../containers/ForgotPassword';
import ResetPassword from '../containers/ResetPassword';

import ClientOrders from './orders/OrdersContainer';
import ClientOrderItems from './order-items/OrderItemsContainer';
import ReportSummaryContainer from './report-summary/ReportSummaryContainer';
import ManageOrderItemsContainer from './manage-order-items/ManageOrderItemsContainer';

type Redirect = (args: { [key: string]: string }) => string;

export const ORDERS_ROUTE = '/client/orders';

export const CLIENT_ROUTES: RouteDefinition = {
  '/login': {
    id: 'login',
    saga: resolveLogin,
    view: Login,
    requiresLogin: false
  },
  '/saml2-validate': {
    id: 'saml2-validate',
    saga: resolveSamlLogin,
    queryParams: ['token'],
    view: null,
    requiresLogin: false
  },
  '/logout': {
    id: 'logout',
    saga: resolveLogout,
    view: Login,
    requiresLogin: false
  },
  '/forgot-password': {
    id: 'forgot-password',
    saga: resolveForgotPassword,
    view: ForgotPassword,
    requiresLogin: false
  },
  '/reset-password': {
    id: 'reset-password',
    saga: resolveResetPassword,
    view: ResetPassword,
    requiresLogin: false,
    queryParams: ['code']
  },
  [ORDERS_ROUTE]: {
    id: 'client-orders',
    saga: resolveOrders,
    view: ClientOrders,
    requiresLogin: true,
    queryParams: [
      'page',
      'query',
      'ordering',
      'createProduct',
      'orderSet',
      'ownerId'
    ],
    cleanupAction: clearOrders()
  },
  '/client/order/:orderId/:orderItemGroup?': {
    id: 'client-order-items',
    saga: resolveOrderItems,
    view: ClientOrderItems,
    requiresLogin: true,
    queryParams: [
      'page',
      'query',
      'ordering',
      'sidePanelContent',
      'selectedItem'
    ],
    cleanupAction: clearOrderItems()
  },
  '/client/report-summary': {
    id: 'client-report-summary',
    saga: resolveReportSummary,
    view: ReportSummaryContainer,
    requiresLogin: true,
    cleanupAction: clearReportSummary()
  },
  '/client/manage-order-items': {
    id: 'client-manage-order-items',
    saga: evaluateOrders,
    view: ManageOrderItemsContainer,
    requiresLogin: true
  }
};

export const CLIENT_REDIRECTS: { [key: string]: Redirect } = {
  '/*': () => ORDERS_ROUTE
};
