// @flow
import camelCase from 'lodash.camelcase';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { destroy } from 'redux-form';
import store from 'store/dist/store.modern';
import slug from 'slug';
import type {
  AddOrderWithFile,
  AddOrderWithItems,
  AddOrExtendItem
} from '../types/add-order';
import type { OrderTypeDescriptor } from '../types/order';
import type { AddOrderActionType } from '../actions/add-order.actions';
import type { SupportingDocument } from '../types/supporting-documents';
import { dateTimeFormatter } from '../helpers/formatter-helpers';
import {
  ADD_ORDER,
  addOrderSuccess,
  addOrderError,
  SHOW_ADD_ORDER_DIALOG,
  loadOrderTypeDescriptorsSuccess,
  loadOrderTypeDescriptorsError,
  toggleAddOrderConfirmation
} from '../actions/add-order.actions';
import { SHOW_EXTEND_ORDER_DIALOG } from '../actions/extend-order.actions';
import userTokenSelector from '../../selectors/user-token.selector';
import { loadCurrentOrders } from './order.saga';
import { Client } from '../../api/order-manager-api-client';
import {
  handleStandardExceptions,
  snakeToCamel,
  camelToSnake
} from '../../sagas/helpers';
import {
  ADD_ORDER_FORM_NAME,
  FIELD_NAMES,
  OPT_OUT_STORAGE_KEY
} from '../constants/add-order';

export function* handleGetOrderTypeDescriptors(): Saga<void> {
  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const response = yield call([client, client.getOrderTypeDescriptors]);
    const orderTypeDescriptors: OrderTypeDescriptor[] = response.data.map(
      (descriptor) => {
        const snakeToCamelDescriptor = snakeToCamel(
          descriptor,
          Object.keys(descriptor)
        );
        snakeToCamelDescriptor.shownFields =
          snakeToCamelDescriptor.shownFields.map((fieldName) =>
            camelCase(fieldName)
          );
        snakeToCamelDescriptor.requiredFields =
          snakeToCamelDescriptor.requiredFields.map((fieldName) =>
            camelCase(fieldName)
          );
        snakeToCamelDescriptor.shownItemFields =
          snakeToCamelDescriptor.shownItemFields.map((fieldName) =>
            camelCase(fieldName)
          );
        snakeToCamelDescriptor.requiredItemFields =
          snakeToCamelDescriptor.requiredItemFields.map((fieldName) =>
            camelCase(fieldName)
          );
        snakeToCamelDescriptor.deliverySpeeds =
          snakeToCamelDescriptor.deliverySpeeds
            ? snakeToCamelDescriptor.deliverySpeeds.map((deliverySpeed) =>
                snakeToCamel(deliverySpeed, Object.keys(deliverySpeed))
              )
            : null;
        return snakeToCamelDescriptor;
      }
    );
    yield put(loadOrderTypeDescriptorsSuccess(orderTypeDescriptors));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(loadOrderTypeDescriptorsError(e.message));
  }
}

export function* addOrderCsv(order: AddOrderWithFile): Saga<void> {
  const orderSnake = camelToSnake(order, Object.keys(order));
  const formData: window.FormData = Object.keys(orderSnake).reduce(
    (formData: window.FormData, k) => {
      formData.append(k, orderSnake[k]);
      return formData;
    },
    new window.FormData()
  );

  const userToken = yield select(userTokenSelector);
  const client = new Client(userToken);
  yield call([client, client.addNewOrderCsv], formData);
}

export function* addOrderJson(order: AddOrderWithItems): Saga<void> {
  const orderWithItemsSnake = {
    ...camelToSnake(order, Object.keys(order)),
    items: order.items.map((item) => camelToSnake(item, Object.keys(item)))
  };
  const userToken = yield select(userTokenSelector);
  const client = new Client(userToken);
  yield call([client, client.addNewOrderJson], orderWithItemsSnake);
}

export function* handleAddOrder(action: AddOrderActionType): Saga<void> {
  try {
    const { values } = action.payload;
    const { itemsSource, items, orderFile, intendedUserSame, ...restValues } =
      values;
    if (intendedUserSame) {
      restValues[FIELD_NAMES.LENDER_NAME] = restValues[FIELD_NAMES.CLIENT_NAME];
      restValues[FIELD_NAMES.LENDER_ADDRESS] =
        restValues[FIELD_NAMES.CLIENT_ADDRESS];
      restValues[FIELD_NAMES.LENDER_CITY] = restValues[FIELD_NAMES.CLIENT_CITY];
      restValues[FIELD_NAMES.LENDER_STATE] =
        restValues[FIELD_NAMES.CLIENT_STATE];
      restValues[FIELD_NAMES.LENDER_ZIPCODE] =
        restValues[FIELD_NAMES.CLIENT_ZIPCODE];
    }

    if (!restValues.customerOrderId && restValues.name) {
      let slugified = slug(restValues.name);
      if (slugified.length > 10) {
        slugified = slugified.substr(0, 10);
      }
      restValues.customerOrderId = `${slugified}-${dateTimeFormatter(
        new Date(),
        'X'
      )}`;
    }

    if (itemsSource === 'csv' && orderFile) {
      const orderWithFile: AddOrderWithFile = {
        ...restValues,
        itemsSource: 'csv',
        orderFile
      };
      yield call(addOrderCsv, orderWithFile);
    } else if (itemsSource === 'entry' && items) {
      const orderWithItems: AddOrderWithItems = {
        ...restValues,
        itemsSource: 'entry',
        items: items.map((item) => {
          const { address: addressParts, ...restItemValues } = item;
          const addOrderItem: AddOrExtendItem = {
            ...restItemValues,
            address: addressParts.address,
            unit: addressParts.unit,
            city: addressParts.city,
            state: addressParts.state,
            zipcode: addressParts.zipcode,
            addressUnparsed: addressParts.addressUnparsed,
            // backend only cares about document ids
            supportingDocuments: item.supportingDocuments
              ? item.supportingDocuments.map(
                  (supportingDocument: SupportingDocument) =>
                    supportingDocument.id
                )
              : undefined
          };
          return addOrderItem;
        })
      };
      yield call(addOrderJson, orderWithItems);
    } else {
      throw Error(
        'handleAddOrder saga: values did not have orderFile or items'
      );
    }

    yield put(addOrderSuccess());
    const optOutAddOrderDialog = store.get(OPT_OUT_STORAGE_KEY);
    if (optOutAddOrderDialog) {
      yield put(toggleAddOrderConfirmation(true, 'toast'));
    } else {
      yield put(toggleAddOrderConfirmation(true, 'dialog'));
    }
    yield call(loadCurrentOrders);
    yield put(destroy(ADD_ORDER_FORM_NAME));
  } catch (e) {
    if (e.name === 'ForbiddenError') {
      yield put(
        addOrderError(
          'You do not have permission to create orders. Please contact your administrator.'
        )
      );
      return;
    } else if (e.name === 'ValidationError') {
      const validationErrors = snakeToCamel(
        e.data,
        Object.keys(e.data),
        {},
        false
      );
      let msg = 'Cannot create order \n';
      const errDetails = [];
      validationErrors.items.map((item) => {
        Object.entries(item).forEach(([key, value]: [string, any]) => {
          if (value !== null && value !== undefined) {
            errDetails.push(`${key}: ${value.toString()}`);
          }
        });
      });

      errDetails.forEach((item: string) => {
        msg += `${item}\n`;
      });

      if (validationErrors.orderFile) {
        if (validationErrors.orderFile.constructor === Array) {
          validationErrors.orderFile.forEach((item) => {
            msg += `\n${item}`;
          });
        } else {
          msg += `\n${validationErrors.orderFile}`;
        }
      }
      yield put(addOrderError(msg, validationErrors));
      return;
    }
    yield call(handleStandardExceptions, e);
    yield put(addOrderError(e.message));
  }
}

export function* registerGetOrderTypeDescriptorsSaga(): Saga<void> {
  yield takeEvery(SHOW_ADD_ORDER_DIALOG, handleGetOrderTypeDescriptors);
  yield takeEvery(SHOW_EXTEND_ORDER_DIALOG, handleGetOrderTypeDescriptors);
}

export function* registerAddOrderSaga(): Saga<void> {
  yield takeEvery(ADD_ORDER, handleAddOrder);
}
