// @flow
import React from 'react';
import classNames from 'classnames';
import { PRODUCT_DETAILS } from '@hc/component-lib';
import houseIcon from '../../icons/house.svg';
import calendarIcon from '../../icons/calendar.svg';
import fileIcon from '../../icons/file.svg';
import fileIdIcon from '../../icons/file_id.svg';
import inspectionsIcon from '../../icons/inspections.svg';
import { INSPECTION_NAMES, ADD_ORDER_FORM_NAME } from '../constants/add-order';
import type { DeliverySpeed } from '../types/order';
import { getFormValues } from 'redux-form';
import type { State } from '../../reducers';
import { connect, type MapStateToProps } from 'react-redux';
import { getAsRepairedLabel } from '../helpers/add-order.helpers';
import styles from '../orders/components/add-order-form/add-order-form.css';

type SelectedOptionsProps = {
  updateStep: (number) => void,
  productType: string,
  deliverySpeed: ?DeliverySpeed,
  inspectionType: ?string,
  includesAsRepairedValue: ?boolean,
  stepNum: number,
  formValues: any,
  disabled?: boolean
};

class SelectedOptionsContent extends React.Component<SelectedOptionsProps> {
  handleChangeClick = (stepNum: number) => {
    this.props.updateStep(stepNum);
  };

  render() {
    const {
      productType,
      deliverySpeed,
      inspectionType,
      includesAsRepairedValue,
      stepNum,
      formValues,
      disabled
    } = this.props;
    const showInspectionOption = deliverySpeed || inspectionType;
    return (
      <div className={styles.optionsContainer}>
        <ProductOption
          productType={productType}
          onChangeClick={this.handleChangeClick}
          disabled={disabled}
        />
        {showInspectionOption && (
          <InspectionOption
            inspectionType={inspectionType}
            includesAsRepairedValue={includesAsRepairedValue}
            deliverySpeed={deliverySpeed}
            onChangeClick={this.handleChangeClick}
            disabled={disabled}
          />
        )}
        {stepNum === 5 && (
          <PropertiesOption
            onChangeClick={this.handleChangeClick}
            formValues={formValues}
          />
        )}
      </div>
    );
  }
}

type ProductOptionProps = {
  onChangeClick: (number) => void,
  productType: string,
  disabled?: boolean
};

class ProductOption extends React.Component<ProductOptionProps> {
  STEP_NUM = 1;

  handleChangeClick = () => {
    const { onChangeClick, disabled } = this.props;
    if (disabled) {
      return false;
    } else {
      onChangeClick(this.STEP_NUM);
    }
  };

  render() {
    const { productType, disabled } = this.props;
    const { icon, title, description } = PRODUCT_DETAILS[productType];
    const Icon = icon;
    return (
      <div className={styles.option}>
        <div className={styles.optionContent} data-hc-name="order-form-option">
          <div className={styles.optionIcon}>
            <Icon />
          </div>
          <div>
            <h4 className={styles.optionTitle} data-hc-name="option-form-name">
              {title}
            </h4>
            <div
              className={styles.optionDescription}
              data-hc-name="option-form-description"
            >
              {description}
            </div>
          </div>
        </div>
        <div
          className={classNames(styles.optionChange, {
            [styles.disabled]: disabled
          })}
          onClick={this.handleChangeClick}
          data-hc-name="option-form-change-link"
        >
          Change
        </div>
      </div>
    );
  }
}

type InspectionOptionProps = {
  onChangeClick: (number) => void,
  deliverySpeed: ?DeliverySpeed,
  inspectionType: ?string,
  disabled?: boolean,
  includesAsRepairedValue: ?boolean
};

class InspectionOption extends React.Component<InspectionOptionProps> {
  STEP_NUM = 2;

  handleChangeClick = () => {
    const { onChangeClick, disabled } = this.props;
    if (disabled) {
      return false;
    } else {
      onChangeClick(this.STEP_NUM);
    }
  };

  getDeliveryText(deliverySpeed) {
    return deliverySpeed.surcharge
      ? `${deliverySpeed.label} + $${deliverySpeed.surcharge}/address`
      : deliverySpeed.label;
  }

  render() {
    const { inspectionType, includesAsRepairedValue, deliverySpeed, disabled } =
      this.props;
    const asRepairedLabel = getAsRepairedLabel(includesAsRepairedValue);
    return (
      <div className={styles.option}>
        <div className={styles.optionContent}>
          <img src={inspectionsIcon} className={`${styles.inspectionsIcon}`} />
          <div>
            <h4
              className={styles.optionTitle}
              data-hc-name="inspection-type-label"
            >
              {INSPECTION_NAMES[inspectionType]}
            </h4>
            {asRepairedLabel && (
              <div
                className={styles.optionDescription}
                data-hc-name="as-repaired-label"
              >
                {asRepairedLabel}
              </div>
            )}
          </div>
        </div>
        <div className={styles.optionActions}>
          {deliverySpeed && (
            <div
              className={styles.optionDescription}
              data-hc-name="delivery-speed"
            >
              <img
                src={calendarIcon}
                className={styles.optionDescriptionIcon}
              />{' '}
              {this.getDeliveryText(deliverySpeed)}
            </div>
          )}
          <div
            className={classNames(styles.optionChange, {
              [styles.disabled]: disabled
            })}
            onClick={this.handleChangeClick}
          >
            Change
          </div>
        </div>
      </div>
    );
  }
}

type PropertiesOptionProps = {
  onChangeClick: (number) => void,
  formValues: any
};

class PropertiesOption extends React.Component<PropertiesOptionProps> {
  STEP_NUM = 3;

  handleClick = () => {
    const { formValues } = this.props;
    if (formValues.orderFile) {
      this.props.onChangeClick(this.STEP_NUM);
    } else {
      this.props.onChangeClick(this.STEP_NUM + 1);
    }
  };

  render() {
    const { formValues } = this.props;
    const isCSV = formValues.itemsSource === 'csv';
    const iconSrc = isCSV ? fileIcon : fileIdIcon;
    const individualAddresses = formValues.items;
    const propertiesCount = !isCSV ? individualAddresses.length : 0;
    let title = 'Properties from File';
    if (!isCSV) {
      title =
        individualAddresses.length > 1
          ? `${propertiesCount} Properties Entered Manually`
          : individualAddresses[0].address.address;
    }
    const description = isCSV
      ? formValues.orderFile.name
      : individualAddresses[0].customerItemId;
    return (
      <div className={styles.option}>
        <div className={styles.optionContent}>
          <img src={houseIcon} className={styles.optionIcon} />
          <h4 className={styles.optionTitle}>{title}</h4>
        </div>
        <div className={styles.optionActions}>
          {description && (isCSV || (!isCSV && propertiesCount === 1)) && (
            <div className={styles.optionDescription}>
              <img src={iconSrc} className={styles.optionDescriptionIcon} />{' '}
              {isCSV ? description : `#${description}`}
            </div>
          )}
          <div className={styles.optionChange} onClick={this.handleClick}>
            Change
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<*, *, *> = (state: State) => {
  const selector = getFormValues(ADD_ORDER_FORM_NAME);
  const formValues = selector(state);
  return {
    formValues
  };
};

const SelectedOptions = connect(mapStateToProps)(SelectedOptionsContent);
export default SelectedOptions;
