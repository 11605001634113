// @flow
import type { RouteDefinition } from '../types/route';

import { loadAcceptOrderDetailsSaga } from './sagas/accept-order.saga';
import { loadReviewValuationDetails } from './sagas/review-valuation.saga';
import { loadValuationCommentDetails } from './sagas/valuation-comment.saga';
import { initValuationRevision } from './sagas/valuation-revision.saga';

import AcceptOrder from './containers/AcceptOrder';
import ReviewValuation from './containers/ReviewValuation';
import ValuationComments from './containers/ValuationComments';
import ValuationRevision from './containers/ValuationRevision';

export const PARTNER_ROUTES: RouteDefinition = {
  '/partner/accept-evaluation-order/:orderId': {
    id: 'accept-evaluation-order',
    saga: loadAcceptOrderDetailsSaga,
    view: AcceptOrder,
    requiresLogin: true
  },
  '/partner/review-evaluation-order/:orderId': {
    id: 'partner-valuationreview',
    saga: loadReviewValuationDetails,
    view: ReviewValuation,
    requiresLogin: false,
    queryParams: ['tok']
  },
  '/partner/comment-evaluation-order/:orderId': {
    id: 'partner-valuationcomments',
    saga: loadValuationCommentDetails,
    view: ValuationComments,
    requiresLogin: false,
    queryParams: ['tok']
  },
  '/partner/revision-evaluation-order/:orderId': {
    id: 'partner-valuationrevision',
    saga: initValuationRevision,
    view: ValuationRevision,
    requiresLogin: true
  }
};
