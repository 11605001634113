// @flow
import type { Action } from '../actions';

import {
  SUBMIT_RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  VERIFY_RESET_PASSWORD_CODE_SUCCESS
} from '../actions/forgot-password.actions';

type ResetPasswordDefaultState = {
  status: 'default'
};
type ResetPasswordResolvedState = {
  status: 'resolved',
  resetPasswordCode: string
};
type ResetPasswordSubmittingState = {
  status: 'submitting',
  resetPasswordCode: string
};
type ResetPasswordErrorState = {
  status: 'error',
  resetPasswordCode: string,
  errorMessage: string
};
export type ResetPasswordState =
  | ResetPasswordDefaultState
  | ResetPasswordResolvedState
  | ResetPasswordSubmittingState
  | ResetPasswordErrorState;

const defaultState = {
  status: 'default'
};

function resetPasswordReducer(
  previousState: ResetPasswordState = defaultState,
  action: Action
): ResetPasswordState {
  switch (action.type) {
    case VERIFY_RESET_PASSWORD_CODE_SUCCESS: {
      return {
        status: 'resolved',
        resetPasswordCode: action.payload.resetPasswordCode
      };
    }
    case SUBMIT_RESET_PASSWORD: {
      return previousState.status === 'resolved' ||
        previousState.status === 'error'
        ? {
            status: 'submitting',
            resetPasswordCode: previousState.resetPasswordCode
          }
        : previousState;
    }
    case RESET_PASSWORD_ERROR: {
      return previousState.status === 'resolved' ||
        previousState.status === 'submitting'
        ? {
            status: 'error',
            resetPasswordCode: previousState.resetPasswordCode,
            errorMessage: action.payload.errorMessage
          }
        : previousState;
    }
    case RESET_PASSWORD_SUCCESS: {
      return defaultState;
    }
    default: {
      return previousState;
    }
  }
}

export default resetPasswordReducer;
