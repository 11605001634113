// @flow
import camelCase from 'lodash.camelcase';

import { snakeToCamel } from '../../sagas/helpers';
import type { OrderTypeDescriptorsState } from '../reducers/order-type-descriptors.reducer';
import type { Order, DeliverySpeed, OrderTypeId } from '../types/order';

export const orderResponseToCamelCase = (orderResponse: any): Order => {
  const orderToCamel: any = snakeToCamel(
    orderResponse,
    Object.keys(orderResponse),
    {},
    false
  );
  orderToCamel.orderTypeDescriptor = {
    ...snakeToCamel(
      orderToCamel.orderTypeDescriptor,
      Object.keys(orderToCamel.orderTypeDescriptor)
    ),
    statusGroups: orderToCamel.orderTypeDescriptor.status_groups.map(
      (statusGroup) => snakeToCamel(statusGroup, Object.keys(statusGroup))
    ),
    headerStatusGroups:
      orderToCamel.orderTypeDescriptor.header_status_groups.map(
        (headerStatusGroup) =>
          snakeToCamel(headerStatusGroup, Object.keys(headerStatusGroup))
      ),
    resultColumns: orderToCamel.orderTypeDescriptor.result_columns.map(
      (resultColumn) => {
        const resultColumnCamel = snakeToCamel(
          resultColumn,
          Object.keys(resultColumn)
        );
        resultColumnCamel.columnName = camelCase(resultColumnCamel.columnName);
        return resultColumnCamel;
      }
    )
  };
  return orderToCamel;
};

export const deliverySpeedById = (
  orderTypeDescriptorsState: OrderTypeDescriptorsState,
  orderTypeId: ?OrderTypeId,
  deliverySpeedId: ?string
): ?DeliverySpeed => {
  let deliverySpeed = null;
  if (
    orderTypeDescriptorsState.status === 'loaded' &&
    deliverySpeedId &&
    orderTypeId
  ) {
    const orderTypeDescriptor = orderTypeDescriptorsState.descriptors.find(
      (descriptor) => {
        return descriptor.id === orderTypeId;
      }
    );
    if (orderTypeDescriptor) {
      deliverySpeed = orderTypeDescriptor.deliverySpeeds.find(
        (deliverySpeedObj) => {
          return deliverySpeedObj.id === deliverySpeedId;
        }
      );
    }
  }
  return deliverySpeed;
};
