// @flow
import React from 'react';
import { RTThemedCard } from '@hc/component-lib/lib/rt-themed';

import type { YearSummary as YearSummaryType } from 'src/client/types/report-summary';

import { yearSummarySortedMonthsSelector } from 'src/client/selectors/report-summary.selectors';

import CollapsibleRow from './CollapsibleRow';
import MonthSummary from './MonthSummary';

import styles from './YearSummary.css';

type YearSummaryProps = {
  year: string,
  yearSummary: YearSummaryType
};

const YearSummary = (props: YearSummaryProps) => {
  const { year, yearSummary } = props;

  const months = yearSummarySortedMonthsSelector(yearSummary);

  const monthSummaries = months.map((month, index) => {
    const monthSummary = yearSummary[month];
    return (
      <MonthSummary
        key={index}
        label={`${month} ${year}`}
        monthSummary={monthSummary}
      />
    );
  });

  return (
    <RTThemedCard data-hc-name="year-summary">
      <CollapsibleRow
        dataHcNameHeader="year-header"
        hasNestedCollapse
        header={year}
        theme={styles}
      >
        {monthSummaries}
      </CollapsibleRow>
    </RTThemedCard>
  );
};

export default YearSummary;
