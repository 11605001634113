// @flow
import {
  ADD_ITEMS_TO_ORDER,
  REMOVE_ITEMS_FROM_ORDER,
  RECOVER_REMOVED_ITEMS,
  MOVE_ITEMS_COMPLETE,
  SHOW_MOVE_COMPLETE_DIALOG,
  DISMISS_MOVE_COMPLETE_DIALOG
} from 'src/client/actions/order-items.actions';

import type { Action } from 'src/client/actions';

type MoveCompleteDialog = {
  active: boolean,
  succeededMessage: string,
  failedMessage: string
};

type MoveOrderItemsNotLoadingState = {
  status: 'not_loading',
  moveCompleteDialog: MoveCompleteDialog
};

type MoveOrderItemsLoadingState = {
  status: 'loading',
  movingType: 'add' | 'remove' | 'recover',
  moveCompleteDialog: MoveCompleteDialog
};

export type MoveOrderItemsState =
  | MoveOrderItemsNotLoadingState
  | MoveOrderItemsLoadingState;

const defaultMoveCompleteDialog = {
  active: false,
  succeededMessage: '',
  failedMessage: ''
};

const defaultState = {
  status: 'not_loading',
  moveCompleteDialog: { ...defaultMoveCompleteDialog }
};

export function moveOrderItemsReducer(
  state: MoveOrderItemsState = defaultState,
  action: Action
): MoveOrderItemsState {
  switch (action.type) {
    case ADD_ITEMS_TO_ORDER: {
      return {
        status: 'loading',
        movingType: 'add',
        moveCompleteDialog: { ...defaultMoveCompleteDialog }
      };
    }
    case REMOVE_ITEMS_FROM_ORDER: {
      return {
        status: 'loading',
        movingType: 'remove',
        moveCompleteDialog: { ...defaultMoveCompleteDialog }
      };
    }
    case RECOVER_REMOVED_ITEMS: {
      return {
        status: 'loading',
        movingType: 'recover',
        moveCompleteDialog: { ...defaultMoveCompleteDialog }
      };
    }
    case MOVE_ITEMS_COMPLETE: {
      return {
        status: 'not_loading',
        moveCompleteDialog: state.moveCompleteDialog
      };
    }
    case SHOW_MOVE_COMPLETE_DIALOG: {
      const { succeededMessage, failedMessage } = action.payload;
      return {
        status: 'not_loading',
        moveCompleteDialog: {
          active: true,
          succeededMessage,
          failedMessage
        }
      };
    }
    case DISMISS_MOVE_COMPLETE_DIALOG: {
      return {
        status: 'not_loading',
        moveCompleteDialog: {
          ...defaultMoveCompleteDialog
        }
      };
    }
    default: {
      return state;
    }
  }
}
