// @flow
import type { Action } from 'src/client/actions';
import type { OrderExport } from 'src/client/types/order-export';

import {
  FETCH_ORDER_EXPORTS_LOADING,
  FETCH_ORDER_EXPORTS_SUCCESS,
  FETCH_ORDER_EXPORTS_ERROR
} from 'src/client/actions/order-export.actions';
import { CLOSE_SIDE_PANEL } from 'src/client/actions/side-panel.actions';
import { WEBSOCKET_ORDER_EXPORT_UPDATED } from 'src/client/actions/websocket.actions';

type OrderExportsNotLoadedState = {
  status: 'not_loaded'
};
type OrderExportsLoadingState = {
  status: 'loading',
  orderId: string
};
type OrderExportsReLoadingState = {
  status: 'reloading',
  orderId: string,
  orderExports: OrderExport[]
};
type OrderExportsLoadedState = {
  status: 'loaded',
  orderId: string,
  orderExports: OrderExport[]
};
type OrderExportsErrorState = {
  status: 'error',
  orderId: string,
  errorMessage: string
};
export type OrderExportsState =
  | OrderExportsNotLoadedState
  | OrderExportsLoadingState
  | OrderExportsReLoadingState
  | OrderExportsLoadedState
  | OrderExportsErrorState;

const defaultState = {
  status: 'not_loaded'
};

export function orderExportsReducer(
  state: OrderExportsState = defaultState,
  action: Action
): OrderExportsState {
  switch (action.type) {
    case FETCH_ORDER_EXPORTS_LOADING: {
      const { orderId } = action.payload;
      if (
        (state.status === 'loading' || state.status === 'reloading') &&
        orderId === state.orderId
      ) {
        return state;
      } else if (state.status === 'loaded' && orderId === state.orderId) {
        return {
          status: 'reloading',
          orderExports: state.orderExports,
          orderId: orderId
        };
      } else {
        return {
          status: 'loading',
          orderId: orderId
        };
      }
    }
    case FETCH_ORDER_EXPORTS_SUCCESS: {
      const { orderId, orderExports } = action.payload;
      if (orderId !== state.orderId) {
        return state;
      }
      return {
        status: 'loaded',
        orderExports: orderExports,
        orderId: orderId
      };
    }
    case FETCH_ORDER_EXPORTS_ERROR: {
      const { orderId, errorMessage } = action.payload;
      if (orderId !== state.orderId) {
        return state;
      }
      return {
        status: 'error',
        errorMessage: errorMessage,
        orderId: orderId
      };
    }
    case WEBSOCKET_ORDER_EXPORT_UPDATED: {
      if (
        state.status === 'loaded' &&
        state.orderId === action.payload.orderId
      ) {
        const orderExportId: string = action.payload.exportUpdateObj.id;
        const matchingOrderExportIndex = state.orderExports.findIndex(
          (orderExport: OrderExport) => orderExport.id === orderExportId
        );
        if (matchingOrderExportIndex !== -1) {
          const updatedOrderExport = {
            ...state.orderExports[matchingOrderExportIndex],
            ...action.payload.exportUpdateObj
          };
          return {
            status: 'loaded',
            orderExports: Object.assign([...state.orderExports], {
              [matchingOrderExportIndex]: updatedOrderExport
            }),
            orderId: state.orderId
          };
        }
      }
      return state;
    }
    case CLOSE_SIDE_PANEL: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
}
