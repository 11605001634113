// @flow
import type { State } from 'src/reducers';

import clientSelector from 'src/client/selectors/client.selector';

import type { EvaluationOrdersState } from 'src/client/reducers/evaluation-order.reducer';

export const evaluationOrdersStateSelector = (
  state: State
): EvaluationOrdersState => clientSelector(state).evaluationOrders;
