// @flow
import * as React from 'react';
import {
  RTThemedTable,
  RTThemedTableHead,
  RTThemedTableCell,
  RTThemedCheckbox,
  RTThemedTableRow,
  RTThemedFontIcon
} from '@hc/component-lib/lib/rt-themed';

import type { routeGeneratorFn } from '../../../components/Pagination';
import type { Links } from '../../../types/link';
import type { OrderingParams } from '../../../types/list';
import type { OrderItem } from '../../types/order-item';
import type { PropertyType } from '../../types/order';

import Pagination from '../../../components/Pagination';
import SortableTableCell from '../../../components/SortableTableCell';
import { COMMON_ORDERING_PARAMS } from './common-ordering-params';
import ItemIssues from './ItemIssues';
import AddressLink from './AddressLink';
import { PROPERTY_TYPES_TO_LABEL } from '../../../constants/property-types';
import { TextButton } from '@hc/component-lib';
import NeedReviewEditTable from './NeedReviewEditTable';
import { getFullAddresFromOrderItem } from '../../helpers/order-item.helpers';

import styles from './item-list.css';

// FYI: This can't be a regular component b/c RTThemedTable will only render children of type RTThemedTableRow (and not a component that renders the row)
export type RenderDisplayRowParams = {
  orderItem: OrderItem,
  selectedOrderItems: string[],
  onToggleOrderItem: (orderItemId: string) => void,
  onEdit: (orderItem: OrderItem) => void
};
const renderDisplayRow = (params: RenderDisplayRowParams): React.Node => {
  const { orderItem, selectedOrderItems, onToggleOrderItem, onEdit } = params;

  return (
    <RTThemedTableRow key={orderItem.id} className={styles.tableRow}>
      <RTThemedTableCell className={styles.selectableTableCell}>
        <RTThemedCheckbox
          theme={styles}
          checked={selectedOrderItems.indexOf(orderItem.id) !== -1}
          onChange={() => onToggleOrderItem(orderItem.id)}
        />
      </RTThemedTableCell>
      <RTThemedTableCell className={styles.idCell}>
        <span>{orderItem.customerItemId}</span>
      </RTThemedTableCell>
      <RTThemedTableCell className={styles.propertyTypeCell}>
        <span>
          {orderItem.propertyType &&
            PROPERTY_TYPES_TO_LABEL[orderItem.propertyType]}
        </span>
      </RTThemedTableCell>
      <RTThemedTableCell>
        <RTThemedFontIcon className={styles.addressIcon} value="place" />
        <AddressLink
          className={styles.addressLink}
          fullAddress={getFullAddresFromOrderItem(orderItem)}
          label={`${orderItem.address} ${orderItem.unit ? orderItem.unit : ''}`}
        />
      </RTThemedTableCell>
      <RTThemedTableCell>
        <span>{orderItem.city}</span>
      </RTThemedTableCell>
      <RTThemedTableCell>
        <span>{orderItem.state}</span>
      </RTThemedTableCell>
      <RTThemedTableCell>
        <span>{orderItem.zipcode}</span>
      </RTThemedTableCell>
      <RTThemedTableCell className={styles.issuesCell}>
        {orderItem.validationChecksComplete ? (
          <ItemIssues validationResults={orderItem.validationResults} />
        ) : (
          'Validating...'
        )}
      </RTThemedTableCell>
      <RTThemedTableCell className={styles.actionsCell}>
        <span>
          {!orderItem.cancelled && (
            <TextButton
              className={styles.editButton}
              onClick={() => onEdit(orderItem)}
            >
              Edit
            </TextButton>
          )}
        </span>
      </RTThemedTableCell>
    </RTThemedTableRow>
  );
};

export type NeedReviewListProps = {
  orderItems: OrderItem[],
  links: Links,
  paginationRouteGeneratorFn: routeGeneratorFn,
  ordering: OrderingParams[],
  selectedOrderItems: string[],
  updatingOrderItemId: ?string,
  propertyTypeChoices: PropertyType[],
  onSort: (OrderingParams[]) => void,
  onToggleOrderItem: (orderItemId: string) => void,
  onToggleAll: (orderItemIds: string[], toggleOn: boolean) => void,
  onUpdateReverify: (
    orderItemId: string,
    onUpdateComplete: () => void,
    formName: string
  ) => void
};

type NeedReviewListState = {
  editingOrderItem: ?OrderItem
};

class NeedReviewList extends React.Component<
  NeedReviewListProps,
  NeedReviewListState
> {
  constructor(props: NeedReviewListProps) {
    super(props);
    this.state = {
      editingOrderItem: null
    };
  }

  handleEdit = (orderItem: OrderItem) => {
    this.setState({
      editingOrderItem: orderItem
    });
  };

  handleCancelEdit = () => {
    this.setState({
      editingOrderItem: null
    });
  };

  render() {
    const {
      orderItems,
      links,
      paginationRouteGeneratorFn,
      ordering,
      selectedOrderItems,
      updatingOrderItemId,
      propertyTypeChoices,
      onSort,
      onUpdateReverify,
      onToggleOrderItem,
      onToggleAll
    } = this.props;

    const { editingOrderItem } = this.state;

    const areAllSelected =
      selectedOrderItems.length === orderItems.length && orderItems.length > 0;

    const tableHead = (
      <RTThemedTableHead className={styles.tableHead}>
        <RTThemedTableCell className={styles.selectableTableCell}>
          <RTThemedCheckbox
            theme={styles}
            onChange={() =>
              onToggleAll(
                orderItems.map((orderItem: OrderItem) => orderItem.id),
                !areAllSelected
              )
            }
            checked={areAllSelected}
          />
        </RTThemedTableCell>
        <SortableTableCell
          onSort={onSort}
          orderingField="customerItemId"
          customOnSortOrdering={COMMON_ORDERING_PARAMS.customItemId}
          ordering={ordering}
        >
          <span>ID</span>
        </SortableTableCell>
        <SortableTableCell
          onSort={onSort}
          orderingField="propertyType"
          customOnSortOrdering={COMMON_ORDERING_PARAMS.propertyType}
          ordering={ordering}
        >
          <span>Property Type</span>
        </SortableTableCell>
        <SortableTableCell
          onSort={onSort}
          orderingField="address"
          customOnSortOrdering={COMMON_ORDERING_PARAMS.address}
          ordering={ordering}
        >
          <span>Address</span>
        </SortableTableCell>
        <SortableTableCell
          onSort={onSort}
          orderingField="city"
          customOnSortOrdering={COMMON_ORDERING_PARAMS.city}
          ordering={ordering}
        >
          <span>City</span>
        </SortableTableCell>
        <SortableTableCell
          onSort={onSort}
          orderingField="state"
          customOnSortOrdering={COMMON_ORDERING_PARAMS.state}
          ordering={ordering}
        >
          <span>State</span>
        </SortableTableCell>
        <SortableTableCell
          onSort={onSort}
          orderingField="zipcode"
          customOnSortOrdering={COMMON_ORDERING_PARAMS.zipcode}
          ordering={ordering}
        >
          <span>Zipcode</span>
        </SortableTableCell>
        <SortableTableCell
          className={styles.issuesCell}
          onSort={onSort}
          orderingField="validationIssueSummary"
          customOnSortOrdering={COMMON_ORDERING_PARAMS.validationIssueSummary}
          customOrderingIndex={1}
          ordering={ordering}
        >
          <span>Issue(s)</span>
        </SortableTableCell>
        <RTThemedTableCell className={styles.actionsCell}>
          Actions
        </RTThemedTableCell>
      </RTThemedTableHead>
    );

    return (
      <div>
        {editingOrderItem ? (
          <NeedReviewEditTable
            orderItems={orderItems}
            selectedOrderItems={selectedOrderItems}
            editingOrderItem={editingOrderItem}
            tableHead={tableHead}
            renderDisplayRow={renderDisplayRow}
            updatingOrderItemId={updatingOrderItemId}
            propertyTypeChoices={propertyTypeChoices}
            onToggleOrderItem={onToggleOrderItem}
            onEdit={this.handleEdit}
            onUpdateReverify={onUpdateReverify}
            onUpdateComplete={() => this.setState({ editingOrderItem: null })}
            onCancel={this.handleCancelEdit}
          />
        ) : (
          <RTThemedTable theme={styles} selectable={false}>
            {tableHead}
            {orderItems.map((orderItem: OrderItem) => {
              return renderDisplayRow({
                orderItem,
                selectedOrderItems,
                onToggleOrderItem,
                onEdit: this.handleEdit
              });
            })}
          </RTThemedTable>
        )}
        <Pagination
          routeGeneratorFn={paginationRouteGeneratorFn}
          links={links}
        />
      </div>
    );
  }
}

export default NeedReviewList;
