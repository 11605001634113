// @flow
import slug from 'slug';

export const removeFileExtension = (input: string): string => {
  // matches input with extension
  const matches = input.match(/(.*)\.[^.]+$/);
  // if no match, return original input
  const fileName: string =
    matches !== null && matches !== undefined ? matches[1] : input;
  return fileName;
};

export const addFileExtension = (
  fileName: string,
  fileExtension: string
): string => {
  return `${fileName}.${fileExtension}`;
};

export const slugifyFileName = (fileName: string): string => {
  return slug(fileName);
};
