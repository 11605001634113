/**
 * NOTE: type identifiers are messed up in flow.
 * React docgen expects 3 types, flow only supports 2
 * Once that is resolved, enable flow
 */
import React, { Component } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import { ErrorMessage } from '../error-message';
import { FormSubmit } from '../form-submit';

import defaultTheme from './JoinOrg.scss';

type JoinOrgTheme = {
  JoinOrg: string,
  FormSubmit: string
}

type Props = {
  /** Join org callback */
  onJoinOrg: (code: string, email: string) => void,
  /** invitation code to join */
  invitationCode: string,
  /** the inviting company's name */
  companyName: string,
  /** Invitee's first name */
  firstName: string,
  /** Invitee's email */
  email: string,
  /** Error on join */
  errorMessage?: ?string,
  /** currently submitting */
  isSubmitting: boolean,
  /** Optional className */
  className?: string,
  /** React Css Themr theme */
  theme: JoinOrgTheme
}

type State = {}

export class JoinOrg extends Component <Props, State, void> {
  handleSubmit = () => {
    this.props.onJoinOrg(this.props.invitationCode, this.props.email);
  }

  render () {
    const {
      className,
      errorMessage,
      isSubmitting,
      firstName,
      companyName,
      theme
    } = this.props;
    const combinedClassName = className ? classNames(theme.JoinOrg, className) : theme.JoinOrg;

    return (
      <div className={combinedClassName}>
        <p>Hey {firstName},</p>
        <p>{companyName} has invited you to join them.</p>

        <FormSubmit
          theme={theme}
          disabled={isSubmitting}
          onSubmit={this.handleSubmit}
          label={isSubmitting ? 'Joining' : 'Join'} />
        {errorMessage && <ErrorMessage theme={theme} value={errorMessage} />}
      </div>
    );
  }
}

export default themr('JoinOrgThemed', defaultTheme)(JoinOrg);
