// @flow
import { type WorkflowWorker } from '../types/workflow';

import {
  type WorkflowWorkerAction,
  WORKFLOW_WORKERS_REFRESH,
  WORKFLOW_WORKERS_LOAD_SUCCESS,
  WORKFLOW_WORKERS_LOAD_ERROR,
  WORKFLOW_WORKER_TASKS_COUNT_SUCCESS
} from '../actions/workflow-workers.actions';
import { omit } from 'lodash-es';

export type WorkflowWorkersInitState = {
  status: 'INIT'
};

export type WorkflowWorkersRefreshState = {
  status: 'LOADING',
  workers: WorkflowWorker[]
};

export type WorkflowWorkersSuccessState = {
  status: 'SUCCESS',
  workers: WorkflowWorker[]
};

export type WorkflowWorkersErrorState = {
  status: 'ERROR',
  reason: string
};

export type WorkflowWorkersState =
  | WorkflowWorkersInitState
  | WorkflowWorkersRefreshState
  | WorkflowWorkersSuccessState
  | WorkflowWorkersErrorState;

const defaultState = {
  status: 'INIT'
};

export function workflowWorkersReducer(
  previousState: WorkflowWorkersState = defaultState,
  action: WorkflowWorkerAction
): WorkflowWorkersState {
  switch (action.type) {
    case WORKFLOW_WORKERS_REFRESH: {
      if (previousState.status === 'SUCCESS') {
        const newState: WorkflowWorkersRefreshState = {
          ...omit(previousState, ['status']),
          status: 'LOADING'
        };
        return newState;
      } else {
        return previousState;
      }
    }
    case WORKFLOW_WORKERS_LOAD_SUCCESS: {
      return {
        status: 'SUCCESS',
        workers: action.payload.workers
      };
    }
    case WORKFLOW_WORKERS_LOAD_ERROR: {
      return {
        status: 'ERROR',
        reason: action.payload.error
      };
    }

    case WORKFLOW_WORKER_TASKS_COUNT_SUCCESS: {
      const { workersTaskCounts } = action.payload;
      if (previousState.status === 'SUCCESS') {
        const newState: WorkflowWorkersSuccessState = {
          ...previousState,
          workers: previousState.workers.map((worker) => {
            return {
              ...worker,
              ...(Object.prototype.hasOwnProperty.call(
                workersTaskCounts,
                worker.worker_id
              )
                ? { count: workersTaskCounts[worker.worker_id] }
                : {})
            };
          })
        };
        return newState;
      } else {
        return previousState;
      }
    }

    default:
      return previousState;
  }
}
