// @flow
import React from 'react';

import HcLogo from 'src/components/HcLogo';
import { logoutRequest } from '../actions/admin-user.actions';
import { connect } from 'react-redux';
import type { MapStateToProps } from 'react-redux';
import type { Dispatch } from 'redux';
import type { State } from 'src/reducers';
import { Button } from '@hc/component-lib';

import styles from './AdminHeader.css';

type AdminHeaderProps = {
  onLogout: () => void,
  isLoggedIn: boolean
};

class AdminHeader extends React.Component<AdminHeaderProps> {
  render() {
    const { onLogout, isLoggedIn } = this.props;

    return (
      <div className={styles.appBarContents}>
        <div className={styles.header}>
          <div className={styles.header}>
            <HcLogo />
            <span className={styles.headerAdmin}>Admin Panel</span>
          </div>
          {isLoggedIn && (
            <Button primary={false} micro onClick={onLogout}>
              Logout
            </Button>
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onLogout: () => dispatch(logoutRequest())
  };
}

const mapStateToProps: MapStateToProps<*, *, *> = (state: State) => {
  return {
    isLoggedIn:
      state.admin.user && state.admin.user.user && state.admin.user.user.token
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
