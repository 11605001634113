// @flow
import { takeEvery, select, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { CancelOrderActionType } from 'src/client/actions/cancel-order.actions';
import {
  CANCEL_ORDER,
  cancelOrderSuccess,
  cancelOrderError
} from 'src/client/actions/cancel-order.actions';
import { Client } from 'src/api/order-manager-api-client';
import { handleStandardExceptions } from 'src/sagas/helpers';
import userTokenSelector from 'src/selectors/user-token.selector';
import { loadCurrentOrderItems } from './order-item.saga';

export function* handleCancelOrder(action: CancelOrderActionType): Saga<void> {
  const { id, reloadItems } = action.payload;
  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    yield call([client, client.cancelOrder], id);
    yield put(cancelOrderSuccess(id));
    if (reloadItems) {
      yield call(loadCurrentOrderItems);
    }
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(cancelOrderError(id, e.message));
  }
}

export function* registerCancelOrderSaga(): Saga<void> {
  yield takeEvery(CANCEL_ORDER, handleCancelOrder);
}
