// @flow
import {
  type WorkflowExternalTask,
  type ExternalTaskParams
} from '../types/workflow';

export const WORKFLOW_WORKER_EXTERNAL_TASKS_LOAD =
  'WORKFLOW_WORKER_EXTERNAL_TASKS_LOAD';
export const WORKFLOW_WORKER_EXTERNAL_TASKS_SUCCESS =
  'WORKFLOW_WORKER_EXTERNAL_TASKS_SUCCESS';
export const WORKFLOW_WORKER_EXTERNAL_TASKS_ERROR =
  'WORKFLOW_WORKER_EXTERNAL_TASKS_ERROR';

export const WORKFLOW_WORKER_EXTERNAL_TASK_DETAIL =
  'WORKFLOW_WORKER_EXTERNAL_TASK_DETAIL';

export const WORKFLOW_PAGINATION_FIRST_PAGE = 'WORKFLOW_PAGINATION_FIRST_PAGE';
export const WORKFLOW_PAGINATION_PREVIOUS_PAGE =
  'WORKFLOW_PAGINATION_PREVIOUS_PAGE';
export const WORKFLOW_PAGINATION_NEXT_PAGE = 'WORKFLOW_PAGINATION_NEXT_PAGE';
export const WORKFLOW_PAGINATION_LAST_PAGE = 'WORKFLOW_PAGINATION_LAST_PAGE';

export type WorkfloWExternalTasksLoadAction = {
  type: 'WORKFLOW_WORKER_EXTERNAL_TASKS_LOAD',
  payload: { params: ExternalTaskParams }
};
export type WorkflowExternalTasksSuccessAction = {
  type: 'WORKFLOW_WORKER_EXTERNAL_TASKS_SUCCESS',
  payload: { tasks: WorkflowExternalTask[], count: number }
};
export type WorkflowExternalTasksErrorAction = {
  type: 'WORKFLOW_WORKER_EXTERNAL_TASKS_ERROR',
  payload: { error: string }
};

export type WorkflowExternalTasksDetailAction = {
  type: 'WORKFLOW_WORKER_EXTERNAL_TASK_DETAIL',
  payload: { task: WorkflowExternalTask }
};

export type WorkflowPaginationFirstPageAction = {
  type: 'WORKFLOW_PAGINATION_FIRST_PAGE'
};
export type WorkflowPaginationPreviousPageAction = {
  type: 'WORKFLOW_PAGINATION_PREVIOUS_PAGE'
};
export type WorkflowPaginationNextPageAction = {
  type: 'WORKFLOW_PAGINATION_NEXT_PAGE'
};
export type WorkflowPaginationLastPageAction = {
  type: 'WORKFLOW_PAGINATION_LAST_PAGE'
};

export type WorkflowExternalTasksAction =
  | WorkfloWExternalTasksLoadAction
  | WorkflowExternalTasksSuccessAction
  | WorkflowExternalTasksErrorAction
  | WorkflowExternalTasksDetailAction
  | WorkflowPaginationFirstPageAction
  | WorkflowPaginationPreviousPageAction
  | WorkflowPaginationNextPageAction
  | WorkflowPaginationLastPageAction;

export const workflowExternalTasksLoad = (
  params: ExternalTaskParams
): WorkfloWExternalTasksLoadAction => ({
  type: WORKFLOW_WORKER_EXTERNAL_TASKS_LOAD,
  payload: { params }
});
export const workflowExternalTasksSuccess = (
  tasks: WorkflowExternalTask[],
  count: number
): WorkflowExternalTasksSuccessAction => ({
  type: WORKFLOW_WORKER_EXTERNAL_TASKS_SUCCESS,
  payload: { tasks, count }
});
export const workflowExternalTasksError = (
  error: string
): WorkflowExternalTasksErrorAction => ({
  type: WORKFLOW_WORKER_EXTERNAL_TASKS_ERROR,
  payload: { error }
});

export const workflowExternalTaskDetail = (task: ?WorkflowExternalTask) => ({
  type: WORKFLOW_WORKER_EXTERNAL_TASK_DETAIL,
  payload: { task }
});

export const workflowPaginationFirstPage =
  (): WorkflowPaginationFirstPageAction => ({
    type: WORKFLOW_PAGINATION_FIRST_PAGE
  });
export const workflowPaginationPreviousPage =
  (): WorkflowPaginationPreviousPageAction => ({
    type: WORKFLOW_PAGINATION_PREVIOUS_PAGE
  });
export const workflowPaginationNextPage =
  (): WorkflowPaginationNextPageAction => ({
    type: WORKFLOW_PAGINATION_NEXT_PAGE
  });
export const workflowPaginationLastPage =
  (): WorkflowPaginationLastPageAction => ({
    type: WORKFLOW_PAGINATION_LAST_PAGE
  });
