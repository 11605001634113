import createSagaMiddleware from 'redux-saga';
import { routerSaga } from './router.saga';
import {
  registerLogin,
  registerLogout,
  registerForgotPassword,
  registerResetPassword
} from './user.saga';
import { runSagas as runClientSagas } from 'src/client/sagas';
import { runSagas as runPartnerSagas } from 'src/partner/sagas';
import { runSagas as runAdminSagas } from 'src/admin/sagas';
import { registerHandleStandardExceptions } from './exceptions.saga';

export const sagaMiddleware = createSagaMiddleware();

export function runSagas() {
  sagaMiddleware.run(routerSaga);
  sagaMiddleware.run(registerLogin);
  sagaMiddleware.run(registerLogout);
  sagaMiddleware.run(registerForgotPassword);
  sagaMiddleware.run(registerResetPassword);
  sagaMiddleware.run(registerHandleStandardExceptions);
  runClientSagas();
  runPartnerSagas();
  runAdminSagas();
}
