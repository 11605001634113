// @flow

import { ADMIN_ROUTE_ACTIONS } from './admin/route.actions';
import { CLIENT_ROUTE_ACTIONS } from './client/route.actions';
import { PARTNER_ROUTE_ACTIONS } from './partner/route.actions';
import { REDIRECTS } from './routes';
import * as logger from './logger';
import { history } from './history/history';

const REDIRECT_ACTIONS = Object.keys(REDIRECTS).reduce((routeActions, key) => {
  const def = REDIRECTS[key];
  routeActions[key] = redirectSaga(def);
  return routeActions;
}, {});

function redirectSaga(f) {
  return function (args) {
    try {
      history.replace(f(args));
    } catch (e) {
      logger.logException(e, 'Error in redirect saga');
    }
  };
}

export const ROUTE_ACTIONS = {
  ...CLIENT_ROUTE_ACTIONS,
  ...PARTNER_ROUTE_ACTIONS,
  ...ADMIN_ROUTE_ACTIONS,
  ...REDIRECT_ACTIONS
};
