// @flow
import { combineReducers } from 'redux';

import type { AcceptOrderState } from './accept-order.reducer';
import type { ReviewValuationState } from './review-valuation.reducer';
import type { ValuationCommentState } from './valuation-comment.reducer';
import type { ValuationRevisionState } from './valuation-revision.reducer';

import { acceptOrderReducer } from './accept-order.reducer';
import { reviewValuationReducer } from './review-valuation.reducer';
import { valuationCommentReducer } from './valuation-comment.reducer';
import { valuationRevisionReducer } from './valuation-revision.reducer';

export type PartnerState = {
  acceptOrder: AcceptOrderState,
  reviewValuation: ReviewValuationState,
  valuationComment: ValuationCommentState,
  valuationRevision: ValuationRevisionState
};

const partnerReducer = combineReducers({
  acceptOrder: acceptOrderReducer,
  reviewValuation: reviewValuationReducer,
  valuationComment: valuationCommentReducer,
  valuationRevision: valuationRevisionReducer
});

export default partnerReducer;
