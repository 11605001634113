import { sagaMiddleware } from 'src/sagas';
import {
  registerAddOrderSaga,
  registerGetOrderTypeDescriptorsSaga
} from './add-order.saga';
import { fetchMessagesSaga, postMessageSaga } from './message.saga';
import {
  registerOrderSeach,
  registerOrderSort,
  registerChangeOrderSet,
  registerChangeOwnerId,
  registerFetchOrderProgress,
  registerDownloadOrderSummary,
  registerWatchOrderIdsToReload,
  registerThrottledFetchOrders,
  registerWebsocketOrderUpdated,
  registerWebsocketOrderItemUpdated,
  registerViewOrderReport
} from './order.saga';
import {
  registerDownloadOrderItem,
  registerOrderItemSort,
  registerOrderItemSearch,
  registerDownloadOrderItemPdf,
  registerFetchSelectedOrderOwner,
  registerUpdateReverifyItem,
  registerAddItemsToOrder,
  registerRemoveItemFromOrder,
  registerRecoverRemovedItems,
  registerReviewAcceptOrder,
  registerReviewCancelOrder,
  registerValidateAddressFieldSuccess,
  registerValidateAddressFieldError,
  registerSelectOrderItemGroup,
  registerWebsocketOrderUpdated as registerWebsocketOrderUpdatedForItems,
  registerWatchOrderItemIdsToReload,
  registerWebsocketOrderItemUpdated as registerWebsocketOrderItemUpdatedForItems
} from './order-item.saga';
import { registerValidationAddress } from './address-validation.saga';
import { registerCancelOrderSaga } from './cancel-order.saga';
import { registerCancelOrderItemSaga } from './cancel-order-item.saga';
import { registerExtendOrderSaga } from './extend-order.saga';
import { registerRevisionRequestSaga } from './revision-request.saga';
import {
  registerRequestOrderExport,
  registerOpenOrderExports
} from './order-export.saga';
import {
  registerOrderItemsGoToPage,
  registerReportSummaryLoadItems,
  registerReportSummaryDownloadCSV
} from './report-summary.saga';
import { registerWebsocketFlow } from './websocket.saga';
import { registerSupportDocumentsSaga } from './supporting-documents.saga';
import { registerEvaluationOrders } from './evaluation-order.saga';

export function runSagas() {
  sagaMiddleware.run(registerAddOrderSaga);
  sagaMiddleware.run(registerGetOrderTypeDescriptorsSaga);
  sagaMiddleware.run(fetchMessagesSaga);
  sagaMiddleware.run(postMessageSaga);
  sagaMiddleware.run(registerOrderSeach);
  sagaMiddleware.run(registerOrderSort);
  sagaMiddleware.run(registerChangeOrderSet);
  sagaMiddleware.run(registerChangeOwnerId);
  sagaMiddleware.run(registerFetchOrderProgress);
  sagaMiddleware.run(registerDownloadOrderSummary);
  sagaMiddleware.run(registerWatchOrderIdsToReload);
  sagaMiddleware.run(registerThrottledFetchOrders);
  sagaMiddleware.run(registerWebsocketOrderUpdated);
  sagaMiddleware.run(registerWebsocketOrderItemUpdated);
  sagaMiddleware.run(registerViewOrderReport);
  sagaMiddleware.run(registerDownloadOrderItem);
  sagaMiddleware.run(registerOrderItemSort);
  sagaMiddleware.run(registerOrderItemSearch);
  sagaMiddleware.run(registerDownloadOrderItemPdf);
  sagaMiddleware.run(registerFetchSelectedOrderOwner);
  sagaMiddleware.run(registerUpdateReverifyItem);
  sagaMiddleware.run(registerAddItemsToOrder);
  sagaMiddleware.run(registerRemoveItemFromOrder);
  sagaMiddleware.run(registerRecoverRemovedItems);
  sagaMiddleware.run(registerReviewAcceptOrder);
  sagaMiddleware.run(registerReviewCancelOrder);
  sagaMiddleware.run(registerValidateAddressFieldSuccess);
  sagaMiddleware.run(registerValidateAddressFieldError);
  sagaMiddleware.run(registerSelectOrderItemGroup);
  sagaMiddleware.run(registerWebsocketOrderUpdatedForItems);
  sagaMiddleware.run(registerWatchOrderItemIdsToReload);
  sagaMiddleware.run(registerWebsocketOrderItemUpdatedForItems);
  sagaMiddleware.run(registerValidationAddress);
  sagaMiddleware.run(registerCancelOrderSaga);
  sagaMiddleware.run(registerCancelOrderItemSaga);
  sagaMiddleware.run(registerExtendOrderSaga);
  sagaMiddleware.run(registerRevisionRequestSaga);
  sagaMiddleware.run(registerRequestOrderExport);
  sagaMiddleware.run(registerOpenOrderExports);
  sagaMiddleware.run(registerOrderItemsGoToPage);
  sagaMiddleware.run(registerReportSummaryLoadItems);
  sagaMiddleware.run(registerReportSummaryDownloadCSV);
  sagaMiddleware.run(registerWebsocketFlow);
  sagaMiddleware.run(registerSupportDocumentsSaga);
  sagaMiddleware.run(registerEvaluationOrders);
}
