import React, { cloneElement, Component } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import { ErrorMessage } from '../error-message';
import { PasswordField } from '../password-field';
import { FormSubmit } from '../form-submit';

import defaultTheme from './ResetPassword.scss';

type ResetPasswordTheme = {
  ResetPasswordForm: string,
  ResetPasswordTitle: string,
  FormSubmit: string
}

type Props = {
  /** Optional custom password field */
  newPasswordField?: any,
  /** Optional confirm password field */
  confirmPasswordField?: any,
  /** Optional disable the submit button even if the form is valid */
  disableSubmitBtn: boolean,
  /** submit callback */
  onSubmit: (newPassword: string) => void,
  /** React Css Themr theme */
  theme: ResetPasswordTheme,
  /** Title */
  title: ?string,
  isSubmitting: boolean,
  /** Focus on the new password field when the page loads */
  focusFirstFieldOnMount: boolean
}

type State = {
  newPassword: string,
  confirmPassword: string,
  newPasswordIsValid: boolean,
  confirmPasswordIsValid: boolean,
  errorMessageMatch: string,
  isPristine: boolean
}

export class ResetPassword extends Component <Props, State, void> {
  state = {
    newPassword: '',
    confirmPassword: '',
    newPasswordIsValid: false,
    confirmPasswordIsValid: false,
    errorMessageMatch: '',
    isPristine: true
  }

  static defaultProps = {
    title: null,
    newPasswordField: (<PasswordField label='New Password' />),
    confirmPasswordField: (<PasswordField label='Confirm Password' />),
    disableSubmitBtn: false,
    onSubmit: () => {},
    focusFirstFieldOnMount: true
  }

  handleChange = (e: SyntheticInputEvent) => {
    this.setState({
      [e.target.name]: e.target.value,
      isPristine: false
    });
  }

  handleValid = (val: string, e: SyntheticInputEvent<*>) => {
    const {
      newPassword,
      confirmPassword
    } = this.state;
    const otherField = e.target.name === 'newPassword' ? 'confirmPassword' : 'newPassword';
    const errorMessageMatch = (
      this.state[`${otherField}IsValid`] &&
      newPassword !== confirmPassword) ? 'The passwords do not match.' : '';
    this.setState({ [`${e.target.name}IsValid`]: true, errorMessageMatch });
  }

  handleInvalid = (val: string, e: SyntheticInputEvent<*>) => {
    this.setState({ [`${e.target.name}IsValid`]: false });
  }

  validatePasswords = () => {
    const {
      newPassword,
      confirmPassword,
      newPasswordIsValid,
      confirmPasswordIsValid
    } = this.state;
    return (
      !!newPassword &&
      !!confirmPassword !== '' &&
      newPasswordIsValid &&
      confirmPasswordIsValid &&
      newPassword === confirmPassword
    );
  }

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ isPristine: true });
    this.props.onSubmit(this.state.newPassword);
  }

  render () {
    const { className, errorMessage, isSubmitting, disableSubmitBtn, title, focusFirstFieldOnMount, theme } = this.props;
    const { newPassword, confirmPassword, errorMessageMatch, isPristine } = this.state;
    const validPasswords = this.validatePasswords();
    const combinedClassName = className ? classNames(theme.ResetPasswordForm, className) : theme.ResetPasswordForm;
    const showServerError = errorMessage && isPristine;

    return (
      <div className={combinedClassName}>
        {title && <h2 className={theme.ResetPasswordTitle}>{title}</h2>}
        <form onSubmit={this.submitForm}>
          {
            cloneElement(this.props.newPasswordField, {
              name: 'newPassword',
              value: newPassword,
              onChange: this.handleChange,
              onValid: this.handleValid,
              onInvalid: this.handleInvalid,
              autoFocus: focusFirstFieldOnMount
            })
          }
          {
            cloneElement(this.props.confirmPasswordField, {
              name: 'confirmPassword',
              value: confirmPassword,
              onChange: this.handleChange,
              onValid: this.handleValid,
              onInvalid: this.handleInvalid
            })
          }
          {errorMessageMatch && <ErrorMessage theme={theme} value={errorMessageMatch} />}
          {showServerError && <ErrorMessage theme={theme} value={errorMessage} />}
          <FormSubmit
            theme={theme}
            disabled={disableSubmitBtn || isSubmitting || !validPasswords || showServerError}
            onSubmit={this.submitForm}
            type='submit'
            label={isSubmitting ? 'Submitting' : 'Submit'} />
        </form>
      </div>
    );
  }
}

export default themr('ResetPasswordThemed', defaultTheme)(ResetPassword);
