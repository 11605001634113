// @flow
import React from 'react';
import SelectableChip from './SelectableChip';

import styles from './ReportSummaryFilters.css';

type ReportSummaryFiltersProps = {
  selectedFilters: string[],
  filters: string[],
  onSelectFilter: (?string) => void,
  onUnselectFilter: (string) => void
};

const ReportSummaryFilters = (props: ReportSummaryFiltersProps) => {
  const { selectedFilters, filters, onSelectFilter, onUnselectFilter } = props;
  const filterList = filters.map((filter, index) => (
    <SelectableChip
      key={index}
      label={filter}
      isSelected={selectedFilters.indexOf(filter) > -1}
      onSelected={onSelectFilter}
      onUnselected={onUnselectFilter}
    />
  ));

  return (
    <div data-hc-name="filters" className={styles.container}>
      <div className={styles.label}>Labels:</div>

      <div className={styles.filters}>
        <SelectableChip
          className={styles.filter}
          label="All"
          isSelected={selectedFilters.length === 0}
          onSelected={() => onSelectFilter()}
          onUnselected={onUnselectFilter}
        />
        {filterList}
      </div>
    </div>
  );
};

export default ReportSummaryFilters;
