// @flow
import React from 'react';
import { themr } from 'react-css-themr';
import linkTheme from './Link.scss';

type LinkProps = {
  theme: {
    Link: string
  },
  target: string
}

export const Link = ({ theme, target = '_self', ...props } : LinkProps) => {
  return <a className={theme.Link} target={target} {...props} />;
};

export const ThemedLink = themr('ThemedLink', linkTheme)(Link);
export default ThemedLink;
