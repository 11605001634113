// @flow
import type { Action } from '../actions';
import type { User } from '../types/user';

import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGOUT_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILURE,
  ADMIN_LOGIN_SET_SUCCESS_ROUTE
} from '../actions/admin-user.actions';

export type AdminUserState = {
  user: ?User,
  loading: boolean,
  loginFailed: boolean,
  successRoute: ?string
};

const defaultState = {
  user: null,
  loading: false,
  loginFailed: false,
  successRoute: null
};

export function adminUserReducer(
  state: AdminUserState = defaultState,
  action: Action
): AdminUserState {
  switch (action.type) {
    case ADMIN_LOGIN_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case ADMIN_LOGOUT_REQUEST: {
      return {
        ...defaultState,
        successRoute: action.payload.route
      };
    }
    case ADMIN_LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        loading: false,
        loginFailed: false
      };
    }
    case ADMIN_LOGIN_FAILURE: {
      return {
        ...state,
        user: null,
        loading: false,
        loginFailed: true
      };
    }
    case ADMIN_LOGIN_SET_SUCCESS_ROUTE: {
      return {
        ...state,
        successRoute: action.payload.route
      };
    }
    default: {
      return state;
    }
  }
}
