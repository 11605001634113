// @flow
import React from 'react';
import { RTThemedDialog } from '@hc/component-lib/lib/rt-themed';
import { FlatButton } from '@hc/component-lib';

import LoadingButton from 'src/components/loading-button/LoadingButton';

import commonStyles from 'src/containers/common.css';
import styles from './ReviewCancelOrderDialog.css';

type ReviewCancelOrderDialogProps = {
  show: boolean,
  loading: boolean,
  onCancel: () => void,
  onDismiss: () => void,
  errorMessage: ?string
};

const ReviewCancelOrderDialog = (props: ReviewCancelOrderDialogProps) => {
  const { show = true, loading, onCancel, onDismiss, errorMessage } = props;

  return (
    <RTThemedDialog
      theme={styles}
      title="Canceling Order"
      onEscKeyDown={onDismiss}
      active={show}
    >
      <p>
        Are you sure you want to cancel this order? Once you cancel this order,
        it will be permanently removed. Please confirm that you want to delete
        before proceeding.
      </p>

      <nav role="navigation" className={styles.navigation}>
        <FlatButton
          theme={styles}
          label="No, keep this order"
          primary={false}
          disabled={loading}
          onClick={onDismiss}
        />

        <LoadingButton
          className={styles.loadingButton}
          theme={styles}
          loading={loading}
          disabled={loading}
          onClick={onCancel}
        >
          <span>Yes, Cancel this order</span>
        </LoadingButton>
      </nav>
      {errorMessage && (
        <p className={commonStyles.errorMessage}>{errorMessage}</p>
      )}
    </RTThemedDialog>
  );
};

export default ReviewCancelOrderDialog;
