// @flow
import React, { PureComponent } from 'react';
import { phoneValidator } from '../../services/validator';
import PhoneField from './PhoneField';

/** Pass thorugh all props */
type Props = {};

/**
 * Standard text field with required validator
 */
export class SignupPhoneField extends PureComponent <Props> {
  render () {
    return <PhoneField validate={[phoneValidator]} {...this.props} />;
  }
}

export default SignupPhoneField;
