// @flow
import { call, put, select, all, takeEvery } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import {
  WORKFLOW_WORKERS_REFRESH,
  loadWorkflowWorkersSuccess,
  loadWorkflowWorkersError,
  loadWorkflowWorkerCountSuccess
} from '../actions/workflow-workers.actions';

import { Client } from '../api/admin-api-client';
import { resolveUserSaga } from 'src/sagas/user.saga';
import { handleStandardExceptions, requireAdminToken } from './helpers';
import userTokenSelector from 'src/selectors/user-token.selector';

export function* routeWorkflowWorkers(): Saga<void> {
  // @TODO handle params from route
  yield call(resolveUserSaga, true);
  yield call(loadWorkflowWorkers);
  yield call(loadWorkflowWorkersCounts);
}

function* handleRefreshWorkflowWorkers(): Saga<void> {
  // @TODO handle params from action
  yield call(loadWorkflowWorkers);
  yield call(loadWorkflowWorkersCounts);
}

export function* loadWorkflowWorkers(): Saga<void> {
  try {
    const token = yield call(requireAdminToken);
    const client = new Client();
    const workers = yield call([client, client.getWorkers], token);
    yield put(loadWorkflowWorkersSuccess(workers));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(loadWorkflowWorkersError(e.message));
  }
}

export function* loadWorkflowWorkersCounts(): Saga<void> {
  const workersState = yield select((state) => state.admin.camunda.workers);
  const userToken = yield select(userTokenSelector);
  if (workersState.status === 'SUCCESS') {
    try {
      const workers = workersState.workers;
      const allWorkerTaskCounts = yield all(
        workers.map((worker) =>
          loadWorkflowWorkerIdCount(userToken, worker.worker_id)
        )
      );
      const workersTaskCounts = allWorkerTaskCounts.reduce(
        (workersTaskCounts, workerCount) => {
          return {
            ...workersTaskCounts,
            [workerCount.workerId]: workerCount.count
          };
        },
        {}
      );
      yield put(loadWorkflowWorkerCountSuccess(workersTaskCounts));
    } catch (e) {
      yield call(handleStandardExceptions, e);
      console.error(e);
    }
  } else {
    // this shouldn't happen
    console.error(
      'Camunda Workflow: workers were not loaded prior to fetching counts'
    );
  }
}

function* loadWorkflowWorkerIdCount(
  token: string,
  workerId: string
): Saga<{ workerId: string, count: number }> {
  const client = new Client();
  const countResponse = yield call(
    [client, client.getExternalTasksCount],
    token,
    { workerId: workerId }
  );
  return { workerId, count: countResponse.count };
}

export function* registerWorkflowWorkerSagas(): Saga<void> {
  yield takeEvery(WORKFLOW_WORKERS_REFRESH, handleRefreshWorkflowWorkers);
}
