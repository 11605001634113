// @flow
import type { UserContext, UserApplication, ComponentAccess } from '../types';

/**
 * Get user application permissions from the current organization
 * @param {UserContext} user
 * @return {UserApplication[]}
 */
export const getUserApplicationsPermissions = (
  userContext: UserContext
): UserApplication[] => {
  const { user } = userContext;
  return (user && user.user_applications) || [];
};

/**
 * Determine if user has access to an application.
 * @param {UserContext} userContext
 * @param {string} applicationName
 * @return {boolean}
 */
export const userHasApplicationPermissions = (
  userContext: UserContext,
  applicationName: string
): boolean => {
  const userApplicationPermissions =
    getUserApplicationsPermissions(userContext);
  const userAppPermission = userApplicationPermissions.find(
    (permission) => permission.application.name === applicationName
  );
  // userAppPermission is not present when user is in trial mode or is admin for organization
  return (
    !userAppPermission ||
    userAppPermission.state === 'allow' ||
    userAppPermission.state === 'grant'
  );
};

/**
 * Use this helper function with AuthApiClient#checkComponentAccess
 * @param {CheckComponentAccess} componentAccess
 */
export const userHasComponentPermissions = (
  componentAccess: ComponentAccess
): boolean => {
  return componentAccess.access;
};

/**
 * Determine is user's current organization is a test organization
 * @param {UserContext} userContext
 * @return {boolean}
 */
export const userInTestOrg = (userContext: UserContext): boolean => {
  return (
    userContext &&
    userContext.current_organization &&
    userContext.current_organization.test
  );
};
