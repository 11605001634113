import desktopIcon from '../../icons/desktop.svg';
import exteriorIcon from '../../icons/exterior.svg';
import interiorExteriorIcon from '../../icons/interior_exterior.svg';
import type { ProductType, GroupKey } from '../types/add-order';

export const PRODUCT_IDS: { [key: GroupKey]: ProductType } = {
  agile_appraisal: 'AGILE_APPRAISAL',
  agile_evaluation: 'AGILE_EVALUATION',
  agile_evaluation_certified: 'AGILE_EVALUATION_CERTIFIED',
  inspection: 'CERTIFIED_INSPECTION',
  excel_data: 'EXCEL_DATA',
  agile_insights: 'VALUE_REPORT',
  agile_certified: 'AGILE_CERTIFIED'
};

export const AGILE_APPRAISAL_GROUP_KEY: GroupKey = 'agile_appraisal';

export const GROUP_KEYS: GroupKey[] = [
  'agile_insights',
  'agile_evaluation',
  'agile_certified',
  'agile_evaluation_certified',
  AGILE_APPRAISAL_GROUP_KEY,
  'inspection',
  'excel_data'
];

export const ICONS = {
  exterior: exteriorIcon,
  interior_exterior: interiorExteriorIcon,
  desktop: desktopIcon
};
