// @flow
import type { State } from 'src/reducers';
import type { OrderExportRequestState } from 'src/client/reducers/order-export-request.reducer';
import type { OrderExportsState } from 'src/client/reducers/order-exports.reducer';

import clientSelector from 'src/client/selectors/client.selector';

export const orderExportRequestStateSelector = (
  state: State
): OrderExportRequestState => clientSelector(state).orderExportRequest;
export const orderExportsStateSelector = (state: State): OrderExportsState =>
  clientSelector(state).orderExports;
