// @flow
import React from 'react';

import hcLogo from './HcLogo.svg';
import styles from './HcLogo.css';
// import { getResellerInfo } from 'src/helpers/reseller-helpers';

// const resellerInfo = getResellerInfo();
const logo = hcLogo;

const HcLogo = () => <img src={logo} className={styles.logoImg} />;

export default HcLogo;
