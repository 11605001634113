// @flow
import type { Action } from 'src/client/actions';

import {
  UPDATE_REVERIFY_ITEM,
  UPDATE_REVERIFY_ITEM_CANCELLED,
  UPDATE_REVERIFY_ITEM_SUCCESS,
  UPDATE_REVERIFY_ITEM_ERROR
} from 'src/client/actions/order-items.actions';

type UpdateReverifyItemInitState = {
  status: 'init'
};
type UpdateReverifyItemLoadingState = {
  status: 'loading',
  orderId: string,
  orderItemId: string,
  onUpdateComplete: () => void,
  formName: string
};
type UpdateReverifyItemErrorState = {
  status: 'error',
  orderItemId: string,
  errorMessage: string
};
export type UpdateReverifyItemState =
  | UpdateReverifyItemInitState
  | UpdateReverifyItemLoadingState
  | UpdateReverifyItemErrorState;

const defaultState = {
  status: 'init'
};

export function updateReverifyItemReducer(
  state: UpdateReverifyItemState = defaultState,
  action: Action
): UpdateReverifyItemState {
  switch (action.type) {
    case UPDATE_REVERIFY_ITEM: {
      return {
        status: 'loading',
        orderId: action.payload.orderId,
        orderItemId: action.payload.orderItemId,
        onUpdateComplete: action.payload.onUpdateComplete,
        formName: action.payload.formName
      };
    }
    case UPDATE_REVERIFY_ITEM_SUCCESS: {
      return defaultState;
    }
    case UPDATE_REVERIFY_ITEM_CANCELLED: {
      return defaultState;
    }
    case UPDATE_REVERIFY_ITEM_ERROR: {
      return {
        status: 'error',
        orderItemId: action.payload.orderItemId,
        errorMessage: action.payload.errorMessage
      };
    }
    default: {
      return state;
    }
  }
}
