// @flow
import type { ValuationCommentDetails } from 'src/partner/types/valuation-comment';

export const VALUATION_COMMENT_RESET = 'VALUATION_COMMENT_RESET';
export const VALUATION_COMMENT_LOAD_COMPLETE =
  'VALUATION_COMMENT_LOAD_COMPLETE';
export const VALUATION_COMMENT_LOAD_ERROR = 'VALUATION_COMMENT_LOAD_ERROR';
export const VALUATION_COMMENT_ADD = 'VALUATION_COMMENT_ADD';
export const VALUATION_COMMENT_ADD_COMPLETE = 'VALUATION_COMMENT_ADD_COMPLETE';

export type ValuationCommentResetAction = {
  type: 'VALUATION_COMMENT_RESET'
};

export type ValuationCommentLoadCompleteAction = {
  type: 'VALUATION_COMMENT_LOAD_COMPLETE',
  payload: {
    orderId: string,
    details: ValuationCommentDetails,
    token: string
  }
};

export type ValuationCommentLoadErrorAction = {
  type: 'VALUATION_COMMENT_LOAD_ERROR',
  payload: {
    message: string
  }
};

export type ValuationCommentAddAction = {
  type: 'VALUATION_COMMENT_ADD',
  payload: {
    comment: string,
    actionRequired: boolean
  }
};

export type ValuationCommentAddCompleteAction = {
  type: 'VALUATION_COMMENT_ADD_COMPLETE',
  payload: {
    success: boolean
  }
};

export type ValuationCommentAction =
  | ValuationCommentResetAction
  | ValuationCommentLoadCompleteAction
  | ValuationCommentLoadErrorAction
  | ValuationCommentAddAction
  | ValuationCommentAddCompleteAction;

export const resetValuationComments = (): ValuationCommentAction => ({
  type: VALUATION_COMMENT_RESET
});
export const valuationCommentLoadComplete = (
  orderId: string,
  details: ValuationCommentDetails,
  token: string
): ValuationCommentAction => ({
  type: VALUATION_COMMENT_LOAD_COMPLETE,
  payload: { orderId, details, token }
});
export const valuationCommentLoadError = (
  message: string
): ValuationCommentAction => ({
  type: VALUATION_COMMENT_LOAD_ERROR,
  payload: { message }
});
export const valuationCommentAdd = (
  comment: string,
  actionRequired: boolean
): ValuationCommentAction => ({
  type: VALUATION_COMMENT_ADD,
  payload: { comment, actionRequired }
});
export const valuationCommentAddComplete = (
  success: boolean
): ValuationCommentAction => ({
  type: VALUATION_COMMENT_ADD_COMPLETE,
  payload: { success }
});
