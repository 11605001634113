// @flow
export const SHOW_CANCEL_ORDER_ITEM_DIALOG = 'SHOW_CANCEL_ORDER_ITEM_DIALOG';
export const DISMISS_CANCEL_ORDER_ITEM_DIALOG =
  'DISMISS_CANCEL_ORDER_ITEM_DIALOG';
export const CANCEL_ORDER_ITEM = 'CANCEL_ORDER_ITEM';
export const CANCEL_ORDER_ITEM_SUCCESS = 'CANCEL_ORDER_ITEM_SUCCESS';
export const CANCEL_ORDER_ITEM_ERROR = 'CANCEL_ORDER_ITEM_ERROR';

export type ShowCancelOrderItemDialogAction = {
  type: 'SHOW_CANCEL_ORDER_ITEM_DIALOG',
  payload: { orderId: string, orderItemId: string, address: string }
};
export type DismissCancelOrderItemDialogAction = {
  type: 'DISMISS_CANCEL_ORDER_ITEM_DIALOG'
};
export type CancelOrderItemActionType = {
  type: 'CANCEL_ORDER_ITEM',
  payload: { orderId: string, orderItemId: string }
};
export type CancelOrderItemSuccessAction = {
  type: 'CANCEL_ORDER_ITEM_SUCCESS',
  payload: { orderId: string, orderItemId: string }
};
export type CancelOrderItemErrorAction = {
  type: 'CANCEL_ORDER_ITEM_ERROR',
  payload: { orderId: string, orderItemId: string, errorMessage: string }
};

export type CancelOrderItemAction =
  | ShowCancelOrderItemDialogAction
  | DismissCancelOrderItemDialogAction
  | CancelOrderItemActionType
  | CancelOrderItemSuccessAction
  | CancelOrderItemErrorAction;

export const showCancelOrderItemDialog = (
  orderId: string,
  orderItemId: string,
  address: string
): ShowCancelOrderItemDialogAction => ({
  type: SHOW_CANCEL_ORDER_ITEM_DIALOG,
  payload: { orderId, orderItemId, address }
});
export const dismissCancelOrderItemDialog =
  (): DismissCancelOrderItemDialogAction => ({
    type: DISMISS_CANCEL_ORDER_ITEM_DIALOG
  });
export const cancelOrderItem = (
  orderId: string,
  orderItemId: string
): CancelOrderItemActionType => ({
  type: CANCEL_ORDER_ITEM,
  payload: { orderId, orderItemId }
});
export const cancelOrderItemSuccess = (
  orderId: string,
  orderItemId: string
): CancelOrderItemSuccessAction => ({
  type: CANCEL_ORDER_ITEM_SUCCESS,
  payload: { orderId, orderItemId }
});
export const cancelOrderItemError = (
  orderId: string,
  orderItemId: string,
  errorMessage: string
): CancelOrderItemErrorAction => ({
  type: CANCEL_ORDER_ITEM_ERROR,
  payload: { orderId, orderItemId, errorMessage }
});
