// @flow
import queryString from 'query-string';
import searchQuery from 'search-query-parser';
import snakeCase from 'lodash.snakecase';

import type { OrderingParams, PagingParams, QueryParams } from 'src/types/list';

const ORDER_SEARCH_FIELDS = {
  status: 'status',
  name: 'name',
  id: 'customer_order_id',
  type: 'order_type'
};

const ORDER_ITEM_SEARCH_FIELDS = {
  uid: 'id',
  id: 'customer_item_id',
  address: 'address',
  unit: 'unit',
  city: 'city',
  state: 'state',
  zipcode: 'zipcode',
  zip: 'zipcode',
  status: 'status'
};

// partner api
export const BASE_PARTNER_API_PATH = `/partner-api/v1`;
export const getAcceptOrderPath = (orderId: string) =>
  `${BASE_PARTNER_API_PATH}/valuation-orders/${orderId}/accept`;

// client api
export const BASE_CLIENT_API_PATH = `/client-api/v1`;
export const getOrdersPath = `${BASE_CLIENT_API_PATH}/orders`;
export const getCreateOrderCsvPath = `${getOrdersPath}/csv/`;
export const parseOrderingToQueryParams = (
  ordering: ?(OrderingParams[])
): string => {
  if (!ordering || ordering.length === 0) {
    return '';
  }
  const queryStringParts: string[] = ordering.map(
    (orderingParam: OrderingParams): string => {
      return orderingParam.asc
        ? `${snakeCase(orderingParam.field)}`
        : `-${snakeCase(orderingParam.field)}`;
    }
  );
  return `ordering=${queryStringParts.join(',')}`;
};
export const getOrdersWithQueryStringPath = (params: QueryParams) => {
  const { filters, ordering, query, ...rest } = params;
  const orderingParam = `${parseOrderingToQueryParams(ordering)}`;
  const searchParams = parseComplexSearchToQueryParams(
    query,
    ORDER_SEARCH_FIELDS
  );
  let status = [];
  let id = [];
  let ownerId = [];
  if (filters) {
    status = filters.status || status;
    id = filters.id || id;
    ownerId = filters.ownerId || ownerId;
  }

  if (!searchParams['status']) {
    searchParams.status = status;
  }

  if (!searchParams['id']) {
    searchParams.id = id;
  }

  if (!searchParams['ownerId']) {
    searchParams.owner_id = ownerId;
  }

  return `${getOrdersPath}?page_size=50&${queryString.stringify(
    rest
  )}&${orderingParam}&${queryString.stringify(searchParams)}`;
};

export const getOrderPath = (orderId: string) => `${getOrdersPath}/${orderId}`;
export const getReviewAcceptOrderPath = (orderId: string) =>
  `${getOrderPath(orderId)}/accept`;
export const getReviewRejectOrderPath = (orderId: string) =>
  `${getOrderPath(orderId)}/reject`;
export const getOrderProgressPath = (orderId: string) =>
  `${getOrderPath(orderId)}/progress`;
export const getOrderSummaryCsvPath = (orderId: string) =>
  `${getOrderPath(orderId)}/summary_csv`;
export const getOrderExportPath = (
  orderId: string,
  includeJSON: boolean,
  fmt: string
) =>
  `${getOrderPath(orderId)}/export/${fmt}?exclude_json=${
    includeJSON ? 'False' : 'True'
  }`;
export const getOrderExportRequestsPath = (orderId: string) =>
  `${getOrderPath(orderId)}/export-requests/`;
export const getOrderProcessCountsPath = (orderId: string) =>
  `${getOrderPath(orderId)}/process-counts`;
export const getCancelOrderPath = (orderId: string) =>
  `${getOrderPath(orderId)}/cancel`;
export const getOrderDownloadPdfPath = (orderId: string) =>
  `${getOrderPath(orderId)}/pdfdownload`;

export const searchOrderItemsPath = (query: string, limit: number) =>
  `${BASE_CLIENT_API_PATH}/items/?q=${encodeURIComponent(
    query
  )}&limit=${limit}`;
export const getOrderItemsPath = (orderId: string) =>
  `${getOrderPath(orderId)}/items`;
export const getOrderItemsWithQueryStringPath = (
  orderId: string,
  params: QueryParams
) => {
  const { filters, ordering, query, ...rest } = params;
  const orderingParam = parseOrderingToQueryParams(ordering);
  const searchParams = parseComplexSearchToQueryParams(
    query,
    ORDER_ITEM_SEARCH_FIELDS
  );
  let id = [];
  if (filters) {
    id = filters.id || id;
  }

  if (!searchParams['id']) {
    searchParams.id = id;
  }

  const processItem = filters ? filters.processItem : undefined;
  const processItemParam: string =
    processItem === null
      ? `process_item=null`
      : `${queryString.stringify({ process_item: processItem })}`;

  const queryParams = `${queryString.stringify(
    rest
  )}&${processItemParam}&${orderingParam}&${queryString.stringify(
    searchParams
  )}`;
  return `${getOrderItemsPath(orderId)}/?page_size=50&${queryParams}`;
};
export const getOrderItemsBulkPath = (orderId: string) =>
  `${getOrderItemsPath(orderId)}/bulk/`;
export const getOrderItemPath = (orderId: string, orderItemId: string) =>
  `${getOrderPath(orderId)}/items/${orderItemId}`;
export const getOrderItemDownloadPath = (
  orderId: string,
  orderItemId: string
) => `${getOrderItemPath(orderId, orderItemId)}/download`;
export const getOrderItemDownloadPdfPath = (
  orderId: string,
  orderItemId: string,
  reportType: string
) => `${getOrderItemPath(orderId, orderItemId)}/pdfdownload/${reportType}`;
export const getOrderItemCommentsPath = (
  orderId: string,
  orderItemId: string
) => `${getOrderItemPath(orderId, orderItemId)}/comments/`;
export const getOrderItemCommentsWithPagingPath = (
  orderId: string,
  orderItemId: string,
  params: PagingParams
) =>
  `${getOrderItemCommentsPath(
    orderId,
    orderItemId
  )}?type=ValuationOrder&${queryString.stringify(params)}`;
export const getAddressPath = `${BASE_CLIENT_API_PATH}/address`;
export const getAddressParsePath = `${getAddressPath}/parse/`;
export const getReviewValuationPath = (orderId: string, token: string) =>
  `${BASE_PARTNER_API_PATH}/valuation-orders/${orderId}/review?${queryString.stringify(
    { tok: token }
  )}`;
export const getPartnerValuationCommentsPath = (
  orderId: string,
  token: string
) =>
  `${BASE_PARTNER_API_PATH}/valuation-orders/${orderId}/comments?${queryString.stringify(
    { tok: token }
  )}`;
export const getPartnerValuationRevisionPath = (orderId: string) =>
  `${BASE_PARTNER_API_PATH}/valuation-orders/${orderId}/valuation_revision_response`;
export const getCancelOrderItemPath = (orderId: string, orderItemId: string) =>
  `${getOrderItemPath(orderId, orderItemId)}/cancel`;
export const getSubmitRevisionRequestPath = (
  orderId: string,
  orderItemId: string
) => `${getOrderItemPath(orderId, orderItemId)}/request_revision`;
export const getOrderItemUpdateReverifyPath = (
  orderId: string,
  orderItemId: string
) => `${getOrderItemPath(orderId, orderItemId)}/update/`;
export const getDownloadReportSummaryPath = (orderId: string): string =>
  `${getOrderPath(orderId)}/report_summary_csv`;

function parseComplexSearchToQueryParams(
  complexSearch: ?string,
  fieldMap: { [string]: string }
): Object {
  const options = { keywords: Object.keys(fieldMap) };
  const parsed = searchQuery.parse(complexSearch, options);

  let search = null;
  const query = {};
  if (typeof parsed === 'string') {
    search = parsed;
  } else {
    search = parsed.text;
    for (const k of Object.keys(fieldMap)) {
      if (Object.prototype.hasOwnProperty.call(parsed, k)) {
        query[fieldMap[k]] = parsed[k];
      }
    }
    if (parsed.exclude) {
      const exclude = parsed.exclude;
      for (const k of Object.keys(fieldMap)) {
        if (Object.prototype.hasOwnProperty.call(exclude, k)) {
          query['not_' + fieldMap[k]] = exclude[k];
        }
      }
    }
  }

  if (search) {
    // SECURITY: false posititive, assign_to_search warning is related to location.search, which is not the case here @john
    // had to add this ignore b/c can't install the latest version of scanjs-rules (0.2.1) b/c it has a typo in it
    // eslint-disable-next-line
    // eslint-disable-next-line scanjs-rules/assign_to_search
    query.search = search;
  }

  return query;
}

// websockets
export const getWebsocketTokenExchangePath = (): string =>
  `/websockets/v1/exchange/`;
export const getAdminWebsocketTokenExchangePath = (): string =>
  `/websockets/v1/admin-exchange/`;
export const getWebsocketUrl = (apiUrl: string, token: string): string =>
  `${apiUrl}/ws?token=${token}`;
