// @flow
import type { Saga } from 'redux-saga';
import { select, call, put, takeEvery } from 'redux-saga/effects';

import { handleStandardExceptions, snakeToCamel } from 'src/sagas/helpers';

import type {
  FetchMessagesAction,
  PostMessageAction
} from 'src/client/actions/order-item-messages.actions';
import type { PostMessage } from 'src/client/types/add-message';

import {
  FETCH_MESSAGES,
  POST_MESSAGE,
  fetchMessagesSuccess,
  fetchMessageError,
  postMessageSuccess,
  postMessageError
} from '../actions/order-item-messages.actions';

import userTokenSelector from 'src/selectors/user-token.selector';

import { Client } from 'src/api/order-manager-api-client';

export function* fetchMessages(action: FetchMessagesAction): Saga<void> {
  try {
    const { orderId, orderItemId, page, pageSize, ordering } = action.payload;
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const response = yield call(
      [client, client.getOrderItemComments],
      orderId,
      orderItemId,
      { page, pageSize },
      ordering
    );
    const messages = response
      .map((m) => ({
        ...m,
        created_at: m.created_at ? new Date(m.created_at) : null
      }))
      .map((m) => snakeToCamel(m, Object.keys(m), {}, false));
    yield put(fetchMessagesSuccess(messages));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(fetchMessageError(e.detail));
  }
}

export function* postMessage(action: PostMessageAction): Saga<void> {
  try {
    const { orderId, orderItemId, comment } = action.payload;
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const packet: PostMessage = {
      comment,
      additional_data: '',
      is_incoming: false,
      is_action_required: false
    };
    const response = yield call(
      [client, client.postOrderItemComment],
      orderId,
      orderItemId,
      packet
    );
    const savedMessage = snakeToCamel(
      response,
      Object.keys(response),
      {},
      false
    );
    yield put(postMessageSuccess(savedMessage));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(postMessageError(e.message));
  }
}

export function* fetchMessagesSaga(): Saga<void> {
  yield takeEvery(FETCH_MESSAGES, fetchMessages);
}

export function* postMessageSaga(): Saga<void> {
  yield takeEvery(POST_MESSAGE, postMessage);
}
