// @flow
import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { FlatButton } from '@hc/component-lib';
import FormTextarea from '../../../components/FormTextarea';

import LoadingButton from 'src/components/loading-button/LoadingButton';
import FORM_NAME from './revision-request-form-name';

import styles from './RevisionRequestDialog.css';

type RevisionRequestFormProps = {
  loading: boolean,
  invalid: boolean,
  onCancel: () => void,
  handleSubmit: () => void, // this has to be called handleSubmit (not onSubmit) or redux-form gets weird
  errors: ?(string[])
};

const maxLength2048 = (value) =>
  value && value.length > 2048
    ? `Must be 2048 characters or less. Current count: ${value.length}`
    : undefined;

const RevisionRequestFormComponent = (props: RevisionRequestFormProps) => {
  const { loading, onCancel, handleSubmit, invalid, errors } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          required
          name="revisionRequestExplanation"
          component={FormTextarea}
          validate={maxLength2048}
        />
      </div>
      {errors && (
        <div className={styles.error}>
          <div>Error(s):</div>
          {errors.map((error) => (
            <div>{error}</div>
          ))}
        </div>
      )}
      <nav role="navigation" className={styles.navigation}>
        <FlatButton
          label="Cancel"
          primary={false}
          disabled={loading}
          onClick={onCancel}
        />

        <LoadingButton
          className={styles.loadingButton}
          theme={styles}
          loading={loading}
          disabled={loading || invalid}
          type="submit"
        >
          Submit
        </LoadingButton>
      </nav>
    </form>
  );
};

const RevisionRequestForm = reduxForm({
  form: FORM_NAME
})(RevisionRequestFormComponent);

export default RevisionRequestForm;
