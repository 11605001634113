// @flow

import type { User } from '../types/user';

export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST';
export const ADMIN_LOGOUT_REQUEST = 'ADMIN_LOGOUT_REQUEST';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';
export const ADMIN_LOGIN_SET_SUCCESS_ROUTE = 'ADMIN_LOGIN_SET_SUCCESS_ROUTE';

export type LoginRequestAction = {
  type: 'ADMIN_LOGIN_REQUEST',
  payload: {
    username: string,
    password: string
  }
};

export type LogoutRequestAction = {
  type: 'ADMIN_LOGOUT_REQUEST',
  payload: {
    route: ?string
  }
};

export type LoginSuccessAction = {
  type: 'ADMIN_LOGIN_SUCCESS',
  payload: {
    user: User
  }
};

export type LoginFailedAction = {
  type: 'ADMIN_LOGIN_FAILURE'
};

export type LoginSetSuccessRouteAction = {
  type: 'ADMIN_LOGIN_SET_SUCCESS_ROUTE',
  payload: {
    route: ?string
  }
};

export type AdminUserAction =
  | LoginRequestAction
  | LogoutRequestAction
  | LoginSuccessAction
  | LoginFailedAction
  | LoginSetSuccessRouteAction;

export const loginRequest = (
  username: string,
  password: string
): LoginRequestAction => ({
  type: ADMIN_LOGIN_REQUEST,
  payload: { username, password }
});
export const logoutRequest = (route: ?string): LogoutRequestAction => ({
  type: ADMIN_LOGOUT_REQUEST,
  payload: { route }
});
export const loginSuccess = (user: User): LoginSuccessAction => ({
  type: ADMIN_LOGIN_SUCCESS,
  payload: { user }
});
export const loginFailure = (): LoginFailedAction => ({
  type: ADMIN_LOGIN_FAILURE
});
export const loginSetSuccessRoute = (
  route: ?string
): LoginSetSuccessRouteAction => ({
  type: ADMIN_LOGIN_SET_SUCCESS_ROUTE,
  payload: { route }
});
