// @flow
import React from 'react';
import {
  RTThemedTable,
  RTThemedTableHead,
  RTThemedTableRow,
  RTThemedTableCell,
  RTThemedFontIcon
} from '@hc/component-lib/lib/rt-themed';
import { connect } from 'react-redux';

import { dateFormatter } from 'src/client/helpers/formatter-helpers';
import routeTo from 'src/history/route-to';
import Link from 'src/components/Link';
import {
  reportSummaryItemsLoad,
  reportSummaryDownloadCSV
} from 'src/client/actions/report-summary.actions';
import OrderItems from './OrderItems';
import { cancelAndCall } from '../../../helpers/click-helpers';
import { IN_ORDER_GROUP } from 'src/client/constants/order-items';
import type { UserDetailsState } from 'src/reducers/user-details.reducer';
import { userDetailsStateSelector } from 'src/selectors/user-state.selector';
import type { State } from 'src/reducers';

import type { Order } from 'src/client/types/order';
import type { Dispatch } from 'redux';

import styles from './OrdersSummary.css';

type Placeholder = {
  id: string,
  type: 'placeholder',
  orderId: string
};
type OrderWithType = {
  type: 'order'
} & Order;
type OrderOrPlaceholder = OrderWithType | Placeholder;

const addPlaceholders = (orders, expandedOrderIds): OrderOrPlaceholder[] => {
  const ordersWithExpandedPlaceholders: OrderOrPlaceholder[] = [];
  orders.forEach((order) => {
    if (expandedOrderIds[order.id]) {
      ordersWithExpandedPlaceholders.push({
        ...order,
        type: 'order'
      });
      const placeHolder: Placeholder = {
        type: 'placeholder',
        id: `${order.id}_placeholder`,
        orderId: order.id
      };
      ordersWithExpandedPlaceholders.push(placeHolder);
    } else {
      ordersWithExpandedPlaceholders.push({
        ...order,
        type: 'order'
      });
    }
  });
  return ordersWithExpandedPlaceholders;
};

const wrappedRouteTo = cancelAndCall(routeTo);

type OrdersSummaryProps = {
  orders: Order[],
  onLoadOrderItems: (orderId: string) => void,
  onDownloadReportSummary: (orderId: string, filename: string) => void,
  userDetailsState: UserDetailsState
};
type OrdersSummaryState = {
  expandedOrderIds: {
    [orderId: string]: boolean
  },
  orderOrPlaceholderList: OrderOrPlaceholder[]
};

class OrdersSummary extends React.PureComponent<
  OrdersSummaryProps,
  OrdersSummaryState
> {
  constructor(props: OrdersSummaryProps) {
    super(props);
    this.state = {
      expandedOrderIds: {},
      orderOrPlaceholderList: addPlaceholders(props.orders, {})
    };
  }

  handleToggleExpandOrder(orderId: string) {
    const isExpanded = Object.prototype.hasOwnProperty.call(
      this.state.expandedOrderIds,
      orderId
    )
      ? this.state.expandedOrderIds[orderId]
      : false;
    const updatedExpandedOrderIds = {
      ...this.state.expandedOrderIds,
      [orderId]: !isExpanded
    };

    this.props.onLoadOrderItems(orderId);

    this.setState({
      expandedOrderIds: updatedExpandedOrderIds,
      orderOrPlaceholderList: addPlaceholders(
        this.props.orders,
        updatedExpandedOrderIds
      )
    });
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: OrdersSummaryProps,
    previousProps: OrdersSummaryProps
  ) {
    if (nextProps.orders !== previousProps.orders) {
      this.setState({
        expandedOrderIds: this.state.expandedOrderIds,
        orderOrPlaceholderList: addPlaceholders(
          nextProps.orders,
          this.state.expandedOrderIds
        )
      });
    }
  }

  render() {
    const { orderOrPlaceholderList, expandedOrderIds } = this.state;
    const { onDownloadReportSummary, userDetailsState } = this.props;
    const wrappedOnDownloadReportSummary = cancelAndCall(
      onDownloadReportSummary
    );
    const userDetails =
      userDetailsState.status === 'loaded'
        ? userDetailsState.userDetails
        : null;
    const isAdminUser = userDetails ? userDetails.isAdminUser : false;
    return (
      <div className={styles.ordersSummary}>
        <RTThemedTable selectable={false} theme={styles}>
          <RTThemedTableHead theme={styles}>
            <RTThemedTableCell theme={styles}>
              <span>Completed Date</span>
            </RTThemedTableCell>
            <RTThemedTableCell theme={styles} className={styles.orderNameCell}>
              <span>Order</span>
            </RTThemedTableCell>
            {isAdminUser && (
              <RTThemedTableCell theme={styles}>
                <span>Ordered By</span>
              </RTThemedTableCell>
            )}
            <RTThemedTableCell theme={styles} numeric>
              <span>Amount Ordered</span>
            </RTThemedTableCell>
            <RTThemedTableCell theme={styles}>
              <span>Product Type</span>
            </RTThemedTableCell>
            <RTThemedTableCell theme={styles}>
              <span>Label</span>
            </RTThemedTableCell>
            <RTThemedTableCell theme={styles}>
              <span />
            </RTThemedTableCell>
            <RTThemedTableCell theme={styles} numeric>
              <span />
            </RTThemedTableCell>
          </RTThemedTableHead>
          {orderOrPlaceholderList.map((orderOrPH: OrderOrPlaceholder) => {
            if (orderOrPH.type === 'placeholder') {
              const placeholder: Placeholder = orderOrPH;
              return (
                <RTThemedTableRow key={orderOrPH.id} theme={styles}>
                  <RTThemedTableCell theme={styles} colSpan={7}>
                    <OrderItems orderId={placeholder.orderId} />
                  </RTThemedTableCell>
                </RTThemedTableRow>
              );
            }
            const order = orderOrPH;
            return (
              <RTThemedTableRow
                data-hc-name="order-summary"
                key={order.id}
                theme={styles}
                onClick={() => this.handleToggleExpandOrder(order.id)}
              >
                <RTThemedTableCell
                  data-hc-name="order-completed-date"
                  theme={styles}
                >
                  <span>
                    {dateFormatter(order.actualDeliveryDate, 'MM/dd/yyyy')}
                  </span>
                </RTThemedTableCell>
                <RTThemedTableCell
                  data-hc-name="order-name"
                  theme={styles}
                  className={styles.orderNameCell}
                >
                  <Link
                    data-hc-name="order-name-link"
                    label={order.name}
                    onClick={(e) =>
                      wrappedRouteTo(
                        e,
                        `/client/order/${order.id}/${IN_ORDER_GROUP}`,
                        false,
                        true
                      )
                    }
                    className={styles.link}
                  />
                </RTThemedTableCell>
                {order.ownerEmail && isAdminUser && (
                  <RTThemedTableCell
                    data-hc-name="order-completed-date"
                    theme={styles}
                  >
                    <span>{order.ownerEmail}</span>
                  </RTThemedTableCell>
                )}
                <RTThemedTableCell
                  data-hc-name="order-count"
                  theme={styles}
                  numeric
                >
                  <span>{order.completeOrderItemsCount}</span>
                </RTThemedTableCell>
                <RTThemedTableCell
                  data-hc-name="order-product-type"
                  theme={styles}
                >
                  <span>{order.orderTypeDescriptor.name}</span>
                </RTThemedTableCell>
                <RTThemedTableCell data-hc-name="order-label" theme={styles}>
                  <span>{order.label}</span>
                </RTThemedTableCell>
                <RTThemedTableCell theme={styles}>
                  <Link
                    data-hc-name="order-download-link"
                    icon="file_download"
                    label="Download Spreadsheet"
                    onClick={(e) =>
                      wrappedOnDownloadReportSummary(
                        e,
                        order.id,
                        `usage-${order.name}`
                      )
                    }
                    className={styles.link}
                  />
                </RTThemedTableCell>
                <RTThemedTableCell theme={styles} numeric>
                  <RTThemedFontIcon
                    data-hc-name="order-expand"
                    className={styles.collapseIcon}
                    value={
                      expandedOrderIds[order.id] ? 'expand_less' : 'expand_more'
                    }
                  />
                </RTThemedTableCell>
              </RTThemedTableRow>
            );
          })}
        </RTThemedTable>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    userDetailsState: userDetailsStateSelector(state)
  };
}

const mapDispatchToProps = (dispatch: Dispatch<*>) => {
  return {
    onLoadOrderItems: (orderId: string) =>
      dispatch(reportSummaryItemsLoad(orderId)),
    onDownloadReportSummary: (orderId: string, filename: string) =>
      dispatch(reportSummaryDownloadCSV(orderId, filename))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersSummary);
