// @flow
import React from 'react';
import { RTThemedTableCell } from '@hc/component-lib/lib/rt-themed';
import classNames from 'classnames';

import type { OrderingParams } from 'src/types/list';

import styles from './SortableTableCell.css';

type SortableTableCellProps = {
  onSort: (ordering: OrderingParams[]) => void,
  ordering: OrderingParams[],
  orderingField: string,
  customOnSortOrdering?: OrderingParams[], // custom ordering params if they will be different than just a asc or desc sort on the orderingFieldName
  customOrderingIndex?: number, // if the ordering param index is something other than 0 (i.e. the primary (aka first) ordering field is not the ordering field to toggle)
  className?: string,
  children?: ?any,
  dataHcName?: string
};

type SortableTableCellState = {
  sortAsc: ?boolean // sortAsc being true or false (i.e. not null) means sort is active
};

class SortableTableCell extends React.Component<
  SortableTableCellProps,
  SortableTableCellState
> {
  constructor(props: SortableTableCellProps) {
    super(props);

    this.state = this.calculateState(props);
  }

  getOrderingIndex(): number {
    return this.props.customOrderingIndex || 0;
  }

  calculateState(props: SortableTableCellProps): SortableTableCellState {
    if (props.ordering.length > 0) {
      const orderingIndex = this.getOrderingIndex();
      const activeOrderingParams = props.ordering[orderingIndex];
      if (
        activeOrderingParams &&
        activeOrderingParams.field === props.orderingField
      ) {
        return {
          sortAsc: activeOrderingParams.asc
        };
      }
    }
    return {
      sortAsc: null
    };
  }

  calculateNewOrdering = (): OrderingParams[] => {
    if (this.state.sortAsc !== null) {
      // toggle sort order
      const currentSortAsc = this.state.sortAsc;
      if (this.props.customOnSortOrdering) {
        const newOrdering = [...this.props.customOnSortOrdering];
        const orderingIndex = this.getOrderingIndex();
        newOrdering[orderingIndex] = {
          field: this.props.orderingField,
          asc: !currentSortAsc
        };
        return newOrdering;
      } else {
        return [
          {
            field: this.props.orderingField,
            asc: !currentSortAsc
          }
        ];
      }
    } else {
      // not currently active
      if (this.props.customOnSortOrdering) {
        return [...this.props.customOnSortOrdering];
      } else {
        return [
          {
            field: this.props.orderingField,
            asc: true
          }
        ];
      }
    }
  };

  handleClick = () => {
    this.props.onSort(this.calculateNewOrdering());
  };

  UNSAFE_componentWillReceiveProps(nextProps: SortableTableCellProps) {
    if (nextProps !== this.props) {
      const newState: SortableTableCellState = this.calculateState(nextProps);
      this.setState(newState);
    }
  }

  render() {
    let sortedDirection = null;
    if (this.state.sortAsc !== null) {
      sortedDirection = this.state.sortAsc ? 'asc' : 'desc';
    }
    return (
      <RTThemedTableCell
        tagName="th"
        onClick={this.handleClick}
        sorted={sortedDirection}
        className={classNames(this.props.className || '', styles.tableCell)}
        data-hc-name={this.props.dataHcName}
      >
        {this.props.children}
      </RTThemedTableCell>
    );
  }
}

export default SortableTableCell;
