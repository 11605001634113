// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  RTThemedLayout,
  RTThemedPanel,
  RTThemedAppBar
} from '@hc/component-lib/lib/rt-themed';

import type { Dispatch } from 'redux';

import AdminHeader from '../components/AdminHeader';
import AdminLoginForm from '../components/AdminLogin';

import { loginRequest } from '../actions/admin-user.actions';

import commonStyles from 'src/containers/common.css';
import styles from './admin.css';

type AdminLoginProps = {
  loginInProgress: boolean,
  loginFailed: boolean,
  onLogin: (username: string, password: string) => void
};

class AdminLogin extends React.Component<AdminLoginProps, void> {
  render() {
    const { onLogin, loginInProgress, loginFailed } = this.props;

    return (
      <RTThemedLayout>
        <RTThemedPanel>
          <RTThemedAppBar
            fixed
            className={`${commonStyles.appBar} ${styles.appBar}`}
          >
            <AdminHeader />
          </RTThemedAppBar>
          <div className={`${styles.loginContainer}`}>
            <AdminLoginForm
              onLogin={onLogin}
              loading={loginInProgress}
              loginFailed={loginFailed}
            />
          </div>
        </RTThemedPanel>
      </RTThemedLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginInProgress: state.admin.user.loading,
    loginFailed: state.admin.user.loginFailed
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onLogin: (username: string, password: string) =>
      dispatch(loginRequest(username, password))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
