// @flow
import React from 'react';

export const AppUserContext = React.createContext({
  applications: [],
  user: {
    first_name: '',
    last_name: ''
  },
  current_organization: {
    name: ''
  },
  accessible_applications: [],
  validity: {}
});
export const TrackingContext = React.createContext({
  data: {
    url: '',
    app: '',
    user: { id: 0, email: '' },
    accessible_applications: [],
    current_organization: { id: 0, slug: '' }
  },
  options: {}
});
export const UrlsContext = React.createContext({
  platformBaseurl: '',
  supportUrl: ''
});

export const ContextProviders = (props: any) => {
  const { appUserContext, trackingContext, urlsContext, children } = props;
  return (
    <AppUserContext.Provider value={appUserContext}>
      <TrackingContext.Provider value={trackingContext}>
        <UrlsContext.Provider value={urlsContext}>
          {children}
        </UrlsContext.Provider>
      </TrackingContext.Provider>
    </AppUserContext.Provider>
  );
};
