// @flow
import type { OrderSet } from 'src/client/types/order-set';
import type { Order, OrderProgress, OrgUsers } from 'src/client/types/order';
import type { Links } from 'src/types/link';
import type { OrderingParams } from 'src/types/list';

export const ORDERS_LOAD = 'ORDERS_LOAD';
export const ORDERS_LOAD_SUCCESS = 'ORDERS_LOAD_SUCCESS';
export const ORDERS_LOAD_ERROR = 'ORDERS_LOAD_ERROR';
export const SEARCH_ORDERS = 'SEARCH_ORDERS';
export const SORT_ORDERS = 'SORT_ORDERS';
export const FETCH_ORDER_PROGRESS = 'FETCH_ORDER_PROGRESS';
export const FETCH_ORDER_PROGRESS_SUCCESS = 'FETCH_ORDER_PROGRESS_SUCCESS';
export const FETCH_ORDER_PROGRESS_ERROR = 'FETCH_ORDER_PROGRESS_ERROR';
export const DOWNLOAD_ORDER_SUMMARY_CSV = 'DOWNLOAD_ORDER_SUMMARY_CSV';
export const ORDERS_PARTIAL_LOAD_SUCCESS = 'ORDERS_PARTIAL_LOAD_SUCCESS';
export const THROTTLED_LOAD_ORDERS = 'THROTTLED_LOAD_ORDERS';
export const CHANGE_ORDER_SET = 'CHANGE_ORDER_SET';
export const CHANGE_OWNER_ID = 'CHANGE_OWNER_ID';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const FETCH_ORG_USERS_SUCCESS = 'FETCH_ORG_USERS_SUCCESS';
export const FETCH_ORG_USERS_ERROR = 'FETCH_ORG_USERS_ERROR';
export const VIEW_ORDER_REPORT = 'VIEW_ORDER_REPORT';

export type OrdersLoadAction = { type: 'ORDERS_LOAD' };
type LoadSuccessPayload = {
  orderSet: OrderSet,
  ownerId?: ?string,
  links: Links,
  orders: Order[],
  page: ?number,
  query: ?string,
  ordering: OrderingParams[],
  defaultOrdering: OrderingParams[]
};
export type OrdersLoadSuccessAction = {
  type: 'ORDERS_LOAD_SUCCESS',
  payload: LoadSuccessPayload
};
export type OrdersLoadErrorAction = {
  type: 'ORDERS_LOAD_ERROR',
  payload: { orderSet: OrderSet, errorMessage: string }
};
export type SearchOrdersAction = {
  type: 'SEARCH_ORDERS',
  payload: { query: string }
};
export type SortOrdersAction = {
  type: 'SORT_ORDERS',
  payload: { ordering: OrderingParams[] }
};
export type FetchOrderProgressAction = {
  type: 'FETCH_ORDER_PROGRESS',
  payload: { order: Order }
};
export type FetchOrderProgressSuccessAction = {
  type: 'FETCH_ORDER_PROGRESS_SUCCESS',
  payload: { orderId: string, progress: OrderProgress }
};
export type FetchOrderProgressErrorAction = {
  type: 'FETCH_ORDER_PROGRESS_ERROR',
  payload: { orderId: string, error: string }
};
export type DownloadOrderSummaryAction = {
  type: 'DOWNLOAD_ORDER_SUMMARY_CSV',
  payload: { orderId: string, filename: string, format?: string }
};
export type OrdersPartialLoadSuccessAction = {
  type: 'ORDERS_PARTIAL_LOAD_SUCCESS',
  payload: { orders: Order[] }
};
export type ThrottledLoadOrdersAction = { type: 'THROTTLED_LOAD_ORDERS' };
export type ChangeOrderSetAction = {
  type: 'CHANGE_ORDER_SET',
  payload: { orderSet: OrderSet }
};
export type ChangeOwnerIdAction = {
  type: 'CHANGE_OWNER_ID',
  payload: { ownerId: string }
};
export type ClearOrdersAction = { type: 'CLEAR_ORDERS' };
export type FetchOrgUsersSuccessAction = {
  type: 'FETCH_ORG_USERS_SUCCESS',
  payload: { orgUsers: OrgUsers }
};
export type FetchOrgUsersErrorAction = {
  type: 'FETCH_ORG_USERS_ERROR',
  payload: { error: string }
};
export type ViewOrderReportAction = {
  type: 'VIEW_ORDER_REPORT',
  payload: { orderId: string }
};

export type OrderAction =
  | OrdersLoadAction
  | OrdersLoadSuccessAction
  | OrdersLoadErrorAction
  | SearchOrdersAction
  | SortOrdersAction
  | FetchOrderProgressAction
  | FetchOrderProgressSuccessAction
  | FetchOrderProgressErrorAction
  | DownloadOrderSummaryAction
  | OrdersPartialLoadSuccessAction
  | ThrottledLoadOrdersAction
  | ChangeOrderSetAction
  | ChangeOwnerIdAction
  | ClearOrdersAction
  | FetchOrgUsersSuccessAction
  | FetchOrgUsersErrorAction
  | ViewOrderReportAction;

export const ordersLoad = (): OrdersLoadAction => ({ type: ORDERS_LOAD });
export const ordersLoadSuccess = (
  payload: LoadSuccessPayload
): OrdersLoadSuccessAction => ({ type: ORDERS_LOAD_SUCCESS, payload });
export const ordersLoadError = (
  orderSet: OrderSet,
  errorMessage: string
): OrdersLoadErrorAction => ({
  type: ORDERS_LOAD_ERROR,
  payload: { orderSet, errorMessage }
});
export const searchOrders = (query: string): SearchOrdersAction => ({
  type: SEARCH_ORDERS,
  payload: { query }
});
export const sortOrders = (ordering: OrderingParams[]): SortOrdersAction => ({
  type: SORT_ORDERS,
  payload: { ordering }
});
export const fetchOrderProgress = (order: Order): FetchOrderProgressAction => ({
  type: FETCH_ORDER_PROGRESS,
  payload: { order }
});
export const fetchOrderProgressSuccess = (
  orderId: string,
  progress: OrderProgress
): FetchOrderProgressSuccessAction => ({
  type: FETCH_ORDER_PROGRESS_SUCCESS,
  payload: { orderId, progress }
});
export const fetchOrderProgressError = (
  orderId: string,
  error: string
): FetchOrderProgressErrorAction => ({
  type: FETCH_ORDER_PROGRESS_ERROR,
  payload: { orderId, error }
});
export const downloadOrderSummary = (
  orderId: string,
  filename: string,
  format?: string
): DownloadOrderSummaryAction => ({
  type: DOWNLOAD_ORDER_SUMMARY_CSV,
  payload: { orderId, filename, format }
});
export const ordersPartialLoadSuccess = (
  orders: Order[]
): OrdersPartialLoadSuccessAction => ({
  type: ORDERS_PARTIAL_LOAD_SUCCESS,
  payload: { orders }
});
export const throttledLoadOrders = (): ThrottledLoadOrdersAction => ({
  type: THROTTLED_LOAD_ORDERS
});
export const changeOrderSet = (orderSet: OrderSet): ChangeOrderSetAction => ({
  type: CHANGE_ORDER_SET,
  payload: { orderSet }
});
export const changeOwnerId = (ownerId: string): ChangeOwnerIdAction => ({
  type: CHANGE_OWNER_ID,
  payload: { ownerId }
});
export const clearOrders = (): ClearOrdersAction => ({ type: CLEAR_ORDERS });
export const fetchOrgUsersSuccess = (
  orgUsers: OrgUsers
): FetchOrgUsersSuccessAction => ({
  type: FETCH_ORG_USERS_SUCCESS,
  payload: { orgUsers }
});
export const fetchOrgUsersError = (
  error: string
): FetchOrgUsersErrorAction => ({
  type: FETCH_ORG_USERS_ERROR,
  payload: { error }
});
export const viewOrderReport = (orderId: string): ViewOrderReportAction => ({
  type: VIEW_ORDER_REPORT,
  payload: { orderId }
});
