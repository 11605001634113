// @flow
import type { UserContext } from '../auth-lib/types/user.types';
import type { UserDetails, LoginData } from 'src/types/user';

export const USER_LOAD_COMPLETE = 'USER_LOAD_COMPLETE';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SELECT_ORG = 'SELECT_ORG';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE';
export const CLEAR_USER = 'CLEAR_USER';

export type UserLoadCompleteAction = {
  type: 'USER_LOAD_COMPLETE',
  payload: { userDetails: UserDetails, userContext: UserContext }
};
export type LoginAction = { type: 'LOGIN', payload: LoginData };
export type LoginErrorAction = {
  type: 'LOGIN_ERROR',
  payload: { errorMessage: string }
};
export type LoginSuccessAction = { type: 'LOGIN_SUCCESS' };
export type LogoutAction = { type: 'LOGOUT' };
export type LogoutCompleteAction = { type: 'LOGOUT_COMPLETE' };
export type ClearUserAction = { type: 'CLEAR_USER' };

export type UserAction =
  | UserLoadCompleteAction
  | LoginAction
  | LoginErrorAction
  | LoginSuccessAction
  | LogoutAction
  | LogoutCompleteAction
  | ClearUserAction;

export const userLoadComplete = (
  userDetails: UserDetails,
  userContext: UserContext
): UserLoadCompleteAction => ({
  type: USER_LOAD_COMPLETE,
  payload: { userDetails, userContext }
});
export const login = (loginData: LoginData): LoginAction => ({
  type: LOGIN,
  payload: loginData
});
export const loginError = (errorMessage: string): LoginErrorAction => ({
  type: LOGIN_ERROR,
  payload: { errorMessage }
});
export const loginSuccess = (): LoginSuccessAction => ({ type: LOGIN_SUCCESS });
export const logout = (): LogoutAction => ({ type: LOGOUT });
export const logoutComplete = (): LogoutCompleteAction => ({
  type: LOGOUT_COMPLETE
});
export const clearUser = (): ClearUserAction => ({ type: CLEAR_USER });
