// @flow
import React from 'react';
import { themr } from 'react-css-themr';

import Notification from './Notification';
import { CloseIcon } from '../../../../icons';

import { TrackerLink } from '../../Tracker';
import { type TrackerConfigurationType } from '../../../../types';
import { NAVIGATION_BAR_EVENTS, NAVIGATION_BAR_TYPES } from '../../constants/events.constants';

import defaultTheme from './NotificationsDrawerContent.scss';
import { NAV_BAR_HC_NAMES } from '../../hc.names';

export type NotificationDrawerContentThemeType = {
  NotificationDrawerContent: string,
  NotificationsHeader: string,
  NotificationsHeaderCount: string,
  Notification: string,
  NotificationsCloseButton: string,
  EmptyNotifications: string
}

export type NotificationDrawerContentProps = {
  theme: NotificationDrawerContentThemeType,
  notifications: any[],
  onCloseClick: () => void,
  tracking: TrackerConfigurationType
}

const NotificationWithTracker = (props) => (
  <TrackerLink
    Link={Notification}
    {...props} />
);

const NotificationsDrawerContent = (props: NotificationDrawerContentProps) => {
  const {
    theme,
    notifications,
    onCloseClick,
    tracking
  } = props;

  const count = notifications.filter(n => !n.seen).length;

  return (
    <div className={theme.NotificationDrawerContent}>
      <div className={theme.NotificationsHeader}>
        <h3>
          Notifications {count > 0 && <div data-hc-name={NAV_BAR_HC_NAMES.HC_NOTIFICATIONS_COUNTER} className={theme.NotificationsHeaderCount}>{count}</div>}</h3>
        <div data-hc-name={NAV_BAR_HC_NAMES.HC_NOTIFICATIONS_CLOSE} className={theme.NotificationsCloseButton} onClick={onCloseClick}>
          <CloseIcon />
        </div>
      </div>

      {
        notifications.map((notification, index) => {
          return (
            <NotificationWithTracker
              type={NAVIGATION_BAR_TYPES.NOTIFICATIONS_TYPE}
              event={NAVIGATION_BAR_EVENTS.NOTIFICATION_ITEM_CLICK}
              eventData={{
                notification: {
                  title: notification.title,
                  seen_on: notification.seenOn
                }
              }}
              tracking={tracking}
              key={index}
              {...notification} />
          );
        })
      }

      {
        notifications.length === 0 &&
          <div className={theme.EmptyNotifications}>
            <span>You're in the clear. No notifications right now.</span>
          </div>
      }
    </div>
  );
};

export default themr('NotificationDrawerContentThemed', defaultTheme)(NotificationsDrawerContent);
