// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  RTThemedDialog,
  RTThemedSnackbar
} from '@hc/component-lib/lib/rt-themed';
import { destroy } from 'redux-form';
import { getEnvUrl } from '../../auth-lib/components/navigation-bar/constants/url.constants';
import { DEVELOPER_CENTER } from '../../auth-lib/components/navigation-bar/constants/products.constants';

import type { Dispatch } from 'redux';
import type { AddOrderState } from '../reducers/add-order.reducer';
import type { OrdersState } from '../reducers/orders.reducer';
import type { OrderProgressState } from '../reducers/order-progress.reducer';
import type { Order, OrgUsers } from '../types/order';
import type { UserDetailsState } from '../../reducers/user-details.reducer';
import type { AddOrderFields } from '../types/add-order';
import type { SidePanelState } from '../reducers/side-panel.reducer';
import type { State } from '../../reducers';
import type { OrderingParams } from '../../types/list';
import type { OrderSet } from '../types/order-set';
import type { CancelOrderState } from '../reducers/cancel-order.reducer';

import { HcHeader } from 'src/components/HcHeader';
import routeTo from 'src/history/route-to';
import {
  fetchOrderProgress,
  searchOrders,
  sortOrders,
  changeOrderSet,
  changeOwnerId,
  viewOrderReport
} from '../actions/orders.actions';
import {
  addOrder,
  showAddOrderDialog,
  hideAddOrderDialog,
  toggleAddOrderConfirmation
} from '../actions/add-order.actions';
import AddOrderForm from './components/add-order-form/AddOrderForm';
import OrderList from './components/OrderList';
import { ordersStateSelector } from '../selectors/orders.selectors';
import { selectedOrderStateSelector } from '../selectors/order-items.selectors';
import { orderProgressStateSelector } from '../selectors/order-progress.selectors';
import { addOrderStateSelector } from '../selectors/add-order.selectors';
import { userDetailsStateSelector } from '../../selectors/user-state.selector';
import { orgUsersSelector } from '../selectors/org-users.selector';
import Drawer from '../../components/Drawer.jsx';
import {
  openOrderExportsSidePanel,
  closeSidePanel
} from '../actions/side-panel.actions';
import { sidePanelStateSelector } from '../selectors/sidepanel.selectors';
import OrderExports from '../components/OrderExports';
import AddOrderConfirm from './components/AddOrderConfirm';
import * as logger from '../../logger';
import { NEED_REVIEW_GROUP, IN_ORDER_GROUP } from '../constants/order-items';
import { buildListLink } from '../helpers/list-helpers';
import { ORDERS_ROUTE } from '../routes';
import CancelOrderOrItemDialog from '../components/CancelOrderOrItemDialog';
import { cancelOrderStateSelector } from '../selectors/cancel-order.selectors';
import {
  showCancelOrderDialog,
  cancelOrder,
  dismissCancelOrderDialog
} from '../actions/cancel-order.actions';

import styles from './OrdersContainer.css';
import commonStyles from '../../containers/common.css';

import { ADD_ORDER_FORM_NAME } from '../constants/add-order';

const {
  SETTINGS: { PLATFORM_ENVIRONMENT }
} = window;

type OrdersProps = {
  addOrderState: AddOrderState,
  ordersState: OrdersState,
  selectedOrder: ?Order,
  userDetailsState: UserDetailsState,
  orderProgressState: OrderProgressState,
  onOrderProgressShow: (Order) => void,
  sidePanelState: SidePanelState,
  cancelOrderState: CancelOrderState,
  orgUsers: OrgUsers,
  onShowAddOrderDialog: () => void,
  onHideAddOrderDialog: () => void,
  onAddOrder: () => void,
  onDismissConfirmation: () => void,
  onDownloadClick: (order: Order) => void,
  onCloseSidePanel: () => void,
  onSelectOrder: (string) => void,
  onSearch: (string) => void,
  onSort: (OrderingParams[]) => void,
  onChangeOrderSet: (orderSet: OrderSet) => void,
  onChangeOwnerId: (ownerId: string) => void,
  onCancelOrder: (string) => void,
  onShowCancelOrderDialog: (string) => void,
  onDismissCancelOrderDialog: () => void,
  onViewOrderReport: (orderId: string) => void
};

class Orders extends React.Component<OrdersProps, void> {
  form: any;

  handleReviewClick: Function;

  handleSelectOrder = (orderId: string) => {
    routeTo(`/client/order/${orderId}/${IN_ORDER_GROUP}`, false, true);
  };

  handleReviewClick = (orderId: string) => {
    routeTo(`/client/order/${orderId}/${NEED_REVIEW_GROUP}`, false, true);
  };

  paginationRouteGeneratorFn = (page: ?number): string => {
    const { ordersState } = this.props;
    if (ordersState.status === 'loaded') {
      return buildListLink(
        ORDERS_ROUTE,
        ordersState.query,
        ordersState.ordering,
        page,
        ordersState.orderSet,
        ordersState.ownerId
      );
    } else {
      logger.logException(
        new Error(
          'OrdersContainer#paginationRouteGeneratorFn orders not loaded'
        )
      );
      return '';
    }
  };

  render() {
    const {
      addOrderState,
      ordersState,
      selectedOrder,
      userDetailsState,
      orderProgressState,
      sidePanelState,
      cancelOrderState,
      orgUsers,
      onOrderProgressShow,
      onShowAddOrderDialog,
      onHideAddOrderDialog,
      onAddOrder,
      onDismissConfirmation,
      onDownloadClick,
      onCloseSidePanel,
      onSearch,
      onSort,
      onChangeOrderSet,
      onChangeOwnerId,
      onShowCancelOrderDialog,
      onDismissCancelOrderDialog,
      onCancelOrder,
      onViewOrderReport
    } = this.props;

    const userDetails =
      userDetailsState.status === 'loaded'
        ? userDetailsState.userDetails
        : null;
    const canCreateOrders = userDetails ? userDetails.canCreateOrders : false;

    return (
      <div>
        <HcHeader
          userDetails={userDetails}
          userContext={
            userDetailsState.status === 'loaded'
              ? userDetailsState.userContext
              : null
          }
        />
        <div className={commonStyles.containerContentWithHcHeader}>
          <div className={commonStyles.clientHeader}>
            <div>
              <h2>Orders</h2>
            </div>
          </div>
          <div className={commonStyles.listContainer}>
            {ordersState.status === 'error' && (
              <div>{ordersState.errorMessage}</div>
            )}
            {(ordersState.status === 'loading' ||
              ordersState.status === 'not_loaded') && <div>...</div>}
            {ordersState.status === 'loaded' && (
              <OrderList
                orders={ordersState.pageItems}
                links={ordersState.links}
                paginationRouteGeneratorFn={this.paginationRouteGeneratorFn}
                selectedOrder={selectedOrder}
                query={ordersState.query}
                ordering={
                  ordersState.ordering.length > 0
                    ? ordersState.ordering
                    : ordersState.defaultOrdering
                }
                orderProgressState={orderProgressState}
                loading={ordersState.fetching}
                showNewOrderButton={canCreateOrders}
                orderSet={ordersState.orderSet}
                orgUsers={orgUsers}
                ownerId={ordersState.ownerId}
                isAdminUser={userDetails ? userDetails.isAdminUser : false}
                onNewOrderClick={onShowAddOrderDialog}
                onSelectOrder={this.handleSelectOrder}
                onReviewClick={this.handleReviewClick}
                onDownloadClick={onDownloadClick}
                onOrderProgressShow={onOrderProgressShow}
                onSearch={onSearch}
                onSort={onSort}
                onChangeOrderSet={onChangeOrderSet}
                onChangeOwnerId={onChangeOwnerId}
                onCancelClick={onShowCancelOrderDialog}
                onViewOrderReport={onViewOrderReport}
              />
            )}
          </div>

          <RTThemedDialog
            theme={styles}
            active={addOrderState.dialogVisible}
            onEscKeyDown={onHideAddOrderDialog}
          >
            {addOrderState.dialogVisible ? (
              <AddOrderForm
                errorMessage={addOrderState.error}
                onCancel={onHideAddOrderDialog}
                onAddOrder={onAddOrder}
                loading={addOrderState.status === 'loading'}
                createProduct={addOrderState.createProduct}
              />
            ) : (
              <div />
            )}
          </RTThemedDialog>

          <AddOrderConfirm
            show={
              addOrderState.confirmationVisible &&
              addOrderState.confirmationType === 'dialog'
            }
            onDismiss={onDismissConfirmation}
          />
          <RTThemedSnackbar
            active={
              addOrderState.confirmationVisible &&
              addOrderState.confirmationType === 'toast'
            }
            label="Order Created"
            timeout={4000}
            onTimeout={onDismissConfirmation}
          />
          <CancelOrderOrItemDialog
            type="order"
            orderId={
              cancelOrderState.dialogVisible ? cancelOrderState.orderId : null
            }
            show={cancelOrderState.dialogVisible}
            onCancel={onCancelOrder}
            onDismiss={onDismissCancelOrderDialog}
            loading={cancelOrderState.status === 'loading'}
            cancelled={cancelOrderState.status === 'cancelled'}
            error={
              cancelOrderState.status === 'error'
                ? cancelOrderState.error
                : null
            }
          />
        </div>
        <Drawer
          side="right"
          show={sidePanelState.open && sidePanelState.contentType === 'export'}
          onOverlayClick={onCloseSidePanel}
        >
          {sidePanelState.open && sidePanelState.contentType === 'export' ? (
            <OrderExports />
          ) : null}
        </Drawer>
        {canCreateOrders && (
          <div className={styles.developerCenterRow}>
            <div className={styles.developerCenter}>
              Looking for API access &amp; documentation? Try our{' '}
              <a href={getEnvUrl(PLATFORM_ENVIRONMENT, DEVELOPER_CENTER)}>
                Developer Center
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  const selectedOrderState = selectedOrderStateSelector(state);
  return {
    addOrderState: addOrderStateSelector(state),
    ordersState: ordersStateSelector(state),
    selectedOrder:
      selectedOrderState.status === 'loaded' ? selectedOrderState.order : null,
    userDetailsState: userDetailsStateSelector(state),
    orderProgressState: orderProgressStateSelector(state),
    sidePanelState: sidePanelStateSelector(state),
    cancelOrderState: cancelOrderStateSelector(state),
    orgUsers: orgUsersSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onOrderProgressShow: (order: Order) => dispatch(fetchOrderProgress(order)),
    onShowAddOrderDialog: () => dispatch(showAddOrderDialog()),
    onHideAddOrderDialog: () => {
      dispatch(hideAddOrderDialog());
      dispatch(destroy(ADD_ORDER_FORM_NAME));
    },
    onAddOrder: (values: AddOrderFields) => dispatch(addOrder(values)),
    onDismissConfirmation: () =>
      dispatch(toggleAddOrderConfirmation(false, 'toast')),
    onDownloadClick: (order) => dispatch(openOrderExportsSidePanel(order)),
    onCloseSidePanel: () => dispatch(closeSidePanel()),
    onSearch: (query: string) => dispatch(searchOrders(query)),
    onSort: (ordering: OrderingParams[]) => dispatch(sortOrders(ordering)),
    onChangeOrderSet: (orderSet: OrderSet) =>
      dispatch(changeOrderSet(orderSet)),
    onChangeOwnerId: (ownerId: string) => dispatch(changeOwnerId(ownerId)),
    onShowCancelOrderDialog: (orderId: string) =>
      dispatch(showCancelOrderDialog(orderId)),
    onCancelOrder: (id: string) => dispatch(cancelOrder(id)),
    onDismissCancelOrderDialog: () => dispatch(dismissCancelOrderDialog()),
    onViewOrderReport: (orderId: string) => dispatch(viewOrderReport(orderId))
  };
}

const OrdersWrapped = connect(mapStateToProps, mapDispatchToProps)(Orders);

export default OrdersWrapped;
