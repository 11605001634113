// @flow
import React from 'react';

import styles from './AddressLink.css';

const AddressLink = (props: { fullAddress: string, label: string }) => {
  const { fullAddress, label } = props;
  return (
    <a
      className={styles.addressLink}
      target="_blank"
      href={`https://www.google.com/maps/place/${encodeURI(fullAddress)}`}
    >
      {label}
    </a>
  );
};

export default AddressLink;
