import type { UserContext } from '../../auth-lib/types/user.types';

export const UPDATE_USER_CONTEXT = 'UPDATE_USER_CONTEXT';
export const HANDLE_STANDARD_EXCEPTIONS = 'HANDLE_STANDARD_EXCEPTIONS';

export const updateUserContext = (userContext: UserContext) => ({
  type: UPDATE_USER_CONTEXT,
  payload: { userContext }
});

export const handleStandardExceptionsAction = (error: Error) => ({
  type: HANDLE_STANDARD_EXCEPTIONS,
  payload: { error }
});
