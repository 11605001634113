// @flow
import React, { type Node } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import { type UserContext } from '../../../../types';

import MenuSection from '../MenuSection';
import AccountDetails from './AccountDetails';
import AccountOrganizationItems from './AccountOrganizationItems';
import AccountCustomizationItems from './AccountCustomizationItems';
import AccountUsageItem from './AccountUsageItem';

import { withUserContext } from '../../UserContext';
import { withUrls } from '../../Urls';

import defaultTheme from './AccountMenuContent.scss';

export type AccountMenuContentThemeType = {
  AccountMenuContent: string,
  AccountDetailsSection: string
};

export type AccountMenuContentProps = {
  className?: string,
  theme: AccountMenuContentThemeType,
  mobile?: boolean,
  children: Node,
  userContext: UserContext,
  platformBaseUrl: string,
  supportUrl?: string,
  logoutOptions?: {
    url: string,
    onClick: () => void
  },
  selectOrgOptions?: {
    url: string,
    onClick: () => void
  }
};

const AccountDetailsWithUserContext = withUserContext(AccountDetails);
const AccountOrganizationItemsWithUserContext = withUrls(
  withUserContext(AccountOrganizationItems)
);
const AccountCustomizationItemsWithUrl = withUrls(AccountCustomizationItems);

class AccountMenuContent extends React.Component<AccountMenuContentProps> {
  handleProfileClick = () => {
    window.location.assign(
      window.decodeURIComponent(
        `${this.props.platformBaseUrl}/my-user/contact-info`
      )
    );
  };

  render () {
    const {
      theme,
      mobile,
      children,
      logoutOptions,
      selectOrgOptions,
      userContext,
      platformBaseUrl
    } = this.props;

    const roles = (userContext.user && userContext.user.roles) || [];
    const isAdminUser = !!roles.find(
      (role) => role.name === 'Administrator' || role.name === 'Power User'
    );

    return (
      <div className={classNames(theme.AccountMenuContent)}>
        {!mobile && (
          <MenuSection className={theme.AccountDetailsSection} theme={theme}>
            <AccountDetailsWithUserContext
              theme={theme}
              mobile={mobile}
              onProfileClick={this.handleProfileClick}
            />
          </MenuSection>
        )}
        {isAdminUser && (
          <MenuSection theme={theme}>
            <AccountOrganizationItemsWithUserContext
              isAdminUser={isAdminUser}
              selectOrgOptions={selectOrgOptions}
              theme={theme}
            />
          </MenuSection>
        )}

        <MenuSection theme={theme}>
          <AccountUsageItem
            isAdminUser={isAdminUser}
            platformBaseUrl={platformBaseUrl}
          />
        </MenuSection>

        {children && <MenuSection theme={theme}>{children}</MenuSection>}

        <MenuSection theme={theme}>
          <AccountCustomizationItemsWithUrl
            logoutOptions={logoutOptions}
            theme={theme}
          />
        </MenuSection>
      </div>
    );
  }
}

export default themr(
  'AccountMenuContentTheme',
  defaultTheme
)(AccountMenuContent);
