// @flow
import type { Action } from 'src/actions';

import { MARK_CHECKPOINT } from 'src/actions/history.actions';

type HistoryStateNoCheckpoint = {|
  isCheckpointSet: false
|};

type HistoryStateWithCheckpoint = {|
  isCheckpointSet: true,
  lastCheckpointRoute: string
|};

export type HistoryState =
  | HistoryStateNoCheckpoint
  | HistoryStateWithCheckpoint;

const defaultState = {
  isCheckpointSet: false
};

export function historyReducer(
  previousState: HistoryState = defaultState,
  action: Action
): HistoryState {
  switch (action.type) {
    case MARK_CHECKPOINT: {
      return {
        isCheckpointSet: true,
        lastCheckpointRoute: action.payload.checkpointRoute
      };
    }
    default: {
      return previousState;
    }
  }
}

export default historyReducer;
