// @flow
import React from 'react';
import { connect } from 'react-redux';
import { RTThemedFontIcon } from '@hc/component-lib/lib/rt-themed';
import {
  CreatedIcon,
  DownloadIcon,
  Checkbox,
  CloseButton
} from '@hc/component-lib';
import { format } from 'date-fns';
import classNames from 'classnames';

import LoadingButton from 'src/components/loading-button/LoadingButton';
import {
  orderExportRequestStateSelector,
  orderExportsStateSelector
} from 'src/client/selectors/order-exports.selectors';
import { closeSidePanel } from 'src/client/actions/side-panel.actions';
import { requestOrderExport } from 'src/client/actions/order-export.actions';
import Loader from 'src/components/Loader';
import { sidePanelStateSelector } from 'src/client/selectors/sidepanel.selectors';
import Link from 'src/components/Link';
import ProgressBar from 'src/components/ProgressBar';

import type { Dispatch } from 'redux';
import type { Order } from 'src/client/types/order';
import type { OrderExportRequestState } from 'src/client/reducers/order-export-request.reducer';
import type { OrderExportsState } from '../reducers/order-exports.reducer';
import type { SidePanelState } from 'src/client/reducers/side-panel.reducer';
import type {
  OrderExport as OrderExportType,
  OrderExportStatus
} from '../types/order-export';

import styles from './OrderExports.css';

const sftpStatustoMarkup = (orderExportStatus: OrderExportStatus) => {
  switch (orderExportStatus) {
    case 'sftp_upload': {
      return (
        <div className={styles.sftpStatus}>
          SFTP Enroute
          <RTThemedFontIcon className={styles.icon} value="loop" />
        </div>
      );
    }
    case 'sftp_upload_failed': {
      return (
        <div className={classNames(styles.sftpStatus, styles.errorContainer)}>
          SFTP Delivery Failed
          <RTThemedFontIcon className={styles.icon} value="error_outline" />
        </div>
      );
    }
    case 'sftp_upload_complete': {
      return (
        <div
          className={classNames(styles.sftpStatus, styles.sftpUploadComplete)}
        >
          SFTP Delivered
          <RTThemedFontIcon className={styles.icon} value="check" />
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

type OrderExportProps = {
  orderExport: OrderExportType
};
const OrderExport = ({ orderExport }: OrderExportProps) => {
  const orderDate = orderExport.completedAt
    ? new Date(orderExport.completedAt)
    : new Date();
  return (
    <div className={styles.export}>
      {(orderExport.status === 'error' ||
        orderExport.status === 'archiving_failed' ||
        orderExport.status === 'upload_failed') && (
        <div className={styles.errorContainer}>An error has occurred.</div>
      )}
      {(orderExport.status === 'queued' ||
        orderExport.status === 'archive_created') && (
        <div className={styles.preparingProgressContainer}>
          <div data-hc-name="preparing-export" className={styles.preparingText}>
            Preparing report bundle...
          </div>
          <ProgressBar
            value={
              orderExport.percentComplete === null
                ? 0
                : orderExport.percentComplete * 100
            }
          />
        </div>
      )}
      {(orderExport.status === 'complete' ||
        orderExport.status === 'sftp_upload' ||
        orderExport.status === 'sftp_upload_failed' ||
        orderExport.status === 'sftp_upload_complete') && (
        <div className={styles.downloadItem}>
          <CreatedIcon
            height={'28px'}
            width={'28px'}
            style={{ padding: '20px 14px 0 20px' }}
          />
          <div className={styles.dateAndSftpWrapper}>
            <div className={styles.dateAndSftpContainer}>
              <div>
                <span data-hc-name="export-date" className={styles.date}>
                  {format(orderDate, 'MMM d, yyyy')}
                </span>
                <span data-hc-name="export-time" className={styles.time}>
                  {format(orderDate, 'h:mm a')}
                </span>
              </div>
            </div>
            <span>{sftpStatustoMarkup(orderExport.status)}</span>
          </div>
          <Link
            className={styles.downloadLink}
            href={orderExport.exportedData}
            label=""
            icon={<DownloadIcon height={'18px'} />}
            theme={styles}
          />
        </div>
      )}
    </div>
  );
};

type OrderExportsProps = {
  orderExportRequestState: OrderExportRequestState,
  orderExportsState: OrderExportsState,
  sidePanelState: SidePanelState,
  onRequestOrderExport: (order: Order, includeJSON: boolean) => void,
  onClose: () => void
};

type OrderExportsComponentState = {
  includeJSON: boolean
};

class OrderExports extends React.Component<
  OrderExportsProps,
  OrderExportsComponentState
> {
  onIncludeJSONToggle: Function;

  constructor(props: OrderExportsProps) {
    super(props);

    this.state = {
      includeJSON: false
    };
  }

  onIncludeJSONToggle = (shouldIncludeJSON: boolean) => {
    this.setState({
      includeJSON: shouldIncludeJSON
    });
  };

  render() {
    const {
      orderExportRequestState,
      orderExportsState,
      sidePanelState,
      onRequestOrderExport,
      onClose
    } = this.props;
    const { includeJSON } = this.state;

    if (sidePanelState.contentType === 'export') {
      const order = sidePanelState.order;
      return (
        <div data-hc-name="export-panel">
          <div
            data-hc-name="export-header"
            className={styles.titleCloseContainer}
          >
            <h3 className={styles.title}>Download All Reports</h3>
            <CloseButton onClick={onClose} />
          </div>
          <div className={styles.panelSection}>
            <div className={styles.orderName}>{order.name}</div>
            <LoadingButton
              dataHcName="export-start-button"
              className={styles.exportButton}
              loading={orderExportRequestState.status === 'loading'}
              disabled={orderExportRequestState.status === 'loading'}
              onClick={() =>
                onRequestOrderExport(order, this.state.includeJSON)
              }
            >
              <span>Initiate New Download</span>
            </LoadingButton>
            <div className={styles.checkContainer}>
              <Checkbox
                dataHcName="export-include-json-checkbox"
                theme={styles}
                label="Include JSON data in downloads"
                checked={includeJSON}
                onChange={this.onIncludeJSONToggle}
              />
            </div>
          </div>
          <div data-hc-name="export-list" className={styles.exportsContainer}>
            {(orderExportsState.status === 'loading' ||
              orderExportsState.status === 'reloading') && (
              <Loader className={styles.loader} loading />
            )}
            {orderExportsState.status === 'error' && (
              <div className={styles.errorContainer}>
                Exports failed to load
              </div>
            )}
            {(orderExportsState.status === 'loaded' ||
              orderExportsState.status === 'reloading') &&
              orderExportsState.orderExports.map(
                (orderExport: OrderExportType) => {
                  return (
                    <OrderExport
                      key={orderExport.id}
                      orderExport={orderExport}
                    />
                  );
                }
              )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    orderExportRequestState: orderExportRequestStateSelector(state),
    orderExportsState: orderExportsStateSelector(state),
    sidePanelState: sidePanelStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onClose: () => dispatch(closeSidePanel()),
    onRequestOrderExport: (order: Order, includeJSON: boolean) =>
      dispatch(requestOrderExport(order.id, includeJSON))
  };
}

const OrderExportsWrap = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderExports);
export default OrderExportsWrap;
