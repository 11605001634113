// @flow
import store from 'src/store';
import { history } from './history';
import { markCheckpoint as markCheckpointAction } from 'src/actions/history.actions';

let stateCount = 1;
const skipRoutingStates = {};

const routeTo = (
  route: string | {},
  skipRouting: boolean = false,
  markCheckpoint: boolean = false
) => {
  const stateId = `${stateCount++}`;
  if (skipRouting) {
    skipRoutingStates[stateId] = true;
  }
  if (markCheckpoint) {
    store.dispatch(
      markCheckpointAction(
        `${history.location.pathname}${history.location.search}`
      )
    );
  }
  history.push(route, { stateId });
};

export function testAndClearSkipRouting(stateId: string) {
  if (skipRoutingStates[stateId]) {
    delete skipRoutingStates[stateId];
    return true;
  }
  return false;
}

export default routeTo;
