import { sagaMiddleware } from 'src/sagas';
import { acceptOrderSaga } from './accept-order.saga';
import {
  reviewValuationAcceptSaga,
  reviewValuationRejectSaga
} from './review-valuation.saga';
import { addCommentSaga } from './valuation-comment.saga';
import { registerValuationRevisionResponseSaga } from './valuation-revision.saga';

export function runSagas() {
  sagaMiddleware.run(acceptOrderSaga);
  sagaMiddleware.run(reviewValuationAcceptSaga);
  sagaMiddleware.run(reviewValuationRejectSaga);
  sagaMiddleware.run(addCommentSaga);
  sagaMiddleware.run(registerValuationRevisionResponseSaga);
}
