import React from 'react';
import { connect } from 'react-redux';

import { refreshWorkflowWorker } from '../actions/workflow-workers.actions';
import { workflowExternalTasksLoad } from '../actions/workflow-external-tasks.actions';
import type { WorkflowWorkersState } from '../reducers/workflow-workers.reducer';

import AdminPage from '../components/AdminPage';
import WorkflowWorkers from '../components/WorkflowWorkers';

import theme from './AdminCamundaWorkflowDashboard.css';
import routeTo from 'src/history/route-to';

type AdminCamundaWorkflowDashboardProps = {
  camundaWorkersState: WorkflowWorkersState,
  onRefreshClick: () => void,
  onWorkerClick: () => void
};

class AdminCamundaWorkflowDashboard extends React.Component<
  AdminCamundaWorkflowDashboardProps,
  void
> {
  renderTabs = (renderTab) => {
    const configs = [
      {
        isActive: true,
        key: 'workers',
        label: 'Workers',
        to: '/admin/dashboard/camunda/workers'
      },
      {
        key: 'tasks',
        label: 'Tasks',
        to: '/admin/dashboard/camunda/tasks'
      }
    ];
    return <React.Fragment>{configs.map(renderTab)}</React.Fragment>;
  };

  renderContent = () => {
    const { camundaWorkersState, onRefreshClick, onWorkerClick } = this.props;
    return (
      <React.Fragment>
        <WorkflowWorkers
          workers={camundaWorkersState.workers}
          onRefreshClick={onRefreshClick}
          onWorkerClick={onWorkerClick}
        />
      </React.Fragment>
    );
  };

  render() {
    return (
      <AdminPage
        theme={theme}
        renderTabs={this.renderTabs}
        renderHeader={this.renderHeader}
        renderContent={this.renderContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    camundaWorkersState: state.admin.camunda.workers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRefreshClick: () => dispatch(refreshWorkflowWorker()),
    onWorkerClick: (workerId: string) => {
      dispatch(workflowExternalTasksLoad({ topicName: null, workerId }));
      routeTo('/admin/dashboard/camunda/tasks');
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCamundaWorkflowDashboard);
