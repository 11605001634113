// @flow
import { format, formatDistanceToNow, isPast } from 'date-fns';

import type { DataType } from 'src/client/types/data-type';

const currencyFormatter = new window.Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});
export const currencyCommaSeparated = (n: ?number) => {
  if (!n) {
    return '';
  }
  return currencyFormatter.format(n);
};

export const dateFormatter = (
  date: string,
  dateFormat: string = 'MM/dd/yy'
) => {
  const [year, month, day] = date.split('-');
  return format(
    new Date(parseInt(year), parseInt(month) - 1, parseInt(day)),
    dateFormat
  );
};

export const dateTimeFormatter = (
  date: string | Date,
  dateFormat: string = 'MM/dd/yy'
) => {
  return format(new Date(date), dateFormat);
};

export const dateStrFormatter = (
  dateStr: string,
  dateFormat: string = 'MM/dd/yy'
) => {
  return format(new Date(dateStr), dateFormat);
};

export type FormatDistanceToNowFormatterOptionsType = {
  includeSeconds?: boolean,
  addSuffix?: boolean,
  locale?: Object
};

export const formatDistanceToNowFormatter = (
  dateStr: string,
  options: FormatDistanceToNowFormatterOptionsType = {}
) => {
  return formatDistanceToNow(new Date(dateStr), options);
};

export const formatDistanceToNowWithPastMessageFormatter = (
  dateStr: string,
  options: FormatDistanceToNowFormatterOptionsType = {},
  pastMessage: string = 'ago'
) => {
  const date = new Date(dateStr);
  return isPast(date) && pastMessage
    ? `${formatDistanceToNowFormatter(dateStr, options)} ${pastMessage}`
    : formatDistanceToNowFormatter(dateStr, options);
};

export const getFormatterForDataType = (dataType: DataType) => {
  switch (dataType) {
    case 'currency':
      return currencyCommaSeparated;
    case 'date':
      return dateTimeFormatter;
    default:
      return (v: any) => (!v ? '' : v);
  }
};
