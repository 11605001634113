import React from 'react';
import { themr } from 'react-css-themr';
import signUpLinkTheme from './SignUpLink.scss';
import { ChevronLink } from '@hc/component-lib';

type SignUpLinkProps = { url: string, theme: {[key: string]: string}}

const ChevronLinkComponent = ({ theme, href }) => <ChevronLink theme={theme} href={href}>Sign up</ChevronLink>;

const SignUpLink = ({ url, theme, handleClick, ...props }: SignUpLinkProps) => {
  return (
    <div className={theme.SignUpLink} data-hc-name='signup-link' {...props}>
    Don&#39;t have an account?&nbsp;
      {
        handleClick
          ? <span onClick={handleClick}><ChevronLinkComponent theme={theme} href={url} /></span>
          : <ChevronLinkComponent theme={theme} href={url} />
      }
    </div>
  );
};

export const ThemedSignUpLink = themr('ThemedSignUpLink', signUpLinkTheme)(SignUpLink);

export default ThemedSignUpLink;
