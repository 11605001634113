// @flow
import * as React from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect, type MapStateToProps } from 'react-redux';
import type { State } from '../../reducers';
import { ADD_ORDER_FORM_NAME } from '../constants/add-order';
import type { ItemAddressFields } from '../types/add-order';
import IndividualAddresses from '../orders/components/add-order-form/IndividualAddresses';
import styles from '../orders/components/add-order-form/add-order-form.css';

type AddOrderAddressesFormProps = {
  updateStep: (number) => void,
  invalid: boolean,
  asyncValidating: boolean,
  change: Function,
  onSubmit?: (
    e: any,
    newValues: { itemsSource: string, items: ItemAddressFields[] }
  ) => void,
  items: ItemAddressFields[]
};

class AddOrderAddressesFormContent extends React.Component<AddOrderAddressesFormProps> {
  STEP_NUM = 5;

  handleFormSubmit = (e) => {
    e.preventDefault();
    const { onSubmit, items } = this.props;
    if (onSubmit) {
      onSubmit(e, { itemsSource: 'entry', items: items });
    } else {
      this.props.updateStep(this.STEP_NUM);
    }
  };

  handleSwitchBulkClick = () => {
    const { updateStep, change } = this.props;
    change('itemsSource', 'csv');
    updateStep(this.STEP_NUM - 2);
  };

  render() {
    const { invalid, asyncValidating, onSubmit } = this.props;
    return (
      <div>
        <div className={styles.addAddressesHeader}>
          <h3>Add Addresses</h3>
          <div
            className={styles.switchToBulkText}
            onClick={this.handleSwitchBulkClick}
          >
            Switch to bulk file upload
          </div>
        </div>
        <form
          onSubmit={this.handleFormSubmit}
          data-hc-name="add-order-addresses-form"
        >
          <Field
            name="itemsSource"
            required
            component={IndividualAddresses}
            props={{
              nextDisabled: asyncValidating || invalid,
              showSubmit: !!onSubmit
            }}
          />
        </form>
      </div>
    );
  }
}

const AddOrderAddressesForm = reduxForm({
  form: ADD_ORDER_FORM_NAME,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true
})(AddOrderAddressesFormContent);

const mapStateToProps: MapStateToProps<*, *, *> = (state: State) => {
  const selector = getFormValues(ADD_ORDER_FORM_NAME);
  const formValues = selector(state);
  return {
    items: formValues.items
  };
};

export default connect(mapStateToProps)(AddOrderAddressesForm);
