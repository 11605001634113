import React from 'react';
import { Validator, type ValidatorType } from './Validator';
import { ValidationIndicator } from './ValidationIndicator';
import { themr } from 'react-css-themr';
import validatorInputTheme from './ValidatorInput.scss';

export const ValidatorInput = (WrappedInput : any) => {
  const ValidatorInput = ({ theme, validating, dirty, error, ...props } : ValidatorType) => {
    return (
      <div className={theme.ValidatorInput}>
        <ValidationIndicator theme={theme} validating={validating} error={error} dirty={dirty} />
        <WrappedInput {...props} error={error} dirty={dirty} theme={theme} />
      </div>
    );
  };
  return Validator(themr('ValidatorInputThemed', validatorInputTheme)(ValidatorInput));
};

export default ValidatorInput;
