// @flow
import * as React from 'react';
import type { FieldProps } from 'redux-form';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@hc/component-lib/hclib/components/atoms/button';
import classNames from 'classnames';
import { RTThemedProgressBar } from '@hc/component-lib/lib/rt-themed';

import {
  uploadSupportingDocuments,
  type OnUploadDocumentSuccessCallback,
  type OnUploadDocumentErrrorCallback
} from '../../../actions/supporting-documents.actions';
import type { SupportingDocument } from '../../../types/supporting-documents';
import type { File } from '../../../types/add-order';
import {
  validateFileNumber,
  validateFileType,
  validateFileSize
} from '../../../helpers/add-order.helpers';

import commonStyles from './add-order-form.css';
import styles from './SupportingDocumentFields.css';

const MAX_NUM_FILES = 5;
const MAX_FILE_SIZE = 5120;
const MAX_FILE_SIZE_LABEL = '5 MB';
const ACCEPTED_FILE_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'application/csv',
  'text/csv'
];
const ACCEPTED_FILE_GROUP_TYPE = 'image/';

type SupportingDocumentsSelectProps = {
  disabled: boolean,
  onChange: (files: any[] | null) => void
};
type SupportingDocumentsSelectState = {
  dropDepth: number,
  inDropArea: boolean
};
export class SupportingDocumentsSelect extends React.Component<
  SupportingDocumentsSelectProps,
  SupportingDocumentsSelectState
> {
  constructor(props: SupportingDocumentsSelectProps) {
    super(props);
    this.state = {
      dropDepth: 0,
      inDropArea: false
    };
    this.fileInput = React.createRef();
  }

  fileInput: any;

  handleClickInput = () => {
    this.fileInput.current && this.fileInput.current.click();
  };

  handleInputChange = (e: any) => {
    const files = [...e.target.files];
    if (files && files.length) {
      this.props.onChange(files);
    } else {
      this.props.onChange(null);
    }
  };

  preventDefaults = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragEnter = (e: any) => {
    this.preventDefaults(e);
    this.setState({
      dropDepth: this.state.dropDepth + 1
    });
  };

  handleDragOver = (e: any) => {
    this.preventDefaults(e);
    this.setState({
      inDropArea: true
    });
  };

  handleDragLeave = (e: any) => {
    this.preventDefaults(e);
    const newState = {
      dropDepth: this.state.dropDepth - 1
    };
    if (newState.dropDepth === 0) {
      // $FlowFixMe
      newState.inDropArea = false;
    }
    this.setState(newState);
  };

  handleDrop = (e: any) => {
    this.handleDragLeave(e);
    const dt = e.dataTransfer;
    const files = [...dt.files];
    if (files && files.length) {
      this.props.onChange(files);
    } else {
      this.props.onChange(null);
    }
  };

  render() {
    const { disabled } = this.props;
    const { inDropArea } = this.state;
    if (disabled) {
      return (
        <div className={styles.dragAndDropContainer}>
          <RTThemedProgressBar
            dataHcName="loading-icon"
            theme={styles}
            type="circular"
            mode="indeterminate"
          />
        </div>
      );
    }
    return (
      <div
        onDragEnter={this.handleDragEnter}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
        className={classNames(styles.dragAndDropContainer, {
          [styles.highlighted]: inDropArea
        })}
      >
        <input
          ref={this.fileInput}
          className={styles.fileInput}
          type="file"
          multiple
          onChange={(e) => this.handleInputChange(e)}
        />
        <div className={styles.selectFilesButtonContainer}>
          <Button
            className={styles.selectFilesButton}
            micro
            onClick={this.handleClickInput}
          >
            Choose File(s)
          </Button>
          <div className={styles.dragAndDropText}>or drag and drop here.</div>
        </div>
        <div className={styles.fileRestrictionsText}>
          <div>5 attachments allowed</div>
          <div>Max 5 MB per attachment</div>
          <div>Accepted file types: PDFs, Excel, Word Docs, Images, CSV</div>
        </div>
      </div>
    );
  }
}

type ErrorType = {
  errorMessage: string,
  affectedFilenames: string[]
};

type SupportingDocumentFieldsProps = FieldProps & {
  onUploadSupportingDocuments: (
    documents: File[],
    onSuccess: OnUploadDocumentSuccessCallback,
    onError: OnUploadDocumentErrrorCallback
  ) => void
};
type SupportingDocumentFieldsState = {
  errors: ErrorType[],
  numUploading: number
};
class SupportingDocumentsFieldsComponent extends React.Component<
  SupportingDocumentFieldsProps,
  SupportingDocumentFieldsState
> {
  constructor(props: SupportingDocumentFieldsProps) {
    super(props);
    this.state = {
      errors: [],
      numUploading: 0
    };
  }

  handleDocumentSuccess = (supportingDocument: SupportingDocument) => {
    this.setState({
      numUploading: this.state.numUploading - 1
    });
    const { input } = this.props;
    const newValue = [...input.value, supportingDocument];
    input.onChange(newValue);
  };

  createError = (message: string, affectedFiles: File[]): ErrorType => {
    return {
      errorMessage: message,
      affectedFilenames: affectedFiles.map((file) => file.name)
    };
  };

  handleDocumentError = (fileName: string, error: string) => {
    this.setState({
      numUploading: this.state.numUploading - 1,
      errors: [
        ...this.state.errors,
        {
          errorMessage: error,
          affectedFilenames: [fileName]
        }
      ]
    });
  };

  addDocuments = (files: any[] | null) => {
    if (files === null) {
      return;
    }

    let filesToInclude: File[] = [];
    const errors: ErrorType[] = [];

    // check files match allowed types
    const fileTypeValidateResult = validateFileType(
      files,
      ACCEPTED_FILE_TYPES,
      ACCEPTED_FILE_GROUP_TYPE
    );
    filesToInclude = fileTypeValidateResult.filesToInclude;
    if (fileTypeValidateResult.filesToExclude.length > 0) {
      errors.push(
        this.createError(
          `File type not allowed. The following files were excluded:`,
          fileTypeValidateResult.filesToExclude
        )
      );
    }

    // check files for file size
    const fileSizeValidateResult = validateFileSize(
      filesToInclude,
      MAX_FILE_SIZE
    );
    filesToInclude = fileSizeValidateResult.filesToInclude;
    if (fileSizeValidateResult.filesToExclude.length > 0) {
      errors.push(
        this.createError(
          `File size limit of ${MAX_FILE_SIZE_LABEL} exceeded. The following files were excluded:`,
          fileSizeValidateResult.filesToExclude
        )
      );
    }

    const existingDocsLength = this.props.input.value
      ? this.props.input.value.length
      : 0;
    // check files don't exceed max number allowed
    const fileNumberValidateResult = validateFileNumber(
      filesToInclude,
      existingDocsLength,
      MAX_NUM_FILES
    );
    filesToInclude = fileNumberValidateResult.filesToInclude;
    if (fileNumberValidateResult.filesToExclude.length > 0) {
      errors.push(
        this.createError(
          `Limit of ${MAX_NUM_FILES} items. The following files were excluded:`,
          fileNumberValidateResult.filesToExclude
        )
      );
    }

    if (filesToInclude.length > 0) {
      this.setState({
        numUploading: filesToInclude.length,
        errors
      });
      this.props.onUploadSupportingDocuments(
        filesToInclude,
        this.handleDocumentSuccess,
        this.handleDocumentError
      );
    } else {
      this.setState({
        errors
      });
    }
  };

  removeDocument = (id) => () => {
    const { value, onChange } = this.props.input;
    const existingSupportingDocs: SupportingDocument[] = value;
    const newValue = existingSupportingDocs.filter((doc) => doc.id !== id);
    onChange(newValue);
  };

  render() {
    const { value } = this.props.input;
    const { errors } = this.state;
    return (
      <div className={styles.supportingDocumentsContainer}>
        <h3>Supporting Documents</h3>
        <div className={styles.documentList}>
          {value &&
            value.map((supportingDoc: SupportingDocument) => {
              return (
                <div
                  key={supportingDoc.id}
                  data-hc-name="supporting-document"
                  className={styles.supportingDocument}
                >
                  <div>{supportingDoc.fileName}</div>
                  <button
                    data-hc-name="remove-document-button"
                    className={commonStyles.removeItemButton}
                    onClick={this.removeDocument(supportingDoc.id)}
                  >
                    X Remove Document
                  </button>
                </div>
              );
            })}
        </div>
        {errors.map((error) => {
          return (
            <div key={error.errorMessage} className={styles.errorContainer}>
              {error.errorMessage}
              <ul>
                {error.affectedFilenames.map((fileName, idx) => {
                  return <li key={`fileName_${idx}`}>{fileName}</li>;
                })}
              </ul>
            </div>
          );
        })}
        <SupportingDocumentsSelect
          onChange={this.addDocuments}
          disabled={this.state.numUploading > 0}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onUploadSupportingDocuments: (
      documents: any[],
      onSuccess: OnUploadDocumentSuccessCallback,
      onError: OnUploadDocumentErrrorCallback
    ) => dispatch(uploadSupportingDocuments(documents, onSuccess, onError))
  };
}

export const SupportingDocumentsFields = connect(
  undefined,
  mapDispatchToProps
)(SupportingDocumentsFieldsComponent);
