// @flow
import React from 'react';
import { connect } from 'react-redux';
import { ForgotPassword as ForgotPasswordForm } from '../auth-lib/components';

import { forgotPasswordStateSelector } from 'src/selectors/user-state.selector';
import { submitForgotPassword } from 'src/actions/forgot-password.actions';
import { HcHeader } from 'src/components/HcHeader';

import type { ForgotPasswordState } from 'src/reducers/forgot-password.reducer';
import type { State } from 'src/reducers';
import type { Dispatch } from 'redux';

import commonStyles from 'src/containers/common.css';
import styles from './ForgotPassword.css';

type ForgotPasswordProps = {
  onSubmit: (email: string) => void,
  forgotPasswordState: ForgotPasswordState
};

const ForgotPassword = (props: ForgotPasswordProps) => {
  const { onSubmit, forgotPasswordState } = props;

  return (
    <div>
      <HcHeader showAppLinks={false} userDetails={null} userContext={null} />
      <div className={commonStyles.containerContentWithHcHeader}>
        <ForgotPasswordForm
          theme={styles}
          title="Forgot Password"
          onSubmit={onSubmit}
          isSubmitting={forgotPasswordState.loading}
          errorMessage={forgotPasswordState.errorMessage || null}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state: State) {
  return {
    forgotPasswordState: forgotPasswordStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onSubmit: (email: string) => dispatch(submitForgotPassword(email))
  };
}

const ForgotPasswordWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);

export default ForgotPasswordWrapped;
