// @flow
import React from 'react';

import type { Links } from 'src/types/link';
import { RTThemedIconButton } from '@hc/component-lib/lib/rt-themed';

export type ActionGeneratorFn = (page: number) => void;

export type PaginationProps = {
  actionGeneratorFn: ActionGeneratorFn,
  links: Links,
  className?: string
};

const PaginationNoRouting = ({
  actionGeneratorFn,
  links,
  className
}: PaginationProps) => {
  return !links.prev && !links.next ? null : (
    <div className={className}>
      <RTThemedIconButton
        icon="first_page"
        onClick={() => actionGeneratorFn(1)}
        disabled={!links.prev}
      />
      <RTThemedIconButton
        icon="chevron_left"
        onClick={() => actionGeneratorFn(links.prev && links.prev.page)}
        disabled={!links.prev}
      />
      <RTThemedIconButton
        icon="chevron_right"
        onClick={() => actionGeneratorFn(links.next && links.next.page)}
        disabled={!links.next}
      />
      <RTThemedIconButton
        icon="last_page"
        onClick={() => actionGeneratorFn(links.last.page)}
        disabled={!links.next}
      />
    </div>
  );
};

export default PaginationNoRouting;
