// @flow
import React from 'react';

import { RTThemedIconButton } from '@hc/component-lib/lib/rt-themed';
import commonFormDialogStyles from '../../../components/form-dialog-common.css';
import AddOrderSelectProductForm from './AddOrderSelectProductForm';
import AddOrderInspectionMethodForm from './AddOrderInspectionMethodForm';
import AddOrderAddressUploadForm from '../../../components/AddOrderAddressUploadForm';
import SelectedOptions from '../../../components/SelectedOptions';
import AddOrderAddressesForm from '../../../components/AddOrderAddressesForm';
import AddOrderInfoForm from './AddOrderInfoForm';
import { PRODUCT_IDS } from '../../../constants/order-choices';
import type {
  AddOrderFields,
  ProductType,
  GroupKey,
  ProductTypeDescriptor
} from '../../../types/add-order';
import type { DeliverySpeed, OrderTypeDescriptor } from '../../../types/order';
import { type MapStateToProps, connect } from 'react-redux';
import type { State } from '../../../../reducers';
import { productTypeChoicesSelector } from '../../../selectors/add-order.selectors';
import styles from './add-order-form.css';

type AddOrderFormProps = {
  loading: boolean,
  errorMessage: ?string,
  onCancel: () => void,
  onAddOrder: (order: AddOrderFields) => void,
  createProduct: ?GroupKey,
  productTypeChoices: ?(ProductTypeDescriptor[]),
  orderTypes: ?(OrderTypeDescriptor[])
};

type AddOrderFormState = {
  productType: ?ProductType,
  step: number,
  deliverySpeed: ?DeliverySpeed,
  inspectionType: ?string,
  includesAsRepairedValue: ?boolean,
  orderTypes: OrderTypeDescriptor[]
};

class AddOrderForm extends React.Component<
  AddOrderFormProps,
  AddOrderFormState
> {
  constructor(props) {
    super(props);
    this.state = {
      productType: null,
      step: 1,
      deliverySpeed: null,
      inspectionType: null,
      includesAsRepairedValue: null,
      orderTypes: this.props.orderTypes || []
    };
  }

  updateStep = (stepNum: number, productType?: ProductType) => {
    this.setState({ step: stepNum });
    const updatedState = {};
    if (productType) {
      updatedState.productType = productType;
    }
    if (stepNum === 1 || stepNum === 2) {
      updatedState.inspectionType = null;
      updatedState.deliverySpeed = null;
    }
    this.setState(updatedState);
  };

  updateOrderTypes = (orderTypes: OrderTypeDescriptor[]) => {
    this.setState({ orderTypes: orderTypes });
  };

  onDeliveryClick = (option: ?DeliverySpeed) => {
    this.setState({ deliverySpeed: option });
  };

  onInspectionClick = (
    inspectionType: ?string,
    includesAsRepairedValue: ?boolean
  ) => {
    this.setState({
      inspectionType,
      includesAsRepairedValue
    });
  };

  handleSubmit = (values: AddOrderFields) => {
    this.props.onAddOrder(values);
  };

  shouldSkipInspectionStep(currentOrderType: OrderTypeDescriptor[]) {
    return (
      currentOrderType.length === 1 &&
      currentOrderType[0].deliverySpeeds.length < 2
    );
  }

  handleComponentFormSubmit = (
    productType: ProductType,
    orderTypes?: OrderTypeDescriptor[]
  ) => {
    const currentOrderType = orderTypes || this.state.orderTypes;
    const { step } = this.state;
    const skipInspection = this.shouldSkipInspectionStep(currentOrderType);
    const nextStep = skipInspection ? step + 2 : step + 1;
    this.updateStep(nextStep, productType);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { createProduct, orderTypes, productTypeChoices } = nextProps;
    if (createProduct && orderTypes && this.state.orderTypes.length < 1) {
      const isValidCreateProductValue =
        productTypeChoices &&
        productTypeChoices.some(
          (product) => product.groupKey === createProduct
        );
      if (!isValidCreateProductValue) {
        return false;
      } else {
        this.updateOrderTypes(orderTypes);
        const productType = PRODUCT_IDS[createProduct];
        this.handleComponentFormSubmit(productType, orderTypes);
      }
    }
  }

  render() {
    const { onCancel, errorMessage, loading, productTypeChoices } = this.props;
    const {
      step,
      deliverySpeed,
      inspectionType,
      includesAsRepairedValue,
      orderTypes,
      productType
    } = this.state;
    const skipInspection = this.shouldSkipInspectionStep(orderTypes);
    return (
      <div>
        <div
          className={commonFormDialogStyles.header}
          data-hc-name="dialog-header"
        >
          <div className={commonFormDialogStyles.headerControls}>
            <div
              className={commonFormDialogStyles.headerContent}
              data-hc-name="dialog-title"
            >
              <h2>New Order</h2>
            </div>
            <RTThemedIconButton
              data-hc-name="close-dialog-button"
              icon="close"
              theme={commonFormDialogStyles}
              onClick={onCancel}
            />
          </div>
        </div>
        <div className={styles.FormContainer}>
          {productType && step > 1 && (
            <SelectedOptions
              updateStep={this.updateStep}
              productType={productType}
              inspectionType={inspectionType}
              deliverySpeed={deliverySpeed}
              onInspectionClick={this.onInspectionClick}
              stepNum={step}
              includesAsRepairedValue={includesAsRepairedValue}
            />
          )}
          {step === 1 && (
            <AddOrderSelectProductForm
              updateStep={this.updateStep}
              updateOrderTypes={this.updateOrderTypes}
              onSubmit={this.handleComponentFormSubmit}
              productTypeChoices={productTypeChoices}
            />
          )}
          {!skipInspection && step === 2 && (
            <AddOrderInspectionMethodForm
              onDeliveryClick={this.onDeliveryClick}
              onInspectionClick={this.onInspectionClick}
              showDeliveryOptions={!!inspectionType}
              updateStep={this.updateStep}
              orderTypes={orderTypes}
              deliverySpeed={deliverySpeed}
              inspectionType={inspectionType}
              includesAsRepairedValue={includesAsRepairedValue}
            />
          )}
          {productType && step === 3 && (
            <AddOrderAddressUploadForm
              updateStep={this.updateStep}
              onSubmit={this.handleSubmit}
              orderTypes={orderTypes}
            />
          )}
          {productType && step === 4 && (
            <AddOrderAddressesForm
              onCancel={onCancel}
              updateStep={this.updateStep}
            />
          )}
          {productType && step === 5 && (
            <AddOrderInfoForm
              loading={loading}
              errorMessage={errorMessage}
              onCancel={onCancel}
              onSubmit={this.handleSubmit}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<*, *, *> = (state: State) => {
  return {
    productTypeChoices: productTypeChoicesSelector(state)
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  const { createProduct } = ownProps;
  const { productTypeChoices } = propsFromState;
  let orderTypes = [];
  if (createProduct && productTypeChoices) {
    const selectedProduct = productTypeChoices.find(
      (product) => product.groupKey === createProduct
    );
    orderTypes = selectedProduct ? selectedProduct.orderTypes : [];
  }
  return {
    orderTypes,
    ...propsFromState,
    ...ownProps
  };
};

export default connect(mapStateToProps, null, mergeProps)(AddOrderForm);
