// @flow
import React, { type Node } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';
import { findDOMNode } from 'react-dom';

import { Tooltip } from '@hc/component-lib/hclib';

import { UserIcon } from '../../../../icons';
import { type MenuSectionThemeType } from '../MenuSection';
import { type MenuItemThemeType } from '../MenuItem';
import { type AccountDetailsThemeType } from './AccountDetails';
import { type UserContext } from '../../../../types';
import AccountMenuContent, { type AccountMenuContentThemeType } from './AccountMenuContent';
import { withTracker } from '../../Tracker';
import { NAVIGATION_BAR_PREFIX, NAVIGATION_BAR_EVENTS } from '../../constants/events.constants';

import defaultTheme from './AccountMenu.scss';
import { NAV_BAR_HC_NAMES } from '../../hc.names';

export type AccountMenuThemeType = {
    AccountMenu: string
  } &
  MenuSectionThemeType &
  AccountDetailsThemeType &
  AccountMenuContentThemeType &
  MenuItemThemeType

export type AccountMenuProps = {
  className?: string,
  theme: AccountMenuThemeType,
  children?: Node,
  content?: Node,
  userContext: UserContext,
  platformBaseUrl: string,
  supportUrl: string,
  onLogout?: () => void,
  mobile?: boolean,
  logoutOptions?: {
    url: string,
    onClick: () => void
  },
  selectOrgOptions?: {
    url: string,
    onClick: () => void
  }
}

const UserIconWithTracker = withTracker(UserIcon);

class AccountMenu extends React.Component <AccountMenuProps> {
  tooltip: { current: null | window.HTMLDivElement }

  constructor (props) {
    super(props);
    this.tooltip = React.createRef();
  }

  componentDidMount () {
    this.addTriggerClickListener();
  }

  componentWillUnmount () {
    this.removeTriggerClickListener();
    const tooltipContentEl = this.queryTooltipChildElement('TooltipContent');
    if (tooltipContentEl) {
      tooltipContentEl.removeEventListener('click', this.handleTooltipContentClick);
    }
  }

  addTriggerClickListener = () => {
    const triggerEl = this.queryTooltipChildElement('Trigger');
    if (triggerEl) {
      triggerEl.addEventListener('click', this.registerTooltipContentClickHandler);
    }
  }

  removeTriggerClickListener = () => {
    const triggerEl = this.queryTooltipChildElement('Trigger');
    if (triggerEl) {
      triggerEl.removeEventListener('click', this.registerTooltipContentClickHandler);
    }
  }

  registerTooltipContentClickHandler = (event) => {
    // wait for tooltip content to be rendered
    setTimeout(() => {
      const tooltipContentEl = this.queryTooltipChildElement('TooltipContent');
      if (tooltipContentEl) {
        tooltipContentEl.addEventListener('click', this.handleTooltipContentClick);
      }
    }, 100);
  }

  handleTooltipContentClick = (event) => {
    if (this.isMenuItem(event.target)) {
      const tooltipScreenEl = this.queryTooltipChildElement('TooltipScreen');
      if (tooltipScreenEl) {
        // wait for menu item onClick handler to execute
        setTimeout(() => tooltipScreenEl.click(), 200);
      }
    } else {
      console.log('AccountMenu.handleTooltipContentClick: Target was not calculated as MenuItem', event.target);
    }
  }

  isMenuItem = (el) => {
    return el && (
      (el.className && el.className.indexOf('MenuItem') > -1) ||
      (el.parentNode && el.parentNode.className &&
        (
          el.parentNode.className.indexOf('MenuItem') > -1 ||
          el.parentNode.className.indexOf('MenuLink') > -1
        )
      )
    );
  }

  queryTooltipChildElement = (queryElementName) => {
    const tooltipEl = findDOMNode(this.tooltip.current);
    return tooltipEl instanceof window.HTMLDivElement && tooltipEl.querySelector(`[class*='${queryElementName}']`);
  }

  render () {
    const {
      className,
      theme,
      children,
      userContext,
      logoutOptions,
      selectOrgOptions,
      platformBaseUrl,
      mobile,
      content = (
        <AccountMenuContent
          theme={theme}
          mobile={mobile}
          userContext={userContext}
          logoutOptions={logoutOptions}
          platformBaseUrl={platformBaseUrl}
          selectOrgOptions={selectOrgOptions}>
          {children}
        </AccountMenuContent>
      ),
      ...rest
    } = this.props;

    return (
      <Tooltip
        ref={this.tooltip}
        className={classNames(theme.AccountMenu, className)}
        theme={theme}
        shift='left'
        clickToTrigger
        trigger={(
          <UserIconWithTracker
            data-hc-name={NAV_BAR_HC_NAMES.HC_PROFILE_MENU}
            type={NAVIGATION_BAR_PREFIX}
            event={NAVIGATION_BAR_EVENTS.PROFILE_ICON_CLICK}
          />
        )}
        content={content}
        {...rest}
      />
    );
  }
}

export default themr('AccountMenuThemed', defaultTheme)(AccountMenu);
