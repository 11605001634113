// @flow
import { combineReducers } from 'redux';
import {
  userDetailsReducer,
  type UserDetailsState
} from './user-details.reducer';
import loginReducer, { type LoginState } from './login.reducer';
import forgotPasswordReducer, {
  type ForgotPasswordState
} from './forgot-password.reducer';
import resetPasswordReducer, {
  type ResetPasswordState
} from './reset-password.reducer';

export type UserState = {
  userDetails: UserDetailsState,
  login: LoginState,
  forgotPassword: ForgotPasswordState,
  resetPassword: ResetPasswordState
};

const userReducer = combineReducers({
  userDetails: userDetailsReducer,
  login: loginReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer
});

export default userReducer;
