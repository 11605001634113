// @flow
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { redirectToLogin } from './helpers';
import { authClient } from './auth-client-instance';
import {
  LOGIN,
  LOGOUT,
  logout as logoutAction,
  logoutComplete,
  loginError,
  loginSuccess,
  type LoginAction
} from 'src/actions/user.actions';
import {
  SUBMIT_FORGOT_PASSWORD,
  forgotPasswordError,
  forgotPasswordSuccess,
  type SubmitForgotPasswordAction,
  SUBMIT_RESET_PASSWORD,
  resetPasswordError,
  resetPasswordSuccess,
  type SubmitResetPasswordAction,
  verifyResetPasswordCodeSuccess
} from 'src/actions/forgot-password.actions';
import { ORDERS_ROUTE } from '../client/routes';
import historyStateSelector from 'src/selectors/history-state.selector';
import routeTo from 'src/history/route-to';
import * as logger from '../logger';
import userTokenSelector from 'src/selectors/user-token.selector';
import { showToast } from 'src/actions/view.actions';
import { resetPasswordStateSelector } from 'src/selectors/user-state.selector';
import { getOrigin, getResellerInfo } from 'src/helpers/reseller-helpers';
import {
  type ForgotPasswordDataPayload,
  RESET_PASSWORD_EMAIL_TEMPLATE_NAME
} from '../auth-lib/clients/auth-api-client';
import type { Saga } from 'redux-saga';
import type { HistoryState } from 'src/reducers/history.reducer';
import type { ResetPasswordState } from 'src/reducers/reset-password.reducer';
import { isNil } from 'lodash-es';
import { LoginRequiredError } from '../api/order-manager-api-client';

export function* resolveUserSaga(loginRequired: boolean): Saga<void> {
  if (!loginRequired) {
    return;
  }
  try {
    const userToken = yield select(userTokenSelector);
    const userContext = yield call(
      [authClient, authClient.checkOrRefreshToken],
      userToken
    );

    if (!isNil(userContext.message)) {
      logger.logException(new Error(userContext.message));
      return;
    }
  } catch (e) {
    console.log('resolveUserSaga error', e);
    throw new LoginRequiredError();
  }
}

// login sagas
export function* resolveLogin(): Saga<void> {}

export function* resolveLogout(): Saga<void> {
  yield call(handleLogout, logoutAction());
}

export function* handleLogin(action: LoginAction): Saga<void> {
  try {
    const { username, password } = action.payload;
    yield call([authClient, authClient.login], {
      username,
      password
    });

    console.log('made it past login call');

    const historyState: HistoryState = yield select(historyStateSelector);
    yield call(
      routeTo,
      historyState.isCheckpointSet
        ? historyState.lastCheckpointRoute
        : ORDERS_ROUTE
    );
    yield put(loginSuccess());
  } catch (e) {
    console.log('loginError', e);
    yield put(loginError(e.message));
  }
}

export function* registerLogin(): Saga<void> {
  yield takeEvery(LOGIN, handleLogin);
}

// logout sagas
export function* handleLogout(): Saga<void> {
  try {
    yield call([authClient, authClient.logout]);
    // call with no arguments to clear out
    yield call(logger.setUserContext);
    yield put(logoutComplete());
    yield call(redirectToLogin, false);
  } catch (e) {
    logger.logException(e);
  }
}

export function* registerLogout(): Saga<void> {
  yield takeEvery(LOGOUT, handleLogout);
}

// forgot password sagas
export function* resolveForgotPassword(): Saga<void> {}

export function* handleForgotPassword(
  action: SubmitForgotPasswordAction
): Saga<void> {
  try {
    const URL = `${getOrigin()}/reset-password?code={}`;
    const CONFIRM_URL = `${getOrigin()}/confirm-email?code={}`;

    const request: ForgotPasswordDataPayload = {
      email: action.payload.email,
      reset_password_url: URL,
      confirm_url: CONFIRM_URL
    };
    const resellerInfo = getResellerInfo();
    if (resellerInfo) {
      request.email_params = {
        template: RESET_PASSWORD_EMAIL_TEMPLATE_NAME,
        company_logo_url: resellerInfo.fullCompanyLogoUrl
      };
    }

    yield call([authClient, authClient.forgotPassword], request);
    yield put(forgotPasswordSuccess());
    yield put(
      showToast(
        'Reset password request submitted. Please check your email for a reset password link.'
      )
    );
    yield call(redirectToLogin, false);
  } catch (e) {
    yield put(forgotPasswordError(e.message));
  }
}

export function* registerForgotPassword(): Saga<void> {
  yield takeEvery(SUBMIT_FORGOT_PASSWORD, handleForgotPassword);
}

// reset password sagas
export function* resolveResetPassword({ code }: { code: string }): Saga<void> {
  try {
    if (!code) {
      yield put(
        showToast(
          'Token was not supplied. Please make sure you copied the reset password link correctly'
        )
      );
      yield call(routeTo, '/forgot-password');
      return;
    }
    yield call([authClient, authClient.verifyResetPasswordToken], code);
    yield put(verifyResetPasswordCodeSuccess(code));
  } catch (e) {
    yield put(
      showToast(
        'Invalid or expired reset password token. Please request to reset your password again.'
      )
    );
    yield call(routeTo, '/forgot-password');
  }
}

export function* handleResetPassword(
  action: SubmitResetPasswordAction
): Saga<void> {
  try {
    const resetPasswordState: ResetPasswordState = yield select(
      resetPasswordStateSelector
    );
    if (resetPasswordState.status === 'default') {
      throw new Error('Reset password code missing');
    }
    yield call(
      [authClient, authClient.resetPassword],
      resetPasswordState.resetPasswordCode,
      action.payload.newPassword
    );
    yield put(showToast('Password has been updated'));
    yield call(redirectToLogin, false);
    yield put(resetPasswordSuccess());
  } catch (e) {
    logger.logException(e);
    yield put(resetPasswordError(e.message));
  }
}

export function* registerResetPassword(): Saga<void> {
  yield takeEvery(SUBMIT_RESET_PASSWORD, handleResetPassword);
}

export function* resolveManageOrderItems(): Saga<void> {}
