// @flow
import * as React from 'react';
import {
  RTThemedTable,
  RTThemedTableRow,
  RTThemedTableCell,
  RTThemedCheckbox
} from '@hc/component-lib/lib/rt-themed';
import { reduxForm, Field } from 'redux-form';
import { TextButton } from '@hc/component-lib';

import AddressAutoComplete from 'src/client/components/AddressAutoComplete';
import {
  EDIT_ADDRESS_FIELD_NAME,
  PROPERTY_TYPE_FIELD_NAME
} from 'src/client/constants/order-items';
import ItemIssues from './ItemIssues';
import {
  formatAddress,
  validateAddress
} from 'src/client/helpers/address-autocomplete';
import FormDropdown from 'src/client/components/FormDropdown';
import { getFullAddress } from '../../helpers/order-item.helpers';

import type { OrderItem } from 'src/client/types/order-item';
import type { PropertyType } from 'src/client/types/order';
import type { RenderDisplayRowParams } from './NeedReviewList';

import itemListStyles from './item-list.css';
import styles from './NeedReviewEditTable.css';

const StyledAddressAutoComplete = (props: any): React.Node => {
  return (
    <div className={styles.autoCompleteContainer}>
      <AddressAutoComplete theme={styles} {...props} />
    </div>
  );
};

type NeedReviewEditTableProps = {
  orderItems: OrderItem[],
  selectedOrderItems: string[],
  editingOrderItem: ?OrderItem,
  tableHead: React.Node,
  renderDisplayRow: (params: RenderDisplayRowParams) => React.Node,
  updatingOrderItemId: ?string,
  propertyTypeChoices: PropertyType[],
  form: string,
  onToggleOrderItem: (orderItemId: string) => void,
  onEdit: (orderItem: OrderItem) => void,
  onUpdateReverify: (
    orderItemId: string,
    onUpdateComplete: () => void,
    formName: string
  ) => void,
  onUpdateComplete: () => void,
  onCancel: () => void
};

class NeedReviewEditTable extends React.Component<NeedReviewEditTableProps> {
  handleSave = (e, orderItemId: string, form) => {
    e.preventDefault();
    this.props.onUpdateReverify(orderItemId, this.props.onUpdateComplete, form);
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  handleEdit = (orderItem: OrderItem) => {
    // hack to fix: https://housecanary.atlassian.net/browse/HOM-2238 and force form to unmount when the user clicks edit on multiple items without clicking cancel
    if (this.props.editingOrderItem) {
      this.props.onCancel();
      setTimeout(() => this.props.onEdit(orderItem), 0);
    } else {
      this.props.onEdit(orderItem);
    }
  };

  render() {
    const {
      orderItems,
      selectedOrderItems,
      editingOrderItem,
      tableHead,
      renderDisplayRow,
      updatingOrderItemId,
      propertyTypeChoices,
      form,
      onToggleOrderItem
    } = this.props;

    return (
      <form data-hc-name="hc-edit-item-address-form">
        <RTThemedTable theme={itemListStyles} selectable={false}>
          {tableHead}
          {orderItems.map((orderItem: OrderItem) => {
            const isUpdating = updatingOrderItemId === orderItem.id;

            if (editingOrderItem && editingOrderItem.id === orderItem.id) {
              return (
                <RTThemedTableRow
                  key={orderItem.id}
                  className={itemListStyles.tableRow}
                >
                  <RTThemedTableCell
                    className={itemListStyles.selectableTableCell}
                  >
                    <RTThemedCheckbox
                      theme={itemListStyles}
                      disabled
                      checked={selectedOrderItems.indexOf(orderItem.id) !== -1}
                    />
                  </RTThemedTableCell>
                  <RTThemedTableCell className={itemListStyles.idCell}>
                    <span>{orderItem.customerItemId}</span>
                  </RTThemedTableCell>
                  <RTThemedTableCell
                    className={itemListStyles.propertyTypeCell}
                  >
                    <Field
                      theme={styles}
                      name={PROPERTY_TYPE_FIELD_NAME}
                      disabled={isUpdating}
                      required
                      component={FormDropdown}
                      auto
                      label=""
                      options={propertyTypeChoices}
                    />
                  </RTThemedTableCell>
                  <RTThemedTableCell colSpan={4}>
                    <Field
                      className={styles.addressField}
                      disabled={isUpdating}
                      name={EDIT_ADDRESS_FIELD_NAME}
                      type="text"
                      format={formatAddress}
                      validate={validateAddress}
                      component={StyledAddressAutoComplete}
                      label={`Current address: ${getFullAddress({
                        address: orderItem.address,
                        unit: orderItem.unit,
                        city: orderItem.city,
                        state: orderItem.state,
                        zipcode: orderItem.zipcode
                      })}`}
                      required
                    />
                  </RTThemedTableCell>
                  <RTThemedTableCell className={styles.issuesCell}>
                    <ItemIssues
                      validationResults={orderItem.validationResults}
                    />
                  </RTThemedTableCell>
                  <RTThemedTableCell className={itemListStyles.actionsCell}>
                    <TextButton
                      className={styles.saveLink}
                      disabled={isUpdating}
                      onClick={(e) => this.handleSave(e, orderItem.id, form)}
                    >
                      Save
                    </TextButton>
                    <TextButton
                      className={styles.cancelLink}
                      disabled={isUpdating}
                      onClick={this.handleCancel}
                    >
                      Cancel
                    </TextButton>
                  </RTThemedTableCell>
                </RTThemedTableRow>
              );
            } else {
              return renderDisplayRow({
                orderItem,
                selectedOrderItems,
                onToggleOrderItem,
                onEdit: this.handleEdit
              });
            }
          })}
        </RTThemedTable>
      </form>
    );
  }
}

const NeedReviewEditTableForm = reduxForm({
  forceUnregisterOnUnmount: true
})(NeedReviewEditTable);

type NeedReviewEditTableFormProps = {
  editingOrderItem: OrderItem
};

const NeedReviewEditTableFormWithInitial = (
  props: NeedReviewEditTableFormProps
) => {
  const { editingOrderItem, ...rest } = props;

  const initialValues = {
    [EDIT_ADDRESS_FIELD_NAME]: {
      addressUnparsed: '',
      parsedValid: true,
      address: editingOrderItem.address,
      unit: editingOrderItem.unit,
      city: editingOrderItem.city,
      state: editingOrderItem.state,
      zipcode: editingOrderItem.zipcode
    },
    [PROPERTY_TYPE_FIELD_NAME]: editingOrderItem.propertyType
  };

  const formName = `EditAddressFormForId_${editingOrderItem.id}`;

  return (
    <NeedReviewEditTableForm
      initialValues={initialValues}
      form={formName}
      editingOrderItem={editingOrderItem}
      {...rest}
    />
  );
};

export default NeedReviewEditTableFormWithInitial;
