// @flow
import React from 'react';
import classNames from 'classnames';
import { RTThemedChip } from '@hc/component-lib/lib/rt-themed';

import styles from './SelectableChip.css';

type SelectableChipProps = {
  className?: string,
  label: string,
  isSelected: boolean,
  onSelected: (string) => void,
  onUnselected: (string) => void
};

class SelectableChip extends React.PureComponent<SelectableChipProps, void> {
  handleToggle: Function;

  handleToggle = () => {
    const {
      label,
      onSelected,
      onUnselected,
      isSelected: wasSelected
    } = this.props;
    const isSelected = !wasSelected;

    isSelected ? onSelected(label) : onUnselected(label);
  };

  render() {
    const { label, isSelected, className } = this.props;
    const chipClassName = classNames(className, {
      [styles.isSelected]: isSelected
    });

    return (
      <RTThemedChip
        theme={styles}
        className={chipClassName}
        onClick={this.handleToggle}
      >
        {label}
      </RTThemedChip>
    );
  }
}

export default SelectableChip;
