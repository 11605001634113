// @flow
/* global Generator */
import { call, select, put, takeEvery, spawn } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import type { Saga } from 'redux-saga';

import { type State } from 'src/reducers/index';
import {
  type WorkflowExternalTask,
  type ExternalTaskParams,
  type WorkflowPagination
} from '../types/workflow';
import { type WorkflowExternalTasksState } from '../reducers/workflow-external-tasks.reducer';
import { resolveUserSaga } from 'src/sagas/user.saga';
import { loadWorkflowWorkers } from './workflow-workers.saga';
import { Client } from '../api/admin-api-client';
import userTokenSelector from 'src/selectors/user-token.selector';
import { handleStandardExceptions } from './helpers';

import {
  workflowExternalTasksSuccess,
  WORKFLOW_WORKER_EXTERNAL_TASKS_LOAD,
  WORKFLOW_PAGINATION_FIRST_PAGE,
  WORKFLOW_PAGINATION_PREVIOUS_PAGE,
  WORKFLOW_PAGINATION_NEXT_PAGE,
  WORKFLOW_PAGINATION_LAST_PAGE
} from '../actions/workflow-external-tasks.actions';

import {
  workflowRefreshAlive,
  WORKFLOW_AUTO_REFRESH_ENABLE,
  WORKFLOW_AUTO_REFRESH_DISABLE,
  WORKFLOW_AUTO_REFRESH_UPDATE_CONFIG
} from '../actions/workflow-auto-refresh.actions';

import { type WorkflowAutoRefreshState } from '../reducers/workflow-auto-refresh.reducer';
import { omit } from 'lodash-es';

let autoRefreshSagaTask = null;

export function* routeWorkflowTasks(): Saga<void> {
  // @TODO handle params from route

  yield call(resolveUserSaga, true);

  const workerState = yield select((state) => state.admin.camunda.workers);
  if (workerState.status !== 'LOADING' || workerState.status !== 'SUCCESS') {
    yield call(loadWorkflowWorkers);
  }

  yield call(loadExternalTasks);
}

function* handleLoadExternalTasks(): Saga<void> {
  if (!autoRefreshSagaTask) {
    yield call(loadExternalTasks);
  } else {
    yield call(cancelAutoRefresh);
    yield call(initializeAutoRefresh);
  }
}

function* handleAutoRefreshEnable(): Saga<void> {
  yield call(initializeAutoRefresh);
}

function* handleAutoRefreshDisable(): Saga<void> {
  yield call(cancelAutoRefresh);
}

function* handleAutoRefreshConfigUpdate(): Saga<void> {
  const autoRefreshState: WorkflowAutoRefreshState = yield select(
    (state: State) => state.admin.camunda.autoRefresh
  );
  if (autoRefreshState.status === 'ENABLED') {
    yield call(cancelAutoRefresh);
    yield call(initializeAutoRefresh);
  }
}

function* initializeAutoRefresh(): Saga<void> {
  const autoRefreshState: WorkflowAutoRefreshState = yield select(
    (state: State) => state.admin.camunda.autoRefresh
  );
  console.log('generating spawn', autoRefresh.refreshTimeDifference);
  autoRefreshSagaTask = yield spawn(
    autoRefresh,
    autoRefreshState.refreshTimeDifference.amount,
    autoRefreshState.refreshTimeDifference.unit
  );
  console.log('generated spawn', autoRefreshSagaTask);
}

function cancelAutoRefresh(): void {
  if (autoRefreshSagaTask && autoRefreshSagaTask.cancel) {
    console.log('cancelling spawn', autoRefreshSagaTask);
    autoRefreshSagaTask.cancel('Canceling autoRefreshSaga Task');
    autoRefreshSagaTask = null;
    console.log('canceled spawn', autoRefreshSagaTask);
  } else {
    console.error(`Attempting to disable auto refresh when it wasn't enabled`);
  }
}

function* autoRefresh(
  amount: number,
  unit: 'seconds' | 'minutes'
): Generator<any, any, any> {
  while (true) {
    const ms = unit === 'seconds' ? amount * 1000 : amount * 60 * 1000;
    const timestamp = Date().toString();
    yield call(loadExternalTasks);
    yield put(workflowRefreshAlive(timestamp));
    yield delay(ms);
  }
}

type TaskParams = ExternalTaskParams & WorkflowPagination;
function getExternalTasksParams(
  externalTasksState: WorkflowExternalTasksState
): ?TaskParams {
  if (externalTasksState.status !== 'ERROR') {
    const filters = externalTasksState.filters;
    const taskParams: ExternalTaskParams = Object.keys(filters).reduce(
      (taskParams: ExternalTaskParams, filterKey: string) => {
        return filters[filterKey] && filterKey !== 'showErrors'
          ? {
              ...taskParams,
              [filterKey]: filters[filterKey]
            }
          : taskParams;
      },
      {}
    );
    const { maxResults, firstResult } = externalTasksState.pagination;
    return {
      ...taskParams,
      maxResults,
      firstResult
    };
  } else {
    console.error('Handling Load External Tasks: calling on error state');
  }
}

function* loadExternalTasks(): Saga<void> {
  try {
    const externalTasksState: WorkflowExternalTasksState = yield select(
      (state: State) => state.admin.camunda.tasks
    );
    const userToken = yield select(userTokenSelector);
    const params: TaskParams = yield call(
      getExternalTasksParams,
      externalTasksState
    );
    if (params) {
      const client = new Client();
      const externalTasks: WorkflowExternalTask[] = yield call(
        [client, client.getExternalTasks],
        userToken,
        params
      );
      const taskParams = omit(params, ['maxResults', 'firstResult']);
      const externalTasksCount = yield call(
        [client, client.getExternalTasksCount],
        userToken,
        taskParams
      );
      const count = externalTasksCount.count;
      yield put(workflowExternalTasksSuccess(externalTasks, count));
    }
  } catch (e) {
    yield call(handleStandardExceptions, e);
    console.error(e);
  }
}

export function* registerWorkflowExternalTasksSagas(): Saga<void> {
  yield takeEvery(WORKFLOW_WORKER_EXTERNAL_TASKS_LOAD, handleLoadExternalTasks);
  yield takeEvery(WORKFLOW_AUTO_REFRESH_ENABLE, handleAutoRefreshEnable);
  yield takeEvery(WORKFLOW_AUTO_REFRESH_DISABLE, handleAutoRefreshDisable);
  yield takeEvery(
    WORKFLOW_AUTO_REFRESH_UPDATE_CONFIG,
    handleAutoRefreshConfigUpdate
  );
  yield takeEvery(WORKFLOW_PAGINATION_FIRST_PAGE, handleLoadExternalTasks);
  yield takeEvery(WORKFLOW_PAGINATION_PREVIOUS_PAGE, handleLoadExternalTasks);
  yield takeEvery(WORKFLOW_PAGINATION_NEXT_PAGE, handleLoadExternalTasks);
  yield takeEvery(WORKFLOW_PAGINATION_LAST_PAGE, handleLoadExternalTasks);
}
