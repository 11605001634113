// @flow
import React from 'react';
import { RTThemedIconButton } from '@hc/component-lib/lib/rt-themed';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import type { AddOrExtendOrderItemFields } from '../../types/add-order';
import type { Order } from '../../types/order';
import type { State } from '../../../reducers/';
import type { OrderTypeDescriptorsState } from '../../reducers/order-type-descriptors.reducer';
import type { MapStateToProps } from 'react-redux';

import AddOrderAddressUploadForm from '../../components/AddOrderAddressUploadForm';
import AddOrderAddressesForm from '../../components/AddOrderAddressesForm';
import SelectedOptions from '../../components/SelectedOptions';
import { PRODUCT_IDS } from '../../constants/order-choices';
import commonFormDialogStyles from '../../components/form-dialog-common.css';
import { ADD_ORDER_FORM_NAME } from '../../constants/add-order';
import { orderTypeDescriptorsStateSelector } from '../../selectors/add-order.selectors';
import { deliverySpeedById } from '../../helpers/order-helpers';

import styles from './ExtendOrder.css';

type ExtendOrderProps = {
  order: Order,
  orderTypeDescriptorsState: OrderTypeDescriptorsState,
  onDismissExtendOrderDialogClick: () => void,
  onExtendOrder: (string, AddOrExtendOrderItemFields) => void
};

type ExtendOrderState = {
  step: number
};

class ExtendOrderContent extends React.Component<
  ExtendOrderProps,
  ExtendOrderState
> {
  state = {
    step: 3
  };

  updateStep = (stepNum: number) => {
    this.setState({ step: stepNum });
  };

  handleSubmit = (e, values: AddOrExtendOrderItemFields, rest) => {
    const { onExtendOrder, order } = this.props;
    const { items, itemsSource } = values || rest.values;
    onExtendOrder(
      order.id,
      items ? { items: items, itemsSource: itemsSource } : values
    );
  };

  render() {
    const {
      onDismissExtendOrderDialogClick,
      order,
      orderTypeDescriptorsState
    } = this.props;
    const { inspectionType, includesAsRepairedValue, groupKey } =
      order.orderTypeDescriptor;
    const { step } = this.state;
    const deliverySpeed = deliverySpeedById(
      orderTypeDescriptorsState,
      order.orderType,
      order.deliverySpeed
    );

    return (
      <div>
        <div
          className={commonFormDialogStyles.header}
          data-hc-name="dialog-header"
        >
          <div className={commonFormDialogStyles.headerControls}>
            <div
              className={commonFormDialogStyles.headerContent}
              data-hc-name="dialog-title"
            >
              <h2>Add More Addresses</h2>
            </div>
            <RTThemedIconButton
              data-hc-name="close-dialog-button"
              icon="close"
              theme={commonFormDialogStyles}
              onClick={onDismissExtendOrderDialogClick}
            />
          </div>
        </div>
        <div className={styles.FormContainer}>
          <SelectedOptions
            updateStep={this.updateStep}
            productType={PRODUCT_IDS[groupKey]}
            inspectionType={inspectionType}
            includesAsRepairedValue={includesAsRepairedValue}
            deliverySpeed={deliverySpeed}
            onInspectionClick={() => false}
            stepNum={step}
            disabled
          />
          {step === 3 && (
            <AddOrderAddressUploadForm
              updateStep={this.updateStep}
              shouldSubmit
              onSubmit={this.handleSubmit}
            />
          )}
          {step === 4 && (
            <AddOrderAddressesForm
              onCancel={onDismissExtendOrderDialogClick}
              updateStep={this.updateStep}
              onSubmit={this.handleSubmit}
            />
          )}
        </div>
      </div>
    );
  }
}

const ExtendOrderForm = reduxForm({
  form: ADD_ORDER_FORM_NAME
})(ExtendOrderContent);

const ExtendOrderFormWithInitial = (props: ExtendOrderProps) => {
  const { order } = props;

  const orderType = order && order.orderType ? order.orderType : null;

  const initialValues = {
    items: [{ address: null, propertyType: null }],
    itemsSource: 'entry',
    orderType: orderType,
    propertyTypeChoices: order.orderTypeDescriptor.propertyTypes
  };

  return <ExtendOrderForm initialValues={initialValues} {...props} />;
};

const mapStateToProps: MapStateToProps<*, *, *> = (state: State) => {
  return {
    orderTypeDescriptorsState: orderTypeDescriptorsStateSelector(state)
  };
};
const ExtendOrderFormWithInitialWrapped = connect(mapStateToProps)(
  ExtendOrderFormWithInitial
);

export default ExtendOrderFormWithInitialWrapped;
