import { AS_REPAIRED_VALUE_NAMES } from '../constants/add-order';
import type { File } from '../types/add-order';

export const getAsRepairedLabel = (
  includesAsRepairedValue: ?boolean
): string | null => {
  if (includesAsRepairedValue === true) {
    return AS_REPAIRED_VALUE_NAMES.asRepaired;
  } else if (includesAsRepairedValue === false) {
    return AS_REPAIRED_VALUE_NAMES.asIs;
  }
  return null;
};

export const validateFileNumber = (
  files: File[],
  numExistingFiles: number,
  maxNumFiles: number
): {
  filesToInclude: File[],
  filesToExclude: File[]
} => {
  let filesToInclude = files;
  let filesToExclude = [];
  if (numExistingFiles + files.length > maxNumFiles) {
    const numFilesToInclude = maxNumFiles - numExistingFiles;
    // slice is exclusive of end index (second argument)
    filesToInclude = files.slice(0, numFilesToInclude);
    filesToExclude = files.slice(numFilesToInclude);
  }
  return {
    filesToInclude,
    filesToExclude
  };
};

export const validateFileType = (
  files: File[],
  acceptedExactTypes: string[],
  acceptedGroupType: string
): {
  filesToInclude: File[],
  filesToExclude: File[]
} => {
  const filesToInclude = [];
  const filesToExclude = [];

  files.forEach((file) => {
    if (
      acceptedExactTypes.indexOf(file.type) === -1 &&
      !file.type.startsWith(acceptedGroupType)
    ) {
      filesToExclude.push(file);
    } else {
      filesToInclude.push(file);
    }
  });

  return {
    filesToInclude,
    filesToExclude
  };
};

export const validateFileSize = (
  files: File[],
  maxFileSize: number
): {
  filesToInclude: File[],
  filesToExclude: File[]
} => {
  const filesToInclude = [];
  const filesToExclude = [];

  files.forEach((file) => {
    const size = Math.round(file.size / 1024);
    if (size > maxFileSize) {
      filesToExclude.push(file);
    } else {
      filesToInclude.push(file);
    }
  });
  return {
    filesToInclude,
    filesToExclude
  };
};
