// @flow
import type { User } from 'src/client/types/manage-users';
import type {
  OrderItem,
  OrderItemGroup,
  OrderItemProcessCounts
} from 'src/client/types/order-item';
import type { Order } from 'src/client/types/order';
import type { Links } from 'src/types/link';
import type { QueryParams, OrderingParams } from 'src/types/list';

// Common order item actions
export const SELECT_ORDER = 'SELECT_ORDER';
export const ORDER_ITEMS_LOAD = 'ORDER_ITEMS_LOAD';
export const ORDER_ITEMS_LOAD_SUCCESS = 'ORDER_ITEMS_LOAD_SUCCESS';
export const ORDER_ITEMS_LOAD_ERROR = 'ORDER_ITEMS_LOAD_ERROR';
export const SEARCH_ORDER_ITEMS = 'SEARCH_ORDER_ITEMS';
export const SORT_ORDER_ITEMS = 'SORT_ORDER_ITEMS';
export const INIT_ORDER_ITEM_GROUP = 'INIT_ORDER_ITEM_GROUP';
export const SELECT_ORDER_ITEM_GROUP = 'SELECT_ORDER_ITEM_GROUP';
export const FETCH_ORDER_ITEM_PROCESS_COUNTS =
  'FETCH_ORDER_ITEM_PROCESS_COUNTS';
export const FETCH_ORDER_ITEM_PROCESS_COUNTS_SUCCESS =
  'FETCH_ORDER_ITEM_PROCESS_COUNTS_SUCCESS';
export const REVIEW_SHOW_CANCEL_ORDER_DIALOG =
  'REVIEW_SHOW_CANCEL_ORDER_DIALOG';
export const REVIEW_DISMISS_CANCEL_ORDER_DIALOG =
  'REVIEW_DISMISS_CANCEL_ORDER_DIALOG';
export const REVIEW_ACCEPT_ORDER = 'REVIEW_ACCEPT_ORDER';
export const REVIEW_ACCEPT_ORDER_SUCCESS = 'REVIEW_ACCEPT_ORDER_SUCCESS';
export const REVIEW_ACCEPT_ORDER_ERROR = 'REVIEW_ACCEPT_ORDER_ERROR';
export const REVIEW_CANCEL_ORDER = 'REVIEW_CANCEL_ORDER';
export const REVIEW_CANCEL_ORDER_SUCCESS = 'REVIEW_CANCEL_ORDER_SUCCESS';
export const REVIEW_CANCEL_ORDER_ERROR = 'REVIEW_CANCEL_ORDER_ERROR';
export const MOVE_ITEMS_COMPLETE = 'MOVE_ITEMS_COMPLETE';
export const SHOW_MOVE_COMPLETE_DIALOG = 'SHOW_MOVE_COMPLETE_DIALOG';
export const DISMISS_MOVE_COMPLETE_DIALOG = 'DISMISS_MOVE_COMPLETE_DIALOG';
export const CLEAR_ORDER_ITEMS = 'CLEAR_ORDER_ITEMS';
export const ORDER_ITEMS_PARTIAL_LOAD_SUCCESS =
  'ORDER_ITEMS_PARTIAL_LOAD_SUCCESS';

export type SelectOrderAction = {
  type: 'SELECT_ORDER',
  payload: { order: Order }
};
export type OrderItemsLoadAction = {
  type: 'ORDER_ITEMS_LOAD',
  payload: { orderItemGroup: OrderItemGroup }
};
export type OrderItemsLoadSuccessAction = {
  type: 'ORDER_ITEMS_LOAD_SUCCESS',
  payload: {
    orderItemGroup: OrderItemGroup,
    links: Links,
    orderItems: OrderItem[],
    params: QueryParams,
    defaultOrdering: OrderingParams[]
  }
};
export type OrderItemsLoadErrorAction = {
  type: 'ORDER_ITEMS_LOAD_ERROR',
  payload: { orderItemGroup: OrderItemGroup, errorMessage: string }
};
export type SearchOrderItemsAction = {
  type: 'SEARCH_ORDER_ITEMS',
  payload: { orderItemGroup: OrderItemGroup, query: string }
};
export type SortOrderItemsAction = {
  type: 'SORT_ORDER_ITEMS',
  payload: { orderItemGroup: OrderItemGroup, ordering: OrderingParams[] }
};
export type InitOrderItemGroupAction = {
  type: 'INIT_ORDER_ITEM_GROUP',
  payload: { orderItemGroup: OrderItemGroup }
};
export type SelectOrderItemGroupAction = {
  type: 'SELECT_ORDER_ITEM_GROUP',
  payload: { orderItemGroup: OrderItemGroup }
};
export type FetchOrderItemProcessCountsAction = {
  type: 'FETCH_ORDER_ITEM_PROCESS_COUNTS'
};
export type FetchOrderItemProcessCountsSuccessAction = {
  type: 'FETCH_ORDER_ITEM_PROCESS_COUNTS_SUCCESS',
  payload: { orderItemProcessCounts: OrderItemProcessCounts }
};
export type ReviewCancelOrderDialogAction = {
  type: 'REVIEW_SHOW_CANCEL_ORDER_DIALOG' | 'REVIEW_DISMISS_CANCEL_ORDER_DIALOG'
};
export type ReviewAcceptOrderAction = {
  type: 'REVIEW_ACCEPT_ORDER',
  payload: { id: string }
};
export type ReviewAcceptOrderSuccessAction = {
  type: 'REVIEW_ACCEPT_ORDER_SUCCESS',
  payload: { order: Order }
};
export type ReviewAcceptOrderErrorAction = {
  type: 'REVIEW_ACCEPT_ORDER_ERROR',
  payload: { errorMessage: string }
};
export type ReviewCancelOrderAction = {
  type: 'REVIEW_CANCEL_ORDER',
  payload: { id: string }
};
export type ReviewCancelOrderSuccessAction = {
  type: 'REVIEW_CANCEL_ORDER_SUCCESS'
};
export type ReviewCancelOrderErrorAction = {
  type: 'REVIEW_CANCEL_ORDER_ERROR',
  payload: { errorMessage: string }
};
export type MoveItemsCompleteAction = {
  type: 'MOVE_ITEMS_COMPLETE',
  payload: { orderItemGroup: OrderItemGroup, succeededItemIds: string[] }
};
export type ShowMoveCompleteDialog = {
  type: 'SHOW_MOVE_COMPLETE_DIALOG',
  payload: { succeededMessage: string, failedMessage: string }
};
export type DismissMoveCompleteDialog = {
  type: 'DISMISS_MOVE_COMPLETE_DIALOG'
};
export type ClearOrderItemsAction = { type: 'CLEAR_ORDER_ITEMS' };
export type OrderItemsPartialLoadSuccessAction = {
  type: 'ORDER_ITEMS_PARTIAL_LOAD_SUCCESS',
  payload: { orderItemGroup: OrderItemGroup, orderItems: OrderItem[] }
};

export const selectOrder = (order: Order): SelectOrderAction => ({
  type: SELECT_ORDER,
  payload: { order }
});
export const orderItemsLoad = (
  orderItemGroup: OrderItemGroup
): OrderItemsLoadAction => ({
  type: ORDER_ITEMS_LOAD,
  payload: { orderItemGroup }
});
export const orderItemsLoadSuccess = (
  orderItemGroup: OrderItemGroup,
  orderItems: OrderItem[],
  links: Links,
  params: QueryParams,
  defaultOrdering: OrderingParams[]
): OrderItemsLoadSuccessAction => ({
  type: ORDER_ITEMS_LOAD_SUCCESS,
  payload: { orderItemGroup, orderItems, links, params, defaultOrdering }
});
export const orderItemsLoadError = (
  orderItemGroup: OrderItemGroup,
  errorMessage: string
): OrderItemsLoadErrorAction => ({
  type: ORDER_ITEMS_LOAD_ERROR,
  payload: { orderItemGroup, errorMessage }
});
export const searchOrderItems = (
  orderItemGroup: OrderItemGroup,
  query: string
): SearchOrderItemsAction => ({
  type: SEARCH_ORDER_ITEMS,
  payload: { orderItemGroup, query }
});
export const sortOrderItems = (
  orderItemGroup: OrderItemGroup,
  ordering: OrderingParams[]
): SortOrderItemsAction => ({
  type: SORT_ORDER_ITEMS,
  payload: { orderItemGroup, ordering }
});
export const initOrderItemGroup = (orderItemGroup: OrderItemGroup) => ({
  type: INIT_ORDER_ITEM_GROUP,
  payload: { orderItemGroup }
});
export const selectOrderItemGroup = (
  orderItemGroup: OrderItemGroup
): SelectOrderItemGroupAction => ({
  type: SELECT_ORDER_ITEM_GROUP,
  payload: { orderItemGroup }
});
export const fetchOrderItemProcessCount =
  (): FetchOrderItemProcessCountsAction => ({
    type: FETCH_ORDER_ITEM_PROCESS_COUNTS
  });
export const fetchOrderItemProcessCountSuccess = (
  orderItemProcessCounts: OrderItemProcessCounts
): FetchOrderItemProcessCountsSuccessAction => ({
  type: FETCH_ORDER_ITEM_PROCESS_COUNTS_SUCCESS,
  payload: { orderItemProcessCounts }
});
export const showReviewCancelOrderDialog =
  (): ReviewCancelOrderDialogAction => ({
    type: REVIEW_SHOW_CANCEL_ORDER_DIALOG
  });
export const dismissReviewCancelOrderDialog =
  (): ReviewCancelOrderDialogAction => ({
    type: REVIEW_DISMISS_CANCEL_ORDER_DIALOG
  });
export const reviewAcceptOrder = (id: string): ReviewAcceptOrderAction => ({
  type: REVIEW_ACCEPT_ORDER,
  payload: { id }
});
export const reviewAcceptOrderSuccess = (
  order: Order
): ReviewAcceptOrderSuccessAction => ({
  type: REVIEW_ACCEPT_ORDER_SUCCESS,
  payload: { order }
});
export const reviewAcceptOrderError = (
  errorMessage: string
): ReviewAcceptOrderErrorAction => ({
  type: REVIEW_ACCEPT_ORDER_ERROR,
  payload: { errorMessage }
});
export const reviewCancelOrder = (id: string): ReviewCancelOrderAction => ({
  type: REVIEW_CANCEL_ORDER,
  payload: { id }
});
export const reviewCancelOrderSuccess = (): ReviewCancelOrderSuccessAction => ({
  type: REVIEW_CANCEL_ORDER_SUCCESS
});
export const reviewCancelOrderError = (
  errorMessage: string
): ReviewCancelOrderErrorAction => ({
  type: REVIEW_CANCEL_ORDER_ERROR,
  payload: { errorMessage }
});
export const moveItemsComplete = (
  orderItemGroup: OrderItemGroup,
  succeededItemIds: string[]
): MoveItemsCompleteAction => ({
  type: MOVE_ITEMS_COMPLETE,
  payload: { orderItemGroup, succeededItemIds }
});
export const showMoveCompleteDialog = (
  succeededMessage: string,
  failedMessage: string
) => ({
  type: SHOW_MOVE_COMPLETE_DIALOG,
  payload: { succeededMessage, failedMessage }
});
export const dismissMoveCompleteDialog = (): DismissMoveCompleteDialog => ({
  type: DISMISS_MOVE_COMPLETE_DIALOG
});
export const clearOrderItems = (): ClearOrderItemsAction => ({
  type: CLEAR_ORDER_ITEMS
});
export const orderItemsPartialLoadSuccess = (
  orderItemGroup: OrderItemGroup,
  orderItems: OrderItem[]
): OrderItemsPartialLoadSuccessAction => ({
  type: ORDER_ITEMS_PARTIAL_LOAD_SUCCESS,
  payload: { orderItemGroup, orderItems }
});

// In order actions
export const DOWNLOAD_ORDER_ITEM = 'DOWNLOAD_ORDER_ITEM';
export const DOWNLOAD_ORDER_ITEM_ERROR = 'DOWNLOAD_ORDER_ITEM_ERROR';
export const VIEW_ORDER_ITEM_REPORT = 'VIEW_ORDER_ITEM_REPORT';
export const FETCH_SELECTED_ORDER_OWNER = 'FETCH_SELECTED_ORDER_OWNER';
export const FETCH_SELECTED_ORDER_OWNER_SUCCESS =
  'FETCH_SELECTED_ORDER_OWNER_SUCCESS';
export const FETCH_SELECTED_ORDER_OWNER_ERROR =
  'FETCH_SELECTED_ORDER_OWNER_ERROR';

export type DownloadOrderItemAction = {
  type: 'DOWNLOAD_ORDER_ITEM',
  payload: { orderId: string, orderItemId: string, filename: string }
};
export type DownloadOrderItemErrorAction = {
  type: 'DOWNLOAD_ORDER_ITEM_ERROR',
  payload: { error: string }
};
export type ViewOrderItemReportAction = {
  type: 'VIEW_ORDER_ITEM_REPORT',
  payload: { orderId: string, orderItemId: string, reportType: string }
};
export type FetchSelectedOrderOwnerAction = {
  type: 'FETCH_SELECTED_ORDER_OWNER',
  payload: { userId: string, selectedOrderId: string }
};
export type FetchSelectedOrderOwnerSuccessAction = {
  type: 'FETCH_SELECTED_ORDER_OWNER_SUCCESS',
  payload: { user: User, selectedOrderId: string }
};
export type FetchSelectedOrderOwnerErrorAction = {
  type: 'FETCH_SELECTED_ORDER_OWNER_ERROR',
  payload: { errorMessage: string, selectedOrderId: string }
};

export const downloadOrderItem = (
  orderId: string,
  orderItemId: string,
  filename: string
): DownloadOrderItemAction => ({
  type: DOWNLOAD_ORDER_ITEM,
  payload: { orderId, orderItemId, filename }
});
export const downloadOrderItemError = (
  error: string
): DownloadOrderItemErrorAction => ({
  type: DOWNLOAD_ORDER_ITEM_ERROR,
  payload: { error }
});
export const viewOrderItemReport = (
  orderId: string,
  orderItemId: string,
  reportType: string
): ViewOrderItemReportAction => ({
  type: VIEW_ORDER_ITEM_REPORT,
  payload: { orderId, orderItemId, reportType }
});
export const fetchSelectedOrderOwner = (
  userId: string,
  selectedOrderId: string
): FetchSelectedOrderOwnerAction => ({
  type: FETCH_SELECTED_ORDER_OWNER,
  payload: { userId, selectedOrderId }
});
export const fetchSelectedOrderOwnerSuccess = (
  user: User,
  selectedOrderId: string
): FetchSelectedOrderOwnerSuccessAction => ({
  type: FETCH_SELECTED_ORDER_OWNER_SUCCESS,
  payload: { user, selectedOrderId }
});
export const fetchSelectedOrderOwnerError = (
  errorMessage: string,
  selectedOrderId: string
): FetchSelectedOrderOwnerErrorAction => ({
  type: FETCH_SELECTED_ORDER_OWNER_ERROR,
  payload: { errorMessage, selectedOrderId }
});

// Need Review / Removed items actions
export const TOGGLE_ITEM_SELECT = 'TOGGLE_ITEM_SELECT';
export const TOGGLE_ALL_ITEMS_SELECT = 'TOGGLE_ALL_ITEMS_SELECT';
export const ADD_ITEMS_TO_ORDER = 'ADD_ITEMS_TO_ORDER';
export const REMOVE_ITEMS_FROM_ORDER = 'REMOVE_ITEMS_FROM_ORDER';
export const RECOVER_REMOVED_ITEMS = 'RECOVER_REMOVED_ITEMS';
export const UPDATE_REVERIFY_ITEM = 'UPDATE_REVERIFY_ITEM';
export const UPDATE_REVERIFY_ITEM_CANCELLED = 'UPDATE_REVERIFY_ITEM_CANCELLED';
export const UPDATE_REVERIFY_ITEM_SUCCESS = 'UPDATE_REVERIFY_ITEM_SUCCESS';
export const UPDATE_REVERIFY_ITEM_ERROR = 'UPDATE_REVERIFY_ITEM_ERROR';

export type ToggleItemSelectAction = {
  type: 'TOGGLE_ITEM_SELECT',
  payload: { orderItemGroup: OrderItemGroup, orderItemId: string }
};
export type ToggleAllItemsSelectAction = {
  type: 'TOGGLE_ALL_ITEMS_SELECT',
  payload: {
    orderItemGroup: OrderItemGroup,
    orderItemIds: string[],
    toggleOn: boolean
  }
};
export type AddItemsToOrderAction = {
  type: 'ADD_ITEMS_TO_ORDER',
  payload: {
    orderItemGroup: OrderItemGroup,
    orderId: string,
    orderItemIds: string[]
  }
};
export type RemoveItemsFromOrderAction = {
  type: 'REMOVE_ITEMS_FROM_ORDER',
  payload: {
    orderItemGroup: OrderItemGroup,
    orderId: string,
    orderItemIds: string[]
  }
};
export type RecoverRemovedItemsAction = {
  type: 'RECOVER_REMOVED_ITEMS',
  payload: {
    orderItemGroup: OrderItemGroup,
    orderId: string,
    orderItemIds: string[]
  }
};
export type UpdateReverifyItemAction = {
  type: 'UPDATE_REVERIFY_ITEM',
  payload: {
    orderId: string,
    orderItemId: string,
    onUpdateComplete: () => void,
    formName: string
  }
};
export type UpdateReverifyItemCancelledAction = {
  type: 'UPDATE_REVERIFY_ITEM_CANCELLED',
  payload: { formName: string }
};
export type UpdateReverifyItemSuccessAction = {
  type: 'UPDATE_REVERIFY_ITEM_SUCCESS',
  payload: { orderItem: OrderItem }
};
export type UpdateReverifyItemErrorAction = {
  type: 'UPDATE_REVERIFY_ITEM_ERROR',
  payload: { orderItemId: string, errorMessage: string }
};

export const toggleItemSelect = (
  orderItemGroup: OrderItemGroup,
  orderItemId: string
): ToggleItemSelectAction => ({
  type: TOGGLE_ITEM_SELECT,
  payload: { orderItemGroup, orderItemId }
});
export const toggleAllItemsSelect = (
  orderItemGroup: OrderItemGroup,
  orderItemIds: string[],
  toggleOn: boolean
): ToggleAllItemsSelectAction => ({
  type: TOGGLE_ALL_ITEMS_SELECT,
  payload: { orderItemGroup, orderItemIds, toggleOn }
});
export const addItemsToOrder = (
  orderItemGroup: OrderItemGroup,
  orderId: string,
  orderItemIds: string[]
): AddItemsToOrderAction => ({
  type: ADD_ITEMS_TO_ORDER,
  payload: { orderItemGroup, orderId, orderItemIds }
});
export const removeItemsFromOrder = (
  orderItemGroup: OrderItemGroup,
  orderId: string,
  orderItemIds: string[]
): RemoveItemsFromOrderAction => ({
  type: REMOVE_ITEMS_FROM_ORDER,
  payload: { orderItemGroup, orderId, orderItemIds }
});
export const recoverRemovedItems = (
  orderItemGroup: OrderItemGroup,
  orderId: string,
  orderItemIds: string[]
): RecoverRemovedItemsAction => ({
  type: RECOVER_REMOVED_ITEMS,
  payload: { orderItemGroup, orderId, orderItemIds }
});
export const updateReverifyItem = (
  orderId: string,
  orderItemId: string,
  onUpdateComplete: () => void,
  formName: string
): UpdateReverifyItemAction => ({
  type: UPDATE_REVERIFY_ITEM,
  payload: { orderId, orderItemId, onUpdateComplete, formName }
});
export const updateReverifyItemCancelled = (
  formName: string
): UpdateReverifyItemCancelledAction => ({
  type: UPDATE_REVERIFY_ITEM_CANCELLED,
  payload: { formName }
});
export const updateReverifyItemSuccess = (
  orderItem: OrderItem
): UpdateReverifyItemSuccessAction => ({
  type: UPDATE_REVERIFY_ITEM_SUCCESS,
  payload: { orderItem }
});
export const updateReverifyItemError = (
  orderItemId: string,
  errorMessage: string
): UpdateReverifyItemErrorAction => ({
  type: UPDATE_REVERIFY_ITEM_ERROR,
  payload: { orderItemId, errorMessage }
});

export type OrderItemAction =
  | SelectOrderAction
  | OrderItemsLoadAction
  | OrderItemsLoadSuccessAction
  | OrderItemsLoadErrorAction
  | SearchOrderItemsAction
  | SortOrderItemsAction
  | InitOrderItemGroupAction
  | SelectOrderItemGroupAction
  | FetchOrderItemProcessCountsAction
  | FetchOrderItemProcessCountsSuccessAction
  | DownloadOrderItemAction
  | DownloadOrderItemErrorAction
  | ViewOrderItemReportAction
  | FetchSelectedOrderOwnerAction
  | FetchSelectedOrderOwnerSuccessAction
  | FetchSelectedOrderOwnerErrorAction
  | ToggleItemSelectAction
  | ToggleAllItemsSelectAction
  | AddItemsToOrderAction
  | RemoveItemsFromOrderAction
  | RecoverRemovedItemsAction
  | MoveItemsCompleteAction
  | ShowMoveCompleteDialog
  | DismissMoveCompleteDialog
  | ClearOrderItemsAction
  | OrderItemsPartialLoadSuccessAction
  | UpdateReverifyItemAction
  | UpdateReverifyItemCancelledAction
  | UpdateReverifyItemSuccessAction
  | UpdateReverifyItemErrorAction
  | ReviewCancelOrderDialogAction
  | ReviewAcceptOrderAction
  | ReviewAcceptOrderSuccessAction
  | ReviewAcceptOrderErrorAction
  | ReviewCancelOrderAction
  | ReviewCancelOrderSuccessAction
  | ReviewCancelOrderErrorAction;
