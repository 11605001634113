// @flow
import * as React from 'react';
import { TypeAhead } from '@hc/component-lib';
import type { OrgUsers, OrgUser } from 'src/client/types/order';

type OrgUsersTypeaheadProps = {
  onChangeOwnerId: (ownerId: string) => void,
  ownerId: ?string,
  orgUsers: OrgUsers
};

type OrgUsersTypeaheadState = {
  suggestions: OrgUsers,
  search: number | string
};

export class OrgUsersTypeahead extends React.Component<
  OrgUsersTypeaheadProps,
  OrgUsersTypeaheadState
> {
  constructor(props: OrgUsersTypeaheadProps) {
    super(props);
    this.state = {
      search: '',
      suggestions: this._getDefaultSuggestions()
    };
  }

  _getDefaultSuggestions = () => {
    const { orgUsers } = this.props;
    const allUsersSuggestion = {
      label: 'All Users',
      value: null
    };
    return [allUsersSuggestion, ...orgUsers];
  };

  _filterAndFormatSuggestions = (users: OrgUsers, search: string) => {
    const options = [
      {
        label: 'All Users',
        value: null
      },
      ...users
        .filter((user) => this._matchUser(user, search))
        .map(this._formatOption)
    ];
    return options;
  };

  _matchUser = (
    { value, label, firstName, lastName }: OrgUser,
    search: string
  ) => {
    if (!search) return true;

    const unmatchedWords = new Map();
    search
      .toLowerCase()
      .split(' ')
      .forEach((word) => {
        if (word && word.length) {
          unmatchedWords.set(word, true);
        }
      });
    const sources = [label, value, firstName, lastName];
    for (let i = 0; i < sources.length; i++) {
      if (sources[i]) {
        const source = sources[i].toString();
        const matches = this._matchWords(source, unmatchedWords.keys());
        if (matches.length) {
          matches.forEach((match) => {
            unmatchedWords.delete(match);
          });
        }
      }
    }

    return !unmatchedWords.size;
  };

  _matchWords = (source: string, words: Object) => {
    if (!source) return [];
    const sourceWords = source.toLowerCase().split(' ');
    const matches = [];
    let word = words.next().value;
    while (word) {
      for (let sw = 0; sw < sourceWords.length; sw++) {
        if (
          word &&
          word.length &&
          sourceWords &&
          sourceWords[sw].length &&
          sourceWords[sw].indexOf(word) > -1
        ) {
          matches.push(word);
          break;
        }
      }
      word = words.next().value;
    }
    return matches;
  };

  _formatOption = (assignee: Object) => ({
    value: assignee.value,
    label: assignee.label
  });

  handleChange = (search: string) => {
    const { orgUsers } = this.props;
    this.setState({
      suggestions: this._filterAndFormatSuggestions(orgUsers, search)
    });
  };

  render() {
    const { onChangeOwnerId, ownerId } = this.props;
    const { suggestions } = this.state;
    return (
      <TypeAhead
        onSelect={(ownerId: string) => {
          onChangeOwnerId(ownerId);
        }}
        suggestions={suggestions}
        value={ownerId}
        onChange={this.handleChange}
        dataHcName="order-search-email-typeahead"
        placeholder="Search or select a user"
      />
    );
  }
}
