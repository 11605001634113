// @flow
import React from 'react';
import { themr } from 'react-css-themr';

import {
  EditProfileIcon,
  SupportIcon,
  LogoutIcon
} from '../../../../icons';

import { withTracker } from '../../Tracker';
import { NAVIGATION_BAR_TYPES, NAVIGATION_BAR_EVENTS } from '../../constants/events.constants';

import MenuItem, { type MenuItemThemeType } from '../MenuItem';
import { NAV_BAR_HC_NAMES } from '../../hc.names';

export type AccountCustomizedItemsProps = {
  theme: MenuItemThemeType,
  platformBaseUrl: string,
  supportUrl: string,
  logoutOptions: {
    url: string,
    onClick: () => void
  }
}

const MenuItemWithTracker = withTracker(MenuItem);

const AccountCustomizationItems = (props: AccountCustomizedItemsProps) => {
  const { theme, platformBaseUrl, supportUrl, logoutOptions = {} } = props;
  const items = [
    {
      icon: <EditProfileIcon />,
      label: 'Edit Profile',
      url: `${platformBaseUrl}/my-user/contact-info`,
      type: NAVIGATION_BAR_TYPES.ACCOUNT_MENU_TYPE,
      event: NAVIGATION_BAR_EVENTS.EDIT_PROFILE_CLICK,
      dataHcName: NAV_BAR_HC_NAMES.HC_PROFILE_EDIT
    },
    {
      icon: <SupportIcon />,
      label: 'Support',
      url: supportUrl,
      type: NAVIGATION_BAR_TYPES.ACCOUNT_MENU_TYPE,
      event: NAVIGATION_BAR_EVENTS.SUPPORT_CLICK,
      dataHcName: NAV_BAR_HC_NAMES.HC_PROFILE_SUPPORT
    },
    {
      icon: <LogoutIcon />,
      label: 'Logout',
      url: logoutOptions.url || `${platformBaseUrl}/logout`,
      onClick: logoutOptions.onClick,
      type: NAVIGATION_BAR_TYPES.ACCOUNT_MENU_TYPE,
      event: NAVIGATION_BAR_EVENTS.LOGOUT_CLICK,
      dataHcName: NAV_BAR_HC_NAMES.HC_PROFILE_LOGOUT
    }
  ];
  return (
    <React.Fragment>
      {
        items.map(item =>
          <MenuItemWithTracker key={item.label} theme={theme} {...item} />
        )
      }
    </React.Fragment>
  );
};

export default themr('AccountCustomizationItemsThemed')(AccountCustomizationItems);
