// @flow
import React from 'react';
import {
  RTThemedTable,
  RTThemedTableHead,
  RTThemedTableRow,
  RTThemedTableCell
} from '@hc/component-lib/lib/rt-themed';
import { connect } from 'react-redux';

import { dateTimeFormatter } from 'src/client/helpers/formatter-helpers';
import { reportSummaryOrderItemsStateSelector } from 'src/client/selectors/report-summary.selectors';
// import PaginationNumbered from 'src/components/PaginationNumbered';
import Loader from 'src/components/Loader';
import { reportSummaryItemsGoToPage } from 'src/client/actions/report-summary.actions';
import PaginationNoRouting from 'src/components/PaginationNoRouting';

import type { MapStateToProps } from 'react-redux';
import type { OrderItem } from 'src/client/types/order-item';
import type { State } from 'src/reducers';
import type { ReportSummaryOrderItemsState } from 'src/client/reducers/report-summary-order-items.reducer';
import type { Dispatch } from 'redux';
import type { ActionGeneratorFn } from 'src/components/PaginationNoRouting';

import styles from './OrderItems.css';

type OrderItemsProps = {
  orderId: string,
  reportSummaryOrderItemsState: ReportSummaryOrderItemsState,
  onItemsGoToPage: (orderId: string, page: number) => void
};

const OrderItems = (props: OrderItemsProps) => {
  const { orderId, reportSummaryOrderItemsState, onItemsGoToPage } = props;

  const orderItemsState =
    reportSummaryOrderItemsState.orderItemsByOrderId[orderId];
  const paginationActionGenerator: ActionGeneratorFn = (page: number) => {
    onItemsGoToPage(orderId, page);
  };

  return (
    <div className={styles.orderItemsContainer}>
      <Loader
        loading={
          orderItemsState === undefined || orderItemsState.status === 'loading'
        }
      />
      {orderItemsState && orderItemsState.status === 'loaded' && (
        <div>
          <RTThemedTable selectable={false} theme={styles}>
            <RTThemedTableHead theme={styles}>
              <RTThemedTableCell theme={styles} className={styles.dateCell}>
                <span>Completed Date</span>
              </RTThemedTableCell>
              <RTThemedTableCell theme={styles}>
                <span>File ID#</span>
              </RTThemedTableCell>
              <RTThemedTableCell theme={styles}>
                <span>Address</span>
              </RTThemedTableCell>
              <RTThemedTableCell theme={styles}>
                <span>City</span>
              </RTThemedTableCell>
              <RTThemedTableCell theme={styles}>
                <span>State</span>
              </RTThemedTableCell>
              <RTThemedTableCell theme={styles}>
                <span>ZIP</span>
              </RTThemedTableCell>
            </RTThemedTableHead>
            {orderItemsState.pageItems.map((orderItem: OrderItem) => {
              return (
                <RTThemedTableRow
                  data-hc-name="order-details"
                  key={orderItem.id}
                  theme={styles}
                >
                  <RTThemedTableCell
                    data-hc-name="completed-date"
                    theme={styles}
                    className={styles.dateCell}
                  >
                    <span>
                      {orderItem.completionDate
                        ? dateTimeFormatter(
                            orderItem.completionDate,
                            'MM/dd/yyyy'
                          )
                        : ''}
                    </span>
                  </RTThemedTableCell>
                  <RTThemedTableCell data-hc-name="file-id" theme={styles}>
                    <span>{orderItem.customerItemId}</span>
                  </RTThemedTableCell>
                  <RTThemedTableCell data-hc-name="address" theme={styles}>
                    <span>{orderItem.address}</span>
                  </RTThemedTableCell>
                  <RTThemedTableCell data-hc-name="city" theme={styles}>
                    <span>{orderItem.city}</span>
                  </RTThemedTableCell>
                  <RTThemedTableCell data-hc-name="state" theme={styles}>
                    <span>{orderItem.state}</span>
                  </RTThemedTableCell>
                  <RTThemedTableCell data-hc-name="zip" theme={styles}>
                    <span>{orderItem.zipcode}</span>
                  </RTThemedTableCell>
                </RTThemedTableRow>
              );
            })}
          </RTThemedTable>
          <PaginationNoRouting
            className={styles.paginationContainer}
            actionGeneratorFn={paginationActionGenerator}
            links={orderItemsState.links}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps: MapStateToProps<*, *, *> = (state: State) => {
  return {
    reportSummaryOrderItemsState: reportSummaryOrderItemsStateSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<*>) => {
  return {
    onItemsGoToPage: (orderId: string, page: number) =>
      dispatch(reportSummaryItemsGoToPage(orderId, page))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderItems);
