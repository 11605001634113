// @flow
import React from 'react';
import { connect } from 'react-redux';

import { ResetPassword as ResetPasswordForm } from '../auth-lib/components';
import { HcHeader } from 'src/components/HcHeader';
import { resetPasswordStateSelector } from 'src/selectors/user-state.selector';
import { submitResetPassword } from 'src/actions/forgot-password.actions';

import type { ResetPasswordState } from 'src/reducers/reset-password.reducer';
import type { State } from 'src/reducers';
import type { Dispatch } from 'redux';

import commonStyles from 'src/containers/common.css';
import styles from './ResetPassword.css';

type ResetPasswordProps = {
  onSubmit: (code: string, newPassword: string) => void,
  resetPasswordState: ResetPasswordState
};

const ResetPassword = (props: ResetPasswordProps) => {
  const { onSubmit, resetPasswordState } = props;

  return (
    <div>
      <HcHeader showAppLinks={false} userDetails={null} userContext={null} />
      <div className={commonStyles.containerContentWithHcHeader}>
        <ResetPasswordForm
          theme={styles}
          title="Reset Password"
          onSubmit={onSubmit}
          isSubmitting={resetPasswordState.status === 'submitting'}
          errorMessage={
            resetPasswordState.status === 'error'
              ? resetPasswordState.errorMessage
              : null
          }
        />
      </div>
    </div>
  );
};

function mapStateToProps(state: State) {
  return {
    resetPasswordState: resetPasswordStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onSubmit: (newPassword: string) =>
      dispatch(submitResetPassword(newPassword))
  };
}

const ResetPasswordWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);

export default ResetPasswordWrapped;
