// @flow
import type { Action } from 'src/client/actions';
import type { Order } from 'src/client/types/order';
import type { Links } from 'src/types/link';
import type { OrderSet } from 'src/client/types/order-set';
import type { OrderingParams } from 'src/types/list';

import { ORDER_SETS } from 'src/client/constants/order-sets';
import {
  ORDERS_LOAD,
  ORDERS_LOAD_SUCCESS,
  ORDERS_LOAD_ERROR,
  SEARCH_ORDERS,
  SORT_ORDERS,
  CHANGE_ORDER_SET,
  CHANGE_OWNER_ID,
  ORDERS_PARTIAL_LOAD_SUCCESS,
  CLEAR_ORDERS
} from 'src/client/actions/orders.actions';
import { WEBSOCKET_ORDER_UPDATED } from 'src/client/actions/websocket.actions';

type OrdersNotLoadedState = {
  status: 'not_loaded',
  fetching: false,
  orderSet: OrderSet,
  ownerId: ?string
};
type OrdersLoadingState = {
  status: 'loading',
  fetching: true,
  orderSet: OrderSet,
  ownerId: ?string
};
type OrdersLoadedState = {
  status: 'loaded',
  fetching: boolean,
  links: Links,
  pageItems: Order[],
  page: ?number,
  query: ?string,
  ordering: OrderingParams[],
  defaultOrdering: OrderingParams[],
  orderSet: OrderSet,
  ownerId: ?string
};
type OrdersErrorState = {
  status: 'error',
  fetching: false,
  errorMessage: string,
  orderSet: OrderSet,
  ownerId: ?string
};

export type OrdersState =
  | OrdersNotLoadedState
  | OrdersLoadingState
  | OrdersLoadedState
  | OrdersErrorState;

const defaultState = {
  status: 'not_loaded',
  fetching: false,
  orderSet: ORDER_SETS.ACTIVE,
  ownerId: null
};

export function ordersReducer(
  state: OrdersState = defaultState,
  action: Action
): OrdersState {
  switch (action.type) {
    case ORDERS_LOAD: {
      return state.status === 'loaded' // if reloading for search and sort
        ? {
            status: 'loaded',
            fetching: true,
            pageItems: state.pageItems,
            links: state.links,
            page: state.page,
            query: state.query,
            ordering: state.ordering,
            defaultOrdering: state.defaultOrdering,
            orderSet: state.orderSet,
            ownerId: state.ownerId
          }
        : {
            status: 'loading',
            fetching: true,
            orderSet: state.orderSet,
            ownerId: state.ownerId
          };
    }
    case ORDERS_LOAD_SUCCESS: {
      return {
        status: 'loaded',
        fetching: false,
        pageItems: action.payload.orders,
        links: action.payload.links,
        page: action.payload.page,
        query: action.payload.query,
        ordering: action.payload.ordering,
        defaultOrdering: action.payload.defaultOrdering,
        orderSet: action.payload.orderSet,
        ownerId: state.ownerId
      };
    }
    case ORDERS_LOAD_ERROR: {
      return {
        status: 'error',
        fetching: false,
        errorMessage: action.payload.errorMessage,
        orderSet: state.orderSet,
        ownerId: state.ownerId
      };
    }
    case SEARCH_ORDERS: {
      return state.status === 'loaded'
        ? {
            status: 'loaded',
            fetching: false,
            pageItems: state.pageItems,
            links: state.links,
            page: null,
            query: action.payload.query,
            ordering: state.ordering,
            defaultOrdering: state.defaultOrdering,
            orderSet: state.orderSet,
            ownerId: state.ownerId
          }
        : state;
    }
    case SORT_ORDERS: {
      return state.status === 'loaded'
        ? {
            status: 'loaded',
            fetching: false,
            pageItems: state.pageItems,
            links: state.links,
            page: state.page,
            query: state.query,
            ordering:
              action.payload.ordering === state.defaultOrdering
                ? []
                : action.payload.ordering,
            defaultOrdering: state.defaultOrdering,
            orderSet: state.orderSet,
            ownerId: state.ownerId
          }
        : state;
    }
    case CHANGE_ORDER_SET: {
      return state.status === 'loaded'
        ? {
            status: 'loaded',
            fetching: false,
            pageItems: state.pageItems,
            links: state.links,
            page: null,
            query: state.query,
            ordering: state.ordering,
            defaultOrdering: state.defaultOrdering,
            orderSet: action.payload.orderSet,
            ownerId: state.ownerId
          }
        : state;
    }
    case CHANGE_OWNER_ID: {
      return state.status === 'loaded'
        ? {
            status: 'loaded',
            fetching: false,
            pageItems: state.pageItems,
            links: state.links,
            page: null,
            query: state.query,
            ordering: state.ordering,
            defaultOrdering: state.defaultOrdering,
            orderSet: state.orderSet,
            ownerId: action.payload.ownerId
          }
        : state;
    }
    case WEBSOCKET_ORDER_UPDATED: {
      if (state.status === 'loaded') {
        const orderId: string = action.payload.orderId;
        const matchingOrderIndex = state.pageItems.findIndex(
          (order: Order) => order.id === orderId
        );
        if (matchingOrderIndex !== -1) {
          const updatedOrder: Order = {
            ...state.pageItems[matchingOrderIndex],
            status: action.payload.status,
            cancelled: action.payload.cancelled
          };
          return {
            status: 'loaded',
            fetching: false,
            pageItems: Object.assign([...state.pageItems], {
              [matchingOrderIndex]: updatedOrder
            }),
            links: state.links,
            page: state.page,
            query: state.query,
            ordering: state.ordering,
            defaultOrdering: state.defaultOrdering,
            orderSet: state.orderSet,
            ownerId: state.ownerId
          };
        }
      }
      return state;
    }
    case ORDERS_PARTIAL_LOAD_SUCCESS: {
      if (state.status === 'loaded') {
        const { orders } = action.payload;
        const updatedOrderById = orders.reduce((accum, updatedOrder: Order) => {
          accum[updatedOrder.id] = updatedOrder;
          return accum;
        }, {});
        return {
          status: 'loaded',
          fetching: false,
          pageItems: state.pageItems.map((order: Order) => {
            if (updatedOrderById[order.id]) {
              return updatedOrderById[order.id];
            }
            return order;
          }),
          links: state.links,
          page: state.page,
          query: state.query,
          ordering: state.ordering,
          defaultOrdering: state.defaultOrdering,
          orderSet: state.orderSet,
          ownerId: state.ownerId
        };
      }
      return state;
    }
    case CLEAR_ORDERS: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
}
