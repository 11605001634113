// @flow
import { type AutoRefreshTimeDifference } from '../types/workflow';

import {
  type WorkflowAutoRefreshAction,
  WORKFLOW_AUTO_REFRESH_ENABLE,
  WORKFLOW_AUTO_REFRESH_DISABLE,
  WORKFLOW_AUTO_REFRESH_UPDATE_CONFIG,
  WORKFLOW_AUTO_REFRESH_ALIVE
} from '../actions/workflow-auto-refresh.actions';

export type WorkflowAutoRefreshState = {
  status: 'INIT' | 'ENABLED' | 'DISABLED',
  refreshTimeDifference: AutoRefreshTimeDifference,
  timestamp: ?string
};

const defaultState = {
  status: 'INIT',
  refreshTimeDifference: {
    amount: 3,
    unit: 'seconds'
  },
  timestamp: null
};

export function workflowAutoRefreshReducer(
  previousState: WorkflowAutoRefreshState = defaultState,
  action: WorkflowAutoRefreshAction
): WorkflowAutoRefreshState {
  switch (action.type) {
    case WORKFLOW_AUTO_REFRESH_ENABLE: {
      return {
        ...previousState,
        status: 'ENABLED',
        timestamp: null
      };
    }
    case WORKFLOW_AUTO_REFRESH_UPDATE_CONFIG: {
      const { amount, unit } = action.payload;
      return {
        ...previousState,
        refreshTimeDifference: {
          ...previousState.refreshTimeDifference,
          ...(amount ? { amount } : {}),
          ...(unit ? { unit } : {})
        }
      };
    }
    case WORKFLOW_AUTO_REFRESH_DISABLE: {
      return {
        ...previousState,
        status: 'DISABLED'
      };
    }
    case WORKFLOW_AUTO_REFRESH_ALIVE: {
      return {
        ...previousState,
        timestamp: action.payload.timestamp
      };
    }
    default:
      return previousState;
  }
}
