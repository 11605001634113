// @flow
import React from 'react';
import { RTThemedIconButton } from '@hc/component-lib/lib/rt-themed';
import {
  Address,
  Button,
  FlatButton,
  CircleIcon,
  CaretIcon
} from '@hc/component-lib';

import { ORDER_ITEM_STATUSES } from 'src/client/constants/order-item-statuses';
import { PROPERTY_TYPES_TO_LABEL } from 'src/constants/property-types';
import {
  currencyCommaSeparated,
  dateFormatter,
  dateTimeFormatter
} from 'src/client/helpers/formatter-helpers';
import { getOrderItemStatusLabel } from 'src/client/helpers/status-helpers';

import type { OrderTypeDescriptor } from 'src/client/types/order';
import type {
  OrderItem,
  AvailableDownloadable
} from 'src/client/types/order-item';
import type { User } from 'src/client/types/manage-users';
import classNames from 'classnames';

import styles from './ItemDetails.css';

const DataItem = ({ data, label, ...rest }: { data: any, label: string }) => {
  return (
    <div {...rest} className={styles.dataContainer}>
      <div className={styles.dataLabel}>{label}</div>
      <div className={styles.dataValue}>{data || '-'}</div>
    </div>
  );
};

type ItemDetailsProps = {
  orderItem: OrderItem,
  selectedOrderTypeDescriptor: OrderTypeDescriptor,
  selectedOrderOwner: ?User,
  onClose: () => void,
  onViewOrderItemReport: (orderItemId: string, pdfType: string) => void,
  onDownloadOrderItem: (orderItemId: string, address: string) => void
};

const ItemDetails = ({
  orderItem,
  selectedOrderTypeDescriptor,
  selectedOrderOwner,
  onClose,
  onViewOrderItemReport,
  onDownloadOrderItem
}: ItemDetailsProps) => {
  const {
    address,
    unit,
    city,
    state,
    zipcode,
    county,
    status,
    availableDownloadables,
    id,
    integrationKey,
    customerItemId,
    propertyType,
    createdAt,
    appraisedValue,
    inspectionCompletionDate,
    inspectionDate,
    appraisalDate
  } = orderItem;
  const fullAddress = unit
    ? `${address} ${unit} ${city} ${state} ${zipcode}`
    : `${address} ${city} ${state} ${zipcode}`;
  const isComplete = status === ORDER_ITEM_STATUSES.COMPLETE;
  return (
    <div data-hc-name="detail-info-panel">
      <div data-hc-name="header-div" className={styles.titleCloseContainer}>
        <h3>Order Item Details</h3>
        <RTThemedIconButton icon="close" onClick={onClose} />
      </div>
      <div className={styles.content}>
        <div className={styles.panelSection}>
          <div
            className={classNames([styles.statusContainer], {
              [styles.complete]: isComplete
            })}
            data-hc-name="status-div"
          >
            <CircleIcon className={styles.statusIndicator} />
            <div className={styles.status}>
              {getOrderItemStatusLabel(status)}
            </div>
          </div>
          <Address
            street={address}
            city={city}
            addrState={state}
            theme={styles}
            unit={unit}
            zip={zipcode}
          />
          {availableDownloadables.map(
            (availableDownloadable: AvailableDownloadable, idx) => {
              return (
                <Button
                  data-hc-name={`download-${availableDownloadable.key}-action`}
                  key={availableDownloadable.key}
                  onClick={() =>
                    onViewOrderItemReport(id, availableDownloadable.key)
                  }
                  primary={idx === 0}
                  theme={styles}
                >
                  View {availableDownloadable.displayName}
                </Button>
              );
            }
          )}
          {isComplete && (
            <FlatButton
              data-hc-name="download-all-data-action"
              onClick={() => onDownloadOrderItem(id, fullAddress)}
              theme={styles}
            >
              Download All Data
            </FlatButton>
          )}
        </div>
        <ExpansionPanel title="Metadata">
          <DataItem
            data-hc-name="housecanary-id-div"
            data={integrationKey}
            label="HouseCanary ID"
          />
          <DataItem
            data-hc-name="customer-id-div"
            data={customerItemId}
            label="Client File ID"
          />
          <DataItem
            data-hc-name="property-type-div"
            data={propertyType && PROPERTY_TYPES_TO_LABEL[propertyType]}
            label="Property Type"
          />
          <DataItem data-hc-name="county-div" data={county} label="County" />
          <DataItem
            data-hc-name="item-created-div"
            data={createdAt ? dateTimeFormatter(createdAt) : null}
            label="Created"
          />
          {selectedOrderTypeDescriptor.needsInspection && (
            <DataItem
              data-hc-name="appraisal-div"
              data={currencyCommaSeparated(appraisedValue)}
              label="Appraisal"
            />
          )}
          {selectedOrderTypeDescriptor.needsInspection && (
            <DataItem
              data-hc-name="inspection-date-div"
              data={
                inspectionCompletionDate
                  ? dateFormatter(inspectionCompletionDate)
                  : inspectionDate
                  ? dateFormatter(inspectionDate)
                  : null
              }
              label="Inspection Date"
            />
          )}
          {selectedOrderTypeDescriptor.needsAppraisal && (
            <DataItem
              data-hc-name="appraisal-date-div"
              data={appraisalDate ? dateTimeFormatter(appraisalDate) : null}
              label="Appraisal Date"
            />
          )}
          {selectedOrderOwner != null && (
            <DataItem
              data-hc-name="order-owner-div"
              data={selectedOrderOwner.email}
              label="Ordered By"
            />
          )}
        </ExpansionPanel>
      </div>
    </div>
  );
};

type ExpansionPanelProps = {
  title: string,
  children?: any
};

type ExpansionPanelState = {
  show: boolean
};

export class ExpansionPanel extends React.Component<
  ExpansionPanelProps,
  ExpansionPanelState
> {
  state = { show: true };

  handleToggle = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const { children, title } = this.props;
    const { show } = this.state;
    return (
      <div className={styles.expansionPanel}>
        <div className={styles.titleContainer} onClick={this.handleToggle}>
          <h4>{title}</h4>
          <CaretIcon
            className={classNames([styles.caretIcon], {
              [styles.active]: show
            })}
            width="18px"
            height="18px"
          />
        </div>
        {show && <div className={styles.panelContent}>{children}</div>}
      </div>
    );
  }
}

export default ItemDetails;
