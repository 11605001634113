// @flow
import type { Saga } from 'redux-saga';
import type { ValuationRevisionResponseAction } from '../actions/valuation-revision.actions';

import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Client } from 'src/api/order-manager-api-client';
import {
  valuationRevisionSuccess,
  valuationRevisionError,
  VALUATION_REVISION_RESPONSE,
  valuationRevisionResponseSuccess,
  valuationRevisionResponseError
} from '../actions/valuation-revision.actions';
import userTokenSelector from 'src/selectors/user-token.selector';
// import * as logger from 'src/logger';

export function* initValuationRevision({
  orderId
}: {
  orderId: string
}): Saga<void> {
  try {
    const userToken = yield select(userTokenSelector);
    // request valuation with order id
    const client = new Client(userToken);
    const valuation = yield call(
      [client, client.getPartnerValuationRevision],
      orderId
    );

    yield put(valuationRevisionSuccess(valuation));
  } catch (e) {
    yield put(valuationRevisionError(e.message));
  }
}

export function* handleValuationRevisionResponse(
  action: ValuationRevisionResponseAction
): Saga<void> {
  const { response, comment } = action.payload;
  try {
    if (response === 'reject' && !comment) {
      return yield put(
        valuationRevisionResponseError(
          'Please supply a message for rejecting revision request.'
        )
      );
    }
    const userToken = yield select(userTokenSelector);
    const orderId = yield select((state) => {
      return state.partner.valuationRevision.data.valuation.integration_key;
    });
    const client = new Client(userToken);
    yield call(
      [client, client.submitPartnerValuationRevisionResponse],
      orderId,
      response,
      comment
    );
    yield put(valuationRevisionResponseSuccess());
  } catch (e) {
    yield put(valuationRevisionResponseError(e.message));
  }
}

export function* registerValuationRevisionResponseSaga(): Saga<void> {
  yield takeEvery(VALUATION_REVISION_RESPONSE, handleValuationRevisionResponse);
}
