// @flow
import type { RouteDefinition } from './types/route';

import { CLIENT_ROUTES, CLIENT_REDIRECTS } from './client/routes';
import { ADMIN_ROUTES } from './admin/routes';
import { PARTNER_ROUTES } from './partner/routes';

type Redirect = (args: { [key: string]: string }) => string;

export const ROUTES: RouteDefinition = {
  ...CLIENT_ROUTES,
  ...ADMIN_ROUTES,
  ...PARTNER_ROUTES
};

export const REDIRECTS: { [key: string]: Redirect } = {
  ...CLIENT_REDIRECTS
};
