// @flow
import type { Dispatch } from 'redux';
import React from 'react';
import { connect } from 'react-redux';
import SearchAutoComplete, {
  type Result
} from '../../components/autocomplete/SearchAutoComplete';
import debounce from 'lodash.debounce';
import fetch from 'isomorphic-fetch';

import { validateAddressField } from 'src/client/actions/address-validation.actions';
import userTokenSelector from 'src/selectors/user-token.selector';
import * as logger from 'src/logger';
import { authClient } from '../../sagas/auth-client-instance';
import { LoginRequiredError } from '../../api/order-manager-api-client';
import { handleStandardExceptionsAction } from '../actions/auth.actions';

var SETTINGS = window.SETTINGS;
const CANCELLED_ERROR_MESSAGE = 'cancelled';

class AutoCompleteService {
  apiHost: string;

  token: string;

  currentRequestId: number;

  constructor(config) {
    this.apiHost = config.apiHost;
    this.token = config.token;
    this.currentRequestId = 0;
  }

  maybeRefreshToken(response, searchString) {
    if (response.status === 401) {
      return this.refreshToken(searchString);
    }
  }

  async refreshToken(searchString) {
    try {
      const userContext = await authClient.checkOrRefreshToken();
      const newToken = userContext.validity.token;
      if (newToken) {
        this.token = newToken;
        return this.makeRequest(searchString);
      }
    } catch (e) {
      throw new LoginRequiredError();
    }
  }

  makeRequest = (searchString) => {
    const searchUrl = `${this.apiHost}?q=${encodeURIComponent(searchString)}`;
    const requestId = this.currentRequestId + 1;
    this.currentRequestId = requestId;
    return fetch(searchUrl, {
      method: 'GET',
      headers: {
        'HC-Auth-Token': this.token
      }
    })
      .then((response) => {
        if (this.currentRequestId !== requestId) {
          throw Error(CANCELLED_ERROR_MESSAGE);
        }

        return (
          this.maybeRefreshToken(response, searchString) || response.json()
        );
      })
      .then((data) => {
        if (this.currentRequestId !== requestId) {
          throw Error(CANCELLED_ERROR_MESSAGE);
        }

        return (
          data &&
          data.map((addr) => ({
            slug: addr.fields.slug,
            fullLine: addr.fields.full_line,
            hasAvm: addr.fields.has_avm,
            id: addr.id
          }))
        );
      });
  };
}

type AddressAutocompleteProps = {
  label: string,
  apiHost: string,
  token: string,
  value: string,
  error: string,
  onChange: (string, any) => void,
  onBlur: (string, any, any) => void,
  handleStandardExceptions: (Error) => void,
  theme?: string,
  disabled?: boolean,
  required?: boolean,
  dataHcName?: string
};

type AddressAutocompleteState = {
  value: string,
  selectedOption: Result | null,
  options: Result[]
};

class AddressAutocomplete extends React.Component<
  AddressAutocompleteProps,
  AddressAutocompleteState
> {
  service: AutoCompleteService;

  constructor(props: AddressAutocompleteProps) {
    super(props);
    this.state = {
      value: props.value || '',
      selectedOption: null,
      options: []
    };
  }

  componentDidMount = () => {
    this.service = new AutoCompleteService({
      apiHost: this.props.apiHost,
      token: this.props.token
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.setState((state) => {
      if (nextProps.value !== state.value) {
        return {
          value: nextProps.value
        };
      }
    });
  };

  updateResults = debounce(
    (query) => {
      if (query && query.length > 2) {
        this.service
          .makeRequest(query)
          .then((result) => {
            result &&
              this.setState({
                options: result.map((e) => ({ id: e.id, label: e.fullLine }))
              });
          })
          .catch((result) => {
            if (result && result.message !== CANCELLED_ERROR_MESSAGE) {
              this.props.handleStandardExceptions(result);
              logger.logWarning(
                new Error(`Cannot load suggestions: ${result}`),
                'address autocomplete'
              );
            }
          });
      }
    },
    500,
    { leading: true, trailing: true }
  );

  handleChange = (value) => {
    if (!value) {
      this.updateResults.cancel();
      this.props.onChange(value);
    } else {
      this.updateResults(value);
    }
  };

  handleBlur = (e) => {
    this.props.onBlur(
      this.state.value,
      this.state.selectedOption ? this.state.selectedOption.id : null,
      e
    );
  };

  clearOptions = () => {
    this.setState({ options: [] });
  };

  handleSelect = (idx: number) => {
    const { options } = this.state;
    const selectedOption = options[idx];
    this.handleChange(selectedOption.label);
    this.setState({ value: selectedOption.label, selectedOption });
  };

  renderResult = (idx: number) => {
    const { options } = this.state;
    if (!options) return null;
    return <span key={idx}>{options[idx].label}</span>;
  };

  render() {
    const { disabled, theme, error, label, required, dataHcName } = this.props;
    const { value, options } = this.state;
    const requiredLabel = required ? `${label} *` : label;
    return (
      <SearchAutoComplete
        disabled={
          Object.prototype.hasOwnProperty.call(this.props, 'disabled')
            ? disabled
            : false
        }
        results={options}
        error={error}
        label={requiredLabel}
        onSearch={this.handleChange}
        onSelectResult={this.handleSelect}
        resultsAreLoading={false}
        renderResult={this.renderResult}
        value={value}
        theme={theme}
        required={required}
        dataHcName={dataHcName}
        onBlur={this.handleBlur}
        onClearResults={this.clearOptions}
      />
    );
  }
}

const AddressAutoCompleteInput = (props) => {
  const {
    token,
    validateAddress,
    input: { name, onChange, onBlur, ...inputHandlers },
    meta: { error, form },
    ...rest
  } = props;

  const handleBlur = (address, addressId, e) => {
    onBlur(e);
    if (address) {
      validateAddress(name, address, addressId, form);
    } else if (onChange) {
      onChange(null);
    }
  };

  const handleChange = (address) => {
    if (!address) {
      onChange(null);
    }
  };

  return (
    <AddressAutocomplete
      apiHost={SETTINGS.ADDRESS_AUTOCOMPLETE_URL}
      token={token}
      error={error}
      onBlur={handleBlur}
      onChange={handleChange}
      {...inputHandlers}
      {...rest}
    />
  );
};

export default connect(
  (state) => ({ token: userTokenSelector(state) }),
  (dispatch: Dispatch<*>) => ({
    handleStandardExceptions: handleStandardExceptionsAction,
    validateAddress: (
      field: string,
      address: string,
      addressId: string,
      formName: string
    ) => {
      if (address) {
        dispatch(validateAddressField(address, field, addressId, formName));
      }
    }
  })
)(AddressAutoCompleteInput);
