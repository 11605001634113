// @flow
import store from '../store';
import { getAuthClient } from '../auth-lib/clients';
import { AUTH_API_URL, LOGIN_SERVICE_URL } from '../constants/auth-urls';
import { userLoadComplete } from '../actions/user.actions';
import * as logger from '../logger';
import {
  ADMINISTRATOR_ROLE_NAME,
  POWER_USER_ROLE_NAME
} from '../auth-lib/clients/auth-api-client';
import type { UserDetails, Role } from 'src/types/user';
import type { UserContext } from '../auth-lib/types/user.types';

const CAN_CREATE_ORDERS_ROLE_NAME = 'OM Create';

export async function loadUserDetails(
  userContext: UserContext
): Promise<UserDetails> {
  const isAdminUser = !!userContext.user.roles.find(
    (role: Role) =>
      role.name === POWER_USER_ROLE_NAME ||
      role.name === ADMINISTRATOR_ROLE_NAME
  );
  return {
    id: userContext.user.id,
    email: userContext.user.email,
    firstName: userContext.user.first_name,
    lastName: userContext.user.last_name,
    orgName: userContext.user.organization.name,
    orgId: userContext.user.organization_id,
    address: userContext.user.street_address,
    city: userContext.user.city,
    state: userContext.user.state,
    zipcode: userContext.user.zip_code,
    phone: userContext.user.phone,
    token: userContext.validity.token,
    roles: userContext.user.roles,
    canCreateOrders:
      isAdminUser ||
      !!userContext.user.roles.find(
        (role: Role) => role.name === CAN_CREATE_ORDERS_ROLE_NAME
      ),
    isAdminUser
  };
}

export async function updateUserContextCallback(userContext: UserContext): any {
  const userDetails = await loadUserDetails(userContext);
  logger.setUserContext(userDetails.email, userDetails.id.toString());
  store.dispatch(userLoadComplete(userDetails, userContext));
}

export const authClient = getAuthClient(
  LOGIN_SERVICE_URL,
  AUTH_API_URL,
  updateUserContextCallback
);
