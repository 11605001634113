// @flow
import type { Action } from 'src/client/actions';

import {
  FETCH_ORG_USERS_SUCCESS,
  FETCH_ORG_USERS_ERROR
} from 'src/client/actions/orders.actions';

type OrgUsersStateNotLoaded = {
  status: 'not_loaded',
  loading: false
};
type OrgUsersStateLoading = {
  status: 'loading',
  orderId: string,
  loading: true
};
type OrgUsersStateLoaded = {
  status: 'loaded',
  loading: false,
  orgUsers: any
};
type OrgUsersStateError = {
  status: 'error',
  loading: false,
  errorMessage: string
};
export type OrgUsersState =
  | OrgUsersStateNotLoaded
  | OrgUsersStateLoading
  | OrgUsersStateLoaded
  | OrgUsersStateError;

const defaultState = {
  status: 'not_loaded',
  loading: false
};

export function orgUsersReducer(
  state: OrgUsersState = defaultState,
  action: Action
): OrgUsersState {
  switch (action.type) {
    case FETCH_ORG_USERS_SUCCESS: {
      const { orgUsers } = action.payload;
      return {
        status: 'loaded',
        loading: false,
        orgUsers: orgUsers
      };
    }
    case FETCH_ORG_USERS_ERROR: {
      const { error } = action.payload;
      return {
        status: 'error',
        loading: false,
        errorMessage: error
      };
    }
    default: {
      return state;
    }
  }
}
