// @flow
import type { State } from 'src/reducers';
import type { OrderItemMessagesState } from 'src/client/reducers/order-item-messages.reducer';
import type { OrderItemPostMessageState } from 'src/client/reducers/order-item-post-message.reducer';

import clientSelector from './client.selector';

export const orderItemMessagesStateSelector = (
  state: State
): OrderItemMessagesState =>
  clientSelector(state).orderItems.itemMessages.messages;

export const orderItemPostMessageStateSelector = (
  state: State
): OrderItemPostMessageState =>
  clientSelector(state).orderItems.itemMessages.postMessage;
