// @flow
import React from 'react';
import { type ProductDetailsLookupMapType, type ProductSlugType } from '../../../types/products.types';
import {
  AgileSuiteIcon,
  DataExplorerIcon,
  DeveloperCenterIcon,
  MarketExplorerIcon,
  PlatformIcon,
  PropertyExplorerIcon
} from '../../../icons/images';

import { getHCLauncherProductName } from '../hc.names';

export const PLATFORM = 'Platform';
export const SUPPORT = 'Support';
export const BEACON = 'Beacon';
export const LOGIN = 'Login';
export const EHRMANTRAUT = 'Ehrmantraut';
export const LOGIN_SERVICE = 'Login Service';
// legacy constants with db slugs
export const VALUE_REPORT = 'Value Report';
export const ANALYTICS_API = 'Analytics API';
export const MARKET_INSIGHTS_PRO = 'Pro';
export const ORDER_MANAGER = 'Order Manager';

// new constants with db slugs
export const PROPERTY_EXPLORER = VALUE_REPORT;
export const DATA_EXPLORER = ANALYTICS_API;
export const DEVELOPER_CENTER = 'Developer Center';
export const MARKET_EXPLORER = MARKET_INSIGHTS_PRO;

export const TRACKING_NAME_PLATFORM = 'platform';
export const TRACKING_NAME_DATA_EXPLORER = 'match_and_append';
export const TRACKING_NAME_PROPERTY_EXPLORER = 'valuereport';
export const TRACKING_NAME_MARKET_EXPLORER = 'pro';
export const TRACKING_NAME_DEVELOPER_CENTER = 'developer_center';
export const TRACKING_NAME_ORDER_MANAGER = 'order_manager';

/**
 * Products/Applications that exist in Ehrmantraut's Application table
 */
export const PRODUCT_SLUGS: { [key: string]: ProductSlugType } = {
  PROPERTY_EXPLORER: VALUE_REPORT,
  MARKET_EXPLORER: MARKET_INSIGHTS_PRO,
  DATA_EXPLORER: ANALYTICS_API,
  ORDER_MANAGER: ORDER_MANAGER
};

export const SORTED_PRODUCT_LABELS = [
  'Data Explorer',
  'Property Explorer',
  'Market Explorer',
  'Developer Center',
  'Agile Suite'
];

export const PLATFORM_PRODUCT = {
  key: PLATFORM,
  trackingName: TRACKING_NAME_PLATFORM,
  label: 'Analytics Platform',
  icon: <PlatformIcon />,
  dataHcName: getHCLauncherProductName(PLATFORM)
};

export const PROPERTY_EXPLORER_PRODUCT = {
  key: VALUE_REPORT,
  trackingName: TRACKING_NAME_PROPERTY_EXPLORER,
  label: 'Property Explorer',
  icon: <PropertyExplorerIcon />,
  dataHcName: getHCLauncherProductName(PROPERTY_EXPLORER),
  hintText: 'Formerly Value Report'
};

export const DATA_EXPLORER_PRODUCT = {
  key: ANALYTICS_API,
  trackingName: TRACKING_NAME_DATA_EXPLORER,
  label: 'Data Explorer',
  icon: <DataExplorerIcon />,
  dataHcName: getHCLauncherProductName(DATA_EXPLORER),
  hintText: `Formerly Match & Append`
};

export const DEVELOPER_CENTER_PRODUCT = {
  key: DEVELOPER_CENTER,
  trackingName: TRACKING_NAME_DEVELOPER_CENTER,
  label: 'Developer Center',
  icon: <DeveloperCenterIcon />,
  dataHcName: getHCLauncherProductName(DEVELOPER_CENTER),
  hintText: 'Formerly Analytics API'
};

export const MARKET_EXPLORER_PRODUCT = {
  key: MARKET_INSIGHTS_PRO,
  trackingName: TRACKING_NAME_MARKET_EXPLORER,
  label: 'Market Explorer',
  icon: <MarketExplorerIcon />,
  dataHcName: getHCLauncherProductName(MARKET_EXPLORER),
  hintText: 'Formerly Market Insights Pro'
};

export const ORDER_MANAGER_PRODUCT = {
  key: ORDER_MANAGER,
  trackingName: TRACKING_NAME_ORDER_MANAGER,
  title: 'Order Manager',
  label: 'Agile Suite',
  icon: <AgileSuiteIcon />,
  dataHcName: getHCLauncherProductName(ORDER_MANAGER),
  hintText: 'Delivered by Order Manager'
};

/**
 * This legacy access product is used when the account
 * has a legacy subscription to Market Explorer. Therefore, access is determined from
 * the applications array in UserContext instead of accessible_applications.
 */
export const MARKET_EXPLORER_LEGACY_ACCESS_PRODUCT = {
  ...MARKET_EXPLORER_PRODUCT,
  id: 999,
  display_name: MARKET_EXPLORER_PRODUCT.label,
  name: MARKET_EXPLORER_PRODUCT.key,
  supports_trials: false,
  terms_url: ''
};

export const DEVELOPER_CENTER_OM_ACCESS_PRODUCT = {
  ...DEVELOPER_CENTER_PRODUCT,
  id: 998,
  display_name: DEVELOPER_CENTER_PRODUCT.label,
  name: DEVELOPER_CENTER_PRODUCT.key,
  supports_trial: false,
  terms_url: ''
};

export const PRODUCTS: ProductDetailsLookupMapType = {
  [PLATFORM]: PLATFORM_PRODUCT,
  [VALUE_REPORT]: PROPERTY_EXPLORER_PRODUCT,
  [ANALYTICS_API]: DATA_EXPLORER_PRODUCT,
  [DEVELOPER_CENTER]: DEVELOPER_CENTER_PRODUCT,
  [MARKET_INSIGHTS_PRO]: MARKET_EXPLORER_PRODUCT,
  [ORDER_MANAGER]: ORDER_MANAGER_PRODUCT
};
