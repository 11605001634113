// @flow
import type { Action } from '../actions';

import {
  SHOW_EXTEND_ORDER_DIALOG,
  DISMISS_EXTEND_ORDER_DIALOG,
  EXTEND_ORDER,
  EXTEND_ORDER_SUCCESS,
  EXTEND_ORDER_ERROR
} from 'src/client/actions/extend-order.actions';

type ExtendOrderOpenStateType = {
  dialogVisible: true,
  status: 'untouched'
};

type ExtendOrderClosedStateType = {
  dialogVisible: false,
  status: 'closed'
};

type ExtendOrderStateType = {
  dialogVisible: true,
  status: 'loading',
  id: string
};

type ExtendOrderErrorStateType = {
  dialogVisible: false,
  status: 'error',
  error: string
};

export type ExtendOrderState =
  | ExtendOrderClosedStateType
  | ExtendOrderOpenStateType
  | ExtendOrderStateType
  | ExtendOrderErrorStateType;

const defaultState = {
  dialogVisible: false,
  status: 'closed',
  id: ''
};

export function extendOrderReducer(
  state: ExtendOrderState = defaultState,
  action: Action
) {
  switch (action.type) {
    case SHOW_EXTEND_ORDER_DIALOG: {
      return {
        dialogVisible: true,
        status: 'untouched'
      };
    }
    case DISMISS_EXTEND_ORDER_DIALOG: {
      return {
        dialogVisible: false,
        status: 'closed'
      };
    }
    case EXTEND_ORDER: {
      return {
        dialogVisible: true,
        status: 'loading',
        id: action.payload.id
      };
    }
    case EXTEND_ORDER_SUCCESS: {
      return {
        dialogVisible: false,
        status: 'extended'
      };
    }
    case EXTEND_ORDER_ERROR: {
      return {
        dialogVisible: false,
        status: 'error',
        error: action.payload.errorMessage
      };
    }
    default: {
      return state;
    }
  }
}
