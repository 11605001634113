// @flow
// for button or link inside of a clickable row
// aka to be able to click the button or link without having the row be "clicked" via event propagation
export function cancelAndCall(f: Function) {
  return function (e: window.Event, ...rest: any[]) {
    e.stopPropagation();
    e.preventDefault();
    f(...rest);
  };
}
