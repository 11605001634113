// @flow
import React from 'react';

import type { ReportSummary as ReportSummaryType } from 'src/client/types/report-summary';

import YearSummary from './YearSummary';

type ReportSummaryProps = {
  reportSummary: ReportSummaryType
};

const ReportSummary = (props: ReportSummaryProps) => {
  const { reportSummary } = props;

  const yearSummaries = reportSummary.years.map((year, index) => {
    const yearSummary = reportSummary[year];
    return <YearSummary key={index} year={year} yearSummary={yearSummary} />;
  });

  return <div>{yearSummaries}</div>;
};

export default ReportSummary;
