// @flow
import React, { type ComponentType, type Node } from 'react';
import { TrackingContext } from './context';

import { type TrackerConfigurationType } from '../../types';
import { NAVIGATION_BAR_PREFIX } from './constants/events.constants';

import client from '../../clients/tracking.client';

const GenericLink = (props: any) => <a {...props} />;

export type TrackerProps = {
  url?: string,
  onClick?: () => void,
  type: string,
  event: string,
  Link?: ComponentType<any>,
  eventData?: { [key: string]: any }
}

export const withTracker = (Component: ComponentType<any>) => {
  return (props: TrackerProps) => {
    return (
      <TrackingContext.Consumer>
        {(tracking: TrackerConfigurationType) => {
          return <TrackerLink Link={Component} tracking={tracking} {...props} />;
        }}
      </TrackingContext.Consumer>
    );
  };
};

export const withTrackingData = (Component: ComponentType<any>) => {
  return (props: any) => {
    return (
      <TrackingContext.Consumer>
        {(tracking: TrackerConfigurationType) => {
          return <Component tracking={tracking} {...props} />;
        }}
      </TrackingContext.Consumer>
    );
  };
};

export type TrackerLinkProps = {
  url?: string,
  href?: string,
  onClick?: () => void,
  type: string,
  event: string,
  children?: Node,
  Link: ComponentType<any>,
  eventData?: { [key: string]: any },
  tracking: TrackerConfigurationType
}

export class TrackerLink extends React.Component<TrackerLinkProps, void> {
  static defaultProps = {
    Link: GenericLink
  }

  handleClick = () => {
    const { onClick, url, href } = this.props;

    // track
    try {
      const trackingUrl = this.getTrackingUrl();
      const eventData = this.getEventData();
      // non-blocking call
      client
        .track(trackingUrl, eventData)
        .catch((e) => {
          console.error('NavigationBar[NavigationLink]: Unable to track event.', e);
        });
    } catch (e) {
      console.error('NavigationBar[NavigationLink]: Unable to track event.', e);
    }

    // console.log(something);
    // execute action
    onClick
      ? onClick()
      : (url || href) && window.location.assign(window.decodeURIComponent(url || href));
  }

  getEventData = () => {
    const { event, type, eventData, tracking: { data: trackingData } } = this.props;
    const currentOrg = trackingData.current_organization || {};
    return {
      app: trackingData.app,
      auth_organization_id: currentOrg.id,
      auth_organization_slug: currentOrg.slug,
      auth_user_id: trackingData.user.id,
      email: trackingData.user.email,
      event_type: type === NAVIGATION_BAR_PREFIX ? type : `${NAVIGATION_BAR_PREFIX}_${type}`,
      event_name: event,
      event_data: {
        ...eventData,
        ...trackingData
      }
    };
  }

  getTrackingUrl = () => {
    const {
      tracking: {
        options: {
          trackerBaseUrl = '',
          trackerUrl = ''
        }
      }
    } = this.props;
    return trackerUrl || `${trackerBaseUrl}/api/v1/track/event`;
  }

  render () {
    const {
      Link,
      children,
      onClick,
      url,
      href,
      tracking,
      ...props
    } = this.props;

    const linkProps = {
      ...(url ? { href: url } : {}),
      ...(href ? { href: href } : {})
    };

    return <Link {...linkProps} {...props} onClick={this.handleClick}>{children}</Link>;
  }
}
