import React from 'react';
import {
  dateStrFormatter,
  formatDistanceToNowWithPastMessageFormatter
} from '../../client/helpers/formatter-helpers';

import type { WorkflowWorker } from '../types/WorflowWorker';

import { DataTable } from '@hc/component-lib/hclib/components/organisms/data-table';
import { TextButton } from '@hc/component-lib/hclib/components/atoms/button';

import Link from 'src/components/Link';

import theme from './WorkflowWorker.css';

export type WorkflowWorkersProps = {
  workers: WorkflowWorker[],
  onWorkerClick: () => void,
  onRefreshClick: () => void
};

const getColumns = (props: WorkflowWorkersProps) => [
  {
    label: 'Topic Set',
    attribute: 'topic_set',
    CellContent: ({ row }) =>
      row.topic_set.charAt(0).toUpperCase() +
      row.topic_set.slice(1).toLowerCase()
  },
  {
    label: 'Worker ID',
    attribute: 'worker_id',
    CellContent: ({ row }) => {
      return (
        <Link
          className={theme.WorkerLink}
          onClick={() => props.onWorkerClick(row.worker_id)}
        >
          {row.worker_id}
        </Link>
      );
    }
  },
  {
    label: 'External Tasks',
    attribute: 'count',
    cellStyle: {
      width: 75
    },
    cellStyleBody: {
      textAlign: 'center'
    },
    CellContent: ({ row }) => row.count
  },
  {
    label: 'Completed Tasks',
    attribute: 'number_of_tasks',
    cellStyle: {
      width: 75
    },
    cellStyleBody: {
      textAlign: 'center'
    },
    CellContent: ({ row }) => row.number_of_tasks
  },
  {
    label: 'Created',
    attribute: 'created_at',
    CellContent: ({ row }) => dateStrFormatter(row.created_at)
  },
  {
    label: 'Updated',
    attribute: 'updated_at',
    cellStyle: {
      width: 200
    },
    CellContent: ({ row }) =>
      formatDistanceToNowWithPastMessageFormatter(row.updated_at)
  }
];

const WorkflowWorkers = (props: WorkflowWorkersProps) => {
  const { workers, onRefreshClick } = props;

  return (
    <div className={theme.WorkflowWorkers}>
      <div className={theme.WorkflowWorkerHeader}>
        <h3>Workflow Workers</h3>
        <TextButton onClick={onRefreshClick}>Refresh</TextButton>
      </div>
      <DataTable columns={getColumns(props)} data={workers} />
    </div>
  );
};

export default WorkflowWorkers;
