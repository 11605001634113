// @flow
import React from 'react';

import {
  RTThemedCard,
  RTThemedCardTitle,
  RTThemedCardText,
  RTThemedButton,
  RTThemedInput
} from '@hc/component-lib/lib/rt-themed';

type AdminLoginProps = {
  onLogin: (username: string, password: string) => void,
  loginFailed: boolean,
  loading: boolean
};

class AdminLogin extends React.Component<
  AdminLoginProps,
  { username: string, password: string }
> {
  constructor(props: AdminLoginProps) {
    super(props);
    this.state = {
      username: '',
      password: ''
    };
  }

  handleChange(field: string, value: string) {
    this.setState({ [field]: value });
  }

  handleLoginClick = () => {
    this.props.onLogin(this.state.username, this.state.password);
  };

  render() {
    return (
      <RTThemedCard style={{ width: '550px' }}>
        <RTThemedCardTitle
          title="Login"
          subtitle={this.props.loginFailed ? 'Login Failed' : ''}
        />
        <RTThemedCardText>
          <section>
            <RTThemedInput
              icon="account_box"
              type="text"
              label="Username"
              name="username"
              value={this.state.username}
              onChange={this.handleChange.bind(this, 'username')}
            />
            <RTThemedInput
              icon="lock"
              type="password"
              label="Password"
              name="password"
              value={this.state.password}
              onChange={this.handleChange.bind(this, 'password')}
            />
            <RTThemedButton
              label="Login"
              raised
              primary
              onClick={this.handleLoginClick}
              disabled={this.props.loading}
            />
          </section>
        </RTThemedCardText>
      </RTThemedCard>
    );
  }
}

export default AdminLogin;
