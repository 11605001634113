// @flow
import React from 'react';
import { passwordValidator, isRequiredValidator } from '../../services/validator';
import ValidatedPasswordInput from './ValidatedPasswordInput';

/**
 * Password field with password validator
 */
export const PasswordField = (props : {}) => (<ValidatedPasswordInput validate={[isRequiredValidator, passwordValidator]} {...props} />);

export default PasswordField;
