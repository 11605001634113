// @flow
import { takeEvery, select, call, all } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import {
  UPLOAD_SUPPORTING_DOCUMENTS,
  type UploadSupportingDocumentsAction,
  type OnUploadDocumentSuccessCallback,
  type OnUploadDocumentErrrorCallback
} from '../actions/supporting-documents.actions';
import userTokenSelector from '../../selectors/user-token.selector';
import { Client } from '../../api/order-manager-api-client';
import { handleStandardExceptions, snakeToCamel } from '../../sagas/helpers';
import type { SupportingDocument } from '../types/supporting-documents';
import type { File } from '../types/add-order';

function* uploadIndividualDocument(
  file: File,
  onDocumentSuccess: OnUploadDocumentSuccessCallback,
  onDocumentError: OnUploadDocumentErrrorCallback
) {
  try {
    const formData = new window.FormData();
    formData.append('file', file);
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const response = yield call(
      [client, client.uploadSupportingDocument],
      formData
    );
    const supportingDocument: SupportingDocument = snakeToCamel(
      response,
      Object.keys(response),
      {},
      false
    );
    onDocumentSuccess(supportingDocument);
  } catch (e) {
    yield call(handleStandardExceptions, e);
    onDocumentError(file.name, e.message);
  }
}

function* handleUploadSupportingDocuments(
  action: UploadSupportingDocumentsAction
): Saga<void> {
  try {
    const { documentFiles, onDocumentSuccess, onDocumentError } =
      action.payload;

    const calls = [];
    documentFiles.forEach((documentFile) => {
      calls.push(
        call(
          uploadIndividualDocument,
          documentFile,
          onDocumentSuccess,
          onDocumentError
        )
      );
    });

    if (calls.length > 0) {
      yield all(calls);
    }
  } catch (e) {
    yield call(handleStandardExceptions, e);
  }
}

export function* registerSupportDocumentsSaga(): Saga<void> {
  yield takeEvery(UPLOAD_SUPPORTING_DOCUMENTS, handleUploadSupportingDocuments);
}
