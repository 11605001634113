// @flow
import type { Action } from '../actions';
import type { OrderTypeDescriptor } from '../types/order';

import {
  SHOW_ADD_ORDER_DIALOG,
  HIDE_ADD_ORDER_DIALOG,
  LOAD_ORDER_TYPE_DESCRIPTORS_SUCCESS,
  LOAD_ORDER_TYPE_DESCRIPTORS_ERROR
} from 'src/client/actions/add-order.actions';

type OrderTypeDescriptorsNotLoadedState = {
  status: 'not_loaded' | 'loading'
};
type OrderTypeDescriptorsLoadedState = {
  status: 'loaded',
  descriptors: OrderTypeDescriptor[]
};
type OrderTypeDescriptorsErrorState = {
  status: 'error',
  error: string
};
export type OrderTypeDescriptorsState =
  | OrderTypeDescriptorsNotLoadedState
  | OrderTypeDescriptorsLoadedState
  | OrderTypeDescriptorsErrorState;

const defaultState = {
  status: 'not_loaded'
};

export function orderTypeDescriptorsReducer(
  state: OrderTypeDescriptorsState = defaultState,
  action: Action
): OrderTypeDescriptorsState {
  switch (action.type) {
    case SHOW_ADD_ORDER_DIALOG: {
      return {
        status: 'loading'
      };
    }
    case HIDE_ADD_ORDER_DIALOG: {
      return {
        ...defaultState
      };
    }
    case LOAD_ORDER_TYPE_DESCRIPTORS_SUCCESS: {
      return {
        status: 'loaded',
        descriptors: action.payload.orderTypeDescriptors
      };
    }
    case LOAD_ORDER_TYPE_DESCRIPTORS_ERROR: {
      return {
        status: 'error',
        error: action.payload.errorMessage
      };
    }
    default: {
      return state;
    }
  }
}
