// @flow
import { call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { authClient } from '../../sagas/auth-client-instance';
import routeTo from 'src/history/route-to';
import { ORDERS_ROUTE } from 'src/client/routes';
import { handleStandardExceptions } from 'src/sagas/helpers';

export function* resolveSamlLogin({ token }: { token: string }): Saga<void> {
  try {
    yield call([authClient, authClient.samlLogin], token);
    console.log('saml:auth');
    yield call(routeTo, ORDERS_ROUTE);
  } catch (e) {
    console.log('saml:failed');
    yield call(handleStandardExceptions, e);
    yield call(routeTo, 'login');
  }
}
