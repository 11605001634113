// @flow
import * as React from 'react';
import { RTThemedProgressBar } from '@hc/component-lib/lib/rt-themed';
import { Button as ComponentButton, FlatButton } from '@hc/component-lib';

import buttonTheme from './button-theme.css';
import progressBarTheme from './progress-bar-theme.css';
import progressBarThemeWhite from './progress-bar-theme-white.css';

type LoadingButtonProps = {
  loading: boolean,
  flat?: boolean,
  children?: React.Node,
  primary?: boolean,
  dataHcName?: string
};

const LoadingButton = (props: LoadingButtonProps) => {
  const { loading, flat, children, ...button } = props;

  const Button = flat ? FlatButton : ComponentButton;
  return (
    <Button theme={buttonTheme} {...button}>
      {loading && (
        <RTThemedProgressBar
          theme={button.primary ? progressBarThemeWhite : progressBarTheme}
          type="circular"
          mode="indeterminate"
        />
      )}
      {children}
    </Button>
  );
};

export default LoadingButton;
