// @flow
import type { Saga } from 'redux-saga';
import { select, call } from 'redux-saga/effects';

import userTokenSelector from 'src/selectors/user-token.selector';

import { Client } from 'src/api/order-manager-api-client';

export function* requestAddressParts(
  address: string,
  addressId: ?string
): Saga<void> {
  const userToken = yield select(userTokenSelector);
  const client = new Client(userToken);
  return yield call([client, client.getAddressParts], address, addressId);
}
