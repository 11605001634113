// @flow
import { ORDER_ITEM_STATUSES } from 'src/client/constants/order-item-statuses';
import type { OrderItemStatus } from 'src/client/types/order-item';
import type { OrderStatus } from 'src/client/types/order';
import type { OrderSet } from 'src/client/types/order-set';
import {
  ORDER_SETS,
  ORDER_SET_TO_STATUSES
} from 'src/client/constants/order-sets';

const ORDER_ITEM_STATUSES_TO_LABEL: { [key: ?string]: string } = {
  [ORDER_ITEM_STATUSES.PROCESSING]: 'Waiting',
  [ORDER_ITEM_STATUSES.INSPECTION_PENDING]: 'In Inspection',
  [ORDER_ITEM_STATUSES.INSPECTION_COMPLETE]: 'In Appraisal',
  [ORDER_ITEM_STATUSES.APPRAISAL_PENDING]: 'In Appraisal',
  [ORDER_ITEM_STATUSES.APPRAISAL_REVISION_PENDING]: 'In Appraisal-Revision',
  [ORDER_ITEM_STATUSES.REVISION_REQUESTED]: 'Revision Requested',
  [ORDER_ITEM_STATUSES.EVALUATION_COMPLETE]: 'Generating Report',
  [ORDER_ITEM_STATUSES.REPORT_PENDING]: 'Generating Report',
  [ORDER_ITEM_STATUSES.COMPLETE]: 'Complete',
  [ORDER_ITEM_STATUSES.CANCEL_PENDING]: 'Cancel Pending',
  [ORDER_ITEM_STATUSES.CANCELLED]: 'Cancelled',
  [ORDER_ITEM_STATUSES.QC_REVIEW]: 'QC Review',
  [ORDER_ITEM_STATUSES.INSPECTION_REVIEW]: 'Inspection Review',
  [ORDER_ITEM_STATUSES.INSPECTION_CORRECTION_REVIEW]:
    'Inspection Correction Review',
  [ORDER_ITEM_STATUSES.VALUATION_REVIEW]: 'Valuation Review',
  [ORDER_ITEM_STATUSES.INSPECTION_REVISION]: 'In Inspection Revision'
};

export const getOrderItemStatusLabel = (
  orderItemStatus: OrderItemStatus
): string => {
  let statusLabel = null;
  if (orderItemStatus === null) {
    statusLabel = 'Waiting';
  } else if (
    Object.prototype.hasOwnProperty.call(
      ORDER_ITEM_STATUSES_TO_LABEL,
      orderItemStatus
    )
  ) {
    statusLabel = ORDER_ITEM_STATUSES_TO_LABEL[orderItemStatus];
  } else {
    const statusWords = orderItemStatus.match(/[A-Z][a-z]+/g);
    statusLabel = statusWords != null ? statusWords.join(' ') : '';
  }
  return statusLabel;
};

export const orderStatusBelongsInOrderSet = (
  orderStatus: OrderStatus
): OrderSet => {
  if (ORDER_SET_TO_STATUSES[ORDER_SETS.ACTIVE].indexOf(orderStatus) !== -1) {
    return ORDER_SETS.ACTIVE;
  } else if (
    ORDER_SET_TO_STATUSES[ORDER_SETS.COMPLETED].indexOf(orderStatus) !== -1
  ) {
    return ORDER_SETS.COMPLETE;
  } else if (
    ORDER_SET_TO_STATUSES[ORDER_SETS.CANCELLED].indexOf(orderStatus) !== -1
  ) {
    return ORDER_SETS.CANCELLED;
  }
  return ORDER_SETS.ALL;
};
