// @flow
import React from 'react';
import store from 'store/dist/store.modern';
import { RTThemedCheckbox } from '@hc/component-lib/lib/rt-themed';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { OPT_OUT_STORAGE_KEY } from 'src/client/constants/add-order';
import styles from './AddOrderConfirm.css';

type AddOrderConfirmProps = {
  show: boolean,
  onDismiss: () => void
};

type AddOrderconfirmState = {
  optOut: boolean
};

class AddOrderConfirm extends React.Component<
  AddOrderConfirmProps,
  AddOrderconfirmState
> {
  onOptOutToggle: Function;

  constructor(props: AddOrderConfirmProps) {
    super(props);

    this.state = {
      optOut: false
    };
  }

  componentDidMount() {
    const optOut = store.get(OPT_OUT_STORAGE_KEY);
    this.setState({
      optOut: optOut || false
    });
  }

  onOptOutToggle = (shouldOptOut: boolean) => {
    this.setState({
      optOut: shouldOptOut
    });
    store.set(OPT_OUT_STORAGE_KEY, shouldOptOut);
  };

  render() {
    return (
      <ConfirmDialog
        title="Checking Addresses"
        loading={false}
        show={this.props.show}
        onConfirm={this.props.onDismiss}
        onDismiss={this.props.onDismiss}
        confirmButtonLabel={'Got it'}
        error={null}
        containerHasHeader
      >
        <div data-hc-name="add-order-confirm-dialog-content">
          <div className={styles.contentSection}>
            We're checking your address(es); addresses without issues will be
            placed immediately. If we find issues with addresses in your order,
            they will be flagged for manual review.
          </div>
          <div className={styles.contentSection}>
            Please add <strong>noreply@housecanary.com</strong> to your email
            contacts so we can alert you about any issues.
          </div>
          <div className={styles.checkContainer}>
            <RTThemedCheckbox
              data-hc-name="add-order-confirm-dialog-never-show"
              theme={styles}
              label={`Don't show this message again`}
              checked={this.state.optOut}
              onChange={this.onOptOutToggle}
            />
          </div>
        </div>
      </ConfirmDialog>
    );
  }
}

export default AddOrderConfirm;
