// @flow
import React from 'react';
import { Button } from '@hc/component-lib';
import { themr } from 'react-css-themr';
import formSubmitTheme from './FormSubmit.scss';

type FormSubmitProps = {
  disabled: boolean,
  onSubmit: any => mixed,
  label: string,
  theme: {
    FormSubmit: string,
    Button: string
  }
};

export const FormSubmit = (props: FormSubmitProps) => {
  const { disabled, theme, onSubmit, label, ...rest } = props;
  return (
    <div className={theme.FormSubmit}>
      <Button theme={theme} onClick={onSubmit} disabled={disabled} {...rest}>{label}</Button>
    </div>
  );
};

FormSubmit.defaultProps = {
  disabled: true,
  label: null
};

export const FormSubmitThemed = themr('FormSubmitThemed', formSubmitTheme)(FormSubmit);

export default FormSubmitThemed;
