// @flow

import React from 'react';
import { Collapse } from 'react-collapse';
import { RTThemedFontIcon } from '@hc/component-lib/lib/rt-themed';
import classNames from 'classnames';

import styles from './CollapsibleRow.css';

type CollapsibleRowProps = {
  header: string,
  hasNestedCollapse?: boolean,
  children?: any,
  theme?: any,
  dataHcNameHeader?: string
};

type CollapsibleRowState = {
  isOpened: boolean
};

// careful with margins!

class CollapsibleRow extends React.PureComponent<
  CollapsibleRowProps,
  CollapsibleRowState
> {
  constructor(props: CollapsibleRowProps) {
    super(props);
    this.state = { isOpened: false };
  }

  handleToggle = () => {
    this.setState((prevState) => {
      return { isOpened: !prevState.isOpened };
    });
  };

  render() {
    const {
      header,
      theme = {},
      children,
      hasNestedCollapse,
      dataHcNameHeader
    } = this.props;
    const { isOpened } = this.state;

    const rowClassName = classNames(
      styles.row,
      theme.row,
      {
        [styles.isOpened]: isOpened
      },
      theme.isOpened && {
        [theme.isOpened]: isOpened
      }
    );
    const headerClassName = classNames(styles.header, theme.header);
    const iconClassName = classNames(styles.icon, theme.icon);
    return (
      <div className={rowClassName}>
        <div className={headerClassName} onClick={this.handleToggle}>
          <div data-hc-name={dataHcNameHeader || null}>{header}</div>
          <div>
            {isOpened ? (
              <RTThemedFontIcon
                className={iconClassName}
                value="keyboard_arrow_up"
              />
            ) : (
              <RTThemedFontIcon
                className={iconClassName}
                value="keyboard_arrow_down"
              />
            )}
          </div>
        </div>
        <Collapse hasNestedCollapse={hasNestedCollapse} isOpened={isOpened}>
          <div className={styles.content}>{children}</div>
        </Collapse>
      </div>
    );
  }
}

export default CollapsibleRow;
