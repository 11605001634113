// @flow
import React from 'react';
import { connect } from 'react-redux';
import { RTThemedIconButton } from '@hc/component-lib/lib/rt-themed';

import type { Dispatch } from 'redux';
import type { OrderItem } from 'src/client/types/order-item';
import type { OrderItemMessagesState } from 'src/client/reducers/order-item-messages.reducer';
import type { OrderItemPostMessageState } from 'src/client/reducers/order-item-post-message.reducer';
import type { SelectedOrderState } from 'src/client/reducers/selected-order.reducer';
import type { SidePanelState } from 'src/client/reducers/side-panel.reducer';

import styles from './ItemMessagesContainer.css';

import {
  orderItemMessagesStateSelector,
  orderItemPostMessageStateSelector
} from 'src/client/selectors/order-item-messages.selectors';
import { selectedOrderStateSelector } from 'src/client/selectors/order-items.selectors';
import { sidePanelStateSelector } from 'src/client/selectors/sidepanel.selectors';
import Message from 'src/components/message/Message.jsx';
import MessageComposer from 'src/components/message/MessageComposer.jsx';
import { postMessage } from 'src/client/actions/order-item-messages.actions';
import { closeSidePanel } from 'src/client/actions/side-panel.actions';
import Loader from 'src/components/Loader';
import * as logger from 'src/logger';

type ItemMessagesContainerProps = {
  orderItemMessagesState: OrderItemMessagesState,
  selectedOrderState: SelectedOrderState,
  sidePanelState: SidePanelState,
  orderItemPostMessageState: OrderItemPostMessageState,
  onShowOrderItemDetails: (orderItem: OrderItem) => void,
  onCloseSidebar: () => void,
  onAddMessage: (orderId: string, orderItemId: string, comment: string) => void
};

class ItemMessagesContainer extends React.Component<
  ItemMessagesContainerProps,
  void
> {
  handleSendMessage = (orderItemId: string, comment: string) => {
    if (this.props.selectedOrderState.status === 'loaded') {
      const { id: orderId } = this.props.selectedOrderState.order;

      this.props.onAddMessage(orderId, orderItemId, comment);
    } else {
      logger.logException(
        new Error(
          'OrderItemMessagesContainer#handleSendMessage selectedOrder is not defined'
        )
      );
    }
  };

  render() {
    const {
      orderItemMessagesState,
      selectedOrderState,
      sidePanelState,
      orderItemPostMessageState,
      onCloseSidebar
    } = this.props;

    if (!sidePanelState.open || sidePanelState.contentType !== 'messages') {
      logger.logException(
        new Error(
          'OrderItemMessagesContainer sidepanel not open or conentType is not messages'
        )
      );
      return null;
    }

    const { id: orderItemId, address, unit } = sidePanelState.orderItem;

    return (
      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <h3>
              {selectedOrderState.status === 'loaded'
                ? selectedOrderState.order.valuationPartner
                : ''}
            </h3>
            <p>
              {address} {unit}
            </p>
          </div>
          <RTThemedIconButton icon="close" onClick={onCloseSidebar} />
        </div>
        {orderItemMessagesState.status === 'error' && (
          <div className={styles.errorContainer}>Messages failed to load</div>
        )}
        <div className={styles.messagesContainer}>
          <Loader loading={orderItemMessagesState.loading} />
          <div className={styles.messages}>
            {orderItemMessagesState.status === 'loaded' &&
              orderItemMessagesState.messageList.map((message, index) => {
                return <Message key={index} message={message} />;
              })}
          </div>
        </div>

        <div className={styles.messageComposerContainer}>
          <MessageComposer
            onSendMessage={(comment) =>
              this.handleSendMessage(orderItemId, comment)
            }
            postMessageError={
              orderItemPostMessageState.status === 'error'
                ? orderItemPostMessageState.error
                : null
            }
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orderItemMessagesState: orderItemMessagesStateSelector(state),
    selectedOrderState: selectedOrderStateSelector(state),
    sidePanelState: sidePanelStateSelector(state),
    orderItemPostMessageState: orderItemPostMessageStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onCloseSidebar: () => dispatch(closeSidePanel()),
    onAddMessage: (orderId: string, orderItemId: string, comment: string) =>
      dispatch(postMessage(orderId, orderItemId, comment))
  };
}

const OrderItemMessagesWrap = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemMessagesContainer);
export default OrderItemMessagesWrap;
