// @flow
import { call, put, select, takeEvery } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { OrderExport } from 'src/client/types/order-export';
import type { RequestOrderExportAction } from 'src/client/actions/order-export.actions';
import type { OpenOrderExportsSidePanelAction } from 'src/client/actions/side-panel.actions';

import { Client } from 'src/api/order-manager-api-client';

import {
  fetchOrderExportsLoading,
  fetchOrderExportsSuccess,
  fetchOrderExportsError,
  REQUEST_ORDER_EXPORT,
  requestOrderExportError
} from 'src/client/actions/order-export.actions';
import userTokenSelector from 'src/selectors/user-token.selector';
import { handleStandardExceptions, snakeToCamel } from 'src/sagas/helpers';
import { OPEN_ORDER_EXPORTS_SIDE_PANEL } from 'src/client/actions/side-panel.actions';
import { connectWebsocket } from 'src/client/actions/websocket.actions';

function* fetchOrderExports(orderId: string): Saga<void> {
  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    yield put(fetchOrderExportsLoading(orderId));
    const response = yield call(
      [client, client.getOrderExportRequests],
      orderId
    );
    const orderExports: OrderExport[] = response.map((orderExport) =>
      snakeToCamel(orderExport, Object.keys(orderExport), {}, false)
    );
    yield put(fetchOrderExportsSuccess(orderId, orderExports));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(fetchOrderExportsError(orderId, e.message));
  }
}

function* handleOpenOrderExports(
  action: OpenOrderExportsSidePanelAction
): Saga<void> {
  const { order } = action.payload;
  yield call(fetchOrderExports, order.id);
  yield put(connectWebsocket());
}

function* handleRequestOrderExport(
  action: RequestOrderExportAction
): Saga<void> {
  const { orderId, includeJSON } = action.payload;

  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    yield call([client, client.requestOrderExport], orderId, includeJSON);

    yield call(fetchOrderExports, orderId);
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(requestOrderExportError(orderId, e.message));
  }
}

export function* registerOpenOrderExports(): Saga<void> {
  yield takeEvery(OPEN_ORDER_EXPORTS_SIDE_PANEL, handleOpenOrderExports);
}

export function* registerRequestOrderExport(): Saga<void> {
  yield takeEvery(REQUEST_ORDER_EXPORT, handleRequestOrderExport);
}
