// @flow
import { combineReducers } from 'redux';
import { addOrderReducer, type AddOrderState } from './add-order.reducer';
import { ordersReducer, type OrdersState } from './orders.reducer';
import {
  orderExportsReducer,
  type OrderExportsState
} from './order-exports.reducer';
import { orderItemsReducer, type OrderItemsState } from './order-items.reducer';
import {
  inOrderItemsReducer,
  type InOrderItemsState
} from './in-order-items.reducer';
import {
  needReviewItemsReducer,
  type NeedReviewItemsState
} from './need-review-items.reducer';
import {
  removedItemsReducer,
  type RemovedItemsState
} from './removed-items.reducer';
import {
  selectedOrderReducer,
  type SelectedOrderState
} from './selected-order.reducer';
import { sidePanelReducer, type SidePanelState } from './side-panel.reducer';
import {
  orderItemMessagesReducer,
  type OrderItemMessagesState
} from './order-item-messages.reducer';
import {
  orderItemPostMessageReducer,
  type OrderItemPostMessageState
} from './order-item-post-message.reducer';
import {
  orderProgressReducer,
  type OrderProgressState
} from './order-progress.reducer';
import {
  orderItemProcessCountsReducer,
  type OrderItemProcessCountsState
} from './order-item-process-counts.reducer';
import {
  reportSummaryReducer,
  type ReportSummaryState
} from './report-summary.reducer';
import {
  cancelOrderReducer,
  type CancelOrderState
} from './cancel-order.reducer';
import {
  cancelOrderItemReducer,
  type CancelOrderItemState
} from './cancel-order-item.reducer';
import {
  extendOrderReducer,
  type ExtendOrderState
} from './extend-order.reducer';
import {
  revisionRequestReducer,
  type RevisionRequestState
} from './revision-request.reducer';
import {
  orderTypeDescriptorsReducer,
  type OrderTypeDescriptorsState
} from './order-type-descriptors.reducer';
import {
  orderExportRequestReducer,
  type OrderExportRequestState
} from './order-export-request.reducer';
import {
  reportSummaryOrderItemsReducer,
  type ReportSummaryOrderItemsState
} from './report-summary-order-items.reducer';
import {
  selectedOrderOwnerReducer,
  type SelectedOrderOwnerState
} from './selected-order-owner.reducer';
import {
  updateReverifyItemReducer,
  type UpdateReverifyItemState
} from './update-reverify-item.reducer';
import {
  addressValidationReducer,
  type AddressValidationState
} from './address-validation.reducer';
import {
  reviewOrderReducer,
  type ReviewOrderState
} from './review-order.reducer';
import {
  moveOrderItemsReducer,
  type MoveOrderItemsState
} from './move-order-items.reducer';
import { orgUsersReducer, type OrgUsersState } from './org-users.reducer';
import {
  evaluationOrdersReducer,
  type EvaluationOrdersState
} from './evaluation-order.reducer';

export type ClientState = {
  addOrder: {
    addOrder: AddOrderState,
    orderTypeDescriptors: OrderTypeDescriptorsState
  },
  orders: OrdersState,
  orderExportRequest: OrderExportRequestState,
  orderExports: OrderExportsState,
  orderItems: {
    orderItems: OrderItemsState,
    inOrderItems: InOrderItemsState,
    needReviewItems: NeedReviewItemsState,
    removedItems: RemovedItemsState,
    selectedOrder: SelectedOrderState,
    selectedOrderOwner: SelectedOrderOwnerState,
    itemMessages: {
      messages: OrderItemMessagesState,
      postMessage: OrderItemPostMessageState
    },
    processCounts: OrderItemProcessCountsState,
    updateReverifyItem: UpdateReverifyItemState,
    reviewOrder: ReviewOrderState,
    moveOrderItems: MoveOrderItemsState
  },
  orderProgress: OrderProgressState,
  reportSummary: ReportSummaryState,
  reportSummaryOrderItems: ReportSummaryOrderItemsState,
  cancelOrder: CancelOrderState,
  cancelOrderItem: CancelOrderItemState,
  extendOrder: ExtendOrderState,
  revisionRequest: RevisionRequestState,
  sidePanel: SidePanelState,
  addressValidation: AddressValidationState,
  orgUsers: OrgUsersState,
  evaluationOrders: EvaluationOrdersState
};

const clientReducer = combineReducers({
  addOrder: combineReducers({
    addOrder: addOrderReducer,
    orderTypeDescriptors: orderTypeDescriptorsReducer
  }),
  orders: ordersReducer,
  orderExportRequest: orderExportRequestReducer,
  orderExports: orderExportsReducer,
  orderItems: combineReducers({
    orderItems: orderItemsReducer,
    inOrderItems: inOrderItemsReducer,
    needReviewItems: needReviewItemsReducer,
    removedItems: removedItemsReducer,
    selectedOrder: selectedOrderReducer,
    selectedOrderOwner: selectedOrderOwnerReducer,
    itemMessages: combineReducers({
      messages: orderItemMessagesReducer,
      postMessage: orderItemPostMessageReducer
    }),
    processCounts: orderItemProcessCountsReducer,
    updateReverifyItem: updateReverifyItemReducer,
    reviewOrder: reviewOrderReducer,
    moveOrderItems: moveOrderItemsReducer
  }),
  orderProgress: orderProgressReducer,
  reportSummary: reportSummaryReducer,
  reportSummaryOrderItems: reportSummaryOrderItemsReducer,
  cancelOrder: cancelOrderReducer,
  cancelOrderItem: cancelOrderItemReducer,
  extendOrder: extendOrderReducer,
  revisionRequest: revisionRequestReducer,
  sidePanel: sidePanelReducer,
  addressValidation: addressValidationReducer,
  orgUsers: orgUsersReducer,
  evaluationOrders: evaluationOrdersReducer
});

export default clientReducer;
