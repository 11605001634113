// @flow

import { type TrackerDataType } from '../types';

type TrackPayloadType = {
  app: string,
  auth_organization_id: number,
  auth_organization_slug: string,
  auth_user_id: number,
  email: string,
  event_name: string,
  event_data: TrackerDataType
}

export const track = (url: string, data: TrackPayloadType) => {
  return window.fetch(url, { method: 'POST', body: JSON.stringify(data) });
};

export default {
  track
};
