// @flow
import Raven from 'raven-js';

export const logException = (ex: any, context: any) => {
  Raven.captureException(ex, {
    extra: {
      context
    }
  });
  window.console && console.error && console.error(ex);
};

export const logWarning = (ex: any, context: any) => {
  Raven.captureMessage(ex, {
    level: 'warning',
    extra: {
      context
    }
  });
  window.console && console.warn && console.warn(ex);
};

export const setUserContext = (email?: string, id?: string) => {
  if (email && id) {
    Raven.setUserContext({
      email,
      id
    });
  } else {
    // clear out
    Raven.setUserContext();
  }
};
