import { call, takeLatest } from 'redux-saga/effects';
import { HANDLE_STANDARD_EXCEPTIONS } from '../client/actions/auth.actions';
import { handleStandardExceptions } from './helpers';
import type { Saga } from 'redux-saga';

export function* handleStandardExceptionsSaga(action: {
  payload: { error: Error }
}): Saga<*> {
  yield call(handleStandardExceptions, action.payload.error);
}

export function* registerHandleStandardExceptions(): Saga<*> {
  yield takeLatest(HANDLE_STANDARD_EXCEPTIONS, handleStandardExceptionsSaga);
}
