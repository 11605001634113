import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import { sagaMiddleware, runSagas } from './sagas';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

if (module.hot) {
  module.hot.accept();
  module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
}

runSagas();

export default store;
