import React from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';
import rowTheme from './Row.scss';

type RowProps = {
  theme: {
    Row: string,
    RowCenter: ?string,
  },
  center: boolean;
};

function Row ({ theme, center, ...props } : RowProps) {
  const cls = classNames(theme.Row, {
    [theme.RowCenter]: center
  });
  return <div className={cls} {...props} />;
}

Row.defaultProps = {
  center: false
};

export default themr('ThemedRow', rowTheme)(Row);
