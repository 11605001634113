// @flow
import React from 'react';
import { RTThemedSnackbar } from '@hc/component-lib/lib/rt-themed';
import { connect } from 'react-redux';

import type { MapStateToProps } from 'react-redux';
import type { State } from 'src/reducers';
import type { ViewTypes, Toast } from 'src/types/view';
import type { Dispatch } from 'redux';

import { ROUTES } from '../routes';
import Loading from './Loading.jsx';
import { hideToast as hideToastAction } from '../actions/view.actions';

export const VIEWS: { [key: string]: any } = Object.keys(ROUTES).reduce(
  (views, key) => {
    const def = ROUTES[key];
    views[def.id] = def.view;
    return views;
  },
  {}
);

const mapStateToProps: MapStateToProps<*, *, *> = (state: State) => ({
  previousView: state.view.previousView,
  selectedView: state.view.currentView,
  loading: state.view.loading,
  toast: state.view.toast
});

const mapDispatchToProps = (dispatch: Dispatch<*>) => {
  return {
    hideToast: () => dispatch(hideToastAction())
  };
};

type ContentViewProps = {
  previousView: ViewTypes,
  selectedView: ViewTypes,
  loading: boolean,
  toast: Toast,
  hideToast: () => void
};

const ContentView = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    previousView,
    selectedView,
    loading,
    toast,
    hideToast
  }: ContentViewProps) => {
    if (loading) {
      const PreviousView = VIEWS[previousView];
      return <Loading>{PreviousView ? <PreviousView /> : null}</Loading>;
    }

    const View = VIEWS[selectedView];
    return (
      <div>
        <View />
        <RTThemedSnackbar
          active={toast.visible}
          label={toast.visible ? toast.message : ''}
          timeout={5000}
          onTimeout={hideToast}
        />
      </div>
    );
  }
);

export default class App extends React.Component<void, void> {
  render() {
    return <ContentView />;
  }
}
