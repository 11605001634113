// @flow
import { call, put, select, takeEvery } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { QueryParams } from 'src/types/list';
import type { ReportSummaryOrderItemsState } from 'src/client/reducers/report-summary-order-items.reducer';

import {
  reportSummaryLoadSuccess,
  reportSummaryLoadError,
  reportSummaryItemsLoading,
  reportSummaryItemsLoadSuccess,
  reportSummaryItemsLoadError,
  REPORT_SUMMARY_ITEMS_LOAD,
  REPORT_SUMMARY_ITEMS_GO_TO_PAGE,
  REPORT_SUMMARY_DOWNLOAD_CSV,
  type ReportSummaryItemsLoadAction,
  type ReportSummaryItemsGoToPageAction,
  type ReportSummaryDownloadCSVAction
} from 'src/client/actions/report-summary.actions';
import { Client } from 'src/api/order-manager-api-client';
import userTokenSelector from 'src/selectors/user-token.selector';
import { ORDER_STATUSES } from 'src/client/constants/order-statuses';
import { handleStandardExceptions } from 'src/sagas/helpers';
import { orderResponseToCamelCase } from 'src/client/helpers/order-helpers';
import { orderItemResponseToCamelCase } from 'src/client/helpers/order-item.helpers';
import {
  addFileExtension,
  slugifyFileName
} from 'src/helpers/file-name-helpers';
import { reportSummaryOrderItemsStateSelector } from 'src/client/selectors/report-summary.selectors';

export function* resolveReportSummary(): Saga<void> {
  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    const params: QueryParams = {
      filters: {
        status: ORDER_STATUSES.COMPLETE
      }
    };
    const { data } = yield call([client, client.getOrders], params);
    const orders = data.map((order) => orderResponseToCamelCase(order));
    yield put(reportSummaryLoadSuccess(orders));
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(reportSummaryLoadError(e.message));
  }
}

export function* fetchOrderItems(
  orderId: string,
  params: QueryParams
): Saga<void> {
  try {
    yield put(reportSummaryItemsLoading(orderId));
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);

    const { links, data } = yield call(
      [client, client.getOrderItems],
      orderId,
      params
    );
    const orderItems = data.map((item) => orderItemResponseToCamelCase(item));
    yield put(
      reportSummaryItemsLoadSuccess(orderId, orderItems, links, params)
    );
  } catch (e) {
    yield call(handleStandardExceptions, e);
    yield put(reportSummaryItemsLoadError(orderId, e.message));
  }
}

function* handleOrderItemsGoToPage(
  action: ReportSummaryItemsGoToPageAction
): Saga<void> {
  const { orderId, page } = action.payload;
  const params: QueryParams = {
    page,
    pageSize: 50
  };
  yield call(fetchOrderItems, orderId, params);
}

function* handleOrderItemsLoad(
  action: ReportSummaryItemsLoadAction
): Saga<void> {
  const { orderId } = action.payload;
  const orderItemsState: ReportSummaryOrderItemsState = yield select(
    reportSummaryOrderItemsStateSelector
  );
  const alreadyLoadedState = orderItemsState.orderItemsByOrderId[orderId];
  // don't reload order items if they're already in state
  if (
    alreadyLoadedState !== undefined &&
    (alreadyLoadedState.status === 'loading' ||
      alreadyLoadedState.status === 'loaded')
  ) {
    return;
  }
  const params: QueryParams = {
    page: 1,
    pageSize: 50
  };
  yield call(fetchOrderItems, orderId, params);
}

function* handleReportSummaryDownloadCSV(
  action: ReportSummaryDownloadCSVAction
): Saga<void> {
  const { orderId, filename } = action.payload;
  const slugifiedFileName = addFileExtension(slugifyFileName(filename), 'csv');

  try {
    const userToken = yield select(userTokenSelector);
    const client = new Client(userToken);
    yield call(
      [client, client.downloadReportSummaryCSV],
      orderId,
      slugifiedFileName
    );
  } catch (e) {
    yield call(handleStandardExceptions, e);
  }
}

export function* registerOrderItemsGoToPage(): Saga<void> {
  yield takeEvery(REPORT_SUMMARY_ITEMS_GO_TO_PAGE, handleOrderItemsGoToPage);
}

export function* registerReportSummaryLoadItems(): Saga<void> {
  yield takeEvery(REPORT_SUMMARY_ITEMS_LOAD, handleOrderItemsLoad);
}

export function* registerReportSummaryDownloadCSV(): Saga<void> {
  yield takeEvery(REPORT_SUMMARY_DOWNLOAD_CSV, handleReportSummaryDownloadCSV);
}
