// @flow

import React, { type Element, type Node } from 'react';
import { themr } from 'react-css-themr';
import classNames from 'classnames';

import defaultTheme from './MenuItem.scss';

export type MenuItemThemeType = {
  MenuItem: string,
  MenuIcon: string,
  MenuLink: string
}

export type MenuItemProps = {
  className?: string,
  theme: MenuItemThemeType,
  label: string,
  icon: Node,
  url?: string,
  onClick?: () => void,
  Link?: Element<any>,
  dataHcName?: string
}

const MenuLink = ({ theme, url, label, dataHcName, onClick }) => {
  return (
    <div className={theme.MenuLink}>
      <span data-hc-name={dataHcName}>
        {label}
      </span>
    </div>
  );
};

class MenuItem extends React.Component <MenuItemProps> {
  handleClick = (e) => {
    const { url, onClick } = this.props;
    onClick
      ? onClick()
      : url && window.location.assign(window.decodeURIComponent(url));
  }

  render () {
    const {
      className,
      theme,
      label,
      url,
      icon,
      onClick,
      Link,
      dataHcName
    } = this.props;

    return (
      <div className={classNames(theme.MenuItem, className)} onClick={this.handleClick}>
        <div className={theme.MenuIcon}>{icon}</div>
        {
          Link || <MenuLink theme={theme} url={url} label={label} onClick={onClick} dataHcName={dataHcName} />
        }
      </div>
    );
  }
}

export default themr('MenuItemThemed', defaultTheme)(MenuItem);
