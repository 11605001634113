// @flow
import * as React from 'react';
import classNames from 'classnames';
import { RTThemedFontIcon } from '@hc/component-lib/lib/rt-themed';

import styles from './ListTabs.css';

type ListTabCountProps = {
  count?: number
};

type ListTabProps = ListTabCountProps & {
  isActive?: boolean,
  label: string,
  hasNotification?: boolean,
  isPaused?: boolean,
  onClick?: () => void
};

export const ListTabCount = ({ count }: ListTabCountProps) => {
  if (count == null) {
    return null;
  }
  return <div className={styles.count}>{count}</div>;
};

export const ListTab = ({
  isActive,
  count,
  label,
  hasNotification,
  isPaused,
  onClick
}: ListTabProps) => {
  return (
    <div
      className={classNames(
        styles.listTab,
        isActive ? styles.listTabActive : null
      )}
      onClick={onClick}
    >
      <div>
        <ListTabCount count={count} />
        <div className={styles.label}>{label}</div>
      </div>
      {hasNotification ? (
        <RTThemedFontIcon className={styles.notification} value="error" />
      ) : null}
      {isPaused ? (
        <RTThemedFontIcon
          className={styles.paused}
          value="pause_circle_filled"
        />
      ) : null}
    </div>
  );
};

type ListTabsProps = {
  dataHcName: string,
  children: React.Node
};

export const ListTabs = ({ dataHcName, children }: ListTabsProps) => {
  return (
    <div data-hc-name={dataHcName} className={styles.listTabs}>
      {children}
    </div>
  );
};
