// @flow
import {
  FETCH_SELECTED_ORDER_OWNER_SUCCESS,
  FETCH_SELECTED_ORDER_OWNER_ERROR
} from 'src/client/actions/order-items.actions';
import type { User } from 'src/client/types/manage-users';
import type { Action } from 'src/client/actions';

type SelectedOrderOwnerNotLoadedState = {
  status: 'not_loaded'
};
type SelectedOrderOwnerLoadedState = {
  status: 'loaded',
  selectedOrderId: string,
  orderOwner: User
};
export type SelectedOrderOwnerState =
  | SelectedOrderOwnerNotLoadedState
  | SelectedOrderOwnerLoadedState;

const defaultState = {
  status: 'not_loaded'
};

export function selectedOrderOwnerReducer(
  state: SelectedOrderOwnerState = defaultState,
  action: Action
): SelectedOrderOwnerState {
  switch (action.type) {
    case FETCH_SELECTED_ORDER_OWNER_SUCCESS: {
      return {
        status: 'loaded',
        selectedOrderId: action.payload.selectedOrderId,
        orderOwner: action.payload.user
      };
    }
    case FETCH_SELECTED_ORDER_OWNER_ERROR: {
      return {
        status: 'not_loaded'
      };
    }
    default: {
      return state;
    }
  }
}
