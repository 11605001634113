// @flow
import type { Action } from '../actions';

import {
  SHOW_CANCEL_ORDER_ITEM_DIALOG,
  DISMISS_CANCEL_ORDER_ITEM_DIALOG,
  CANCEL_ORDER_ITEM,
  CANCEL_ORDER_ITEM_ERROR,
  CANCEL_ORDER_ITEM_SUCCESS
} from 'src/client/actions/cancel-order-item.actions';

type CancelOrderItemClosedState = {
  dialogVisible: false,
  status: 'closed',
  address: null,
  orderId: null,
  orderItemId: null
};

type CancelOrderItemStateType = {
  dialogVisible: true,
  status: 'untouched' | 'loading' | 'cancelled',
  address: ?string,
  orderId: string,
  orderItemId: string
};

type CancelOrderItemErrorState = {
  dialogVisible: true,
  status: 'error',
  error: string,
  address: ?string,
  orderId: string,
  orderItemId: string
};

export type CancelOrderItemState =
  | CancelOrderItemClosedState
  | CancelOrderItemStateType
  | CancelOrderItemErrorState;

const defaultState = {
  dialogVisible: false,
  status: 'closed',
  address: null,
  orderId: null,
  orderItemId: null
};

export function cancelOrderItemReducer(
  state: CancelOrderItemState = defaultState,
  action: Action
) {
  switch (action.type) {
    case SHOW_CANCEL_ORDER_ITEM_DIALOG: {
      return {
        dialogVisible: true,
        status: 'untouched',
        orderId: action.payload.orderId,
        orderItemId: action.payload.orderItemId,
        address: action.payload.address
      };
    }
    case DISMISS_CANCEL_ORDER_ITEM_DIALOG: {
      return {
        dialogVisible: false,
        status: 'closed',
        address: null,
        orderId: null,
        orderItemId: null
      };
    }
    case CANCEL_ORDER_ITEM: {
      return {
        dialogVisible: true,
        status: 'loading',
        orderId: action.payload.orderId,
        orderItemId: action.payload.orderItemId,
        address: state.address
      };
    }
    case CANCEL_ORDER_ITEM_SUCCESS: {
      if (
        action.payload.orderId !== state.orderId ||
        action.payload.orderItemId !== state.orderItemId
      ) {
        return state;
      }
      return {
        dialogVisible: true,
        status: 'cancelled',
        orderId: action.payload.orderId,
        orderItemId: action.payload.orderItemId,
        address: state.address
      };
    }
    case CANCEL_ORDER_ITEM_ERROR: {
      if (
        action.payload.orderId !== state.orderId ||
        action.payload.orderItemId !== state.orderItemId
      ) {
        return state;
      }
      return {
        dialogVisible: true,
        status: 'error',
        error: action.payload.errorMessage,
        orderId: action.payload.orderId,
        orderItemId: action.payload.orderItemId,
        address: state.address
      };
    }
    default: {
      return state;
    }
  }
}
