// @flow
import React from 'react';
import { RTThemedProgressBar } from '@hc/component-lib/lib/rt-themed';

import styles from './Loading.css';

export const Loading = (props: any) => (
  <div>
    <div className={styles.loadingOverlay}>
      <div className={styles.loadingContent}>
        <RTThemedProgressBar
          theme={styles}
          type="circular"
          mode="indeterminate"
        />
      </div>
    </div>
    {props.children}
  </div>
);

export default Loading;
