// @flow
// $FlowFixMe
import { createSelector } from 'reselect';

import type { State } from '../../reducers';
import type { AddOrderState } from '../reducers/add-order.reducer';
import type { OrderTypeDescriptorsState } from '../reducers/order-type-descriptors.reducer';
import type {
  FieldMeta,
  FieldMetaByOrderType,
  ChoicesByOrderType,
  ProductTypeDescriptor
} from '../types/add-order';
import { GROUP_KEYS, PRODUCT_IDS } from '../constants/order-choices';

import clientSelector from '../selectors/client.selector';
import { FIELD_NAMES, ITEM_FIELD_NAMES } from '../constants/add-order';

export const addOrderStateSelector = (state: State): AddOrderState =>
  clientSelector(state).addOrder.addOrder;
export const orderTypeDescriptorsStateSelector = (
  state: State
): OrderTypeDescriptorsState =>
  clientSelector(state).addOrder.orderTypeDescriptors;

export const productTypeChoicesSelector = createSelector(
  orderTypeDescriptorsStateSelector,
  (
    orderTypeDescriptorsState: OrderTypeDescriptorsState
  ): ?(ProductTypeDescriptor[]) => {
    if (orderTypeDescriptorsState.status === 'loaded') {
      const orderTypes = orderTypeDescriptorsState.descriptors;
      if (orderTypes) {
        const productTypes = GROUP_KEYS.map((groupKey) => ({
          groupKey: groupKey,
          productType: PRODUCT_IDS[groupKey],
          orderTypes: []
        }));
        for (let i = 0; i < orderTypes.length; i++) {
          const orderType = orderTypes[i];
          const groupKey = orderType.groupKey;
          const productType = productTypes.find(
            (possibleProductType) => possibleProductType.groupKey === groupKey
          );
          if (productType) {
            productType.orderTypes.push(orderType);
          }
        }
        const products = productTypes.filter((productType) => {
          return productType ? productType.orderTypes.length > 0 : false;
        });
        return products;
      }
    }
    return null;
  }
);

const fieldMetaByOrderTypeSelectorFactory = (
  FIELD_NAMES: { [key: string]: string },
  defaultFieldMetas: FieldMeta,
  descriptorShownKey: 'shownFields' | 'shownItemFields',
  descriptorRequiredKey: 'requiredFields' | 'requiredItemFields'
) => {
  return (
    orderTypeDescriptorsState: OrderTypeDescriptorsState
  ): FieldMetaByOrderType => {
    const defaultFieldMetaByOrderType = {
      default: { ...defaultFieldMetas }
    };
    if (orderTypeDescriptorsState.status === 'loaded') {
      return orderTypeDescriptorsState.descriptors.reduce(
        (fieldMetaByOrderType, descriptor) => {
          const fieldNameKeys: string[] = Object.keys(FIELD_NAMES);
          // $FlowFixMe
          fieldMetaByOrderType[descriptor.id] = fieldNameKeys.reduce(
            (fieldMetas, fieldNameKey): FieldMeta => {
              const fieldName = FIELD_NAMES[fieldNameKey];
              fieldMetas[fieldName] = {
                shown: descriptor[descriptorShownKey].indexOf(fieldName) !== -1,
                required:
                  descriptor[descriptorRequiredKey].indexOf(fieldName) !== -1
              };
              return fieldMetas;
            },
            { ...defaultFieldMetas }
          );
          return fieldMetaByOrderType;
        },
        { ...defaultFieldMetaByOrderType }
      );
    }
    return defaultFieldMetaByOrderType;
  };
};

const defaultFieldMetas: FieldMeta = Object.keys(FIELD_NAMES).reduce(
  (stubbedFieldMeta, fieldNameKey) => {
    const fieldName = FIELD_NAMES[fieldNameKey];
    stubbedFieldMeta[fieldName] = {
      shown: true,
      required: false
    };
    return stubbedFieldMeta;
  },
  {}
);

const fieldMetaSelector = fieldMetaByOrderTypeSelectorFactory(
  FIELD_NAMES,
  defaultFieldMetas,
  'shownFields',
  'requiredFields'
);
export const fieldMetaByOrderTypeSelector = createSelector(
  orderTypeDescriptorsStateSelector,
  fieldMetaSelector
);

const defaultItemFieldMetas: FieldMeta = Object.keys(ITEM_FIELD_NAMES).reduce(
  (stubbedFieldMeta, fieldNameKey) => {
    const fieldName = ITEM_FIELD_NAMES[fieldNameKey];
    stubbedFieldMeta[fieldName] = {
      shown: true,
      required: false
    };
    return stubbedFieldMeta;
  },
  {}
);

const itemFieldMetaSelector = fieldMetaByOrderTypeSelectorFactory(
  ITEM_FIELD_NAMES,
  defaultItemFieldMetas,
  'shownItemFields',
  'requiredItemFields'
);
export const itemFieldMetaByOrderTypeSelector = createSelector(
  orderTypeDescriptorsStateSelector,
  itemFieldMetaSelector
);

export const choicesByOrderTypeSelector = createSelector(
  orderTypeDescriptorsStateSelector,
  (
    orderTypeDescriptorsState: OrderTypeDescriptorsState
  ): ChoicesByOrderType => {
    const choicesByOrderType = {
      default: []
    };
    if (orderTypeDescriptorsState.status === 'loaded') {
      return orderTypeDescriptorsState.descriptors.reduce(
        (accumulator, descriptor) => {
          // $FlowFixMe
          accumulator[descriptor.id] = {
            propertyTypes: descriptor.propertyTypes
          };
          return accumulator;
        },
        { ...choicesByOrderType }
      );
    }
    return choicesByOrderType;
  }
);
