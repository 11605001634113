// @flow
import React from 'react';
import { connect } from 'react-redux';

import type { Dispatch } from 'redux';
import type { SelectedOrderState } from 'src/client/reducers/selected-order.reducer';
import type { OrderItemsState } from 'src/client/reducers/order-items.reducer';
import type { State } from 'src/reducers';
import type { RemovedItemsState } from 'src/client/reducers/removed-items.reducer';
import type { MoveOrderItemsState } from 'src/client/reducers/move-order-items.reducer';
import type { OrderingParams } from 'src/types/list';

import RemovedList from './components/RemovedList';
import {
  selectedOrderStateSelector,
  orderItemsStateSelector,
  removedItemsStateSelector,
  moveOrderItemsStateSelector
} from 'src/client/selectors/order-items.selectors';
import {
  recoverRemovedItems,
  sortOrderItems,
  searchOrderItems,
  toggleItemSelect,
  toggleAllItemsSelect
} from 'src/client/actions/order-items.actions';
import * as logger from 'src/logger';
import { REMOVED_GROUP } from 'src/client/constants/order-items';
import LoadingButton from 'src/components/loading-button/LoadingButton';
import EmptyListPlaceholder from './components/EmptyListPlaceholder';
import {
  buildListLink,
  shouldDisplayList
} from 'src/client/helpers/list-helpers';
import Search from 'src/components/Search.jsx';
import { ORDER_STATUSES } from 'src/client/constants/order-statuses';

import styles from './order-items.css';

type ReviewOrderSubmitProps = {
  selectedOrderState: SelectedOrderState,
  removedItemsState: RemovedItemsState,
  orderItemsState: OrderItemsState,
  moveOrderItemsState: MoveOrderItemsState,
  onRecoverRemovedItems: (orderId: string, orderItemIds: string[]) => void,
  onSearch: (query: string) => void,
  onSort: (OrderingParams[]) => void,
  onToggleItemSelect: (orderItemId: string) => void,
  onToggleAllItemsSelect: (orderItemIds: string[], toggleOn: boolean) => void
};

class ReviewOrderSubmit extends React.Component<ReviewOrderSubmitProps, void> {
  paginationRouteGeneratorFn = (page: ?number): string => {
    const { removedItemsState } = this.props;
    const { selectedOrderState } = this.props;
    if (
      removedItemsState.status === 'loaded' &&
      selectedOrderState.status === 'loaded'
    ) {
      return buildListLink(
        `/client/order/${selectedOrderState.order.id}/${REMOVED_GROUP}`,
        removedItemsState.query,
        removedItemsState.ordering,
        page
      );
    } else {
      logger.logException(
        new Error(
          'ReviewOrderSubmitContainer#paginationRouteGeneratorFn selectedOrder is not defined or orderItems not loaded'
        )
      );
      return '';
    }
  };

  render() {
    const {
      selectedOrderState,
      removedItemsState,
      orderItemsState,
      moveOrderItemsState,
      onRecoverRemovedItems,
      onSearch,
      onSort,
      onToggleItemSelect,
      onToggleAllItemsSelect
    } = this.props;

    const selectedOrderItems: string[] =
      orderItemsState.selectedItemsByGroup[REMOVED_GROUP];

    return selectedOrderState.status === 'loaded' ? (
      <div>
        <div className={styles.listContainer}>
          {removedItemsState.status === 'error' && (
            <div>{removedItemsState.errorMessage}</div>
          )}
          {removedItemsState.status === 'loaded' &&
            shouldDisplayList(
              removedItemsState.pageItems,
              removedItemsState.query
            ) && (
              <div>
                {(selectedOrderState.order.partialEnabled ||
                  selectedOrderState.order.status ===
                    ORDER_STATUSES.CLIENT_REVIEW) && (
                  <div className={styles.listActionsContainer}>
                    <LoadingButton
                      className={styles.listAction}
                      primary={false}
                      micro
                      label="Recover"
                      loading={
                        moveOrderItemsState.status === 'loading' &&
                        moveOrderItemsState.movingType === 'recover'
                      }
                      disabled={
                        !(selectedOrderItems.length > 0) ||
                        removedItemsState.fetching ||
                        moveOrderItemsState.status === 'loading'
                      }
                      onClick={() =>
                        onRecoverRemovedItems(
                          selectedOrderState.order.id,
                          selectedOrderItems
                        )
                      }
                    />
                    <Search
                      query={removedItemsState.query}
                      onSearch={onSearch}
                    />
                  </div>
                )}
                <RemovedList
                  orderId={selectedOrderState.order.id}
                  orderItems={removedItemsState.pageItems}
                  loading={removedItemsState.fetching}
                  links={removedItemsState.links}
                  paginationRouteGeneratorFn={this.paginationRouteGeneratorFn}
                  selectedOrderItems={selectedOrderItems}
                  ordering={
                    removedItemsState.ordering.length > 0
                      ? removedItemsState.ordering
                      : removedItemsState.defaultOrdering
                  }
                  onSearch={onSearch}
                  onSort={onSort}
                  onToggleOrderItem={onToggleItemSelect}
                  onToggleAll={onToggleAllItemsSelect}
                />
              </div>
            )}
          {removedItemsState.status === 'loaded' &&
            !shouldDisplayList(
              removedItemsState.pageItems,
              removedItemsState.query
            ) && (
              <EmptyListPlaceholder descriptor="No addresses removed from order" />
            )}
        </div>
      </div>
    ) : (
      <div>...</div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    selectedOrderState: selectedOrderStateSelector(state),
    removedItemsState: removedItemsStateSelector(state),
    orderItemsState: orderItemsStateSelector(state),
    moveOrderItemsState: moveOrderItemsStateSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
  return {
    onRecoverRemovedItems: (orderId: string, orderItemIds: string[]) =>
      dispatch(recoverRemovedItems(REMOVED_GROUP, orderId, orderItemIds)),
    onSearch: (query: string) =>
      dispatch(searchOrderItems(REMOVED_GROUP, query)),
    onSort: (ordering: OrderingParams[]) =>
      dispatch(sortOrderItems(REMOVED_GROUP, ordering)),
    onToggleItemSelect: (orderItemId: string) =>
      dispatch(toggleItemSelect(REMOVED_GROUP, orderItemId)),
    onToggleAllItemsSelect: (orderItemIds: string[], toggleOn: boolean) =>
      dispatch(toggleAllItemsSelect(REMOVED_GROUP, orderItemIds, toggleOn))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewOrderSubmit);
