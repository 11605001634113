// @flow
import type { AddressParts } from '../../types/address';
import type {
  OrderItem,
  ValidationResult,
  OrderItemGroup
} from '../types/order-item';
import {
  IN_ORDER_GROUP,
  NEED_REVIEW_GROUP,
  REMOVED_GROUP
} from '../constants/order-items';
import { snakeToCamel } from 'src/sagas/helpers';

export const orderItemResponseToCamelCase = (
  orderItemResponse: any
): OrderItem => {
  const orderItemsCamel = snakeToCamel(
    orderItemResponse,
    Object.keys(orderItemResponse)
  );
  orderItemsCamel.availableDownloadables =
    orderItemsCamel.availableDownloadables.map((availableDownloadable) => {
      return snakeToCamel(
        availableDownloadable,
        Object.keys(availableDownloadable)
      );
    });
  orderItemsCamel.validationResults = orderItemsCamel.validationResults.map(
    (validationResult) => {
      return snakeToCamel(validationResult, Object.keys(validationResult));
    }
  );
  return orderItemsCamel;
};

export const isOverrideable = (orderItem: OrderItem): boolean => {
  for (let i = 0; i < orderItem.validationResults.length; i++) {
    const validationResult: ValidationResult = orderItem.validationResults[i];
    if (!validationResult.canOverride) {
      return false;
    }
  }
  return true;
};

export const getFullAddresFromOrderItem = (orderItem: OrderItem) => {
  const addressParts: AddressParts = {
    address: orderItem.address,
    unit: orderItem.unit,
    city: orderItem.city,
    state: orderItem.state,
    zipcode: orderItem.zipcode
  };
  return getFullAddress(addressParts);
};

export const getFullAddress = (addressParts: AddressParts): string => {
  const unit = addressParts.unit ? ` ${addressParts.unit}` : '';
  return `${addressParts.address}${unit}, ${addressParts.city}, ${addressParts.state} ${addressParts.zipcode}`;
};

export const getOrderItemGroup = (processItem: ?boolean): OrderItemGroup => {
  switch (processItem) {
    case true:
      return IN_ORDER_GROUP;
    case null:
      return NEED_REVIEW_GROUP;
    case false:
      return REMOVED_GROUP;
    default:
      return IN_ORDER_GROUP;
  }
};
