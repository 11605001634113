// @flow
import type { OrderStatus } from 'src/client/types/order';

export const ORDER_STATUSES: { [key: string]: OrderStatus } = {
  ACCEPTED: 'Accepted',
  CLIENT_REVIEW: 'ClientReview',
  NEW: 'New',
  COMPLETE: 'Complete',
  CANCEL_PENDING: 'CancelPending',
  CANCELLED: 'Cancelled'
};
