// @flow
import type { ResellerInfo } from 'src/types/reseller';
import { SUBDOMAIN_TO_RESELLER } from 'src/constants/subdomain-to-reseller';

export const getSubdomain = (host: string): ?string => {
  const parts = host.split('.');
  if (parts.length === 4) {
    const sub = parts[0];
    return sub;
  }
  return null;
};

export const getResellerInfo = (): ?ResellerInfo => {
  const subdomain = getSubdomain(window.location.host);
  if (subdomain) {
    const resellerInfo: ?ResellerInfo = SUBDOMAIN_TO_RESELLER[subdomain];
    if (resellerInfo) {
      return resellerInfo;
    }
  }
  return null;
};

// we would be able to use window.location.origin but since IE doesn't support it (and why we can't have nice things)
export const getOrigin = () =>
  `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ':' + window.location.port : ''
  }`;
