// @flow

export const SUBMIT_FORGOT_PASSWORD = 'SUBMIT_FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

export const VERIFY_RESET_PASSWORD_CODE_SUCCESS =
  'VERIFY_RESET_PASSWORD_CODE_SUCCESS';

export const SUBMIT_RESET_PASSWORD = 'SUBMIT_RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export type SubmitForgotPasswordAction = {
  type: 'SUBMIT_FORGOT_PASSWORD',
  payload: { email: string }
};
export type ForgotPasswordErrorAction = {
  type: 'FORGOT_PASSWORD_ERROR',
  payload: { errorMessage: string }
};
export type ForgotPasswordSuccessAction = { type: 'FORGOT_PASSWORD_SUCCESS' };

export type VerifyResetPasswordCodeSuccess = {
  type: 'VERIFY_RESET_PASSWORD_CODE_SUCCESS',
  payload: { resetPasswordCode: string }
};

export type SubmitResetPasswordAction = {
  type: 'SUBMIT_RESET_PASSWORD',
  payload: { newPassword: string }
};
export type ResetPasswordErrorAction = {
  type: 'RESET_PASSWORD_ERROR',
  payload: { errorMessage: string }
};
export type ResetPasswordSuccessAction = { type: 'RESET_PASSWORD_SUCCESS' };

export type ForgotPasswordAction =
  | SubmitForgotPasswordAction
  | ForgotPasswordErrorAction
  | ForgotPasswordSuccessAction
  | VerifyResetPasswordCodeSuccess
  | SubmitResetPasswordAction
  | ResetPasswordErrorAction
  | ResetPasswordSuccessAction;

export const submitForgotPassword = (
  email: string
): SubmitForgotPasswordAction => ({
  type: SUBMIT_FORGOT_PASSWORD,
  payload: { email }
});
export const forgotPasswordError = (
  errorMessage: string
): ForgotPasswordErrorAction => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { errorMessage }
});
export const forgotPasswordSuccess = (): ForgotPasswordSuccessAction => ({
  type: FORGOT_PASSWORD_SUCCESS
});

export const verifyResetPasswordCodeSuccess = (
  resetPasswordCode: string
): VerifyResetPasswordCodeSuccess => ({
  type: VERIFY_RESET_PASSWORD_CODE_SUCCESS,
  payload: { resetPasswordCode }
});
export const submitResetPassword = (
  newPassword: string
): SubmitResetPasswordAction => ({
  type: SUBMIT_RESET_PASSWORD,
  payload: { newPassword }
});
export const resetPasswordError = (
  errorMessage: string
): ResetPasswordErrorAction => ({
  type: RESET_PASSWORD_ERROR,
  payload: { errorMessage }
});
export const resetPasswordSuccess = (): ResetPasswordSuccessAction => ({
  type: RESET_PASSWORD_SUCCESS
});
