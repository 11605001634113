// @flow

import type { Order } from 'src/client/types/order';

export const orderPercentComplete = (order: Order): number => {
  const {
    completeOrderItemsCount,
    orderItemsToProcessCount,
    orderItemsToReviewCount
  } = order;
  const orderItemsCount = order.partialEnabled
    ? orderItemsToReviewCount + orderItemsToProcessCount
    : orderItemsToProcessCount;
  return orderItemsCount > 0
    ? Math.round((completeOrderItemsCount / orderItemsCount) * 100)
    : orderItemsCount;
};
