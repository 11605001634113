// @flow
import type { Action } from '../actions';
import type { GroupKey } from 'src/client/types/add-order';
import {
  SHOW_ADD_ORDER_DIALOG,
  HIDE_ADD_ORDER_DIALOG,
  ADD_ORDER,
  ADD_ORDER_ERROR,
  ADD_ORDER_SUCCESS,
  TOGGLE_ADD_ORDER_CONFIRMATION
} from 'src/client/actions/add-order.actions';

type Status = 'new' | 'loading' | 'error';

export type AddOrderState = {
  dialogVisible: boolean,
  confirmationVisible: boolean,
  confirmationType: 'toast' | 'dialog',
  error: ?string,
  status: Status,
  createProduct: ?GroupKey
};

const defaultState = {
  dialogVisible: false,
  confirmationVisible: false,
  confirmationType: 'toast',
  error: null,
  status: 'new',
  createProduct: null
};

export function addOrderReducer(
  state: AddOrderState = defaultState,
  action: Action
): AddOrderState {
  switch (action.type) {
    case SHOW_ADD_ORDER_DIALOG: {
      return {
        ...state,
        dialogVisible: true,
        createProduct: action.payload.createProduct
      };
    }
    case HIDE_ADD_ORDER_DIALOG: {
      return {
        ...state,
        dialogVisible: false,
        status: 'new',
        error: null
      };
    }
    case ADD_ORDER: {
      return {
        ...state,
        error: null,
        status: 'loading'
      };
    }
    case ADD_ORDER_ERROR: {
      return {
        ...state,
        status: 'error',
        error: action.payload.errorMessage
      };
    }
    case ADD_ORDER_SUCCESS: {
      return {
        ...state,
        dialogVisible: false,
        status: 'new',
        error: null,
        confirmationVisible: true
      };
    }
    case TOGGLE_ADD_ORDER_CONFIRMATION: {
      return {
        ...state,
        confirmationVisible: action.payload.showConfirmation,
        confirmationType: action.payload.confirmationType
      };
    }
    default: {
      return state;
    }
  }
}
