// @flow

import React from 'react';
import classNames from 'classnames';

import { ORDER_ITEM_STATUSES } from 'src/client/constants/order-item-statuses';

import type { OrderProgress, StatusGroup } from 'src/client/types/order';

import commonStyles from './order-progress-common.css';
import styles from './OrderProgressDetails.css';

type OrderProgressDetailsProps = {
  orderNumber: string,
  orderProgress: OrderProgress,
  statusGroups: StatusGroup[]
};

const OrderProgressDetails = (props: OrderProgressDetailsProps) => {
  const { orderNumber, orderProgress, statusGroups } = props;

  return (
    <div>
      {statusGroups.map((statusGroup: StatusGroup) => {
        const statusCount = Object.keys(orderProgress).reduce(
          (count, orderProgressKey) => {
            if (statusGroup.includedStatuses.indexOf(orderProgressKey) !== -1) {
              count = orderProgress[orderProgressKey];
            }
            return count;
          },
          0
        );
        let rowClassName = commonStyles.row;
        if (
          statusGroup.includedStatuses.indexOf(ORDER_ITEM_STATUSES.COMPLETE) !==
          -1
        ) {
          rowClassName = classNames(commonStyles.row, styles.Complete);
        }
        return (
          <div
            className={rowClassName}
            key={`${orderNumber}_${statusGroup.name}`}
          >
            <div className={styles.label}>{statusGroup.name}</div>
            <div className={styles.numberOfItems}>{statusCount}</div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderProgressDetails;
